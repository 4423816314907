/* eslint-disable prefer-const */
/* eslint-disable linebreak-style */
/* eslint-disable brace-style */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable require-jsdoc */
import React, { useEffect, useReducer, useState, useContext, useMemo, memo, useRef } from 'react';
import { View, Text, TouchableOpacity, ScrollView, Image } from 'react-native';
import { useIsFocused } from '@react-navigation/native';

import { useScale } from '../components/scale';
import Header from '../components/header';
import NavSideBar from '../components/NavSideBar';
import ModeContext from '../components/ModeContext';
import { useColorContext } from '../components/ColorContext';
import { useNavBarContext } from '../components/NavBarContext';
import { CounterContext } from '../components/CounterContext';
import { useSnackbar } from '../components/SnackbarContext';
import SnackbarAlert from '../components/SnackbarAlert';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import LegendItem from '../components/Legend';

import { Tooltip } from 'recharts';
import * as Progress from 'react-native-progress';
import { TextInput, HelperText } from 'react-native-paper';
import { DateRangePicker, Stack } from 'rsuite';
import { Platform } from 'react-native';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';

import { Box, Button } from '@mui/material';
import { useTheme, darken, createTheme, ThemeProvider } from '@mui/material/styles';

import { MoreHoriz, Check, ConstructionOutlined } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/Download';

import { ExportToCsv } from 'export-to-csv';
import { MaterialReactTable, MRT_FullScreenToggleButton, MRT_ToggleGlobalFilterButton, MRT_ShowHideColumnsButton, MRT_ToggleFiltersButton } from 'material-react-table';

import colors from '../colors';
import { get_approved_1380s, get_image_s3, get_single_file, update_file_status } from '../api_handler/files';
import { create_new_user_signature } from '../api_handler/users';
import { put_image_s3 } from '../api_handler/files';
import { create_notification } from '../api_handler/notifications';

import Modal from 'react-native-modal';
import ViewShot from "react-native-view-shot";
import { captureRef } from "react-native-view-shot";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { storeJsonData } from '../api_handler/asyncstorage';

import { generatePDF, generate_1380, generate_1380_aftp } from './generatePDF';


import dayjs from 'dayjs';
import Scrollbars from 'react-custom-scrollbars';
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');

dayjs.extend(utc);
dayjs.extend(timezone);

let cur_form_id = '';

// styles
function PayManagement({ route, navigation }) {
  const { colors } = useColorContext();
  const { navState } = useNavBarContext();
  const { updateUnpaidFiles } = useContext(CounterContext);
  const { showSnackbar } = useSnackbar();
  const viewRef = useRef();

  const [rows, setRows] = React.useState([]);
  const [docData, setdocData] = useState(1);
  const [density, setDensity] = useState('compact');
  const [columnVisibility, setColumnVisibility] = useState({});
  const [rowSelection, setRowSelection] = useState({});
  const isFocused = useIsFocused();
  const rerender = useReducer(() => ({}), {})[1]; // Forces the MRT_ components to re-render
  const [showColumnFilters, setShowColumnFilters] = useState(false);
  const [showGlobalFilter, setShowGlobalFilter] = useState(false);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 15 });
  const [loadingPDFs, setLoadingPDFs] = React.useState(false);
  const { mode } = React.useContext(ModeContext);
  const [isLoading, setIsLoading] = useState(true);

  const tableInstanceRef = useRef(null);
  const [hasPermission, setHasPermission] = React.useState(false);
  const [hasMarkPermission, setHasMarkPermission] = React.useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [showConfirmVis, setShowConfirmVis] = useState(false);
  const [showDenyConfirmVis, setShowDenyConfirmVis] = useState(false);
  const [selectedRows, setSelectedRows] = useState(undefined);

  const [unitSelection, setUnitSelection] = useState([]);
  const [unitType, setUnitType] = useState(['All Units']);
  const [statusType, setStatusType] = useState(['Approved']);
  const [dates, setDates] = useState([null, null]);

  const palette2 = ['#81C784', '#FFD54F'];
  const palette2Darker = ['#3d8840', '#c89900'];

  const [isLight, setIsLight] = useState(mode === 'light');
  const [pieColorSelection, setpieColors] = useState(isLight ? palette2Darker : palette2);
  const [activeChartButton, setActiveChartButton] = useState(0);
  const [payTotals, setPayTotals] = useState([0, 0]);
  const [filteredByDateUnit, setFilteredByDateUnit] = React.useState([]);

  const [viewSig, setViewSig] = useState(false);
  const [doesUserHaveSignature, setDoesUserHaveSignature] = useState(false);
  const [didUserSignDocument, setDidUserSignDocument] = useState(false);
  const [signatureName, setSignatureName] = useState("");
  const [comment, setComment] = useState('');
  const [emptyComment, setEmptyComment] = useState(false);
  const [height, setHeight] = useState(0);
  const [pdfBase64, setPdfBase64] = useState(null);


  const handleGeneratePdf = async (row) => {
    if (row) {
      const fileData = await get_single_file({ 'form_id': row.form_id, 'form_type': row.form_type }, global.token);
      if (fileData) {
        const base64 = row.form_type == '1380 aftp' ?
          await generate_1380_aftp(row.form_id, fileData.body, route.navigation, true, false, row.attachments, row.creator_id) :
          await generate_1380(row.form_id, fileData.body, route.navigation, true, false, row.attachments, row.creator_id);
        setPdfBase64(base64);
        localStorage.setItem('pdfBase64', base64);
      };
    };
    setIsLoading(false);
  };

  const StatusTypes = [
    {
      index: 0,
      label: 'All Statuses',
    },
    {
      index: 4,
      label: 'Approved',
    },
    {
      index: 6,
      label: 'Paid',
    },
  ];

  const predefinedRanges = [
    {
      label: 'Today',
      closeOverlay: false,
      value: [new Date(), new Date()],
      placement: 'left',
    },
    {
      label: 'Yesterday',
      closeOverlay: false,
      value: [addDays(new Date(), -1), addDays(new Date(), -1)],
      placement: 'left',
    },
    {
      label: 'Last 30 days',
      closeOverlay: false,
      value: [subDays(new Date(), 29), new Date()],
      placement: 'left',
    },
    {
      label: 'This month',
      closeOverlay: false,
      value: [startOfMonth(new Date()), new Date()],
      placement: 'left',
    },
    {
      label: 'Last month',
      closeOverlay: false,
      value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
      placement: 'left',
    },
    {
      label: 'This year',
      closeOverlay: false,
      value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
      placement: 'left',
    },
    {
      label: 'Last year',
      closeOverlay: false,
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
      placement: 'left',
    },
    {
      label: 'Last week',
      closeOverlay: false,
      value: (value) => {
        const start = new Date();
        return [
          startOfWeek(addDays(start, -7), { weekStartsOn: 0 }),
          endOfWeek(addDays(start, -7), { weekStartsOn: 0 }),
        ];
      },
      appearance: 'default',
    },
    {
      label: 'This week',
      closeOverlay: false,
      value: (value) => {
        const start = new Date();
        return [
          startOfWeek(start, { weekStartsOn: 0 }),
          endOfWeek(start, { weekStartsOn: 0 }),
        ];
      },
      appearance: 'default',
    },
    {
      label: 'Next week',
      closeOverlay: false,
      value: () => {
        const today = new Date();
        const nextWeek = addDays(today, 7);
        return [
          startOfWeek(nextWeek, { weekStartsOn: 0 }),
          endOfWeek(nextWeek, { weekStartsOn: 0 }),
        ];
      },
      appearance: 'default',
    },
  ];

  if (Platform.OS === 'web') {
    require('rsuite/dist/rsuite.min.css');
  }

  const scale = useScale();
  const styles = ({
    tableContainer: {
      justifyContent: 'center',
      marginVertical: '2%',
      alignSelf: 'center',
      marginHorizontal: '5%',
      borderColor: colors.border_color,
      borderWidth: global.user_data.theme === 'dark' ? 1 : 0,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
    },
    container: {
      height: '100%',
      width: '100%',
      flexDirection: 'row',
      backgroundColor: colors.screen_background,
    },
    // THIS IS THE NEW CSS FROM LEADER ADMIN
    titleDiv: {
      flexDirection: 'row',
      width: '100%',
      alignItems: 'center',
    },
    title: {
      fontSize: scale(30),
      fontFamily: 'Trebuchet MS',
      flex: 1,
      paddingTop: 25,
      fontWeight: 'bold',
      marginHorizontal: 5,
      textAlign: 'Left',
      color: colors.screen_title,
    },
    pageTopSection: {
      flexDirection: 'row',
      width: '90%',
      alignSelf: 'center',
      marginLeft: '5%',
      marginTop: '2.5%',
    },
    pageInfoText: {
      fontSize: 18,
      marginHorizontal: 5,
      textAlign: 'left',
      paddingTop: 25,
      color: colors.text,
    },
    filtersContainer: {
      marginTop: '5%',
      paddingLeft: '2%',
      paddingRight: '5.4%',
    },
    filtersRow: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    unitsFilter: {
      flex: 1,
      margin: '2%',
    },
    filtersTextBox: {
      width: '100%',
      backgroundColor: colors.textfield_background,
      height: '100%',
    },
    dateRangePickerContainer: {
      flex: 1,
      marginBottom: '3.3%',
      paddingHorizontal: '2%',
    },
    listItemTextTypography: {
      fontSize: '16px',
    },
    chartSelector: {
      flexDirection: 'row',
      flex: 1,
      justifyContent: 'space-evenly',
      textAlign: 'center',
      borderBottomWidth: 2,
      borderColor: '#D9D9D9',
      margin: 25,
    },
    pieButton: {
      flex: 0.25,
    },
    pieButtonPressed: {
      borderBottomWidth: 4,
      borderColor: '#A98C42',
    },
    legendContainer: {
      backgroundColor: colors.leader_dash_legend_container,
      borderRadius: 20,
      flex: 2.5,
      alignItems: 'center',
      marginTop: '2.5%',
      fontFamily: 'Trebuchet MS',
      borderColor: colors.border_color,
      borderWidth: global.user_data.theme === 'dark' ? 1 : 0,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
    },
    chartButtons: {
      width: '99%',
      alignContent: 'center',
    },
    legend: {
      color: colors.bow,
      alignItems: 'left',
    },
    doc: {
      margin: 15,
      width: 40,
      height: 40,
    },
    main: {
      height: '100%',
      flex: 10,
    },
    payBtn: {
      width: '90%',
      borderRadius: '40',
      alignItems: 'center',
      color: 'white',
      borderRadius: 15,
      shadowColor: '#171717',
      shadowOffset: { width: -2, height: 4 },
      shadowOpacity: 0.2,
      shadowRadius: 3,
      backgroundColor: colors.table_background_button,
      marginTop: '5%',
      marginBottom: '5%',
    },
    innerText: {
      color: colors.button_text,
      fontWeight: 'bold',
      fontSize: 16,
      padding: 10,
      whiteSpace: 'nowrap',
    },
    modalContainer: {
      flexDirection: 'column',
      height: '97.5%',
      width: '50%',
      alignSelf: 'center',
      backgroundColor: colors.screen_background,
      borderRadius: 5,
      overflow: 'hidden',
    },
    denyContainer: {
      width: '100%',
      alignSelf: 'center',
      alignItems: 'center',
      justifyContent: 'center',
    },
    mainModalContainer: {
      width: '60%',
      height: '95%',
      alignSelf: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 10,
      borderColor: colors.border_color,
      borderWidth: 1,
      shadowColor: "#000",
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.1,
      shadowRadius: 4,
      elevation: 3,
      backgroundColor: colors.screen_background,
    },
    pdfContainer: {
      width: '95%',
      height: '60%',
      alignSelf: 'center',
      borderWidth: 1,
      borderColor: colors.border_color,
      marginBottom: '1%'
    },
    signatureContainer: {
      width: '85%',
      height: '30%',
      padding: 20,
      borderRadius: 20,
      backgroundColor: colors.screen_background,
      borderWidth: 1,
      borderColor: colors.border_color,
      alignSelf: 'center',
      alignItems: 'center',
      justifyContent: 'center',
    },
    contentContainer: {
      width: '85%',
      height: '30%',
      padding: 20,
      borderRadius: 20,
      backgroundColor: colors.screen_background,
      borderWidth: 1,
      borderColor: colors.border_color,
      alignSelf: 'center',
      alignItems: 'center',
      justifyContent: 'center',
    },
    titleContainer: {
      alignSelf: 'center',
      marginBottom: 10,
    },
    titleText: {
      fontSize: 32,
      fontWeight: '800',
      color: colors.file_Denied,
      textDecoration: 'underline'
    },
    headerContainer: {
      alignSelf: 'center',
    },
    headerText: {
      fontSize: 26,
      fontWeight: 'bold',
      color: colors.text,
    },
    signatureBoxContainer: {
      flex: 1,
      paddingRight: 10,
      paddingLeft: 5,
      marginVertical: 10,
    },
    signatureBox: {
      width: '100%',
      height: '67px',
      backgroundColor: 'white',
      borderRadius: 4,
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
      borderWidth: 1,
      borderColor: colors.border_color,
      marginVertical: 10,
    },
    signaturePreview: {
      padding: 10,
      backgroundColor: "white",
      borderRadius: 10,
    },
    signatureText: {
      color: "black",
      fontSize: 24,
      fontWeight: "bold",
      textAlign: "center",
    },
    noSignatureText: {
      color: colors.placeholder_text,
      fontSize: 16,
      fontStyle: 'italic',
    },
    inputImageContainer: {
      flexDirection: 'row',
      width: '100%',
    },
    commentInputContainer: {
      flex: 1,
      paddingHorizontal: 10,
      marginVertical: 10,
    },
    signatureInputContainer: {
      flex: 1,
      paddingLeft: 10,
      paddingRight: 5,
      marginVertical: 10,
    },
    input: {
      borderColor: colors.border_color,
      borderWidth: 1,
      borderRadius: 6,
      backgroundColor: colors.modal_background,
      width: "100%",
      paddingHorizontal: 10,
      fontSize: 14,
      textAlignVertical: 'top',
      alignSelf: 'center',
      marginVertical: 10,
    },
    commentInput: {
      borderColor: colors.border_color,
      borderWidth: 1,
      borderRadius: 6,
      backgroundColor: colors.modal_background,
      width: "100%",
      paddingHorizontal: 10,
      fontSize: 14,
      textAlignVertical: 'top',
      alignSelf: 'center',
      marginVertical: 10,
    },
    imageContainer: {
      width: '50%',
      justifyContent: 'center',
      alignItems: 'center',
      paddingLeft: 0,
      paddingRight: 10,
      marginVertical: 10,
    },
    imageBox: {
      width: '100%',
      paddingHorizontal: 10,
      marginVertical: 10,
      height: '67px',
      backgroundColor: 'white',
      borderWidth: 1,
      borderColor: colors.border_color,
      borderRadius: 4,
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
    },
    signatureImage: {
      width: '100%',
      height: '100%',
      resizeMode: 'contain',
    },
    createButton: {
      width: "35%",
      borderRadius: 10,
      padding: 12,
      backgroundColor: colors.action_button,
      borderColor: colors.border_color,
      borderWidth: 1,
      alignItems: 'center',
      shadowColor: "#000",
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.1,
      shadowRadius: 4,
      elevation: 3,
      marginBottom: 10,
    },
    buttonContainer: {
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'space-around',
      marginBottom: 20,
    },
    contentButton: {
      width: "35%",
      borderRadius: 10,
      padding: 12,
      backgroundColor: colors.action_button,
      borderColor: colors.border_color,
      borderWidth: 1,
      alignItems: 'center',
      shadowColor: "#000",
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.1,
      shadowRadius: 4,
      elevation: 3,
      marginBottom: 10,
    },
    cancelButton: {
      backgroundColor: colors.button,
    },
    signButton: {
      backgroundColor: colors.action_button,
    },
    denyButton: {
      backgroundColor: colors.file_Denied,
    },
    buttonText: {
      color: colors.text,
      fontSize: 16,
      fontWeight: 'bold',
    },
  });

  const handleStatusFilter = (selectedValues) => {
    const allStatusIndex = selectedValues.indexOf('All Statuses');
    const previousAllStatusIndex = statusType.indexOf('All Statuses');

    let updatedValues = [...selectedValues];

    if (allStatusIndex !== -1 && previousAllStatusIndex === -1 && selectedValues.length > 1) {
      updatedValues = ['All Statuses'];
    } else if ((allStatusIndex === -1 || previousAllStatusIndex === -1) && selectedValues.length === 2) {
      updatedValues = ['All Statuses'];
    } else if (previousAllStatusIndex !== -1 && selectedValues.length > 1) {
      updatedValues = selectedValues.filter((value) => value !== 'All Statuses');
    }

    setStatusType(updatedValues);
    applyFilters(docData, unitType, updatedValues, dates[0], dates[1]);
  };

  const handleUnitFilter = (selectedValues) => {
    const allUnitsIndex = selectedValues.indexOf('All Units');
    const previousAllUnitsIndex = unitType.indexOf('All Units');

    let updatedValues = [...selectedValues];

    if (allUnitsIndex !== -1 && previousAllUnitsIndex === -1 && selectedValues.length > 1) {
      updatedValues = ['All Units'];
    } else if ((allUnitsIndex === -1 || previousAllUnitsIndex === -1) && selectedValues.length === 3) {
      updatedValues = ['All Units'];
    } else if (previousAllUnitsIndex !== -1 && selectedValues.length > 1) {
      updatedValues = selectedValues.filter((value) => value !== 'All Units');
    }
    setUnitType(updatedValues);
    applyFilters(docData, updatedValues, statusType, dates[0], dates[1]);
  };

  const statusLabelToCode = {
    'Approved': 4,
    'Paid': 6,
  };

  const handleDateRangeChange = (dateRanges) => {
    if (dateRanges) {
      setDates(dateRanges);
      applyFilters(docData, unitType, statusType, dateRanges[0], dateRanges[1]);
    } else {
      setDates([null, null]);
      applyFilters(docData, unitType, statusType);
    }
  };

  const filterByDateAndUnit = (data, unitType, startDate, endDate) => {
    let filtered = data;

    // Date range filtering
    if (startDate && endDate) {
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 999);

      filtered = filtered.filter((item) => {
        const timestampIndex = item.status === 6 ? item.timestamp.length - 1 : 0;
        const submittedDate = new Date(item.timestamp[timestampIndex]);
        return submittedDate >= startDate && submittedDate <= endDate;
      });
    }

    // Unit type filtering
    if (!unitType.includes('All Units')) {
      filtered = filtered.filter((item) => unitType.some((unit) => item.unit_name.toLowerCase() === unit.toLowerCase()));
    }

    return filtered;
  };


  const applyFilters = (data, unitType = ['All Units'], statusType = ['Approved'], startDate, endDate) => {
    let filtered = data;
    const datesProvided = startDate && endDate;

    if (!datesProvided && !statusType.includes('Approved') && !statusType.includes('All Statuses')) {
      const currentDate = new Date();
      endDate = new Date(currentDate);
      startDate = new Date(currentDate.setDate(currentDate.getDate() - 90));
    }

    if (startDate && endDate) {
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 999);

      filtered = filtered.filter((item) => {
        const timestampIndex = item.status === 6 ? item.timestamp.length - 1 : 0;
        const submittedDate = new Date(item.timestamp[timestampIndex]);
        return submittedDate >= startDate && submittedDate <= endDate;
      });
    }
    if (!unitType.includes('All Units')) {
      filtered = filtered.filter((item) => unitType.some((unit) => item.unit_name.toLowerCase() === unit.toLowerCase()));
    }

    if (!statusType.includes('All Statuses')) {
      filtered = filtered.filter((item) => {
        const itemStatusCode = item.status;
        const selectedStatusCodes = statusType.map((label) => statusLabelToCode[label]);
        return selectedStatusCodes.includes(itemStatusCode);
      });
    }
    const groupedAndSortedForms = groupAndSortForms(filtered);
    setRows(groupedAndSortedForms);
  };

  const groupAndSortForms = (forms) => {
    const groupedForms = {};

    forms.forEach((form) => {
      const unitName = form.unit_name;
      if (!groupedForms[unitName]) {
        groupedForms[unitName] = {
          approvedForms: [],
          paidForms: [],
        };
      }

      if (form.status === 6) {
        groupedForms[unitName].paidForms.push(form);
      } else {
        groupedForms[unitName].approvedForms.push(form);
      }
    });

    Object.values(groupedForms).forEach((unitForms) => {
      unitForms.approvedForms.sort((a, b) => {
        const dateA = new Date(a.timestamp[0]);
        const dateB = new Date(b.timestamp[0]);
        return dateA - dateB;
      });

      unitForms.paidForms.sort((a, b) => {
        const dateA = new Date(a.timestamp[a.timestamp.length - 1]);
        const dateB = new Date(b.timestamp[b.timestamp.length - 1]);
        return dateB - dateA;
      });
    });

    const result = [];

    Object.values(groupedForms).forEach((unitForms) => {
      result.push(...unitForms.approvedForms);
    });

    Object.values(groupedForms).forEach((unitForms) => {
      result.push(...unitForms.paidForms);
    });

    return result;
  };

  const pieSelection = useMemo(() => {
    const updatedPayData = [
      { name: 'Approved', value: payTotals[0] || 0 },
      { name: 'Paid', value: payTotals[1] || 0 },
    ];
    return updatedPayData;
  }, [payTotals]);

  useEffect(() => {
    if (global.user_data.theme === 'light') {
      setIsLight(true);
    } else {
      setIsLight(false);
    }
    setpieColors(isLight ? palette2Darker : palette2);
  }, [global.user_data.theme, isLight]);

  function pieSelector(data, color, index) {
    setpieColors(color);
    setActiveChartButton(index);
  }

  const ChartSelectorButtons = () => {
    const buttonData = [
      { label: 'Payment Visual', palette: pieColorSelection, index: 0 },
    ];

    return (
      <View style={styles.chartSelector}>
        {buttonData.map((button) => (
          <TouchableOpacity
            key={button.index}
            style={[
              styles.pieButton,
              activeChartButton === button.index && styles.pieButtonPressed,
            ]}
            onPress={() => {
              pieSelector(pieSelection, button.palette, button.index);
            }}
          >
            <Text style={{ color: colors.bow }}>{button.label}</Text>
          </TouchableOpacity>
        ))}
      </View>
    );
  };

  useEffect(() => {
    const fetch1380s = async () => {
      const ret = await get_approved_1380s({
        user_id: global.user_data.user_id,
        unit_id: global.user_data.unit_id,
        permissions: global.user_data.permissions,
        admin_unit_ids: global.user_data.admin_unit_ids,
      }, global.token);
      if (ret.status !== 200) {
        showSnackbar('error', 'Application Error: Failed to retrieve approved 1380s');
        navigation.navigate('Home');
        return;
      } else {
        setUnitSelection(ret.data.units);
        setPayTotals(Object.values(ret.data.status_counts['totals']));
        setdocData(ret.data.body);
        applyFilters(ret.data.body);
      }
    };

    const importCSS = async () => {
      if (isLight) {
        await import('../styles/dateRangePickerLight.css');
      } else {
        await import('../styles/dateRangePickerDark.css');
      }
    };

    function checkSignature() {
      if (global.signature_uri == null || global.signature_uri === "") {
        setDoesUserHaveSignature(false);
      } else {
        setDoesUserHaveSignature(true);
      }
    }

    const loadDataAndStyles = async () => {
      setIsLoading(true);
      await Promise.all([fetch1380s(), importCSS()]);
      checkSignature();
      setIsLoading(false);
    };



    // Check that the user is entering the page (not exiting)
    if (isFocused) {
      loadDataAndStyles();
    }

    if (navState.canSeePayManagement || navState.canEditPayManagement) {
      setHasPermission(true);
    } else {
      setHasPermission(false);
    }

    if (navState.canEditPayManagement) {
      setHasMarkPermission(true);
    } else {
      setHasMarkPermission(false);
    }
  }, [isFocused]);

  useEffect(() => {
    const filteredDateUnit = filterByDateAndUnit(docData, unitType, dates[0], dates[1]);

    if (!Array.isArray(filteredDateUnit)) {
      console.error('Filtered Date Unit is not an array:', filteredDateUnit);
      return;
    }

    const groupedAndSortedByDateUnitForms = groupAndSortForms(filteredDateUnit);
    setFilteredByDateUnit(groupedAndSortedByDateUnitForms);

    let approvedCount = 0; let paidCount = 0;
    for (const data of groupedAndSortedByDateUnitForms) {
      if (data.status === 6) {
        paidCount++;
      } else {
        approvedCount++;
      }
    }
    setPayTotals([approvedCount, paidCount]);
  }, [unitType, dates]);

  function getCurrentFormattedDate() {
    const now = new Date();

    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Month is zero-based
    const year = now.getFullYear();

    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}_${minutes}`;
  }

  // Opens Error Message Alert
  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  // Closes Error Message Alert
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const csvOptions = {
    filename: getCurrentFormattedDate(),
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: ['Form ID', 'Requestor', 'Unit', 'File Type', 'Submission Date', 'Paid?', 'Payment Date'],
  };

  const csvExporter = new ExportToCsv(csvOptions);

  // Retrieve excel columns in correct order
  const handleExportData = () => {
    const desiredOrder = ['form_id', 'form_name', 'unit_name', 'request_type', 'timestamp', 'paid', 'payment_date'];

    const rowsInDesiredOrder = rows.map((item) => {
      const singleRow = {};
      desiredOrder.forEach((header) => {
        const uploadedIndex = item['timestamp_string'].indexOf('File Uploaded');
        const paidIndex = item['timestamp_string'].indexOf('File Paid');

        if (header === 'timestamp') {
          singleRow[header] = uploadedIndex !== -1 ? formatTableDate(item['timestamp'][uploadedIndex]) : '';
        } else if (header === 'paid') {
          singleRow[header] = paidIndex !== -1 ? 'TRUE' : 'FALSE';
        } else if (header === 'payment_date') {
          singleRow[header] = paidIndex !== -1 ? formatTableDate(item['timestamp'][paidIndex]) : '';
        } else {
          singleRow[header] = item[header];
        }
      });

      return singleRow;
    });

    csvExporter.generateCsv(rowsInDesiredOrder);
  };

  const captureTextAsImage = async () => {
    try {
      const fullname =
        global.user_data.first_name.toLowerCase() +
        " " +
        global.user_data.last_name.toLowerCase();
      if (signatureName.toLowerCase() === fullname) {
        const uri = await captureRef(viewRef, {
          format: "png",
          quality: 0.8,
        });

        let ret = await create_new_user_signature(
          { user_id: global.user_data.user_id },
          global.token
        );
        if (ret.status === 200) {
          ret = ret.data;
          await put_image_s3(
            "signatures/" + ret.body.signature_id,
            uri,
            "image/png",
            global.token
          );

          global.signature_uri = uri;

          global.user_data.signature_id = ret.body.signature_id;
          await AsyncStorage.setItem(
            "signature",
            global.signature_uri
          );
          storeJsonData("user_data", global.user_data);

          setDoesUserHaveSignature(true);
          showSnackbar("success", "Successfully created signature");
        } else {
          showSnackbar(
            "error",
            "Application Error: Failed to create your signature"
          );
        }
      } else {
        showSnackbar(
          "error",
          "The name inputted does not match your name we have on record!"
        );
      }
    } catch (error) {
      console.error("Error capturing image:", error);
    }
  };

  const sendNotifToSoldier = async (soldier_id, form_id, body) => {
    const data = {
      title: '1380 Status Update',
      body: body,
      receiver_id: soldier_id,
      sender_id: global.user_data.user_id,
      notification_type: 'file',
      object_type: 'review 1380 form',
      form_events_id: form_id,
    };
    const res = await create_notification(data, global.token);
    if (res.status !== 200) {
      showSnackbar(
        'success',
        '1380 form updated successfully',
        'top',
        'right',
        6000,
        400,
        () => {
          showSnackbar(
            'error',
            `Application Error: Failed to send notification`,
          );
        },
      );
    }
    return res.status === 200;
  };

  const handleSignature = () => {
    setViewSig(!viewSig);
    setDidUserSignDocument(!didUserSignDocument);
  };

  const deny_status = async (selectedRows) => {
    setIsLoading(true);
    let updatedRows = [...docData];
    const notiStatus = [];
    let flag = false;

    for (const selectedRow of selectedRows) {
      let body = '';
      let updatedRow;

      // Deny the form
      body = 'Your 1380 form submitted on ' + date_tdy(selectedRow.timestamp[0]) + ' has been denied by a Pay Admin.';

      const currDate = new Date().toISOString();
      updatedRow = {
        ...selectedRow,
        status: 5,
        paid: 'false',
        timestamp_string: [...selectedRow.timestamp_string, 'File Denied'],
        timestamp: [...selectedRow.timestamp, currDate],
      };

      const res = await update_file_status(JSON.stringify({
        form_id: selectedRow.form_id,
        status: 5,
        permissions: global.user_data.permissions,
        already_paid: 'false',
        timestamp: currDate,
        timestamp_string: 'File Denied',
        comment: `PAY ADMIN: ${comment || '[No comment]'}`,
        reviewer_id: global.user_data.user_id,
        setting_reviewer_id: true,
      }), global.token);

      flag = res.status !== 200;

      if (flag) {
        showSnackbar('error', 'Application Error: Failed to update the status of selected forms');
      } else {
        updatedRows = updatedRows.map((row) => (row.form_id === selectedRow.form_id ? updatedRow : row));
        const status = await sendNotifToSoldier(selectedRow.creator_id, selectedRow.form_id, body, selectedRow.form_name);
        notiStatus.push({ name: selectedRow.form_name, status: status });
        setComment('');
      }
    }

    if (!flag) {
      const updatedPayTotals = updatedRows.reduce(
        (totals, row) => {
          if (row.status === 4) {
            totals[0] += 1;
          } else if (row.status === 6) {
            totals[1] += 1;
          }
          return totals;
        },
        [0, 0],
      );

      updateUnpaidFiles(updatedPayTotals[0]);

      setPayTotals(updatedPayTotals);
      setdocData(updatedRows);
      applyFilters(updatedRows, unitType, statusType);
      const failedForms = notiStatus.filter((form) => form.status !== true).map((form) => form.name).join(', ');
      if (failedForms !== '') {
        showSnackbar('error', `Application Error: Failed to send notifications for ${failedForms}`);
      }
    }
    setIsLoading(false);
  };

  const change_status = async (selectedRows) => {
    setIsLoading(true);
    let updatedRows = [...docData];
    const notiStatus = [];
    let flag = false;

    for (const selectedRow of selectedRows) {
      let body = '';
      let updatedRow;

      // Form is unpaid... update to be paid
      if (selectedRow.status == 4) {
        body = 'Your 1380 form submitted on ' + date_tdy(selectedRow.timestamp[0]) + ' has been paid.';

        const currDate = new Date().toISOString();
        updatedRow = {
          ...selectedRow,
          status: 6,
          paid: 'true',
          timestamp_string: [...selectedRow.timestamp_string, 'File Paid'],
          timestamp: [...selectedRow.timestamp, currDate],
        };

        const res = await update_file_status(JSON.stringify({
          form_id: selectedRow.form_id,
          status: 6,
          permissions: global.user_data.permissions,
          already_paid: 'false',
          timestamp: currDate,
          timestamp_string: 'File Paid',
          setting_reviewer_id: false,
        }), global.token);

        if (res.status !== 200) {
          flag = true;
        }
      }

      // Form is paid... change to be unpaid
      else {
        const indexToRemove = selectedRow.timestamp_string.findIndex((item) => item === 'File Paid');
        let oldTimestamp;
        if (indexToRemove !== -1) {
          oldTimestamp = selectedRow.timestamp[indexToRemove];
          body = 'Your 1380 form paid on ' + date_tdy(oldTimestamp) + ' has been marked as unpaid.';
        } else {
          body = 'Your paid 1380 form has been marked as unpaid.';
        }
        updatedRow = {
          ...selectedRow,
          status: 4,
          paid: 'true',
          timestamp_string: selectedRow.timestamp_string.filter((_, i) => i !== indexToRemove),
          timestamp: selectedRow.timestamp.filter((_, i) => i !== indexToRemove),
          paid_date: null,
        };

        const res = await update_file_status(JSON.stringify({
          form_id: selectedRow.form_id,
          status: 4,
          permissions: global.user_data.permissions,
          already_paid: 'true',
          timestamp: oldTimestamp,
          setting_reviewer_id: false,
        }), global.token);

        if (res.status !== 200) {
          flag = true;
        }
      }

      if (flag) {
        showSnackbar('error', 'Application Error: Failed to update the status of selected forms');
      } else {
        updatedRows = updatedRows.map((row) => (row.form_id === selectedRow.form_id ? updatedRow : row));
        const status = await sendNotifToSoldier(selectedRow.creator_id, selectedRow.form_id, body, selectedRow.form_name);
        notiStatus.push({ name: selectedRow.form_name, status: status });
      }
    }

    if (!flag) {
      const updatedPayTotals = updatedRows.reduce(
        (totals, row) => {
          if (row.status === 4) {
            totals[0] += 1;
          } else if (row.status === 6) {
            totals[1] += 1;
          }
          return totals;
        },
        [0, 0],
      );

      updateUnpaidFiles(updatedPayTotals[0]);

      setPayTotals(updatedPayTotals);
      setdocData(updatedRows);
      applyFilters(updatedRows, unitType, statusType);
      const failedForms = notiStatus.filter((form) => form.status !== true).map((form) => form.name).join(', ');
      if (failedForms !== '') {
        showSnackbar('error', `Application Error: Failed to send notifications for ${failedForms}`);
      }
    }
    setIsLoading(false);
  };

  function date_tdy(date) {
    const utcDate = dayjs(date).utc(); // Parse input date as UTC
    const estDate = utcDate.tz('America/New_York'); // Convert to Eastern Standard Time (EST)

    // Format the date in military time (24-hour format)
    const formattedDate = estDate.format('MMM D YYYY');

    return formattedDate;
  }

  function formatTableDate(inputDateString) {
    const inputDate = new Date(inputDateString);

    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit', // Use "2-digit" for 24-hour format
      minute: '2-digit',
      timeZoneName: 'short',
      hour12: false, // Ensure 24-hour format
    };

    return inputDate.toLocaleString(undefined, options);
  }

  const globalTheme = useTheme();
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: mode,
          primary: globalTheme.palette.primary,
          background: {
            default: colors.textfield_background,
          },
        },
        typography: { fontSize: 17 },
      }),
    [globalTheme, isFocused],
  );

  const columns = useMemo(() => {
    const columnsBeforePayStatus = [
      {
        accessorKey: 'file',
        header: 'File',
        size: 1,
        accessorFn: (row) => (row.status == 4 ? 'Unpaid' : 'Paid'),
        Cell: ({ row }) => {
          return (
            <TouchableOpacity style={{ width: 40, height: 40 }} onPress={() => {
              setIsLoading(true);
              generatePDF(row.original.form_id, row.original.form_type, navigation, false, false, row.original.attachments, row.original.creator_id);
              setIsLoading(false);
            }}>
              <Image
                defaultSource={
                  row.original.status == 4 ?
                    isLight ?
                      require('../assets/document_submitted.png') :
                      require('../assets/document_submitted_dark.png') :
                    isLight ?
                      require('../assets/document_paid.png') :
                      require('../assets/document_paid_dark.png')
                }
                resizeMode="contain"
                style={{ width: '100%', height: '100%' }}
              />
            </TouchableOpacity>
          );
        },
        enableColumnFilter: false,
      },
      {
        accessorKey: 'form_name',
        header: 'Requestor',
        size: 1,
      },
      {
        accessorKey: 'form_id',
        header: 'Form ID',
        size: 1,
      },
      {
        accessorKey: 'unit_name',
        header: 'Unit',
        size: 1,
      },
      {
        accessorKey: 'request_type',
        header: 'File Type',
        size: 1,
      },
      {
        id: 'timestamp',
        accessorFn: (row) => formatTableDate(row.timestamp[0]),
        header: 'Submission Date',
        size: 1,
      },
      {
        id: 'paid',
        accessorFn: (row) => (row.status == 4 ? 'Unpaid' : 'Paid'),
        header: 'Paid?',
        size: 1,
        Cell: ({ cell, renderedCellValue }) => (
          cell.getValue() != 'Paid' ? (
            <MoreHoriz style={{ color: 'grey', status: 1 }}>{renderedCellValue}</MoreHoriz>
          ) : (
            <Check style={{ color: 'green', status: 0 }} >{renderedCellValue}</Check>
          )),
        filterFn: 'equals',
        filterSelectOptions: [
          { text: 'Paid', value: 'Paid' },
          { text: 'Unpaid', value: 'Unpaid' },
        ],
        filterVariant: 'select',
      },
    ];

    const columnsAfterPayStatus = [
      {
        id: 'payment_date',
        accessorFn: (row) => {
          const hasFilePaid = row.timestamp_string.some((item) => item === 'File Paid');
          return hasFilePaid ? formatTableDate(row.timestamp[2]) : '';
        },
        header: 'Payment Date',
        size: 1,

      },
    ];

    if (hasMarkPermission) {
      columnsBeforePayStatus.push({
        accessorKey: 'pay_status',
        header: 'Pay Status',
        size: 1,
        Cell: ({ row }) => (<><TouchableOpacity
          style={styles.payBtn}
          onPress={() => {
            if (!hasMarkPermission) {
              handleOpenAlert();
            } else {
              setSelectedRows([row.original]);
              setShowConfirmVis(true);
              cur_form_id = row.original.form_id;
            }
          }}>
          <Text style={styles.innerText}>{row.original.status == 4 ? 'Mark as Paid' : 'Mark as Unpaid'}</Text>
        </TouchableOpacity>
          {row.original.status != 5 && row.original.status != 6 && <TouchableOpacity
            style={{ ...styles.payBtn, backgroundColor: '#990000' }}
            onPress={() => {
              if (!hasMarkPermission) {
                handleOpenAlert();
              } else {
                setIsLoading(true);
                setSelectedRows([row.original]);
                setShowDenyConfirmVis(true);
                cur_form_id = row.original.form_id;
                handleGeneratePdf(row.original);
              }
            }}>
            <Text style={{ ...styles.innerText, color: '#FFFFFF' }}>Deny Request</Text>
          </TouchableOpacity>}</>),
        enableColumnFilter: false,
      });
    }

    return columnsBeforePayStatus.concat(columnsAfterPayStatus);
  }, [hasMarkPermission, rows]);

  const loadingStyles = {
    animation: {
      flexDirection: 'row',
      alignItems: 'center',
      flex: 1,
      justifyContent: 'center',
      paddingVertical: 20,
      background: colors.screen_background,
    },
  };

  return (
    <ThemeProvider theme={tableTheme}>
      <View style={{ height: '100%', width: '100%', flexDirection: 'row' }}>
        <SnackbarAlert
          open={openAlert}
          onClose={handleCloseAlert}
          severity="error"
          message="Access Denied: User does not have correct permissions."
          anchorVertical={'bottom'}
          anchorHorizontal={'right'}
          autoHideDuration={5000}
          paddingBottom="2.5%"
          paddingRight="2%"
        />
        {/* <NavSideBar /> */}
        {isLoading ? (
          <View style={loadingStyles.animation}>
            <Progress.CircleSnail
              color={colors.loading_circle}
              indeterminate={true}
              size={275}
              thickness={8}
            />
          </View>
        ) : !hasPermission ? (
          <View style={styles.main}>
            <View style={styles.titleDiv}>
              <Text style={styles.title}>Access Denied: User does not have permission to view this page.</Text>
            </View>
          </View>
        ) : (
          <Scrollbars style={styles.container}>
            <View style={styles.pageTopSection}>
              <View style={{ alignText: 'center', flex: 2.25 }}>

                <View style={styles.titleDiv}>
                  <Text style={styles.title}>
                    <Text style={{ fontWeight: 'bold' }}>Pay Management</Text>
                  </Text>
                </View>

                <Text style={styles.pageInfoText} >
                  This page allows Pay Admins to track a subordinates pay statuses for various different activities.
                  Clicking on the file column will display the form.
                </Text>

                {/* aqui */}
                <View style={styles.filtersContainer}>
                  <View style={styles.filtersRow}>
                    <View style={styles.unitsFilter}>
                      <FormControl style={styles.filtersTextBox}>
                        <InputLabel id="tables-units-label">Units Filter</InputLabel>
                        <Select
                          multiple
                          value={unitType}
                          input={
                            <OutlinedInput
                              label="Units Filter"
                              sx={{

                                '& .MuiOutlinedInput-notchedOutline': {
                                  borderColor: colors.border_color,
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                  borderColor: colors.border_color,
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                  borderColor: colors.border_color,
                                },
                                '& .MuiSelect-icon': {
                                  color: colors.inputted_text,
                                },
                                '& .MuiInputBase-input': {
                                  color: colors.inputted_text,
                                },
                                '& .MuiFormLabel-root': {
                                  color: colors.inputted_text,
                                },
                                '& .MuiFormLabel-root.Mui-focused': {
                                  color: colors.inputted_text,
                                },
                                '& .MuiFormHelperText-root': {
                                  backgroundColor: colors.screen_background,
                                  margin: 0,
                                  paddingLeft: 1,
                                },
                              }}
                            />
                          }
                          onChange={(value) => {
                            handleUnitFilter(value.target.value);
                          }}
                          MenuProps={{
                            style: { zIndex: 1500 },
                          }}
                          renderValue={(selected) => selected.join(', ')}
                        >
                          {unitSelection.map((option) => (
                            <MenuItem style={{ whiteSpace: 'pre' }} key={option.label} value={option.label}>
                              <ListItemText
                                primaryTypographyProps={{ style: styles.listItemTextTypography }}
                                primary={option.label}
                              />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </View>
                    <View style={styles.unitsFilter}>
                      <FormControl style={styles.filtersTextBox}>
                        <InputLabel id="tables-units-label">Status Filter</InputLabel>
                        <Select
                          multiple
                          value={statusType}
                          input={
                            <OutlinedInput
                              label="Status Filter"
                              sx={{
                                '& .MuiOutlinedInput-notchedOutline': {
                                  borderColor: colors.border_color,
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                  borderColor: colors.border_color,
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                  borderColor: colors.border_color,
                                },
                                '& .MuiSelect-icon': {
                                  color: colors.inputted_text,
                                },
                                '& .MuiInputBase-input': {
                                  color: colors.inputted_text,
                                },
                                '& .MuiFormLabel-root': {
                                  color: colors.inputted_text,
                                },
                                '& .MuiFormLabel-root.Mui-focused': {
                                  color: colors.inputted_text,
                                },
                                '& .MuiFormHelperText-root': {
                                  backgroundColor: colors.screen_background,
                                  margin: 0,
                                  paddingLeft: 1,
                                },
                              }}
                            />
                          }
                          onChange={(value) => {
                            handleStatusFilter(value.target.value);
                          }}
                          MenuProps={{
                            style: { zIndex: 1500 },
                          }}
                          renderValue={(selected) => selected.join(', ')}
                        >
                          {StatusTypes.map((option) => (
                            <MenuItem style={{ whiteSpace: 'pre' }} key={option.label} value={option.label}>
                              <ListItemText
                                primaryTypographyProps={{ style: styles.listItemTextTypography }}
                                primary={option.label}
                              />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </View>
                  </View>
                  <View style={styles.dateRangePickerContainer}>
                    <DateRangePicker
                      size="lg"
                      format="MM/dd/yyyy"
                      character=" - "
                      placeholder="Select Date Range"
                      ranges={predefinedRanges}
                      className={`custom-daterange-picker ${isLight ? 'light-mode' : 'dark-mode'}`}
                      onChange={(dates) => handleDateRangeChange(dates)}
                      placement='autoVerticalStart'
                    />
                  </View>
                </View>
              </View>

              <View style={{ flexDirection: 'row', flex: .25 }}>
                <View />
              </View>

              {/* aqui */}
              <View style={styles.legendContainer}>
                <View style={styles.chartButtons}>
                  <ChartSelectorButtons />
                </View>
                <LegendItem pieSelection={pieSelection} pieColorSelection={pieColorSelection} />
              </View>
            </View>

            <View style={styles.tableContainer}>
              <ThemeProvider theme={tableTheme}>
                <MaterialReactTable
                  columns={columns}
                  data={rows}
                  enableRowSelection={hasMarkPermission}
                  positionToolbarAlertBanner={'bottom'}
                  tableInstanceRef={tableInstanceRef}
                  enableGrouping
                  enableColumnDragging={false}
                  enableStickyHeader
                  enableExpandAll={false}
                  selectAllMode={'all'}
                  isMultiSortEvent={() => true}

                  initialState={{
                    showGlobalFilter: false,
                    columnOrder: hasMarkPermission === false ? ['file', 'form_id', 'form_name', 'unit_name', 'request_type', 'timestamp', 'paid', 'payment_date'] : ['mrt-row-select', 'file', 'form_id', 'form_name', 'unit_name', 'request_type', 'timestamp', 'paid', 'pay_status', 'payment_date'],
                    pagination: { pageIndex: 0, pageSize: 50 },
                  }}
                  muiTableBodyProps={{
                    sx: (theme) => ({
                      'fontSize': 20,
                      '& tr:nth-of-type(n)': {
                        backgroundColor: colors.table_background_color,
                      },
                      '& tr:nth-of-type(2n-1)': {
                        backgroundColor: darken(theme.palette.background.default, 0.075),
                      },
                      '& tr.Mui-selected': {
                        backgroundColor: colors.table_selected,
                      },
                      '& tr:hover:not(.Mui-selected) > td': {
                        backgroundColor: colors.table_hover,
                      },
                    }),
                  }}

                  // Table State Management for updater function
                  onColumnVisibilityChange={(updater) => {
                    setColumnVisibility((prev) =>
                      updater instanceof Function ? updater(prev) : updater,
                    );
                    queueMicrotask(rerender); // Rerender after state update
                  }}

                  onRowSelectionChange={(updater) => {
                    setRowSelection((prev) =>
                      updater instanceof Function ? updater(prev) : updater,
                    );
                    queueMicrotask(rerender); // Rerender after state update
                  }}

                  onPaginationChange={(updater) => {
                    setPagination((prev) =>
                      updater instanceof Function ? updater(prev) : updater,
                    );
                    queueMicrotask(rerender); // Rerender after state update
                  }}

                  onShowColumnFiltersChange={(updater) => {
                    setShowColumnFilters((prev) =>
                      updater instanceof Function ? updater(prev) : updater,
                    );
                    queueMicrotask(rerender); // Rerender after state update
                  }}

                  onShowGlobalFilterChange={(updater) => {
                    setShowGlobalFilter((prev) =>
                      updater instanceof Function ? updater(prev) : updater,
                    );
                    queueMicrotask(rerender); // Rerender after state update
                  }}

                  state={{
                    columnVisibility,
                    density,
                    rowSelection,
                    pagination,
                    showColumnFilters,
                    showGlobalFilter,
                  }}

                  renderToolbarInternalActions={({ table }) => (
                    <>
                      <Box>
                        <MRT_ToggleGlobalFilterButton table={tableInstanceRef.current} />

                        <Tooltip arrow title="Export Table">
                          <IconButton onClick={handleExportData}
                          >
                            <DownloadIcon />
                          </IconButton>
                        </Tooltip>

                        <MRT_ToggleFiltersButton table={tableInstanceRef.current} />
                        <MRT_ShowHideColumnsButton table={tableInstanceRef.current} />
                        <MRT_FullScreenToggleButton table={tableInstanceRef.current} />

                      </Box>
                    </>
                  )}

                  renderTopToolbarCustomActions={({ table }) => (

                    <Box
                      sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                    >
                      <Modal
                        isVisible={showConfirmVis}
                        style={{
                          alignSelf: 'center',
                          margin: 0,
                          width: '30%',
                        }}
                      >
                        {selectedRows && (
                          <View style={{
                            backgroundColor: colors.screen_background,
                            alignItems: 'center',
                            borderRadius: 10,
                            flexDirection: 'column',
                            paddingHorizontal: '5%',
                          }}>
                            <View style={{ height: 100, alignItems: 'center', marginTop: 50 }}>
                              <Text style={{ fontSize: 25, fontFamily: 'Trebuchet MS', textAlign: 'center', color: colors.text }}>
                                {selectedRows && selectedRows.length > 1 ? (
                                  'Are you sure you want to mark the selected forms as paid/unpaid?'
                                ) : (
                                  `Are you sure you want to mark Form with ID ${selectedRows[0].form_id} as paid/unpaid?`
                                )}
                              </Text>
                            </View>

                            <View style={{
                              flexDirection: 'row',
                              width: '100%',
                              justifyContent: 'space-around',
                            }}>
                              <TouchableOpacity
                                style={{
                                  width: '20%',
                                  borderRadius: '40',
                                  alignItems: 'center',
                                  backgroundColor: colors.button,
                                  borderRadius: 15,
                                  shadowColor: '#171717',
                                  shadowOffset: { width: -2, height: 4 },
                                  shadowOpacity: 0.2,
                                  shadowRadius: 3,
                                  marginBottom: '5%',
                                }}
                                onPress={() =>
                                  setShowConfirmVis(false)}>
                                <Text style={styles.innerText}>Cancel</Text>
                              </TouchableOpacity>

                              <TouchableOpacity
                                style={{
                                  width: '20%',
                                  borderRadius: '40',
                                  alignItems: 'center',
                                  backgroundColor: colors.button,
                                  borderRadius: 15,
                                  shadowColor: '#171717',
                                  shadowOffset: { width: -2, height: 4 },
                                  shadowOpacity: 0.2,
                                  shadowRadius: 3,
                                  marginBottom: '5%',
                                }}
                                onPress={() => {
                                  change_status(selectedRows);
                                  setSelectedRows(undefined);
                                  table.setRowSelection([]);
                                  setShowConfirmVis(false);
                                }}>
                                <Text style={styles.innerText}>Confirm</Text>
                              </TouchableOpacity>
                            </View>
                          </View>
                        )}
                      </Modal>
                      <Modal
                        isVisible={showDenyConfirmVis && !isLoading}
                        style={{
                          alignSelf: 'center',
                          margin: 0,
                          width: '90%',
                          height: '90%',
                          zIndex: 10
                        }}
                      >
                        <View style={styles.mainModalContainer}>
                          {selectedRows ? (
                            <View style={styles.titleContainer}>
                              <Text style={styles.titleText}>
                                {selectedRows.length === 1 && `Deny Form ${selectedRows[0].form_id}?`}
                              </Text>
                            </View>
                          ) : null}

                          <View style={styles.pdfContainer}>
                            {pdfBase64 && (
                              <iframe
                                src={`data:application/pdf;base64,${pdfBase64}`}
                                width="100%"
                                height="100%"
                                type="application/pdf"
                              />
                            )}
                          </View>
                          {selectedRows ? (
                            !doesUserHaveSignature ? (
                              <View style={styles.signatureContainer}>
                                <View style={styles.textContainer}>
                                  <Text style={styles.headerText}>Create Your Signature</Text>
                                </View>

                                <View style={styles.denyContainer}>
                                  <View style={styles.inputImageContainer}>
                                    <View style={styles.signatureInputContainer}>
                                      <TextInput
                                        style={[styles.input, { height: Math.max(64, height) }]}
                                        theme={{
                                          colors: {
                                            text: colors.inputted_text,
                                            accent: colors.textfield_background,
                                            primary: colors.inputted_text,
                                            placeholder: colors.placeholder_text,
                                          },
                                        }}
                                        label="Enter your first and last name"
                                        placeholder="John Doe"
                                        value={signatureName}
                                        onChange={(data) => setSignatureName(data.target.value)}
                                      />
                                    </View>

                                    <View style={styles.signatureBoxContainer}>
                                      <View style={styles.signatureBox}>
                                        {signatureName ? (
                                          <ViewShot
                                            ref={viewRef}
                                            style={styles.signaturePreview}
                                            options={{ format: "png", quality: 1 }}
                                          >
                                            <Text style={styles.signatureText}>{signatureName}</Text>
                                          </ViewShot>
                                        ) : (
                                          <Text style={styles.noSignatureText}>No signature found</Text>
                                        )}
                                      </View>
                                    </View>
                                  </View>

                                  <View style={styles.buttonContainer}>
                                    <TouchableOpacity
                                      style={[styles.contentButton, styles.cancelButton]}
                                      onPress={() => {
                                        setShowDenyConfirmVis(false);
                                        setSignatureName('');
                                        setDidUserSignDocument(false);
                                      }}
                                    >
                                      <Text style={styles.buttonText}>Cancel</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity
                                      style={styles.createButton}
                                      onPress={captureTextAsImage}
                                    >
                                      <Text style={styles.buttonText}>Create Signature</Text>
                                    </TouchableOpacity>
                                  </View>

                                </View>
                              </View>
                            ) : (
                              <View style={styles.contentContainer}>
                                <View style={styles.headerContainer}>
                                  <Text style={styles.headerText}>Pay Admin's Comments</Text>
                                </View>

                                <View style={styles.denyContainer}>
                                  <View style={styles.inputImageContainer}>
                                    <View style={styles.commentInputContainer}>
                                      <TextInput
                                        style={[styles.commentInput, { height: Math.max(64, height), borderColor: emptyComment ? colors.file_Denied : colors.border_color }]}
                                        theme={{
                                          colors: {
                                            text: emptyComment ? colors.file_Denied : colors.inputted_text,
                                            accent: emptyComment ? colors.file_Denied : colors.textfield_background,
                                            primary: emptyComment ? colors.file_Denied : colors.inputted_text,
                                            placeholder: emptyComment ? colors.file_Denied : colors.placeholder_text,
                                          },
                                        }}
                                        outlineColor={emptyComment ? colors.file_Denied : colors.textfield_background}
                                        activeUnderlineColor={emptyComment ? colors.file_Denied : colors.bow}
                                        placeholderTextColor={emptyComment ? colors.file_Denied : colors.text}
                                        selectionColor={emptyComment ? colors.file_Denied : colors.text}
                                        activeOutlineColor={emptyComment ? colors.file_Denied : colors.text}
                                        InputProps={{ style: { color: emptyComment ? colors.file_Denied : colors.text } }}
                                        label={emptyComment ? 'Please provide a comment for the denial' : "Pay Admin Comment"}
                                        placeholder='Explain why you denied the request*'
                                        inputStyle={{ color: emptyComment ? colors.file_Denied : colors.bow }}
                                        value={comment}
                                        multiline={true}
                                        onChangeText={(text) => {
                                          setComment(text);
                                        }}
                                        onContentSizeChange={(event) => {
                                          setHeight(event.nativeEvent.contentSize.height)
                                        }}
                                        onBlur={() => {
                                          setEmptyComment(comment === '')
                                        }}
                                        error={emptyComment}
                                      />
                                    </View>


                                    {viewSig ? (
                                      <View style={styles.imageContainer}>
                                        <View style={styles.imageBox}>
                                          <Image
                                            resizeMode="contain"
                                            style={styles.signatureImage}
                                            source={{ uri: global.signature_uri }}
                                          />
                                        </View>
                                      </View>
                                    ) : null}
                                  </View>
                                  <View style={styles.buttonContainer}>
                                    <TouchableOpacity
                                      style={[styles.contentButton, styles.cancelButton]}
                                      onPress={() => {
                                        setShowDenyConfirmVis(false);
                                        setComment('');
                                        setViewSig(false);
                                        setDidUserSignDocument(false);
                                      }}
                                    >
                                      <Text style={styles.buttonText}>Cancel</Text>
                                    </TouchableOpacity>
                                    {!didUserSignDocument ? (
                                      <TouchableOpacity
                                        style={[styles.contentButton, styles.signButton]}
                                        onPress={handleSignature}
                                      >
                                        <Text style={[styles.buttonText, { color: colors.text }]}>Sign Document</Text>
                                      </TouchableOpacity>
                                    ) : (
                                      <TouchableOpacity
                                        style={[styles.contentButton, styles.denyButton]}
                                        onPress={() => {
                                          if (emptyComment || comment === '') {
                                            showSnackbar("error", "Please provide a comment before submitting")
                                          } else {
                                            deny_status(selectedRows);
                                            setSelectedRows(undefined);
                                            table.setRowSelection([]);
                                            setShowDenyConfirmVis(false);
                                            setViewSig(false);
                                            setDidUserSignDocument(false);
                                          }
                                        }}
                                      >
                                        <Text style={[styles.buttonText, { color: colors.text }]}>Deny Form</Text>
                                      </TouchableOpacity>
                                    )}

                                  </View>
                                </View>
                              </View>
                            )
                          ) : null}
                        </View>

                      </Modal>
                      {
                        hasMarkPermission && (
                          <Button
                            disabled={table.getSelectedRowModel().rows.length === 0}
                            onClick={() => {
                              setSelectedRows(table.getSelectedRowModel().rows.map((row) => row.original));
                              setShowConfirmVis(true);
                            }}
                            startIcon={<Check />}
                            variant="contained"
                            style={{
                              color: table.getSelectedRowModel().rows.length === 0 ?
                                colors.new_table_color :
                                colors.new_table_color_selected,
                              backgroundColor: table.getSelectedRowModel().rows.length === 0 ?
                                colors.new_table_bg_color :
                                colors.new_table_bg_color_selected,
                            }}
                          >
                            Mark as paid/unpaid
                          </Button>
                        )
                      }

                    </Box>
                  )}
                />
              </ThemeProvider>
            </View>
          </Scrollbars>
        )}
      </View>
    </ThemeProvider>
  );
};

export default PayManagement;
