import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { useColorContext } from '../ColorContext';

const colorDots = {
  Mandatory: 'red',
  Optional: 'green',
  Personal: 'blue',
};

export default function Labels({ state, setState }) {
  const { colors } = useColorContext();

  const styles = {
    hText: {
      fontWeight: 'bold',
      marginTop: 10,
      fontSize: 30,
      color: colors.screen_title,
    },
    lText: {
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 23, 
      padding: '2%',
      color: colors.screen_title,
    },
    labelContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: 3,
    },
    checkboxContainer: {
      width: 24,
      height: 24,
      borderRadius: 12,
      borderWidth: 1,
      borderColor: 'gray',
      marginRight: 10,
      alignItems: 'center',
      justifyContent: 'center',
    },
    checkbox: {
      width: '100%',
      height: '100%',
      borderRadius: 9, // Make the checkbox round to create circles
      alignItems: 'center',
      justifyContent: 'center',
    },
    checkmark: {
      width: '50%',
      height: '50%',
      backgroundColor: 'white',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 5, // Make the checkmark round to create circles
    },
  };

  const handleCheckboxClick = (key) => {
    const updatedLabels = { ...state.labels };
    updatedLabels[key] = !updatedLabels[key];
    setState({ ...state, labels: updatedLabels });
  };

  return (
    <View>
      <Text style={styles.hText}>Filter Events</Text>
      {Object.keys(state.labels).map((key, idx) => (
        <TouchableOpacity
          key={idx}
          style={styles.labelContainer}
          onPress={() => {
            handleCheckboxClick(key);
          }}
        >
          <View style={styles.checkboxContainer}>
            <View
              style={[
                styles.checkbox,
                { backgroundColor: colorDots[key] },
              ]}
            >
              {state.labels[key] && <View style={styles.checkmark} />}
            </View>
          </View>
          <Text style={styles.lText}>{key}</Text>
        </TouchableOpacity>
      ))}
    </View>
  );
}
