import React, {
  useEffect,
  useState,
  useRef,
  useReducer,
  useContext,
  useMemo,
} from "react";
import { FlatList, View, Text, Button, TouchableOpacity } from "react-native";
import { NavigationContainer, useIsFocused } from "@react-navigation/native";
import {
  MaterialReactTable,
  MRT_FullScreenToggleButton,
  MRT_ToggleGlobalFilterButton,
  MRT_ShowHideColumnsButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
} from "material-react-table";
import colors from "../../colors";
import { useColorContext } from '../../components/ColorContext';
import { useScale } from "../../components/scale";
import ModeContext from '../../components/ModeContext';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';

import { IconButton } from "@mui/material";
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
} from "@mui/icons-material";
import { Card } from "react-native-paper";
import {
  PieChart,
  Pie,
  Cell,
  Legend,
  Tooltip,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import DropDownPicker from "react-native-dropdown-picker";
import Modal from "react-native-modal";
import { ScrollView } from "react-native-gesture-handler";
import {
  darken,
  createTheme,
  ThemeProvider,
  useTheme,
} from "@mui/material/styles";
import Scrollbars from "react-custom-scrollbars";
import { Box } from '@mui/material';

const LeaderBudgetRST = ({ rstData, rstLineData, num_subs, unit_info }) => {
  const { color } = useColorContext();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const { mode, toggleMode } = useContext(ModeContext);
  const scale = useScale();
  const styles = createStyles(scale);
  const isFocused = useIsFocused();

  const [soldierData, setSoldierData] = useState(rstData);
  const [filteredData, setFilteredData] = useState(rstData);
  // unit drop down menu
  const [open, setOpen] = useState(false);
  const [unitValue, setUnitValue] = useState("All Units");
  const [unitSelection, setUnitSelection] = useState(unit_info);

  const colorsLight = ["#ab581a", "#184697"];
  const colorsDark = ["#00B0FF", "#FF4081"];
  const totalMuta = () => {
    let muta = 0;
    for (let i = 0; i < rstData.length; i++) {
      muta += rstData[i]["muta"];
    }
    return muta;
  };
  const [pieSelection, setPieSelection] = useState([
    {
      name: "RSTs logged",
      value: totalMuta(),
    },
    {
      name: "Total RSTs Permissable",
      value: 48 * num_subs,
    },
  ]);
  const [pieColorSelection, setpieColors] = useState(
    mode === "light" ? colorsLight : colorsDark
  );
  const [lineSelection, setlineSelection] = useState(rstLineData);
  const [activeChartButton, setActiveChartButton] = useState(0);
  const [activeDateButton, setActiveDateButton] = useState("YTD");

  const [data, setRstData] = useState(rstData);
  const [linedata, setRstLineData] = useState(rstLineData);

  const tableInstanceRef = useRef(null);
  // filter modal
  const [modalVisible, setModalVisible] = useState(false);
  const [modalFilters, setModalFilters] = useState([]);
  const rerender = useReducer(() => ({}), {})[1]; // Forces the MRT_ components to re-render
  const [columnVisibility, setColumnVisibility] = useState({});
  const [density, setDensity] = useState("compact");
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 15 });
  const [rowSelection, setRowSelection] = useState({});
  const [showColumnFilters, setShowColumnFilters] = useState(false);
  const [showGlobalFilter, setShowGlobalFilter] = useState(false);

  // Create theme styling for table
  const globalTheme = useTheme();
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: mode,
          primary: globalTheme.palette.primary,
          background: {
            default:
              mode === "light"
                ? colors.light_mode_textfield_background
                : colors.dark_mode_textfield_background,
          },
        },
      }),
    [globalTheme, isFocused]
  );

  const columns = [
    {
      accessorKey: "full_name",
      header: "Name",
      size: 1,
    },
    {
      accessorKey: "branch",
      header: "Branch",
      size: 1,
    },
    {
      accessorKey: "rank",
      header: "Rank",
      size: 1,
    },
    {
      accessorKey: "grade",
      header: "Grade",
      size: 1,
    },
    {
      accessorKey: "unit_name",
      header: "Unit",
      size: 1,
    },
    {
      accessorKey: "muta",
      header: "Muta",
      size: 1,
    },
    {
      accessorKey: "dollars_spent",
      header: "Dollar",
      size: 1,
    },
  ];
  const chartButtons = [
    {
      label: "RST",
      // data: aftpData,
      // colors: mode === "light" ? aftpColors : aftpColorsDark,
      // lineData: aftpLineData,
      index: 0,
      pieSelector: pieSelector,
    },
  ];
  const dateButtons = [
    {
      label: "1M",
      value: 1,
      index: 1,
      dateSelector: dateSelector,
    },
    {
      label: "3M",
      value: 3,
      index: 3,
      dateSelector: dateSelector,
    },
    {
      label: "6M",
      value: 6,
      index: 6,
      dateSelector: dateSelector,
    },
    {
      label: "YTD",
      value: "YTD",
      index: "YTD",
      dateSelector: dateSelector,
    },
  ];

  useEffect(() => {
    if (unitValue == "All Units") {
      setFilteredData(soldierData);
    } else {
      setFilteredData(
        soldierData.filter((soldier) => soldier.unit_name === unitValue)
      );
    }
  }, [unitValue]);

  const renderModal = (modalFilters) => {
    return (
      <View style={styles.modal}>
        <Button onPress={toggleModal} title="Close"></Button>
        <FlatList
          numColumns={"2"}
          data={modalFilters}
          renderItem={({ item }) => (
            <Card style={{ margin: 5 }}>
              <View
                style={{
                  padding: 10,
                  margin: 5,
                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    flex: 1,
                    justifyContent: "flex-start",
                  }}
                >
                  <Text style={{ alignSelf: "center" }}>{item.label}</Text>
                  <IconButton onClick={() => filters(item.id)}>
                    {item.isChecked ? (
                      <CheckBoxIcon />
                    ) : (
                      <CheckBoxOutlineBlankIcon />
                    )}
                  </IconButton>
                </View>
              </View>
            </Card>
          )}
        />
        <Button title="Apply Filter" style={{ padding: "25" }}></Button>
      </View>
    );
  };

  // function pieSelector(data, color, linedata, index) {
  function pieSelector(index) {
    // setpieSelection(data);
    // setpieColors(color);
    setActiveChartButton(index);
    // setlineSelection(linedata);
  }

  function dateSelector(index) {
    setActiveDateButton(index);
  }

  async function refreshList() {
    // global.revFiles = await
    // setRefresh(true);
    // setRefresh(false);
  }

  function toggleModal() {
    setModalVisible(!modalVisible);
  }

  const filters = (id) => {
    let temp = modalFilters.map((item) => {
      if (item.id === id) {
        return { ...item, isChecked: !item.isChecked };
      }
      return item;
    });
    setModalFilters(temp);
  };

  // DOESM'T REALLY WORK!!!!!!!!!!!!! at least the moving part maybe we should just show most recent rather than 0 index
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const dataPoint = payload[0].payload;
      const usagePercentage =
        (dataPoint["Dollars Spent"] / dataPoint["95% Dollar Usage Target"]) *
        100;
      const balance =
        dataPoint["95% Dollar Usage Target"] - dataPoint["Dollars Spent"];

      return (
        <View
          style={{
            backgroundColor: colors.white,
            padding: 10,
            borderRadius: 25,
          }}
        >
          <Text>{`95% Target: ${dataPoint["95% Dollar Usage Target"]}`}</Text>
          <Text>{`Spent: ${dataPoint["Dollars Spent"]}`}</Text>
          <Text>{`Balance: ${balance}`}</Text>
          <Text>{`Percentage: ${usagePercentage.toFixed(2)}%`}</Text>
        </View>
      );
    }

    return null;
  };

  //let selected = modalFilters.filter((modalFilters) => modalFilters.isChecked);

  // figure out how to have two charts be in responsive containers witout being cut off
  // view wrap around responsive = nothing shows
  // responsive wrap view = not responsive
  // change font to match
  // maybe instead of time we do % comparison like 60% spending
  const LegendItem = () => {
    const dataKeys = ["95% Dollar Usage Target", "Dollars Spent"];

    return (
      <View style={styles.pieChart}>
        <ResponsiveContainer width="100%" height={275}>
          <PieChart>
            <Tooltip />
            <Pie
              data={pieSelection}
              outerRadius={'80%'}
              innerRadius={"35%"}
              dataKey="value"
              nameKey="name"
            >
              {Object.values(pieSelection).map((entry, index) => (
                <Cell key={`cell-${index}`} fill={pieColorSelection[index]} />
              ))}
            </Pie>
            <Legend style={{ fontFamily: 'Trebuchet MS' }} />
          </PieChart>
        </ResponsiveContainer>
        <View style={{ flexDirection: "column", flex: 0.25 }} />
        <Text
          style={{
            margin: 15,
            marginTop: 25,
            fontSize: 18,
            textAlign: "center",
            color: mode === "light" ? colors.black : colors.white,
          }}
        >
          Dollar Usage Per Month{"\n"}2023-2024
        </Text>
        <ResponsiveContainer width={475} height={275}>
          <LineChart
            width="98%"
            height="98%"
            data={lineSelection}
            style={{ fontFamily: 'Trebuchet MS' }}
          >
            <XAxis
              dataKey="month"
              interval={0}
              tickCount={4}
              style={{
                fontFamily: "Arial-BoldMT",
                fill: mode === "light" ? colors.black : colors.white,
              }}
            />
            <YAxis
              tick={{
                fill: mode === "light" ? colors.black : colors.white,
              }}
            />
            <Tooltip content={<CustomTooltip />} />
            <CartesianGrid strokeDasharray="5 5" />
            <Legend style={{ fontFamily: 'Trebuchet MS' }} />
            {dataKeys.map((dataKey, index) => (
              <Line
                key={dataKey}
                type="monotone"
                dataKey={dataKey}
                stroke={pieColorSelection[index]}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </View>
    );
  };

  return (
    <View style={styles.mainContainer}>
      <View style={{ alignText: "center", flex: 3, flexDirection: "column" }}>
        <Text
          style={
            mode === "light" ? styles.pageTitleLight : styles.pageTitleDark
          }
        >
          Budget
        </Text>

        <Text
          style={{
            ...styles.pageDescription,
            color: mode === "light" ? colors.black : colors.white,
            marginTop: "2%"
          }}
        >
          Outlines information regarding budgeting and funding types throughout
          the fiscal year (FY). Visualize expenditure, colors of money, and
          pay-code types, including After Flight Training Periods (AFTP),
          Readiness Management Assemblies (RMA), MDR, and Additional Training
          Assemblies (ATA).
        </Text>

        <View style={styles.unitsFilter}>
          <FormControl style={{ width: '66%', backgroundColor: mode === "light" ? colors.light_mode_textfield_background : colors.dark_mode_textfield_background, }}>
            <InputLabel id="tables-units-label">Units Filter</InputLabel>
            <Select
              value={unitValue}
              input={<OutlinedInput label="Units Filter" />}
              onChange={(value) => {
                setUnitValue(value.target.value);
              }}
            >
              {unitSelection.map((option) => (
                <MenuItem style={{ whiteSpace: "pre" }} key={option.label} value={option.label}>
                  <ListItemText primaryTypographyProps={{ style: styles.listItemTextTypography }} primary={option.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </View>
        <Text
          style={{
            fontSize: 12,
            marginHorizontal: 5,
            textAlign: "Left",
            paddingTop: 5,
            color: mode === "light" ? colors.black : colors.white,
          }}
        >
          Statuses are displayed for above selected Unit.
        </Text>
        <View style={mode === "light" ? styles.lightList : styles.darkList}>
          <View style={styles.tableContainer}>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                columns={columns}
                data={filteredData}
                tableInstanceRef={tableInstanceRef}
                enableFullScreenToggle={true}
                renderToolbarInternalActions={({ table }) => (
                  <>
                    <Box>
                      <MRT_ToggleGlobalFilterButton table={tableInstanceRef.current} />
                      <MRT_ToggleFiltersButton table={tableInstanceRef.current} />
                      <MRT_ShowHideColumnsButton table={tableInstanceRef.current} />
                      <MRT_FullScreenToggleButton table={tableInstanceRef.current} onClickCapture={() => { setIsFullScreen(!isFullScreen) }} />
                    </Box>
                  </>
                )}
              />
            </ThemeProvider>
          </View>
        </View>
      </View>

      <View style={{ flexDirection: "row", flex: 0.25 }} />

      {!isFullScreen && <View
        style={
          mode === "light"
            ? styles.legendContainerLight
            : styles.legendContainerDark
        }
      >
        <LegendItem pieData={pieSelection} pieColor={pieColorSelection} />
      </View>}
    </View>

  );
};

const createStyles = (scale) => ({
  scrollViewLight: {
    backgroundColor: colors.light_mode_screen_background,
  },
  scrollViewDark: {
    backgroundColor: colors.dark_mode_screen_background,
  },
  containerLight: {
    flex: 1,
    backgroundColor: colors.light_mode_screen_background,
  },
  containerDark: {
    flex: 1,
    backgroundColor: colors.dark_mode_screen_background,
  },
  pdf: {
    width: 500,
    height: 1000,
  },
  lightList: {
    backgroundColor: colors.leader_dash_light_gray,
    borderRadius: 20,
    marginTop: 15,
    marginBottom: 15,
  },
  darkList: {
    backgroundColor: colors.leader_dash_very_dark_gray,
    borderRadius: 20,
    marginTop: 15,
    marginBottom: 15,
  },
  title: {
    fontSize: scale(50),
  },
  pageTitleLight: {
    fontSize: 30,
    fontWeight: "bold",
    marginHorizontal: 5,
    textAlign: "Left",
    paddingTop: 25,
    color: "#000",
  },
  pageTitleDark: {
    fontSize: 30,
    fontWeight: "bold",
    marginHorizontal: 5,
    textAlign: "Left",
    paddingTop: 25,
    color: "#FFF",
  },
  button: {
    marginTop: 2,
  },
  header: {
    height: "15%",
    // marginTop: "0%",
    // height: 110,
  },
  main: {
    marginTop: "1%",
    height: "85%",
    alignItems: "center",
  },
  tableHeader: {
    flexDirection: "column",
    justifyContent: "content",
    position: "sticky",
    top: 0,
    // zIndex: 999,
    width: "99%",
  },
  searchBarContainerLight: {
    backgroundColor: colors.light_mode_textfield_background,
    flexDirection: "row",
    flex: 1,
    height: 40,
    paddingVertical: 10,
    margin: 5,
    borderRadius: 20,
    borderBottomWidth: 1,
    borderColor: "#ECECEC",
    alignItems: "center",
    shadowColor: "#ECECEC",
    shadowOffset: { width: 3, height: 3 },
    shadowOpacity: 0.3,
    shadowRadius: 8,
  },
  searchBarContainerDark: {
    backgroundColor: colors.dark_mode_textfield_background,
    flexDirection: "row",
    flex: 1,
    height: 40,
    paddingVertical: 10,
    margin: 5,
    borderRadius: 20,
    borderBottomWidth: 1,
    borderColor: "#ECECEC",
    alignItems: "center",
    shadowColor: "#ECECEC",
    shadowOffset: { width: 3, height: 3 },
    shadowOpacity: 0.3,
    shadowRadius: 8,
  },
  iconsLight: {
    padding: 10,
    color: colors.black,
  },
  iconsDark: {
    padding: 10,
    color: colors.white,
  },
  mainContainer: {
    flexDirection: "row",
    // zIndex: 5,
    width: "90%",
    alignSelf: "center",
    justifyContent: "flex-start",
  },
  modal: {
    backgroundColor: "white",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  searchBarLight: {
    height: 40,
    borderRadius: 20,
    textAlign: "left",
    flex: 1,
    padding: 10,
    outlineStyle: "none",
    color: colors.black,
  },
  searchBarDark: {
    height: 40,
    borderRadius: 20,
    textAlign: "left",
    flex: 1,
    padding: 10,
    outlineStyle: "none",
    color: colors.white,
  },
  headerLabelsTime: {
    flex: 1.5,
    justifyContent: "content",
    fontSize: 18,
    textAlign: "center",
  },
  headerLabelsLight: {
    flex: 1,
    justifyContent: "content",
    fontSize: 15,
    textAlign: "center",
    color: colors.black,
  },
  headerLabelsDark: {
    flex: 1,
    justifyContent: "content",
    fontSize: 15,
    textAlign: "center",
    color: colors.white,
  },
  itemLight: {
    backgroundColor: colors.light_mode_textfield_background,
    flex: 1,
    flexDirection: "row",
    backgroundColor: "white",
    paddingVertical: 20,
    marginVertical: 8,
    marginHorizontal: 5,
    borderRadius: 20,
    shadowColor: "#000",
    shadowOffset: { width: 3, height: 3 },
    shadowOpacity: 0.3,
    shadowRadius: 8,
    alignItems: "center",
    textAlign: "center",
  },
  itemDark: {
    flexDirection: "row",
    backgroundColor: colors.dark_mode_textfield_background,
    paddingVertical: 20,
    marginVertical: 8,
    marginHorizontal: 5,
    borderRadius: 20,
    shadowColor: "#000",
    shadowOffset: { width: 3, height: 3 },
    shadowOpacity: 0.3,
    shadowRadius: 8,
    alignItems: "center",
    textAlign: "center",
  },
  itemDetailLight: {
    color: colors.black,
    flex: 1,
  },
  itemDetailDark: {
    color: colors.white,
    flex: 1,
  },
  doc: {
    margin: 15,
    width: 40,
    height: 40,
  },
  chartSelector: {
    flexDirection: "row",
    flex: 1,
    justifyContent: "space-evenly",
    textAlign: "center",
    borderBottomWidth: 2,
    borderColor: "#D9D9D9",
    margin: 25,
  },
  dateSelector: {
    alignSelf: "center",
    width: "80%",
    flexDirection: "row",
    flex: 1,
    justifyContent: "space-evenly",
    textAlign: "center",
    margin: 25,
    marginTop: 0,
  },
  dateButtonPressed: {
    borderRadius: 20,
    backgroundColor: "#A98C42",
  },
  pageDescription: {
    fontSize: 18,
    marginHorizontal: 5,
    textAlign: "Left",
    marginTop: "10%",
  },
  pieChart: {
    flexDirection: "column",
    flex: 3,
    alignItems: "center",
    fontFamily: "Trebuchet MS",
    fontWeight: "bold",
    fontSize: 14,
  },
  pieButton: {
    flex: 0.25,
  },
  pieButtonPressed: {
    borderBottomWidth: 4,
    borderColor: "#A98C42",
  },
  legendContainerLight: {
    backgroundColor: colors.leader_dash_light_gray,
    borderRadius: 20,
    flex: 3,
    flexDirection: "column",
    alignItems: "center",
    margin: 10,
  },
  legendContainerDark: {
    backgroundColor: colors.leader_dash_very_dark_gray,
    borderRadius: 20,
    flex: 3,
    flexDirection: "column",
    alignItems: "center",
    margin: 10,
  },
  legend: {
    color: "black",
    alignItems: "left",
  },
  allButton: {
    flexDirection: "row",
    height: 30,
    width: "30%",
    marginLeft: "25%",
  },
  appButton: {
    flexDirection: "row",
    height: 30,
    width: "30%",
    marginLeft: "25%",
  },
  filterAllText: {
    fontSize: 15,
    marginTop: 10,
  },
  filterAppText: {
    fontSize: 15,
    marginTop: 10,
  },
  tableContainer: {
    height: "98%",
  },
  textInputProps: {
    '& .MuiFormLabel-root, & label.Mui-focused, & .MuiInputBase-input': {
      color: colors.inputted_text,
      // WebkitTextFillColor:  colors.inputted_text ,
      fontFamily: 'Trebuchet MS',

    },
    '.MuiSvgIcon-root': {
      fill: colors.inputted_text,
      fontFamily: 'Trebuchet MS',
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: colors.form_border,
        fontFamily: 'Trebuchet MS',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#1876d2',
        fontFamily: 'Trebuchet MS',
      },
      // '& fieldset': {
      //     borderColor:  '#c4c4c4' : 'transparent',
      // },
    },
    "&  .MuiFormHelperText-root": {
      backgroundColor: colors.screen_background,
      fontFamily: 'Trebuchet MS',
      margin: 0,
      paddingLeft: 1
    },
    // .MuiFormHelperText-root.Mui-error
  },
  textbox: {
    width: '66%',
    backgroundColor: colors.textfield_background,
  },
  unitsFilter: {
    marginTop: '5%',
  },
});

export default LeaderBudgetRST;
