import {get_all_subordinates_files} from '../../api_handler/files';

export async function dataSummary(rev) {
  const summaryArray = [];
  const pendingFiles = rev.filter(
      (file) => file.status == 1 || file.status == 2 || file.status == 3,
  );
  const disapprovedFiles = rev.filter((file) => file.status == 5);

  const numRedFiles = global.aprovFiles.length;
  const numYellowFiles = pendingFiles.length;
  const numGreenFiles = disapprovedFiles.length;

  summaryArray.push(numRedFiles);
  summaryArray.push(numYellowFiles);
  summaryArray.push(numGreenFiles);

  return summaryArray;
}

export const subordinateFiles = async () => {
  let rev = await get_all_subordinates_files({
      user_id: global.user_data.user_id,
      admin_unit_ids: global.user_data.admin_unit_ids,
      permissions: global.user_data.permissions
    },
    global.token,
  );

  if (rev.status !== 200) {
    return null;
  }

  const unit_list = rev.data.units;
  const file_summary = Object.values(rev.data.status_counts.total);
  const status_counts = rev.data.status_counts
  rev = rev.data.files;
  rev = rev.filter((file) => file.status !== 0);
  rev.sort((a, b) => new Date(b.timestamp[0]) - new Date(a.timestamp[0]));
  global.aprovFiles = rev.filter((file) => file.status == 4);

  rev.map((file) => {
    if (file.status == 1) {
      file.statusName = 'Ready for Review';
      file.pic = require('../../assets/doc_yellow.png');
      file.request_type = file.request_type || '';

      const submittedDate = new Date(file.timestamp[0]);
      const formattedDate = formatDate(submittedDate);
      file.timestamp = `Submitted: ${formattedDate}`;
    }

    if (file.status == 2) {
      if (file.request_type == 'RST') {
        file.statusName = 'Pending Reviewer Signature';
        file.pic = require('../../assets/approved_rec_doc.png');

        const submittedDate = new Date(file.timestamp[0]);
        const formattedDate = formatDate(submittedDate);
        const recommendedDate = new Date(file.timestamp[1])
        const recFormattedDate = formatDate(recommendedDate)
        file.timestamp = `Submitted: ${formattedDate}, Recommended: ${recFormattedDate}`;

        
      } else {
        file.statusName = 'Ready for Review';
        file.pic = require('../../assets/doc_yellow.png');
        file.request_type = file.request_type || '';

        const submittedDate = new Date(file.timestamp[0]);
        const formattedDate = formatDate(submittedDate);
        file.timestamp = `Submitted: ${formattedDate}`;
      }
    }

    if (file.status == 3) {
      file.statusName = 'Denied Recommendation';
      file.pic = require('../../assets/disapproved_rec_doc.png');
      file.request_type = file.request_type || '';

      const submittedDate = new Date(file.timestamp[0]);
      const formattedDate = formatDate(submittedDate);
      const recommendedDate = new Date(file.timestamp[1])
      const recFormattedDate = formatDate(recommendedDate)
      file.timestamp = `Submitted: ${formattedDate}, Recommended: ${recFormattedDate}`;
    }

    if (file.status === 4) {
      file.statusName = 'Approved';
      file.pic = require('../../assets/doc_green.png');
      file.request_type = file.request_type || '';
    
      if (file.timestamp_string && file.timestamp_string.length === 3) {
        // Scenario 1: Submitted, Recommended, Reviewed
        const submittedDate = new Date(file.timestamp[0]);
        const recommendedDate = new Date(file.timestamp[1]);
        const reviewedDate = new Date(file.timestamp[2]);
    
        file.timestamp = `Submitted: ${formatDate(submittedDate)}, Recommended: ${formatDate(recommendedDate)}, Approved: ${formatDate(reviewedDate)}`;
      } else if (file.timestamp_string && file.timestamp_string.length === 2) {
        // Scenario 2: Submitted, Reviewed
        const submittedDate = new Date(file.timestamp[0]);
        const reviewedDate = new Date(file.timestamp[1]);
    
        file.timestamp = `Submitted: ${formatDate(submittedDate)}, Approved: ${formatDate(reviewedDate)}`;
      }
    }

    if (file.status == 5) {
      file.statusName = 'Denied';
      file.pic = require('../../assets/doc_red.png');
      file.request_type = file.request_type || '';

      if (file.timestamp_string && file.timestamp_string.length === 3) {
        // Scenario 1: Submitted, Recommended, Denied
        const submittedDate = new Date(file.timestamp[0]);
        const recommendedDate = new Date(file.timestamp[1]);
        const deniedDate = new Date(file.timestamp[2]);

        file.timestamp = `Submitted: ${formatDate(submittedDate)}, Recommended: ${formatDate(recommendedDate)} Denied: ${formatDate(deniedDate)}`;
      } else if (file.timestamp_string && file.timestamp_string.length === 2) {
        // Scenario 2: Submitted, Denied
        const submittedDate = new Date(file.timestamp[0]);
        const deniedDate = new Date(file.timestamp[1]);

        file.timestamp = `Submitted: ${formatDate(submittedDate)}, Denied: ${formatDate(deniedDate)}`;
      }
    }

    if (file.status === 6) {
      file.statusName = 'Paid';
      file.pic = require('../../assets/paid_doc.png');
      file.request_type = file.request_type || '';
    
      if (file.timestamp_string && file.timestamp_string.length === 3) {
        // Scenario: Submitted, Approved, Paid
        const submittedDate = new Date(file.timestamp[0]);
        const approvedDate = new Date(file.timestamp[1]);
        const paidDate = new Date(file.timestamp[2]);
    
        file.timestamp = `Submitted: ${formatDate(submittedDate)}, Approved: ${formatDate(approvedDate)}, Paid: ${formatDate(paidDate)}`;
      }
    }
  });


  const data = [rev, unit_list, file_summary, status_counts];

  return data;
};

function formatDate(date) {
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const year = String(date.getFullYear()).slice(-2);
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  const timeZone = date.toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];

  return `${month}/${day}/${year} ${hours}:${minutes} ${timeZone}`;
}