import React, { useState, useContext, useEffect } from 'react';
import { View, Text, TextInput, TouchableOpacity, Modal } from 'react-native';
import { modalStyles, lightModeStyles, darkModeStyles, budgetOverviewStyles } from './styles';
import ModeContext from "../ModeContext";
import {edit_budget} from "../../api_handler/budget";
import { getFiscalYear } from "../../utils/monthInfo";
import { useSnackbar } from '../SnackbarContext';
import { BorderColor } from '@mui/icons-material';


const EditBudget = ({ isVisible, onCancel, onEdit, budgetGroupButton, activeChartButton, token, allBudgets, unitId}) => {
  const [adaBudget, setAdaBudget] = useState('');
  const [aftpBudget, setAftpBudget] = useState('');
  const [mdrBudget, setMdrBudget] = useState('');
  const [rmaBudget, setRmaBudget] = useState('');
  const [ebdlBudget, setEbdlBudget] = useState('');
  const [regexError, setRegexError] = useState('');
  const { mode, toggleMode } = useContext(ModeContext);
  const styles = mode === 'light' ? lightModeStyles : darkModeStyles;
  const { showSnackbar } = useSnackbar();
  const [errorFields, setErrorFields] = useState({});


  useEffect(() => {
    // Set initial values when allBudgets change
    if (allBudgets) {
      setAdaBudget(allBudgets["ALL"].toString());
      setAftpBudget(allBudgets["AFTP"].toString());
      setMdrBudget(allBudgets["MDR"].toString());
      setRmaBudget(allBudgets["RMA"].toString());
      setEbdlBudget(allBudgets["EBDL"].toString());
    }
  }, [allBudgets]);
  
  const handleCancel = () => {
    // Reset regex message
    setRegexError('');

    // Call the onCancel function to close the modal
    onCancel();
  };

  const handleEdit = async () => {
    // Regular expression pattern to match a valid number
    const numberPattern = /^\d+(\.\d+)?$/;

    if (!adaBudget) {
      setRegexError("Budget can't be empty");
      setErrorFields({...errorFields, adaBudget: true});
    }
    if (!aftpBudget) {
      setRegexError("Budget can't be empty");
      setErrorFields({...errorFields, aftpBudget: true});
    }
    if (!mdrBudget) {
      setRegexError("Budget can't be empty");
      setErrorFields({...errorFields, mdrBudget: true});
    }
    if (!rmaBudget) {
      setRegexError("Budget can't be empty");
      setErrorFields({...errorFields, rmaBudget: true});
    }
    if (!ebdlBudget) {
      setRegexError("Budget can't be empty");
      setErrorFields({...errorFields, ebdlBudget: true});
    }
    

    const errors = {};

    // Validate each input value
    if (!numberPattern.test(adaBudget) || adaBudget < 0) {
      errors.adaBudget = true;
      setRegexError('Please enter valid numbers.');
    }

    if (!numberPattern.test(aftpBudget) || aftpBudget < 0) {
      errors.aftpBudget = true;
      setRegexError('Please enter valid numbers.');
    }

    if (!numberPattern.test(mdrBudget) || mdrBudget < 0) {
      errors.mdrBudget = true;
      setRegexError('Please enter valid numbers.');
    }

    if (!numberPattern.test(rmaBudget) || rmaBudget < 0) {
      errors.rmaBudget = true;
      setRegexError('Please enter valid numbers.');
    }

    if (!numberPattern.test(ebdlBudget) || ebdlBudget < 0) {
      errors.ebdlBudget = true;
      setRegexError('Please enter valid numbers.');
    }

    if (Object.keys(errors).length > 0) {
      setErrorFields(errors);
      return;
    }

    if ((parseFloat(aftpBudget) + parseFloat(mdrBudget) + parseFloat(rmaBudget) + parseFloat(ebdlBudget)) > parseFloat(adaBudget)) {
        errors.adaBudget = true;
        setRegexError('ALL must exceed total costs.');
        return;
    } else {
      setRegexError('');
    }

    // probably should get all pots and display the data here that way instead
    const data = {
      budgets: [
        { subtype: "ALL", budget_total: parseFloat(adaBudget), unit_id: unitId },
        { subtype: "RMA", budget_total: parseFloat(rmaBudget), unit_id: unitId },
        { subtype: "AFTP", budget_total: parseFloat(aftpBudget), unit_id: unitId },
        { subtype: "MDR", budget_total: parseFloat(mdrBudget), unit_id: unitId },
        { subtype: "EBDL", budget_total: parseFloat(ebdlBudget), unit_id: unitId }
      ].filter(budget => budget.budget_total !== 0), // Filter out budgets with value 0
      pot: budgetGroupButton,
      fiscal_year: getFiscalYear()
    };

    try {
      // Call the create_budget API function
      const response = await edit_budget(data, token);
      onEdit();

      await new Promise(resolve => setTimeout(resolve, 300));

      if (response?.status === 200) {
        showSnackbar("success", "Budget Successfully Edited", 'top', 'right');
      } else {
        showSnackbar("error", `Error Editing Budget: ${response?.data?.body}`, 'top', 'right');
      }
      
    // Close the modal
    onCancel();
    }
    
    catch (error) {
    console.error(error.message); // Handle errors
  }

  setRegexError('');
  };

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={isVisible}
    >
      <View style={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 22,
      }}>
        <View style={{
          backgroundColor: mode === 'light' ? '#f0ecec': '#201c1c', // Light/Dark mode for modal
          borderRadius: 10,
          padding: 20,
          alignItems: 'center',
          height: 520,
          width: 350,
          shadowColor: mode === 'light' ? 'black' : 'white',
          shadowOffset: {
            width: 1,
            height: 1,
          },
          shadowOpacity: 0.55,
          shadowRadius: 6,
          elevation: 6,
        }}>

          <View style={[modalStyles.container]}>
            <Text style={[styles.text, { fontWeight: 'bold', padding: '4px' }]}>ALL Budget:</Text>
            <TextInput
              style={[modalStyles.input, { color: mode === 'light' ? 'black' : 'white', borderColor: errorFields.adaBudget ? 'red' : (mode === 'light' ? 'black' : 'white') }]}
              placeholder="New budget ($)"
              keyboardType="numeric"
              value={adaBudget}
              onChangeText={text => setAdaBudget(text)}
            />

            <Text style={[styles.text, { fontWeight: 'bold', padding: '4px' }]}>AFTP Budget:</Text>
            <TextInput
              style={[modalStyles.input, { color: mode === 'light' ? 'black' : 'white', borderColor: errorFields.aftpBudget ? 'red' : (mode === 'light' ? 'black' : 'white') }]}
              placeholder="New budget ($)"
              keyboardType="numeric"
              value={aftpBudget}
              onChangeText={text => setAftpBudget(text)}
            />

            <Text style={[styles.text, { fontWeight: 'bold', padding: '4px' }]}>MDR Budget:</Text>
            <TextInput
              style={[modalStyles.input, { color: mode === 'light' ? 'black' : 'white', borderColor: errorFields.mdrBudget ? 'red' : (mode === 'light' ? 'black' : 'white') }]}
              placeholder="New budget ($)"
              keyboardType="numeric"
              value={mdrBudget}
              onChangeText={text => setMdrBudget(text)}
            />

            <Text style={[styles.text, { fontWeight: 'bold', padding: '4px' }]}>RMA Budget:</Text>
            <TextInput
              style={[modalStyles.input, { color: mode === 'light' ? 'black' : 'white', borderColor: errorFields.rmaBudget ? 'red' : (mode === 'light' ? 'black' : 'white') }]}
              placeholder="New budget ($)"
              keyboardType="numeric"
              value={rmaBudget}
              onChangeText={text => setRmaBudget(text)}
            />

            <Text style={[styles.text, { fontWeight: 'bold', padding: '4px' }]}>EBDL Budget:</Text>
            <TextInput
              style={[modalStyles.input, { color: mode === 'light' ? 'black' : 'white', borderColor: errorFields.ebdlBudget ? 'red' : (mode === 'light' ? 'black' : 'white') }]}
              placeholder="New budget ($)"
              keyboardType="numeric"
              value={ebdlBudget}
              onChangeText={text => setEbdlBudget(text)}
            />

            <Text style={{ color: '#D70040', fontWeight: 'bold', padding: 2, textAlign: 'center', fontSize: 14}}>{regexError}</Text>

            <View style={modalStyles.buttonContainer}>
              <TouchableOpacity
                style={[budgetOverviewStyles.editButton, {alignItems: 'center'}]}
                onPress={handleEdit}
              >
                <Text style={{fontWeight: 'bold', color: 'white'}}>Save</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[budgetOverviewStyles.deleteButton, {alignItems: 'center'}]}
                onPress={handleCancel}
              >
                <Text style={{fontWeight: 'bold', color: 'white'}}>Cancel</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default EditBudget;