import React from 'react';
import {View, Text, Image, TouchableOpacity, ScrollView} from 'react-native';

import {confirm_forgot_password, forgot_password_reset} from '../api_handler/users.js';
import {IconButton, InputAdornment, OutlinedInput} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import { useSnackbar } from '../components/SnackbarContext.js';


function ForgotPassword({navigation}) {
  const { showSnackbar } = useSnackbar();
  const [code, onChangeCode] = React.useState('');
  const [password, onChangePassword] = React.useState('');
  const [email, onChangeEmail] = React.useState('');
  const [confirmPassword, onChangeConfirmPassword] = React.useState('');
  const [firstPage, setFirstPage] = React.useState(true);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [error, setError] = React.useState('');
  const [secondError, setSecondError] = React.useState('');

  const styles = ({
    container: {
      width: '100%',
      height: '100%',
      backgroundColor: 'white',
      alignItems: 'center',
    },
    main: {
      marginTop: '1%',
      width: '50%',
      height: '80%',
      alignItems: 'center',
      borderColor: 'white',
      borderWidth: 1,
    },
    buttonContainer: {
      width: 200,
      marginBottom: 15,
    },
    more_containers: {
      // height: 300,
      width: '100%',
      alignItems: 'center',
      flexDirection: 'column',
      marginTop: 25,
      marginBottom: 10,
    },
    logo: {
      width: 800,
      height: 300,
      resizeMode: 'contain',
    },
    textbox: {
      m: 1,
      borderWidth: 1,
      height: 60,
      width: '40%',
      marginTop: '3vh',
    },
    // passBox: {
    //     borderWidth: 1,
    //     height: 60,
    //     width: '40%',
    //     flexDirection: "row"
    // },
    buttonStyle: {
      height: '40px',
      width: '100%',
      backgroundColor: '#0d1232',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
    buttonText: {
      color: 'white',
      fontSize: 25,
      marginBottom: 7,
      textAlign: 'center',
    },
    login_failed_text: {
      color: 'red',
      fontSize: 20,
      marginBottom: 7,
      textAlign: 'center',
    },
    validPasswordMsg: {
      fontWeight: 'bold',
      textAlign: 'center',
    },
    validPasswordMsgBox: {
      marginTop: 20,
      borderWidth: 0.3,
      paddingLeft: 15,
      paddingRight: 15,
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      lineHeight: '100%', // Vertically center the text using line-height
    },
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  async function handleForgottenPassword() {
    const res = await forgot_password_reset({email: email});
    if (res.status === 400) {
      showSnackbar("error", `Application Error: ${res.data.body}`)
    } else {
      setFirstPage(false);
    }
  }

  async function handleOnSubmit() {
    if (password == confirmPassword) {
      const res = await confirm_forgot_password({email: email, confirmation_code: code, new_password: password});
      if (res.status === 400) {
        showSnackbar("error", `Application Error: ${res.data.body}`)
      } else {
        navigation.navigate('Login');
      }
    } else {
      showSnackbar("error", 'Passwords must match');
    }
  }

  return (
    <ScrollView showsverticalscrollindicator="true" style={{backgroundColor: '#ffffff'}}>
      <View style={styles.container}>
        <View style={styles.main}>
          <Image style={styles.logo} source={require('../assets/US_Army_Reserve logo light mode large final.png')} />
          {firstPage ? (
                        <View style={styles.more_containers}>
                          <Text style={{marginBottom: 30, fontSize: 30}}>Forgot your password?</Text>
                          <Text style={{marginBottom: 20}}>Enter your email and we'll send you a confirmation code to reset your password.</Text>
                          <TextField
                            style={styles.textbox}
                            onChange={(data) => {
                              onChangeEmail(data.target.value); setError('');
                            }}
                            value={email}
                            label={'Email Address'}
                          />
                          <Text style={{margin: 10, color: 'red'}}>{error}</Text>
                          <View style={[styles.buttonContainer, {marginTop: 10}]}>
                            <TouchableOpacity style={styles.buttonStyle} onPress={() => {
                              handleForgottenPassword();
                            }}>
                              <Text style={styles.buttonText}>Send Email</Text>
                            </TouchableOpacity>
                          </View>
                        </View>
                    ) : (
                        <>
                          <View style={styles.more_containers}>
                            {/* <Text style={{marginBottom: 10}}>Place Email Code Here</Text> */}
                            <TextField
                              style={styles.textbox}
                              onChange={(data) => {
                                onChangeCode(data.target.value); setSecondError('');
                              }}
                              value={code}
                              label={'Confirmation Code'}
                            />

                            {/* <Text style={{margin: 10}}>New Password &#8315;</Text> */}
                            <FormControl sx={styles.textbox} variant="outlined">
                              <InputLabel htmlFor="password">Password</InputLabel>
                              <OutlinedInput
                                id="password"
                                label={'Password'}
                                type={showPassword ? 'text' : 'password'}
                                onChange={(data) => {
                                  onChangePassword(data.target.value); setSecondError('');
                                }}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                  </InputAdornment>
                                }
                              />
                            </FormControl>
                            <View style={styles.validPasswordMsgBox}>
                              <Text style={styles.validPasswordMsg}>
                                        Password must have:
                                {'\n'}• At least 8 characters
                                {'\n'}• 1 special character
                                {'\n'}• 1 uppercase letter
                                {'\n'}• 1 lowercase letter
                                {'\n'}• 1 number
                              </Text>
                            </View>
                            {/* <Text style={{margin: 10}}>Confirm New Password</Text> */}
                            <FormControl sx={styles.textbox} variant="outlined">
                              <InputLabel htmlFor="confirmPassword">Confirm Password</InputLabel>
                              <OutlinedInput
                                id="confirmPassword"
                                label={'Confirm Password'}
                                type={showConfirmPassword ? 'text' : 'password'}
                                onChange={(data) => {
                                  onChangeConfirmPassword(data.target.value); setSecondError('');
                                }}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={handleClickShowConfirmPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                  </InputAdornment>
                                }
                              />
                            </FormControl>
                            <Text style={{margin: 10, color: 'red'}}>{secondError}</Text>
                          </View>

                          <View style={styles.buttonContainer}>
                            <TouchableOpacity style={styles.buttonStyle} color="#0d1232" onPress={() => {
                              handleOnSubmit();
                            }}>
                              <Text style={styles.buttonText}>Submit</Text>
                            </TouchableOpacity>
                          </View>
                        </>
                    )}

          <View style={styles.buttonContainer}>
            <TouchableOpacity style={styles.buttonStyle} color="#0d1232" onPress={() => {
 firstPage ? navigation.navigate('Login') : setFirstPage(true);
            }}>
              <Text style={styles.buttonText}>Back</Text>
            </TouchableOpacity>
          </View>

          {/* TODO: Replace Back Button with Non-Intrusive Text */}
          {/* <TouchableOpacity onPress={() => { firstPage ? navigation.navigate('Login') : setFirstPage(true) }}>
                            <Text style={{ color: 'blue', textDecorationLine: 'underline', marginTop: 10 }}>Back to Apps Client</Text>
                        </TouchableOpacity> */}
        </View>
      </View>
    </ScrollView>
  );
};

export default ForgotPassword;
