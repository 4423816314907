import React from 'react';
import {View, Text, TouchableOpacity} from 'react-native';

import {useScale} from '../components/scale';
import CustomHeader from '../components/header';
import { useColorContext} from '../components/ColorContext';

function Review({navigation}) {
  const {colors} = useColorContext();
  const [refresh, setRefresh] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState(null);

  const scale = useScale();
  const styles = ({
    container: {
      flex: 1,
      backgroundColor:  colors.screen_background,
    },
    list: {
      width: '75%',
    },
    title: {
      fontSize: scale(50),
      fontFamily: 'Trebuchet MS',
      marginTop: '0.5%',
      color:  colors.text ,
    },
    button: {
      marginTop: 2,
    },
    header: {
      backgroundColor: 'pink',
      height: '15%',
    },
    main: {
      marginTop: '0%',
      height: '85%',
      alignItems: 'center',
    },
    item: {
      backgroundColor: 'orange',
      padding: 20,
      marginVertical: 8,
      marginHorizontal: 16,
      borderRadius: 8,
      shadowColor: '#000',
      shadowOffset: {width: 3, height: 3},
      shadowOpacity: 0.3,
      shadowRadius: 8,
    },
    helpBtn: {
      width: '40%',
      borderRadius: '40',
      alignItems: 'center',
      color: 'white',
      borderRadius: 15,
      shadowColor: '#171717',
      shadowOffset: {width: -2, height: 4},
      shadowOpacity: 0.2,
      shadowRadius: 3,
      marginTop: 30,
      marginBottom: 30,
      backgroundColor:  colors.button ,
    },
    innerText: {
      color:  colors.button_text,
      fontWeight: 'bold',
      padding: 10,
    },
  });

  return (

    <View style = {styles.container}>
      <CustomHeader />

      <View style = {styles.main}>
        <Text style = {styles.title}>Help</Text>


        <TouchableOpacity
          style={styles.helpBtn}
          onPress={ ()=>{
            navigation.navigate('UserManual');
          }}
        >
          <Text style={styles.innerText}>User Manual</Text>
        </TouchableOpacity>

        <TouchableOpacity
          style={styles.helpBtn}
          onPress={ ()=>{
            navigation.navigate('BugReport');
          }}
        >
          <Text style={styles.innerText}>Bug Report</Text>
        </TouchableOpacity>

      </View>
    </View>
  );
};

export default Review;
