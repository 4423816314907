import React from 'react';
import {View, StyleSheet} from 'react-native';
import CreateEventButton from './CreateEventButton';
import SmallCalendar from './SmallCalendar';
import Labels from './Labels';
import { useColorContext } from '../ColorContext';

export default function Sidebar({state, setState}) {
  const { colors } = useColorContext();

  const styles = ({
    container: {
      borderWidth: 1,
      padding: 20,
      width: '23%', // Adjust the width as needed
      borderColor: 'gray',
    },
  });  

  return (
    <View style={styles.container}>
      <SmallCalendar state={state} setState={setState}/>
      <Labels state={state} setState={setState} />
    </View>
  );
}