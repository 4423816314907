import React, { useEffect, useState, useMemo } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
} from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import { PieChart, Pie, Cell, Legend, Tooltip, ResponsiveContainer } from 'recharts';
import { get_subordinates_med_data } from '../../api_handler/medical';
import { useScale } from '../../components/scale';
import { useColorContext } from '../../components/ColorContext';
import Scrollbars from 'react-custom-scrollbars';
import NavSideBar from '../../components/NavSideBar';
import { MaterialReactTable } from 'material-react-table';
import { useTheme, createTheme, ThemeProvider } from '@mui/material/styles';
import ModeContext from '../../components/ModeContext';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { useSnackbar } from '../../components/SnackbarContext'
import * as Progress from 'react-native-progress'

export default function LeaderMedOv({ navigation }) {
  const { showSnackbar } = useSnackbar();
  const [rows, setRows] = React.useState([]);
  const isFocused = useIsFocused();
  const { mode } = React.useContext(ModeContext);
  const { colors } = useColorContext();
  const [indivData, setIndivData] = useState([]);
  const [dataDrc, settotalDrcNum] = useState([]);
  const [dataMrc, settotalMrcNum] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [unitSelection, setUnitSelection] = useState([]);
  const [unitType, setUnitType] = useState(['All Units'])
  const [density, setDensity] = useState('compact');
  const [loaded, setLoaded] = useState(false);


  // Filter
  const [searchText, setSearchText] = useState('');

  /**
 *  Filters table to show only the entries matching graph drc value
 *
 */
  function filterFromDrcGraph(entry) {
    if (searchText === 'All Units') {
      const filtered = indivData.filter((item) => String(item.drc) === entry.name);
      setFilteredData(filtered);
    } else {
      const filtered = indivData.filter((item) =>
        item.unit_name.toLowerCase().includes(searchText.toLowerCase()) &&
        String(item.drc) === entry.name,
      );
      setFilteredData(filtered);
    }
  }

  /**
   * Filters the table to show only the entries matching graph mrc value
   *
   */
  function filterFromMrcGraph(entry) {
    if (searchText === 'All Units') {
      const filtered = indivData.filter((item) => String(item.mrc) === entry.name);
      setFilteredData(filtered);
    } else {
      const filtered = indivData.filter((item) =>
        item.unit_name.toLowerCase().includes(searchText.toLowerCase()) &&
        String(item.mrc) === entry.name,
      );
      setFilteredData(filtered);
    }
  }

  const scale = useScale();
  const styles = {
    container: {
      width: '100%',
      backgroundColor: colors.screen_background,
    },
    pageTopSection: {
      flexDirection: 'row',
      width: '90%',
      alignSelf: 'center',
      marginLeft: '5%',
      marginTop:'2.5%'
    },
    pageInfoText: {
      fontSize: 18,
      marginHorizontal: 5,
      textAlign: 'left',
      paddingTop: 25,
      color: colors.text
    },
    tableContainer: {
      justifyContent: 'center',
      marginVertical: '2%',
      alignSelf: 'center',
      marginHorizontal:'5%',
      borderColor: colors.border_color,
      borderWidth: global.user_data.theme === 'dark' ? 1 : 0,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
    },
    titleDiv: {
      flexDirection: 'row',
      width: '100%',
      alignItems: 'center',
    },
    title: {
      fontSize: scale(30),
      fontFamily: 'Trebuchet MS',
      flex: 1,
      paddingTop: 25,
      fontWeight: 'bold',
      marginHorizontal: 5,
      textAlign: 'Left',
      color: colors.screen_title,
    },
    textInputProps: {
      '& .MuiFormLabel-root, & label.Mui-focused, & .MuiInputBase-input': {
        color: colors.inputted_text,
        // WebkitTextFillColor:  colors.inputted_text : colors.dark_mode_inputted_text,
      },
      '.MuiSvgIcon-root': {
        fill: colors.inputted_text,
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: colors.border_color,
        },
        '&:hover fieldset': {
          borderColor: colors.border_color,
        },
        '&.Mui-focused fieldset': {
          borderColor: colors.border_color,
        },
      },
      '&  .MuiFormHelperText-root': {
        backgroundColor: colors.screen_background,
        margin: 0,
        paddingLeft: 1,
      },
      // .MuiFormHelperText-root.Mui-error
    },
    textbox: {
      width: '66%',
      backgroundColor: colors.textfield_background,
    },
    unitsFilter: {
      marginTop: '5%',
    },
    pieChart: {
      flexDirection: 'row',
      alignItems: 'right',
      fontFamily: 'Trebuchet MS',
      fontSize: 14,

    },
  };

  useEffect(() => {
    async function getSubMedicalData() {
      const res = await get_subordinates_med_data(
        {
          user_id: global.user_data.user_id,
          admin_unit_ids: global.user_data.admin_unit_ids
        },
        global.token,
      );

      // Check if the response has the expected properties
      if (res.status === 200) {
        const updatedData = updateData(res.data.body);
        setRows(updatedData);
        setIndivData(updatedData);
        settotalDrcNum(res.data.drc_counts);
        settotalMrcNum(res.data.mrc_counts);
        setUnitSelection(res.data.sub_units);
        setSearchText('All Units');
      } else {
        showSnackbar("error", "Application Error: Failed to fetch subordinates medical data");
        navigation.navigate('Home');
      }
      setLoaded(true);
    }

    getSubMedicalData();
  }, [isFocused]);

  const handleSearch = (selectedValues) => {
    const allUnitsIndex = selectedValues.indexOf('All Units');
    const previousAllUnitsIndex = unitType.indexOf('All Units');

    let updatedValues = [...selectedValues];

    if (allUnitsIndex !== -1 && previousAllUnitsIndex === -1 && selectedValues.length > 1) {
      updatedValues = ['All Units'];
    }
    else if (previousAllUnitsIndex !== -1 && selectedValues.length > 1) {
      updatedValues = selectedValues.filter(value => value !== 'All Units');
    }

    setUnitType(updatedValues);
  
  
    let filtered;
    if (!updatedValues.includes('All Units')) {
      filtered = indivData.filter(item => 
        updatedValues.some(unit => item.unit_name.toLowerCase().includes(unit.toLowerCase()))
      );
    } else {
      filtered = indivData;
    }
  
    setRows(filtered);
  };
  
  

  function updateData(data) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };

    return data.map(item => {
      // Convert dent_date to human-readable text
      if (item.dent_date) {
        const dentDate = new Date(item.dent_date);
        item.dent_date = dentDate.toLocaleDateString(undefined, options);
      } else {
        item.dent_date = 'N/A';
      }

      // Convert pha_date to human-readable text
      if (item.pha_date) {
        const phaDate = new Date(item.pha_date);
        item.pha_date = phaDate.toLocaleDateString(undefined, options);
      } else {
        item.pha_date = 'N/A';
      }

      // Replace mrc and drc null values with "N/A"
      ['mrc', 'drc'].forEach(key => {
        if (item[key] === null) {
          item[key] = 'N/A';
        }
      });

      // Replace other null values with "N/A"
      Object.keys(item).forEach(key => {
        if (item[key] === null) {
          item[key] = '';
        }
      });

      return item;
    });
  }


  const globalTheme = useTheme();
  const generalTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: colors.mode,
          primary: globalTheme.palette.primary,
        },
      }),
    [globalTheme, isFocused],
  );
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: mode,
          primary: globalTheme.palette.primary,
          background: {
            default: colors.textfield_background,
          },
        },
        typography: { fontSize: 17 },
      }),
    [globalTheme, isFocused],
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: 'unit_name',
        header: 'Unit',
        size: 1,
      },
      {
        accessorFn: (row) => `${row.rank} ${row.first_name} ${row.last_name}`,
        header: 'Soldier',
        size: 1,
      },
      {
        accessorKey: 'mrc',
        header: 'MRC',
        size: 0.5,
      },
      {
        accessorKey: 'drc',
        header: 'DRC',
        size: 0.5,
      },
      {
        accessorKey: 'pha_date',
        header: 'PHA Date',
        size: 1,
      },
      {
        accessorKey: 'dent_date',
        header: 'DENT Date',
        size: 1,
      },
    ],
    [rows],
  );

  const loadingStyles = {
    animation: {
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
        justifyContent: 'center',
        paddingVertical: 20,
        background: colors.screen_background,
    }
  }

  if (!loaded) {
    return (
      <View style={loadingStyles.animation}>
          <Progress.CircleSnail
            color={colors.loading_circle}
            indeterminate={true}
            size={275}
            thickness={8}
          />
      </View>
    );
  }


  return (
    <ThemeProvider theme={generalTheme}>
    <View style={{ height: '100%', width: '100%', flexDirection: 'row' }}>
      {/* <NavSideBar /> */}
      <Scrollbars style={styles.container}>
        <View style={styles.pageTopSection} >
          <View style={{ alignText: 'center', flex: 3 }}>

            <View style={styles.titleDiv}>
              <Text style={styles.title}>
                <Text style={{ fontWeight: 'bold' }}>Medical Overview</Text>
              </Text>
            </View>

            <Text style={styles.pageInfoText} >
              Track medical statuses of Medical Readiness Classification (MRC) and
              Dental Readiness Classification (DRC) of subordinates.
            </Text>

            <View style={styles.unitsFilter}>
              <FormControl style={styles.textbox}>
                <InputLabel id="tables-units-label">Units Filter</InputLabel>
                <Select
                  multiple
                  value={unitType}
                  input={
                    <OutlinedInput 
                      label="Units Filter"
                      sx={{
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: colors.border_color,
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: colors.border_color,
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: colors.border_color,
                        },
                        '& .MuiSelect-icon': {
                          color: colors.inputted_text,
                        },
                        '& .MuiInputBase-input': {
                          color: colors.inputted_text,
                        },
                        '& .MuiFormLabel-root': {
                          color: colors.inputted_text,
                        },
                        '& .MuiFormLabel-root.Mui-focused': {
                          color: colors.inputted_text,
                        },
                        '& .MuiFormHelperText-root': {
                          backgroundColor: colors.screen_background,
                          margin: 0,
                          paddingLeft: 1,
                        },
                      }}
                    />
                  }
                  onChange={(value) => {
                    handleSearch(value.target.value);
                  }}
                  renderValue={(selected) => selected.join(', ')}
                >
                  {unitSelection.map((option) => (
                    <MenuItem style={{ whiteSpace: "pre" }} key={option.label} value={option.label}>
                      <ListItemText primaryTypographyProps={{ style: styles.listItemTextTypography }} primary={option.label} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </View>

            <Text
              style={{
                fontSize: 12,
                marginHorizontal: 5,
                textAlign: 'Left',
                paddingTop: 5,
                fontFamily: 'Trebuchet MS',
                color: colors.text
              }}
            >
              Statuses are displayed for above selected Unit.
            </Text>
          </View>

          <View style={{ flexDirection: 'row', flex: 1, gap: 10 }}>
            <View />
          </View>

          <View
            style={styles.pieChart}
          >

            <PieChart width={300} height={300} align="right" title="DRC Status">
              <Tooltip label="DRC" />
              <text
                x={150}
                y={30}
                textAnchor="middle"
                fontSize={16}
                fill={colors.text
                }
              >
                DRC Status
              </text>
              <Pie
                data={dataDrc}
                outerRadius={100}
                innerRadius={'35%'}
                fill="#82ca9d"
                dataKey="value"
                nameKey="name"
              >
                {Object.values(dataDrc).map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={colors.leader_dash_med_pie_color_palette[index]} />
                ))}
              </Pie>

              <Legend />
            </PieChart>

            <PieChart width={300} height={300} title="MRC Status">
              <text
                x={150}
                y={30}
                textAnchor="middle"
                fontSize={16}
                fill={
                  colors.text
                }
              >
                MRC Status
              </text>
              <Tooltip label="MRC" />
              <Pie
                data={dataMrc}
                outerRadius={100}
                innerRadius={'35%'}
                fill="#82ca9d"
                dataKey="value"
                nameKey="name"
              >

                {/* <Cell key={`cell-${index}`} fill={colors.leader_dash_med_pie_color_palette[index]} /> */}
                {Object.values(dataMrc).map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={colors.leader_dash_med_pie_color_palette[index]} />

                ))}
              </Pie>
              <Legend />
            </PieChart>
          </View>
        </View>

        <View style={styles.tableContainer}>
          <ThemeProvider theme={tableTheme}>
            <MaterialReactTable
              columns={columns}
              data={rows}
              state={{density}}
            />
          </ThemeProvider>
        </View>

      </Scrollbars>
    </View>
    </ThemeProvider>
  );
}
