export const monthDict = {
    Oct: 0,
    Nov: 1,
    Dec: 2,
    Jan: 3,
    Feb: 4,
    Mar: 5,
    Apr: 6,
    May: 7,
    Jun: 8,
    Jul: 9,
    Aug: 10,
    Sep: 11,
};

export const monthNames = [
    "Oct",
    "Nov",
    "Dec",
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
];

export const inputMonths = ['October', 'November', 'December', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September'];


export function getFiscalMonth(date) {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const monthAbbr = months[date.getMonth()];
    return monthDict[monthAbbr];
}

export function getFiscalYear() {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth();

    if (month >= 9) { // October is month 9 in JavaScript
        year++;
    }

    return year;
}
