import React, { useEffect, useState } from "react";
import { Text, View, StyleSheet, TouchableOpacity } from "react-native";
import { useColorContext } from "../ColorContext";
import { monthDict, monthNames } from "../../utils/monthInfo";
import { useNavBarContext } from "../NavBarContext";

function ChartRangeFilter({ openPopup, range, setRange, targetLine }) {
    const [buttonPressed, setButtonPressed] = useState(0);
    const { colors } = useColorContext();
    const { navState } = useNavBarContext();
    const [hasPermission, setHasPermission] = React.useState(navState.canEditLeadersDash);

    const allValuesNotZero = targetLine && Object.values(targetLine).every(value => value !== '0');

    let rangeFilters = [
        {
            label: "3Mo",
            index: 0,
            onPress: () => {
                setButtonPressed(0)
                return setRange('3Mo')
            }
        },
        {
            label: "6Mo",
            index: 1,
            onPress: () => {
                setButtonPressed(1)
                return setRange('6Mo')
            }
        },
        {
            label: "FY",
            index: 2,
            onPress: () => {
                setButtonPressed(2)
                return setRange('FY')
            }
        },
    ];

    if (hasPermission) {
        rangeFilters.push({
            label: allValuesNotZero ? "Edit Target" : "Create Target",
            index: 3,
            // TODO: Update to open popup.
            onPress: function () {
                setButtonPressed(3);
                openPopup();
            },
        })
    }

    const styles = StyleSheet.create({
        chartSelector: {
            flexDirection: "row",
            flex: 1,
            justifyContent: "space-between",
            alignItems: "center",
            // borderBottomWidth: 2,
            // borderColor: '#D9D9D9',
            // margin: 25,
            marginHorizontal: 25,
            marginVertical: 5,
            width: '100%',
        },
        blackText: {
            color: colors.bow,
        },
        pieButton: {
            minWidth: 60,
            alignItems: "center",
            borderColor: colors.gobl,
            borderWidth: 2,
            borderRadius: 10,
            padding: 10,
        },
        pieButtonPressed: {
            backgroundColor: colors.gobl,
            borderRadius: 10,
            padding: 10,
        },
        targetLineButton: {
            backgroundColor: "green",
            borderRadius: 10,
            padding: 10,
        }
    });

    useEffect(() => {
        const rangeIndex = rangeFilters.find((filter) => filter.label === range)?.index;
        setButtonPressed(rangeIndex);
    }, [range])

    return (
        <View style={styles.chartSelector}>
            {rangeFilters?.map((button, index) => (
                <TouchableOpacity
                    key={index}
                    style={[
                        (button.index !== 3) 
                            ? styles.pieButton
                            : null,
                        (buttonPressed === index)
                            ? styles.pieButtonPressed
                            : null,
                        (button.index === 3)
                            ? styles.targetLineButton
                            : null,
                    ]}
                    onPress={() => {
                        button.onPress();
                    }}
                >
                    <Text style={[styles.blackText]}>{button.label}</Text>
                </TouchableOpacity>
            ))}
        </View>
    );
}

export default ChartRangeFilter;
