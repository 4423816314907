import { PDFDocument } from 'pdf-lib';
import moment from 'moment';
import { get_single_file, get_image_s3 } from '../api_handler/files.js';

// Import all of the PDFs
import * as form_rst from '../PDFs/RST_request_form_raw.js';
import * as form_dental from '../PDFs/dental_exam_form_raw.js';
import * as form_1380 from '../PDFs/1380_form_raw.js';
import * as form_1380_new from '../PDFs/1380_new_raw.js'
import * as form_1380_aftp from '../PDFs/1380_aftp_form_blank.js';
import * as form_1380_aftp_new from '../PDFs/1380_aftp_new.js'
import * as form_ebdl from '../PDFs/EBDL_request_form_raw.js';
import * as form_ata from '../PDFs/ATA_request_form_raw.js';
import * as form_mdr from '../PDFs/MDR_request_form_raw.js';

// Old method of displaying PDFs
// export async function pdfIn(base64) {
//   const pdfWindow = window.open('');
//   pdfWindow.document.write('<iframe width=\'100%\' height=\'100%\' src=\'data:application/pdf;base64,' + base64 + '\'></iframe>');
// };

// New method of displaying PDF
// Function decodes base64 and displays PDF in a new tab
export function pdfIn(base64) {
  // Convert base64 to raw binary
  const binary_string = window.atob(base64);

  // Convert binary to byte array
  const len = binary_string.length;
  const bytes = new Uint8Array(len);

  for (let i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }

  // Create blob from byte array
  const blob = new Blob([bytes], { type: 'application/pdf' });

  // Create an local object URL from the blob object
  const url = URL.createObjectURL(blob);

  // Open url in new tab
  window.open(url);
}

// Decodes images stored in S3
export function decodeBase64(s) {
  const e = {}; let i; let b = 0; let c; let x; let l = 0; let a; let r = ''; const w = String.fromCharCode; const L = s.length;
  const A = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
  for (i = 0; i < 64; i++) {
    e[A.charAt(i)] = i;
  }
  for (x = 0; x < L; x++) {
    c = e[s.charAt(x)]; b = (b << 6) + c; l += 6;
    while (l >= 8) {
      ((a = (b >>> (l -= 8)) & 0xff) || (x < (L - 2))) && (r += w(a));
    }
  }
  return r;
};

// Generates the RST form, exported to be used in other screens
// form_id - form_id of the PDF being generated
// data - JSON containing all of the form data
// navigation - REACT Native prop that holds the screen stack, used when a user is redirected to view PDF screen
// getBase64 - True: returns base64 of PDF, False: redirects user to View PDF Screen
// is_deletable - True: form can be deleted, False: form cannot be deleted (depends on the forms status)
export const generate_rst = async (form_id, data, navigation, getBase64, is_deletable) => {
  const pdfDoc = await PDFDocument.load(form_rst.pdf());
  const form = pdfDoc.getForm();

  form.getTextField('REQUEST DATE').setText((data.date ?? '') + "\t#" + (form_id == '' ? 'XXXX' : form_id));
  form.getTextField('RANK AND SOLDIER NAME').setText(data.name_and_rank ?? '');

  if (data.excused_absence) {
    form.getCheckBox('EXCUSED ABSENCE CHECK').check();
    form.getTextField('EXCUSED BA DATES').setText(data.excused_absence_ba_date ?? '');
    form.getTextField('MUTA').setText(data.excused_absence_muta_num ?? '');
  }

  if (data.rst) {
    form.getCheckBox('RST CHECK').check();
    form.getTextField('IN LIEU OF BA DATES').setText(data.rst_ba_date ?? '');
    form.getTextField('DUTY TO BE PERFORMED ON DATES').setText(data.rst_performed_on ?? '');
  }

  if (data.rma) {
    form.getCheckBox('RMA CHECK').check();
    form.getTextField('RMA DATES').setText(data.rma_date ?? '');
  }

  if (data.ata) {
    form.getCheckBox('ATA CHECK').check();
    form.getTextField('ATA DATES').setText(data.ata_date ?? '');
    form.getTextField('NUM OF ATAS').setText(data.ata_num_of_ata ?? '');
  }

  form.getTextField('DUTIES TO BE PERFORMED').setText(data.tasks_to_perform ?? '');
  form.getTextField('JUSTIFICATION').setText(data.justification ?? '');
  form.getTextField('SOLDIERS NOTES').setText(data.soldier_notes ?? '');

  form.getTextField('HEADER').setText(data.header ?? '');
  form.getTextField('FIELD MEMO OFFICE SYMBOL').setText(data.field_memo ?? '');
  form.getTextField('MEMORANDUM FOR').setText(data.memorandum ?? '');
  var reviewer_data = '';
  if (data.commanding_officer != null) {
    var reviewer_data_arr = data.commanding_officer.split(" ");
    reviewer_data = reviewer_data_arr[0];
    if (reviewer_data_arr[1] != "" && reviewer_data_arr[1] != undefined) {
      reviewer_data = reviewer_data.concat(` ${reviewer_data_arr[1]}.`)
    }
    reviewer_data = reviewer_data.concat(` ${reviewer_data_arr[2]}`);
    reviewer_data = reviewer_data.concat(`\n${reviewer_data_arr[3]}`);
    if (reviewer_data_arr[4] != "" && reviewer_data_arr[4] != undefined) {
      reviewer_data = reviewer_data.concat(`, ${reviewer_data_arr[4]}`);
    }
    if (reviewer_data_arr[5] === "102") {
      reviewer_data = reviewer_data.concat("\nCommanding")
    }
  }
  form.getTextField('COMMANDER').setText(reviewer_data);

  if (data.soldier_signature_id != null) {
    const signature_uri = decodeBase64(await get_image_s3('signatures/' + data.soldier_signature_id, 'image/png', global.token)).split(',').pop();
    const img = await pdfDoc.embedPng(signature_uri);
    pdfDoc.getPage(0).drawImage(img, {
      x: 165,
      y: 235,
      width: 100,
      height: 25,
    });
  }

  if (data.recommender_comment != null) {
    form.getTextField('RECOMMENDER COMMENTS').setText(data.recommender_comment ?? '');
  }

  if (data.reviewer_comment != null) {
    form.getTextField('REVIEWER COMMENTS').setText(data.reviewer_comment ?? '');
  }

  if (data.recommender_signature_id != null) {
    if (data?.recommend_approval_date) {
      form.getTextField('RECOMMENDER DATE').setText(moment(data.recommend_approval_date ?? '').format('DD MMM YY').toUpperCase());
    }
    
    if (data.request_is_approved) {
      form.getCheckBox('RECOMMEND APPROVED CHECK').check();
    } else {
      if (data.reviewer_signature_id) {
        form.getCheckBox('RECOMMEND APPROVED CHECK').check();
      }
      else {
        form.getCheckBox('RECOMMEND DISAPPROVED CHECK').check();
      }
    }


    const signature_uri = decodeBase64(await get_image_s3('signatures/' + data.recommender_signature_id, 'image/png', global.token)).split(',').pop();
    const img = await pdfDoc.embedPng(signature_uri);

    pdfDoc.getPage(0).drawImage(img, {
      x: 325,
      y: 180,
      width: 50,
      height: 50,
    });
  }

  if (data.reviewer_signature_id != null) {
    if (data.request_is_approved) {
      form.getCheckBox('REVIEWER APPROVED CHECK').check();
    } else if (data.request_is_approved === false) {
      form.getCheckBox('REVIEWER DISAPPROVED CHECK').check();
    }

    if (data?.request_is_approved_date) {
      form.getTextField('REVIEWER DATE').setText(moment(data.request_is_approved_date ?? '').format('DD MMM YY').toUpperCase());
    }

    if (data.status !== 1) {
      const signature_uri = decodeBase64(await get_image_s3('signatures/' + data.reviewer_signature_id, 'image/png', global.token)).split(',').pop();
      const img = await pdfDoc.embedPng(signature_uri);
      pdfDoc.getPage(0).drawImage(img, {
        x: 330,
        y: 80,
        width: 50,
        height: 50,
      });
    }
  }



  form.flatten();
  const pdfBytes = await pdfDoc.saveAsBase64();

  if (getBase64) {
    return pdfBytes;
  } else {
    pdfIn(pdfBytes);
  }
};

export const generate_ebdl = async (form_id, data, navigation, getBase64, is_deletable) => {
  const pdfDoc = await PDFDocument.load(form_ebdl.pdf());
  const form = pdfDoc.getForm();

  form.getTextField('REQUEST DATE').setText((data.date ?? '') + "\t#" + (form_id == '' ? 'XXXX' : form_id));
  form.getTextField('RANK AND SOLDIER NAME').setText(data.name_and_rank ?? '');

  form.getTextField('TRAINING/COURSE').setText(data.training_to_be_completed ?? '');
  form.getTextField('DUTY TO BE PERFORMED ON DATES').setText(data.dates_of_training ?? '');
  form.getTextField('HEADER').setText(data.header ?? '');
  form.getTextField('FIELD MEMO OFFICE SYMBOL').setText(data.field_memo ?? '');
  form.getTextField('MEMORANDUM FOR').setText(data.memorandum ?? '');
  form.getTextField('COMMANDER').setText(data.commanding_officer ?? '');
  form.getTextField('NUM OF EBDLs').setText(parseInt(data.num_of_trainings).toString() ?? '');

  if (data.soldier_signature_id != null && form_id !== undefined) {
    const signature_uri = decodeBase64(await get_image_s3('signatures/' + data.soldier_signature_id, 'image/png', global.token)).split(',').pop();
    const img = await pdfDoc.embedPng(signature_uri);
    pdfDoc.getPage(0).drawImage(img, {
      x: 165,
      y: 225,
      width: 50,
      height: 50,
    });
  }

  if (data.approver_signature_id != null && form_id !== undefined) {
    if (data.request_is_approved) {
      form.getCheckBox('APPROVED CHECK').check();
    } else {
      form.getCheckBox('DISAPPROVED CHECK').check();
    }

    form.getTextField('REVIEWER DATE').setText(moment(data.request_is_approved_date ?? '').format('DD MMM YY').toUpperCase());
    form.getTextField('APP COMMENTS').setText(data.approver_comment ?? '');

    const signature_uri = decodeBase64(await get_image_s3('signatures/' + data.approver_signature_id, 'image/png', global.token)).split(',').pop();
    const img = await pdfDoc.embedPng(signature_uri);
    pdfDoc.getPage(0).drawImage(img, {
      x: 330,
      y: 80,
      width: 50,
      height: 50,
    });
  }

  form.flatten();
  const pdfBytes = await pdfDoc.saveAsBase64();

  if (getBase64) {
    return pdfBytes;
  } else {
    pdfIn(pdfBytes);
  }
};

export const generate_ata = async (form_id, data, navigation, getBase64, is_deletable) => {
  const pdfDoc = await PDFDocument.load(form_ata.pdf());
  const form = pdfDoc.getForm();

  form.getTextField('REQUEST DATE').setText((data.date ?? '') + "\t#" + (form_id == '' ? 'XXXX' : form_id));
  form.getTextField('RANK AND SOLDIER NAME').setText(data.name_and_rank ?? '');

  form.getTextField('TRAINING/COURSE').setText(data.training_to_be_completed ?? '');
  form.getTextField('DUTY TO BE PERFORMED ON DATES').setText(data.dates_of_training ?? '');
  form.getTextField('HEADER').setText(data.header ?? '');
  form.getTextField('FIELD MEMO OFFICE SYMBOL').setText(data.field_memo ?? '');
  form.getTextField('MEMORANDUM FOR').setText(data.memorandum ?? '');
  form.getTextField('COMMANDER').setText(data.commanding_officer ?? '');
  form.getTextField('NUM OF EBDLs').setText(parseInt(data.num_of_trainings).toString() ?? '');

  if (data.soldier_signature_id != null && form_id !== undefined) {
    const signature_uri = decodeBase64(await get_image_s3('signatures/' + data.soldier_signature_id, 'image/png', global.token)).split(',').pop();
    const img = await pdfDoc.embedPng(signature_uri);
    pdfDoc.getPage(0).drawImage(img, {
      x: 165,
      y: 225,
      width: 50,
      height: 50,
    });
  }

  if (data.approver_signature_id != null && form_id !== undefined) {
    if (data.request_is_approved) {
      form.getCheckBox('APPROVED CHECK').check();
    } else {
      form.getCheckBox('DISAPPROVED CHECK').check();
    }

    form.getTextField('REVIEWER DATE').setText(moment(data.request_is_approved_date ?? '').format('DD MMM YY').toUpperCase());
    form.getTextField('APP COMMENTS').setText(data.approver_comment ?? '');

    const signature_uri = decodeBase64(await get_image_s3('signatures/' + data.approver_signature_id, 'image/png', global.token)).split(',').pop();
    const img = await pdfDoc.embedPng(signature_uri);
    pdfDoc.getPage(0).drawImage(img, {
      x: 330,
      y: 80,
      width: 50,
      height: 50,
    });
  }

  form.flatten();
  const pdfBytes = await pdfDoc.saveAsBase64();

  if (getBase64) {
    return pdfBytes;
  } else {
    pdfIn(pdfBytes);
  }
};

export const generate_mdr = async (form_id, data, navigation, getBase64, is_deletable) => {
  const pdfDoc = await PDFDocument.load(form_mdr.pdf());
  const form = pdfDoc.getForm();

  form.getTextField('REQUEST DATE').setText((data.date ?? '') + "\t#" + (form_id == '' ? 'XXXX' : form_id));
  form.getTextField('RANK AND SOLDIER NAME').setText(data.name_and_rank ?? '');

  form.getTextField('TRAINING/COURSE').setText(data.training_to_be_completed ?? '');
  form.getTextField('DUTY TO BE PERFORMED ON DATES').setText(data.dates_of_training ?? '');
  form.getTextField('HEADER').setText(data.header ?? '');
  form.getTextField('FIELD MEMO OFFICE SYMBOL').setText(data.field_memo ?? '');
  form.getTextField('MEMORANDUM FOR').setText(data.memorandum ?? '');
  form.getTextField('COMMANDER').setText(data.commanding_officer ?? '');
  form.getTextField('NUM OF EBDLs').setText(data.num_of_trainings ?? '');

  if (data.soldier_signature_id != null && form_id !== undefined) {
    const signature_uri = decodeBase64(await get_image_s3('signatures/' + data.soldier_signature_id, 'image/png', global.token)).split(',').pop();
    const img = await pdfDoc.embedPng(signature_uri);
    pdfDoc.getPage(0).drawImage(img, {
      x: 165,
      y: 225,
      width: 50,
      height: 50,
    });
  }

  if (data.approver_signature_id != null && form_id !== undefined) {
    if (data.request_is_approved) {
      form.getCheckBox('APPROVED CHECK').check();
    } else {
      form.getCheckBox('DISAPPROVED CHECK').check();
    }

    form.getTextField('REVIEWER DATE').setText(moment(data.request_is_approved_date ?? '').format('DD MMM YY').toUpperCase());
    form.getTextField('APP COMMENTS').setText(data.approver_comment ?? '');

    const signature_uri = decodeBase64(await get_image_s3('signatures/' + data.approver_signature_id, 'image/png', global.token)).split(',').pop();
    const img = await pdfDoc.embedPng(signature_uri);
    pdfDoc.getPage(0).drawImage(img, {
      x: 330,
      y: 80,
      width: 50,
      height: 50,
    });
  }

  form.flatten();
  const pdfBytes = await pdfDoc.saveAsBase64();

  if (getBase64) {
    return pdfBytes;
  } else {
    pdfIn(pdfBytes);
  }
};

// Generates the Dental form, exported to be used in other screens
// form_id - form_id of the PDF being generated
// data - JSON containing all of the form data
// navigation - REACT Native prop that holds the screen stack, used when a user is redirected to view PDF screen
// getBase64 - True: returns base64 of PDF, False: redirects user to View PDF Screen
// is_deletable - True: form can be deleted, False: form cannot be deleted (depends on the forms status)
export const generate_dental = async (form_id, data, navigation, getBase64, is_deletable) => {
  const pdfDoc = await PDFDocument.load(form_dental.pdf());
  const form = pdfDoc.getForm();

  form.getField('SERVICE MEMBER NAME').setText(data.name === null ? "" : data.name);
  form.getField('DOD ID NUMBER').setText(data.dod.toString() ?? '');
  form.getField('BRANCH OF SERVICE').setText(data.branch_of_service ?? '');
  form.getField('UNIT OF ASSIGNMENT').setText(data.unit_assignment ?? '');
  form.getField('UNIT ADDRESS').setText(data.unit_address ?? '');

  if (data.examination_result != null) {
    if (data.examination_result == 1) {
      form.getCheckBox('CHECK1').check();
    } else if (data.examination_result == 2) {
      form.getCheckBox('CHECK2').check();
    } else if (data.examination_result == 3) {
      form.getCheckBox('CHECK3').check();
      if (data.conditions[0]) form.getCheckBox('CHECK3A').check();
      if (data.conditions[1]) form.getCheckBox('CHECK3B').check();
      if (data.conditions[2]) form.getCheckBox('CHECK3C').check();
      if (data.conditions[3]) form.getCheckBox('CHECK3D').check();
      if (data.conditions[4]) form.getCheckBox('CHECK3E').check();
      if (data.conditions[5]) form.getCheckBox('CHECK3F').check();
    }
  }

  form.getField('CONDITIONS').setText(data.optional_conditions ?? '');
  form.getField('X-RAYS USED').setText(data['x-rays_consulted'] ?? '');
  form.getField('X-RAY DATE').setText(data['x-rays_date'] ?? '');
  form.getField('DENTIST NAME').setText(data.dentist_name ?? '');
  form.getField('DENTIST NUMBER').setText(data.dentist_phone ?? '');
  form.getField('DENTIST LICENSE').setText(data.dentist_license ?? '');
  form.getField('EXAM DATE').setText(data.date_of_examination ?? '');

  if (data.dentist_signature_id != null && data.dentist_signature_id != '') {
    const signature_uri = decodeBase64(await get_image_s3('signatures/' + data.dentist_signature_id, 'image/png', global.token)).split(',').pop();
    const img = await pdfDoc.embedPng(signature_uri);
    pdfDoc.getPage(0).drawImage(img, {
      x: 30,
      y: 35,
      width: 100,
      height: 30,
    });
  }

  form.flatten();
  const pdfBytes = await pdfDoc.saveAsBase64();

  if (getBase64) {
    return pdfBytes;
  } else {
    pdfIn(pdfBytes);
  }
};

// Generates the 1380 form, exported to be used in other screens
// form_id - form_id of the PDF being generated
// data - JSON containing all of the form data
// navigation - REACT Native prop that holds the screen stack, used when a user is redirected to view PDF screen
// getBase64 - True: returns base64 of PDF, False: redirects user to View PDF Screen
// is_deletable - True: form can be deleted, False: form cannot be deleted (depends on the forms status)
// attachments - array of attachment names or keys
// creator_id - user_id of user who created the form (needed to get images/pdfs of form)
export const generate_1380 = async (form_id, data, navigation, getBase64, is_deletable, attachments, creator_id) => {
  let newForm = form_id > 3593 || form_id === 0 || form_id === '';
  const pdfDoc = await PDFDocument.load(newForm ? form_1380_new.pdf() : form_1380.pdf());

  const pdfwidth = pdfDoc.getPage(0).getWidth();
  const pdfheight = pdfDoc.getPage(0).getHeight();

  if (attachments) {
    for (let i = 0; i < attachments.length; i++) {
      if (attachments[i].includes('RST')) {
        const rst = await get_single_file({ form_id: attachments[i].split(' ').pop(), form_type: 'RST' }, global.token);
        const rst_base_64 = await generate_rst(rst.body.form_id, rst.body, navigation, true, false);
        const pdf = await PDFDocument.load(rst_base_64);
        const copiedPages = await pdfDoc.copyPages(pdf, pdf.getPageIndices());

        for (const page of copiedPages) {
          pdfDoc.addPage(page);
        }
      } else if (attachments[i].includes('dental')) {
        const dental = await get_single_file({ form_id: attachments[i].split(' ').pop(), form_type: 'dental' }, global.token);
        const dental_base_64 = await generate_dental(dental.body.form_id, dental.body, navigation, true, false);
        const pdf = await PDFDocument.load(dental_base_64);
        const copiedPages = await pdfDoc.copyPages(pdf, pdf.getPageIndices());

        for (const page of copiedPages) {
          pdfDoc.addPage(page);
        }
      } else if (attachments[i].includes('EBDL')) {
        const ebdl = await get_single_file({ form_id: attachments[i].split(' ').pop(), form_type: 'ebdl' }, global.token);
        const ebdl_base_64 = await generate_ebdl(ebdl.body.form_id, ebdl.body, navigation, true, false);
        const pdf = await PDFDocument.load(ebdl_base_64);
        const copiedPages = await pdfDoc.copyPages(pdf, pdf.getPageIndices());

        for (const page of copiedPages) {
          pdfDoc.addPage(page);
        }
      } else if (attachments[i].includes('ATA')) {
        const ata = await get_single_file({ form_id: attachments[i].split(' ').pop(), form_type: 'ata' }, global.token);
        const ata_base_64 = await generate_ata(ata.body.form_id, ata.body, navigation, true, false);
        const pdf = await PDFDocument.load(ata_base_64);
        const copiedPages = await pdfDoc.copyPages(pdf, pdf.getPageIndices());

        for (const page of copiedPages) {
          pdfDoc.addPage(page);
        }
      } else if (attachments[i].includes('MDR')) {
        const mdr = await get_single_file({ form_id: attachments[i].split(' ').pop(), form_type: 'mdr' }, global.token);
        const mdr_base_64 = await generate_mdr(mdr.body.form_id, mdr.body, navigation, true, false);
        const pdf = await PDFDocument.load(mdr_base_64);
        const copiedPages = await pdfDoc.copyPages(pdf, pdf.getPageIndices());

        for (const page of copiedPages) {
          pdfDoc.addPage(page);
        }
      } else if (attachments[i].includes('jpg') || attachments[i].includes('png')) {
        const document = await PDFDocument.create();
        const image = document.addPage([pdfwidth, pdfheight]);

        const path = 'additional_documentation_1380s/' + creator_id + '/' + form_id + '/' + attachments[i];
        const content_type = (attachments[i].includes('jpg')) ? 'image/jpg' : 'image/png';
        const file = decodeBase64(await get_image_s3(path, content_type, global.token)).split(',').pop();

        const img = (attachments[i].includes('jpg')) ? await document.embedJpg(file) : await document.embedPng(file);
        const { width, height } = img.scaleToFit(pdfwidth, pdfheight);
        image.drawImage(img, {
          width: width,
          height: height,
        });

        const copiedPages = await pdfDoc.copyPages(document, [0]);

        for (const page of copiedPages) {
          pdfDoc.addPage(page);
        }
      } else if (attachments[i].includes('pdf') && !attachments[i].includes('RST') && !attachments[i].includes('dental')) {
        const path = 'additional_documentation_1380s/' + creator_id + '/' + form_id + '/' + attachments[i];
        const content_type = 'application/pdf';
        const file = decodeBase64(await get_image_s3(path, content_type, global.token)).split(',').pop();
        const pdf = await PDFDocument.load(file);
        const copiedPages = await pdfDoc.copyPages(pdf, pdf.getPageIndices());

        for (const page of copiedPages) {
          pdfDoc.addPage(page);
        }
      }
    }
  }

  if (newForm) {
    if (data?.signature_id_11) {
      const signature_uri = decodeBase64(await get_image_s3('signatures/' + data.signature_id_11, 'image/png', global.token)).split(',').pop();
      const img = await pdfDoc.embedPng(signature_uri);
      pdfDoc.getPage(0).drawImage(img, {
        x: 380,
        y: 110,
        width: 100,
        height: 25,
      });
    }
  
    if (data?.signature_id_12) {
      const signature_uri = decodeBase64(await get_image_s3('signatures/' + data.signature_id_12, 'image/png', global.token)).split(',').pop();
      const img = await pdfDoc.embedPng(signature_uri);
      pdfDoc.getPage(0).drawImage(img, {
        x: 380,
        y: 65,
        width: 100,
        height: 25,
      });
    }
  } else {
    if (data?.signature_id_11) {
      const signature_uri = decodeBase64(await get_image_s3('signatures/' + data.signature_id_11, 'image/png', global.token)).split(',').pop();
      const img = await pdfDoc.embedPng(signature_uri);
      pdfDoc.getPage(0).drawImage(img, {
        x: 320,
        y: 65,
        width: 100,
        height: 25,
      });
    }
  }

  // Get all the fields from 1380 form
  const form = pdfDoc.getForm();

  let name_10 = ''
  let name_11 = ''

  if (data?.name_10) {
    name_10 = data.name_10;
  } else if (data?.reviewer_ids?.length === 1) {
    name_10 = data.reviewer_rank + ' ' + data.reviewer_name + ', ' + data.reviewer_grade;
  }

  if (data?.name_11) {
    name_11 = data.name_11
  } else if (data?.validator_ids?.length === 1) {
    name_11 = data.validator_rank + ' ' + data.validator_name + ', ' + data.validator_grade
  }


  // similar text fields between old and new forms
  form.getField('2 FROM').setText(data.from_2 ?? '');
  form.getField('3 ANNIVERSARY YEAR ENDING DATE').setText(data.anniversary_year_3 ?? '');
  form.getField('4 TO').setText(data.to_4 ?? '');
  form.getField('5 NAME').setText(data.name_5 ?? '');
  form.getField('8 INDIVIDUALS ASSIGNED ORGANIZATION').setText(data.individuals_assigned_orgnanization_8 ?? '');
  form.getField('10 TYPED NAME GRADE AND POSITION OF OFFICER HAVING KNOWLEDGE OF DUTIES PERFORMED').setText(name_10 ?? '');

  if (newForm) {
    form.getField('0 FORMID').setText((data.form_id ? '#' + data.form_id.toString() : ''));
    form.getField('1 DATE').setText((data.creation_date_1 ?? ''));
    form.getField('5a DOD ID NO').setText(data.dod.toString() ?? '');
    form.getField('6 RANK').setText(data.rank ?? '');
    form.getField('7 MOS').setText(data.mos ?? '')
    form.getField('12 FOR RECORDS MANAGER OF INDIVIDUAL SOLDIERS RECORDS').setText(name_11 ?? '');
  } else {
    console.log(data?.cite_Authorization_9)
    form.getField('1 DATE').setText((data.creation_date_1 ?? '') + "\t#" + (form_id === 0 ? 'XXXX' : form_id));
    form.getField('6 GRADE').setText(data.grade_6 ?? '');
    form.getField('7 BRANCH').setText(data.branch_7 ?? '');
    form.getField('9 CITE AUTHORIZATION').setText(data.cite_Authorization_9 ?? '');
  }

  // get all of the events fields
  if (data.date_9a) {
    for (let i = 0; i < data.date_9a.length; i++) {
      const eventDate = new Date(data.date_9a[i]);
      // Create array of event fields
      form.getField('DAY ' + (i + 1)).setText((eventDate ?? '').getDate().toString());
      form.getField('MONTH ' + (i + 1)).setText(((eventDate ?? '').getMonth() + 1).toString());
      form.getField('YEAR ' + (i + 1)).setText((eventDate ?? '').getFullYear().toString().slice(-2));
      form.getField('HOURS ' + (i + 1)).setText(data.hours_9b[i]);
      form.getField('RETIREMENT POINTS ' + (i + 1)).setText(data.retirement_points_9c[i]);
      form.getField('LOCATION OF DUTIES NATURE OF DUTIES TRAINING OR INSTRUCTION ' + (i + 1)).setText(data.description_9d[i]);
    }
  }

  const otherCheckField = form.getCheckBox('9 OTHER');
  const otherTextField = form.getField('9 OTHER TEXT');
  switch (data.duty_performed_9) {
    case 'RST':
    case 'rst':
      otherCheckField.check();
      otherTextField.setText('RST Code 11');
      break;
    case 'AFTP':
    case 'aftp':
      otherCheckField.check();
      otherTextField.setText('AFTP Code 31');
      break;
    case 'MDR':
    case 'mdr':
      otherCheckField.check();
      otherTextField.setText('MDR Code 61');
      break;
    case 'RMA':
    case 'rma':
      otherCheckField.check();
      otherTextField.setText('RMA Code 91');
      break;
    case 'EBDL':
    case 'ebdl':
      otherCheckField.check();
      otherTextField.setText('EBDL Code 42');
      break;
    case 'OTHER':
    case 'other':
      otherCheckField.check();
      otherTextField.setText('');
      break;
  }

  if (newForm) {
    if (data?.hdip_acip_authorized_9) {
      form.getCheckBox('Incentive yes no').check();
      if (data?.incentive_pay) {
        form.getField('Incentive Pay').setText(data.incentive_pay ?? '');
      }
    }
  }

  form.flatten();
  const pdfBytes = await pdfDoc.saveAsBase64();

  if (getBase64) {
    return pdfBytes;
  } else {
    pdfIn(pdfBytes);
  }
};

// Generates the AFTP form, exported to be used in other screens
// form_id - form_id of the PDF being generated
// data - JSON containing all of the form data
// navigation - REACT Native prop that holds the screen stack, used when a user is redirected to view PDF screen
// getBase64 - True: returns base64 of PDF, False: redirects user to View PDF Screen
// is_deletable - True: form can be deleted, False: form cannot be deleted (depends on the forms status)
// attachments - array of attachment names or keys
// creator_id - user_id of user who created the form (needed to get images/pdfs of form)
export const generate_1380_aftp = async (form_id, data, navigation, getBase64, is_deletable, attachments, creator_id) => {
  let newForm = form_id > 3593 || form_id === 0;
  const pdfDoc = await PDFDocument.load(newForm ? form_1380_aftp_new.pdf() : form_1380_aftp.pdf());

  if (newForm) {
    if (data.signature_id_11 != null && data.signature_id_11 != '') {
      const signature_uri = decodeBase64(await get_image_s3('signatures/' + data.signature_id_11, 'image/png', global.token)).split(',').pop();
      const img = await pdfDoc.embedPng(signature_uri);
      pdfDoc.getPage(0).drawImage(img, {
        x: 380,
        y: 110,
        width: 100,
        height: 25,
      });
      pdfDoc.getPage(0).drawImage(img, {
        x: 380,
        y: 65,
        width: 100,
        height: 25,
      });
    }
  } else {
    if (data.signature_id_11 != null && data.signature_id_11 != '') {
      const signature_uri = decodeBase64(await get_image_s3('signatures/' + data.signature_id_11, 'image/png', global.token)).split(',').pop();
      const img = await pdfDoc.embedPng(signature_uri);
      pdfDoc.getPage(0).drawImage(img, {
        x: 320,
        y: 65,
        width: 100,
        height: 25,
      });
    }
  }

  let name_10 = ''

  if (data.name_10) {
    name_10 = data.name_10;
  } else if (data?.reviewer_ids?.length === 1) {
    name_10 = data.reviewer_rank + ' ' + data.reviewer_name + ', ' + data.reviewer_grade;
  }

  const form = pdfDoc.getForm();
  // similar text fields between old and new forms
  form.getField('2 FROM').setText(data.from_2 ?? '');
  form.getField('3 ANNIVERSARY YEAR ENDING DATE').setText(data.anniversary_year_3 ?? '');
  form.getField('4 TO').setText(data.to_4 ?? '');
  form.getField('5 NAME').setText(data.name_5 ?? '');
  form.getField('8 INDIVIDUALS ASSIGNED ORGANIZATION').setText(data.individuals_assigned_orgnanization_8 ?? '');
  form.getField('10 TYPED NAME GRADE AND POSITION OF OFFICER HAVING KNOWLEDGE OF DUTIES PERFORMED').setText(name_10 ?? '');

  if (newForm) {
    form.getField('0 FORMID').setText((data.form_id ? '#' + data.form_id.toString() : ''));
    form.getField('1 DATE').setText((data.creation_date_1 ?? ''));
    form.getField('5a DOD ID NO').setText(data.dod.toString() ?? '');
    form.getField('6 RANK').setText(data.rank ?? '');
    form.getField('7 MOS').setText(data.mos ?? '')
    form.getField('12 FOR RECORDS MANAGER OF INDIVIDUAL SOLDIERS RECORDS').setText(name_10 ?? '');
  } else {
    form.getField('1 DATE').setText((data.creation_date_1 ?? '') + "\t#" + (form_id === 0 ? 'XXXX' : form_id));
    form.getField('6 GRADE').setText(data.grade_6 ?? '');
    form.getField('7 BRANCH').setText(data.branch_7 ?? '');
  }

  // get all of the events fields
  if (data.date_9a) {
    for (let i = 0; i < data.date_9a.length; i++) {
      const eventDate = new Date(data.date_9a[i]);

      form.getField('DAY ' + (i + 1)).setText(eventDate.getDate().toString() ?? '');
      form.getField('MONTH ' + (i + 1)).setText((eventDate.getMonth() + 1).toString() ?? '');
      form.getField('YEAR ' + (i + 1)).setText(eventDate.getFullYear().toString().slice(-2) ?? '');

      form.getField('HOURS ' + (i + 1)).setText(data.hours_9b?.[i] ?? '');
      form.getField('RETIREMENT POINTS ' + (i + 1)).setText(data.retirement_points_9c?.[i] ?? '');
      form.getField('AFTP ' + (i + 1)).setText(data.aftp_number_9d?.[i] ?? '');
      form.getField('SUPPORT ' + (i + 1)).setText(data.support_number_9d?.[i] ?? '');
      form.getField('TIME IN ' + (i + 1)).setText(data.time_in_9d?.[i] ?? '');
      form.getField('TIME OUT ' + (i + 1)).setText(data.time_out_9d?.[i] ?? '');
      form.getField('ACFT ' + (i + 1)).setText(data.acft_9d?.[i] ?? '');
      form.getField('FLT HRS ' + (i + 1)).setText(data.flt_hrs_9d?.[i] ?? '');
      form.getField('TYPE OF TRAINING PERIOD ' + (i + 1)).setText(data.type_of_training_completed_9d?.[i] ?? '');
    }
  }

  form.getCheckBox(!newForm ? '9 OTHER' : 'OTHER').check();

  if (data.hdip_acip_authorized_9) {
    form.getCheckBox('HDIP/ACIP AUTHORIZED').check();
  }

  form.flatten();
  const pdfBytes = await pdfDoc.saveAsBase64();

  if (getBase64) {
    return pdfBytes;
  } else {
    pdfIn(pdfBytes);
  }
};

// Use if form meta data is not available or if form type is unknown
// form_id - form_id of the PDF being generated
// data - JSON containing all of the form data
// navigation - REACT Native prop that holds the screen stack, used when a user is redirected to view PDF screen
// getBase64 - True: returns base64 of PDF, False: redirects user to View PDF Screen
// is_deletable - True: form can be deleted, False: form cannot be deleted (depends on the forms status)
// attachments - array of attachment names or keys
// creator_id - user_id of user who created the form (needed to get images/pdfs of form)
export const generatePDF = async (form_id, form_type, navigation, getBase64, is_deletable, attachments, creator_id) => {
  const data = await get_single_file({ form_id: form_id, form_type: form_type }, global.token);

  if (data == undefined) {
    return;
  } else if (data.statusCode == 400) {
    console.log(data);
    return;
  } else if (data.errorMessage === 'current transaction is aborted, commands ignored until end of transaction block') {
    console.log('Backend endpoint is down - database/sql error - quick fix is to redeploy get_single_file endpoint');
    return;
  } else {
    if (form_type === 'RST') {
      if (getBase64) {
        return await generate_rst(form_id, data.body, navigation, getBase64, is_deletable);
      } else {
        await generate_rst(form_id, data.body, navigation, getBase64, is_deletable);
      }
    } else if (form_type === 'dental') {
      if (getBase64) {
        return await generate_dental(form_id, data.body, navigation, getBase64, is_deletable);
      } else {
        await generate_dental(form_id, data.body, navigation, getBase64, is_deletable);
      }
    } else if (form_type === '1380') {
      if (getBase64) {
        return await generate_1380(form_id, data.body, navigation, getBase64, is_deletable, attachments, creator_id);
      } else {
        await generate_1380(form_id, data.body, navigation, getBase64, is_deletable, attachments, creator_id);
      }
    } else if (form_type === '1380 aftp') {
      if (getBase64) {
        return await generate_1380_aftp(form_id, data.body, navigation, getBase64, is_deletable, attachments, creator_id);
      } else {
        await generate_1380_aftp(form_id, data.body, navigation, getBase64, is_deletable, attachments, creator_id);
      }
    } else if (form_type === 'EBDL') {
      if (getBase64) {
        return await generate_ebdl(form_id, data.body, navigation, getBase64, is_deletable);
      } else {
        await generate_ebdl(form_id, data.body, navigation, getBase64, is_deletable);
      }
    } else if (form_type === 'ATA') {
      if (getBase64) {
        return await generate_ata(form_id, data.body, navigation, getBase64, is_deletable);
      } else {
        await generate_ata(form_id, data.body, navigation, getBase64, is_deletable);
      }
    } else if (form_type === 'MDR') {
      if (getBase64) {
        return await generate_mdr(form_id, data.body, navigation, getBase64, is_deletable);
      } else {
        await generate_mdr(form_id, data.body, navigation, getBase64, is_deletable);
      }
    } else {
      console.log('Invalid form type');
    }
  }
};
