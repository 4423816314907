import React, { useContext, useEffect } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import * as Linking from 'expo-linking';

import AuthStack from './AuthStack';
import AppStack from './AppStack';
import ModeContext from '../components/ModeContext';
import { AuthUserContext } from './AuthUserProvider';
import MainLayout from './MainLayout.js'

import { getJsonData, getData, removeData } from '../api_handler/asyncstorage';

const prefix = Linking.makeUrl('/');

function Routes(props) {
    const { user, setUser } = useContext(AuthUserContext);
    const { mode, toggleMode } = useContext(ModeContext);

    // Parses JWT Token (we use it to get expiration timestamp)
    function parseJwt(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }

    // This function gets cached data during refresh of the website
    // This function is called during login as well (unintended consequence)
    async function handleRefresh() {
        // Retrieve cached data
        const data = await getJsonData('user_data');
        const token = await getData('idToken');
        const signature = await getData('signature');
        const pfp = await getData('pfp')
        const editDate = await getJsonData('editBADate');

        // If cached data is found and the token is not expired
        // Keep user logged in, else redirect user to the login screen
        if (data != null) {
            if (isTokenExpired(token)) {
                // Sign user out
                signOut();
            } else {
                global.user_data = data;
                global.token = token;
                global.signature_uri = signature;
                global.profile_pic = pfp;
                global.editDate = editDate;

                // Update theme (stored in cache)
                if (global.user_data.theme === "dark" && mode === "light") toggleMode();
                if (global.user_data.theme === "light" && mode === "dark") toggleMode();

                // Navigate to home page or last page user navigated to
                setUser(true);
            }
        } else {
            console.log('User must login as no user data in cache');
            setUser(false);
        }
    }

    function isTokenExpired(token) {
        // Get current date/time in unix timestamp format and add one hour (the hour is a buffer)
        const currentDate = new Date();
        const unixTimestamp = Math.floor(currentDate.getTime() / 1000) + (60 * 60);

        if (unixTimestamp >= parseJwt(token).exp) {
            return true;
        } else {
            return false;
        }
    }

    useEffect(() => {
        handleRefresh();
        // Check token every minute
        if (user == true) {
            const interval = setInterval(() => {
                if (token == null) {
                    clearInterval(interval);
                } else if (isTokenExpired(token)) {
                    signOut();
                    clearInterval(interval);
                } else {
                    console.log('Token is still valid.');
                }
            }, 60000);
        }
    }, [user]);

    // Sign out function
    const signOut = async () => {
        console.log('Token has expired!');

        await removeData('user_data');
        await removeData('idToken');
        await removeData('signature');

        await setUser(false);

        global.token = null;
        global.user_data = null;
        global.profile_pic = null;
        global.revFiles = null;
        global.approvFiles = null;
        global.signature_uri = null;
        global.editUserID = null;
        global.reviewRec = null;
        global.reviewRecRst = null;
        global.reviewRst = null;
        global.recRst = null;

        navigation.navigate('Login');
    };

    // Linking stuff, never truly finished it
    const linking = {
        prefixes: [prefix],
        LeadersDashboard: {
            screens: {
                Leader: "Leader",
                DocumentOverview: "Document Overview",
                MedicalOverview: "Medical Overview",
                TrainingOverview: "Training Overview",
                BudgetOverview: "Budget Overview",
                NotFound: "*"
            },
        },
    };

    // If user is logged in, show app stack, otherwise show auth stack
    // Note: we should make a better fallback page (make sure its static though, no API calls)
    return (
        <NavigationContainer linking={linking} fallback={<Text>Loading...</Text>}>
          {user ? (
            <MainLayout>
              <AppStack />
            </MainLayout>
          ) : (
            <AuthStack />
          )}
        </NavigationContainer>
      );
}

export default Routes;
