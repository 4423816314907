/* eslint-disable require-jsdoc */
import React, { useContext, useEffect, useState, useRef } from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import {get_user_subordinates} from '../../api_handler/users';
import dayjs from 'dayjs';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import {createTheme, ThemeProvider, useTheme} from '@mui/material/styles';
import {create_event} from '../../api_handler/events';
import {create_notification} from '../../api_handler/notifications';
import { Scrollbars } from 'react-custom-scrollbars';
import { requestCalendarEvents } from './CalendarFunctions';
import { ClickAwayListener } from '@mui/base';

//import Modal from 'react-native-modal';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Chip,
  Box,
  Dialog,
  FormControlLabel,
  Checkbox,
  FormHelperText
} from '@mui/material';
import { useColorContext } from '../ColorContext';
import { useSnackbar } from '../../components/SnackbarContext';


export default function AddEventModal({ state, setState}) {
  const { colors } = useColorContext();
  const { showSnackbar } = useSnackbar();
  //Event Section
  const [eventTitle, setEventTitle] = useState("");
  const [otherEventTitle, setOtherEventTitle] = useState("");
  const [eventTitleItems, setEventTitleItems] = useState([
    { label: 'BA', value: ' BA' },
    { label: 'ECT', value: ' ECT' },
    { label: 'Other title', value: 'Other title:' },
  ]);
  const [eventTitleError, setEventTitleError] = useState(false);

  //Training Section
  const [trainingEvents, setTrainingEvents] = useState([]);
  const [otherTrainingEvents, setOtherTrainingEvents] = useState("");
  const [trainingItems, setTrainingItems] = useState([
    { label: 'Range', value: ' Range' },
    { label: 'ACFT', value: ' ACFT' },
    { label: 'CBRN Chamber', value: ' CBRN Chamber' },
    { label: 'Driver\'s Training', value: ' Driver\'s Training' },
  ]);
  const [trainingEventsError, setTrainingEventsError] = useState(false);

  //MUTAs
  const [muta, setMuta] = useState(1);
  const [mutaItems, setMutaItems] = useState([
    {label: 'ECT', value: 0},
    {label: '1', value: 1},
    {label: '2', value: 2},
    {label: '3', value: 3},
    {label: '4', value: 4},
    {label: '5', value: 5},
    {label: '6', value: 6},
    {label: '7', value: 7},
    {label: '8', value: 8},
  ]);

  const [mutaError , setMutaError] = useState(false);

  //Event Type
  const [eventType, setEventType] = useState("");
  const [eventTypeItems, setEventTypeItems] = useState([
    {label: 'Mandatory', value: 'Mandatory'},
    {label: 'Optional', value: 'Optional'},
    {label: 'Personal', value: 'Personal'},
  ]);

  const [eventTypeError, setEventTypeError] = useState(false);

  useFocusEffect(
      React.useCallback(() => {
        // This effect is used to prevent navigation instantly if date picker is open
        const beforeRemoveListener = (e) => {
          if (!dateTimePicker1Open && !dateTimePicker2Open) {
            return;
          }

          e.preventDefault();

          setDateTimePicker1Open(false);
          setDateTimePicker2Open(false);
          // navigation.dispatch(e.data.action);
          setTimeout(() => {
            navigation.dispatch(e.data.action);
          }, 10);
        };

        navigation.addListener('beforeRemove', beforeRemoveListener);

        return () => {
          navigation.removeListener('beforeRemove', beforeRemoveListener);
        };
      }, [dateTimePicker1Open, dateTimePicker2Open])
  );

  //Date and Time
  useEffect(() => {
    if (state.selectedDay !== null) {
      const selectedDayAsDate = dayjs(state.selectedDay);
      setStartDateTime(selectedDayAsDate);
    } else {
      setStartDateTime(null);
    }
  }, [state.selectedDay]);
  const [startDateTime, setStartDateTime] = useState(null);
  const [endDateTime, setEndDateTime] = useState(null);

  const theme = useTheme();
  const calendarTheme = createTheme({
    palette: {
      mode: colors.mode,
      primary: theme.palette.primary,
    },
  });
  const [startDateTimeError, setStartDateTimeError] = useState(false);
  const [endDateTimeError, setEndDateTimeError] = useState(false);
  const [badDateError, setBadDateError] = useState(false);
  
  const [invitees, setInvitees] = useState([]);

  const getEventInfo = async () => {
    
    const getUserSubordinatesJSON = {'user_id': global.user_data.user_id};
    const subsInfo = await get_user_subordinates(getUserSubordinatesJSON, global.token);
    if (subsInfo.status !== 200) {
      showSnackbar("error", "Application Error: Failed to fetch potential invitees information");
    } else {
      // Create an array with all user's subordinates info (ids, names, and if they are invited)
      const allInvitees = subsInfo.data.body.map((info) => ({
        id: info.user_id,
        name: info.name,
      }));

      setInviteItems(allInvitees);
    }
  };
  const [inviteItems, setInviteItems] = useState([]);
  useEffect(() => {
    if (state.showEventModal) {
      getEventInfo();
    }
  }, [state.showEventModal]);
  const [inviteesError, setInviteesError] = useState(false);

  const [dateTimePicker1Open, setDateTimePicker1Open] = React.useState(false);
  const [dateTimePicker2Open, setDateTimePicker2Open] = React.useState(false);
  const dateTimePicker1Ref = useRef(null);
  const dateTimePicker2Ref = useRef(null);

  //Location
  const [location, setLocation] = useState("");

  const [locationError, setLocationError] = useState(false);

  //Description
  const [description, setDescription] = useState("");

  const [descriptionError, setDescriptionError] = useState(false);

  //Remarks
  const [remarks, setRemarks] = useState("");
  const [remarksError, setRemarksError] = useState(false);

  //More Remarks
  const [moreRemarks, setMoreRemarks] = useState("");

  //Repeat weekly or yearly
  const [repeatWeekly, setRepeatWeekly] = useState(false);
  const [repeatYearly, setRepeatYearly] = useState(false);

  function setDefault(){
    setEventTitle("");
    setOtherEventTitle("");
    setTrainingEvents([]);
    setOtherTrainingEvents("");
    setMuta(1);
    setEventType("");
    setStartDateTime(null);
    setEndDateTime(null);
    setInvitees([]);
    setLocation("");
    setDescription("");
    setRemarks("");
    setMoreRemarks("");
    setRepeatWeekly(false);
    setRepeatYearly(false);

    setErrorDefault()
  }
  // sets all errors to false too, this would only run if all the inputs are good or on modal close
  function setErrorDefault(){

    setEventTitleError(false);
    setTrainingEventsError(false);
    setMutaError(false);
    setEventTypeError(false);
    setStartDateTimeError(false);
    setEndDateTimeError(false);
    setInviteesError(false);
    setLocationError(false);
    setDescriptionError(false);
    setRemarksError(false);
  }

  
  const style = {
    modalContainer: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      // backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContent: {
      //backgroundColor: colors.screen_background,
      borderRadius: 50,
      //justifyContent: 'flex-start',
      alignItems: 'center',
      justifyContent:'center',
      width: '50%',
      height: '70%',
    },
    titleText: {
      color: colors.text,
      fontSize: 40,
      //marginTop: '6%',
    },
    row: {
      width: '100%',
      flex: 1,
      flexDirection: 'row',
      color: colors.text,
      padding: '5%',
    },

    dropDownContainer: {
      width: '50%',
    },
    dropDownStyle: {
      borderColor: colors.text,
      color: colors.text,
      borderWidth: 1,
      width: '100%',
      //backgroundColor: colors.modal_background,
    },
    menuItem: {
    },
    scrollViewContent: {
      flexGrow: 1, 
      padding: 5,
    },
    entryText: {
      color: colors.text,
      textAlign: 'left',
      fontSize: 26,
      flex: 1,
      width: '100%'
    },
    closeButton: {
      flex:1,
      color: colors.text,
      alignItems: 'flex-end'
    },
    input: {
      borderWidth: 1,
      padding: 3,
      borderRadius: 5,
      fontSize: 18,
      width: '70%',
    },
    dateBox: {
      'margin': 1.5,
      'width': '25%',
      'backgroundColor':  colors.textfield_background,
      '& .MuiFormLabel-root, & label.Mui-focused, & .MuiInputBase-input': {
        color:  colors.inputted_text,
      },
      '.MuiSvgIcon-root ': {
        fill:  colors.inputted_text,
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor:  colors.field_set,
        },
        '&:hover fieldset': {
          borderColor:  colors.form_border,
        },
        '&.Mui-focused fieldset': {
          borderColor: '#1876d2',
        },
      },
      '& .MuiFormLabel-root.Mui-error': {
        color:  colors.placeholder_text,
      },
      '& .MuiFormHelperText-root.Mui-error': {
        backgroundColor:  colors.screen_background,
        color: '#d32f2f',
        margin: 0,
        paddingLeft: 1,
      },
    },
    textInputProps: {
      '& .MuiFormLabel-root, & label.Mui-focused, & .MuiInputBase-input': {
        color:  colors.inputted_text,
      },
      '.MuiSvgIcon-root': {
        fill:  colors.inputted_text,
      },
      '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
          borderColor: colors.form_border,
        },
        '&.Mui-focused fieldset': {
          borderColor: '#1876d2',
        },
      },
      '&  .MuiFormHelperText-root': {
        backgroundColor:  colors.screen_background,
        margin: 0,
        paddingLeft: 1,
      },
      // .MuiFormHelperText-root.Mui-error
    },
    submitButton: {
      fontSize: 34,
      backgroundColor: colors.navSideBar_Background,
      color: colors.bow,
      borderRadius: 50,
      padding: '2%',
      //flex:1,
      //alignItems:'flex-end',
      //width:'10%',
    },
    menuProps: {
      PaperProps: {
        style: {
          maxHeight: '40%',
          width: '5%',
        },
      },
      style: {
        maxHeight: '100%',
      },
    },
    checkRow: {
      flex: 1,
      alignItems: 'flex-end'
    }
  };

  const textBox = ( value, setter, placeholder, error, errorText, isRequired ) => {
    return (
      <View style={{ alignItems:'flex-end',flex:1}}>
        <ThemeProvider theme={calendarTheme}>
          <TextField
            style={style.input}
            // sx={style.textInputProps}
            required={isRequired}
            label= {placeholder}
            onChange={(data) => setter(data.target.value)}
            onBlur={() => setter(value.trim())}
            value={value}
            error={error}
            helperText={error ? errorText : ''}
            multiline
          />
        </ThemeProvider>
      </View>);
  }

  const selectBox = ( value, setter, title, items,error,errorText) => {
    return (
      <View style={{ alignItems:'flex-end',flex:1, width:'100%'}}>
        <ThemeProvider theme={calendarTheme}>
          <TextField
            select
            required={true}
            label= {title}
            value={value}
            error={error}
            style={{width:'30%'}}
            helperText={error ? errorText : ''}
            onChange={(event) => { setter(event.target.value) }}
          >
            {items.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </ThemeProvider>
      </View>);
  }
  // Send to all invitees_id array given type and title
  const sendNotifsToInvitees = async (type, title, invitees_id) => {
    const text = (type == 'Optional') ? 'an ' : 'a ';

    for (const i in invitees_id) {
      const notificationContents = {
        title: 'You\'ve been invited!',
        body: 'You have been invited to ' + text + type + ' event: ' + title,
        receiver_id: invitees_id[i],
        notification_type: "event",
        object_type: "event invite",
      };

      await create_notification(notificationContents, global.token);
    }
  };


  //Handles the cases where we can/cannot submit. If we cannot, we will make the box red to let the people know there is an error there
  const isError = () => {
    let error = false;
    // Check empty title
    if (eventTitle === 'Other title:') {
      if (otherEventTitle.trim() === '') {
        error = true;
        setEventTitleError(true);
      } else {
        setEventTitleError(false);
      }
    } else  if (eventTitle === '') {
      setEventTitleError(true);
    }
    else {
      setEventTitleError(false);
    }

    if (trainingEvents.length === 0 && otherTrainingEvents.trim().length === 0) {
      error = true;
      setTrainingEventsError(true);
    } else {
      setTrainingEventsError(false);
    }

    if (startDateTime === null) {
      error = true;
      setStartDateTimeError(true);
    } else {
      setStartDateTimeError(false);
    }

    if (endDateTime === null) {
      error = true;
      setEndDateTimeError(true);
    } else {
      setEndDateTimeError(false);
    }

    // If endDate happens before startDate
    if (endDateTime !== null && endDateTime <= startDateTime) {
      error = true;
      setBadDateError(true);
    } else {
      setBadDateError(false);
    }

    if(eventType === ''){
      error = true;
      setEventTypeError(true);
    } else {
      setEventTypeError(false);
    }

    //The only time you can set get away with not choosing anyone is if you dont have anyone to invite.
    if (invitees.length == 0 && inviteItems.length !==0 ){
      error = true;
      setInviteesError(true);
    } else {
      setInviteesError(false);
    }

    if (location === '') {
      error = true;
      setLocationError(true);
    } else {
      setLocationError(false);
    }

    if (description === '') {
      error = true;
      setDescriptionError(true);
    } else {
      setDescriptionError(false);
    }

    if (remarks === '') {
      error = true;
      setRemarksError(true);
    } else {
      setRemarksError(false);
    }



    return error;
  }

  //Submits the event created, and sends notifications to a person in the event.
  const submitEvent = async () => {
    //First need to check if all of the fields which are required are filled out
    if(!isError()){
      // if we are able to submit, we will do it here
      const inviteeIDs = invitees.map((item) => {return item.id});
      const title = eventTitle === 'Other title:' ? otherEventTitle : eventTitle;
      let isPeriod = endDateTime!==null &&  dayjs(startDateTime).format('YYYY-MM-DD') != dayjs(endDateTime).format('YYYY-MM-DD');

      const data = {
        'scheduled_events_id': global.eventID,
        'permission': (global.user_data.permissions.includes(2051) || global.user_data.permissions.includes(2052)) ? 2052 : 0,
        'organizer_id': global.user_data.user_id,
        'organizer_name': global.user_data.first_name + ' ' + global.user_data.middle_name + ' ' + global.user_data.last_name,
        'invitees_id': inviteeIDs ,
        'confirmed_id': (eventTitle != 'Personal')?inviteeIDs:inviteItems.map((item) => {return item.id}),
        'title': title,
        'location': location,
        'description': description,
        'period': isPeriod,
        'type': eventType,
        'muta': muta,
        'remarks': remarks,
        'remarks_2': moreRemarks,
        'training_events': trainingEvents.length!=0 ? trainingEvents.join(', ') + ", "+ otherTrainingEvents: otherTrainingEvents ,
        'starttime': dayjs(startDateTime).toISOString(),
        'endtime': endDateTime?dayjs(endDateTime).toISOString():dayjs(),
        'timestamp': new Date(),
        'weekly': isPeriod?false:repeatWeekly,
        'yearly': isPeriod?false:repeatYearly,

      };   
      let res = await create_event(data, global.token);

      if (res.status !== 200) {
        showSnackbar("error", "Failed to create the event");
      } else {
        //let the user know that if the event was weekly before and they make it ongoing, it cannot be weekly anymore
        if((repeatWeekly || repeatYearly) && isPeriod){
          alert("As the dates span 2+ days, the following event will not be weekly");
        }
        //close the modal... and sets isSubmitted to true so that the events will re-fetch with the new event!
        setTimeout( ()=> {setState((prevState) => ({
          ...prevState,
          showEventModal: false, selectedDay: undefined, isSubmitted: true
        }))},100);

        sendNotifsToInvitees(eventType, title, inviteeIDs);
        setDefault();
        showSnackbar("success", "Successfully created the event");
      }
    }
    else{
      showSnackbar("error", "Please correct the highlighted issues");
    }
  }

  return (
    
    <Dialog
    //does not open the add event modal if we know the user doesnt have the permissons to add an event.
      open={state.showEventModal && (global.user_data.permissions.includes(2051) || global.user_data.permissions.includes(2052))}
      onClose={() => {
        setState((prevState) => ({
          ...prevState,
          showEventModal: false, selectedDay: undefined
        }));
        setErrorDefault();
      }}
      fullWidth
      maxWidth="lg"
      classes={style.modalContent}
      PaperProps={{
        style: {
          backgroundColor: colors.screen_background,
          borderRadius: 50,
        },
      }}
    >
      <View style={style.modalContainer}>
    
        {/* Header */}
        <View style={style.row}>
          <Text style={style.titleText}>Add Event</Text>
          <TouchableOpacity
            style={style.closeButton}
            onPress={() => {
              setState((prevState) => ({
                ...prevState,
                showEventModal: false, selectedDay: undefined 
              }));
              setErrorDefault();
            }}
          >
            <CloseIcon />
          </TouchableOpacity>
        </View>

        {/* Event Metadata */}
        <ScrollView style={{ width: '100%' }} contentContainerStyle={style.scrollViewContent}>


          {/* Event Title */}
          <View style={style.row}>             
            <Text style={style.entryText}>Event Title *</Text>
            {selectBox( eventTitle, setEventTitle, "Event Title", eventTitleItems, eventTitleError, "Please Select an Event Title")}
          </View>
          {/* If other title is chosen, we want to show another text box where the new one can be set */}
          {eventTitle === 'Other title:' ? textBox(otherEventTitle, setOtherEventTitle, "Enter event title ", eventTitleError,"The event title cannot be empty",true) : console.log(eventTitle)}

          {/* Training Events */}
          <View style={style.row}>
            <Text style={style.entryText}>Training Events *</Text>
            <ThemeProvider theme={calendarTheme}>
              <FormControl
                fullWidth
                style={style.dropDownContainer} // Use the new dropDownContainer style
                error={trainingEventsError}
              >
                <InputLabel id="training_dropdown">Training Events</InputLabel>
                <Select
                  label="Training Events"
                  labelId="training_checkbox"
                  id="training-dropdown"
                  value={trainingEvents}
                  onChange={(event) => {
                    setTrainingEvents(event.target.value);
                  }}
                  // error={trainingEventsError}
                  // errorText={'Please Choose a value for training events'}
                  display="true"
                  multiple
                  style={style.dropDownStyle} // Use the new dropDownStyle
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                  MenuProps={style.menuProps}
                >
                  {trainingItems.map((item) => (
                    <MenuItem style={{fontSize:18}} key={item.label} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{trainingEventsError ? 'Please choose a value for training events through the dropdown or textbox below' : ''}</FormHelperText>
              </FormControl>
            </ThemeProvider>
          </View>
          {/* If the training val is filled, we are good to show any others */}
          {textBox(otherTrainingEvents,setOtherTrainingEvents,"Enter any other training events: ", trainingEventsError ,"Please enter a training event from the dropdown or in this textbox",false)}

          {/* MUTAs */}
          <View style={style.row}>
            <Text numberOfLines={1} style={style.entryText}>MUTAs *</Text>
            {selectBox(muta,setMuta,"MUTA Number",mutaItems,false, "" )}

          </View>

          {/* Event Type */}
          <View style={style.row}>
            <Text numberOfLines={1} style={style.entryText}>Event Type *</Text>            
            {selectBox(eventType,setEventType,"Event Type" ,eventTypeItems, eventTypeError,"Please Select an event type" )}
          </View>

          {/* Start Date and Time */}
          <View style={style.row}>
            <Text numberOfLines={1} style={style.entryText}>Start Date & Time *</Text>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <ThemeProvider theme={calendarTheme}>
                <ClickAwayListener
                  onClickAway={(event) => {
                    if (dateTimePicker1Ref.current && !dateTimePicker1Ref.current.contains(event.target)) {
                      setDateTimePicker1Open(false);
                    }
                  }}
                  mouseEvent="onMouseDown"
                >
                  <DateTimePicker
                    //sx={style.dateBox}
                    label="Start Date & Time *"
                    ampm={false}
                    onChange={(date) => setStartDateTime(date)}
                    value={startDateTime}
                    error={startDateTimeError}
                    onClose={() => setDateTimePicker1Open(false)}
                    onOpen={() => setDateTimePicker1Open(true)}
                    open={dateTimePicker1Open}
                    slotProps={{
                      textField: {
                        error: startDateTimeError,
                        helperText: startDateTimeError? 'Enter a date & time':' ',
                      },
                    }}
                    PopperProps={{
                      modifiers: [
                        {
                          name: 'eventListeners',
                          options: {
                            scroll: false,
                            resize: true,
                          },
                        },
                      ],
                      ref: (popperRef) => {
                        if (popperRef && popperRef.node) {
                          popperRef.node.id = 'calendar-element';
                          dateTimePicker1Ref.current = popperRef.node.querySelector('.MuiCalendarPicker-root');
                        }
                      },
                    }}
                  />
                </ClickAwayListener>
              </ThemeProvider>
            </LocalizationProvider>
          </View>

          {/* End Date and Time */}
          <View style={style.row}>
            <Text numberOfLines={1} style={style.entryText}>End Date & Time</Text>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <ThemeProvider theme={calendarTheme}>
                <ClickAwayListener
                  onClickAway={(event) => {
                    if (dateTimePicker2Ref.current && !dateTimePicker2Ref.current.contains(event.target)) {
                      setDateTimePicker2Open(false);
                    }
                  }}
                  mouseEvent="onMouseDown"
                >
                  <DateTimePicker
                    label="End Date & Time"
                    ampm={false}
                    onChange={(date) => setEndDateTime(date)}
                    value={endDateTime}
                    error={badDateError || endDateTimeError}
                    onClose={() => setDateTimePicker2Open(false)}
                    onOpen={() => setDateTimePicker2Open(true)}
                    open={dateTimePicker2Open}
                    slotProps={{
                      textField: {
                        error: badDateError || endDateTimeError,
                        helperText: badDateError ? 'Enter a date & time after the start date':'Please enter a valid end date and time',
                      },
                    }}
                    PopperProps={{
                      modifiers: [
                        {
                          name: 'eventListeners',
                          options: {
                            scroll: false,
                            resize: true,
                          },
                        },
                      ],
                      ref: (popperRef) => {
                        if (popperRef && popperRef.node) {
                          popperRef.node.id = 'calendar-element';
                          datetimePicker2Ref.current = popperRef.node.querySelector('.MuiCalendarPicker-root');
                        }
                      },
                    }}
                  />
                </ClickAwayListener>
              </ThemeProvider>
            </LocalizationProvider>
          </View>

          {/* Invitees */}
          <View style={style.row}>
            <Text numberOfLines={1} style={style.entryText}>Invitees *</Text>
            <ThemeProvider theme={calendarTheme}>
              <FormControl
                fullWidth
                style={style.dropDownContainer} // Use the new dropDownContainer style
                error={inviteesError}
              >
                <InputLabel id="invitee_dropdown">Invitees</InputLabel>
                <Select
                  key={inviteItems.length}
                  label="Invitees"
                  labelId="invitee_checkbox"
                  id="invitee-dropdown"
                  value={invitees}
                  onChange={(event) => {
                    setInvitees(event.target.value);
                  }}
                  display="true"
                  multiple
                  style={style.dropDownStyle}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value.id} label={value.name} />
                      ))}
                    </Box>
                  )}
                  MenuProps={style.menuProps}
                >
                  {inviteItems.map((item) => (
                    <MenuItem  style={{fontSize:18}} key={item.name} value={item}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{inviteesError?'Please select at least one invitee for this event':''}</FormHelperText>
              </FormControl>
            </ThemeProvider>
          </View>

          {/* Location */}
          <View style={style.row}>
            <Text numberOfLines={1} style={style.entryText}>Location *</Text>
            {textBox(location,setLocation,"Enter an event location", locationError, "Please enter a location", true)}
          </View>

          {/* Description */}
          <View style={style.row}>
            <Text numberOfLines={1} style={style.entryText}>Description *</Text>
            {textBox(description,setDescription,"Enter an event description",descriptionError,"Please enter a description",true)}
          </View>

          {/* Remarks */}
          <View style={style.row}>
            <Text numberOfLines={1} style={style.entryText}>Remarks *</Text>  
            {textBox(remarks,setRemarks,"e.g., Special Equipment, Uniform",remarksError, "Please enter a remark",true)}
          </View>

          {/* More Remarks */}
          <View style={style.row}>
            <Text numberOfLines={1} style={style.entryText}>More Remarks</Text>
            {textBox(moreRemarks,setMoreRemarks,"e.g., Instructor/Alternate",false,"",false)}
          </View>

          {/* Repeat areas */}
          {(endDateTime==null ||  dayjs(startDateTime).format('YYYY-MM-DD') === dayjs(endDateTime).format('YYYY-MM-DD')) &&
          (<View numberOfLines={1} style={style.row}>
            <ThemeProvider theme={calendarTheme}>
              <FormControlLabel style={style.checkRow} checked={repeatWeekly} control={<Checkbox onChange={() => { setRepeatWeekly(!repeatWeekly) }} />} label="Repeat Weekly" />
              <FormControlLabel style={style.checkRow} checked={repeatYearly} control={<Checkbox onChange={() => { setRepeatYearly(!repeatYearly) }} />} label="Repeat Yearly" />
            </ThemeProvider>
          </View>)}

          <View numberOfLines={1} style={style.row}>
            <View style={{flex:1, alignItems:'flex-end'}}>
              <TouchableOpacity style={style.submitButton} onPress={() => {submitEvent()}}><Text numberOfLines={1} style={style.entryText}>Add Event</Text></TouchableOpacity>
            </View>
          </View>
        </ScrollView>          
      </View>
    </Dialog>
    
  );
}
