import React, {useState} from 'react';
import {View, Text, TouchableOpacity} from 'react-native';
import {useFocusEffect} from '@react-navigation/native';
import {useScale} from '../components/scale';

import CustomHeader from '../components/header';
import ModeContext from '../components/ModeContext';
import NumberBadge from '../components/NumberBadge';
import {get_recommend_files, get_review_files} from '../api_handler/files';
import { useColorContext} from '../components/ColorContext';
import { useNavBarContext } from '../components/NavBarContext';

function Request({navigation}) {
  const {colors} = useColorContext();
  const { navState } = useNavBarContext();
  const scale = useScale();
  const styles = ({
    container: {
      flex: 1,
      backgroundColor: colors.screen_background,
    },
    header: {
      marginTop: '0%',
      height: 110,
      backgroundColor: colors.screen_background,
    },
    pageTitle: {
      fontSize: 30,
      fontWeight: 'bold',
      marginHorizontal: 5,
      textAlign: 'Left',
      paddingTop: 25,
      color: colors.text,
    },
    contentBox: {
      backgroundColor: colors.content_box,
      height: '50vh',
      width: '100%',
      borderRadius: '20px',
      justifyContent: 'center',
      padding: 10,
      paddingLeft: 10,
      paddingRight: 10,
      marginTop: 10,
    },
    button: {
      height: 80,
      borderRadius: 10,
      padding: 10,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: colors.wob,
      margin: 8,
      marginBottom: 15,
      borderColor: colors.button_border,
      borderWidth: 1,
      shadowOffset: {width: 0, height: 2},
      shadowOpacity: 0.25,
      shadowRadius: 4,
      flexDirection: 'row',
      alignItems: 'center',
      flex: 1,
    },
    textStyle: {
      justifyContent: 'Left',
      fontSize: 15,
      fontWeight: 'bold',
      color: colors.text,
    },
    backButton: {
      height: 40,
      width: 160,
      borderRadius: 10,
      alignItems: 'center',
      justifyContent: 'center',
      borderColor: 'black',
      borderWidth: 1,
      shadowOffset: {width: 0, height: 2},
      shadowOpacity: 0.25,
      shadowRadius: 4,
      marginTop: '5%',
      backgroundColor: colors.wob,
    },
  });
  const [loaded, setLoaded] = useState(false);

  const getRecRevFiles = async () => {
    setLoaded(false);
    // only call this endpoint for users with the permission
    if (navState.canSeeRecommend) {
      const rec = await get_recommend_files({'user_id': global.user_data.user_id,'permissions': global.user_data.permissions}, global.token);
      
      const recRstFiltered = rec.body.filter(
        (file) => file.status < 2 && file.form_type.toLowerCase() === 'rst',
      );

      global.recRst = recRstFiltered.length;
    }

    if (navState.canSeeReview) {
      const rev = await get_review_files({'role': global.user_data.role, 'user_id': global.user_data.user_id, 'permissions': global.user_data.permissions}, global.token);

      const revRstFiltered = rev.body.filter(
          (file) => file.status < 4 && file.form_type.toLowerCase() === 'rst',
      );

      const rev1380Filtered = rev.body.filter(
        (file) => file.status < 4 && (file.form_type === '1380' || file.form_type === '1380 aftp')
      )

      global.reviewRst = revRstFiltered.length;
      global.review1380 = rev1380Filtered.length;
    }

    global.reviewRecRst = global.reviewRst + global.recRst;
    setLoaded(true);
  };

  useFocusEffect(
      React.useCallback(() => {
        async function getInfo() {
          getRecRevFiles();
        }
        getInfo();
      }, []),
  );

  const RSTNav = () => {
    navigation.navigate('RSTHome');
  };

  const Nav1380 = () => {
    navigation.navigate('Home1380', {ids: []});
  };

  const YFNav = () => {
    navigation.navigate('YourFiles', {from1380: false});
  };

  const goBack = () => {
    navigation.navigate('Home');
  };

  if (!loaded) {
    return (
      <View style = { styles.container}>
        <CustomHeader />
        <View style = { styles.header}>
		  <Text>Loading...</Text>
        </View>
      </View>
    );
  }

  return (
    <View style = { styles.container}>
      <CustomHeader />

      <View
        style={{
          flexDirection: 'row',
          zIndex: 5,
          width: '75%',
          alignSelf: 'center',
        }}
      >
        <View style={{alignText: 'center', flex: 3}}>
          <Text style={ styles.pageTitle }>Requests</Text>

          <Text
            style={{
              fontSize: 18,
              marginHorizontal: 5,
              textAlign: 'Left',
              paddingTop: 25,
              paddingBottom: 40,
              color:  colors.text ,
            }}
          >
                        View Requests, Forms, and Files
          </Text>

          <TouchableOpacity
            style = { styles.backButton }
            onPress= {() => goBack()}>
            <Text style={ styles.textStyle }>Back to Home</Text>
          </TouchableOpacity>

          <View style={ styles.contentBox}>
            <TouchableOpacity
              style={[ styles.button , {justifyContent: 'space-between'}]}
              onPress={() => RSTNav()}
            >
              <Text style={ styles.textStyle }>RST (Rescheduled Training)</Text>
              {global.reviewRecRst > 0 ?
                        <View style={{flexDirection: 'flex-end'}}>
                          <NumberBadge count={global.reviewRecRst} />
                        </View> : null}
            </TouchableOpacity>

            <TouchableOpacity
              style={[ styles.button , {justifyContent: 'space-between'}]}
              onPress={() => Nav1380()}
            >
              <Text style={ styles.textStyle }>1380</Text>
              {global.review1380 > 0 ?
                        <View style={{flexDirection: 'flex-end'}}>
                          <NumberBadge count={global.review1380} />
                        </View> : null}
            </TouchableOpacity>

            <TouchableOpacity
              style={ styles.button }
              onPress={() => YFNav()}
            >
              <Text style={ styles.textStyle }>Your Files</Text>
            </TouchableOpacity>

          </View>
        </View>
      </View>
    </View>
  );
};

export default Request;
