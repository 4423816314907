import React, { createContext, useContext, useState } from 'react';
import GlobalSnackbar from './GlobalSnackbar';

const SnackbarContext = createContext();

export const useSnackbar = () => useContext(SnackbarContext);

export const SnackbarProvider = ({ children }) => {
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    message: '',
    severity: '',
    anchorVertical: 'top',
    anchorHorizontal: 'right',
    autoHideDuration: 6000,
    width: 400,
    onClose: null,
  });

  const showSnackbar = (
    severity,
    message,
    anchorVertical = 'top',
    anchorHorizontal = 'right',
    autoHideDuration = 6000,
    width = 400,
    onClose = null
  ) => {
    setSnackbarProps({
      open: true,
      message,
      severity,
      anchorVertical,
      anchorHorizontal,
      autoHideDuration,
      width,
      onClose
    });
  };

  const handleClose = () => {
    setSnackbarProps({ ...snackbarProps, open: false });
    if (typeof snackbarProps.onClose === 'function') {
      snackbarProps.onClose();
    }
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar, handleClose }}>
      {children}
      <GlobalSnackbar
        open={snackbarProps.open}
        onClose={handleClose}
        severity={snackbarProps.severity}
        message={snackbarProps.message}
        anchorVertical={snackbarProps.anchorVertical}
        anchorHorizontal={snackbarProps.anchorHorizontal}
        autoHideDuration={snackbarProps.autoHideDuration}
        width={snackbarProps.width}
        paddingBottom="2.5%"
        paddingRight="2%"
      />
    </SnackbarContext.Provider>
  );
};
