import React from 'react';
import { View } from 'react-native';
import NavSideBar from '../components/NavSideBar';

const MainLayout = ({ children }) => {
  return (
    <View style={{ flex: 1, flexDirection: 'row' }}>
      <NavSideBar />
      <View style={{ flex: 1 }}>
        {children}
      </View>
    </View>
  );
};

export default MainLayout;