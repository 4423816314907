import dayjs from 'dayjs';
import React, {useState, useEffect} from 'react';
import {View, Text, TouchableOpacity, ScrollView} from 'react-native';
import {useColorContext} from '../ColorContext';

export default function Day({day, state, setState}) {
  const [dayAgenda, setDayAgenda] = useState([]);
  const [hoveredEvent, setHoveredEvent] = useState(null);
  const {colors} = useColorContext();

  useEffect(() => {
    const formattedDay = day.format('YYYY-MM-DD');

    if (state.monthObjects.hasOwnProperty(formattedDay)) {
      setDayAgenda(state.monthObjects[formattedDay].agenda);
    } else {
      setDayAgenda([]);
    }
  }, [state.monthObjects, day]);

  const styles = ({
    container: {
      borderWidth: 1,
      borderColor: 'gray',
      flex: 10,
      width: '100%',
      height: 400,
      // flexDirection: "column",
    },
    header: {
      // alignItems: "center",
      // justifyContent: "center",
    },
    dayOfWeek: {
      flex: 1,
      fontSize: 12,
      textAlign: 'center',
      textDecorationLine: 'underline',
      color: colors.text,
      marginTop: 5,
    },
    dayOfMonth: {
      flex: 1,
      fontSize: 24,
      padding: 5,
      textAlign: 'left',
      color: colors.text,
      borderRadius: 999, // Use a large number for a circular shape
    },
    currentDay: {
      backgroundColor: colors.calendar_selected,
      color: colors.calendar_inverse_text,
    },
    selectedDay: {
      backgroundColor: colors.light_blue,
      color: colors.text,
    },
    eventsContainer: {
      flex: 1,
      maxHeight: 400,
      overflow: 'hidden',
    },
    tooltip: {
      position: 'relative',
      backgroundColor: 'rgba(255, 255, 255, 0.95)', // semi-transparent white
      padding: 10,
      borderRadius: 5,
      zIndex: 1000,
      overflow: 'visible',
      width: '100%',
      height: '100%',
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.23,
      shadowRadius: 2.62,
    },
    tooltipTitle: {
      fontSize: 16,
      fontWeight: 'bold',
      marginBottom: 5,
    },
    tooltipContent: {
      fontSize: 14,
      lineHeight: 20,
      marginBottom: 5, // added space between lines
    },
    event: {
      padding: 5,
      margin: 3,
      borderRadius: 5,
      flexDirection: 'row',
      alignItems: 'center',
      zIndex: 1,
    },
    eventText: {
      fontSize: 20,
      color: 'white',
      marginLeft: 2,
    },
  });

  const renderTooltip = (event) => {
    if (hoveredEvent === event.id) {
      return (
        <View style={styles.tooltip}>
          {event.time &&
          <>
            <Text style={styles.tooltipTitle}>Time</Text>
            <Text style={styles.tooltipContent}>{event.time}</Text>
          </>
          }
          {event.location &&
          <>
            <Text style={styles.tooltipTitle}>Location</Text>
            <Text style={styles.tooltipContent}>{event.location}</Text>
          </>
          }
          {event.description &&
          <>
            <Text style={styles.tooltipTitle}>Description</Text>
            <Text style={styles.tooltipContent}>{event.description}</Text>
          </>
          }
          {event.training_events &&
          <>
            <Text style={styles.tooltipTitle}>Training Events</Text>
            <Text style={styles.tooltipContent}>{event.training_events}</Text>
          </>
          }
          {event.remarks &&
          <>
            <Text style={styles.tooltipTitle}>Remarks</Text>
            <Text style={styles.tooltipContent}>{event.remarks}</Text>
          </>
          }
        </View>
      );
    }
    return null;
  };


  function getCurrentDayStyle() {
    if (day.format('DD-MM-YY') === dayjs().format('DD-MM-YY')) {
      return styles.currentDay;
    } else if (state.selectedDay && day.format('DD-MM-YY') == state.selectedDay.format('DD-MM-YY')) {
      return styles.selectedDay;
    }
    return {};
  }

  const colorDots = {
    Mandatory: 'red',
    Optional: 'green',
    Personal: 'blue',
  };

  const handleMouseEnter = (eventId) => {
    setState({...state, isHovered: true});
  };

  const handleMouseLeave = () => {
    setState({...state, isHovered: false});
  };

  const handleDaySelect = (day) => {
    setState({...state, showEventModal: true, selectedDay: day});
    // Add any other state updates as needed
    console.log(state.selectedDay);
  };

  return (
    <TouchableOpacity
      style={styles.container}
      onPress={() => {
        handleDaySelect(day);
      }}
    >
      <View style={styles.header}>
        <Text style={[styles.dayOfMonth, getCurrentDayStyle()]}>
          {day.format('DD')}
        </Text>
      </View>
      <View style={styles.eventsContainer}>
        <ScrollView showsverticalscrollindicator="true">
          {dayAgenda.map((evt, idx) => (
            state.labels[evt.type] && (
              <View key={idx} style={styles.eventContainer}>
                <TouchableOpacity
                  style={[styles.event, {backgroundColor: colorDots[evt.type]}]}
                  onMouseEnter={() => {
                    handleMouseEnter(evt.id);
                    setHoveredEvent(evt.id);
                  }}
                  onMouseLeave={() => {
                    handleMouseLeave();
                    setHoveredEvent(null);
                  }}
                  onPress={() => {
                    console.log(evt);
                    setState({...state,day:evt, showEditEventModal:true})
                  }}
                >
                  {renderTooltip(evt)}
                  {hoveredEvent !== evt.id && <Text style={styles.eventText}>{evt.title}</Text>}
                </TouchableOpacity>

              </View>
            )
          ))}
        </ScrollView>
      </View>
    </TouchableOpacity>
  );
}
