/* eslint-disable linebreak-style */
/* eslint-disable space-before-blocks */
/* eslint-disable linebreak-style */
/* eslint-disable no-trailing-spaces */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable curly */
/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
/* eslint-disable object-curly-spacing */
/* eslint-disable require-jsdoc */
/* eslint-disable arrow-parens */
/* eslint-disable camelcase */
/* eslint-disable comma-spacing */
/* eslint-disable quotes */
/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable linebreak-style */
import React, { useEffect, useState } from "react";
import { View, Text, StyleSheet } from "react-native";
import { useColorContext } from "../ColorContext";
import { TouchableOpacity } from "react-native-gesture-handler";
import { create_target_line } from "../../api_handler/budget";
import CurrencyInput from 'react-currency-input-field';
import { inputMonths, getFiscalMonth, getFiscalYear, monthDict } from "../../utils/monthInfo";
import { CheckBox } from 'react-native-web';
import Bump from "../Bump";
import { useSnackbar } from "../SnackbarContext";

function createInputValues(targetLine) {
    return inputMonths.reduce((values, month) => {
        values[month] = targetLine?.[month.toLowerCase()] || "0";
        return values;
    }, {});
}
const MonthInputGrid = ({ budget, closePopup, subtype, pot, targetLine }) => {
    const { showSnackbar } = useSnackbar();
    const currentMonth = getFiscalMonth(new Date());
    const inputMonths = ['October', 'November', 'December', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September'];
    const [linearProgression, setLinearProgression] = useState(false);
    const budgetPrefix = subtype === "ALL" ? pot : subtype;

    const [inputValues, setInputValues] = useState(createInputValues(targetLine));
    const [originalInputValues, setOriginalInputValues] = useState(createInputValues(targetLine));
    const [prevMonth, setPrevMonth] = useState(null);
    const [prevLinProg, setPrevLinProg] = useState(false);
    const [manuallyEditedCells, setManuallyEditedCells] = useState([]);
    const [automaticallyEditedCells, setAutomaticallyEditedCells] = useState([]);

    const originalTotalSpent = Object.values(originalInputValues)
            .filter((val) => !isNaN(parseFloat(val)))
            .reduce((acc, val) => acc + parseFloat(val), 0);

    const originalSpent = originalTotalSpent.toFixed(2).toString();
    const originalRemaining = parseFloat(budget - originalSpent).toFixed(2).toString();

    useEffect(() => {
        setLinearProgression(false)
        setManuallyEditedCells([]);
        setAutomaticallyEditedCells([]);
    }, [subtype])

    useEffect(() => {
        setInputValues(createInputValues(targetLine));
        setOriginalInputValues(createInputValues(targetLine));
    }, [targetLine]);

    const { colors } = useColorContext();
    const [spent, setSpent] = useState("0");
    const [remaining, setRemaining] = useState("0");

    const handleInputChange = (month, value) => {
        if (value === '' || value === null || value === undefined) {
            value = 0
        }

        if (!manuallyEditedCells.includes(month)) {
            setManuallyEditedCells([...manuallyEditedCells, month]);
        }

        if (!linearProgression || (month !== prevMonth && prevLinProg == true)) {
            console.log("first one");
            setLinearProgression(false);
            setInputValues((prevValues) => ({
                ...prevValues,
                [month]: value,
            }));
            setPrevLinProg(false);
        }
        
        if (linearProgression && (month === prevMonth || prevMonth == null || prevLinProg == false)){
            console.log("second one");
            // Calculate the total spent in months already passed
            const totalSpentPassedMonths = Object.values(inputValues)
            .slice(0, currentMonth) // Consider only months already passed
            .filter((val) => !isNaN(parseFloat(val)))
            .reduce((acc, val) => acc + parseFloat(val), 0);

            // Subtract the total spent in passed months from the budget AND whatever this month is being set to
            const remainingBudgetAfterPassedMonths = budget - totalSpentPassedMonths - value;

            // Calculate the spread amount for the remaining months, -1 because we're not counting current month
            const remainingMonthsCount = 12 - currentMonth;
            const spreadAmount = Math.max(0, remainingBudgetAfterPassedMonths / (remainingMonthsCount - 1));
            console.log(remainingBudgetAfterPassedMonths, remainingMonthsCount);
            console.log(spreadAmount);
            const updatedInputValues = { ...inputValues };
            console.log(month, currentMonth);
    
            for (let i = currentMonth; i <= 11; i++) {
                const monthKey = inputMonths[i];
                if (monthKey === month) {
                    updatedInputValues[monthKey] = parseFloat(value);
                } else {
                    const spreadAmountString = Math.floor(spreadAmount * 100) / 100; // Round down to the nearest cent
                    updatedInputValues[monthKey] = parseFloat(spreadAmountString.toString());
                    setAutomaticallyEditedCells([...automaticallyEditedCells, month]);
                }
            }
            
            console.log(updatedInputValues);
            setInputValues(updatedInputValues);
            setPrevLinProg(true);
        }
        setPrevMonth(month);
    };
    
    const isMonthPassed = (month) => {
        const monthIndex = monthDict[month.slice(0, 3)];
        return monthIndex < currentMonth;
    };

    const handleSubmitTargetLine = async () => {
        // Verify inputs, throw error otherwise.
        const validateInputValues = () => {
            for (let key in inputValues) {
                if (inputValues[key] === null || inputValues[key] === undefined) {
                    return false;
                }
            }

            return true
        }

        if (!validateInputValues()) {
            showSnackbar("error", "Input Invalid: Please fill out all the fields.");
            return;
        }

        const month_targets = Object.values(inputValues).map(value => {
            // Convert null or undefined values to 0.00
            if (value === null || value === undefined) {
                return 0.00;
            }
            return parseFloat(value);
        });

        const data = {
            "unit_id": global.user_data.unit_id, // Can set to 1 for testing.
            "subtype": subtype,
            "pot": pot,
            "month_targets": month_targets,
            "permissions": global.user_data.permissions, // Can test with [3005]
            "fiscal_year": getFiscalYear(),
        };

        // console.log(JSON.stringify(data)) 

        const results = await create_target_line(data, global.token);
        // console.log(JSON.stringify(results))

        if (results?.status === 200)
            showSnackbar("success", "Target Line Succesfully Submitted");
        else
            showSnackbar("error", "Application Error: Failed to create the Target Line");
    };

    const handleLinearProgressionToggle = (newValue) => {
        setLinearProgression(newValue);
        if (newValue === true) {
            setManuallyEditedCells([]);
            // Calculate the total spent in months already passed
            const totalSpentPassedMonths = Object.values(inputValues)
            .slice(0, currentMonth) // Consider only months already passed
            .filter((val) => !isNaN(parseFloat(val)))
            .reduce((acc, val) => acc + parseFloat(val), 0);

            // Subtract the total spent in passed months from the budget
            const remainingBudgetAfterPassedMonths = budget - totalSpentPassedMonths;

            // Calculate the spread amount for the remaining months
            const remainingMonthsCount = 12 - currentMonth;
            const spreadAmount = remainingBudgetAfterPassedMonths / remainingMonthsCount;

            const updatedInputValues = { ...inputValues };
        
            for (let i = currentMonth; i <= 11; i++) {
                const month = inputMonths[i];
                const spreadAmountString = Math.floor(spreadAmount * 100) / 100; // Round down to the nearest cent
                updatedInputValues[month] = parseFloat(spreadAmountString.toString());
            }
        
            setInputValues(updatedInputValues);
            setAutomaticallyEditedCells(inputMonths);
        }
        
        if (newValue === false){
            setInputValues(originalInputValues);
            setAutomaticallyEditedCells([]);
        }
    };

    useEffect(() => {
        const totalSpent = Object.values(inputValues)
            .filter((val) => !isNaN(parseFloat(val)))
            .reduce((acc, val) => acc + parseFloat(val), 0);

        const spent = totalSpent.toFixed(2).toString();
        const remaining = parseFloat(budget - spent).toFixed(2).toString();

        setSpent(spent);
        setRemaining(remaining)
    }, [inputValues, budget]);

    const styles = StyleSheet.create({
        container: {
            paddingTop: 20,
            alignItems: "center",
            paddingHorizontal: 16,
            height: "100%",
            width: "100%"
        },
        row: {
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 20,
            width: "100%",
        },
        inputContainer: {
            width: "25%",
        },
        label: {
            marginBottom: 5,
            fontSize: 16,
            fontWeight: "bold",
            color: colors.bow,
        },
        input: {
            borderWidth: 2,
            borderRadius: 20,
            backgroundColor: colors.gobl,
            borderColor: colors.button_outline,
            padding: 8,
            fontSize: 16,
            color: colors.bow,
        },
        inputPassed: {
            backgroundColor: 'gray', // Assuming this is the style you want to apply for passed months
            borderWidth: 2,
            borderRadius: 20,
            borderColor: colors.button_outline,
            padding: 8,
            fontSize: 16,
            color: colors.bow,
        },
        inputEdited: {
            backgroundColor: 'orange', // or any color you desire for manually edited cells
            borderWidth: 2,
            borderRadius: 20,
            borderColor: colors.button_outline,
            padding: 8,
            fontSize: 16,
            color: colors.bow,
        },
        inputAutomated: {
            backgroundColor: 'green',
            borderWidth: 2,
            borderRadius: 20,
            borderColor: colors.button_outline,
            padding: 8,
            fontSize: 16,
            color: colors.bow,
        },
        stats: {
            borderWidth: 2,
            borderRadius: 20,
            backgroundColor: colors.gobl,
            borderColor: colors.button_outline,
            padding: 8,
            fontSize: 20,
            color: colors.bow,
        },
        button: {
            borderWidth: 0,
            borderRadius: 20,
            padding: 12,
            paddingHorizontal: 30,
            fontSize: 16,
        },
        buttonText: {
            color: "white",
            fontWeight: "bold",
            fontSize: 16,
        },
        column: {
            flexDirection: 'column',
            borderColor: 'black',
            borderWidth: 1,
            backgroundColor: colors.cardBackground,
            borderRadius: 20,
            width: '100%',
        },
    });

    
    return (
        <View style={styles.container}>
            {/* Months grid section */}
            <View style={styles.row}>
                {Object.entries(inputValues).slice(0, 3).map(([month, value]) => (
                    <View key={month} style={styles.inputContainer}>
                        <Text style={styles.label}>{month}</Text>
                        <TouchableOpacity
                            onPress={() => {
                                if (monthDict[month.slice(0,3)] < currentMonth) {
                                    showSnackbar("error", "Past months are not editable.");
                                }
                            }}
                        >
                            <CurrencyInput 
                                style={
                                    manuallyEditedCells.includes(month) ? styles.inputEdited :
                                    isMonthPassed(month) ? styles.inputPassed : automaticallyEditedCells.includes(month) ? styles.inputAutomated : 
                                    styles.input
                                }
                                value={inputValues[month]}
                                decimalsLimit={2}
                                prefix={'$'}
                                delimiter=","
                                separator='.'
                                onValueChange={(text) => handleInputChange(month, text)}
                                disabled={isMonthPassed(month)}
                            />
                        </TouchableOpacity>
                    </View>
                ))}
            </View>

            <View style={styles.row}>
                {Object.entries(inputValues).slice(3, 6).map(([month, value]) => (
                    <View key={month} style={styles.inputContainer}>
                        <Text style={styles.label}>{month}</Text>
                        <TouchableOpacity
                            onPress={() => {
                                if (monthDict[month.slice(0,3)] < currentMonth) {
                                    showSnackbar("error", "Past months are not editable.");
                                }
                            }}
                        >
                            <CurrencyInput 
                                style={
                                    manuallyEditedCells.includes(month) ? styles.inputEdited :
                                    isMonthPassed(month) ? styles.inputPassed : automaticallyEditedCells.includes(month) ? styles.inputAutomated : 
                                    styles.input
                                }
                                value={inputValues[month]}
                                decimalsLimit={2}
                                prefix={'$'}
                                delimiter=","
                                separator='.'
                                onValueChange={(text) => handleInputChange(month, text)}
                                disabled={isMonthPassed(month)}
                            />
                        </TouchableOpacity>
                    </View>
                ))}
            </View>

            <View style={styles.row}>
                {Object.entries(inputValues).slice(6, 9).map(([month, value]) => (
                    <View key={month} style={styles.inputContainer}>
                        <Text style={styles.label}>{month}</Text>
                        <TouchableOpacity
                            onPress={() => {
                                if (monthDict[month.slice(0,3)] < currentMonth) {
                                    showSnackbar("error", "Past months are not editable.");
                                }
                            }}
                        >
                            <CurrencyInput 
                                style={
                                    manuallyEditedCells.includes(month) ? styles.inputEdited :
                                    isMonthPassed(month) ? styles.inputPassed : automaticallyEditedCells.includes(month) ? styles.inputAutomated : 
                                    styles.input
                                }
                                value={inputValues[month]}
                                decimalsLimit={2}
                                prefix={'$'}
                                delimiter=","
                                separator='.'
                                onValueChange={(text) => handleInputChange(month, text)}
                                disabled={isMonthPassed(month)}
                            />
                        </TouchableOpacity>
                    </View>
                ))}
            </View>

            <View style={styles.row}>
                {Object.entries(inputValues).slice(9, 12).map(([month, value]) => (
                    <View key={month} style={styles.inputContainer}>
                        <Text style={styles.label}>{month}</Text>
                        <TouchableOpacity
                            onPress={() => {
                                if (monthDict[month.slice(0,3)] < currentMonth) {
                                    showSnackbar("error", "Past months are not editable.");
                                }
                            }}
                        >
                            <CurrencyInput 
                                style={
                                    manuallyEditedCells.includes(month) ? styles.inputEdited :
                                    isMonthPassed(month) ? styles.inputPassed : automaticallyEditedCells.includes(month) ? styles.inputAutomated : 
                                    styles.input
                                }
                                value={inputValues[month]}
                                decimalsLimit={2}
                                prefix={'$'}
                                delimiter=","
                                separator='.'
                                onValueChange={(text) => handleInputChange(month, text)}
                                disabled={isMonthPassed(month)}
                            />
                        </TouchableOpacity>
                    </View>
                ))}
            </View>


            {/* Budget Info Section */}
            <Text
                style={[
                    styles.label,
                    { alignSelf: "flex-start", fontSize: 18, paddingTop: 15 },
                ]}
            >
                Budget Info
            </Text>
            <View style={[styles.column, { padding: 10, alignSelf: 'flex-start' }]}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    {/* TODO: Label this with correct budget type i.e. ADA/RST, AFTP, EBDL, MDR, RMA */}
                    <Text style={[styles.label, { width: 125 }]}>{budgetPrefix} Budget</Text>
                    <CurrencyInput
                        value={budget}
                        prefix="$"
                        delimiter=","
                        separator='.'
                        decimalsLimit={2}
                        style={styles.stats}
                        disabled={true}
                    />
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text style={[styles.label, { width: 125 }]}>Spent</Text>
                    <CurrencyInput
                        value={spent}
                        prefix="$"
                        delimiter=","
                        separator='.'
                        decimalsLimit={2}
                        style={styles.stats}
                        disabled={true}
                    />
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text style={[styles.label, { width: 125 }]}>Remaining</Text>
                    <CurrencyInput
                        value={remaining}
                        prefix="$"
                        delimiter=","
                        separator='.'
                        decimalsLimit={2}
                        style={styles.stats}
                        disabled={true}
                    />
                </View>
            </View>
            <Bump size={20} />
            <View style={{alignSelf: "flex-start", width: 300}}>
                <Text style={[styles.label, { marginLeft: '1%', marginRight: "2%" }]}>Linear Progression</Text>
                <CheckBox
                    disabled={false}
                    value={linearProgression}
                    onValueChange={(newValue) => {
                        handleLinearProgressionToggle(newValue);
                    }}
                />
            </View>
            
            {/* Cancel and Submit Section */}
            <View style={[styles.row, { paddingTop: 20 }]}>
                <TouchableOpacity
                    style={[styles.button, { backgroundColor: "red" }]}
                    onPress={closePopup}
                >
                    <Text style={styles.buttonText}>Back</Text>
                </TouchableOpacity>
                <TouchableOpacity
                    style={[styles.button, { backgroundColor: "green" }]}
                    onPress={handleSubmitTargetLine}
                >
                    <Text style={styles.buttonText}>Submit</Text>
                </TouchableOpacity>
            </View>
        </View>
    );
};

export default MonthInputGrid;
