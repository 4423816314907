import React, { useContext, useState, useEffect } from 'react';
import { View, Text, Image } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { removeData } from '../api_handler/asyncstorage.js';
import { useScale } from '../components/scale';
import { useIsFocused } from '@react-navigation/native';

import { AuthUserContext } from '../nav/AuthUserProvider.js';

function CustomHeader() {
  const navigation = useNavigation();
  const [currPage, setCurrPage] = useState('');
  const [profilePic, setProfilePic] = useState('');
  const isFocused = useIsFocused();

  const { user, setUser } = useContext(AuthUserContext);

  useEffect(() => {
    // Redirect user to login if data is null
    // if (global.user_data === undefined) {
    //   navigation.navigate('Login');
    // }

    // Wait for profile pictures to be updated in other files
    const getData = async () => {
      const storedProfilePic = await AsyncStorage.getItem('profilePic');
      const storedName = await AsyncStorage.getItem('name');
      setProfilePic(storedProfilePic || '');
    };

    if (isFocused) {
      getData();
      setCurrPage(navigation?.getState()?.routes?.[navigation?.getState()?.index]?.name || '');
    }
  }, [navigation, isFocused]);

  const scale = useScale();
  const styles = ({
    container: {
      width: '100%',
      height: '100%',
      backgroundColor: 'white',
    },
    bigContainer: {
      height: 145,
    },
    header: {
      backgroundColor: '#0d1232',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      flex: 1,
    },
    content: {
      height: '80%',
      // backgroundColor : 'pink',
      width: '80%',
      flex: 1,
      // alignItems: 'center',
      justifyContent: 'center',
      // flexDirection: 'row'
    },
    title: {
      fontSize: scale(40),
      width: '100%',
      // marginLeft: '160%',
      color: 'white',
      fontFamily: 'Trebuchet MS',
      justifyContent: 'center',
    },
    logo: {
      width: '20%',
      height: '80%',
      resizeMode: 'contain',
      // marginLeft: 0,
      // backgroundColor: 'red',
      // position: 'absolute',
      justifyContent: 'center', // Center vertically
    },
    bar: {
      // flexDirection : 'row',
      backgroundColor: 'black',
      width: '100%',
      height: '30%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    barLinks: {
      flexDirection: 'row',
      backgroundColor: 'black',
      width: '100%',
      height: '100%',
      alignItems: 'center',
      flex: 1,
    },
    headerLink: {
      color: 'white',
      flex: 1,
      textAlign: 'center',
      fontSize: '12px',
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
    headerLinkAdmin: {
      color: 'white',
      flex: 1,
      textAlign: 'center',
      display: 'none',
    },
    home: {
      // flex : .10,
      color: 'white',
    },
    calendar: {
      // flex : .10,
      color: 'white',
    },
    review: {
      // flex : .10,
      color: 'white',
    },
    docs: {
      // flex : .10,
      color: 'white',
    },
    notis: {
      // flex : .10,
      color: 'white',
    },
    help: {
      // flex : .10,
      color: 'white',
    },
    sOut: {
      // flex : .10,
      color: 'white',
    },
    back: {
      // flex : .1,
      size: '10%',
      resizeMode: 'contain',
      backgroundColor: 'red',
      zIndex: 1,
    },
    contentBox: {
      width: '8%',
      height: '80%',
      alignSelf: 'center',
      alignItems: 'center', // Center horizontally
      justifyContent: 'center', // Center vertically
    },
    circularContainer: {
      height: '80%',
      width: '45%',
      borderRadius: 50,
      overflow: 'hidden',
      alignItems: 'center', // Center horizontally
      justifyContent: 'center', // Center vertically
    },
    User: {
      flex: 1,
      width: '100%', // Set width to 100% to fill circularContainer
      height: '100%', // Set height to 100% to fill circularContainer
    },
    welcome: {
      height: '80%',
      width: '90%',
      color: '#fecc67',
      fontSize: scale(10),
      fontFamily: 'Trebuchet MS',
      // alignItems: 'right',
    },
  });

  // Displays admin console only if user is admin
  function isAdmin() {
    if (global.user_data.admin_unit_ids && global.user_data.admin_unit_ids.length > 0) {
      return styles.headerLink;
    } else {
      return styles.headerLinkAdmin;
    }
  };

  // User clicks log out
  const logOut = async () => {
    await removeData('user_data');
    await removeData('idToken');

    await setUser(null);

    global.token = null;
    global.user_data = null;
    global.profile_pic = null;
    global.revFiles = null;
    global.approvFiles = null;
    global.signature_uri = null;
    global.editUserID = null;
    global.reviewRec = null;
    global.reviewRecRst = null;
    global.reviewRst = null;
    global.recRst = null;



    navigation.navigate('Login');
  };

  return (
    <>
      <View style={styles.bigContainer}>
        <View style={styles.container}>
          <View style={styles.header} >
            <View style={styles.content}>
              <Image style={styles.logo} source={require('../assets/US_Army_Reserve logo dark mode large final.png')} />
            </View>

            <View style={styles.contentBox}>
              <View style={styles.circularContainer}>
                <Image style={styles.User} source={{ uri: profilePic }} />
              </View>
            </View>
          </View>

          <View style={styles.bar}>
            <View style={styles.barLinks}>
              <Image style={styles.back} source={require('../assets/backbutton.png')} />

              <Text
                onPress={() => {
                  navigation.navigate('Home');
                }}
                style={[styles.headerLink, { color: (currPage === 'Home') ? '#fecc67' : 'white' }]}>
                Home
              </Text>

              <Text
                onPress={() => {
                  navigation.navigate('Leader');
                }}
                style={[
                  styles.headerLink,
                  {
                    color: [
                      'Leader',
                      'Document Overview',
                      'Training Overview',
                      'Budget Overview',
                      'Medical Overview',
                    ].includes(currPage) ? '#fecc67' : 'white',
                  },
                ]}
              >
                Leader
              </Text>

              <Text
                onPress={() => {
                  navigation.navigate('SoldierHelp');
                }}
                style={[styles.headerLink, { color: (currPage === 'SoldierHelp') ? '#fecc67' : 'white' }]}>
                Soldier Help
              </Text>

              <Text
                onPress={() => {
                  navigation.navigate('PayManagement');
                }}
                style={[styles.headerLink, { color: (currPage === 'PayManagement') ? '#fecc67' : 'white' }]}>
                Pay Management
              </Text>

              <Text
                onPress={() => {
                  navigation.navigate('Request');
                }}
                style={[
                  styles.headerLink,
                  {
                    color: [
                      'Request',
                      'RSTHome',
                      'CreateNewRST',
                      'Home1380',
                      'RstTo1380',
                      'YourFiles',
                    ].includes(currPage) ? '#fecc67' : 'white',
                  },
                ]}
              >
                Request
              </Text>

              <Text
                onPress={() => {
                  navigation.navigate('Calendar');
                }}
                style={[styles.headerLink, { color: (currPage === 'Calendar') ? '#fecc67' : 'white' }]}>
                Calendar
              </Text>

              <Text
                onPress={() => {
                  navigation.navigate('Users');
                }}
                style={isAdmin()}>
                Admin Console
              </Text>

              <Text
                onPress={() => {
                  navigation.navigate('MoreHelp');
                }}
                style={[styles.headerLink, { color: (currPage === 'MoreHelp') ? '#fecc67' : 'white' }]}>
                More Help
              </Text>

              <Text
                onPress={() => {
                  navigation.navigate('Settings');
                }}
                style={[styles.headerLink, { color: (currPage === 'Settings') ? '#fecc67' : 'white' }]}>
                Settings
              </Text>

              <Text
                onPress={() => {
                  logOut();
                }}
                style={[styles.headerLink]}>
                Sign Out
              </Text>

              {/* <Text onPress = {()=> navigation.navigate('Leader')} style = {styles.headerLink}>Leader</Text> */}
              {/* <Text onPress = {()=> navigation.navigate('SoldierHelp')} style = {styles.headerLink}>Soldier Help</Text> */}
              {/* <Text onPress = {()=> navigation.navigate('PayManagement')} style = {styles.headerLink}>Pay Management</Text> */}
              {/* <Text onPress = {()=> navigation.navigate('Review')} style = {styles.headerLink}>Review</Text> */}
              {/* <Text onPress = {()=> navigation.navigate('Report')} style = {styles.headerLink}>Report</Text> */}
              {/* <Text onPress = {()=> navigation.navigate('Request')} style = {styles.headerLink}>Request</Text> */}
              {/* <Text onPress = {()=> navigation.navigate('Calendar')} style = {styles.headerLink}> Calendar </Text> */}
              {/* <Text onPress = {()=> navigation.navigate('Uploads')} style = {styles.headerLink}>Uploads</Text> */}
              {/* <Text onPress = {()=> navigation.navigate('Chat')} style = {styles.headerLink}>Chat</Text> */}
              {/* <Text onPress = {()=> navigation.navigate('Users')} style = {isAdmin()}>Admin Console</Text> */}
              {/* <Text onPress ={()=> navigation.navigate('MoreHelp')} style = {styles.headerLink}>More Help</Text> */}
              {/* <Text onPress ={()=> navigation.navigate('Settings')} style = {styles.headerLink}>Settings</Text> */}
              {/* <Text onPress = {()=> logOut()} style = {styles.headerLink}>Sign Out</Text> */}
            </View>
          </View>
        </View>
      </View>
    </>
  );
};

export default CustomHeader;
