import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import LoginScreen from '../pages/Login';
import ForgotPasswordScreen from '../pages/ForgotPassword.js';
import ResetTempPasswordScreen from '../pages/ResetTempPassword.js';

const Stack = createStackNavigator();

function AuthStack(props) {
    return (
        <Stack.Navigator initialRouteName="Login">
            <Stack.Screen name="Login" component={LoginScreen} options={{ headerShown: false }} />
            <Stack.Screen name="ForgotPassword" component={ForgotPasswordScreen} options={{ headerShown: false }} />
            <Stack.Screen name="Reset Temp Password" component={ResetTempPasswordScreen} options={{ headerShown: false }} />
        </Stack.Navigator>
    );
}

export default AuthStack;
