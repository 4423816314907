/* eslint-disable linebreak-style */
/* eslint-disable object-curly-spacing */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react';
import { FormControlLabel, Radio, Box } from '@mui/material';
import { useColorContext } from './ColorContext';

const CustomRadioWithBackground = ({ id, checked, onChange, label }) => {
  const { colors } = useColorContext();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: checked ? '#FDEFCB' : 'transparent',
        border: checked ? `2px solid ${colors.gold}` : '2px solid transparent',
        borderRadius: 1,
        marginRight: '4px',
        cursor: 'pointer',
        height: '40px', // To match dropdowns/datepickers/etc.
        width: '120px',
        justifyContent: 'start',
      }}
      onClick={onChange}
    >
      <FormControlLabel
        id={id}
        value="1"
        control={
          <Radio
            checked={checked}
            onChange={onChange}
            value="1"
            sx={{
              '&.Mui-checked': {
                color: colors.gold,
                borderRadius: '50%',
                border: '2px solid transparent',
                width: 20,
                height: 20,
                boxSizing: 'border-box',
              },
              '&:not(.Mui-checked)': {
                '&.MuiRadio-root': {
                  color: 'transparent',
                  borderRadius: '50%',
                  border: `2px solid ${colors.gold}`,
                  width: 20,
                  height: 20,
                  boxSizing: 'border-box',
                },
              },
            }}
            style={{ marginLeft: '14px', marginTop: '3px', marginBottom: '3px', marginRight: '4px' }}
            name="radio-buttons"
          />
        }
        label={<Box component="span" style={{color: checked ? '#000000' : colors.text}} onClick={(e) => e.stopPropagation()}>{label}</Box>}
        style={{marginRight: '8px'}}
      />
    </Box>
  );
};

export default CustomRadioWithBackground;
