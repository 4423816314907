import React, { Fragment, useEffect, useState } from 'react';
import { View, Text } from 'react-native';
import { useScale } from '../components/scale';
import CustomHeader from '../components/header';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';

import { CheckBox } from 'react-native-web';
import { Dimensions, useWindowDimensions, ScrollView, Pressable, Modal } from 'react-native';
import { en, registerTranslation, DatePickerModal } from 'react-native-paper-dates';
registerTranslation('en', en);
import { TextInput } from 'react-native-paper';
import { Picker } from '@react-native-picker/picker';
import dayjs from 'dayjs';
import { get_event, update_event, delete_event } from '../api_handler/events';

import colors from '../colors';
import { useIsFocused } from '@react-navigation/native';
import { create_notification } from '../api_handler/notifications';
import { get_user_subordinates } from '../api_handler/users';
import { get_all_role_users } from '../api_handler/roles';

import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ModeContext from '../components/ModeContext';

const windowDimensions = Dimensions.get('window');
const windowHeight = windowDimensions.height;

function Review({ navigation }) {
  const [refresh, setRefresh] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState(null);
  const { mode, toggleMode } = React.useContext(ModeContext);

  const scale = useScale();

  const styles = createStyles(scale);
  const isFocused = useIsFocused();

  const [showPicker, setShowPicker] = useState(false);
  const [showInvitees, setshowInvitees] = useState(false);

  const [showStart, setShowStart] = useState(false);
  const [showEnd, setShowEnd] = useState(false);
  const [showEndTime, setshowEndTime] = useState(false);

  const [showEButton, setshowEButton] = useState(false);
  const [showSButton, setshowSButton] = useState(false);
  const [showEndCal, setshowEndCal] = useState(false);
  const [showCal, setshowCal] = useState(false);

  const [showSetTimes, setshowSetTimes] = useState(false);
  const [showTime, setshowTime] = useState(false);
  const [startMode, setStartMode] = useState('date');
  const [endMode, setEndMode] = useState('date');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [timestamp, setTimestamp] = useState(new Date());

  const [isYearly, setisYearly] = useState(false);
  const [isWeekly, setisWeekly] = useState(false);
  const [isPeriod, setIsPeriod] = useState(false);

  const [newInvitees, setNewInvitees] = useState([]);
  const [inviteeIDs, setinviteeIDs] = useState([]);
  const [confirmedIDs, setconfirmedIDs] = useState([]);

  const [loaded, setLoaded] = useState(false);
  const [allpeople, setallpeople] = useState([]);
  const [hasPplToInvite, sethasPplToInvite] = useState(false);
  const [temp, settemp] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const [organizer, setOrganizer] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [location, setLocation] = useState('');
  const [remarks, setRemarks] = useState('');
  const [remarks_2, setRemarks2] = useState('');
  const [training_events, setTrainingEvents] = useState('');
  const [type, setType] = useState('Optional');
  const { width } = useWindowDimensions();
  const [isCommander, setCommander] = useState(false);
  const [commanderInvitees, setCommanderInvitees] = useState([]);
  const [commanderButton, setCommanderButton] = useState(false);

  const [hasPermission, setHasPermission] = React.useState(false);
  const inviteeParams = commanderButton ? commanderInvitees : (type.value !== 'Personal' ? newInvitees : allpeople);

  const [muta, setMuta] = useState(0);

  const [itemsType, setItemsType] = useState([
    { label: 'Mandatory', value: 'Mandatory' },
    { label: 'Optional', value: 'Optional' },
    { label: 'Personal', value: 'Personal' },
  ]);
  const [tempTraining1, setTempTraining1] = useState([]);
  const [tempTraining2, setTempTraining2] = useState('');
  const [items, setItems] = useState([
    { label: 'Range', value: ' Range' },
    { label: 'ACFT', value: ' ACFT' },
    { label: 'CBRN Chamber', value: ' CBRN Chamber' },
    { label: 'Driver\'s Training', value: ' Driver\'s Training' },
  ]);
  const [itemsMuta, setItemsMuta] = useState([
    { label: 'ECT', value: 0 },
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
    { label: '6', value: 6 },
    { label: '7', value: 7 },
    { label: '8', value: 8 },
  ]);
  const [tempTitle1, setTempTitle1] = useState('');
  const [tempTitle2, setTempTitle2] = useState('');
  const [itemsTitle, setItemsTitle] = useState([
    { label: 'BA', value: ' BA' },
    { label: 'ECT', value: ' ECT' },
    { label: 'Other title', value: 'Other title: ' },
  ]);

  useEffect(() => {
    async function preLoad() {
      await getEventInfo();
      setLoaded(true);
    }
    preLoad();
  }, []);

  useEffect(() => {
    console.log(title);
    console.log(type.value);
    console.log(muta.value);
    console.log(training_events);
    console.log(location);
    console.log(description);
    console.log(remarks);
    console.log(remarks_2);
  }, [title, type, muta, training_events]);

  // Helps for users to know the role of the commander they are selecting.  Will likely add unit on to here as well.
  function getUserRole(role_id) {
    switch (role_id) {
      case 7:
        return 'BN CDR';
      case 8:
        return 'CO CDR';
      default:
        return '';
    }
  }

  useEffect(() => {
    // Check that the user had permission for this page
    if (global.user_data.permissions.includes(2051) || global.user_data.permissions.includes(2052)) {
      setCommander(true);
      setHasPermission(true);

    } else {
      setCommander(false);
      setHasPermission(false);
    }
  }, []);

  const handleCommanderButton = React.useCallback(async (value) => {
    setCommanderButton(value);
  }, [commanderButton, commanderInvitees]);

  const onChange = (selected, desc) => {
    // called when user changes start or end date
    let currentDate;

    if (desc == 'start') {
      currentDate = selected || startDate;
      setStartDate(selected);
    } else {
      currentDate = selected || endDate;
      setEndDate(selected);
      setIsPeriod(true);
    }
  };

  const getEventInfo = async () => {
    const get_event_JSON = { 'scheduled_events_id': global.eventID };
    const event = await get_event(get_event_JSON, global.token);

    if (event) {
      setStartDate(dayjs(event.body['starttime']).$d);
      if (event.body['period']) setEndDate(dayjs(event.body['endtime']).$d);
      else setEndDate(dayjs(event.body['starttime']).$d);
      setType(event.body['type']);
      setTitle(event.body['title']);
      setIsPeriod(event.body['period']);
      setDescription(event.body['description']);
      setOrganizer(event.body['organizer_id']);
      setinviteeIDs(event.body['invitees_id']);
      setconfirmedIDs(event.body['confirmed_id']);
      setisWeekly(event.body['weekly']);
      setisYearly(event.body['yearly']);
      setLocation(event.body['location']);
      setMuta(event.body['muta']);
      setRemarks(event.body['remarks']);
      setRemarks2(event.body['remarks_2']);
      setTrainingEvents(event.body['training_events']);
      setTimestamp(event.body['timestamp']);
    }
  };

  // Closes any time popups
  const onDismissSingle = React.useCallback(() => {
    setshowCal(false);
    setshowTime(false);
    setshowEndCal(false);
    setshowEndTime(false);
  }, [showCal]);

  // Closes start date calendar
  const onConfirmSingle = React.useCallback((params) => {
    setshowCal(false);
    const hours = startDate.getHours();
    const minutes = startDate.getMinutes();

    params.date.setHours(hours, minutes, 0);

    setStartDate(params.date);
  }, [showCal, startDate]);

  // Closes end date calendar
  const onConfirmEndDate = React.useCallback((params) => {
    setshowEndCal(false);
    const hours = endDate.getHours();
    const minutes = endDate.getMinutes();

    params.date.setHours(hours, minutes, 0);

    setEndDate(params.date);
    setIsPeriod(true);
  }, [showEndCal, endDate]);

  // Closes start time
  const onConfirmTime = (time_JSON) => {
    if (time_JSON !== null) {
      const hours = time_JSON.$H;
      const minutes = time_JSON.$m;
      setshowTime(false);
      const d = startDate;
      d.setHours(hours, minutes, 0);
      setStartDate(d);
    }
  };

  const onConfirmEndTime = (time_JSON) => {
    if (time_JSON !== null) {
      const hours = time_JSON.$H;
      const minutes = time_JSON.$m;
      setshowEndTime(false);
      const d = endDate;
      d.setHours(hours, minutes, 0);
      setEndDate(d);
      setIsPeriod(true);
    }
  };

  const onDateTimeChange = (event, value) => {
    if (value === undefined) {
      setShowDatePicker(false);
      setCheckBoxY(false);
    } else {
      setShowDatePicker(false);
      setSelectedDate(value);
    }
  };

  // Send notifications to mobile phones of all invitees using their tokens
  const sendNotifsToInvitees = async (type, title, invitees_id) => {
    const text = (type == 'Optional') ? 'an ' : 'a ';

    // Iterate through all invitees array and send a notification
    for (const i in invitees_id) {
      const notificationContents = {
        title: 'You\'ve been invited!',
        body: 'You have been invited to ' + text + type + ' event: ' + title,
        receiver_id: invitees_id[i],
      };

      await create_notification(notificationContents, global.token);
    }
  };

  // Update event if input is valid
  const submitEvent = async () => {
    if (type.value !== 'Personal') {
      setconfirmedIDs(newInvitees);
    } else {
      setconfirmedIDs(allpeople);
    }

    if (dayjs(endDate).isBefore(startDate) && isPeriod) {
      alert('Start Date Must Be Before End Date');
    } else if (dayjs(startDate).isBefore(dayjs(), 'day')) {
      alert('Event Cannot Be In The Past...');
    }

    // Update event based on user input
    else {
      let permission = 0;
      if (global.user_data.permissions.includes(2052)) {
        permission = 2052;
      } else if (global.user_data.permissions.includes(2051) && !global.user_data.permissions.includes(2052)) {
        permission = 2051;
      }
      const update_event_JSON = {
        'scheduled_events_id': global.eventID,
        'organizer_id': organizer,
        'organizer_name': global.user_data.first_name + ' ' + global.user_data.middle_name + ' ' + global.user_data.last_name,
        'invitees_id': inviteeIDs,
        'confirmed_id': confirmedIDs,
        'title': title,
        'location': location,
        'description': description,
        'period': isPeriod,
        'type': type.value,
        'muta': muta.value,
        'remarks': remarks,
        'remarks_2': remarks_2,
        'training_events': training_events,
        'starttime': startDate.toISOString(),
        'endtime': endDate.toISOString(),
        'timestamp': timestamp,
        'weekly': isWeekly,
        'yearly': isYearly,
        "permission": permission
      };

      await update_event(update_event_JSON, global.token);

      // Send notifications to invitees about updated event
      sendNotifsToInvitees(type.value, title, inviteeIDs);

      global.eventID = '';
      navigation.navigate('Calendar');
    }
  };

  // Get all current and potential (subordinates) invitees
  const getInviteesInfo = async () => {
    // Get all current user's subordinates recursively
    const get_user_subordinates_JSON = { 'user_id': global.user_data.user_id };
    const subs_Info = await get_user_subordinates(get_user_subordinates_JSON, global.token);

    // Create an array with all user's subordinates info (ids, names, and if they are invited)
    const allInvitees = subs_Info.body.map((info) => ({
      id: info.user_id,
      name: info.name,
      isInvited: inviteeIDs.includes(info.user_id),
    }));

    // Check if current user can invite soldiers (subordinates) to events otherwise don't show
    if (allInvitees.length > 0) {
      sethasPplToInvite(true);
    }

    // Set the new Invitees and all people to user's subordinates
    setNewInvitees(allInvitees);
    setallpeople(allInvitees);
  };

  // Get current event's current and potential (commander) invitees
  const getCommanderInvitees = async () => {
    let commanderList = [];
    if (global.user_data.permissions.includes(2052)) {
      commanderList = await get_all_role_users({ unit_id: global.user_data.unit_id, permission: 2052 }, global.token);
      commanderList = commanderList.body;
      let bnCommander = await get_all_role_users({ unit_id: global.user_data.unit_id, permission: 2051 }, global.token);
      commanderList.push.apply(commanderList, bnCommander.body);
    }
    else {
      commanderList = await get_all_role_users({ unit_id: global.user_data.unit_id, permission: 2051 }, global.token);
      commanderList = commanderList.body;
    }

    commanderList = commanderList.filter((user) => user.user_id != global.user_data.user_id);
    const invitees = commanderList.map((info) => ({
      id: info.user_id,
      name: info.name,
      isInvited: inviteeIDs.includes(info.user_id),
      role: info.role_id,
    }));

    setCommanderInvitees(invitees);

    if (invitees.length > 0) {
      sethasPplToInvite(true);
    }
  };

  // Closes modal after saving ids
  function submitInvitees(invitees) {
    const ids = [];
    let allInvites = inviteeIDs;

    console.log('All current invites: ' + allInvites);

    for (let i = 0; i < invitees.length; i++) {
      if (!invitees[i].isInvited && allInvites.includes(invitees[i].id)) {
        allInvites = allInvites.filter((item) => item !== invitees[i].id);
      }

      if (invitees[i].isInvited) {
        ids.push(invitees[i].id);
      }
    }

    allInvites.push(...ids);

    const uniqueIds = [...new Set(allInvites)];

    setinviteeIDs(uniqueIds);
    setModalVisible(false);
  }

  // Checkbox clicked for invitee, change value and update newInvitees array
  const clickBox = (id, invitees) => {
    let update_invites;
    update_invites = invitees;

    for (let i = 0; i < update_invites.length; i++) {
      if (id == update_invites[i].id) {
        update_invites[i].isInvited = !update_invites[i].isInvited;
        console.log('invitee: ' + update_invites[i].name + ' invite updated to: ' + update_invites[i].isInvited);
        break;
      }
    }
    if (commanderButton) {
      setCommanderInvitees(update_invites);
    } else if (type.value != 'Personal') {
      setNewInvitees(update_invites);
    } else {
      setallpeople(update_invites);
    }
  };

  // User clicked delete event
  const delEvent = async () => {
    // Pop up asking to confirm event's deletion
    if (confirm('Are you sure you\'d like to delete event ' + title + '?')) {
      const delete_event_JSON = { 'scheduled_events_id': global.eventID };
      await delete_event(delete_event_JSON, global.token);

      global.eventID = '';
      navigation.navigate('Calendar');
    }
  };

  // Runs when event type change... UseEffect needed otherwise setState change happens after, due to asynchronicity
  useEffect(() => {
    if (type.value != 'Personal') {
      const t = [];

      // we only keep invitees that are under user's chain of command
      // when switching from personal to non-personal
      for (let i = 0; i < newInvitees.length; i++) {
        if (inviteeIDs.includes(newInvitees[i].id)) {
          t.push(newInvitees[i].id);
        }
      }
      setinviteeIDs(t);
    }
  }, [type]);

  /*
    These two functions set the title.  If one of the titles is selected from dropdown menu,
    the "tempTitle1" string is set.  If the value of "tempTitle1" is "Other Title",
    "tempTitle2" is set to whatever value is written into the textbox.
  */
  useEffect(() => {
    if (tempTitle1 !== '' && tempTitle1 !== undefined) {
      if (tempTitle1.label === 'Other title') {
        setTitle('Other Title: ' + tempTitle2);
      } else {
        setTitle(tempTitle1.label);
      }
    }
  }, [tempTitle1, tempTitle2]);

  const titleSet = () => {
    console.log('temptitle1: ' + JSON.stringify(tempTitle1));
    if (tempTitle1 != '' && tempTitle1 !== undefined) {
      if (tempTitle1.label === 'Other title') {
        return (
          <Fragment>
            <TextInput
              selectionColor={mode === 'dark' ? colors.white : colors.black}
              activeOutlineColor={mode === 'dark' ? colors.white : colors.black}
              placeholder="Enter event title "
              outlineColor={mode === 'dark' ? colors.white : colors.gray}
              style={mode === 'dark' ? styles.inputDark : styles.input}
              onChangeText={(newText) => setTempTitle2(newText)}
            />
          </Fragment>
        );
      }
    }
  };

  /*
    These two functions set the training_events.  If training is selected from dropdown menu,
    The value is added to the "tempTraining1" object.  If a value is added from the optional textbox,
    "tempTraining2" is set to that value.  All of these values are concatenated to the training_events string.
  */
  useEffect(() => {
    const trainingNames = tempTraining1.map((name) => name.value);
    const concatenated = trainingNames.join(', ');

    setTrainingEvents(concatenated + ', ' + tempTraining2);
  }, [tempTraining1, tempTraining2]);

  const trainingSet = () => {
    if (tempTraining1 !== null && tempTraining1 !== undefined) {
      return (
        <Fragment>
          <TextInput
            selectionColor={mode === 'dark' ? colors.white : colors.black}
            activeOutlineColor={mode === 'dark' ? colors.white : colors.black}
            placeholder="Enter any other training events: "
            outlineColor={mode === 'dark' ? colors.white : colors.gray}
            style={mode === 'dark' ? styles.inputDark : styles.input}
            onChangeText={newText => setTempTraining2(newText)}
          />
        </Fragment>
      )
    }
  };

  // "Handle" functions used to handle dropdown selections
  const handleEventTitleChange = (event) => {
    setTempTitle1(event.target.value);
  };

  const handleTrainingChange = (event) => {
    const {
      target: { value },
    } = event;

    const vals = typeof value === 'string' ? value.split(',') : value;
    setTempTraining1(vals);
  };

  const handleMUTAChange = (event) => {
    setMuta(event.target.value);
  };

  const handleEventTypeChange = (event) => {
    setType(event.target.value);
  };

  if (!loaded) {
    return (
      <View style={mode === 'light' ? styles.containerLight : styles.containerDark}>
        <CustomHeader />
        <View style={styles.header}>
          <Text style={mode === 'light' ? styles.titleLight : styles.titleDark}>Loading...</Text>
        </View>
      </View>
    );
  }

  return (
    <ScrollView>
      <View style={mode === 'light' ? styles.containerLight : styles.containerDark}>
        <CustomHeader />

        <View style={{ flexDirection: 'row', width: '100%', height: 0.06 * windowHeight }}>

          <Pressable
            onPress={() => {
              navigation.navigate('Calendar');
            }}
          >
            <Text style={mode === 'light' ? styles.textLight : styles.textDark}>&larr; Calendar</Text>

          </Pressable>

          <Text style={mode === 'light' ? styles.titleLight : styles.titleDark}>Edit Event</Text>

        </View>

        <View style={{ flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', marginTop: 15 }}>
          <View style={styles.dropdownContainer}>
            <Text style={mode === 'light' ? styles.headerLight : styles.headerDark}>Event Title</Text>
            <FormControl
              fullWidth
              style={mode === 'light' ? styles.dropDownStyle : styles.dropDownStyleDark}>
              <InputLabel id="title_dropdown">Event Title</InputLabel>
              <Select
                label="Event Title"
                labelId='title_checkbox'
                id="title-dropdown"
                value={tempTitle1}
                onChange={handleEventTitleChange}
                display
              >
                {itemsTitle.map((value) => {
                  return <MenuItem
                    key={value.label}
                    value={value}>{value.value}</MenuItem>;
                })}
              </Select>
            </FormControl>

            {tempTitle1 != tempTitle2 &&
              titleSet()
            }

            <Text style={mode === 'light' ? styles.headerLight : styles.headerDark}>Training Events</Text>

            <FormControl
              fullWidth
              style={mode === 'light' ? styles.dropDownStyle : styles.dropDownStyleDark}>
              <InputLabel id="training_dropdown">Training Events</InputLabel>
              <Select
                label="Training Events"
                labelId='training_checkbox'
                id="training-dropdown"
                value={tempTraining1}
                onChange={handleTrainingChange}
                display
                multiple
              >

                {items.map((value) => {
                  return <MenuItem value={value}>{value.label}</MenuItem>;
                })}
              </Select>
            </FormControl>

            {tempTraining1 != tempTraining2 &&
              trainingSet()
            }

            <Text style={mode === 'light' ? styles.headerLight : styles.headerDark}>MUTAs</Text>
            <FormControl
              fullWidth
              style={mode === 'light' ? styles.dropDownStyle : styles.dropDownStyleDark}>
              <InputLabel id="MUTA_dropdown">MUTA Number</InputLabel>
              <Select
                label="MUTA Number"
                labelId='MUTA_checkbox'
                id="MUTA-dropdown"
                value={muta}
                onChange={handleMUTAChange}
                display
              >
                {itemsMuta.map((value) => {
                  return <MenuItem value={value}>{value.label}</MenuItem>;
                })}
              </Select>
            </FormControl>

            <Text style={mode === 'light' ? styles.headerLight : styles.headerDark}>Event Type</Text>
            <FormControl
              fullWidth
              style={mode === 'light' ? styles.dropDownStyle : styles.dropDownStyleDark}>
              <InputLabel id="event_type_dropdown">Event Type</InputLabel>
              <Select
                label="Event Type"
                labelId='event_type_checkbox'
                id="event-type-dropdown"
                value={type}
                onChange={handleEventTypeChange}
                display
              >
                {itemsType.map((value) => {
                  return <MenuItem value={value}>{value.label}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </View>

          <Text style={mode === 'light' ? styles.headerLight : styles.headerDark}>Date and Time</Text>

          <View>
            {showPicker ? (
              <View style={{ width: '40%' }}>
                <Picker
                  selectedValue={type}
                  onValueChange={(val, index) => {
                    setShowPicker(!showPicker);
                    setNewInvitees(newInvitees);
                    setallpeople(allpeople);
                    setCommanderInvitees(commanderInvitees);
                  }}
                  style={styles.picker}
                >
                </Picker>
              </View>
            ) : null}
          </View>

          <View>
            <View style={{ flexDirection: 'row', width: '100%', alignItems: 'center' }}>
              <Pressable
                style={styles.button}
                onPress={() => {
                  setshowSetTimes(!showSetTimes);
                  setshowSButton(true);
                  setshowEButton(true);
                  setShowStart(false);
                  setShowEnd(false);
                  setshowInvitees(false);
                  if (showPicker) setShowPicker(false);
                }}
              >
                <Text style={styles.textStyle}>Event Date-Time</Text>
              </Pressable>
              <Text style={mode === 'light' ? styles.textLight : styles.textDark}>
                {isPeriod ?
                  (startDate.toString()).substring(4, 21) + ' - ' + (endDate.toString()).substring(4, 21) :
                  (startDate.toString()).substring(4, 21)}
              </Text>
            </View>

            {showSetTimes ? (
              <View style={{ marginLeft: '10%' }}>
                {showSButton ? (
                  <Pressable
                    style={styles.button}
                    onPress={() => {
                      setShowStart(true);
                      setShowEnd(false);
                      setshowSButton(false);
                      setshowEButton(true);
                    }}
                  >
                    <Text style={styles.textStyle}>Set Start Time</Text>
                  </Pressable>
                ) : null}

                {showStart ? (
                  <View style={styles.view}>
                    <Pressable
                      style={styles.button}
                      onPress={() => {
                        setshowCal(true);
                      }}
                    >
                      <Text style={styles.textStyle}>Date</Text>
                    </Pressable>

                    <Pressable
                      style={styles.button}
                      onPress={() => {
                        { showTime ? setshowTime(false) : setshowTime(true); }
                      }}
                    >
                      <Text style={styles.textStyle}>Time</Text>
                    </Pressable>
                  </View>
                ) : null}

                {showCal ? (
                  <View style={{ width: width * 0.75 }}>
                    <DatePickerModal
                      locale="en"
                      mode="single"
                      visible={true}
                      onDismiss={onDismissSingle}
                      date={startDate}
                      onConfirm={onConfirmSingle}
                      validRange={{
                        startDate: new Date(), // optional
                      }}
                    />
                  </View>
                ) : null}

                {showTime ? (
                  <View style={{ width: width * 0.75 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>

                      <StaticTimePicker
                        onAccept={onConfirmTime}
                        onDismiss={onDismissSingle}
                        ampm={false}
                        ampmInClock={false}
                        sx={{
                          width: '30%',
                          bgcolor: '#F2F2F2',
                        }}
                      />

                    </LocalizationProvider>
                  </View>
                ) : null}

                {showEButton ? (
                  <Pressable
                    style={styles.button}
                    onPress={() => {
                      setShowEnd(true);
                      setShowStart(false);
                      setshowEButton(false);
                      setshowSButton(true);
                    }}
                  >
                    <Text style={styles.textStyle}>Set End Time (Optional)</Text>
                  </Pressable>
                ) : null}

                {showEnd ? (
                  <View style={styles.view}>
                    <Pressable
                      style={styles.button}
                      onPress={() => {
                        setshowEndCal(true);
                      }}
                    >
                      <Text style={styles.textStyle}>Date</Text>
                    </Pressable>

                    <Pressable
                      style={styles.button}
                      onPress={() => {
                        { showEndTime ? setshowEndTime(false) : setshowEndTime(true); }
                      }}
                    >
                      <Text style={styles.textStyle}>Time</Text>
                    </Pressable>
                  </View>
                ) : null}

                {showEndCal ? (
                  <View style={{ width: width * 0.75 }}>
                    <DatePickerModal
                      locale="en"
                      mode="single"
                      visible={true}
                      onDismiss={onDismissSingle}
                      date={endDate}
                      onConfirm={onConfirmEndDate}
                      validRange={{
                        startDate: startDate, // optional
                      }}
                    />
                  </View>
                ) : null}

                {showEndTime ? (
                  <View style={{ width: width * 0.75 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>

                      <StaticTimePicker
                        visible={true}
                        onAccept={onConfirmEndTime}
                        ampm={false}
                        ampmInClock={false}
                        sx={{
                          width: '30%',
                          bgcolor: '#F2F2F2',
                        }}
                      />

                    </LocalizationProvider>
                  </View>
                ) : null}

              </View>
            ) : null}

          </View>
          <Text style={mode === 'light' ? styles.headerLight : styles.headerDark}>Invitees</Text>
          <View>
            <View style={{ flexDirection: 'row', width: '100%', alignItems: 'center' }}>
              <Pressable
                style={styles.button}
                onPress={() => {
                  if (hasPermission) {
                    setshowInvitees(!showInvitees);
                    setshowSetTimes(false);
                  }
                }}
              >
                <Text style={styles.textStyle}>Event Invitees</Text>
              </Pressable>
              <Text style={mode === 'light' ? styles.textLight : styles.textDark}>{inviteeIDs.length} invitee(s)</Text>
            </View>

            {showInvitees ? (
              <View style={{ width: 'auto', marginRight: width * 0.05, marginLeft: '10%' }}>
                <View style={styles.view} >
                  <Pressable
                    style={styles.button}
                    onPress={() => {
                      getEventInfo();
                      handleCommanderButton(false);
                      setModalVisible(true);
                    }}
                  >
                    <Text style={styles.textStyle}>Edit Invitees</Text>
                  </Pressable>
                </View>
                {
                  isCommander ? (<View style={styles.view} >
                    <Pressable
                      style={styles.button}
                      onPress={() => {
                        handleCommanderButton(true);
                        getCommanderInvitees();
                        setModalVisible(true);
                      }}
                    >
                      <Text style={styles.textStyle}>Edit Commanders</Text>
                    </Pressable>
                  </View>) : null
                }
              </View>
            ) : null}
          </View>

          <View style={styles.modalStyling}>

            <Modal
              animationType="slide"
              transparent={true}
              visible={modalVisible}
              onRequestClose={() => {
                setModalVisible(false);
              }}
            >
              <View
                style={{
                  margin: 20,
                  marginTop: '10%',
                  backgroundColor: 'white',
                  borderRadius: 20,
                  paddingLeft: 15,
                  paddingRight: 15,
                  paddingTop: 10,
                  paddingBottom: 10,
                  alignItems: 'center',
                  shadowColor: '#000',
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.25,
                  shadowRadius: 4,
                  elevation: 5,
                  width: '60%',
                  justifyContent: 'center',
                  height: 'fit-content',
                  marginLeft: '20%',
                }}
              >
                <Text style={{ fontSize: 18, margin: 2 }}> Invitees: </Text>

                <View style={styles.list}>

                  {hasPplToInvite ? (
                    <View>

                      {commanderButton ? (<View style={{ height: 200 }}>
                        <ScrollView style={styles.list2}>

                          {commanderInvitees.map((user, index) => {
                            const key = `${user.id}_${index}`;
                            return (
                              <View key={key} style={styles.checkboxContainer}>
                                <CheckBox
                                  disabled={false}
                                  value={commanderInvitees[index].isInvited}
                                  onValueChange={(val) => {
                                    settemp(!temp); // not sure what this line does but the page breaks if I take it out
                                    clickBox(user.id, commanderInvitees); // calls clickBox function
                                    setCommanderInvitees(commanderInvitees); // refreshes screen with newInvitees values
                                  }}
                                />

                                <Text>{user.name} {user.role ? `- ${getUserRole(user.role)}` : ''}</Text>
                              </View>
                            );
                          })}

                        </ScrollView>
                      </View>
                      ) : type.value != 'Personal' ? (
                        <View style={{ height: 200 }}>
                          <ScrollView style={styles.list2}>

                            {newInvitees.map((user, index) => {
                              const key = `${user.id}_${index}`;
                              return (
                                <View key={key} style={styles.checkboxContainer}>
                                  <CheckBox
                                    disabled={false}
                                    value={newInvitees[index].isInvited}
                                    onValueChange={(val) => {
                                      settemp(!temp); // not sure what this line does but the page breaks if I take it out
                                      clickBox(user.id, newInvitees); // calls clickBox function
                                      setNewInvitees(newInvitees); // refreshes screen with newInvitees values
                                    }}
                                  />

                                  <Text>{user.name}</Text>
                                </View>
                              );
                            })}

                          </ScrollView>
                        </View>
                      ) : (
                        <View style={{ height: 200 }}>
                          <ScrollView style={styles.list2}>
                            {allpeople.map((user, index) => {
                              const key = `${user.id}_${index}`;
                              return (
                                <View key={key} style={styles.checkboxContainer}>
                                  <CheckBox
                                    disabled={false}
                                    value={allpeople[index].isInvited}
                                    onValueChange={(val) => {
                                      settemp(!temp); // not sure what this line does but the page breaks if I take it out
                                      clickBox(user.id, allpeople); // calls clickBox function
                                      setallpeople(allpeople); // refreshes screen with allpeople values
                                    }}
                                  />
                                  <Text>{user.name}</Text>
                                </View>
                              );
                            })}
                          </ScrollView>
                        </View>
                      )}

                      <View>
                        <View style={{ flexDirection: 'row', width: '100%', alignItems: 'center' }}>
                          <Pressable
                            style={styles.button}
                            onPress={() => {
                              submitInvitees(inviteeParams);
                              if (commanderButton) {
                                handleCommanderButton(false);
                              }
                            }}
                          >
                            <Text style={styles.textStyle}>Update Invitees</Text>
                          </Pressable>

                          <Pressable
                            style={styles.buttonClose}
                            onPress={() => {
                              setModalVisible(!modalVisible);
                              if (commanderButton) {
                                handleCommanderButton(false);
                              }
                            }}
                          >
                            <Text style={styles.textStyle}>Close</Text>
                          </Pressable>
                        </View>
                      </View>
                    </View>
                  ) : (
                    <View>
                      <Text>There are no valid users with permission to be invited to this event.</Text>

                      <Pressable
                        style={styles.buttonClose}
                        onPress={() => setModalVisible(!modalVisible)}
                      >
                        <Text style={styles.textStyle}>Close</Text>
                      </Pressable>
                    </View>
                  )}
                </View>
              </View>
            </Modal>
          </View>

          <Text style={mode === 'light' ? styles.headerLight : styles.headerDark}>Location</Text>

          <TextInput
            selectionColor={'black'}
            activeOutlineColor={'black'}
            placeholder="Enter an event location"
            style={mode === 'dark' ? styles.inputDark : styles.input}
            onChangeText={setLocation}
            value={location}
            multiline={false}
            onPressOut={() => {
              if (showPicker) setShowPicker(false);
              if (showSetTimes) setshowSetTimes(false);
            }}
          />

          <Text style={mode === 'light' ? styles.headerLight : styles.headerDark}>Description</Text>

          <TextInput
            selectionColor={'black'}
            activeOutlineColor={'black'}
            placeholder="Enter an event description"
            style={mode === 'dark' ? styles.inputDark : styles.input}
            onChangeText={setDescription}
            value={description}
            multiline={true}
            onPressOut={() => {
              if (showPicker) setShowPicker(false);
              if (showSetTimes) setshowSetTimes(false);
            }}
          />


          <Text style={mode === 'light' ? styles.headerLight : styles.headerDark}>Remarks</Text>

          <TextInput
            selectionColor={'black'}
            activeOutlineColor={'black'}
            placeholder="e.g., Special Equipment, Uniform"
            style={mode === 'dark' ? styles.inputDark : styles.input}
            onChangeText={setRemarks}
            value={remarks}
            multiline={true}
            onPressOut={() => {
              if (showPicker) setShowPicker(false);
              if (showSetTimes) setshowSetTimes(false);
            }}
          />

          <Text style={mode === 'light' ? styles.headerLight : styles.headerDark}>More Remarks</Text>

          <TextInput
            selectionColor={'black'}
            activeOutlineColor={'black'}
            placeholder="e.g., Instructor/Alternate"
            style={mode === 'dark' ? styles.inputDark : styles.input}
            onChangeText={setRemarks2}
            value={remarks_2}
            multiline={true}
            onPressOut={() => {
              if (showPicker) setShowPicker(false);
              if (showSetTimes) setshowSetTimes(false);
            }}
          />
          <View style={{ flexDirection: 'row', alignItems: 'center', paddingTop: 30, paddingBottom: 30 }}>
            <CheckBox
              disabled={false}
              value={isWeekly}
              onValueChange={(newValue) => {
                setisWeekly(newValue);
              }}
            />

            <Text style={mode === 'light' ? styles.labelLight : styles.labelDark}> Repeat Weekly </Text>
            <CheckBox
              disabled={false}
              value={isYearly}
              onValueChange={(newValue) => setisYearly(newValue)}
            />
            <Text style={mode === 'light' ? styles.labelLight : styles.labelDark}> Repeat Yearly </Text>
          </View>

          <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Pressable
              style={styles.button}
              onPress={submitEvent}
            >
              <Text style={styles.textStyle}>Submit Event</Text>

            </Pressable>

            <Pressable
              style={styles.button}
              onPress={delEvent}
            >
              <Text style={styles.textStyle}>Delete Event</Text>

            </Pressable>
          </View>
        </View>
      </View>
    </ScrollView>
  );
};
const createStyles = (scale) => ({
  containerLight: {
    flex: 1,
    backgroundColor: colors.light_mode_screen_background,
  },
  containerDark: {
    flex: 1,
    backgroundColor: colors.dark_mode_screen_background,
  },
  input: {
    minHeight: 'fit-content',
    margin: 12,
    borderWidth: 1,
    padding: 3,
    borderRadius: 5,
    fontSize: 18,
    backgroundColor: colors.white,
    width: '50%',
  },
  inputDark: {
    minHeight: 'fit-content',
    margin: 12,
    borderWidth: 1,
    padding: 3,
    borderRadius: 5,
    fontSize: 18,
    backgroundColor: colors.light_gray,
    width: '50%',
  },
  list: {
    width: '75%',
    flex: 1,
  },
  title: {
    fontSize: scale(50),
  },
  button: {
    borderRadius: 10,
    padding: 10,
    elevation: 2,
    backgroundColor: 'black',
    margin: 8,
    borderColor: 'black',
    borderWidth: 1,
    width: 'fit-content',
  },
  buttonClose: {
    borderRadius: 10,
    padding: 10,
    elevation: 2,
    backgroundColor: 'black',
    margin: 8,
    borderColor: 'black',
    borderWidth: 1,
    width: 'fit-content',
  },
  view: {
    flexDirection: 'row',
    textAlign: 'center',
  },
  textStyle: {
    color: 'white',
    fontSize: '100%',
  },
  textStyleRed: {
    color: 'red',
    fontSize: '100%',
  },
  titleLight: {
    fontSize: scale(50),
    fontFamily: 'Trebuchet MS',
    marginTop: '0.5%',
    margin: '.05%',
    marginLeft: '38%',
  },
  titleDark: {
    fontSize: scale(50),
    fontFamily: 'Trebuchet MS',
    marginTop: '0.5%',
    margin: '.05%',
    marginLeft: '38%',
    color: colors.dark_mode_text,
  },
  main: {
    marginTop: '0%',
    height: '79%',
    // alignItems : 'center',
    marginLeft: '30%',
  },
  item: {
    backgroundColor: 'orange',
    padding: 20,
    marginVertical: 8,
    marginHorizontal: 16,
    borderRadius: 8,
    shadowColor: '#000',
    shadowOffset: { width: 3, height: 3 },
    shadowOpacity: 0.3,
    shadowRadius: 8,
  },
  innerText: {
    color: 'white',
    fontWeight: 'bold',
    padding: 10,
  },
  checkboxContainer: {
    flexDirection: 'row',
    fontSize: 18,
    alignItems: 'center',
    flex: 1,
  },
  heading: {
    fontSize: scale(50),
    fontFamily: 'Trebuchet MS',
    marginTop: '0.5%',
    marginLeft: '38%',
  },
  list2: {
    fontSize: 300,
    flex: 1,
  },
  modalStyling: {
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    // marginLeft: '250%',
  },
  labelLight: {
    fontSize: 15,
    color: colors.light_mode_text,
  },
  labelDark: {
    fontSize: 15,
    color: colors.dark_mode_text,
  },
  textLight: {
    fontSize: 22,
    textAlign: 'center',
    color: colors.light_mode_text,
  },
  textDark: {
    fontSize: 22,
    textAlign: 'center',
    color: colors.dark_mode_text,
  },
  dropdownContainer: {
    alignItems: 'center',
    width: '50%',
  },
  dropDownStyle: {
    borderColor: colors.black,
    borderWidth: 1,
    width: '50vw',
    backgroundColor: colors.white,
  },
  dropDownStyleDark: {
    borderColor: colors.black,
    borderWidth: 1,
    width: '50vw',
    backgroundColor: colors.light_gray,
  },
  headerLight: {
    fontSize: 18,
    fontWeight: 'bold',
    marginVertical: 8,
    padding: 10,
    textAlign: 'left',
    color: colors.black,
  },
  headerDark: {
    fontSize: 18,
    fontWeight: 'bold',
    marginVertical: 8,
    padding: 10,
    textAlign: 'left',
    color: colors.white,
  },
});

export default Review;
