import React, { useContext, createContext, useState, useEffect } from "react";
import ModeContext from "./ModeContext";

const ColorContext = createContext();

function swapColor(isLight) {
    const initialState = {
        mode: isLight ? "light" : "dark",

        logo: isLight
            ? require("../assets/US_Army_Reserve logo light mode large final.png")
            : require("../assets/US_Army_Reserve logo dark mode large final.png"),
        // Default colors
        defaultButton: "black",
        black: "#000000",
        blue: "#4287f5",
        light_blue: "#81aef7",
        navy_blue: "#0d1232",
        dark_gray: "#404040",
        white: "#FFFFFF",
        delete: "tomato",
        gold: "#ffc947",
        dark_gold: "#efa900",
        gray: "#757575",
        light_gray: "#D3D3D3",
        red: "#cc0000",
        purple: "#625364",

        // bow
        bow: isLight ? "black" : "white",
        wob: isLight ? "white" : "black",
        gow: isLight ? "#ECEBEB" : "white", //Grey or white
        wodg: isLight ? "white" : "#424242", //White or Dark Grey
        gob: isLight ? "#ECEBEB" : "000000", //Grey or Black
        godg: isLight ? "#ECEBEB" : "#424242", //Grey or Dark Grey
        golg: isLight ? "#ECEBEB" : "#D3D3D3", //Grey or Light Grey
        bolg: isLight ? "#000000" : "#D3D3D3", //Black or Light Grey
        pop: isLight ? "purple" : "pink", //Purple or Pink
        gobl: isLight ? "#FDEBC5" : "#16263B", //Gold or Blue
        inverseGobl: isLight ? "#16263B" : "#FDEBC5",
        gobl_for_checkbox: isLight ? "#FDEBC5" : "#1b4780", //Gold or Lighter Blue
        blgo: isLight ? "#16263B" : "#ffc947", //Blue or Leaderdash Gold

        // Loading Circle
        loading_circle: isLight ? "#D1C7D4" : "#625364",

        // Screen
        screen_background: isLight ? "#ffffff" : "#1f1f1f",

        // Header (back button, screen name, pfp)
        screen_title: isLight ? "#404040" : "#D7D8D9",

        filter_dropdown: isLight ? '#F5F5F5' : '#2A2A2A',

        // Textfields
        textfield_background: isLight ? "#ffffff" : "#2a2a2a",
        textfield_background_locked: isLight ? "#D3D3D3" : "#393939",
        textfield_background_hover: isLight ? '#FDEBC5': '#444',
        textfield_outline: isLight ? "#404040" : "#000000",
        inputted_text: isLight ? "#000000" : "#b3bbc9",
        inputted_text_disabled: isLight ? '#A0A0A0' : '#787878',
        placeholder_text: isLight ? "#404040" : "#b3bbc9",
        disabled_text: isLight ? '#BDBDBD' : '#3f4447',

        // Buttons
        button_text: isLight ? "#000000" : "#D7D8D9",
        button: isLight ? "#D3D3D3" : "#000000",
        button_border: isLight ? "#A98C42" : "#000000",
        button_hover: isLight ? "#E0E0E0" : "#1F1F1F",
        button_selected: isLight ? "#EDF4FB" : "#293037",

        // Loading
        loading_circle: isLight ? "#D1C7D4" : "#625364",

        // Text
        text: isLight ? "#404040" : "#D7D8D9",
        text_disabled: isLight ? "#b0b0b0" : "#909090",

        // Other
        modal_background: isLight ? "#ffffff" : "#404040",
        pdf_background: isLight ? "#ffffff" : "#404040",
        table_background_odd: isLight ? "#E4E4E4:" : "#363636",
        table_background_even: isLight ? "#EFEFEF" : "#262626",
        table_background_button: isLight ? "#D3D3D3" : "#4c4c4c",
        table_background_color: isLight ? "#ffffff" : "#363636",

        // Bottom Nav Bar
        inactive_nav_bar_text: isLight ? "#6F6F7A" : "#999",
        active_nav_bar_text: isLight ? "#000000" : "#F7CD46",
        inactive_nav_bar: isLight ? "#ffc947" : "#000",
        active_nav_bar: isLight ? "#ffc947" : "#000",

        // Gold Button and text
        gold_button: "#ffc947",
        gold_button_text: isLight ? "black" : "white",

        // Leader's dashboard
        leader_dash_light_gray: "#ECECEC",
        leader_dash_dark_gray: "#676A66",
        leader_dash_very_dark_gray: "#494949",
        leader_dash_darkmode_item: "#9F9F9F",
        leader_dash_modal: isLight ? "white" : "#676A66",
        leader_dash_legend_container: isLight ? "#ECECEC" : "#494949",
        leader_dash_selected: isLight ? "#A3A9A2" : "#C2C7C1",
        leader_dash_palette_1: isLight
            ? ["#98ba49", "#4991ba", "#0e0b75", "#b81a1a"]
            : ["#99ff66", "#66ccff", "#a16aeb", "#ff3333"],
        leader_dash_palette_2: isLight
            ? ["#41b6e6", "#ff6633", "#d3273e", "#9900cc"]
            : ["#66a6ff", "#ff9999", "#ff6666", "#cc66cc"],
        leader_dash_palette_3: isLight
            ? ["#00bf7d", "#1b365d", "#ffaa45", "#d3273e"]
            : ["#00ff99", "#3388cc", "#ffcc66", "#ff6666"],
        leader_dash_palette_4: isLight
            ? ["#ff4c4c", "#4d82b8", "#b51963", "#ac5bac"]
            : ["#ff6666", "#66a6ff", "#ff33cc", "#cc66cc"],
        leader_dash_med_pie_color_palette: isLight
            ? ["#98ba49", "#4991ba", "#0e0b75", "#b81a1a"]
            : ["#41ba18", "#18baaa", "#d1d126", "#c22b37"],
        leader_dash_blue: isLight ? "#3256a8" : "#6a9fff",
        leader_dash_orange: isLight ? "#f05e16" : "#ffaa45",
        leader_dash_yellow: isLight ? "#ffbf00" : "#fce805",
        leader_dash_green: isLight ? "#238823" : "#66ff66",
        leader_dash_red: isLight ? "#d2222d" : "#ff6c6c",

        // Leaders Dash Budget
        legendContainer: isLight ? "#ECECEC" : "#494949",

        aftpColors: isLight ? ["#003087", "#8A8D8F"] : ["#FF5733", "#EC7BFF"],
        mdrpColors: isLight ? ["#97a949", "#96547f"] : ["#FFC300", "#FF6F00"],
        ebdlColors: isLight ? ["#303bb0", "#5cd299"] : ["#64DD17", "#00E5FF"],
        rmaColors: isLight ? ["#ab581a", "#184697"] : ["#00B0FF", "#FF4081"],

        // Table Colors
        table_selected: isLight ? "#d9e8fc !important" : "#4d6578 !important",
        table_hover: isLight ? "#e1edfc !important" : "#3a3f49 !important",

        ebdl_screen: isLight ? '#ffffff' : '#353535',

        // Calendar Colors
        calendar_selected: isLight ? "#0d1232" : "#ffc947",
        calendar_inverse_text: !isLight ? "#404040" : "#D7D8D9",

        // Entry Box
        entry_box: isLight ? "#D3D3D3" : "#404040",
        form_border: isLight ? "#212121" : "#ffffff",

        // 1380 Button
        // content_box: isLight ? "#FEECC9" : "#404040",
        content_box: isLight ? "#FDEBC5" : "#404040",
        action_button: isLight ? "#FDEBC5" : "#B38118",
        // action_button: isLight ? "#FEECC9" : "#181F49",
        border_color: isLight ? "#000000" : "#74777a",
        button_cancel: isLight ? '#e9767d' : '#931820',
        button_submit: isLight ? '#78dc78' : '#195f19',
        button_draft: isLight ? '#98A9F1' : '#141B40',
        field_set: isLight ? "#c4c4c4" : "transparent",

        // new table colors (this will need to be a bit cleaned later)
        new_table_color: isLight ? "#bdbdbd" : "bcbcbc",
        new_table_color_selected: isLight ? "#ffffff" : "bcbcbc",
        new_table_bg_color: isLight ? "#ffffff" : "#323232",
        new_table_bg_color_selected: "#f60204",

        // Add event colors.
        outline_color: isLight ? "#f5f5f5" : "white",

        // Bug Report Colors.
        bg_color: isLight ? "#0d1232" : "black",

        // View comment colors.
        comment_color: isLight ? "#f5f5f5" : "#404040",

        // Navigation Side Bar
        navSideBar_Background: isLight ? "#FDEBC5" : "#141B40",
        selectedOption: isLight ? "#E2C485" : "#141B43",
        selectedSubMenuOption: isLight ? "#F6D899" : "#282F57",
        expandedSubmenuOptions: isLight ? "#FFE2A4" : "#060A23",

        // Home
        carouselBackdrop: isLight ? "#FDEBC5" : "#4B4B4B",

        // Alert colors
        errorRootColor: '#5f2120',
        errorIconColor: '#d32f2f',
        errorBackgroundColor: '#fdeded',
        warningRootColor: '#856404',
        warningIconColor: '#f57f17',
        warningBackgroundColor: '#fff3cd',
        successRootColor: '#1E4620',
        successIconColor: '#2e7d32',
        successBackgroundColor: '#F7F7ED',

        // file select
        file_button_color: isLight ? '#F5F5F5' : '#404040',
        file_selected: isLight ? '#ffc947' : '#b3bbc9',
        file_Submitted: isLight ? '#FFBF00' : '#FFBF00',
        file_Awaiting_Review: isLight ? '#FFBF00' : '#FFBF00',
        file_Must_Resubmit: isLight ? '#F05E16' : '#F05E16',
        file_Approved: isLight ? '#238823' : '#238823',
        file_Denied: isLight ? '#D2222D' : '#D2222D',
        file_Paid: isLight ? '#238823' : '#238823',
    };
    return initialState;
}

export const useColorContext = () => {
    const context = useContext(ColorContext);
    if (!context) {
        throw new Error("useColorContext must be used within a ColorProvider");
    }
    return context;
};

export default function ColorProvider({ children }) {
    const modeContext = useContext(ModeContext);
    const isLight = modeContext.mode === "light";

    // Compute the initial state based on the mode
    const initialState = swapColor(isLight);

    const [colors, setColors] = useState(initialState);

    useEffect(() => {
        // Update colors whenever the mode changes
        setColors(swapColor(modeContext.mode === "light"));
    }, [modeContext.mode]);

    return (
        <ColorContext.Provider value={{ colors, setColors }}>
            {children}
        </ColorContext.Provider>
    );
}
