import React, {useEffect} from 'react';
import {Modal, Text, View} from 'react-native';
import CalendarHeader from './CalendarHeader';
import Sidebar from './Sidebar';
import Month from './Month';
import {getMonth} from './util';
import PropTypes from 'prop-types';
import {useCalendarContext} from './CalendarContext';
import AddEventModal from './AddEventModal';
import EditEventModal from './EditEventModal';
import { requestCalendarEvents } from './CalendarFunctions';

export default function Calendar() {
  const {state, setState} = useCalendarContext();


  useEffect(() => {

    async function setEvents(){
      if((state.isSubmitted === true || state.events.length === 0)){
        await setState((prevState) => ({...prevState,isSubmitted:false}));
        requestCalendarEvents(state,setState);
        console.log(state.events);
      }
    }
    setEvents()
   
    
  }, [state.isSubmitted, state.showEventModal, state.showEditEventModal]);

  useEffect(() => {
    const updatedState = {
      ...state,
      currentMonthIndex: state.selectedMonth !== undefined ? state.selectedMonth : state.currentMonthIndex,
      selectedMonth: undefined,
      currentMonth: getMonth(state.currentMonthIndex),
      showEventModal: state.showEventModal,
	    selectedDay: state.selectedDay,
      todayClicked: false,
    };
    //updatedState.showEventModal = false;
    setState(updatedState);
  }, [state.selectedMonth, state.currentMonthIndex, state.selectedDay, setState]);


  const styles = ({
    container: {
      flex: 1,
      zoom: 0.85, // Set the zoom level
    },
    content: {
      flex: 1,
      flexDirection: 'row',
    },
    monthContainer: {
      flex: 1,
      width: '75%', // Allow Month to take up 75% of the width
    },
  });

  return (
     <View style={styles.container}>
      
      <CalendarHeader state={state} setState={setState}/>
      <View style={styles.content}>
        <View style={styles.content}>
          <AddEventModal
             state={state} setState={setState} // Control the visibility of the modal
          />
          <EditEventModal state={state} setState={setState}/>
          <Month state={state} setState={setState}/> 
        </View>
        
        <Sidebar state={state} setState={setState} />
      </View>
    </View>
  );
}

Calendar.propTypes = {
  navigation: PropTypes.object.isRequired,
};
