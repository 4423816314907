import { notificationsAPI } from './api_settings';
import axios from 'axios';

export const create_notification = async (data, token) => {
  const url_endpoint = notificationsAPI + 'notifications/create_notification';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.post(url_endpoint, data, { headers: header });
    return response;
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      return error.response;
    } else {
      console.error(error.response);
      return { 
        statusCode: 500,
        error: 'An error occurred while uploading the file.' 
      };
    }
  }
};
