export let API_ENV = 'PROD';
export const budgetAPI =            API_ENV === 'PROD' ? 'https://c8ec0aqw1a.execute-api.us-east-1.amazonaws.com/PROD/' : 'https://96mj95ymd0.execute-api.us-east-1.amazonaws.com/STAGE/';
export const eventsAPI =            API_ENV === 'PROD' ? 'https://4np7m3il8e.execute-api.us-east-1.amazonaws.com/PROD/' : 'https://gaeaa1qnu4.execute-api.us-east-1.amazonaws.com/STAGE/';
export const filesAPI =             API_ENV === 'PROD' ? 'https://zdxnx6y205.execute-api.us-east-1.amazonaws.com/PROD/' : 'https://q48ftpns09.execute-api.us-east-1.amazonaws.com/STAGE/';
export const medicalAPI =           API_ENV === 'PROD' ? 'https://mqzay2p0r7.execute-api.us-east-1.amazonaws.com/PROD/' : 'https://2nblhgztu2.execute-api.us-east-1.amazonaws.com/STAGE/';
export const notificationsAPI =     API_ENV === 'PROD' ? 'https://evaowtjhp7.execute-api.us-east-1.amazonaws.com/PROD/' : 'https://puy97pzqjh.execute-api.us-east-1.amazonaws.com/STAGE/';
export const rolesAPI =             API_ENV === 'PROD' ? 'https://m90dxxsuwb.execute-api.us-east-1.amazonaws.com/PROD/' : 'https://l5l69ffbr5.execute-api.us-east-1.amazonaws.com/STAGE/';
export const unitsAPI =             API_ENV === 'PROD' ? 'https://dgopcb8i23.execute-api.us-east-1.amazonaws.com/PROD/' : 'https://lfwcufi9h9.execute-api.us-east-1.amazonaws.com/STAGE/';
export const usersAPI =             API_ENV === 'PROD' ? 'https://slb4a0tzd5.execute-api.us-east-1.amazonaws.com/PROD/' : 'https://6dpf8inpni.execute-api.us-east-1.amazonaws.com/STAGE/';
export const jiraAPI =              API_ENV === 'PROD' ? 'https://wul1jzski6.execute-api.us-east-1.amazonaws.com/default/' : 'https://wul1jzski6.execute-api.us-east-1.amazonaws.com/default/'
export const s3API =                API_ENV === 'PROD' ? 'https://qkip87u2t5.execute-api.us-east-1.amazonaws.com/dev/' : 'https://mk0hqsqkae.execute-api.us-east-1.amazonaws.com/STAGE/';
