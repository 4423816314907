import React from 'react';
import {View, Text, TouchableOpacity} from 'react-native';
import {useColorContext} from '../ColorContext';
import Add from '@mui/icons-material/Add';

export default function CreateEventButton({state,setState}) {
  const {colors} = useColorContext();

  const styles = ({
    createEventButton:{
      fontSize: 24,
      fontFamily: 'Calibri',
      fontWeight: 'bold',
      backgroundColor: colors.navSideBar_Background,
      borderRadius: 5,
      padding: 10,
      alignItems: 'center',
      justifyContent: 'center',
      width: '10%',
      color: colors.text
      // flexDirection: 'row',
    },
    buttonText: {
      fontSize: 27,
      color: colors.text,
      alignItems: 'center',
      fontWeight: 'bold',
      justifyContent: 'center',
      flex: 1,
      padding: '2%',
    },
  });

  return (
    (global.user_data.permissions.includes(2051) || global.user_data.permissions.includes(2052)) ? (
      <TouchableOpacity
        onPress={() => {
          console.log('Button Pressed');
          setState({...state, showEventModal: true});
        }}
        style={styles.createEventButton}
      >
        <Text style={styles.buttonText}>Add Event</Text>
        {/* Add component can be used here if it's an SVG or other custom component */}
      </TouchableOpacity>
    ) : null
  );  
}
