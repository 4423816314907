import React from 'react';
import {View, Text} from 'react-native';
import {TouchableOpacity, StyleSheet } from 'react-native';

import {useScale} from '../components/scale';
import CustomHeader from '../components/header';
import NavSideBar from '../components/NavSideBar';
import { useColorContext} from '../components/ColorContext';
import ModeContext from '../components/ModeContext';


function Review({navigation}) {
  const {mode} = React.useContext(ModeContext);
  const {colors} = useColorContext();
  const scale = useScale();
  const styles = ({
    container: {
      flex: 1,
      backgroundColor: colors.screen_background,
    },
    navBarWrapper: {
      flexDirection: 'row',
      height: '100%',
      flex: 1,
    },
    list: {
      width: '75%',
    },
    title: {
      fontSize: scale(50),
      fontFamily: 'Trebuchet MS',
      marginTop: '0.5%',
      color: colors.text,
    },
    button: {
      marginTop: 2,
    },
    header: {
      backgroundColor: 'pink',
      height: '15%',
    },
    main: {
      marginBottom: 20,
    },
    item: {
      color: mode == 'dark' ? colors.white : colors.black,
      backgroundColor: colors.button,
      alignItems: 'center',
      justifyContent: 'center',
      padding: 20,
      height: 100,
      width: 700,
      margin: 10,
      borderRadius: 20,
      marginVertical: 8,
      marginHorizontal: 16,
      borderRadius: 8,
      shadowColor: '#000',
      shadowOffset: { width: 3, height: 3 },
      shadowOpacity: 0.3,
      shadowRadius: 8,
    },
    innerText: {
      color: colors.button_text,
      fontWeight: 'bold',
      padding: 10,
      fontSize: 20,
    },
    buttonContainer: {
      flexDirection: 'column',
      flex: 2,
      justifyContent: 'center',
      alignItems: 'center',
      maxHeight: 250,
      marginVertical: 50,
    },
    hidden: {
      backgroundColor: 'transparent',
      borderWidth: 0,
    },
    wrapper: {
      flex: 1,
      alignItems: 'center',
      marginVertical: 10,
    },
  });

  const onPressCCIRButton = () => {
    navigation.navigate("CCIR")
  };
  return (

    <View style = {styles.container}>
      <View style={styles.navBarWrapper}>
      {/* <NavSideBar /> */}
      
      <View style={styles.wrapper}>
      <View style = {styles.main}>
        <Text style = {styles.title}>Report</Text>
        
      </View>
      <View style={styles.buttonContainer}>
        <TouchableOpacity
          style={styles.item}
          onPress={onPressCCIRButton}
        >
          <Text style={styles.innerText}>CCIR</Text>
        </TouchableOpacity>
      </View> 
      </View>
      </View>
    </View>
  );
};

export default Review;
