import React, { useCallback, useState, useEffect } from 'react';
import { View, StyleSheet } from "react-native";
import ChartRangeFilter from "./ChartRangeFilter";
import ChartToggler from "./ChartToggler";
import Bump from "../Bump";
import BudgetOverview from "./BudgetOverview";
import Stats from "./Stats";
import { get_budget_stats } from "../../api_handler/budget";
import { getFiscalYear } from "../../utils/monthInfo";
import {get_budget} from "../../api_handler/budget";

// Test budget data
const testBudget = {
    budget_pot_type: 'Test Pot',
    budget_amount: 1000,
    budget_spent: 500,
    end_date: '2023-12-31',
    edit_date: '2023-12-01',
    budget_left: 500,
    adaBudget: 100,
    aftpBudget: 200,
    mdrBudget: 300,
    rmaBudget: 400,
    ebdlBudget: 500,
  };


function BottomChart({ openPopup, range, setRange, targetLine, handleDataKeyToggle, dataKeys, activeChart, unitId, budgetGroupButton, token, handleBudgetEditOrDelete}) {
    const [budget, setBudget] = useState(null);
    const [refresh, toggleRefresh] = useState(false);

    const handleRefreshStats = () => {
    toggleRefresh(!refresh);
    fetchData();
    handleBudgetEditOrDelete();
    };

    const fetchData = async () => {
        try {
            const result = await hasBudget();
            setBudget(result);
        } catch (error) {
            console.error('Error fetching budget:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [unitId, activeChart, budgetGroupButton, token, budget, refresh]);

    const getBudgetStats = useCallback(() => {
        return get_budget_stats({ "unit_id": unitId, "subtype": activeChart, "pot": budgetGroupButton, "fiscal_year": getFiscalYear() }, token);
    }, [unitId, activeChart, budgetGroupButton, getFiscalYear, token]);

    const data = {
        "pot": budgetGroupButton,
        "subtype": activeChart,
        "unit_id": unitId,
        "fiscal_year": getFiscalYear(),
    }

    async function hasBudget() {
        try {
            const results = await get_budget(data, token);

            if (results !== null)
            {
                if (results.status === 200)
                    return 1;
                else
                    return null;
            }

            else return null;

        } catch (error) {
            return null;
        }
    }
    
    return (
        <View>
            <View style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <ChartToggler handleToggle={handleDataKeyToggle} dataKeys={dataKeys}/>
                <Bump size={20} />
                <ChartRangeFilter targetLine={targetLine} openPopup={openPopup} range={range} setRange={setRange}/>
            </View>
            <View>
                <Stats getBudgetStats={getBudgetStats} activeChart={activeChart} budget={budget} refresh={refresh}/>
                {/* Enter Null as budget to test as empty */}
            </View>
            <View style={{display: 'block', alignItems: 'center'}}>
                <BudgetOverview budget={budget} unitId={unitId} budgetGroupButton={budgetGroupButton} token={token} activeChartButton={activeChart} refreshStats={refresh} handleRefreshStats={handleRefreshStats}/>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    view: {
        width: "100%",
        backgroundColor: "blue",
    },
});

export default BottomChart;
