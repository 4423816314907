/* eslint-disable require-jsdoc */
import React, { useEffect, useReducer, useState, useMemo, useRef } from 'react';
import { View, Text, TouchableOpacity, ScrollView } from 'react-native';

import { TextField, IconButton, Autocomplete} from '@mui/material';
import { DeleteForeverRounded } from '@mui/icons-material';
import { useTheme, darken, ThemeProvider, createTheme } from '@mui/material/styles';
import { useIsFocused } from '@react-navigation/native';
import { useScale } from '../../../components/scale';
import CustomHeader from '../../../components/adminHeader';

import ModeContext from '../../../components/ModeContext';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';

import { MaterialReactTable, MRT_FullScreenToggleButton, MRT_ToggleGlobalFilterButton, MRT_ToggleFiltersButton } from 'material-react-table';

import { LogBox } from 'react-native';
import { delete_unit, get_all_users_in_single_unit, get_table_unit, get_unit_id_name, get_units, update_unit } from '../../../api_handler/units';
import { useColorContext } from '../../../components/ColorContext';
import NavSideBar from '../../../components/NavSideBar';
import Scrollbars from 'react-custom-scrollbars';
import { useNavBarContext } from '../../../components/NavBarContext';
import FormHelperText from '@mui/material/FormHelperText';
import { TextInput, HelperText } from "react-native-paper";
import { useSnackbar } from '../../../components/SnackbarContext';
import MenuItem from '@mui/material/MenuItem';
import * as Progress from 'react-native-progress';
import zIndex from '@mui/material/styles/zIndex';
import { List, Paper } from '@mui/material'

function View_units({ navigation }) {
  const { colors } = useColorContext();
  const { navState } = useNavBarContext();
  const { showSnackbar } = useSnackbar();
  // Ignore the specific warning message related to the Modal
  LogBox.ignoreLogs(['The modal content node does not accept focus.']);
  // Table
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [loaded, setLoaded] = useState(false);
  const states = require('../users/json_files/States.json');
  const [rowsPerPage, setRowsPerPage] = React.useState(7);

  const isFocused = useIsFocused();
  const tableInstanceRef = useRef(null);
  const scale = useScale();

  const { mode } = React.useContext(ModeContext);
  const [density, setDensity] = useState('compact');
  const [columnVisibility, setColumnVisibility] = useState({});
  const [rowSelection, setRowSelection] = useState({});
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const rerender = useReducer(() => ({}), {})[1]; // Forces the MRT_ components to re-render
  const [showColumnFilters, setShowColumnFilters] = useState(false);
  const [showGlobalFilter, setShowGlobalFilter] = useState(false);
  const [expandedUnitData, setExpandedUnitData] = useState({});
  const [expandedAssociatedNameData, setExpandedAssociatedNameData] = useState({});
  const [showAddRow, setShowAddRow] = useState(true);
  const [updateUICError, setupdateUICError] = useState(false);
  const [updateNameError, setupdateNameError] = useState(false);
  const [originalUnitName, setOriginalUnitName] = useState('');
  const [originalUnitUIC, setoriginalUnitUIC] = useState('');
  const [originalAddressLine1, setOriginalAddressLine1] = useState('');
  const [originalAddressLine2, setOriginalAddressLine2] = useState('');
  const [originalCity, setOriginalCity] = useState('');
  const [originalState, setOriginalState] = useState('');
  const [originalZipcode, setOriginalZipcode] = useState('');

  const [hasRole, setHasRole] = React.useState(false);
  const [isSubmitted, setIsSubmitted] = useState(true);
  const [unitNameExistsError, setUnitNameExistsError] = useState(false);
  const [UICExistsError, setUICExistsError] = useState(false);
  const [endpointFailError, setEndpointFailError] = useState(false);

  const styles = {
    table: {
      tableContainer: {
        marginLeft: '5%',
        width: '91%',
        height: '100%',
        marginTop: '2%'
      }
    },
    container: {
      flex: 1,
      height: '100%',
      backgroundColor: colors.screen_background,
    },
    wrapper: {
      backgroundColor: colors.screen_background,
      flexDirection: 'row',
      height: '100%'
    },
    header: {
      height: 125,
    },
    innerContainer: {
      flexDirection: 'row',
      alignItems: 'stretch',
      width: '100%',
      height: '87%',
      backgroundColor: colors.screen_background,
    },
    scrollView: {
      backgroundColor: colors.screen_background,
      height: '100%',
      width: '100%',
    },
    title: {
      fontSize: scale(30),
      fontFamily: 'Trebuchet MS',
      flex: 1,
      color: colors.text,
      paddingLeft: '5%',
      paddingTop: 25
    },
    title_div: {
      marginTop: '0%',
      height: '10%',
      alignItems: 'center',
    },
    page_title: {
      fontSize: 50,
      fontFamily: 'Trebuchet MS',
      height: '100%',
      color: colors.text,
    },
    descriptionAndFilterContainer: {
      flexDirection: 'row',
      marginTop: '1%',
      alignItems: 'flex-start',

      description: {
        width: '100%',
        fontFamily: 'Trebuchet MS',
        fontSize: scale(18),
        color: colors.text,
        paddingLeft: '5%',
        paddingRight: '5%',
        flex: 1
      },
    },
    main: {
      height: '100%',
      flexDirection: 'column',
      flex: 10
    },
    titleDiv: {
      flexDirection: 'row',
      width: '100%',
      alignItems: 'center',
    },
    searchBar: {
      width: '50%',
      fontSize: '40',
      borderRadius: '40px',
      marginTop: 30,
      marginBottom: 30,
      alignItems: 'space-evenly',
    },
    buttonDiv: {
      flexDirection: 'row',
      height: '10%',
      alignItems: 'center',
      justifyContent: 'space-evenly',
    },
    tableView: {
      width: '100%',
      alignItems: 'center',
      height: '80%',
    },
    tableDiv: {
      marginTop: '0%',
      backgroundColor: 'white',
      width: '90%',
      alignItems: 'center',
    },
    buttons: {
      width: '15%',
      // backgroundColor: '#0d1232',
      borderRadius: '40px',
      alignItems: 'center',
      shadowColor: '#171717',
      shadowOffset: { width: -2, height: 4 },
      shadowOpacity: 0.2,
      shadowRadius: 3,
      marginTop: 30,
      marginBottom: 30,
      backgroundColor: colors.button,
    },
    innerText: {
      color: colors.button_text,
      fontWeight: 'bold',
      padding: 10,
    },
    commentInput: {
      minHeight: "fit-content",
      borderWidth: 1,
      borderRadius: '5px',
      marginTop: '1%',
      backgroundColor: colors.textfield_background,
      width: "95%",
      '& .MuiFormHelperText-root.Mui-error': {
        backgroundColor: colors.textfield_background,
        borderRadius: '5px',
        color: '#d32f2f',
        margin: 0,
        paddingLeft: 1,
      },
    },
    textbox: {
      width: "35%",
      marginTop: '1%',
      marginBottom: '1%',
      backgroundColor: colors.textfield_background,
      '& .MuiFormHelperText-root.Mui-error': {
        marginTop: '5px',
        backgroundColor: colors.textfield_background,
        borderRadius: '5px',
        color: '#d32f2f',
        margin: 0,
        paddingLeft: 1,
      },
      '& .MuiInputLabel-root': {
        color: colors.text,
      },
    },
    
    textInputProps: {
      '& .MuiFormLabel-root, & label.Mui-focused, & .MuiInputBase-input': {
        color: colors.inputted_text,
      },
      '.MuiSvgIcon-root': {
        fill: colors.inputted_text,
      },
      '&  .MuiFormHelperText-root': {
        backgroundColor: colors.textfield_background,
        margin: 0,
        paddingLeft: 1,
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: colors.border_color,
        },
        '&:hover fieldset': {
          borderColor: colors.form_border,
        },
        '&.Mui-focused fieldset': {
          borderColor: '#1876d2',
        },
      },
    },
  };

  async function get_all_units() {
    const ret = await get_units({}, global.token);
    if (ret.status === 200) {
      setRows(ret.data.body);
    } else {
      showSnackbar("error", "Application Error: Failed to retrieve all unit's information");
    }
    setLoaded(true);
  }

  useEffect(() => {
    if (navState.isSuperUser) {
      setHasRole(true);
    } else {
      setHasRole(false);
    }
  }, []);

  useEffect(() => {
    if (isSubmitted == true) {
      get_all_units();
      setIsSubmitted(false);
    }
  }, [isSubmitted]);

  // Delete Unit code
  const [deleteModalVis, setDeleteModalVis] = React.useState(false);
  const [confirmDeleteModalVis, setConfirmDeleteModalVis] = React.useState(false);
  const [unitID, setUnitID] = React.useState();
  const [unitName, setUnitName] = React.useState('');
  const [unitUIC, setUnitUIC] = React.useState('');
  const [addressLine1, setAddressLine1] = React.useState('');
  const [addressLine2, setAddressLine2] = React.useState('');
  const [state, setState] = React.useState('');
  const [city, setCity] = React.useState('');
  const [zipCode, setZipCode] = React.useState('');
  const [addressLine1EmptyError, setAddressLine1Error] = useState(false);
  const [addressLine2EmptyError, setAddressLine2Error] = useState(false);
  const [stateEmptyError, setStateEmptyError] = useState(false);
  const [cityEmptyError, setCityEmptyError] = useState(false);
  const [zipCodeEmptyError, setZipCodeEmptyError] = useState(false);
  const [zipCodeLengthError, setZipCodeLengthError] = useState(false);
  const [unitSoldiers, setUnitSoldiers] = React.useState([]);
  const [units, setUnits] = React.useState([]);

  function startDeleteProcess(unit_id, unit_name) {
    setDeleteModalVis(true);
    setUnitID(unit_id);
    setUnitName(unit_name);
  }

  function cancelDelete() {
    setUnitID('');
    setUnitName('');
    setUnitUIC('');
    setUnitSoldiers([]);
    setDeleteModalVis(false);
    setConfirmDeleteModalVis(false);
  }

  const deleteWarning = async () => {
    const ret = await get_all_users_in_single_unit({ unit_id: unitID }, global.token);
    if (ret.status !== 200) {
      showSnackbar("error", "Application Error: Failed to retrieve soldiers apart of unit to be deleted")
      setDeleteModalVis(false);
      setConfirmDeleteModalVis(false);
      return;
    }

    if (ret.data.body.length > 0) {
      const soldiers = ret.data.body.map((item) => ({
        name: item.name,
        unit_id: item.unit_id,
        user_id: item.user_id,
      }));

      setUnitSoldiers(soldiers);
      setUnits(soldiers.map((obj) => obj.user_id));
      viewConfirmDelete();
    } else {
      confirmDelete();
    }
  };

  function viewConfirmDelete() {
    setConfirmDeleteModalVis(true);
    console.log(units);
  }

  const confirmDelete = async () => {
    setLoaded(false);
    let res = await delete_unit({ unit_id: unitID, admin_id: global.user_data.user_id }, global.token);
    console.log(res)
    if (res !== undefined && res.body.includes('Unit deleted with ID')) {
      showSnackbar("success", "Successfully deleted unit")
      setDeleteModalVis(false);
      setConfirmDeleteModalVis(false);
    } else {
      showSnackbar("error", "Application Error: Failed to delete unit")
    }
    get_all_units();
    setLoaded(true);
  };

  // Update Unit code
  const [updateUnitModalVis, setUpdateUnitModalVis] = React.useState(false);

  async function startUpdateProcess(unit_name, unit_uic, unit_id, unit_address_line_1, unit_address_line_2, unit_city, unit_state, unit_zipcode) {
    setUnitName(unit_name);
    setUnitUIC(unit_uic);
    setUnitID(unit_id);
    setAddressLine1(unit_address_line_1);
    setAddressLine2(unit_address_line_2);
    setCity(unit_city);
    setState(unit_state);
    setZipCode(unit_zipcode);
    setUpdateUnitModalVis(true);
  }

  async function cancelUnitUpdate() {
    setUpdateUnitModalVis(false);
    setUnitName('');
    setUnitUIC('');
    setUnitID('');
    setupdateNameError(false);
    setupdateUICError(false);
  }


  const checkOutput = (response) => {
    let error = false;
    setUICExistsError(false);
    setUnitNameExistsError(false);

    if (response.data.body.includes('UIC and Unit name already exists')) {
      setUICExistsError(true);
      setUnitNameExistsError(true);
      showSnackbar('error', 'UIC and unit name already exist');
      return true;
    } else if (response.data.body.includes('UIC already exists')) {
      setUICExistsError(true);
      showSnackbar('error', 'UIC already exists');
      return true;
    } else if (response.data.body.includes('the Unit name already exists')) {
      setUnitNameExistsError(true);
      showSnackbar('error', 'Unit name already exists');
      return true;
    } else if (response.status !== 200) {
      setUICExistsError(false);
      setUnitNameExistsError(false);
      setEndpointFailError(true);
      showSnackbar('error', 'Application Error: Failed to update unit');
      return true;
    }

    return error;
  };

  // const handleOpenAlert = () => {
  //   if (UICExistsError && unitNameExistsError) {
  //     showSnackbar('error', 'UIC and unit name already exist');
  //   } else if (UICExistsError) {
  //     showSnackbar('error', 'UIC already exists');
  //   } else if (unitNameExistsError) {
  //     showSnackbar('error', 'Unit name already exists');
  //   }
  // };

  // Update Unit
  async function updateUnit() {
    const isEmpty = (value) => value === '';
  
    // Validate inputs before making the API call
    if (unitName === '' && unitUIC === '') {
      setupdateNameError(true);
      setupdateUICError(true);
      showSnackbar("error", "Please enter a valid unit name and UIC");
      return;
    } else if (unitName === '') {
      setupdateNameError(true);
      setupdateUICError(false);
      showSnackbar("error", "Please enter a valid unit name");
      return;
    } else if (unitUIC === '') {
      setupdateUICError(true);
      setupdateNameError(false);
      showSnackbar("error", "Please enter a valid unit UIC");
      return;
    } else if ((addressLine1 || state || city || zipCode) && (isEmpty(addressLine1) || isEmpty(state) || isEmpty(city) || isEmpty(zipCode) || (!isEmpty(zipCode) && zipCode.length !== 5))) {
      showSnackbar("error", "Please enter a complete street address");
      setAddressLine1Error(isEmpty(addressLine1));
      setAddressLine2Error(isEmpty(addressLine2));
      setStateEmptyError(isEmpty(state));
      setCityEmptyError(isEmpty(city));
      setZipCodeEmptyError(isEmpty(zipCode));
      setZipCodeLengthError(!isEmpty(zipCode) && zipCode.length !== 5);
      return;
    }
  
    setLoaded(false);
  
    const response = await update_unit({
      unit_name: unitName,
      unit_uic: unitUIC,
      unit_id: unitID,
      admin_id: global.user_data.user_id,
      original_name: originalUnitName,
      original_uic: originalUnitUIC,
      original_address_line_1: originalAddressLine1,
      original_address_line_2: originalAddressLine2,
      original_city: originalCity,
      original_state: originalState,
      original_zipcode: originalZipcode,      
      unit_address_line_1: addressLine1,
      unit_address_line_2: addressLine2,
      unit_city: city,
      unit_state: state,
      unit_zipcode: zipCode
    }, global.token);
  
    //console.log("the response is " + response.body);
  
    // Check for response errors
    if (response !== undefined && checkOutput(response)) {
      // handleOpenAlert();
      console.log("update unit error");
      setLoaded(true);
    } else {
      console.log("no duplicates!");
      setupdateNameError(false);
      setupdateUICError(false);
      setAddressLine1Error(false);
      setAddressLine2Error(false);
      setStateEmptyError(false);
      setCityEmptyError(false);
      setZipCodeEmptyError(false);
      setZipCodeLengthError(false);
      setUnitName('');
      setUnitUIC('');
      setUnitID('');
      setAddressLine1('');
      setAddressLine2Error('');
      setState('');
      setCity('');
      setZipCode('');
      setUpdateUnitModalVis(false);
      showSnackbar("success", "Unit edited successfully.");
      setLoaded(true);
      get_all_units();
    }
  }
  

  const fetchUnitData = async (unit_id) => {
    try {
      let res = await get_table_unit({ unit_id }, global.token);
      if (res.status !== 200) {
        showSnackbar("error", "Application Error: Failed to retrieve unit information")
        return null;
      }
      return res.data.body;
    } catch (error) {
      console.error('Error fetching user data:', error);
      return null;
    }
  };

  const fetchAssociatedName = async (unit_id) => {
    try {
      let res = await get_unit_id_name({ unit_id }, global.token)
      if (res.status !== 200) {
        showSnackbar("error", "Application Error: Failed to retrieve unit information")
        return null;
      }
      return res.data.body.unit_name;
    } catch (error) {
      console.error('Error fetching name data:', error);
      return null;
    }
  };

  const SectionHeader = ({ children }) => (
    <Typography variant="h5" style={{ fontWeight: 'bold' }}>
      {children}
    </Typography>
  );

  const Field = ({ children }) => (
    <Typography variant="h6">
      {children}
    </Typography>
  );

  const BottomField = ({ children }) => (
    <Typography variant="h6" style={{ marginBottom: '10px' }}>
      {children}
    </Typography>
  );

  const globalTheme = useTheme();
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: mode,
          primary: globalTheme.palette.primary,
          background: {
            default: colors.textfield_background,
          },
        },
      }),
    [globalTheme, isFocused],
  );

  const columns = [
    {
      accessorKey: 'unit_name',
      header: 'Unit Name',
      size: 1,
      enableSorting: false,
      enableColumnFilter: false,
      enableGrouping: false,
      Cell: ({ row }) => {
        return (
          <Text style={{ fontSize: 24, color: colors.bow, fontSize: scale(16), fontFamily: 'Trebuchet MS' }}>
            {row.original.unit_name}
          </Text>);
      }
    },
    {
      accessorKey: 'del',
      header: 'Delete Unit',
      size: 1,
      fontSize: scale(24),
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      enableSorting: false,
      enableColumnFilter: false,
      enableGrouping: false,
      Cell: ({ row }) => {
        return (
          <IconButton style={{ padding: 0, }} onClick={() => {
            startDeleteProcess(row.original.unit_id, row.original.unit_name);
          }}>
            <DeleteForeverRounded style={{ color: 'red' }} />
          </IconButton>
        );
      },
    },
    {
      accessorKey: 'edit',
      header: 'Edit Unit',
      size: 1,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      enableSorting: false,
      enableColumnFilter: false,
      enableGrouping: false,
      Cell: ({ row }) => {
        const [isHovered, setIsHovered] = React.useState(false);

        const handleMouseEnter = () => {
          setIsHovered(true);
        };

        const handleMouseLeave = () => {
          setIsHovered(false);
        };

        return (
          <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {row.original.is_deletable === false ? (
              <Upload />
            ) : (
              <IconButton
                onClick={() => {
                  setoriginalUnitUIC(row.original.uic)
                  setOriginalUnitName(row.original.unit_name)
                  setOriginalAddressLine1(row.original.unit_address_line_1)
                  setOriginalAddressLine2(row.original.unit_address_line_2)
                  setOriginalCity(row.original.unit_city)
                  setOriginalState(row.original.unit_state)
                  setOriginalZipcode(row.original.unit_zipcode)
                  startUpdateProcess(row.original.unit_name, row.original.uic, row.original.unit_id, row.original.unit_address_line_1, row.original.unit_address_line_2, row.original.unit_city, row.original.unit_state, row.original.unit_zipcode);
                }}
              >
                <EditIcon style={{ color: isHovered ? 'green' : mode == 'dark' ? colors.white : colors.gray }} />
              </IconButton>
            )}
          </div>
        );
      },
    }
  ];

  const loadingStyles = {
    animation: {
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
        justifyContent: 'center',
        paddingVertical: 20,
        background: colors.screen_background,
        zIndex: 100,
    }
  }

  if (!loaded) {
    return (
      <View style={loadingStyles.animation}>
          <Progress.CircleSnail
            color={colors.loading_circle}
            indeterminate={true}
            size={275}
            thickness={8}
            zIndex={1100}
          />
      </View>
    );
  }

  return (
    <Scrollbars showsverticalscrollindicator="true"style={styles.scrollView}>
      <View style={styles.wrapper}>
        {/* <NavSideBar /> */}

        {!hasRole ? (
          <View style={styles.main}>
          <View style={styles.title_div}>
            <Text style={styles.page_title}>Access Denied: User does not have permission to view this page.</Text>
          </View>
        </View>
      ) : (
        <View style={styles.main}>
          <View style={styles.titleDiv}>
            <Text style={styles.title}>
              <Text style={{ fontWeight: 'bold' }}>Admin Console {'>'} </Text>
              <Text>Manage Units</Text>
            </Text>
          </View>
          <View style={styles.descriptionAndFilterContainer}>
            <View style={{ width: '50', flex: 1 }}>
              <Text style={styles.descriptionAndFilterContainer.description}>
                This page allows admins to manage units being used within Project Mercury.
                Admins are able to add, edit, filter, and delete given units within the application.
              </Text>
            </View>
          </View>



          {/* Delete role modal */}
          <Modal
            open={deleteModalVis}
            style={{
              margin: 0,
              width: '40%',
              borderRadius: '15px',
              top: '30%',
              left: '40%',
              right: 'auto',
              bottom: 'auto',
              zIndex: 1000,
            }}
          >
            <View style={{
              backgroundColor: colors.screen_background,
              alignItems: 'center',
              height: '50%',
              borderRadius: '15px'
            }}>
              <View style={{ height: '50%', alignItems: 'center', marginTop: 50 }}>
                <Text style={{
                  color: colors.text,
                  fontSize: 25,
                  fontFamily: 'Trebuchet MS',
                  textAlign: 'center',
                }}>{'Are you sure you would like to delete the unit "' + unitName + '"?'}</Text>
              </View>

              <View style={{
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-around',
              }}>
                <TouchableOpacity
                  style={{
                    width: '15%',
                    borderRadius: '40px',
                    alignItems: 'center',
                    backgroundColor: colors.button,
                    shadowColor: '#171717',
                    shadowOffset: { width: -2, height: 4 },
                    shadowOpacity: 0.2,
                    shadowRadius: 3,
                    marginTop: 30,
                    marginBottom: 30,
                  }}
                  onPress={() => cancelDelete()}>
                  <Text style={styles.innerText}>Cancel</Text>
                </TouchableOpacity>

                <TouchableOpacity
                  style={{
                    width: '15%',
                    borderRadius: '40px',
                    alignItems: 'center',
                    backgroundColor: colors.button,
                    shadowColor: '#171717',
                    shadowOffset: { width: -2, height: 4 },
                    shadowOpacity: 0.2,
                    shadowRadius: 3,
                    marginTop: 30,
                    marginBottom: 30,
                  }}
                  onPress={() => deleteWarning()}>
                  <Text style={styles.innerText}>Submit</Text>
                </TouchableOpacity>
              </View>
            </View>
          </Modal>
          {/* End of Delete Modal */}

          {/* Confirm Delete role modal */}
          <Modal
            open={confirmDeleteModalVis}
            style={{
              margin: 0,
              width: '40%',
              height: '120%',
              top: '30%',
              left: '30%',
              right: 'auto',
              bottom: 'auto',
              borderRadius: '15px',
              zIndex: 1000,
            }}
          >
            <View style={{
              backgroundColor: colors.screen_background,
              alignItems: 'center',
              height: '50%',
            }}>
              <View style={{ height: '50%', alignItems: 'center', marginTop: 50 }}>
                <Text style={{
                  fontSize: 50,
                  fontFamily: 'Trebuchet MS',
                  textAlign: 'center',
                }}>Are you sure? {unitName} currently contains:</Text>
              </View>
              <ScrollView style={{
                maxHeight: '600vh', // Adjust as needed
                width: '90%',
                borderWidth: 2,
                borderColor: 'gray', // You can adjust the border color
                borderRadius: '10px',
                padding: 10,
                marginTop: -50,
                marginBottom: 20,
              }}
              >
                <Text style={{
                  fontSize: 25,
                  fontFamily: 'Trebuchet MS',
                  textAlign: 'center',
                  color: colors.text,
                }}>{unitSoldiers.map((soldier) => `${soldier.name}`).join(',\n')}
                </Text>
              </ScrollView>
              <View style={{
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-around',
              }}>
                <TouchableOpacity
                  style={{
                    width: '15%',
                    alignItems: 'center',
                    backgroundColor: colors.button,
                    borderRadius: '15px',
                    shadowColor: '#171717',
                    shadowOffset: { width: -2, height: 4 },
                    shadowOpacity: 0.2,
                    shadowRadius: 3,
                    marginTop: 30,
                    marginBottom: 30,
                  }}
                  onPress={() => cancelDelete()}>
                  <Text style={styles.innerText}>Cancel</Text>
                </TouchableOpacity>

                <TouchableOpacity
                  style={{
                    width: '15%',
                    alignItems: 'center',
                    backgroundColor: colors.button,
                    borderRadius: 15,
                    shadowColor: '#171717',
                    shadowOffset: { width: -2, height: 4 },
                    shadowOpacity: 0.2,
                    shadowRadius: 3,
                    marginTop: 30,
                    marginBottom: 30,
                  }}
                  onPress={() => confirmDelete()}>
                  <Text style={styles.innerText}>Confirm</Text>
                </TouchableOpacity>
              </View>
            </View>
          </Modal>
          {/* End of Confirm Delete Modal */}
          {/* Update Unit Modal */}
          <Modal
            open={updateUnitModalVis}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                position: 'absolute',
                width: '30%',
                borderRadius: '10px',
                zIndex: 1000,
              }}
            >

              <View 
                style={{
                  backgroundColor: colors.textfield_background,
                  alignItems: 'center',
                  height: '40%',
                  borderRadius: '10px',
                  paddingLeft: '3%',
                  paddingRight: '3%'
                }}
              >
                <View style={{ height: '15%', alignItems: 'center', marginTop: '5%', marginBottom: '5%'}}>
                  <Text style={{
                    fontSize: 50,
                    fontFamily: 'Trebuchet MS',
                    textAlign: 'center',
                    color: colors.text,
                  }}>Update Unit</Text>
                </View>
                <View style={{flexDirection: 'row', alignItems: 'center', width: '95%'}}>
                  <TextField
                    style={styles.commentInput}
                    sx={styles.textInputProps}
                    label="Unit UIC"
                    value={unitUIC}
                    onChange={(data) => setUnitUIC(data.target.value)}
                    InputLabelProps={styles.textbox.InputLabelProps}
                    InputProps={{
                      style: { backgroundColor: colors.modal_background }, 
                      ...styles.textbox.InputProps 
                    }}
                    error={updateUICError || UICExistsError}
                    helperText={updateUICError ? 'Enter unit UIC' : ' '}
                  />
                  <TextField
                    style={{ ...styles.commentInput, marginLeft: '2%' }}
                    sx={styles.textInputProps}
                    label="Unit Name"
                    value={unitName}
                    onChange={(data) => setUnitName(data.target.value)}
                    InputLabelProps={styles.textbox.InputLabelProps}
                    InputProps={{
                      style: { backgroundColor: colors.modal_background }, 
                      ...styles.textbox.InputProps 
                    }}
                    error={updateNameError || unitNameExistsError}
                    helperText={updateNameError ? 'Enter unit name' : ' '}
                  />
                </View>
                <View style={{width: '100%', alignItems: 'center'}}>
                  <TextField
                    style={styles.commentInput}
                    sx={styles.textInputProps}
                    label="Address Line 1"
                    value={addressLine1}
                    onChange={(data) => {
                      setAddressLine1(data.target.value.replace(/[^A-Za-z0-9.'#@$%&]\s/g, ''))
                      setAddressLine1Error(false)
                    }}
                    onBlur={() => {
                      setAddressLine1(addressLine1.trim())
                      setAddressLine1Error(false)
                    }}
                    error={addressLine1EmptyError}
                    helperText={addressLine1EmptyError ? 'Enter address' : ' '}
                    InputProps={{
                      style: { backgroundColor: colors.modal_background }, 
                      ...styles.textbox.InputProps 
                    }}
                  />

                  <TextField
                    style={styles.commentInput}
                    sx={styles.textInputProps}
                    label="Address Line 2"
                    onChange={(data) => setAddressLine2(data.target.value.replace(/[^A-Za-z0-9.'#@$%&]\s/g, ''))}
                    onBlur={() => setAddressLine2(addressLine2.trim())}
                    value={addressLine2}
                    InputProps={{
                      style: { backgroundColor: colors.modal_background }, 
                      ...styles.textbox.InputProps 
                    }}
                    helperText={' '}
                  />
                </View>
                
                <View style={{
                  flexDirection: 'row',
                  width: '95%',
                  justifyContent: 'space-around',
                }}>
                  <TextField
                  style={styles.textbox}
                  sx={styles.textInputProps}
                  label="City"
                  onChange={(data) => {
                    setCity(data.target.value.replace(/[^A-Za-z0-9.'#@$%&]\s/g, ''))
                    setCityEmptyError(false)
                  }}
                  onBlur={() => {
                    setCity(city.trim())
                    setCityEmptyError(false)
                  }}
                  value={city}
                  error={cityEmptyError}
                  InputProps={{
                    style: { backgroundColor: colors.modal_background }, 
                    ...styles.textbox.InputProps 
                  }}
                  helperText={cityEmptyError ? 'Enter a city' : ' '}
                />
                <Autocomplete
                  style={{...styles.textbox, marginLeft: '2%', marginRight: '2%', borderRadius:'5px' }}
                  sx={{...styles.textInputProps}}
                  options={states}
                  getOptionLabel={(option) => option.label}
                  onChange={(event, value) => {
                    setState(value ? value.value : ''); // Assuming `setState` updates the state value
                    setStateEmptyError(false);
                  }}
                  value={states.find((option) => option.value === state)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="State"
                      error={stateEmptyError}
                      style={{marginLeft: '2%', marginRight: '2%', backgroundColor: colors.modal_background, borderRadius:'5px', width: '100%'}}
                      helperText={stateEmptyError ? 'Enter a state' : ' '}
                    />
                  )}
                  renderOption={(props, option) => (
                    <MenuItem 
                      {...props}
                      style={{backgroundColor: colors.modal_background, color: colors.inputted_text}}
                    >
                      {option.label}
                    </MenuItem>
                  )}
                  ListboxProps={{
                    style: {
                      maxHeight: '200px',
                      overflowY: 'auto',
                    },
                  }}
                  ListboxComponent={List}
                  PaperComponent={({ children }) => (
                    <Paper style={{ background: colors.modal_background, color: colors.inputted_text, color: colors.inputted_text }}>
                      {children}
                    </Paper>
                  )}
                  noOptionsText={
                    <Typography style={{ color: colors.inputted_text }}>
                      No options
                    </Typography>
                  }
                />
                <TextField
                  style={styles.textbox}
                  sx={styles.textInputProps}
                  label="Zip Code"
                  onChange={(data) => {
                    setZipCode(data.target.value.replace(/[^0-9-]/g, '').slice(0, 5))
                    setZipCodeEmptyError(false)
                  }}
                  onBlur={() => {
                    setZipCode(zipCode.trim())
                    setZipCodeEmptyError(false)
                  }}
                  value={zipCode}
                  error={zipCodeEmptyError || zipCodeLengthError}
                  InputProps={{
                    style: { backgroundColor: colors.modal_background }, 
                    ...styles.textbox.InputProps 
                  }}
                  helperText={zipCodeEmptyError ? 'Enter a zip code' :
                    zipCodeLengthError ? 'Zip Code must be 5 digits' : ' '}
                />
                </View>
                <View style={{
                  flexDirection: 'row',
                  width: '100%',
                  justifyContent: 'center',
                  marginTop: '5%',
                }}>
                  <TouchableOpacity
                    style={{
                      width: '15%',
                      borderRadius: '40px',
                      alignItems: 'center',
                      backgroundColor: colors.button,
                      shadowColor: '#171717',
                      shadowOffset: { width: -2, height: 4 },
                      shadowOpacity: 0.2,
                      shadowRadius: 3,
                      marginBottom: '5%',
                      marginRight: '5%'
                    }}
                    onPress={() => cancelUnitUpdate()}>
                    <Text style={styles.innerText}>Cancel</Text>
                  </TouchableOpacity>

                  <TouchableOpacity
                    style={{
                      width: '15%',
                      borderRadius: '40px',
                      alignItems: 'center',
                      backgroundColor: colors.button,
                      shadowColor: '#171717',
                      shadowOffset: { width: -2, height: 4 },
                      shadowOpacity: 0.2,
                      shadowRadius: 3,
                      marginBottom: '5%',
                      marginLeft: '5%'
                    }}
                    onPress={() => updateUnit()}>
                    <Text style={styles.innerText}>Submit</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </div>
          </Modal>
          {/* End of Update Role Modal */}


          <Scrollbars style={styles.table.tableContainer}>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                columns={columns}
                data={rows}
                enableRowSelection
                positionToolbarAlertBanner={'bottom'}
                tableInstanceRef={tableInstanceRef}
                enableGrouping
                enableColumnDragging={false}
                enableStickyHeader
                enableExpandAll={false}
                selectAllMode={'all'}
                renderDetailPanel={({ row }) => {
                  if (row.getIsExpanded()) {
                    if (!expandedUnitData[row.original.unit_id]) {
                      // Fetch user data asynchronously if not already fetched
                      fetchUnitData(row.original.unit_id).then((unitData) => {
                        // Cache expanded unit data
                        setExpandedUnitData((prevData) => ({
                          ...prevData,
                          [row.original.unit_id]: unitData,
                        }));
                      });
                      // Fetch the associated name based on the unit_id
                      if (!expandedAssociatedNameData[row.original.superior_unit_id]) {
                        fetchAssociatedName(row.original.superior_unit_id).then((name) => {
                          setExpandedAssociatedNameData((prevData) => ({
                            ...prevData,
                            [row.original.superior_unit_id]: name,
                          }));
                        });
                      }
                    }
                    // Render unit data when available, or a loading message
                    const unitData = expandedUnitData[row.original.unit_id];
                    const associatedName = expandedAssociatedNameData[row.original.superior_unit_id];
                    return (
                      <View>
                        {unitData && associatedName ? (
                          <>
                            <Box
                              sx={{
                                display: 'grid',
                                margin: 'auto',
                                gridTemplateColumns: '1fr 1fr',
                                width: '100%',
                              }}
                            >
                              <SectionHeader>Basic Information</SectionHeader>
                              <br />
                              <Field>UIC: {unitData.uic}</Field>
                              <Field>Unit Name: {unitData.unit_name}</Field>
                              <BottomField>Unit Role: {unitData.unit_role}</BottomField>
                              <BottomField></BottomField>

                              <SectionHeader>Unit Hierarchy</SectionHeader>
                              <br />
                              <Field>Superior Unit: {associatedName}</Field>
                              <Field>Battalion Name: {unitData.battalion_name}</Field>
                              <Field>Brigade Name: {unitData.brigade_name}</Field>
                              <BottomField>Command Name: {unitData.command_name}</BottomField>

                              <SectionHeader>Address</SectionHeader>
                              <br />
                              <Field>Address Line 1: {unitData.unit_address_line_1}</Field>
                              <Field>Address Line 2: {unitData.unit_address_line_2}</Field>
                              <Field>State: {unitData.unit_state}</Field>
                              <Field>City: {unitData.unit_city}</Field>
                              <Field>Zip Code: {unitData.unit_zipcode}</Field>
                              <Field>1380 to: {unitData.unit_1380_to}</Field>
                              <BottomField>1380 from: {unitData.unit_1380_from}</BottomField>
                              <BottomField></BottomField>
                            </Box>
                          </>
                        ) : (
                          <Typography variant="h5">Loading...</Typography>
                        )}
                      </View>
                    );
                  }
                }}
                initialState={{
                  showGlobalFilter: false,
                  columnOrder: ['mrt-row-expand', 'unit_name', 'edit', 'del'],
                }}
                muiTableBodyProps={{
                  sx: (theme) => ({
                    '& tr:nth-of-type(4n)': {
                      backgroundColor: colors.table_background_color,
                    },
                    '& tr:nth-of-type(4n - 2), & tr:nth-of-type(4n - 3)': {
                      backgroundColor: darken(theme.palette.background.default, 0.075),
                    },
                    '& tr.Mui-selected': {
                      backgroundColor: colors.table_selected,
                    },
                    '& tr:hover:not(.Mui-selected) > td': {
                      backgroundColor: colors.table_hover,
                    },
                    ...{
                      fontSize: '24px', // You can adjust the size as needed
                    },
                  }),
                }}

                // Table State Management for updater function
                onColumnVisibilityChange={(updater) => {
                  setColumnVisibility((prev) =>
                    updater instanceof Function ? updater(prev) : updater,
                  );
                  queueMicrotask(rerender); // Rerender after state update
                }}


                onRowSelectionChange={(updater) => {
                  setRowSelection((prev) =>
                    updater instanceof Function ? updater(prev) : updater,
                  );
                  queueMicrotask(rerender); // Rerender after state update
                }}

                onPaginationChange={(updater) => {
                  setPagination((prev) =>
                    updater instanceof Function ? updater(prev) : updater,
                  );
                  queueMicrotask(rerender); // Rerender after state update
                }}

                onShowColumnFiltersChange={(updater) => {
                  setShowColumnFilters((prev) =>
                    updater instanceof Function ? updater(prev) : updater,
                  );
                  queueMicrotask(rerender); // Rerender after state update
                }}

                onShowGlobalFilterChange={(updater) => {
                  setShowGlobalFilter((prev) =>
                    updater instanceof Function ? updater(prev) : updater,
                  );
                  queueMicrotask(rerender); // Rerender after state update
                }}

                state={{
                  columnVisibility,
                  density,
                  rowSelection,
                  pagination,
                  showColumnFilters,
                  showGlobalFilter,
                }}
                renderToolbarInternalActions={({ table }) => (
                  <>
                    <Box>
                      <MRT_ToggleGlobalFilterButton table={tableInstanceRef.current} />
                      <MRT_FullScreenToggleButton table={tableInstanceRef.current} />
                      {showAddRow ? (
                        <Tooltip arrow title="Add Unit">
                          <IconButton onClick={() => {
                            //setShowAddRow(false);
                            navigation.navigate('admin_dashboard', { screen: 'add_unit', params: { setIsSubmitted: setIsSubmitted } },);
                          }
                          }
                          >
                            <AddIcon />
                          </IconButton>
                        </Tooltip>
                      ) : null}
                    </Box>
                  </>
                )}
              />
            </ThemeProvider>
          </Scrollbars>
        </View>
        )}
      </View>
    </Scrollbars>
  );
};

export default View_units;
