import { StyleSheet } from 'react-native';

const commonStyles = {
  container: {
    padding: 12,
    marginBottom: 15,
    marginTop: 15,
    fontFamily: 'Trebuchet MS',
    fontSize: '14px',
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
  },
};

const lightColors = {
  background: '#FFFFFF',
  text: '#000000',
  accent: '#2196F3',
  buttonBackground: '#4CAF50',
  buttonDeleteBackground: '#FF0000',
};

const darkColors = {
  background: '#201c1c',
  text: '#FFFFFF',
  accent: '#64B5F6',
  buttonBackground: '#81C784',
  buttonDeleteBackground: '#E57373',
};

export const lightModeStyles = StyleSheet.create({
  container: {
    padding: 10,
    marginBottom: 10,
    marginTop: 10,
    fontFamily: 'Trebuchet MS',
    fontSize: '16px',
  },
  text: {
    color: lightColors.text,
    fontFamily: 'Trebuchet MS',
    fontSize: '16px',
  },
  createButton: {
    marginTop: 10,
    backgroundColor: lightColors.accent,
    padding: 10,
    borderRadius: 5,
  },
  editButton: {
    backgroundColor: lightColors.buttonBackground,
    padding: 10,
    borderRadius: 5,
    flex: 1,
    marginRight: 5,
    borderColor: '#b3b7ba',
    borderStyle: 'solid',
    borderWidth: 2,
  },
  deleteButton: {
    backgroundColor: lightColors.buttonDeleteBackground,
    padding: 10,
    borderRadius: 5,
    flex: 1,
    marginLeft: 5,
    borderColor: '#b3b7ba',
    borderWidth: 2,
    borderStyle: 'solid',
  },
});

export const darkModeStyles = StyleSheet.create({
  container: {
    padding: 10,
    marginBottom: 10,
    marginTop: 10,
    fontFamily: 'Trebuchet MS',
    fontSize: '16px',
  },
  text: {
    color: darkColors.text,
    fontFamily: 'Trebuchet MS',
    fontSize: '16px',
  },
  createButton: {
    marginTop: 10,
    backgroundColor: darkColors.accent,
    padding: 10,
    borderRadius: 5,
  },
  editButton: {
    backgroundColor: darkColors.buttonBackground,
    padding: 10,
    borderRadius: 5,
    flex: 1,
    marginRight: 5,
    borderColor: '#b3b7ba',
    borderWidth: 2,
    borderStyle: 'solid',
  },
  deleteButton: {
    backgroundColor: darkColors.buttonDeleteBackground,
    padding: 10,
    borderRadius: 5,
    flex: 1,
    marginLeft: 5,
    borderColor: '#b3b7ba',
    borderWidth: 2,
    borderStyle: 'solid',
  },

  
});

export const budgetOverviewStyles = StyleSheet.create({
  ...commonStyles,
  editButton: {
    backgroundColor: 'rgb(0, 48, 135)',
    padding: 5,
    borderRadius: 5,
    borderColor: '#b3b7ba',
    borderStyle: 'solid',
    borderWidth: 2,
    flex: 1,
    marginRight: 10,
  },
  deleteButton: {
    backgroundColor: '#FF0000',
    padding: 5,
    borderRadius: 5,
    flex: 1,
    marginLeft: 'auto',
    borderColor: '#b3b7ba',
    borderStyle: 'solid',
    borderWidth: 2,
    marginLeft: 10,
  },
  createButton: {
    marginTop: 10,
    padding: 10,
    borderRadius: 5,
    backgroundColor: 'rgb(0, 48, 135)',
    borderColor: '#b3b7ba',
    borderStyle: 'solid',
    borderWidth: 2,
    alignItems: 'center',
    color: 'white',
  },

  alertButton: {
    backgroundColor: 'rgb(0, 48, 135)',
    padding: 5,
    borderRadius: 5,
    borderColor: '#b3b7ba',
    borderStyle: 'solid',
    borderWidth: 2,
    flex: 1,
  },
});

export const modalStyles = StyleSheet.create({
  container: {
    padding: 10,
  },
  input: {
    height: 40,
    borderColor: '#908c8c',
    borderWidth: 2,
    marginBottom: 10,
    paddingHorizontal: 10,
    borderRadius: 5,
    fontSize: '14px',
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  button: {
    padding: 10,
    borderRadius: 5,
    flex: 1,
    marginHorizontal: 5,
  },
});