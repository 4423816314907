/* eslint-disable require-jsdoc */
import React, { useEffect, useState } from 'react';
import { View, Text, TouchableOpacity, ScrollView } from 'react-native';

import { useScale } from '../../../components/scale';
import { storeJsonData } from '../../../api_handler/asyncstorage';

import { useIsFocused } from '@react-navigation/native';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import * as Progress from 'react-native-progress';
import { add_unit, get_all_unit_names, get_unique_battalions, get_unique_brigades, get_unique_commands } from '../../../api_handler/units';

import ModeContext from '../../../components/ModeContext';
import SnackbarAlert from '../../../components/SnackbarAlert';
import { useColorContext } from '../../../components/ColorContext';
import NavSideBar from '../../../components/NavSideBar';
import { useRoute } from '@react-navigation/native';
import { useSnackbar } from '../../../components/SnackbarContext';

function AddUnit({ navigation }) {
  const { showSnackbar } = useSnackbar();
  const [UIC, setUIC] = React.useState('');
  const [unitRole, setunitRole] = React.useState('');
  const [unitName, setunitName] = React.useState('');

  const [battalion, setBattalion] = React.useState('');
  const [otherBattalion, setOtherBattalion] = React.useState('');
  const [brigade, setBrigade] = React.useState('');
  const [otherBrigade, setOtherBrigade] = React.useState('');
  const [command, setCommand] = React.useState('');
  const [otherCommand, setOtherCommand] = React.useState('');

  const [unit, setUnit] = React.useState(global.user_data.unit_id);
  const [superiorUnit, setSuperiorUnit] = React.useState(global.user_data.unit_id);

  const [addressLine1, setAddressLine1] = React.useState('');
  const [addressLine2, setAddressLine2] = React.useState('');
  const [state, setState] = React.useState('');
  const [city, setCity] = React.useState('');
  const [zipCode, setZipCode] = React.useState('');
  const [selectedUnits, setSelectedUnits] = React.useState([]);
  const [fetchError, setFetchError] = useState(false);
  const [battalions, setBattalions] = React.useState([]);
  const [brigades, setBrigades] = React.useState([]);
  const [commands, setCommands] = React.useState([]);
  const [unitNames, setUnitNames] = React.useState([]);
  const [filteredUnitNames, setFilteredUnitNames] = React.useState([]);

  const [UICEmptyError, setUICEmptyError] = useState(false);
  const [unitRoleEmptyError, setunitRoleEmptyError] = useState(false);
  const [unitNameEmptyError, setunitNameEmptyError] = useState(false);
  const [battalionEmptyError, setBattalionEmptyError] = useState(false);
  const [otherBattalionEmptyError, setOtherBattalionEmptyError] = useState(false);
  const [brigadeEmptyError, setBrigadeEmptyError] = useState(false);
  const [otherBrigadeEmptyError, setOtherBrigadeEmptyError] = useState(false);
  const [commandEmptyError, setCommandEmptyError] = useState(false);
  const [otherCommandEmptyError, setOtherCommandEmptyError] = useState(false);
  const [unitNameExistsError, setUnitNameExistsError] = useState(false);
  const [UICExistsError, setUICExistsError] = useState(false);

  const [addressLine1EmptyError, setAddressLine1Error] = useState(false);
  const [stateEmptyError, setStateEmptyError] = useState(false);
  const [cityEmptyError, setCityEmptyError] = useState(false);
  const [zipCodeEmptyError, setZipCodeEmptyError] = useState(false);
  const [zipCodeLengthError, setZipCodeLengthError] = useState(false);

  const { mode } = React.useContext(ModeContext);
  const isFocused = useIsFocused();
  const [openAlert, setOpenAlert] = useState(false);
  const { colors } = useColorContext();
  const route = useRoute();
  const { setIsSubmitted } = route.params;
  const [loaded, setLoaded] = useState(true);

  // import drop down menu options
  const states = require('../users/json_files/States.json');

  useEffect(() => {
    fetchBattalions();
    fetchBrigades();
    fetchCommands();
    fetchUnitNames();
  }, []);

  // Reset all values when user navigates to another page
  useEffect(() => {
    setUIC('');
    setunitRole('');
    setunitName('');

    setBattalion('');
    setOtherBattalion('');
    setBrigade('');
    setOtherBrigade('');
    setCommand('');
    setOtherCommand('');

    setSuperiorUnit(global.user_data.unit_id);

    setAddressLine1('');
    setAddressLine2('');
    setState('');
    setCity('');
    setZipCode('');

    setUICEmptyError(false);
    setunitRoleEmptyError(false);
    setunitNameEmptyError(false);
    setBattalionEmptyError(false);
    setOtherBattalionEmptyError(false);
    setBrigadeEmptyError(false);
    setOtherBrigadeEmptyError(false);
    setCommandEmptyError(false);
    setOtherCommandEmptyError(false);

    setAddressLine1Error(false);
    setStateEmptyError(false);
    setCityEmptyError(false);
    setZipCodeEmptyError(false);
    setZipCodeLengthError(false);
  }, [isFocused]);

  const scale = useScale();
  const styles = ({
    container: {
      flex: 1,
      height: '100%',
      backgroundColor: colors.screen_background,
    },
    wrapper: {
      flexDirection: 'row',
      height: '100%'
    },
    sectionDiv: {
      marginTop: '1.5%',
      // marginBottom: "1%",
      alignItems: 'flex-start',
      left: '6%',
      width: '88%',
      overflow: 'hidden',
    },
    sectionContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-evenly',
      width: '100%',
    },
    horizontalLine: {
      top: '10px',
      width: '100%',
      height: '2px',
      backgroundColor: '#d6dde4',
      position: 'absolute',
    },
    sectionTitle: {
      fontSize: 20,
      fontWeight: 'bold',
      color: colors.inputted_text,
    },
    container: {
      // flex: 1,
      width: '100%',
      backgroundColor: colors.screen_background,
      // height: '100vh',
      // minHeight: '100vh',
    },
    scrollView: {
      backgroundColor: colors.screen_background,
      padding: "3%",
      flex: 1,
      height: '100vh',
      minHeight: '100vh',

    },
    scrollViewContainer: {
      justifyContent: 'center',
      alignItems: 'center'
    },
    titleDiv: {
      marginTop: '0%',
      alignItems: 'center',
    },
    title: {
      fontSize: scale(25),
      fontFamily: 'Trebuchet MS',
      marginTop: '0.5%',
      // paddingBottom: '2%',
      color: colors.text,
    },
    header: {
      backgroundColor: 'pink',
      height: '15%',
    },
    main: {
      // flex: 1,
      display: 'flex',
      flexDirection: 'column',
      marginTop: '0%',
      height: '56%',
      width: '100%',
      justifyContent: 'center',
    },
    compartment: {
      flexDirection: 'column',
      // flex: 1,
      height: '100%',
      alignItems: 'center',
    },
    textandinput: {
      // flex: 1,
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      justifyContent: 'space-evenly',
      width: '100%',
    },
    textbox: {
      margin: 12,
      width: '25%',
      // paddingBottom: '1%',
      backgroundColor: colors.textfield_background,
    },
    checkBox: {
      height: '24px',
      color: colors.inputted_text,
    },
    listItemTextTypography: {
      fontSize: '1rem',
    },
    dateBox: {
      'margin': 1.5,
      'width': '25%',
      'backgroundColor': colors.textfield_background,
      '& .MuiFormLabel-root, & label.Mui-focused, & .MuiInputBase-input': {
        color: colors.inputted_text,
      },
      '.MuiSvgIcon-root ': {
        fill: colors.inputted_text,
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: colors.field_set,
        },
        '&:hover fieldset': {
          borderColor: colors.form_border,
        },
        '&.Mui-focused fieldset': {
          borderColor: '#1876d2',
        },
      },
      '& .MuiFormLabel-root.Mui-error': {
        color: colors.placeholder_text,
      },
      '& .MuiFormHelperText-root.Mui-error': {
        backgroundColor: colors.screen_background,
        color: '#d32f2f',
        margin: 0,
        paddingLeft: 1,
      },
    },
    dateBoxEnd: {
      'width': '12vw',
      '& .MuiOutlinedInput-root': {
        '& fieldset, &:hover fieldset, &.Mui-focused fieldset': {
          borderColor: 'transparent',
        },
        '& input': {
          textAlign: 'right',
        },
      },
      '& .MuiInputLabel-shrink, & .MuiInputLabel-root.Mui-focused': {
        color: 'transparent',
      },
      '& .MuiFormLabel-root': {
        textAlign: 'right',
        right: '60px',
      },
    },
    innerText: {
      color: colors.button_text,
      fontWeight: 'bold',
      padding: 10,
      fontSize: 18,
    },
    button: {
      backgroundColor: colors.button,
      width: 180,
      height: 50,
      alignItems: 'center',
      borderRadius: 15,
      shadowColor: '#171717',
      shadowOffset: { width: -2, height: 4 },
      shadowOpacity: 0.2,
      shadowRadius: 3,
      // fontSize: 180,
      // marginTop: 30,
      // marginBottom: 30,
    },
    buttonDiv: {
      paddingTop: '5%',
      paddingBottom: '10%',
      // top: '10%',
      flexDirection: 'row',
      // flexDirection: 'column',
      // flex: 1,
      height: '10%',
      width: '100%',
      // alignItems : 'center',
      // justifyContent: 'space-around',
      // justifyContent: 'flex-end'
    },
    textInputProps: {
      '& .MuiFormLabel-root, & label.Mui-focused, & .MuiInputBase-input': {
        color: colors.inputted_text,
        // WebkitTextFillColor:  colors.inputted_text ,
      },
      '.MuiSvgIcon-root': {
        fill: colors.inputted_text,
      },
      '& .MuiOutlinedInput-root': {
        // '& fieldset': {
        //     borderColor:  '#c4c4c4' : 'transparent',
        // },
        '&:hover fieldset': {
          borderColor: colors.form_border,
        },
        '&.Mui-focused fieldset': {
          borderColor: '#1876d2',
        },
      },
      '&  .MuiFormHelperText-root': {
        backgroundColor: colors.screen_background,
        margin: 0,
        paddingLeft: 1,
      },
      // .MuiFormHelperText-root.Mui-error
    },
    textInputPropsDisabled: {
      '& .MuiFormLabel-root.Mui-disabled, & label.Mui-focused.Mui-disabled, & .MuiInputBase-input.Mui-disabled': {
        color: colors.inputted_text,
        WebkitTextFillColor: colors.inputted_text,
      },
      '& .MuiFormHelperText-root.Mui-disabled': {
        backgroundColor: colors.screen_background,
        color: colors.placeholder_text,
        margin: 0,
        paddingLeft: 1,
      },
    },
    selectProps: {
      MenuProps: {
        sx: {
          '& .MuiMenu-paper': {
            background: colors.textfield_background,
            color: colors.inputted_text,
          },
        },
      },
    },
  });

  // Opens Error Message Alert
  // const handleOpenAlert = () => {
    
  //   console.log("uic: " + UICExistsError + ". and name: " + unitNameExistsError);
  //   if(UICExistsError && unitNameExistsError){
  //     showSnackbar('error', 'UIC and name already exist')
  //   } else if (UICExistsError){
  //     showSnackbar('error', 'UIC already exists');
  //   } else if(unitNameExistsError){
  //     showSnackbar('error', 'Unit name already exists');
  //   }
  // };

  // Closes Error Message Alert
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  // Gather existing unique battalions from the database and assign them to state
  async function fetchBattalions() {
    try {
      const temp = await get_unique_battalions({}, global.token);
      if (temp.status === 200) {
        setBattalions(temp.data.body);
      } else {
        setFetchError(true);
        showSnackbar("error", "Application Error: Failed to fetch existing Battalion Names")
      }
    } catch (error) {
      console.error(error);
    }
  }

  // Gather existing unique brigades from the database and assign them to state
  async function fetchBrigades() {
    try {
      const temp = await get_unique_brigades({}, global.token);
      if (temp.status === 200) {
        setBrigades(temp.data.body);
      } else {
        setFetchError(true);
        showSnackbar("error", "Application Error: Failed to fetch existing Brigade Names")
      }
    } catch (error) {
      console.error(error);
    }
  }

  // Gather existing unique commands from the database and assign them to state
  async function fetchCommands() {
    try {
      const temp = await get_unique_commands({}, global.token);
      if (temp.status === 200) {
        setCommands(temp.data.body);
      } else {
        setFetchError(true);
        showSnackbar("error", "Application Error: Failed to fetch existing Command Names")
      }
    } catch (error) {
      console.error(error);
    }
  }

  const fetchUnitNames = async () => {
    try {
      const unit_names_JSON = {
        'unit_id': 0,
        'get_top_unit': true,
        'get_path': true,
      };

      // Retrieve all units and eliminate the ones the user does not have access to
      const unit_names_res = await get_all_unit_names(unit_names_JSON, global.token);
      if (unit_names_res.status !== 200) {
        showSnackbar("error", "Application Error: Failed to retrieve all units")
        navigation.goBack();
      } else {
        const unit_ids_set = new Set(global.user_data.admin_unit_ids);
        const filteredUnitNames = unit_names_res.data.body.filter((unit) => unit_ids_set.has(unit.unit_id));
        setFilteredUnitNames(filteredUnitNames);
        setUnitNames(unit_names_res.data.body);
        setUnit(global.addUserSelectedUnit || unit_names_res.data.body[0].unit_id);
      }
    } catch (error) {
      console.error('Error: ', error);
    }
  };

  // Checks invalid or empty user input
  const checkInput = () => {
    let error = false;

    // Check empty UIC name
    if (UIC === '') {
      error = true;
      setUICEmptyError(true);
    } else {
      setUICEmptyError(false);
    }

    // Check empty unit name
    if (unitName === '') {
      error = true;
      setunitNameEmptyError(true);
    } else {
      setunitNameEmptyError(false);
    }

    // Check empty unit role
    if (unitRole === '') {
      error = true;
      setunitRoleEmptyError(true);
    } else {
      setunitRoleEmptyError(false);
    }

    // Check empty battalion name
    if (battalion === '') {
      error = true;
      setBattalionEmptyError(true);
    } else {
      setBattalionEmptyError(false);
    }

    // Check empty other battalion name
    if (battalion === 'Other' && otherBattalion === '') {
      error = true;
      setOtherBattalionEmptyError(true);
    } else {
      setOtherBattalionEmptyError(false);
    }

    // Check empty brigade name
    if (brigade === '') {
      error = true;
      setBrigadeEmptyError(true);
    } else {
      setBrigadeEmptyError(false);
    }

    // Check empty other brigade name
    if (brigade === 'Other' && otherBrigade === '') {
      error = true;
      setOtherBrigadeEmptyError(true);
    } else {
      setOtherBrigadeEmptyError(false);
    }

    // Check empty command name
    if (command === '') {
      error = true;
      setCommandEmptyError(true);
    } else {
      setCommandEmptyError(false);
    }

    // Check empty other command name
    if (command === 'Other' && otherCommand === '') {
      error = true;
      setOtherCommandEmptyError(true);
    } else {
      setOtherCommandEmptyError(false);
    }

    // Check if address is not full
    const isEmpty = (value) => value === '';

    if ((addressLine1 || state || city || zipCode) &&
      (isEmpty(addressLine1) || isEmpty(state) || isEmpty(city) || isEmpty(zipCode))) {
      error = true;
      if (isEmpty(addressLine1)) {
        setAddressLine1Error(true);
      } else {
        setAddressLine1Error(false);
      }

      if (isEmpty(state)) {
        setStateEmptyError(true);
      } else {
        setStateEmptyError(false);
      }

      if (isEmpty(city)) {
        setCityEmptyError(true);
      } else {
        setCityEmptyError(false);
      }

      if (isEmpty(zipCode)) {
        setZipCodeEmptyError(true);
      } else {
        setZipCodeEmptyError(false);
      }
    } else {
      setAddressLine1Error(false);
      setStateEmptyError(false);
      setCityEmptyError(false);
      setZipCodeEmptyError(false);
    }

    // Check zip code is of full length (5 digits)
    if (zipCode !== '' && zipCode.length !== 5) {
      error = true;
      setZipCodeLengthError(true);
    } else {
      setZipCodeLengthError(false);
    }

    if(error === true){
      showSnackbar('error', 'Please correct one or more invalid inputs')
    }

    return error;
  };

  // Function is useless we don't look for DOD or email was copied over from add user (leaving it until unit endpoints are fixed)
  const checkOutput = (response) => {
    let error = false;
    setUICExistsError(false);
    setUnitNameExistsError(false);
    console.log(response)
    if (response.data.body.includes('UIC and name already exist')) {
      error = true;
      setUICExistsError(true);
      setUnitNameExistsError(true);
      showSnackbar('error', 'UIC and name already exist');
    } else if (response.data.body.includes('UIC already exists')) {
      error = true;
      setUICExistsError(true);
      showSnackbar('error', 'UIC already exists');
    } else if (response.data.body.includes('Unit name already exists')) {
      error = true;
      setUnitNameExistsError(true);
      showSnackbar('error', 'Unit name already exists');
    } else if (response.status != 200){
      error = true;
      setUICExistsError(false);
      setUnitNameExistsError(false);
      showSnackbar('error', 'Application Error: Failed to add unit');
    }
    return error;
  };

  // User clicks add user
  const AddUnit = async () => {
    setLoaded(false);
    // Check for input errors
    if (checkInput()) {
      showSnackbar("error", "Please correct one or more invalid inputs");
    }

    // Populate new user variable with info entered in states
    else {
      const newUnit = {
        admin_id: global.user_data.user_id,
        UIC: UIC,
        unit_name: unitName,
        unit_role: unitRole,
        superior_unit_id: unit,
        is_hhc: unitName.includes('HHC') ? true : false,
        battalion_name: battalion === 'Other' ? otherBattalion : battalion,
        brigade_name: brigade === 'Other' ? otherBrigade : brigade,
        command_name: command === 'Other' ? otherCommand : command,
        has_brigade: true,
        unit_address_line_1: addressLine1,
        unit_address_line_2: addressLine2,
        unit_city: city,
        unit_state: state,
        unit_zipcode: zipCode,
      };

      // Call add_unit enpdoint
      const response = await add_unit(newUnit, global.token);
      // Check for response errors
      if (checkOutput(response)) {
        console.log("error adding unit");
        setLoaded(true);
      } else {
        // Update the global admin_unit_ids so front end is up to date with new unit
        let match = response.data.body.match(/\d+/);
        let extracted_unit_id = match ? parseInt(match[0]) : null;

        global.user_data.admin_unit_ids = global.user_data.admin_unit_ids.concat(extracted_unit_id);
        storeJsonData('user_data', global.user_data);

        //want to update the changes made, so we send a notification to the units page to actually update.
        // setIsSubmitted(true);
        navigation.navigate('admin_dashboard', { screen: "view_units" });
        showSnackbar('success', 'Unit successfully added');
        setLoaded(true);
        location.reload();
      }
    }
  };

  const loadingStyles = {
    animation: {
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
        justifyContent: 'center',
        paddingVertical: 20,
        background: colors.screen_background,
        zIndex: 100,
    }
  }

  if (!loaded) {
    return (
      <View style={loadingStyles.animation}>
          <Progress.CircleSnail
            color={colors.loading_circle}
            indeterminate={true}
            size={275}
            thickness={8}
          />
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <View style={styles.wrapper}>
        {/* <NavSideBar /> */}

        <ScrollView style={styles.scrollView} contentContainerStyle={styles.scrollViewContainer}>
          <View style={styles.container}>
            <View style={styles.titleDiv}>
              <Text style={styles.title}>Add Unit</Text>
            </View>


            <View style={styles.main}>
              <View style={styles.compartment}>
                <View style={styles.textandinput}>

                  <View style={styles.sectionDiv}>
                    <Text style={styles.sectionTitle}>Basic Information</Text>
                    <View style={[styles.horizontalLine, { left: '210px' }]} />
                  </View>

                  <View style={styles.sectionContainer}>
                    <TextField
                      style={styles.textbox}
                      sx={styles.textInputProps}
                      required
                      label="UIC"
                      onChange={(data) => {
                        setUIC(data.target.value)
                        setUICEmptyError(false)
                      }}
                      onBlur={() => {
                        setUIC(UIC.trim().toUpperCase())
                        setUICEmptyError(false)
                      }}
                      value={UIC}
                      error={UICEmptyError || UICExistsError}
                      helperText={UICEmptyError ? 'Enter a UIC' : ' '}
                    />

                    <TextField
                      style={styles.textbox}
                      sx={styles.textInputProps}
                      required
                      label="Unit Name"
                      onChange={(data) => {
                        setunitName(data.target.value)
                        setunitNameEmptyError(false)
                      }}
                      onBlur={() => {
                        setunitName(unitName.trim())
                        setunitNameEmptyError(false)
                      }}
                      value={unitName}
                      error={unitNameEmptyError || unitNameExistsError}
                      helperText={unitNameEmptyError ? 'Enter a unit name' : ' '}
                    />

                    <TextField
                      style={styles.textbox}
                      sx={styles.textInputProps}
                      required
                      label="Unit Role"
                      onChange={(data) => {
                        setunitRole(data.target.value)
                        setunitRoleEmptyError(false)
                      }}
                      onBlur={() => {
                        setunitRole(unitRole.trim())
                        setunitRoleEmptyError(false)
                      }}
                      value={unitRole}
                      error={unitRoleEmptyError}
                      helperText={unitRoleEmptyError ? 'Enter a unit role' : ' '}
                    />

                    {(filteredUnitNames !== undefined && (filteredUnitNames.length > 0 && unitNames.length > 0)) ? (
                      <>
                        <FormControl style={styles.textbox} sx={styles.textInputProps}>
                          <InputLabel id="superior-unit-label">Superior Unit</InputLabel>
                          <Select
                            inputProps={styles.selectProps}
                            labelId="superior-unit-label"
                            select="true"
                            value={unit}
                            required
                            onChange={(data) => setUnit(data.target.value)}
                            input={<OutlinedInput label="Superior Unit" />}
                            renderValue={(selected) => {
                              const selectedName = (filteredUnitNames.find((item) => item.unit_id === selected)).unit_name;
                              const nameComponents = selectedName.split(' -> ');
                              const lastComponent = nameComponents[nameComponents.length - 1];
                              return (
                                <div>{lastComponent}</div>
                              );
                            }}
                          >
                            {filteredUnitNames.map((option) => (
                              <MenuItem style={{ whiteSpace: 'pre' }} key={option.unit_id} value={option.unit_id}>{option.unit_name}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </>
                    ) :
                      <>
                        <TextField
                          style={styles.textbox}
                          sx={styles.textInputProps}
                          label="Superior Unit"
                        >
                        </TextField>
                      </>
                    }
                    <TextField
                      style={{ visibility: 'hidden', ...styles.textbox }}
                      value={''}
                    />
                    <TextField
                      style={{ visibility: 'hidden', ...styles.textbox }}
                      value={''}
                    />
                  </View>

                  <View style={styles.sectionDiv}>
                    <Text style={styles.sectionTitle}>Unit Hierarchy</Text>
                    <View style={[styles.horizontalLine, { left: '200px' }]} />
                  </View>


                  <View style={styles.sectionContainer}>

                    {battalions.length > 0 ? (
                      <TextField
                        style={styles.textbox}
                        sx={styles.textInputProps}
                        SelectProps={styles.selectProps}
                        select
                        required
                        label="Battalion Name"
                        onChange={(data) => {
                          setBattalion(data.target.value)
                          setBattalionEmptyError(false)
                        }}
                        value={battalion}
                        error={battalionEmptyError}
                        helperText={battalionEmptyError ? 'Select a Battalion' : ' '}
                      >
                        {battalions.map((option) => (
                          <MenuItem key={option.battalion_name} value={option.battalion_name}>{option.battalion_name}</MenuItem>
                        ))}
                      </TextField>
                    ) :
                      <TextField
                        style={styles.textbox}
                        sx={styles.textInputProps}
                        label="Battalion Name"
                      >
                      </TextField>
                    }

                    {brigades.length > 0 ? (
                      <TextField
                        style={styles.textbox}
                        sx={styles.textInputProps}
                        SelectProps={styles.selectProps}
                        select
                        required
                        label="Brigade Name"
                        onChange={(data) => {
                          setBrigade(data.target.value)
                          setBrigadeEmptyError(false)
                        }}
                        value={brigade}
                        error={brigadeEmptyError}
                        helperText={brigadeEmptyError ? 'Select a Brigade' : ' '}
                      >
                        {brigades.map((option) => (
                          <MenuItem key={option.brigade_name} value={option.brigade_name}>{option.brigade_name}</MenuItem>
                        ))}
                      </TextField>
                    ) :
                      <TextField
                        style={styles.textbox}
                        sx={styles.textInputProps}
                        label="Brigade Name"
                      >
                      </TextField>
                    }

                    {commands.length > 0 ? (
                      <TextField
                        style={styles.textbox}
                        sx={styles.textInputProps}
                        SelectProps={styles.selectProps}
                        select
                        required
                        label="Command Name"
                        onChange={(data) => {
                          setCommand(data.target.value)
                          setCommandEmptyError(false)
                        }}
                        value={command}
                        error={commandEmptyError}
                        helperText={commandEmptyError ? 'Select a Command' : ' '}
                      >
                        {commands.map((option) => (
                          <MenuItem key={option.command_name} value={option.command_name}>{option.command_name}</MenuItem>
                        ))}
                      </TextField>
                    ) :
                      <TextField
                        style={styles.textbox}
                        sx={styles.textInputProps}
                        label="Command Name"
                      >
                      </TextField>
                    }
                    <TextField
                      style={{ visibility: battalion === 'Other' ? 'visible' : 'hidden', ...styles.textbox }}
                      sx={styles.textInputProps}
                      label="Enter Battalion Name Here"
                      required
                      onChange={(data) => setOtherBattalion(data.target.value)}
                      onBlur={() => setOtherBattalion(otherBattalion.trim())}
                      value={otherBattalion}
                      error={otherBattalionEmptyError}
                      helperText={otherBattalionEmptyError ? 'Enter a Battalion name' : ' '}
                    />
                    <TextField
                      style={{ visibility: brigade === 'Other' ? 'visible' : 'hidden', ...styles.textbox }}
                      sx={styles.textInputProps}
                      label="Enter Brigade Name Here"
                      required
                      onChange={(data) => setOtherBrigade(data.target.value)}
                      onBlur={() => setOtherBrigade(otherBrigade.trim())}
                      value={otherBrigade}
                      error={otherBrigadeEmptyError}
                      helperText={otherBrigadeEmptyError ? 'Enter a Brigade name' : ' '}
                    />
                    <TextField
                      style={{ visibility: command === 'Other' ? 'visible' : 'hidden', ...styles.textbox }}
                      sx={styles.textInputProps}
                      label="Enter Command Name Here"
                      required
                      onChange={(data) => setOtherCommand(data.target.value)}
                      onBlur={() => setOtherCommand(otherCommand.trim())}
                      value={otherCommand}
                      error={otherCommandEmptyError}
                      helperText={otherCommandEmptyError ? 'Enter a Command name' : ' '}
                    />
                  </View>

                  <View style={styles.sectionDiv}>
                    <Text style={styles.sectionTitle}>Address</Text>
                    <View style={[styles.horizontalLine, { left: '190px' }]} />
                  </View>

                  <View style={styles.sectionContainer}>
                    <TextField
                      style={styles.textbox}
                      sx={styles.textInputProps}
                      label="Address Line 1"
                      onChange={(data) => {
                        setAddressLine1(data.target.value.replace(/[^A-Za-z0-9.'#@$%&]\s/g, ''))
                        setAddressLine1Error(false)
                      }}
                      onBlur={() => {
                        setAddressLine1(addressLine1.trim())
                        setAddressLine1Error(false)
                      }}
                      value={addressLine1}
                      error={addressLine1EmptyError}
                      helperText={addressLine1EmptyError ? 'Enter address' : ' '}
                    />

                    <TextField
                      style={styles.textbox}
                      sx={styles.textInputProps}
                      label="Address Line 2"
                      onChange={(data) => setAddressLine2(data.target.value.replace(/[^A-Za-z0-9.'#@$%&]\s/g, ''))}
                      onBlur={() => setAddressLine2(addressLine2.trim())}
                      value={addressLine2}
                      helperText={' '}
                    />

                    <TextField
                      style={styles.textbox}
                      sx={styles.textInputProps}
                      SelectProps={styles.selectProps}
                      select
                      label="State"
                      onChange={(data) => {
                        setState(data.target.value)
                        setStateEmptyError(false)
                      }}
                      value={state}
                      error={stateEmptyError}
                      helperText={stateEmptyError ? 'Enter a state' : ' '}
                    >
                      {states.map((option) => (
                        <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      style={styles.textbox}
                      sx={styles.textInputProps}
                      label="City"
                      onChange={(data) => {
                        setCity(data.target.value.replace(/[^A-Za-z0-9.'#@$%&]\s/g, ''))
                        setCityEmptyError(false)
                      }}
                      onBlur={() => {
                        setCity(city.trim())
                        setCityEmptyError(false)
                      }}
                      value={city}
                      error={cityEmptyError}
                      helperText={cityEmptyError ? 'Enter a city' : ' '}
                    />

                    <TextField
                      style={styles.textbox}
                      sx={styles.textInputProps}
                      label="Zip Code"
                      onChange={(data) => {
                        setZipCode(data.target.value.replace(/[^0-9-]/g, '').slice(0, 5))
                        setZipCodeEmptyError(false)
                      }}
                      onBlur={() => {
                        setZipCode(zipCode.trim())
                        setZipCodeEmptyError(false)
                      }}
                      value={zipCode}
                      error={zipCodeEmptyError || zipCodeLengthError}
                      helperText={zipCodeEmptyError ? 'Enter a zip code' :
                        zipCodeLengthError ? 'Zip Code must be 5 digits' : ' '}
                    />

                    <TextField
                      style={{ visibility: 'hidden', ...styles.textbox }}
                      value={''}
                    />

                  </View>
                </View>

                <SnackbarAlert
                  open={openAlert}
                  onClose={handleCloseAlert}
                  severity="error"
                  message="Please correct one or more invalid inputs"
                  anchorVertical={'bottom'}
                  anchorHorizontal={'right'}
                  autoHideDuration={5000}
                  paddingBottom="2.5%"
                  paddingRight="2%"
                />

                <View style={styles.buttonDiv}>
                  <View style={{ left: '6%' }}>
                    <TouchableOpacity
                      style={styles.button}
                      onPress={() => {
                        navigation.navigate('admin_dashboard', { screen: "view_units" });
                      }}>
                      <Text style={styles.innerText}>Cancel</Text>
                    </TouchableOpacity>
                  </View>

                  <View style={{ position: 'absolute', right: '6%' }}>
                    {!fetchError && <TouchableOpacity
                      style={styles.button}
                      onPress={() => {
                        AddUnit();
                      }}>
                      <Text style={styles.innerText}>Add Unit</Text>
                    </TouchableOpacity>}
                  </View>
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
    </View>
  );
};

export default AddUnit;
