import React, { useEffect, useContext } from 'react';

import { View, Text, Image, StyleSheet, TouchableOpacity } from 'react-native';
import { storeData, storeJsonData } from '../api_handler/asyncstorage.js';

import { login } from '../api_handler/users.js';
import { getApprovedFiles, getFiles, get_image_s3 } from '../api_handler/files.js';
import * as default_pfp from '../api_handler/default_pfp';
import AsyncStorage from '@react-native-async-storage/async-storage';
import ModeContext from '../components/ModeContext';
import { IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useCalendarContext } from '../components/calendar/CalendarContext';
import { requestCalendarEvents } from '../components/calendar/CalendarFunctions';

import { AuthUserContext } from '../nav/AuthUserProvider.js';
import { useSnackbar } from '../components/SnackbarContext.js'

function Login({ navigation }) {
    const { showSnackbar } = useSnackbar();
    const { state, setState } = useCalendarContext();
    const [email, onChangeEmail] = React.useState('');
    const [password, onChangePassword] = React.useState('');
    const [login_failed, on_login_failed] = React.useState(false);
    const { mode, toggleMode } = useContext(ModeContext);
    const [showPassword, setShowPassword] = React.useState(false);

    const { user, setUser } = useContext(AuthUserContext);

    async function decodeBase64(s) {
        var e = {}, i, b = 0, c, x, l = 0, a, r = '', w = String.fromCharCode, L = s.length;
        var A = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
        for (i = 0; i < 64; i++) { e[A.charAt(i)] = i; }
        for (x = 0; x < L; x++) {
            c = e[s.charAt(x)]; b = (b << 6) + c; l += 6;
            while (l >= 8) { ((a = (b >>> (l -= 8)) & 0xff) || (x < (L - 2))) && (r += w(a)); }
        }
        return r;
    };

    async function handleOnLogin(user, pass) {
        const login_info = {
            email: user,
            password: pass,
        };

        const res = await login(login_info);
        if (res.status != 200) {
            if (res.data.body === 'New password required') {
                navigation.navigate('Reset Temp Password', { email: login_info.email });
            } else {
                showSnackbar('error', 'Invalid login credentials');
                on_login_failed(true);
            }
        } else {
            global.token = res.data.idToken;
            global.user_data = res.data.body;

            // Set modalVis in AsyncStorage to display modal in Home.js once
            await AsyncStorage.setItem('modalVis', JSON.stringify(true));

            // This data must be set before calling setUser
            storeJsonData('user_data', res.data.body);
            storeData('idToken', res.data.idToken);

            await setUser(true);

            requestCalendarEvents(state, setState);

            // Get users current profile picture from s3
            var pfp = await get_image_s3('pfp/' + global.user_data.user_id, 'image/jpg', global.token);
            if (pfp === null || pfp === undefined) {
                global.profile_pic = 'data:image/png;base64,' + default_pfp.pfp();
            } else {
                global.profile_pic = await decodeBase64(pfp);
            }

            // Get users current signature (image) from s3
            var signature = await get_image_s3('signatures/' + global.user_data.signature_id, 'image/png', global.token);
            if (signature != undefined) {
                global.signature_uri = await decodeBase64(signature);
            } else {
                global.signature_uri = '';
            }

            // Set Profile Pic and Name in AsyncStorage to be used in Home.js
            await AsyncStorage.setItem('profilePic', global.profile_pic);
            await AsyncStorage.setItem('signature', global.signature_uri);

            // Change to dark mode and navigate to Home
            if (global.user_data.theme === "dark" && mode === "light") toggleMode();
            if (global.user_data.theme === "light" && mode === "dark") toggleMode();

            // Set on_login_failed to false so cache doesn't hold true if sign out happens.
            on_login_failed(false);
        }
    }

    return (
        <View style={styles.container}>

            <View style={styles.main}>
                <Image style={styles.logo} source={require('../assets/US_Army_Reserve logo light mode large final.png')} />

                <View style={styles.more_containers}>
                    <TextField
                        style={styles.emailBox}
                        onChange={data => onChangeEmail(data.target.value.toLowerCase())}
                        value={email}
                        label={"Email"}
                    />
                    <FormControl sx={styles.passBox} variant="outlined">
                        <InputLabel htmlFor="password">Password</InputLabel>
                        <OutlinedInput
                            id="password"
                            label={"Password"}
                            type={showPassword ? 'text' : 'password'}
                            onChange={data => onChangePassword(data.target.value)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => {
                                            setShowPassword(!showPassword)
                                        }}
                                        onMouseDown={(event) => {
                                            event.preventDefault();
                                        }}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <TouchableOpacity onPress={() => { navigation.navigate('ForgotPassword') }}>
                        <Text style={{ color: 'blue', textDecorationLine: 'underline', marginTop: 10 }}>Forgot Password?</Text>
                    </TouchableOpacity>
                </View>

                <View style={styles.buttonContainer}>
                    <TouchableOpacity style={styles.buttonStyle} color="#0d1232" onPress={() => { handleOnLogin(email, password) }}>
                        <Text style={styles.buttonText}>Login</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        width: '100%',
        height: '100%',
        backgroundColor: 'white',
        alignItems: 'center',
    },
    main: {
        marginTop: '5%',
        width: '50%',
        height: '80%',
        alignItems: 'center',
        borderColor: 'white',
        borderWidth: 1,
        padding: 20,
    },
    buttonContainer: {
        width: 200,
        marginBottom: 15,
    },
    more_containers: {
        height: 200,
        width: "100%",
        alignItems: 'center',
        flexDirection: 'column',
        marginTop: 25,
    },
    logo: {
        width: 800,
        height: 300,
        resizeMode: 'contain',
    },
    emailBox: {
        borderWidth: 1,
        height: '30%',
        width: '40%',
    },
    passBox: {
        borderWidth: 1,
        height: '30%',
        width: '40%',
        marginTop: '25px',
        // flexDirection: "row"
    },
    buttonStyle: {
        height: '125%',
        width: '100%',
        backgroundColor: "#0d1232",
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        borderRadius: '10px',
    },
    buttonText: {
        color: 'white',
        fontSize: 35,
        marginBottom: 7,
        textAlign: 'center',
    },
    login_failed_text: {
        color: 'red',
        fontSize: 20,
        marginBottom: 7,
        textAlign: 'center',
    },
});

export default Login;
