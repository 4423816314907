import React, { useEffect, useState, useRef } from "react";
import { View, Text, Image, TouchableOpacity, ScrollView } from "react-native";
import { CheckBox } from "react-native-web";
import { useScale } from "../../components/scale";
import NavSideBar from "../../components/NavSideBar";
import Modal from 'react-native-modal';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as Progress from 'react-native-progress'
import { createTheme, styled, useTheme, ThemeProvider, lighten, darken } from '@mui/material/styles';
import { TextInput } from "react-native-paper";
import { get_single_file, approve_1380s } from "../../api_handler/files";
import { create_notification } from "../../api_handler/notifications.js";
import { generatePDF } from "../generatePDF.js";
import { useColorContext } from "../../components/ColorContext";

import TextField from '@mui/material/TextField';
import ViewShot from 'react-native-view-shot';
import { captureRef } from 'react-native-view-shot';
import { create_new_user_signature } from "../../api_handler/users.js";
import { put_image_s3 } from "../../api_handler/files";
import { storeJsonData } from '../../api_handler/asyncstorage.js';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Scrollbars from "react-custom-scrollbars";
import { useSnackbar } from '../../components/SnackbarContext';

function Review1380({ route, navigation }) {
  const { colors } = useColorContext();
  const { showSnackbar } = useSnackbar();
  const scale = useScale();
  const theme = useTheme()
  const reviewTheme = createTheme({
    palette: {
      mode: colors.mode,
      primary: theme.palette.primary,
    },
  });

  const [loaded, setLoaded] = useState(false);
  const [viewSig, setViewSig] = useState(false);
  const [author, setAuthor] = useState("");
  const [isApproved, setisApproved] = useState(false);
  const [isDisapproved, setisDisapproved] = useState(false);
  const [date, setDate] = useState(new Date());
  const [comment, setComment] = useState("");
  const [commentInputHeight, setCommentInputHeight] = useState(64);
  const [events, setEvents] = useState([]);
  const [eventsLength, setEventsLength] = useState();
  const [entryExists, setEntryExists] = useState(false);
  const [grade, setGrade] = useState("");
  const [branch, setBranch] = useState("");
  const [modalVis, setmodalVis] = React.useState(false);

  const [name, setName] = useState("");

  const [doesUserHaveSignature, setDoesUserHaveSignature] = useState(false);
  const [signatureName, setSignatureName] = useState("");
  const [didUserSignDocument, setDidUserSignDocument] = useState(false);
  const [expandedAccordions, setExpandedAccordions] = useState(new Array(events.length).fill(false));
  const [isValidating, setIsValidating] = useState(route.params?.same_reviewer);
  const [hasValidator, setHasValidator] = useState(false);
  const [reviewerText, setReviewerText] = useState('');

  const viewRef = useRef();

  useEffect(() => {
    if (comment === '') {
      setCommentInputHeight(64);
    }
  }, [comment]);

  const styles = {
    container: {
      height: '100%',
      width: '100%',
      flexDirection: 'row',
      backgroundColor: colors.screen_background
    },
    main: {
      width: '75%',
      flex: 10,
      alignItems: 'center',
    },
    header: {
      marginTop: "0%",
      height: 110,
      backgroundColor: colors.screen_background,
    },
    rowContainer: {
      flexDirection: "row",
      marginTop: 15,
      marginBottom: 10,
      justifyContent: "center",
    },
    title: {
      fontSize: scale(50),
    },
    pageTitle: {
      fontSize: scale(30),
      fontFamily: 'Trebuchet MS',
      flex: 1,
      color: colors.text,
      paddingLeft: '5%',
      paddingTop: 25
    },
    signText: {
      fontWeight: 'bold',
      fontSize: scale(14),
      textAlign: 'center',
      margin: '1%',
      paddingLeft: '5%',
      paddingRight: '5%',
      color: colors.bow
    },
    description: {
      fontSize: scale(18),
      marginLeft: '5%',
      textAlign: 'Left',
      paddingTop: 25,
      color: colors.bow,
      width: '50%',
      fontFamily: 'Trebuchet MS',
      marginBottom: '2%'
    },
    button: {
      width: "10vw",
      borderRadius: 10,
      padding: 10,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: colors.action_button,
      margin: 8,
      marginBottom: 15,
      borderColor: colors.border_color,
      borderWidth: 1,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      alignItems: "center",
    },
    cancelButton: {
      width: "10vw",
      borderRadius: 10,
      padding: 10,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: colors.modal_background,
      margin: 8,
      marginBottom: 15,
      borderColor: colors.border_color,
      borderWidth: 1,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      alignItems: "center",
    },
    contentContainer: {
      height: "50vh",
      width: "100%",
      marginTop: 10,
      margin: 20,
      flexDirection: "row",
      justifyContent: "center",
      flex: 1,
    },
    tableContainer: {
      backgroundColor: colors.screen_background,
      flex: 1,
      width: "35%",
    },
    tableDiv: {
      borderColor: colors.border_color,
      borderWidth: 1,
      borderRadius: 5,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      backgroundColor: colors.modal_background,
    },
    textStyle: {
      fontSize: 15,
      fontWeight: "bold",
      color: colors.text,
    },
    commentInput: {
      borderColor: colors.border_color,
      minHeight: 64,
      height: commentInputHeight,
      borderWidth: 1,
      borderRadius: 5,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      backgroundColor: colors.modal_background,
      width: "85%",
      marginTop: '5%'
    },
    entryBox: {
      backgroundColor: colors.screen_background,
      justifyContent: "center",
      alignItems: "center",
      padding: '3%',
      marginBottom: '2%',
      width: '85%',
      borderColor: colors.border_color,
      borderWidth: 1,
      borderRadius: 5,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
    },
    approvalModalTitle: {
      fontFamily: 'Trebuchet MS',
      fontSize: '1.25rem',
      fontWeight: 'bold',
      color: colors.text,
      marginBottom: '3%'
    },
    checkboxContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: 10,
    },
    checkboxLabel: {
      marginLeft: 8,
      fontSize: 15,
      color: colors.text,
    },
    modal: {
      backgroundColor: colors.screen_background,
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      width: '30%', 
      maxHeight: '40%', 
      alignSelf: 'center',
      borderRadius: '10px',
    },
  
    modalText: {
      color: colors.text,
      width: '90%', // Adjust to fit content better
      fontSize: scale(20),
      // fontWeight: 'bold',
      textAlign: 'center',
      // marginBottom: '5%',
    },
  
    modalButton: {
      marginTop: 30,
      marginBottom: 20,
      width: '55%', // Adjust button width relative to modal size
      height: '20%',
    },
    noticeText: {
      fontSize: scale(40),
      textDecorationLine: 'underline',
      color: colors.text,
      padding: '2%',
      alignItems: 'center',
      fontWeight: 'bold',
      justifyContent: 'center',
    },
    buttonStyle: {
      height: '100%',
      width: '100%',
      backgroundColor: colors.button,
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      borderRadius: '10px',
    },
    buttonText: {
      fontSize: scale(25),
      color: colors.text,
      padding: '2%',
      alignItems: 'center',
      fontWeight: 'bold',
      justifyContent: 'center',
    },
  };
 
  useEffect(() => {
    async function loadFile() {
      const res = await get_single_file(
        { form_id: route.params.fileID, form_type: "1380" },
        global.token
      );
      if (res === undefined) {
        showSnackbar("error", "Application Error: Failed to retrieve the review form")
        navigation.navigate("AwaitingAction")
      } else {
        await preLoadInfo(res.body);
      }
    }
    
    function checkSignature() {
      if (global.signature_uri == null || global.signature_uri === '') {
        setDoesUserHaveSignature(false);
      } else {
        setDoesUserHaveSignature(true);
      }
    }

    loadFile();
    setDidUserSignDocument(false);
    checkSignature();
  }, []);

  const captureTextAsImage = async () => {
    try {
      const fullname = global.user_data.first_name.toLowerCase() + " " + global.user_data.last_name.toLowerCase();
      if (signatureName.toLowerCase() === fullname) {
        const uri = await captureRef(viewRef, {
          format: 'png',
          quality: 0.8
        });

        let ret = await create_new_user_signature({ user_id: global.user_data.user_id }, global.token);
        if (ret.status === 200) {
          ret = ret.data;
          await put_image_s3('signatures/' + ret.body.signature_id, uri, 'image/png', global.token);

          global.signature_uri = uri;
  
          global.user_data.signature_id = ret.body.signature_id;
          await AsyncStorage.setItem('signature', global.signature_uri);
          storeJsonData('user_data', global.user_data);
  
          setDoesUserHaveSignature(true);
        } else {
          showSnackbar("error", "Application Error: Failed to create your signature");
        }
        
      } else {
        showSnackbar('error', 'The name inputted does not match your name we have on record!');
      }
    } catch (error) {
      console.error('Error capturing image:', error);
    }
  };

  const viewFilled = () => {
    setLoaded(false)
    const data = route.params;
    generatePDF(data.fileID, data.form_type, navigation, false, false, data.attachments, data.creator_id);
    setLoaded(true)
  };

  const preLoadInfo = async (body) => {
    console.log(body)
    if (body.reviewer_ids && body.validator_ids) {
      const isReviewer = body.reviewer_ids.includes(global.user_data.user_id);
      const isValidator = body.validator_ids.includes(global.user_data.user_id) && body.validator_id === null;
      const isValidated = body.validator_ids.includes(global.user_data.user_id) && body.validator_id === global.user_data.user_id;

      if (isReviewer && isValidator && !isValidated) {
        if(body.attachments.length > 0)
          setmodalVis(true);
        setReviewerText('Review 1380, and Sign as both Officer Having Knowledge of Duties Performed and Validate Approved Additional Training (Blocks 10/11, 10a/11a)')
      } else if (isReviewer) {
        setReviewerText('Approve and Sign as Officer Having Knowledge of Duties Performed (Block 10/11)')
      } else {
        if(body.attachments.length > 0)
          setmodalVis(true);
        setReviewerText('Review 1380, and Validate Approved Additional Training (Block 10a/11a)')
      }
    }

    setName(body.name_5);
    setDate(body.creation_date_1);
    setGrade(body.grade_6);
    setBranch(body.branch_7);

    let numOfEvents = body.date_9a ? body.date_9a.length : 0;
    
    for (let event = 0; event < numOfEvents; event++) {
      events.push({
        id: event,
        hours: body.hours_9b[event],
        points: body.retirement_points_9c[event],
        duties: body.duty_performed_9 === 'aftp' ? body.type_of_training_completed_9d[event] : body.description_9d[event],
        date: new Date(body.date_9a[event]),
      });
    }

    setEntryExists(true);
    setEventsLength(numOfEvents);
    setLoaded(true);
  };

  function FileSelect({ id, selected, select, date, points, hours, duties }) {
    return (
      <Text style={styles.textStyle}>
        {"Entry " +
          (parseInt(id, 10) + 1) +
          ":            " +
          date.toDateString() +
          "\n# of hours: " +
          hours +
          "   Retirement points: " +
          points +
          "\nDescription: " +
          duties  +"\n\n"}
      </Text>
    );
  }

  // Building 1380 data table
  function createTable() {
    const data = [
      { label: "Soldiers Info.", data: name + " " + grade + " " + branch},
      { label: "Date Filed", data: date},
    ];

    return data;
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${TableCell.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
  }));


  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:hover': {
      backgroundColor: colors.textfield_background_hover

    },

    '& td, & th': {
      borderColor: colors.border_color,
    },
  }));

  const closeAllAccordions = () => {
    setExpandedAccordions(new Array(events.length).fill(false));
  };

  const changeAccept = (value) => {
    setisApproved(value);

    if (value) {
      setisDisapproved(false);
    }
  };

  const changeDecline = (value) => {
    setisDisapproved(value);

    if (value) {
      setisApproved(false);
    }
  };

  const handleSignature = () => {
    closeAllAccordions();
    setViewSig(true);
    setDidUserSignDocument(true);
  }

  const onSubmit = async () => {
    setLoaded(false);
    // make sure user has signature and approved/disapproved
    const sigFile = global.signature_uri;
    if (sigFile == null || sigFile === '') {
      showSnackbar("error", "You do not have a signature...");
      setLoaded(true);
    } else if (isApproved === false && isDisapproved === false) {
      showSnackbar("error", "Please either approve or disapprove this request.");
      setLoaded(true);
    } else if(!didUserSignDocument) {
        console.log(didUserSignDocument)
        showSnackbar("error", "Please Sign Document");
        setLoaded(true);
        return
      } else {
        // Get approve/deny checkboxes from form
        /* Liam moved const name to here, looks like it never would have been created if
           the user disapproved the form. Left the original implementation commented out for future reference */
        const name =
            global.user_data.rank +
            " " +
            global.user_data.first_name +
            " " +
            global.user_data.middle_name +
            " " +
            global.user_data.last_name +
            ", " +
            global.user_data.grade;
        if (isApproved) {
          const data = {
            user_id: global.user_data.user_id,
            form_id: route.params.fileID,
            name_10: name,
            signature_id_11: global.user_data.signature_id,
            status: 4,
            comments: comment,
            permissions: global.user_data.permissions,
            is_validating: route.params.fileID > 3593 ? isValidating : false,
            has_validator: route.params.fileID > 3593 ? hasValidator : true,
          };

          let res = await approve_1380s(data, global.token);
          if (res.status === 200) {
            await sendNotifToSoldier(res.data.creator_id, 'approved');
          } else {
            showSnackbar("error", "Application Error: Failed to add your review to the 1380 Form");
            navigation.navigate("AwaitingAction");
            return;
          }
          
          //will not let the reviewer continue if the file is dissaproved with an empty comment
        }else if (comment === '') {
          showSnackbar('error', 'Please enter a comment');
          setLoaded(true);
          return;
          
        } else if (isDisapproved) {
          const data = {
            user_id: global.user_data.user_id,
            form_id: route.params.fileID,
            name_10: name,
            signature_id_11: global.user_data.signature_id,
            status: 5,
            comments: comment,
            permissions: global.user_data.permissions,
            is_validating: route.params.fileID > 3593 ? isValidating : false,
            has_validator: route.params.fileID > 3593 ? hasValidator : true,
          };

          let res = await approve_1380s(data, global.token);
          if (res.status === 200) {
            await sendNotifToSoldier(res.data.creator_id, 'disapproved');
          } else {
            showSnackbar("error", "Application Error: Failed to add your review to the 1380 Form");
            navigation.navigate("AwaitingAction");
            return;
          }
        }

        setLoaded(true);

      navigation.navigate("AwaitingAction");
    }

    /* ------------------------------------------*/
    // email to supervising chief
    // send email to res.sectionChiefEmail
  };
  /* -----------------------------------------------*/

  const sendNotifToSoldier = async (soldier_id, status) => {
    const data = {
      title: '1380 Form ' + status,
      body: comment,
      receiver_id: soldier_id,
      sender_id: global.user_data.user_id,
      notification_type: 'file',
      object_type: '1380 form ' + status,
      form_events_id: route.params.fileID,
    };
    let message = status === 'approved' ? "1380 Form approved successfully" : "1380 Form disapproved successfully";
    let res = await create_notification(data, global.token);
    if (res.status !== 200) {
      showSnackbar(
        "success",
        message,
        "top",
        "right",
        6000,
        400,
        () => {
          showSnackbar("error", `Application Error: Failed to send notification to ${name}`);
        }
      )
    } else {
      showSnackbar("success", message);
    }
  };

  const onCancel = () => {
    navigation.navigate("AwaitingAction");
  };

  const loadingStyles = {
    animation: {
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
        justifyContent: 'center',
        paddingVertical: 20,
        background: colors.screen_background,
    }
  }

  if (!loaded) {
    return (
      <View style={loadingStyles.animation}>
          <Progress.CircleSnail
            color={colors.loading_circle}
            indeterminate={true}
            size={275}
            thickness={8}
          />
      </View>
    );
  }

  return (
    <ThemeProvider theme={reviewTheme}>
      <View style={styles.container}>
        {
          loaded ? (
            <Scrollbars style={styles.main}>
              <View style={{ flexDirection: 'column' }}>
                <Text style={styles.pageTitle}>Review </Text>
                <Text style={styles.description}>
                  {reviewerText}
                </Text>
              </View>



              <View style={{ flexDirection: "row", marginTop: '1%', width: '100%', paddingLeft: '5%', paddingRight: '5%'  }}>
                <View style={[styles.tableContainer, {flex: 1, backgroundColor: colors.screen_background}]}>
                  <View style={styles.tableDiv}>
                    <TableContainer component={Paper} style={{ height: 'fit-content', width: '100%', paddingTop: '1%', backgroundColor: colors.textfield_background }}>
                      <Table>
                        <TableHead style={{borderColor: colors.border_color}}>
                          <TableRow>
                            <StyledTableCell style={{ color: colors.text, fontSize: '1.25rem', width: '35%', borderColor: colors.border_color }}>Label</StyledTableCell>
                            <StyledTableCell style={{ color: colors.text, fontSize: '1.25rem', borderColor: colors.border_color }}>Data</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {createTable().map((row) => (
                            <StyledTableRow key={row.label}>
                              <StyledTableCell style={{ color: colors.text, width: '35%', fontSize: '1rem', }}>{row.label}</StyledTableCell>
                              <StyledTableCell style={{ color: colors.text, fontSize: '1rem' }}>{row.data}</StyledTableCell>
                            </StyledTableRow>
                          ))}
                          {events.map((event, index) => (
                            <React.Fragment key={event.id}>
                              <StyledTableRow>
                                <StyledTableCell colSpan={2} style={{ color: colors.text, fontWeight: 'bold', padding: '0px', borderWidth: index === events.length - 1 ? '0px' : '1px'}}>
                                  <Accordion
                                    sx={{
                                      boxShadow: 'none',
                                      backgroundColor: colors.textfield_background,
                                      paddingTop: '5px',
                                      paddingTop: '5px',
                                      '&:hover': {
                                        backgroundColor: colors.textfield_background_hover
                                      },
                                    }}
                                    slotProps={{
                                      transition: {
                                        unmountOnExit: true,
                                      },
                                    }}
                                    defaultExpanded={index === 0}
                                    expanded={expandedAccordions[index]}
                                    onChange={(event, isExpanded) => {
                                      setExpandedAccordions((prevState) => {
                                        const updatedState = [...prevState];
                                        updatedState[index] = isExpanded;
                                        return updatedState;
                                      });
                                    }}
                                  >
                                    <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: colors.text}} />}>
                                      <Typography style={{ color: colors.text, fontWeight: 'bold' }}>
                                        Submitters Entry {index + 1}
                                      </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails style={{ padding: 0 }}>
                                      <Table>
                                        <TableBody>
                                          <TableRow>
                                            <StyledTableCell style={{ color: colors.text }}>Hours</StyledTableCell>
                                            <StyledTableCell style={{ color: colors.text }}>{event.hours}</StyledTableCell>
                                          </TableRow>
                                          <TableRow>
                                            <StyledTableCell style={{ color: colors.text }}>Points</StyledTableCell>
                                            <StyledTableCell style={{ color: colors.text }}>{event.points}</StyledTableCell>
                                          </TableRow>
                                          <TableRow>
                                            <StyledTableCell style={{ color: colors.text }}>Date</StyledTableCell>
                                            <StyledTableCell style={{ color: colors.text }}>{event.date.toDateString()}</StyledTableCell>
                                          </TableRow>
                                          <TableRow>
                                            <StyledTableCell style={{ color: colors.text, borderWidth: 0 }}>Duties</StyledTableCell>
                                            <StyledTableCell style={{ color: colors.text, borderWidth: 0 }}>{event.duties}</StyledTableCell>
                                          </TableRow>
                                        </TableBody>
                                      </Table>
                                    </AccordionDetails>
                                  </Accordion>
                                </StyledTableCell>
                              </StyledTableRow>
                            </React.Fragment>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </View>
                </View>

                <View style={{ flexDirection: "column", alignItems: "center", flex: 1}}>
                  <View style={styles.entryBox}>
                    <Text style={styles.approvalModalTitle}>Reviewer Actions</Text>

                    <View style={styles.checkboxContainer}>
                      <CheckBox
                        disabled={false}
                        value={isApproved}
                        onValueChange={(newValue) => {
                          changeAccept(newValue);
                        }}
                      />
                      <Text style={styles.checkboxLabel}>Approve</Text>
                      <CheckBox
                        disabled={false}
                        value={isDisapproved}
                        onValueChange={(newValue) => {
                          changeDecline(newValue);
                        }}
                        style={{ marginLeft: 20, }}
                      />
                      <Text style={styles.checkboxLabel}>Disapprove</Text>
                    </View>

                    <TextInput
                      style={styles.commentInput}
                      theme={{
                        colors: {
                          text: colors.inputted_text,
                          accent: colors.textfield_background,
                          primary: colors.inputted_text,
                          placeholder: colors.placeholder_text,
                        },
                      }}
                      outlineColor={colors.textfield_background}
                      activeUnderlineColor={colors.bow}
                      placeholderTextColor={colors.text}
                      selectionColor={colors.text}
                      activeOutlineColor={colors.text}
                      InputProps={{ style: { color: colors.text } }}
                      label="Comment"
                      placeholder='Explain why you approved/denied the request (Optional)'
                      inputStyle={{ color: colors.bow }}
                      multiline={true}
                      autoSize={true}
                      value={comment}
                      onChangeText={(text) => {
                        setComment(text);
                        if (text.length === 0) {
                          setCommentInputHeight(64)
                        } 
                      }}
                      onContentSizeChange={({nativeEvent: {contentSize: {width, height}}}) => {
                        if (height > 64 && height < 96) {
                          setCommentInputHeight(96);
                        } else if (height > 96 && height < 128){
                          setCommentInputHeight(128);
                        } else if (height > 128 && height < 160) {
                          setCommentInputHeight(160);
                        } else if (height > 160){
                          setCommentInputHeight(192);
                        }
                      }}
                    />
                  </View>

                  <Text style={[styles.signText, {width: '85%', marginTop: ''}]}>
                    By clicking on the "Submit" button below, you consent to your
                    digital signature being affixed to the document. Your signature can
                    be viewed below after clicking the "Sign Document" button. If you
                    don't have a signature, please add one below.
                  </Text>
                  <View style={{ width: '100%', alignItems: 'center', maringTop: '1%' }}>
                    {doesUserHaveSignature == true ? (
                      <>
                        <TouchableOpacity
                          style={[styles.button, {backgroundColor: colors.action_button}]}
                          onPress={handleSignature}
                        >
                          <Text style={{ justifyContent: "center", fontSize: 15, color: colors.text, fontWeight: 'bold' }}>
                            {viewSig ? "Document Signed" : "Sign Document"}
                          </Text>
                        </TouchableOpacity>
                        {viewSig && (
                          <>
                            <Image
                              resizeMode={"contain"}
                              style={{ width: 200, height: 100, backgroundColor: 'white'}}
                              source={{ uri: global.signature_uri }}
                            />
                          </>
                        )}
                      </>
                    ) : (
                      <View style={{ width: '50%', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={[styles.textStyle, { padding: 15 }]}>No signature found</Text>

                        <TextInput
                          style={styles.commentInput}
                          theme={{
                            colors: {
                              text: colors.inputted_text,
                              accent: colors.textfield_background,
                              primary: colors.inputted_text,
                              placeholder: colors.placeholder_text,
                            },
                          }}
                          label="Enter your first and last name"
                          placeholder=""
                          value={signatureName}
                          onChange={(data) => setSignatureName(data.target.value)}
                        />

                        <ViewShot ref={viewRef} style={{ padding: 10, marginVertical: 10, backgroundColor: 'white' }} options={{ format: 'png', quality: 1 }}>
                          <Text style={{ color: 'black' }}>{signatureName}</Text>
                        </ViewShot>

                        <TouchableOpacity style={styles.button} onPress={captureTextAsImage}>
                          <Text style={styles.textStyle}>Create Signature</Text>
                        </TouchableOpacity>
                      </View>
                    )}
                  </View>
                </View>
              </View>
                
              <View style={[styles.rowContainer, { marginTop: 50 }]}>
                <TouchableOpacity style={[styles.cancelButton, {backgroundColor: colors.button_cancel}]} onPress={onCancel}>
                  <Text style={styles.textStyle}>Cancel</Text>
                </TouchableOpacity>

                <TouchableOpacity style={[styles.cancelButton, {marginLeft: '5%', marginRight: '5%'}]} onPress={viewFilled}>
                  <Text style={styles.textStyle}>View Form</Text>
                </TouchableOpacity>

                <TouchableOpacity style={[styles.button, {backgroundColor: colors.button_submit}]} onPress={onSubmit}>
                  <Text style={styles.textStyle}>Submit</Text>
                </TouchableOpacity>
              </View>
            </Scrollbars>
            ) : (
            <View style={styles.container}>
              <View style={styles.header}>
              </View>
            </View>
          )
        }

        {/* Review validator Modal */}
        <Modal isVisible={modalVis}>
          <View style={styles.modal}>
            <Text style={styles.noticeText}>
              Notice
            </Text>
            <Text style={styles.modalText}> Attached forms have not been actioned. </Text>
            <Text style={[styles.modalText, {fontWeight: 'bold'}]}> Review Carefully!</Text>
            <View style={styles.modalButton}>
              <TouchableOpacity style={styles.buttonStyle} color="#0d1232" onPress={() => setmodalVis(false)}>
                <Text style={styles.buttonText}>Continue</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>
      </View>
    </ThemeProvider>
  );
}
export default Review1380;