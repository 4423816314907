/* eslint-disable require-jsdoc */
import React, { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { Dimensions, View, Text, Image, TouchableOpacity } from 'react-native';
import { useScale } from '../components/scale';

import { useFocusEffect, useIsFocused } from '@react-navigation/native';
import { AntDesign } from "@expo/vector-icons";
import CustomHeader from '../components/header';
import Modal from 'react-native-modal';
import moment from "moment";
import AsyncStorage from '@react-native-async-storage/async-storage';
import ModeContext from '../components/ModeContext';
import { useColorContext } from '../components/ColorContext';
import NavSideBar from '../components/NavSideBar';
import Carousel from 'react-native-reanimated-carousel';
import { useCalendarContext } from '../components/calendar/CalendarContext';
import SmallCalendar from '../components/calendar/SmallCalendar';
import Add from '@mui/icons-material/Add';
import dayjs from 'dayjs';
import { getMonth } from '../components/calendar/util';
import { requestCalendarEvents } from '../components/calendar/CalendarFunctions';
import * as Progress from 'react-native-progress';
import Scrollbars from "react-custom-scrollbars";
import { useNavBarContext, checkIfAdmin, checkIfApprover } from '../components/NavBarContext';
import {
  getFileDate,
  formatRstDisplayDate,
  formatDateToMilDate,
} from '../components/FileDataFunctions.js'
import { get_all_user_files } from '../api_handler/files';
import { generatePDF } from "./generatePDF.js";
import { useSnackbar } from '../components/SnackbarContext.js';


function Home({ navigation }) {
  const {navState, setNavState} = useNavBarContext();
  const { mode } = useContext(ModeContext);
  const { colors } = useColorContext();
  const { showSnackbar } = useSnackbar();
  
  
  const [profilePic, setProfilePic] = useState('');
  const [name, setName] = useState('');
  const [modalVis, setmodalVis] = React.useState(false);
 
  const isFocused = useIsFocused();
  const isLight = (mode === 'light');
  const { width } = Dimensions.get('window')

  // RECENT FORM STATUS STATES
  const [files, setFiles] = useState([]);
  const [selected, setSelected] = useState(-1);
  const [loaded, setLoaded] = useState(false);
  const [allFiles, setAllFiles] = useState([]);
  const [selectedFileInfo, setSelectedFileInfo] = useState({});

  // Setting screen size scale for cases of Tablet browsers
  const image = isLight ? require('../assets/US_Army_Reserve logo light mode large final.png') : require('../assets/US_Army_Reserve logo dark mode large final.png');
  const images = [
    require("../assets/stock_army/army_1.jpg"),
    require("../assets/stock_army/army_2.jpg"),
    require("../assets/stock_army/army_3.jpg"),
    require("../assets/stock_army/army_4.jpg"),
    require("../assets/stock_army/army_5.jpg"),
  ];
  const quotes = [
    '"There will always be rocks in the road ahead of us. They will be stumbling blocks or stepping stones; it all depends on how you use them." - Friedrich Nietzsche',
    '"The only thing necessary for the triumph of evil is for good men to do nothing." - Edmund Burke',
    '"The best way to predict the future is to create it." - Peter Drucker',
    '"To be yourself in a world that is constantly trying to make you something else is the greatest accomplishment." - Ralph Waldo Emerson',
    '"Success is not final, failure is not fatal: It is the courage to continue that counts." - Winston Churchill'
  ];

  const getCommentForFormType = useCallback((form) => {
    switch (form.form_type) {
        case 'RST': return form.rst_approver_comment;
        case 'EBDL': return form.ebdl_approver_comment;
        case '1380': return form.approver_comment_1380;
        case '1380 aftp': return form.approver_comment_1380;
        case 'ATA': return form.ata_approver_comment;
        case 'MDR': return form.mdr_approver_comment;
        default: return '';
    }
  }, []);

  useEffect(() => {
    // Redirect user to login if data is null
    if (global.user_data === undefined) {
      navigation.navigate('Login');
    }

    // Wait for profile pictures to be updated in other files
    const getData = async () => {
      const storedProfilePic = await AsyncStorage.getItem('profilePic');
      const storedName = await AsyncStorage.getItem('name');
      const modalVisCheck = await AsyncStorage.getItem('modalVis');
      setProfilePic(storedProfilePic || '');
      setName(storedName || '');
      setmodalVis(JSON.parse(modalVisCheck));
    };

    // Get data when user enters page
    if (isFocused) {
      getData();
    }
  }, [isFocused]);

  const scale = useScale();
  const styles = ({
    wrapper: {
      flexDirection: 'row',
      height: '100%'
    },
    container: {
      flex: 10,
      backgroundColor: colors.screen_background,
    },
    intro: {
      height: '10%',
      width: '100%',
      alignContent: 'center',
      justifyContent: 'center',
    },
    commsApp: {
      fontSize: scale(35),
      fontFamily: 'Trebuchet MS',
      alignContent: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      color: colors.text,
      padding: '3%'
    },
    main: {
      marginTop: '0%',
      height: '85%',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    modal: {
      backgroundColor: colors.screen_background,
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: '40%',
      alignSelf: 'center',
    },
    modalText: {
      color: colors.text,
      width: '80%',
      fontSize: scale(20),
      fontWeight: 'bold',
      textAlign: 'center',
      marginBottom: '5%',
    },
    image: {
      flex: 1,
      width: '80%',
      height: '20%',
      resizeMode: 'contain',
      marginTop: 50,
    },
    carouselImage: {
      justifyContent: 'center',
      alignSelf: 'center',
      width: '95%',
      flex: 8,
      borderRadius: 40,
    },
    carouselText: {
      fontSize: scale(20),
      fontFamily: 'Calibri',
      alignContent: 'center',
      justifyContent: 'center',
      resizeMode: 'contain',
      textAlign: 'center',
      color: colors.text,
      flex: 1,
      marginTop: '5%',
      padding: '2%'
    },
    modalButton: {
      marginBottom: '10%',
      width: '80%',
      height: '10%',
    },
    buttonStyle: {
      height: '100%',
      width: '100%',
      backgroundColor: colors.button,
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      borderRadius: '10px',
    },
    carousel: {
      flex: 4,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: colors.content_box,
      borderColor: colors.border_color,
      borderWidth: 1,
      borderRadius: 40,
      flexDirection: 'column',
      paddingTop: '3%',
      paddingBottom: '3%',
      marginTop: '-6%',
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
    },
    buttonText: {
      fontSize: 35,
      color: colors.text,
      padding: '2%',
      alignItems: 'center',
      fontWeight: 'bold',
      justifyContent: 'center',
    },

    // RECENT FORM STATUS CSS aqui
    recentFormStatus: {
      recentFormsContainer: {
        backgroundColor: colors.navSideBar_Background,
        borderLeftWidth: 1,
        paddingLeft: '1%',
        paddingRight: '1%',
        width: '30%',
        borderColor: colors.border_color,
        height: '100%',
        msOverflowStyle: 'none', // IE and Edge
        scrollbarWidth: 'none', // Firefox
        WebkitScrollbar: { display: 'none' },
      },
      recentFormsTitle: {
        paddingTop: width < 1425 ? ( width < 1205 ? '15%' : '10%' ) : '5%',
        fontSize: width < 1350 ? (width < 950 ? 16 : 18) : 24,
        paddingLeft: '2%',
        textAlign: 'left',
        fontWeight: 'bold',
        color: colors.text,
        fontFamily: 'Trebuchet MS',
        borderColor: colors.text,
        paddingBottom: width < 1425 ? ( width < 1205 ? '15%' : '10%' ) : '5%',
      },
      formsContainer: {
        height: '100%',
        flexDirection: 'column',
        width: '100%',
      },
    },
    button: {
      height: width < 1440 ?  '22%': '15%',
      width: '96.5%',
      borderRadius: 10,
      padding: "6%",
      paddingLeft: "3.5%",
      paddingRight: "3.5%",
      margin: "1.855%",
      marginBottom: "3.48%",
      borderColor: colors.border_color,
      borderWidth: 1,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      flexDirection: "row",
      justifyContent: "center",
      flex: 1,
    },
    fileContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      padding: 10,
      borderRadius: 8,
      marginBottom: 5,
    },
    docContainer: {
      width: 40,
      height: 40,
      marginRight: 10,
    },
    doc: {
      width: '100%',
      height: '100%',
      resizeMode: 'contain',
    },
    textContainer: {
      flex: 1,
    },
    textStyle: {
      fontSize: width < 1425 ? (width < 1205 ? 10 : 12) : 16,
      fontWeight: 'bold',
      color: colors.text,
      marginBottom: 2,
    },
    commentStyle: {
      fontSize: width < 1425 ? (width < 1205 ? 10 : 12) : 15,
      color: colors.text,
      flexWrap: 'wrap',
    },
    selected: {
      backgroundColor: colors.gold,
    },
    notSelected: {
      backgroundColor: colors.file_button_color,
    },

    // CALENDAR CSS
    // calendar: {
    //   calContainer: {
    //     backgroundColor: colors.screen_background,
    //     borderLeftWidth: 1,
    //     paddingLeft: '1%',
    //     paddingRight: '1%',
    //     width: '25%', // Adjust the width as needed
    //     borderColor: colors.text,
    //     height: '100%'
    //   },
    //   smallCalendarWrapper: {
    //     flexDirection: 'row',
    //     alignItems: 'flex-end'
    //   },
    //   title: {
    //     fontSize: 25,
    //     textAlign: 'left',
    //     fontWeight: 'bold',
    //     color: colors.text,
    //     flex: 3,
    //     justifyContent: 'center',
    //     fontFamily: 'Trebuchet MS'
    //   },
    //   smallCalendar: {
    //     borderTopWidth: 1,
    //     borderColor: colors.text,
    //     marginTop: '3%',
    //     fontFamily: 'Trebuchet MS',
    //   },
    //   upcomingEventsTitle: {
    //     paddingTop: '5%',
    //     fontSize: 25,
    //     textAlign: 'left',
    //     fontWeight: 'bold',
    //     color: colors.text,
    //     fontFamily: 'Trebuchet MS',
    //     maringTop: '5%',
    //     borderBottomWidth: 1,
    //     borderColor: colors.text,
    //     paddingBottom: '2%'
    //   },
    //   events: {
    //     height: '100%',
    //     flexDirection: 'column',
    //     width: '100%'
    //   },
    //   inidivEvent: {
    //     backgroundColor: colors.navSideBar_Background,
    //     borderRadius: 25,
    //     padding: '5%',
    //     paddingTop: '3%',
    //     marginTop: '3%'
    //   },
    // },
    // todayText: {
    //   fontSize: 18,
    //   color: colors.text,
    //   padding: '2%',
    //   alignItems: 'center',
    //   fontWeight: 'bold',
    //   justifyContent: 'center',
    //   fontFamily: 'Trebuchet MS'
    // },
    // eventTitleText: {
    //   fontSize: 17,
    //   color: colors.text,
    //   padding: '.5%',
    //   textAlign: 'left',
    //   fontWeight: 'bold'
    // },
    // eventBodyText: {
    //   fontSize: 15,
    //   color: colors.text,
    //   padding: '.5%',
    //   textAlign: 'left',
    // },
    // createEventButton: {
    //   fontSize: 24,
    //   fontFamily: 'Calibri',
    //   fontWeight: 'bold',
    //   backgroundColor: colors.navSideBar_Background,
    //   borderRadius: 50,
    //   padding: 10,
    //   flexDirection: 'row',
    //   alignItems: 'center',
    //   justifyContent: 'center',
    //   height: '75%',
    //   flex: 2,
    //   color: colors.text,
    // },
  });

  /**
   * These function underneath are corresponding to the Calendar Functionality that was present
   * in the application before it was requested to remove that particular functionality until
   * further notice (3/20/2024). The permission logic hasn't been completely ironed out and with
   * our demon approaching it was decided we would hide the functionality and implement a 
   * "Recent Form Status" that is depicted on mobile. Instead of deleting the functionality I 
   * decided to put all the Calendar Related Functionality together and comment it out because
   * the calendar will be returned once the permissions related to the calendar are addressed.
   */

  // const { state, setState } = useCalendarContext();
  // const [upcomingEvents, setUpcomingEvents] = useState([]);

  // function handleReset() {
  //   const monthChosen =
  //     state.currentMonthIndex === dayjs().month()
  //       ? state.currentMonthIndex + Math.random()
  //       : dayjs().month();
  //   setState({
  //     ...state,
  //     todayClicked: true,
  //     selectedDay: undefined,
  //     currentMonthIndex: monthChosen,
  //   });
  // }

  // function getUpcomingDays() {
  //   let arr = [];
  //   const today = new Date(); // shows today formatted

  //   //sorts the array by ascending order, filters out days which have happened,
  //   // and leaves only the remaining 3 days with events on them
    
  //   let eventArray = Object.entries(state.monthObjects)
  //     .sort((a, b) => new Date(a[0]) - new Date(b[0]))
  //     .filter(([date, eventData]) => new Date(date) >= today)


  //   for (const [date, eventData] of eventArray) {
  //     for (let event of eventData.agenda) {
  //       event.date = date
  //       arr.push(event);
  //       if (arr.length == 5) {
  //         return arr;
  //       }
  //     }
  //   }
  //   return arr;
  // }

  // useEffect(() => {
  //   // Redirect user to login if data is null
  //   if (global.user_data === undefined) {
  //     navigation.navigate('Login');
  //   }

  //   // Wait for profile pictures to be updated in other files
  //   const getData = async () => {
  //     const storedProfilePic = await AsyncStorage.getItem('profilePic');
  //     const storedName = await AsyncStorage.getItem('name');
  //     let updatedLabels = {
  //       Mandatory: true,
  //       Optional: true,
  //       Personal: true,
  //     };
  //     await setState((prevState) => ({
  //       ...prevState,
  //       labels: updatedLabels,
  //     }));
  //     setProfilePic(storedProfilePic || '');
  //     setName(storedName || '');
  //   };

  //   // Get data when user enters page
  //   if (isFocused) {
  //     getData();

  //   }
  // }, [isFocused]);

  // useEffect(() => {
  //   const updatedState = {
  //     ...state,
  //     currentMonthIndex: state.selectedMonth !== undefined ? state.selectedMonth : state.currentMonthIndex,
  //     selectedMonth: undefined,
  //     currentMonth: getMonth(state.currentMonthIndex),
  //     showEventModal: state.showEventModal,
  //     selectedDay: state.selectedDay,
  //     todayClicked: false,
  //   };
  //   setState(updatedState);
  // }, [state.selectedMonth, state.currentMonthIndex, state.selectedDay, setState]);

  // useEffect(() => {
  //   if (state.events.length === 0 || state.dotObjects.length === 0 || state.monthObjects.length === 0) {
  //     requestCalendarEvents(state, setState);
  //   }

  // }, [global.user_id]);

  // useEffect(() => {

  //   setUpcomingEvents(getUpcomingDays());
  // }, [state.monthObjects]);

  useEffect(() => {
    // Use effect which on log in makes it update with the permisisons of the person which just logged in.
    if (global.user_data) {
      const updatedState = {
        ...navState,
        origTab:2,
        openTab:null,
        mainMenuTab: 2,
        subMenuTab:null,
        subMenuOptionTab: null,
        canSeePayManagement: global.user_data.permissions?.includes(34),
        canEditPayManagement: global.user_data.permissions?.includes(33),
        canSeeLeadersDash: global.user_data.permissions?.includes(36),
        canEditLeadersDash: global.user_data.permissions?.includes(35),
        canSeeAdminConsole: checkIfAdmin(),
        canSeeReview: checkIfApprover(),
        canSeeRecommend: global.user_data.permissions?.includes(28),
        isSuperUser:global.user_data.role?.includes('SUPERUSER'),
        canSeeUnitInfo: checkIfAdmin(),
        canSeeRoles: checkIfAdmin(),
        canSeeUsers: global.user_data.permissions?.includes(27),
        canUploadUnitDocuments: global.user_data.permissions?.includes(37),
        canUploadBaDates: global.user_data.permissions?.includes(39)
      };
      setNavState(updatedState);
    }
  },[isFocused])

  
  async function getFiles() {
    setFiles([]);

    const files = await get_all_user_files(
      global.user_data.user_id,
      global.token
    );

    if (files.status === 200) {
      const parsedFiles = files.data.body
        .map((file) => ({
          ...file,
          parsedDate: getFileDate(file),
        }))
        .sort((a, b) => b.form_id - a.form_id);

      setFiles(parsedFiles.slice(0, 5));
      console.log(parsedFiles.slice(0, 5))
    } else {
      showSnackbar("error", "Application Error: Failed to retrieve accounts recent forms");
    }
  }

  useEffect(() => {
    async function getPageInfo() {
      await getFiles();
    }
  
    getPageInfo();
    setLoaded(true);
  }, []);

  async function acknowledge() {
    setmodalVis(false);
    await AsyncStorage.setItem('modalVis', JSON.stringify(false));
  }

  const loadingStyles = {
    animation: {
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
        justifyContent: 'center',
        paddingVertical: 20,
        background: colors.screen_background,
    }
  }

  if (!loaded) {
    return (
      <View style={loadingStyles.animation}>
          <Progress.CircleSnail
            color={colors.loading_circle}
            indeterminate={true}
            size={275}
            thickness={8}
          />
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <View style={styles.wrapper}>

        {/** USAR Acknowledgement Modal */}
        <Modal isVisible={modalVis}>
          <View style={styles.modal}>
            <Image source={image} style={styles.image} />
            <Text style={styles.modalText}>
              This is an official communication application of the United States Army Reserve
              and may contain Controlled Unclassified Information(CUI). Unauthorized use of
              this application may be considered a violation of federal law and the Uniform Code of
              Military Justice (UCMJ).
            </Text>
            <View style={styles.modalButton}>
              <TouchableOpacity style={styles.buttonStyle} color="#0d1232" onPress={() => acknowledge()}>
                <Text style={styles.buttonText}>I Acknowledge</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>

        {/** Side Bar */}
        {/* <NavSideBar /> */}
        <View style={styles.container}>

          {/** Main Page */}
          <View style={styles.intro}>
            <Text style={styles.commsApp}>Welcome to Army Reserve Mercury</Text>
          </View>

          {/** Carousel */}
          <View style={styles.main}>
            <Carousel
              loop
              width={width * 0.56}
              height={width * 0.4}
              mode="parallax"
              autoPlay={true}
              autoPlayInterval={9000}
              style={{ alignSelf: "center", justifyContent: "center", alignContent: "center" }}
              data={images}
              scrollAnimationDuration={1000}
              renderItem={({ index }) => (
                <View style={styles.carousel}>
                  <Image style={styles.carouselImage} source={images[index]} />
                  <Text style={styles.carouselText}>{quotes[index]}</Text>
                </View>
              )}
            />
          </View>
        </View>
        {/* Calendar and events tab (commented out because sponsors wanted functionality hidden will be returned*/}
        {/* <View style={styles.calendar.calContainer}>
          <View style={{ flexDirection: 'column', height: '100%' }}>
            <View style={styles.calendar.smallCalendarWrapper}>
              <Text style={styles.calendar.title}>Calendar</Text>
              <TouchableOpacity style={styles.createEventButton} onPress={handleReset}>
                <Text style={styles.todayText}>Today</Text>
              </TouchableOpacity>

              {(global.user_data.permissions.includes(2051) || global.user_data.permissions.includes(2052)) &&
                <TouchableOpacity
                  onPress={() => {
                    console.log('Button Pressed');
                    setState({ ...state, showEventModal: true });
                    navigation.navigate("Calendar");
                  }}
                  style={[styles.createEventButton]}
                >
                  <Text style={styles.todayText}>Add</Text>

                  <Add style={styles.buttonText}></Add>
                </TouchableOpacity>}

            </View>
            <TouchableOpacity style={styles.calendar.smallCalendar} onPress={() => { navigation.navigate("Calendar"); }}>
              <SmallCalendar state={state} setState={setState} markedDatesObj={[]} />
            </ TouchableOpacity>
            {upcomingEvents.length === 0 ? (
              <Scrollbars style={{ flex: 1 }}>
                <View style={{ alignItems: 'center', backgroundColor: colors.navSideBar_Background, borderRadius: 25 }}>
                  <Text style={styles.eventTitleText}>No upcoming events!</Text>
                </View>
              </Scrollbars>
            ) : (
              <Scrollbars style={styles.calendar.events}>
                {upcomingEvents.map((event) => (
                  <View style={styles.calendar.inidivEvent}>
                    {event.endDate!==null &&  event.endDate.diff(event.startDate) >= 24 * 60 * 60 * 1000 ? (
                     <Text style={styles.eventTitleText}>{event.time}</Text>
                    ) : (
                      <Text style={styles.eventTitleText}>
                      {event.title} on {event.date} at {event.time === undefined ? "00:00" : event.time}
                    </Text>
                      
                    )}


                    <Text style={styles.eventBodyText}>Location: {event.location}</Text>
                    <Text style={styles.eventBodyText}>Type: {event.type}</Text>
                    <Text style={styles.eventBodyText}>Remarks: {event.remarks}</Text>
                  </View>)
                )}
              </Scrollbars>
            )} */}
          { files.length !== 0 ? (
            <>
            <View style={styles.recentFormStatus.recentFormsContainer}>
              <View style={{ flexDirection: 'column', height: '100%' }}>
                <Text style={styles.recentFormStatus.recentFormsTitle}>Recent Form Status</Text>
                  <Scrollbars className="scrollContainer" style={styles.recentFormStatus.formsContainer} autoHide autoHideTimeout={0} autoHideDuration={0}>
                    {
                      files.map((file) => (
                        <View key={file.form_id}>
                          <FileSelect
                            key={file.form_id}
                            id={file.form_id}
                            comment={getCommentForFormType(file)}
                            name={file.form_name}
                            date={file.timestamp}
                            excusedAbsenceBADate={file.excused_absence_ba_date}
                            rstPerformedOn={file.rst_performed_on}
                            rmaDate={file.rma_date}
                            ataDate={file.ata_date}
                            selected={selected}
                            status={file.status}
                            style={styles.item}
                            filename={file.form_name}
                            filetype={file.form_type}
                            navigation={navigation}
                            requestType={file.request_type}
                            timestamp_msg={file.timestamp_string}
                            attachments={file.attachments}
                            date_9a={file.date_9a}
                            ata_dates={file.ata_dates}
                            ebdl_dates={file.ebdl_dates}
                            mdr_dates={file.mdr_dates}
                          />
                        </View>
                      ))
                    }
                  </Scrollbars>
                </View>
              </View>
            </>
          ): null}
      </View>
    </View>
  );

  function FileSelect({
    id,
    filetype,
    filename,
    requestType,
    date,
    comment,
    timestamp_msg,
    status,
    navigation,
    attachments,
    excusedAbsenceBADate,
    rstPerformedOn,
    rmaDate,
    ataDate,
    date_9a,
    ata_dates,
    ebdl_dates,
    mdr_dates,
  }) {
    const docs = {
      0: require("../assets/doc_blue.png"),
      1: require("../assets/doc_yellow.png"),
      2: require("../assets/approved_rec_doc.png"),
      3: require("../assets/disapproved_rec_doc.png"),
      4: require("../assets/doc_green.png"),
      5: require("../assets/doc_red.png"),
      6: require("../assets/doc_paid.png"),
      7: require("../assets/corrupted_doc.png"),
    };

    const status_names = {
      0: 'Drafted',
      1: 'Submitted',
      2: 'Awaiting Review',
      3: 'Must Resubmit',
      4: 'Approved',
      5: 'Denied',
      6: 'Paid',
    };

    let statusDate = formatDateToMilDate(new Date(date[0]).toLocaleDateString());
    let displayDate;

    if (filetype.includes("1380")) {
      if (date_9a) {
        displayDate = date_9a
          .map((date) => moment(new Date(date)).format("DDMMMYYYY").toUpperCase())
          .join(", ");
      } else {
        displayDate = "Invalid Date";
      }
    }

    if (filetype.includes("RST")) {
      if (excusedAbsenceBADate === '' && rmaDate === '' && ataDate === '') {
        const dates = rstPerformedOn.split(', ').map((dateStr) => {
          // Extract the date part "DDMMMYY"
          const match = dateStr.match(/\d{2}[A-Z]{3}\d{2}/);
          if (match) {
            const datePart = match[0];
            // Pass the date part to formatRstDisplayDate and convert to moment object
            const formattedDate = moment(formatRstDisplayDate(datePart), 'DDMMMYYYY').format('DDMMMYYYY').toUpperCase();
            // Reconstruct the date string with the formatted date and quantity
            return formattedDate;
          } else {
            // If the date format is not matched, return the original date string
            return dateStr;
          }
        });
        displayDate = dates.join(', ');
      } else {
        displayDate = moment(formatRstDisplayDate(excusedAbsenceBADate || rmaDate || ataDate), 'DDMMMYYYY').format('DDMMMYYYY').toUpperCase();
      }
    } else {
      displayDate = moment(new Date(date[date.length - 1]))
        .format("DDMMMYYYY")
        .toUpperCase();
    }

    if (filetype.includes("ATA")) {
      displayDate = ata_dates;
    }

    if (filetype.includes("EBDL")) {
      displayDate = ebdl_dates;
    }

    if (filetype.includes("MDR")) {
      displayDate = mdr_dates;
    }

    if (filetype.includes("dental")) {
      displayDate = statusDate
    }

    let statusTextColor = colors.default;
    if (status_names[status] === 'Submitted') {
      statusTextColor = colors.file_Submitted;
    } else if (status_names[status] === 'Awaiting Review') {
      statusTextColor = colors.file_Awaiting_Review;
    } else if (status_names[status] === 'Must Resubmit') {
      statusTextColor = colors.file_Must_Resubmit;
    } else if (status_names[status] === 'Approved') {
      statusTextColor = colors.file_Approved;
    } else if (status_names[status] === 'Denied') {
      statusTextColor = colors.file_Denied;
    } else if (status_names[status] === 'Paid') {
      statusTextColor = colors.file_Paid;
    }

    const formatDisplayDate = (displayDate) => {
      if (displayDate === "Invalid Date") {
        return displayDate;
      }
      
      return displayDate
        .split(", ")
        .map((date, index) => {
          if (index === 0) {
            return date;
          } else if ((index + 1) % 3 === 0) {
            return `\n${date}`;
          } else {
            return `, ${date}`;
          }
        })
        .join("");
    };
    
    return (
      <View style={styles.fileContainer}>
        <TouchableOpacity
          style={[
            styles.button,
            {
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            },
            id === selected ? styles.selected : styles.notSelected,
          ]}
          onPress={() => {
            setSelected(id);
            console.log(id, filetype, navigation, attachments, global.user_data.user_id)
            setLoaded(false);
            generatePDF(
              id,
              filetype,
              navigation,
              false,
              false,
              attachments,
              global.user_data.user_id
            );
            setLoaded(true);
            setTimeout(() => { setSelected(-1); }, 1000);
          }}
        >
          <View style={styles.docContainer}>
            <Image
              style={styles.doc}
              defaultSource={docs[displayDate === "Invalid Date" ? 7 : status]}
            />
          </View>
          <View style={styles.textContainer}>
            <Text style={styles.textStyle}>
              {`${filename} (${id})\n${requestType}\nDate(s): ${formatDisplayDate(displayDate)}`}
            </Text>
            <Text style={[styles.textStyle, { color: statusTextColor }]}>
              {`Status: ${status_names[displayDate === "Invalid Date" ? 7 : status]} ${statusDate}`}
            </Text>
            {comment && (
              <Text style={styles.commentStyle}>
                {`Comment: ${comment}`}
              </Text>
            )}
          </View>
          <AntDesign name="right" size={14} color={colors.bow} />
        </TouchableOpacity>
      </View>
    );
  }
};

export default Home;