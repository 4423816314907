import React from "react";
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import { useColorContext } from "../../../components/ColorContext";

const LieuDateSelect = ({
  index,
  styles,
  value,
  onChange,
  error,
  options,
  onFocus,
  onBlur,
  width,
  GetSortOrder
}) => {
  const { colors } = useColorContext();

  const sortedOptions = options.sort(GetSortOrder('label'));

  const handleFocus = () => {
    onFocus();
  };

  const handleBlur = () => {
    onBlur(value);
  };

  return (
    <FormControl
      fullWidth
      style={{ ...styles.dropDownStyle, width: width > 1300 ? "72%" : "100%", marginTop: "0%" }}
      sx={{
        ...styles.textInputProps,
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: error ? "red" : colors.border_color,
          },
          "&:hover fieldset": {
            borderColor: error ? "red" : colors.border_color,
          },
          "&.Mui-focused fieldset": {
            borderColor: error ? "red" : colors.border_color,
          },
        },
      }}
    >
      <InputLabel
        id={`lieuDateSelect-${index}`}
        style={{ color: colors.inputted_text }}
      >
        BA Date
      </InputLabel>
      <Select
        value={value}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        label="LieuBA"
      >
        {sortedOptions.length === 0 ? (
          <MenuItem value="No Options" disabled>
            No Options
          </MenuItem>
        ) : (
          sortedOptions.map((option) => (
            <MenuItem key={option.label} value={option.label}>
              {option.label}
            </MenuItem>
          ))
        )}
      </Select>
    </FormControl>
  );
};

export default LieuDateSelect;
