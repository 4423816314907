import React, { useState, useEffect } from "react";
import { View } from "react-native";
import MonthInputGrid from "./MonthInputGrid";
import { get_budget, get_target_lines } from "../../api_handler/budget";
import { getFiscalYear } from "../../utils/monthInfo";

// Make this a swipeup modal.
function EditTargetLinePopup({ closePopup, subtype, pot }) {
    const [budgetInfo, setBudgetInfo] = useState({});
    const [targetLine, setTargetLine] = useState([]);
    const [targetLoaded, setTargetLoaded] = useState([]);

    // WHen subtype and pot change, get new budget.
    useEffect(() => {
        async function getBudget() {
            const data = {
                "unit_id": global.user_data.unit_id,
                "pot": pot,
                "subtype": subtype,
                "fiscal_year": getFiscalYear(),
            }

            const results = await get_budget(data, global.token)

            if (results?.status === 200)
                setBudgetInfo(results.data)
            else
                setBudgetInfo({budget_total: 0})
        }

        async function getTargetLine() {
            setTargetLoaded(false)
            const data = {
                "unit_id": global.user_data.unit_id,
                "pot": pot,
                "subtype": subtype,
                "fiscal_year": getFiscalYear(),
            }

            const results = await get_target_lines(data, global.token)
            // console.log(JSON.stringify(results, null, 2));

            if (results?.status === 200) {
                setTargetLine(results?.data?.body?.[subtype.toUpperCase()])
                setTargetLoaded(true)
            }
            else
                setTargetLine([])
        }

        getBudget()
        getTargetLine()
    }, [subtype, pot])

    const styles = {
        container: {
            width: '90%',
            flex: 1,
        }
    }

    return (
        <View style={styles.container}>
            <MonthInputGrid
                budget={budgetInfo?.budget_total} 
                closePopup={closePopup} 
                subtype={subtype} 
                pot={pot}
                targetLine={targetLine}/>
        </View>
    );
}

export default EditTargetLinePopup;
