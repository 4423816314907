import React, {useState} from 'react';


import {useScale} from '../components/scale';
import CustomHeader from '../components/header';
import { useColorContext} from '../components/ColorContext';
import NavSideBar from '../components/NavSideBar';

import {
  Text,
  View,
} from 'react-native';
import Scrollbars from "react-custom-scrollbars";


function UserManual({navigation}) {
  const {colors} = useColorContext();
  const [refresh, setRefresh] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState(null);
  const [subject, typeSubject] = useState('');
  const [body, typeBody] = useState('');

  const scale = useScale();
  const styles = ({

    container: {
      flex: 1,
      backgroundColor:  colors.screen_background ,
    },
    wrapper: {
      flexDirection: 'row',
      height: '100%'
    },
    list: {
      width: '75%',
    },
    title: {
      fontSize: scale(30),
      fontFamily: 'Trebuchet MS',
      flex: 1,
      color: colors.text,
      paddingLeft: '5%',
      paddingTop: 25
    },
    button: {
      marginTop: 2,
    },
    header: {
      backgroundColor: 'pink',
      height: '15%',
    },
    main: {
      height: '100%',
      alignItems: 'center',
    },
    welcome: {

    },
    item: {
      backgroundColor: 'orange',
      padding: 20,
      marginVertical: 8,
      marginHorizontal: 16,
      borderRadius: 8,
      shadowColor: '#000',
      shadowOffset: {width: 3, height: 3},
      shadowOpacity: 0.3,
      shadowRadius: 8,
    },
    helpBtn: {
      width: '40%',
      backgroundColor: '#0d1232',
      borderRadius: '40',
      alignItems: 'center',
      color: 'white',
      borderRadius: 15,
      shadowColor: '#171717',
      shadowOffset: {width: -2, height: 4},
      shadowOpacity: 0.2,
      shadowRadius: 3,
      marginTop: 30,
      marginBottom: 30,
    },
    innerText: {
      color:  colors.button_text,
      fontWeight: 'bold',
      padding: 10,
    },
  });

  return (
    <View style = {styles.container}>
      <View style = {styles.wrapper}>
      {/* <NavSideBar /> */}
      <Scrollbars style = {styles.main}>
        <iframe src="https://drive.google.com/file/d/1UEDe_iQjQ2VTMlxizdbFGx0PfK7T4X2t/preview" width="100%" height="100%" allow="autoplay"></iframe>
      </Scrollbars>
    </View>
    </View>
  );
};


export default UserManual;
