import React, { useState, useContext, useEffect } from 'react';
import { View, Text, TouchableOpacity, Modal} from 'react-native';
import { budgetOverviewStyles, lightModeStyles, darkModeStyles } from './styles'; // Import styles
import CreateBudget from './CreateBudget';
import EditBudget from './EditBudget';
import AlertThreshold from './AlertThreshold';
import ModeContext from "../ModeContext";
import { delete_budget } from "../../api_handler/budget";
import { get_budget } from "../../api_handler/budget";
import { getFiscalYear } from "../../utils/monthInfo";
import { useNavBarContext } from '../NavBarContext';
import { useSnackbar } from '../SnackbarContext';

const BudgetOverview = ({ budget, unitId, budgetGroupButton, token, activeChartButton, refreshStats, handleRefreshStats}) => {
  const { navState } = useNavBarContext();
  const [isCreateVisible, setCreateVisible] = useState(false);
  const [isEditVisible, setEditVisible] = useState(false);
  const [isDeleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
  const [isAlertThresholdVisible, setAlertThresholdVisible] = useState(false); // New state for the AlertThreshold modal
  const { mode, toggleMode } = useContext(ModeContext);
  const [allBudgets, setAllBudgets] = useState(null);
  const [hasBudget, setHasBudget] = useState(false);
  const [refresh, toggleRefresh] = useState(false);
  const { showSnackbar } = useSnackbar();
  const [hasPermission, setHasPermission] = React.useState(navState.canEditLeadersDash);

  useEffect(() => {
    if (budget !== null || hasBudget == true)
    {
      setHasBudget(true);
    }
      const fetchAllBudgets = async () => {
      try {
        const fetchedBudgets = await readBudgetForAllADASubtypes(budgetGroupButton, token);
        setAllBudgets(fetchedBudgets);
      } catch (error) {
        console.log(error);
      }
    }
    fetchAllBudgets();
    }, [budget, refresh]);

  const handleCreate = (newBudgetData) => {

    setHasBudget(true);
    handleRefreshStats();
    setCreateVisible(false);
  };

  const handleEdit = (editedBudget) => {
    toggleRefresh(!refresh);
    handleRefreshStats();
    setEditVisible(false);
  };

  const readBudgetForAllADASubtypes = async (pot, token) => {
    const subtypes = ["ALL", "AFTP", "MDR", "RMA", "EBDL"]; // ADA subtypes
    const budgets = {};
  
    // Loop through each subtype and fetch its budget information
    for (const subtype of subtypes) {
      const readData = {
        "pot": pot,
        "subtype": subtype,
        "unit_id": unitId,
        "fiscal_year": getFiscalYear(),
      };

      const response = await get_budget(readData, token);
      if (response != null)
      {
        if (response.status === 200) {
          budgets[subtype] = response.data.budget_total;
        } else {
          budgets[subtype] = 0; // Set default value to 0
        }
      }
      else
      {
        budgets[subtype] = 0;
      }
    }
  
    return budgets;
  };

  const handleCancelCreate = () => {
    setCreateVisible(false);
  };

  const handleCancelEdit = () => {
    setEditVisible(false);
  };

  const handleDelete = () => {
    setDeleteConfirmationVisible(true);
  };

  const confirmDelete = () => {
    reallyDelete();
    // Change buttons
    setHasBudget(false);

    // Get new budget stats after deleting
    handleRefreshStats();
  }
  const reallyDelete = async () => {

    try
    {
      const response = await delete_budget({ "unit_id": unitId, "subtype": activeChartButton, "pot": budgetGroupButton, "fiscal_year": getFiscalYear() }, token);
      setDeleteConfirmationVisible(false);

      await new Promise(resolve => setTimeout(resolve, 300));
      
      if (response?.status === 200) {
        showSnackbar("success", "Budget Successfully Deleted", 'top', 'right');
      } else {
        showSnackbar("error", `Error Deleting Budget: ${response?.data?.body}`, 'top', 'right');
      }

      // Reset stats
      handleRefreshStats();
    } 
    catch (error) {
        console.error('Error deleting budget:', error.message);
    }

};

  const cancelDelete = () => {
    setDeleteConfirmationVisible(false);
  };

  const handleAlertThreshold = () => {
    setAlertThresholdVisible(true);
  };

  const handleCancelAlertThreshold = () => {
    setAlertThresholdVisible(false);
  };

  const styles = mode === 'light' ? lightModeStyles : darkModeStyles;

  if (!hasBudget) {
    return (
      <View style={[budgetOverviewStyles, styles.container]}>
        {hasPermission && (
          <View>
            <Text style={[styles.text, { fontWeight: 'bold', textAlign: 'center' }]}>
              <span style={{ color:'#888484'}}>Please create budget for this budget type!</span>
            </Text>
            <TouchableOpacity
              style={[budgetOverviewStyles.createButton, { marginTop: 10 }]}
              onPress={() => setCreateVisible(true)}
            >
              <Text style={{ color: 'white', fontWeight: 'bold', fontFamily: 'Trebuchet MS', fontSize: '16px' }}>Create Budget</Text>
            </TouchableOpacity>
          </View>
        )}
        
        {/* CreateBudget Modal */}
        <CreateBudget
          isVisible={isCreateVisible}
          onCancel={handleCancelCreate}
          onCreate={handleCreate}
          unitId={unitId}
          budgetGroupButton={budgetGroupButton}
          token={token}
          allBudgets={allBudgets}
        />
      </View>
    );
  }
  else
  {
    return (
      <View style={[budgetOverviewStyles.container, styles.container]}>

        {hasPermission && (
          <View style={budgetOverviewStyles.buttonContainer}> 
            <TouchableOpacity
              style={[budgetOverviewStyles.editButton, { alignItems: 'center' }]}
              onPress={() => setEditVisible(true)}
            >
              <Text style={{ fontWeight: 'bold', color: 'white' }}>Edit</Text>
            </TouchableOpacity>
            
            <TouchableOpacity
              style={[budgetOverviewStyles.deleteButton, { alignItems: 'center' }]}
              onPress={handleDelete}
            >
              <Text style={{ fontWeight: 'bold', color: 'white' }}>Delete</Text>
            </TouchableOpacity>
          </View>
        )}
  
        {/* EditBudget Modal */}
        <EditBudget
          isVisible={isEditVisible}
          onCancel={handleCancelEdit}
          onEdit={handleEdit}
          budgetGroupButton={budgetGroupButton}
          activeChartButton={activeChartButton}
          token={token}
          allBudgets={allBudgets}
          unitId={unitId}
        />
  
        {/* Delete Confirmation Modal */}
        <Modal
          animationType="slide"
          transparent={true}
          visible={isDeleteConfirmationVisible}
        >
          <View style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 22,
          }}>
            <View style={{
              backgroundColor: mode === 'light' ? '#f0ecec' : '#201c1c',
              borderRadius: 10,
              padding: 20,
              alignItems: 'center',
              height: 150,
              width: 300,
              shadowColor: 'black',
              shadowOffset: {
                width: 0,
                height: 2,
              },
              shadowOpacity: 0.55,
              shadowRadius: 6,
              elevation: 6,
            }}>
              <Text style={[styles.text, { fontWeight: 'bold', padding: '2px', textAlign: 'center' }]}>Are you sure you want to delete budget {activeChartButton}?</Text>
              <View style={budgetOverviewStyles.buttonContainer}>
                <TouchableOpacity
                  style={[budgetOverviewStyles.editButton, { alignItems: 'center', padding: '10px' }]}
                  onPress={confirmDelete}
                >
                  <Text style={{ fontWeight: 'bold', color: 'white' }}>Yes</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={[budgetOverviewStyles.deleteButton, { alignItems: 'center', padding: '10px' }]}
                  onPress={cancelDelete}
                >
                  <Text style={{ fontWeight: 'bold', color: 'white' }}>No</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </Modal>
  
        {/* AlertThreshold Modal */}
        <AlertThreshold
          isVisible={isAlertThresholdVisible}
          onCancel={handleCancelAlertThreshold}
          onSave={null} /* handle the logic for saving the alert threshold */
        />
      </View>
    );
  }  
};

export default BudgetOverview;