/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable linebreak-style */
import React, {useState, useRef, useEffect} from 'react';
import {View, StyleSheet} from 'react-native';
import {LocalizationProvider, DatePicker} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {
  ThemeProvider,
  ClickAwayListener,
  FormControl,
} from '@mui/material';
import CheckboxList from '../CheckboxList';
import moment from 'moment';
import {useSnackbar} from '../../../components/SnackbarContext';
import {useFocusEffect} from '@react-navigation/native';

const TableCellComponent = ({
  navigation,
  rows,
  dateObj,
  setRows,
  onDateTimeChange,
  deleteEntry,
  reviewTheme,
  colors,
  styles,
  toBePerformedOnDates,
  setToBePerformedOnDates,
  highlightedRows,
  index,
  setHighlightedRows,
  width,
  handleToBePerformedOnCheckboxChange
}) => {
  const {showSnackbar} = useSnackbar();
  const datePickerRef = useRef(null);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedMuta, setSelectedMuta] = useState(null);

  useEffect(() => {
    // Initialize selectedDate with the toBePerformedOn date from the row
    if (rows[index]?.toBePerformedOn?.value) {
      setSelectedDate(moment(rows[index].toBePerformedOn.value));
    }
  }, [rows, index]);

  useFocusEffect(
      React.useCallback(() => {
      // This effect is used to prevent navigation instantly if date picker is open
        const beforeRemoveListener = (e) => {
          if (!datePickerOpen) {
            return;
          }

          e.preventDefault();

          setDatePickerOpen(false);
          setTimeout(() => {
            navigation.dispatch(e.data.action);
          }, 200);
        };

        navigation.addListener('beforeRemove', beforeRemoveListener);

        return () => {
          navigation.removeListener('beforeRemove', beforeRemoveListener);
        };
      }, [datePickerOpen])
  );

  // Function is used to determine whether the inputted date is valid based on our conditions
  const handleDatePickerBlur = () => {
    // Check if a date is selected
    if (selectedDate) {
      // Format the selected date to match the required label format
      const selectedLabel = moment(selectedDate.$d).format('DDMMMYYYY').toUpperCase();
  
      const currentDateObject = rows[index].toBePerformedOn;

      // Check if the selected date is already chosen in other rows
      const isDateAlreadyChosen = toBePerformedOnDates.some(
          (date) =>
            date.label === selectedLabel &&
          date.rowId !== rows[index].id &&
          date.MUTA === selectedMuta
      );
      

      // If the selected date is already chosen, highlight the error and show an alert
      if (isDateAlreadyChosen) {
        setHighlightedRows((prevState) => {
          const updatedHighlightedRows = [...prevState];
          updatedHighlightedRows[index] = {
            ...updatedHighlightedRows[index],
            toBePerformedOnEmpty: true,
          };
          return updatedHighlightedRows;
        });

        // Delay the snackbar to avoid it being dismissed by the input blur event
        setTimeout(() => {
          showSnackbar('error', 'Date already chosen');
        }, 250);
        return;
      }
  
      // If the selected date is the same as the current date object, clear any errors
      if (currentDateObject && currentDateObject.label === selectedLabel) {
        setHighlightedRows((prevState) => {
          const updatedHighlightedRows = [...prevState];
          updatedHighlightedRows[index] = {
            ...updatedHighlightedRows[index],
            toBePerformedOnEmpty: false,
          };
          return updatedHighlightedRows;
        });
        return;
      }
    }
  
    // Update the highlighted rows to indicate if the toBePerformedOn date is invalid
    setHighlightedRows((prevState) => {
      const updatedHighlightedRows = [...prevState];
      updatedHighlightedRows[index] = {
        ...updatedHighlightedRows[index],
        toBePerformedOnEmpty: !selectedDate,
      };
      return updatedHighlightedRows;
    });
  };

  const handleDatePickerFocus = () => {
    // Reset the error state for the specific row
    setHighlightedRows((prevState) => {
      const updatedHighlightedRows = [...prevState];
      updatedHighlightedRows[index] = {
        ...updatedHighlightedRows[index],
        toBePerformedOnEmpty: false,
      };
      return updatedHighlightedRows;
    });
  };

  const handleCheckboxChange = (value) => {
    // Set the selected MUTA value in the state
    setSelectedMuta(value);

    // Format the selected date to match the required format
    const formattedSelectedDate = moment(selectedDate.$d).format('DDMMMYY').toUpperCase();

    // Update the MUTA value for the corresponding date in the toBePerformedOnDates state
    const updatedToBePerformedOnDates = toBePerformedOnDates.map((dateObj) => {
      if (dateObj.value === formattedSelectedDate) {
        return {...dateObj, MUTA: value};
      }
      return dateObj;
    });
    setToBePerformedOnDates(updatedToBePerformedOnDates);

    // Update the MUTA value in the toBePerformedOn object for the corresponding row
    const updatedRows = rows.map((row) => {
      if (
        row.toBePerformedOn &&
        row.toBePerformedOn.value === formattedSelectedDate
      ) {
        return {
          ...row,
          toBePerformedOn: {
            ...row.toBePerformedOn,
            MUTA: value,
          },
        };
      }
      return row;
    });
    setRows(updatedRows);

    // Update the toBePerformedOnMUTA error state to indicate no error
    setHighlightedRows((prevState) => {
      const updatedHighlightedRows = [...prevState];
      updatedHighlightedRows[index] = {
        ...updatedHighlightedRows[index],
        toBePerformedOnMUTA: false,
      };
      return updatedHighlightedRows;
    });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    onDateTimeChange(date, dateObj.id, index);
    setDatePickerOpen(false);
  };

  return (
    <View
      style={{
        flexDirection: width > 1300 ? 'row' : 'column',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <FormControl fullWidth style={{width: width > 1300 ? '72%' : '100%'}}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ThemeProvider theme={reviewTheme}>
            <ClickAwayListener
              onClickAway={(event) => {
                if (
                  datePickerRef.current &&
                  !datePickerRef.current.contains(event.target)
                ) {
                  setDatePickerOpen(false);
                }
              }}
              mouseEvent="onMouseDown"
              disableRestoreFocus={true}
            >
              <DatePicker
                sx={{
                  ...styles.rmaDateBox,
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: highlightedRows[index]?.toBePerformedOnEmpty
                        ? 'red'
                        : colors.border_color,
                      color: colors.inputted_text,
                    },
                    '&:hover fieldset': {
                      borderColor: highlightedRows[index]?.toBePerformedOnEmpty
                        ? 'red'
                        : colors.border_color,
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: highlightedRows[index]?.toBePerformedOnEmpty
                        ? 'red'
                        : colors.border_color,
                    },
                  },
                }}
                label="Performed On*"
                value={selectedDate}
                onChange={handleDateChange}
                dateFormat="MM/dd/yyyy"
                onClose={() => setDatePickerOpen(false)}
                onOpen={() => setDatePickerOpen(true)}
                open={datePickerOpen}
                TextFieldComponent={(props) => (
                  <TextField
                    {...props}
                    error={highlightedRows[index]?.toBePerformedOnEmpty}
                    style={{
                      borderColor: highlightedRows[index]?.toBePerformedOnEmpty
                        ? 'red'
                        : 'initial',
                      color: colors.inputted_text,
                    }}
                  />
                )}
                slotProps={{
                  textField: {
                    onBlur: handleDatePickerBlur,
                    // onFocus: handleDatePickerFocus,
                  },
                }}
                PopperProps={{
                  modifiers: [
                    {
                      name: 'eventListeners',
                      options: {
                        scroll: false,
                        resize: true,
                      },
                    },
                  ],
                  ref: (popperRef) => {
                    if (popperRef && popperRef.node) {
                      popperRef.node.id = 'calendar-element';
                      datePickerRef.current = popperRef.node.querySelector('.MuiCalendarPicker-root');
                    }
                  },
                }}
              />
            </ClickAwayListener>
          </ThemeProvider>
        </LocalizationProvider>
      </FormControl>
      {/* This is here to determine whether we show the MUTA checkboxes if the date has been selected and is a valid date*/}
      {selectedDate &&
      !isNaN(new Date(selectedDate).getTime()) &&
      !highlightedRows[index]?.toBePerformedOnEmpty ? (
        <CheckboxList
          items={[1, 2]}
          row={dateObj}
          onItemSelect={handleToBePerformedOnCheckboxChange}
          index={dateObj.label}
          selectedValue={dateObj?.toBePerformedOn.MUTA}
          error={highlightedRows[index]?.toBePerformedOnMUTA}
          width={width}
        />
      ) : null}
    </View>
  );
};

export default TableCellComponent;
