
/* eslint-disable indent */
/* eslint-disable no-trailing-spaces */
/* eslint-disable arrow-parens */
/* eslint-disable react/prop-types */
/* eslint-disable quotes */
/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable linebreak-style */
/* eslint-disable require-jsdoc */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState, useRef } from 'react';
import { View, Text, TouchableOpacity, ScrollView } from 'react-native';

import { useScale } from '../../../components/scale';
import CustomHeader from '../../../components/adminHeader';
import { ClickAwayListener } from '@mui/base';

import { useIsFocused } from '@react-navigation/native';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import { add_user, get_all_superior_users } from '../../../api_handler/users';
import { get_roles, get_permissions } from '../../../api_handler/roles';
import { get_all_unit_names, get_table_unit } from '../../../api_handler/units';

import ModeContext from '../../../components/ModeContext';
import { useColorContext } from '../../../components/ColorContext';
import NavSideBar from '../../../components/NavSideBar';
import Scrollbars from 'react-custom-scrollbars';
import { useSnackbar } from '../../../components/SnackbarContext';
import { useNavBarContext } from '../../../components/NavBarContext';
import * as Progress from 'react-native-progress'
import { Menu } from 'react-native-paper';

function AddUser({ navigation }) {
  const [loaded, setLoaded] = useState(true);
  const [firstName, setfirstName] = React.useState('');
  const [firstNameEmptyError, setfirstNameEmptyError] = useState(false);

  const [middleName, setmiddleName] = React.useState('');

  const [lastName, setlastName] = React.useState('');
  const [lastNameEmptyError, setlastNameEmptyError] = useState(false);

  const [email, setEmail] = React.useState('');
  const [emailEmptyError, setEmailEmptyError] = useState(false);
  const [emailInvalidError, setEmailInvalidError] = useState(false);
  const [emailArmyError, setEmailArmyError] = useState(false);
  const [emailExistsError, setEmailExistsError] = useState(false);
  const [emailCognitoError, setEmailCognitoError] = useState(false);

  const [phone, setPhone] = React.useState('');
  const [phoneLengthError, setphoneLengthError] = useState(false);

  const [DOD, setDOD] = React.useState('');
  const [DODEmptyError, setDODEmptyError] = useState(false);
  const [DODInvalidError, setDODInvalidError] = useState(false);
  const [DODExistsError, setDODExistsError] = useState(false);

  const [role, setRole] = React.useState(100);
  const [roleEmptyError, setRoleEmptyError] = useState(false);
  
  const [roles, setRoles] = React.useState([]);
  
  const [incentivePay, setIncentivePay] = React.useState('None');
  const [incentivePayEmptyError, setIncentivePayEmptyError] = useState(false);

  const [ACIPDate, setACIPDate] = useState(null);
  const [ACIPEmptyError, setACIPEmptyError] = useState(false);

  const [startDate, setStartDate] = React.useState(null);
  const [startDateEmptyError, setStartDateEmptyError] = useState(false);

  const [dentalDate, setDentalDate] = React.useState(null);
  const [invalidDentalDate, setInvalidDentalDate] = React.useState(false);

  const [PHADate, setPHADate] = React.useState(null);
  const [invalidPHADate, setInvalidPHADate] = React.useState(false);

  const [rank, setRank] = React.useState('');
  const [rankEmptyError, setRankEmptyError] = useState(false);
 
  const [grade, setGrade] = React.useState('');
  const [branch, setBranch] = React.useState('');
  const [unit, setUnit] = React.useState('');

  const [superiorUnit, setSuperiorUnit] = React.useState(global.user_data.unit_id);

  const [superior, setSuperior] = React.useState('');
  const [superiorEmptyError, setSuperiorEmptyError] = useState(false);

  const [MOS, setMOS] = React.useState('');
  const [MPC, setMPC] = React.useState('');
  const [RCC, setRCC] = React.useState('');
  const [UIC, setUIC] = React.useState('');

  const [dentalReadiness, setDentalReadiness] = React.useState('');
  const [medicalReadiness, setMedicalReadiness] = React.useState('');
  const [flightRated, setFlightRated] = React.useState('false');
  const [flightStatus, setFlightStatus] = React.useState('false');

  const [addressLine1, setAddressLine1] = React.useState("");
  const [addressLine1EmptyError, setAddressLine1Error] = useState(false);

  const [addressLine2, setAddressLine2] = React.useState("")

  const [state, setState] = React.useState("");
  const [stateEmptyError, setStateEmptyError] = useState(false);

  const [city, setCity] = React.useState("");
  const [cityEmptyError, setCityEmptyError] = useState(false);

  const [zipCode, setZipCode] = React.useState("");
  const [zipCodeEmptyError, setZipCodeEmptyError] = useState(false);
  const [zipCodeLengthError, setZipCodeLengthError] = useState(false);

  const [selectedUnits, setSelectedUnits] = React.useState([]);
  const [hasAdminPrivs, setHasAdminPrivs] = React.useState(false);
  const [selectedPermissions, setSelectedPermissions] = React.useState([]);

  const [datePicker1Open, setDatePicker1Open] = React.useState(false);
  const [datePicker2Open, setDatePicker2Open] = React.useState(false);
  const [dateTimePicker1Open, setDateTimePicker1Open] = React.useState(false);
  const [dateTimePicker2Open, setDateTimePicker2Open] = React.useState(false);
  const datePicker1Ref = useRef(null);
  const datePicker2Ref = useRef(null);
  const dateTimePicker1Ref = useRef(null);
  const dateTimePicker2Ref = useRef(null);

  const [AllSuperiors, setAllSuperiors] = React.useState([]);

  const [unitNames, setUnitNames] = React.useState([]);

  const [permissionsNames, setPermissionsNames] = React.useState([]);
  const [permissionsHelperText, setPermissionsHelperText] = React.useState(' ');

  const [filteredUnitNames, setFilteredUnitNames] = React.useState([]);
  
  const [pageNum, setPageNum] = useState(1);
  const { mode } = React.useContext(ModeContext);
  const isFocused = useIsFocused();

  const [hasPermission, setHasPermission] = React.useState(false);

  const disabled_text_color = '#00000066';
  // import drop down menu options
  const states = require('./json_files/States.json');
  const branches = require('./json_files/Branches.json');
  const ranks = require('./json_files/Grades.json');
  const flightRatedOptions = require('./json_files/FlightRated.json');
  const flightStatusOptions = require('./json_files/FlightStatus.json');
  const readiness = require('./json_files/Readiness.json');
  const incentives = require('./json_files/Incentives.json');
  const { colors } = useColorContext();
  const { showSnackbar } = useSnackbar();
  const { navState } = useNavBarContext();

  useFocusEffect(
    React.useCallback(() => {
      // This effect is used to prevent navigation instantly if date picker is open
      const beforeRemoveListener = (e) => {
        if (!datePicker1Open && !datePicker2Open && !dateTimePicker1Open && !dateTimePicker2Open) {
          return;
        }

        e.preventDefault();

        setDatePicker1Open(false);
        setDatePicker2Open(false);
        setDateTimePicker1Open(false);
        setDateTimePicker2Open(false);
        // navigation.dispatch(e.data.action);
        setTimeout(() => {
          navigation.dispatch(e.data.action);
        }, 200);
      };

      navigation.addListener('beforeRemove', beforeRemoveListener);

      return () => {
        navigation.removeListener('beforeRemove', beforeRemoveListener);
      };
    }, [datePicker1Open, datePicker2Open, dateTimePicker1Open, dateTimePicker2Open]),
);

  useEffect(() => {
    fetchRoles();
    fetchUnitNames();
    fetchAllSuperiors(global.user_data.unit_id);

    // Check that the user had permission for this page
    if (navState.canSeeUsers) {
      setHasPermission(true);
    }
    else {
      setHasPermission(false);
    }
  }, []);

  useEffect(() => {
    if (roles && roles.length > 0) {
      handleRoleChange(role);
    }
  }, [roles]);

  useEffect(() => {
    if ([33, 34, 35, 36, 27, 26].some(permission => selectedPermissions.includes(permission))) {
      setHasAdminPrivs(true);
    } else {
      setHasAdminPrivs(false);
    }
  }, [selectedPermissions])

  // Reset all values when user navigates to another page
  useEffect(() => {
    setfirstName('');
    setmiddleName('');
    setlastName('');
    setEmail('');
    setPhone('');

    setDOD('');
    setRole(100);
    setIncentivePay('None');
    setACIPDate(null);
    setStartDate(null);
    setDentalDate(null);
    setPHADate(null);

    setRank('');
    setGrade('');
    setBranch('');
    // setUnit(global.user_data.unit_id);
    setSuperiorUnit(global.user_data.unit_id);
    setSuperior('');

    setMOS('');
    setMPC('');
    setRCC('');
    setUIC('');

    setDentalReadiness('');
    setMedicalReadiness('');
    setFlightRated('false');
    setFlightStatus('false');

    setAddressLine1('');
    setAddressLine2('');
    setState('');
    setCity('');
    setZipCode('');
    setSelectedUnits([]);

    setfirstNameEmptyError(false);
    setlastNameEmptyError(false);
    setEmailEmptyError(false);
    setEmailInvalidError(false);
    setEmailArmyError(false);
    setEmailExistsError(false);
    setEmailCognitoError(false);
    setphoneLengthError(false);

    setDODEmptyError(false);
    setDODInvalidError(false);
    setDODExistsError(false);
    setRoleEmptyError(false);
    setIncentivePayEmptyError(false);
    setACIPEmptyError(null);
    setStartDateEmptyError(false);

    setRankEmptyError(false);
    setSuperiorEmptyError(false);

    setAddressLine1Error(false);
    setStateEmptyError(false);
    setCityEmptyError(false);
    setZipCodeEmptyError(false);
    setZipCodeLengthError(false);

    setPageNum(1);
  }, [isFocused]);

  // Create theme styling for calendar
  const globalTheme = useTheme();
  const calendarTheme = createTheme({
    palette: {
      mode: mode,
      primary: globalTheme.palette.primary,
    },
  });

  const scale = useScale();
  const styles = ({
    sectionDiv: {
      marginTop: '1.5%',
      alignItems: 'flex-start',
      left: '6%',
      width: '88%',
      overflow: 'hidden',
    },
    sectionContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-evenly',
      width: '100%',
    },
    horizontalLine: {
      top: '10px',
      width: '100%',
      height: '2px',
      backgroundColor: '#d6dde4',
      position: 'absolute',
    },
    sectionTitle: {
      fontSize: 20,
      fontWeight: 'bold',
      color: colors.inputted_text,
    },
    container: {
      width: '100%',
      backgroundColor: colors.screen_background,
    },
    scrollView: {
      backgroundColor: colors.screen_background,
      flexDirection: 'column',
      height: '100%',
    },
    titleDiv: {
      marginTop: '0%',
      alignItems: 'center',
    },
    title: {
      fontSize: scale(25),
      fontFamily: 'Trebuchet MS',
      marginTop: '0.5%',
      color: colors.text,
    },
    header: {
      backgroundColor: 'pink',
      height: '15%',
    },
    main: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '0%',
      height: '56%',
      width: '100%',
      justifyContent: 'center',
    },
    compartment: {
      flexDirection: 'column',
      height: '100%',
      alignItems: 'center',
    },
    textandinput: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      justifyContent: 'space-evenly',
      width: '100%',
    },
    textbox: {
      margin: 10,
      width: '25%',
      backgroundColor: colors.textfield_background,
      color: colors.inputted_text
    },
    checkBox: {
      height: '24px',
      color: colors.inputted_text,
    },
    listItemTextTypography: {
      fontSize: '1rem',
    },
    dateBox: {
      'margin': 1.5,
      'width': '25%',
      'backgroundColor': colors.textfield_background,
      '& .MuiFormLabel-root, & label.Mui-focused, & .MuiInputBase-input': {
        color: colors.inputted_text,
      },
      '.MuiSvgIcon-root ': {
        fill: colors.inputted_text,
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: colors.form_border,
        },
        '&:hover fieldset': {
          borderColor: colors.form_border,
        },
        '&.Mui-focused fieldset': {
          borderColor: '#1876d2',
        },
      },
      '& .MuiFormLabel-root.Mui-error': {
        color: colors.placeholder_text,
      },
      '& .MuiFormHelperText-root.Mui-error': {
        backgroundColor: colors.screen_background,
        color: '#d32f2f',
        margin: 0,
        paddingLeft: 1,
      },
    },
    dateBoxEnd: {
      'width': '12vw',
      '& .MuiOutlinedInput-root': {
        '& fieldset, &:hover fieldset, &.Mui-focused fieldset': {
          borderColor: 'transparent',
        },
        '& input': {
          textAlign: 'right',
        },
      },
      '& .MuiInputLabel-shrink, & .MuiInputLabel-root.Mui-focused': {
        color: 'transparent',
      },
      '& .MuiFormLabel-root': {
        textAlign: 'right',
        right: '60px',
      },
    },
    innerText: {
      color: colors.button_text,
      fontWeight: 'bold',
      padding: 10,
      fontSize: 18,
    },
    button: {
      backgroundColor: colors.button,
      width: 180,
      height: 50,
      alignItems: 'center',
      borderRadius: 15,
      shadowColor: '#171717',
      shadowOffset: { width: -2, height: 4 },
      shadowOpacity: 0.2,
      shadowRadius: 3,
    },
    buttonDiv: {
      paddingTop: '2%',
      flexDirection: 'row',
      width: '100%',
    },
    textInputProps: {
      '& .MuiFormLabel-root, & label.Mui-focused, & .MuiInputBase-input': {
        color: colors.inputted_text,
      },
      '.MuiSvgIcon-root': {
        fill: colors.inputted_text,
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: colors.border_color,
        },
        '&:hover fieldset': {
          borderColor: global.user_data.theme === 'dark' ? colors.form_border : '#1876d2',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#1876d2',
        },
      },
      '&  .MuiFormHelperText-root': {
        backgroundColor: colors.screen_background,
        margin: 0,
        paddingLeft: 1,
      },
    },
    dateInputProps: {
      '& .MuiFormLabel-root, & label.Mui-focused, & .MuiInputBase-input': {
        color: colors.inputted_text,
      },
      '.MuiSvgIcon-root': {
        fill: colors.inputted_text,
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: colors.border_color, 
        },
        '&:hover fieldset': {
          borderColor: global.user_data.theme === 'dark' ? colors.form_border : '#1876d2',
        },
      },
      '&  .MuiFormHelperText-root': {
        backgroundColor: colors.screen_background,
        margin: 0,
        paddingLeft: 1,
      },
    },
    textInputPropsDisabled: {
      '& .MuiFormLabel-root.Mui-disabled, & label.Mui-focused.Mui-disabled, & .MuiInputBase-input.Mui-disabled': {
        color: colors.disabled_text,
        WebkitTextFillColor: colors.disabled_text,
      },
      '& .MuiOutlinedInput-root.Mui-disabled': {
        '& fieldset': {
          borderColor: colors.disabled_text,
        },
      },
      '& .MuiFormHelperText-root.Mui-disabled': {
        backgroundColor: colors.screen_background,
        color: colors.placeholder_text,
        margin: 0,
        paddingLeft: 1,
      },
    },
    selectProps: {
      MenuProps: {
        sx: {
          '& .MuiMenu-paper': {
            background: colors.textfield_background,
            color: colors.inputted_text,
          },
        },
      },
    },
  });

  // Gather existing roles from the database and assign them to state
  async function fetchRoles() {
    try {
      let roles_res = await get_roles({ permissions: global.user_data.permissions }, global.token);
      let permissions_res = await get_permissions({}, global.token);
      if (roles_res.status !== 200 || permissions_res.status !== 200) {
        showSnackbar("error", "Application Error: Failed to retrieve roles and permissions")
        navigation.goBack();
      } else {
        roles_res = roles_res.data;
        permissions_res = permissions_res.data;
        
        if(!navState.isSuperUser) {
          roles_res.body = roles_res.body.filter(item => item.role !== 'SUPER_USER' && item.role !== 'SUPERUSER')
        }

        permissions_res.body = permissions_res.body.filter(item => item.id >= 25 && item.id !== 38)
        roles_res.body = roles_res.body.filter(item => item.role_id >= 100)
        
        if (!navState.isSuperUser) {
          permissions_res.body = permissions_res.body.filter(item => item.permission_name !== 'USARC Edit Documents')
        }
        
        setRoles(roles_res.body);
        setPermissionsNames(permissions_res.body);
      }
    }
    catch (error) {
      console.error(error);
    }
  }

  async function fetchAllSuperiors(data) {
    try {
      const all_superiors_res = await get_all_superior_users(data, global.token);
      if (all_superiors_res.status === 200) {
        setAllSuperiors(all_superiors_res.data.body);
      } else {
        showSnackbar("error", "Application Error: Failed to fetch potential superiors");
      }
    } catch (error) {
      console.error(error);
    }
  }

  const fetchUnitNames = async () => {
    try {
      const unit_names_JSON = {
        'unit_id': 0,
        'get_top_unit': true,
        'get_path': true,
      };

      // Retrieve all units and eliminate the ones the user does not have access to
      const unit_names_res = await get_all_unit_names(unit_names_JSON, global.token);
      if (unit_names_res.status !== 200) {
        showSnackbar("error", "Application Error: Failed to retrieve all units")
        navigation.goBack();
      } else {
        const unit_ids_set = new Set(global.user_data.admin_unit_ids);
        const filteredUnitNames = unit_names_res.data.body.filter((unit) => unit_ids_set.has(unit.unit_id));
        console.log(filteredUnitNames);

        console.log(unit_names_res.data.body);


        setFilteredUnitNames(filteredUnitNames);
        setUnitNames(unit_names_res.data.body);
        setUnit(global.addUserSelectedUnit || filteredUnitNames[0].unit_id);
      }
      
    } catch (error) {
      console.error('Error: ', error);
    }
  };

  const fetchUnitData = async (unit_id) => {
    try {
      return (await get_table_unit({ unit_id }, global.token)).body;
    } catch (error) {
      console.error('Error fetching user data:', error);
      return null;
    }
  };

  function setUnitAddress(unit_id)
  {
    fetchUnitData(unit_id)
    .then(unitData => {
      // console.log(unitData);
      setAddressLine1(unitData.unit_address_line_1);
      setAddressLine2(unitData.unit_address_line_2);
      setState(unitData.unit_state.trim());
      setCity(unitData.unit_city);
      setZipCode(unitData.unit_zipcode);
    })
    .catch(error => {
      console.error('Error fetching unit data:', error);
    });

  }

  const validateStartDate = (date) => {
    if (date) {
      const isValidDate = !isNaN(new Date(date).getTime());
      setStartDateEmptyError(!isValidDate);
    } else {
      setStartDateEmptyError(true);
    }
  };

  function formatDate(date) {
    const d = new Date(date);
    console.log(date)
    let month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        hour = '' + d.getHours(),
        minute = '' + d.getMinutes();
  
    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
    if (hour.length < 2) 
        hour = '0' + hour;
    if (minute.length < 2) 
        minute = '0' + minute;
  
    return [month, day, year].join('/') + ' ' + [hour, minute].join(':');
  }

  const validateDateWithTime = (label, date) => {
    const formattedDate = formatDate(date)
    const dateTimeRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/(19|20)\d{2} (0\d|1\d|2[0-3]):([0-5]\d)$/;

    if (dateTimeRegex.test(formattedDate)) {
      const [datePart, timePart] = formattedDate.split(' ');
      const [month, day, year] = datePart.split('/').map(Number);
      const [hours, minutes] = timePart.split(':').map(Number);
  
      const dateObj = new Date(year, month - 1, day, hours, minutes);
  
      if (dateObj && dateObj.getFullYear() === year && dateObj.getMonth() === month - 1 &&
          dateObj.getDate() === day && dateObj.getHours() === hours && dateObj.getMinutes() === minutes) {
        if (label == 'Dental Date') {
          setInvalidDentalDate(false);
        } else {
          setInvalidPHADate(false);
        }
      } else {
        if (label == 'Dental Date') {
          setInvalidDentalDate(true);
        } else {
          setInvalidPHADate(true);
        }
      }
    } else {
      if (label == 'Dental Date') {
        setInvalidDentalDate(true);
      } else {
        setInvalidPHADate(true);
      }
    }
  };

  const handleFlightRatedChange = (value) => {
    console.log(value);
    if(value === "true") setFlightRated("true");
    // else if(value === "miltech") setFlightRated("miltech");
    else if(value === "false"){
      setFlightRated("false");
      if(selectedPermissions.includes(32)){
        const updatedPermissions = selectedPermissions.filter(permission => permission !== 32);
        setSelectedPermissions(updatedPermissions);
        showSnackbar("error", "Flight Rated is required for AFTP Approval; AFTP Approval permission has been removed");
      }
    }
  };

  const handleRoleChange = (data) => {
    setRole(data);

    const selectedRole = roles.find(item => item.role_id === data);
    const roleToPermissions = selectedRole ? selectedRole.permissions_id : null;

    if (selectedRole.role_id === 100) {
      setSelectedPermissions([])
    } else if (roleToPermissions) {
      setSelectedPermissions(roleToPermissions);
    }

    if (selectedRole.role_id === 104) {
      setSelectedUnits([...selectedUnits, ...filteredUnitNames.map(unit => unit.unit_id)]);
    } else if (selectedRole.role_id !== 100) {
      const newArray = [unit];
      if (roleToPermissions.some(permission => [33,34,35,36,27,26].includes(permission))) {
        setSelectedUnits(newArray);
      }
    }
  };

  const handleRankChange = (data) => {
    setRank(data);
    setRankEmptyError(false)
    ranks.find(item => {
      if (item.rank == data) {
        setGrade(item.grade);
      }
    })
  }

  const handleAdminUnitsChange = (event) => {
    const { target: { value }, } = event;
    const selectedUnits = typeof value === 'string' ? value.split(',') : value;
    setSelectedUnits(selectedUnits);
  };

  const handlePermissionsChange = (event) => {
    const { target: { value } } = event;
    let selectedPermissions = typeof value === 'string' ? value.split(',') : value;

    const previousSelections = new Set(selectedPermissions);

    const updatePermissions = () => {
      if (selectedPermissions.includes(35) && !selectedPermissions.includes(36)) {
        selectedPermissions.push(36);
      } else if (!selectedPermissions.includes(35) && previousSelections.has(35) && selectedPermissions.includes(36)) {
        selectedPermissions = selectedPermissions.filter(permission => permission !== 36);
      }

      if (selectedPermissions.includes(33) && !selectedPermissions.includes(34)) {
        selectedPermissions.push(34);
      } else if (!selectedPermissions.includes(33) && previousSelections.has(33) && selectedPermissions.includes(34)) {
        selectedPermissions = selectedPermissions.filter(permission => permission !== 34);
      }

      if (selectedPermissions.includes(26)) {
        if (!selectedPermissions.includes(27)) {
          selectedPermissions.push(27);
        }
        if (!selectedPermissions.includes(39)) {
          selectedPermissions.push(39);
        }
      } else if (previousSelections.has(26)) {
        selectedPermissions = selectedPermissions.filter(permission => permission !== 27 && permission !== 39);
      }

      if(selectedPermissions.includes(32)){
        setFlightRated("true");
      }
    };

    updatePermissions();

    selectedPermissions = [...new Set(selectedPermissions)];
    setSelectedPermissions(selectedPermissions);
  
    const newArray = [unit];
    if (selectedPermissions.some(permission => [35, 36, 26, 27].includes(permission))) {
      setSelectedUnits(newArray);
    }
  };

  // Adds '()' and '-' as user inputs digits
  const formatPhoneNumber = (data) => {

    // Remove any non-digits from string
    const phoneNumber = data.replace(/[^\d]/g, "");

    // Length is currently no more than 3 digits: Do nothing
    if (phoneNumber.length < 4) {
      setPhone(phoneNumber);
    }

    // Length is between 4 - 6: add '()'
    else if (phoneNumber.length < 7) {
      setPhone('(' + phoneNumber.slice(0, 3) + ') ' +
        phoneNumber.slice(3));
    }

    // Length is between 7 - 10: add '-'
    else {
      setPhone('(' + phoneNumber.slice(0, 3) + ') ' +
        phoneNumber.slice(3, 6) + '-' +
        phoneNumber.slice(6, 10));
    }
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[a-z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
    return emailRegex.test(email);
  };

  const checkFirstPageInput = () => {
    let error = false;

    // Check empty first name
    if (firstName === '') {
      error = true;
      setfirstNameEmptyError(true);
    } else {
      setfirstNameEmptyError(false);
    }

    // Check empty last name
    if (lastName === '') {
      error = true;
      setlastNameEmptyError(true);
    } else {
      setlastNameEmptyError(false);
    }

    // Check empty email
    if (email === '') {
      error = true;
      setEmailEmptyError(true);
    }

    // Check invalid email
    else if (!isValidEmail(email)) {
      error = true;
      setEmailEmptyError(false);
      setEmailInvalidError(true);
    }

    // Check email ends with @army.mil
    // else if (!email.endsWith('@army.mil')) {
    //   error = true;
    //   setEmailEmptyError(false);
    //   setEmailInvalidError(false);
    //   setEmailArmyError(true);
    // } 
    else {
      setEmailEmptyError(false);
      setEmailInvalidError(false);
      setEmailArmyError(false);
    }

    // Check phone number is empty or exactly 14 characters (includes '()' and '-')
    if (phone !== '' && phone.length != 14) {
      error = true;
      setphoneLengthError(true);
    } else {
      setphoneLengthError(false);
    }

    // Check empty DOD Number
    if (DOD === '') {
      error = true;
      setDODEmptyError(true);
    }

    // Check DOD number is exactly 10 digits
    else if (DOD.length != 10) {
      error = true;
      setDODEmptyError(false);
      setDODInvalidError(true);
    } else {
      setDODEmptyError(false);
      setDODInvalidError(false);
    }

    // Check empty role
    if (role === '') {
      error = true;
      setRoleEmptyError(true);
    } else {
      setRoleEmptyError(false);
    }

    // Check if Start Date is empty
    if (startDate === null) {
      error = true;
      setStartDateEmptyError(true);
    } else {
      setStartDateEmptyError(false);
    }

    // Check if Rank is empty
    if (rank === '') {
      error = true;
      setRankEmptyError(true);
    } else {
      setRankEmptyError(false);
    }

    // Check empty superior name
    if (AllSuperiors?.length !== 0 && superior === '') {
      error = true;
      setSuperiorEmptyError(true);
    } else {
      setSuperiorEmptyError(false);
    }

    return error;
  }

  // Checks invalid or empty user input
  const checkSecondPageInput = () => {
    let error = false;

    // Check if Incentive Pay is empty
    if (incentivePay === '') {
      error = true;
      setIncentivePayEmptyError(true);
    }

    // Check if ACIP is chosen and empty
    else if (incentivePay === 'ACIP' && ACIPDate === null) {
      error = true;
      setIncentivePayEmptyError(false);
      setACIPEmptyError(true);
    } else {
      setIncentivePayEmptyError(false);
      setACIPEmptyError(false);
    }

    // Check if address is not full
    const isEmpty = (value) => value === '';

    if ((addressLine1 || state || city || zipCode) &&
      (isEmpty(addressLine1) || isEmpty(state) || isEmpty(city) || isEmpty(zipCode))) {
      error = true;
      if (isEmpty(addressLine1)) {
        setAddressLine1Error(true);
      } else {
        setAddressLine1Error(false);
      }

      if (isEmpty(state)) {
        setStateEmptyError(true);
      } else {
        setStateEmptyError(false);
      }

      if (isEmpty(city)) {
        setCityEmptyError(true);
      } else {
        setCityEmptyError(false);
      }

      if (isEmpty(zipCode)) {
        setZipCodeEmptyError(true);
      } else {
        setZipCodeEmptyError(false);
      }
    } else {
      setAddressLine1Error(false);
      setStateEmptyError(false);
      setCityEmptyError(false);
      setZipCodeEmptyError(false);
    }

    // Check zip code is of full length (5 digits)
    if (zipCode !== '' && zipCode.length !== 5) {
      error = true;
      setZipCodeLengthError(true);
    } else {
      setZipCodeLengthError(false);
    }

    return error;
  };

  // Checks response of add_user endpoint
  const checkOutput = (response) => {
    let error = false;
    
    if (response.body.includes('Cognito user pool')) {
      error = true;
      setEmailCognitoError(true);
    } else {
      setEmailCognitoError(false);
    }
    
    if (response.body.includes('email already exists')) {
      error = true;
      setEmailExistsError(true);
    } else {
      setEmailExistsError(false);
    }

    if (response.body.includes('DOD already exists')) {
      error = true;
      setDODExistsError(true);
    } else {
      setDODExistsError(false);
    }
    
    return error;
  };

  // User clicks add user
  const AddUser = async () => {
    // Check for input errors
    if (checkSecondPageInput()) {
      showSnackbar("error", "Please correct one or more invalid inputs");
    }
    // Populate new user variable with info entered in states
    else {
      setLoaded(false);
      const newUser = {
        admin_id: global.user_data.user_id,
        admin_permissions: global.user_data.permissions,
        first_name: firstName,
        last_name: lastName,
        email: email,
        dod: parseInt(DOD),
        unit_id: parseInt(unit),
        superior_id: isNaN(parseInt(superior)) ? 0 : parseInt(superior),
        role_id: parseInt(role),
        permissions: selectedPermissions,
        middle_name: middleName,
        rank: rank,
        grade: grade,
        branch: branch,
        incentive_pay: incentivePay,
        acip_date: ACIPDate,
        start_date: startDate,
        dent_date: dentalDate,
        pha_date: PHADate,
        mos: MOS,
        mpc: MPC,
        rcc: RCC,
        upc: UIC, // UPC (Unit Processing Code) was changed to UIC (Unit Identification Code) but WAS NOT changed in the DB
        drc: parseInt(dentalReadiness),
        mrc: parseInt(medicalReadiness),
        flight_rated: flightRated,
        flight_status: flightStatus,
        address_line_1: addressLine1,
        address_line_2: addressLine2,
        city: city,
        state: state,
        country: "USA",
        zipcode: zipCode,
        phone_number: phone,
        admin_unit_ids: selectedPermissions.includes(33) || selectedPermissions.includes(34) || selectedPermissions.includes(35) || selectedPermissions.includes(36) || selectedPermissions.includes(26) || selectedPermissions.includes(27) ? selectedUnits : [],
      }

      // Call add_user enpdoint
      const response = await add_user(newUser, global.token);
      if (checkOutput(response.data)) {
        showSnackbar("error", "Please correct one or more invalid inputs on the previous page");
      } else if (response.data.body.includes('Error while executing the sql query')) {
        showSnackbar("error", "Problem occurred when trying to add the user")
      } else {
        navigation.navigate('admin_dashboard', { screen: 'view_users' });
        showSnackbar("success", "User added successfully");
      }
      setLoaded(true);
    }
  };

  const loadingStyles = {
    animation: {
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
        justifyContent: 'center',
        paddingVertical: 20,
        background: colors.screen_background,
    }
  }

  if (!loaded) {
    return (
      <View style={loadingStyles.animation}>
          <Progress.CircleSnail
            color={colors.loading_circle}
            indeterminate={true}
            size={275}
            thickness={8}
          />
      </View>
    );
  }

  return (
    !hasPermission ? (
      <View style={{height: '100%', width: '100%', flexDirection: 'row'}}>
        {/* <NavSideBar/> */}
        <View style={styles.main}>
          <View style={styles.titleDiv}>
            <Text style={styles.title}>Access Denied: User does not have permission to view this page.</Text>
          </View>
        </View>
      </View>
    ) : pageNum === 1 ? (
      <View style={{height: '100%', width: '100%', flexDirection: 'row'}}>
        {/* <NavSideBar/> */}
        <Scrollbars style={styles.scrollView}>
          <View style={styles.container}>
            <View style={styles.titleDiv}>
              <Text style={styles.title}>Add User</Text>
            </View>


            <View style={styles.main}>
              <View style={styles.compartment}>
                <View style={styles.textandinput}>

                  <View style={styles.sectionDiv}>
                    <Text style={styles.sectionTitle}>Personal Information</Text>
                    <View style={[styles.horizontalLine, { left: '210px' }]} />
                  </View>

                  <View style={styles.sectionContainer}>
                    <TextField
                      style={styles.textbox}
                      sx={styles.textInputProps}
                      required
                      label="First Name"
                      onChange={(data) => {
                        setfirstName(data.target.value.replace(/[^A-Za-z\s]|(\s)\s+/g, ''));

                        if (data.target.value.trim()) {
                          setfirstNameEmptyError(false);
                        }
                      }}
                      onBlur={() => {
                        const trimmed = firstName.trim();
                        setfirstName(trimmed);
                        setfirstNameEmptyError(!trimmed);
                      }}
                      value={firstName}
                      error={firstNameEmptyError}
                      helperText={firstNameEmptyError ? 'Enter a first name' : ' '}
                    />

                    <TextField
                      style={styles.textbox}
                      sx={styles.textInputProps}
                      label="Middle Name"
                      onChange={(data) => setmiddleName(data.target.value.replace(/[^A-Za-z\s]|(\s)\s+/g, ''))}
                      onBlur={() => setmiddleName(middleName.trim())}
                      value={middleName}
                      error={false}
                      helperText={' '}
                    />

                    <TextField
                      style={styles.textbox}
                      sx={styles.textInputProps}
                      required
                      label="Last Name"
                      onChange={(data) => {
                        setlastName(data.target.value.replace(/[^A-Za-z\s]|(\s)\s+/g, ''))

                        if (data.target.value.trim()) {
                          setlastNameEmptyError(false);
                        }
                      }}
                      onBlur={() => {
                        const trimmed = lastName.trim();
                        setlastName(trimmed)
                        setlastNameEmptyError(!trimmed)
                      }}
                      value={lastName}
                      error={lastNameEmptyError}
                      helperText={lastNameEmptyError ? 'Enter a last name' : ' '}
                    />

                    <TextField
                      style={styles.textbox}
                      sx={styles.textInputProps}
                      required
                      label="Email"
                      onChange={(data) => {
                        const newEmail = data.target.value.replace(/[^A-Za-z0-9+_.~!#$%&'*+=?^_`{|}@.-]/g, '').toLowerCase();
                        setEmail(newEmail);
                    
                        if (newEmail) {
                          setEmailEmptyError(false);
                          setEmailInvalidError(false);
                          setEmailArmyError(false);
                          setEmailExistsError(false);
                          setEmailCognitoError(false);
                        }
                      }}
                      onBlur={() => {
                        const trimmedEmail = email.trim();
                        setEmail(trimmedEmail);
                    
                        setEmailEmptyError(!trimmedEmail);
                        setEmailInvalidError(!isValidEmail(trimmedEmail));
                      }}
                      value={email}
                      error={emailEmptyError || emailInvalidError || emailArmyError || emailExistsError || emailCognitoError}
                      helperText={emailEmptyError ? 'Enter an email' :
                        emailInvalidError ? 'This email format is invalid' :
                          emailArmyError ? 'Enter a military email (@army.mil)' :
                            emailCognitoError ? 'A verified user with this email already exists' :
                              emailExistsError ? 'This email is already in use' : ''}
                    />

                    <TextField
                      style={styles.textbox}
                      sx={styles.textInputProps}
                      label="Phone Number"
                      onChange={(data) => formatPhoneNumber(data.target.value)}
                      onBlur={() => {
                        const trimmedPhone = phone.trim();
                        setPhone(trimmedPhone);
                        
                        const onlyDigits = trimmedPhone.replace(/[^\d]/g, "");
                        setphoneLengthError(onlyDigits.length !== 10);
                      }}
                      value={phone}
                      error={phoneLengthError}
                      helperText={phoneLengthError ? 'Phone Number must be 10 digits' : ' '}
                    />

                    <TextField
                      style={{ visibility: 'hidden', ...styles.textbox }}
                      value={''}
                    />
                  </View>

                  <View style={styles.sectionDiv}>
                    <Text style={styles.sectionTitle}>Soldier Information</Text>
                    <View style={[styles.horizontalLine, { left: '200px' }]} />
                  </View>

                  <View style={styles.sectionContainer}>
                    <TextField
                      style={styles.textbox}
                      sx={styles.textInputProps}
                      required
                      id="DOD"
                      label="DOD Number"
                      onChange={(data) => {
                        setDOD(data.target.value.replace(/[^0-9]/g, '').slice(0, 10))

                        if (data.target.value.trim()) {
                          setDODEmptyError(false);
                        }
                      }}
                      onBlur={() => {
                        const trimmed = DOD.trim();
                        setDOD(trimmed)
                        setDODEmptyError(!trimmed)
                        setDODInvalidError(trimmed.length !== 10)
                        setDODExistsError(false)
                      }}
                      value={DOD}
                      error={DODEmptyError || DODInvalidError || DODExistsError}
                      helperText={DODEmptyError ? 'Enter a DOD Number' :
                        DODInvalidError ? 'DOD Number must be 10 digits' :
                          DODExistsError ? 'This DOD Number is already in use' : ' '}
                    />

                    {roles.length > 0 ? (
                      <TextField
                        style={styles.textbox}
                        sx={styles.textInputProps}
                        SelectProps={styles.selectProps}
                        select
                        required
                        label="Role"
                        onChange={data => handleRoleChange(data.target.value)}
                        value={role}
                        error={roleEmptyError}
                        helperText={roleEmptyError ? "Select a Role" : ' '}
                      >
                        {roles.map((option) => (
                          <MenuItem key={option.role_id} value={option.role_id}>{option.role}</MenuItem>
                        ))}
                      </TextField>
                    ) :
                      <TextField
                        style={styles.textbox}
                        sx={styles.textInputProps}
                        label="Role"
                        error={false}
                        helperText={' '}
                      >
                      </TextField>
                    }

                    <FormControl 
                      style={styles.textbox} 
                      sx={styles.textInputProps}
                    >
                      <InputLabel id="permissions-label">Permissions</InputLabel>
                      <Select
                        inputProps={styles.selectProps}
                        labelId="permissions-label"
                        multiple
                        value={selectedPermissions}
                        onChange={handlePermissionsChange}
                        input={<OutlinedInput label="Permissions" />}
                        renderValue={(selected) => {
                          const selectedPermissionNames = selected.map(permissionId => {
                            const selectedPermission = permissionsNames.find(option => option.id === permissionId);
                            return selectedPermission ? selectedPermission.permission_name : '';
                          });

                          return selectedPermissionNames.join(', ');
                        }}
                      >
                        {permissionsNames.map((option) => (
                          <MenuItem style={{ whiteSpace: "pre" }} key={option.id} value={option.id}>
                            <Checkbox
                              sx={styles.checkBox}
                              checked={selectedPermissions.indexOf(option.id) > -1}
                            />
                            <ListItemText primaryTypographyProps={{ style: styles.listItemTextTypography }} primary={option.permission_name} />
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>{permissionsHelperText}</FormHelperText>
                    </FormControl>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <ThemeProvider theme={calendarTheme}>
                      <ClickAwayListener
                              onClickAway={(event) => {
                                if (datePicker1Ref.current && !datePicker1Ref.current.contains(event.target)) {
                                  setDatePicker1Open(false);
                                }
                              }}
                              mouseEvent="onMouseDown"
                            >
                        <DatePicker
                          sx={styles.dateBox}
                          SelectProps={styles.selectProps}
                          label="Start Date (PEBD)*"
                          value={startDate}
                          onClose={() => setDatePicker1Open(false)}
                          onOpen={() => setDatePicker1Open(true)}
                          open={datePicker1Open}
                          onChange={(date) => {
                            setStartDate(date);
                            setStartDateEmptyError(false)
                          }}
                          slotProps={{
                            textField: {
                              style: styles.textbox,
                              sx: styles.dateInputProps,
                              onBlur: () => { validateStartDate(startDate); },
                              error: startDateEmptyError,
                              helperText: startDateEmptyError ? 'Enter a Valid Start Date (PEBD)' : ' ',
                            },
                          }}
                          PopperProps={{
                            modifiers: [
                              {
                                name: 'eventListeners',
                                options: {
                                  scroll: false,
                                  resize: true,
                                },
                              },
                            ],
                            ref: (popperRef) => {
                              if (popperRef && popperRef.node) {
                                popperRef.node.id = 'calendar-element';
                                datePicker1Ref.current = popperRef.node.querySelector('.MuiCalendarPicker-root');
                              }
                            },
                          }}
                        />
                      </ClickAwayListener>
                      </ThemeProvider>
                    </LocalizationProvider>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <ThemeProvider theme={calendarTheme}>
                      <ClickAwayListener
                              onClickAway={(event) => {
                                if (dateTimePicker1Ref.current && !dateTimePicker1Ref.current.contains(event.target)) {
                                  setDateTimePicker1Open(false);
                                }
                              }}
                              mouseEvent="onMouseDown"
                            >
                        <DateTimePicker
                          sx={styles.dateBox}
                          label="Dental Date"
                          ampm={false}
                          value={dentalDate}
                          onChange={(date) => {
                            setDentalDate(date);
                            setInvalidDentalDate(false);
                          }}
                          onClose={() => setDateTimePicker1Open(false)}
                          onOpen={() => setDateTimePicker1Open(true)}
                          open={dateTimePicker1Open}
                          slotProps={{
                            textField: {
                              style: styles.textbox,
                              sx: styles.dateInputProps,
                              onBlur: () => { validateDateWithTime("Dental Date", dentalDate) },
                              error: invalidDentalDate,
                              helperText: invalidDentalDate ? 'Enter a Valid Dental Date' : ' ',
                            },
                          }}
                          PopperProps={{
                            modifiers: [
                              {
                                name: 'eventListeners',
                                options: {
                                  scroll: false,
                                  resize: true,
                                },
                              },
                            ],
                            ref: (popperRef) => {
                              if (popperRef && popperRef.node) {
                                popperRef.node.id = 'calendar-element';
                                dateTimePicker1Ref.current = popperRef.node.querySelector('.MuiCalendarPicker-root');
                              }
                            },
                          }}
                        />
                      </ClickAwayListener>
                      </ThemeProvider>
                    </LocalizationProvider>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <ThemeProvider theme={calendarTheme}>
                      <ClickAwayListener
                              onClickAway={(event) => {
                                if (dateTimePicker2Ref.current && !dateTimePicker2Ref.current.contains(event.target)) {
                                  setDateTimePicker2Open(false);
                                }
                              }}
                              mouseEvent="onMouseDown"
                            >
                        <DateTimePicker
                          sx={{
                            ...styles.dateBox,
                            '& .MuiInputLabel-root': {
                              fontSize: 12,
                            },
                          }}
                          label="Physical Health Assessment Date"
                          ampm={false}
                          value={PHADate}
                          onClose={() => setDateTimePicker2Open(false)}
                          onOpen={() => setDateTimePicker2Open(true)}
                          open={dateTimePicker2Open}
                          onChange={(date) => {
                            setPHADate(date)
                            setInvalidPHADate(false)
                          }}
                          slotProps={{
                            textField: {
                              style: styles.textbox,
                              sx: styles.dateInputProps,
                              onBlur: () => { validateDateWithTime("Physical Health Assessment Date", PHADate) },
                              error: invalidPHADate,
                              helperText: invalidPHADate ? 'Enter a Valid PHA Date' : ' ',
                            },
                          }}
                          PopperProps={{
                            modifiers: [
                              {
                                name: 'eventListeners',
                                options: {
                                  scroll: false,
                                  resize: true,
                                },
                              },
                            ],
                            ref: (popperRef) => {
                              if (popperRef && popperRef.node) {
                                popperRef.node.id = 'calendar-element';
                                dateTimePicker2Ref.current = popperRef.node.querySelector('.MuiCalendarPicker-root');
                              }
                            },
                          }}
                        />
                        </ClickAwayListener>
                      </ThemeProvider>
                    </LocalizationProvider>
                  </View>

                  <View style={styles.sectionDiv}>
                    <Text style={styles.sectionTitle}>Soldier Hierarchy</Text>
                    <View style={[styles.horizontalLine, { left: '175px' }]} />
                  </View>

                  <View style={styles.sectionContainer}>
                    <TextField
                      style={styles.textbox}
                      sx={styles.textInputProps}
                      SelectProps={styles.selectProps}
                      select
                      required
                      label="Rank"
                      onChange={data => handleRankChange(data.target.value)}
                      value={rank}
                      error={rankEmptyError}
                      helperText={rankEmptyError ? "Select a rank" : " "}
                    >
                      {ranks.map((option) => (
                        <MenuItem
                          key={option.rank}
                          value={option.rank}
                          sx={styles.menuItem}
                        >
                          {option.rank}
                        </MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      style={styles.textbox}
                      sx={styles.textInputPropsDisabled}
                      disabled
                      label="Grade"
                      value={grade}
                      error={false}
                      helperText={" "}
                    >
                    </TextField>

                    <TextField
                      style={styles.textbox}
                      sx={styles.textInputProps}
                      SelectProps={styles.selectProps}
                      select
                      label="Branch"
                      onChange={(data) => setBranch(data.target.value)}
                      value={branch}
                      error={false}
                      helperText={' '}
                    >
                      {branches.map((option) => (
                        <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                      ))}
                    </TextField>

                    {(filteredUnitNames.length > 0 && unitNames.length > 0) ? (
                      <>
                        <FormControl style={styles.textbox} sx={styles.textInputProps}>
                          <InputLabel id="soldiers-unit-label">Soldier's Unit</InputLabel>
                          <Select
                            inputProps={styles.selectProps}
                            labelId="soldiers-unit-label"
                            select="true"
                            value={unit}
                            onChange={(data) => 
                              {
                                setUnit(data.target.value);
                                const value = data.target.value;
                                const newArray = [value];
                                if(selectedPermissions.includes (33) || selectedPermissions.includes(34) || selectedPermissions.includes(35) || selectedPermissions.includes(36) || selectedPermissions.includes(26) || selectedPermissions.includes(27)) {setSelectedUnits(selectedUnits.concat(newArray));}
                                setUnitAddress(data.target.value);
                              }}
                            input={<OutlinedInput label="Soldier's Unit" />}
                            renderValue={(selected) => {
                              const selectedName = (filteredUnitNames.find((item) => item.unit_id === selected)).unit_name;
                              const nameComponents = selectedName.split(' -> ');
                              const lastComponent = nameComponents[nameComponents.length - 1];
                              return (
                                <div>{lastComponent}</div>
                              );
                            }}
                          >
                            {filteredUnitNames.map((option) => (
                              <MenuItem style={{ whiteSpace: 'pre' }} key={option.unit_id} value={option.unit_id}>{option.unit_name}</MenuItem>
                            ))}
                          </Select>
                          <FormHelperText>{permissionsHelperText}</FormHelperText>
                        </FormControl>

                        <FormControl style={styles.textbox} sx={styles.textInputProps}>
                          <InputLabel id="superiors-unit-label">Superior's Unit</InputLabel>
                          <Select
                            inputProps={styles.selectProps}
                            labelId="superiors-unit-label"
                            select="true"
                            value={superiorUnit}
                            onChange={(data) => {
                              setSuperiorUnit(data.target.value);
                              fetchAllSuperiors(data.target.value);
                            }}
                            
                            input={<OutlinedInput label="Superior's Unit" />}
                            renderValue={(selected) => {
                              const selectedName = (unitNames.find((item) => item.unit_id === selected)).unit_name;
                              const nameComponents = selectedName.split(' -> ');
                              const lastComponent = nameComponents[nameComponents.length - 1];
                              return (
                                <div>{lastComponent}</div>
                              );
                            }}
                          >
                            {unitNames.map((option) => (
                              <MenuItem style={{ whiteSpace: 'pre' }} key={option.unit_id} value={option.unit_id}>{option.unit_name}</MenuItem>
                            ))}
                          </Select>
                          <FormHelperText>{permissionsHelperText}</FormHelperText>
                        </FormControl>

                        <TextField
                          style={styles.textbox}
                          sx={styles.textInputProps}
                          SelectProps={styles.selectProps}
                          select
                          required={AllSuperiors?.length === 0 ? false : true}
                          label="Superior's Name"
                          onChange={(data) => {
                            setSuperior(data.target.value)
                            setSuperiorEmptyError(false)
                          }}
                          value={superior}
                          error={AllSuperiors?.length === 0 ? false : superiorEmptyError}
                          helperText={AllSuperiors?.length === 0 ? '' : (superiorEmptyError ? 'Select a Superior' : ' ')}
                        >
                          {console.log(AllSuperiors)}
                          {AllSuperiors.map((option) => (
                            <MenuItem key={option.dod} value={option.user_id}>{option.name + ' (DOD: ' + option.dod + ')'}</MenuItem>
                          ))}
                        </TextField>
                      </>
                    ) :
                      <>
                        <TextField
                          style={styles.textbox}
                          sx={styles.textInputProps}
                          label="Soldier's Unit"
                          error={false}
                          helperText={' '}
                        >
                        </TextField>

                        <TextField
                          style={styles.textbox}
                          sx={styles.textInputProps}
                          label="Superior's Unit"
                          error={false}
                          helperText={' '}
                        >
                        </TextField>

                        <TextField
                          style={styles.textbox}
                          sx={styles.textInputProps}
                          label="Superior's Name"
                          error={false}
                          helperText={' '}
                        >
                        </TextField>
                      </>
                    }
                  </View>
                </View>

                <View style={styles.buttonDiv}>
                  <View style={{ left: '6%' }}>
                    <TouchableOpacity
                      style={styles.button}
                      onPress={() => {
                        navigation.navigate('admin_dashboard', { screen: 'view_users' });
                      }}>
                      <Text style={styles.innerText}>Cancel</Text>
                    </TouchableOpacity>
                  </View>

                  <View style={{ position: 'absolute', right: '6%' }}>
                    <TouchableOpacity
                      style={styles.button}
                      onPress={() => {
                        if(checkFirstPageInput()) {
                          showSnackbar("error", "Please correct one or more invalid inputs");
                        } else {
                          setPageNum(2);
                        }
                      }}>
                      <Text style={styles.innerText}>Next Page</Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </Scrollbars>
      </View>
      // *** Second Page ***
    ) :
      <View style={{height: '100%', width: '100%', flexDirection: 'row'}}>
        {/* <NavSideBar/> */}

        <Scrollbars style={styles.scrollView}>
          <View style={styles.container}>

            <View style={styles.titleDiv}>
              <Text style={styles.title}>Add User - Page 2</Text>
            </View>

            <View style={styles.main}>
              <View style={styles.compartment}>
                <View style={styles.textandinput}>

                  <View style={styles.sectionDiv}>
                    <Text style={styles.sectionTitle}>Command Organization</Text>
                    <View style={[styles.horizontalLine, { left: '235px' }]} />
                  </View>

                  <View style={styles.sectionContainer}>
                    <TextField
                      style={styles.textbox}
                      sx={styles.textInputProps}
                      InputLabelProps={{ style: { fontSize: 12 } }}
                      label="Military Occupational Specialty (MOS)"
                      onChange={(data) => setMOS(data.target.value.replace(/[^A-Za-z0-9]/g, ''))}
                      onBlur={() => setMOS(MOS.trim())}
                      value={MOS}
                    />

                    <TextField
                      style={styles.textbox}
                      sx={styles.textInputProps}
                      InputLabelProps={{ style: { fontSize: 12 } }}
                      label="Military Personnel Classification (MPC)"
                      onChange={(data) => setMPC(data.target.value.replace(/[^A-Za-z]/g, ''))}
                      value={MPC}
                      onBlur={() => setMPC(MPC.trim())}
                    />

                    <TextField
                      style={styles.textbox}
                      sx={styles.textInputProps}
                      InputLabelProps={{ style: { fontSize: 12 } }}
                      label="Reserve Components Command (RCC)"
                      onChange={(data) => setRCC(data.target.value.replace(/[^A-Za-z]/g, ''))}
                      onBlur={() => setRCC(RCC.trim())}
                      value={RCC}
                    />

                    <TextField
                      style={styles.textbox}
                      sx={styles.textInputProps}
                      InputLabelProps={{ style: { fontSize: 12 } }}
                      label="Unit Identification Code (UIC)"
                      onChange={(data) => setUIC(data.target.value.replace(/[^A-Za-z0-9]/g, ''))}
                      onBlur={() => setUIC(UIC.trim())}
                      value={UIC}
                    />

                    {/*Used to keep styling */}
                    <TextField
                      style={{ visibility: 'hidden', ...styles.textbox }}
                      value={''}
                    />

                    {(hasAdminPrivs)
                    ? (<FormControl style={styles.textbox} sx={styles.textInputProps}>
                      <InputLabel id="admin-privileges-label">Admin Privileges</InputLabel>
                      <Select
                        inputProps={styles.selectProps}
                        labelId="admin-privileges-label"
                        multiple
                        value={selectedUnits}
                        onChange={handleAdminUnitsChange}
                        input={<OutlinedInput label="Admin Privileges" />}
                        renderValue={(selected) => {
                          const getSelectedLastComponent = (selectedName) => {
                            const nameComponents = selectedName.split(' -> ');
                            return nameComponents[nameComponents.length - 1];
                          };

                          return (
                            selected.map((value) => (getSelectedLastComponent(filteredUnitNames.find((item) => item.unit_id === value).unit_name))).join(', ')
                          );
                        }}
                      >
                        {filteredUnitNames.map((option) => (
                          <MenuItem style={{ whiteSpace: 'pre' }} key={option.unit_id} value={option.unit_id}>
                            <Checkbox
                              sx={styles.checkBox}
                              checked={selectedUnits.indexOf(option.unit_id) > -1}
                            />
                            <ListItemText primaryTypographyProps={{ style: styles.listItemTextTypography }} primary={option.unit_name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>):(<TextField
                      style={{ visibility: 'hidden', ...styles.textbox }}
                      value={''}
                    />)}
                  </View>

                  <View style={styles.sectionDiv}>
                    <Text style={styles.sectionTitle}>Readiness Information</Text>
                    <View style={[styles.horizontalLine, { left: "220px" }]} />
                  </View>
                  <View style={styles.sectionContainer}>
                    <TextField
                      style={styles.textbox}
                      sx={styles.textInputProps}
                      SelectProps={styles.selectProps}
                      select
                      required
                      label="Incentive Pay"
                      onChange={data => {
                        setIncentivePay(data.target.value)
                        setACIPDate(null);
                      }}
                      value={incentivePay}
                      error={incentivePayEmptyError || (incentivePay === "ACIP" && ACIPEmptyError)}
                      helperText={incentivePayEmptyError ? "Select an incentive pay" :
                        (incentivePay === "ACIP" && ACIPEmptyError) ? "Select an ACIP start date" : ""}
                      InputProps={
                        incentivePay === "ACIP"
                          ? {
                            endAdornment: (
                              <InputAdornment position="start">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <ThemeProvider theme={calendarTheme}>
                                  <ClickAwayListener
                              onClickAway={(event) => {
                                if (datePicker2Ref.current && !datePicker2Ref.current.contains(event.target)) {
                                  setDatePicker2Open(false);
                                }
                              }}
                              mouseEvent="onMouseDown"
                            >
                                    <DatePicker
                                      label="Date"
                                      onChange={date => setACIPDate(date)}
                                      value={ACIPDate}
                                      sx={styles.dateBoxEnd}
                                      onClose={() => setDatePicker2Open(false)}
                                      onOpen={() => setDatePicker2Open(true)}
                                      open={datePicker2Open}
                                      PopperProps={{
                                        modifiers: [
                                          {
                                            name: 'eventListeners',
                                            options: {
                                              scroll: false,
                                              resize: true,
                                            },
                                          },
                                        ],
                                        ref: (popperRef) => {
                                          if (popperRef && popperRef.node) {
                                            popperRef.node.id = 'calendar-element';
                                            datePicker2Ref.current = popperRef.node.querySelector('.MuiCalendarPicker-root');
                                          }
                                        },
                                      }}
                                    />
                                  </ClickAwayListener>
                                  </ThemeProvider>
                                </LocalizationProvider>
                              </InputAdornment>
                            )
                          } : {}
                      }
                    >
                      {incentives.map((option) => (
                        <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      style={styles.textbox}
                      sx={styles.textInputProps}
                      SelectProps={styles.selectProps}
                      select
                      label="Dental Readiness (DRC)"
                      onChange={data => setDentalReadiness(data.target.value)}
                      value={dentalReadiness}
                    >
                      {readiness.map((option) => (
                        <MenuItem style={{ whiteSpace: "pre" }} key={option.value} value={option.value}>{option.label}</MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      style={styles.textbox}
                      sx={styles.textInputProps}
                      SelectProps={styles.selectProps}
                      select
                      label="Medical Readiness (MRC)"
                      onChange={(data) => setMedicalReadiness(data.target.value)}
                      value={medicalReadiness}
                    >
                      {readiness.map((option) => (
                        <MenuItem style={{ whiteSpace: 'pre' }} key={option.value} value={option.value}>{option.label}</MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      style={styles.textbox}
                      sx={styles.textInputProps}
                      SelectProps={styles.selectProps}
                      select
                      label="Flight Rated"
                      onChange={(data) => handleFlightRatedChange(data.target.value)}
                      value={flightRated}
                    >
                      {flightRatedOptions.map((option) => (
                        <MenuItem style={{ whiteSpace: 'pre' }} key={option.value} value={option.value}>{option.label}</MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      style={styles.textbox}
                      sx={styles.textInputProps}
                      SelectProps={styles.selectProps}
                      select
                      label="Flight Status"
                      onChange={(data) => setFlightStatus(data.target.value)}
                      value={flightStatus}
                    >
                      {flightStatusOptions.map((option) => (
                        <MenuItem style={{ whiteSpace: 'pre' }} key={option.value} value={option.value}>{option.label}</MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      style={{ visibility: 'hidden', ...styles.textbox }}
                      value={''}
                    />
                  </View>

                  <View style={styles.sectionDiv}>
                    <Text style={styles.sectionTitle}>Unit's Address</Text>
                    <View style={[styles.horizontalLine, { left: '145px' }]} />
                  </View>

                  <View style={styles.sectionContainer}>
                    <TextField
                      style={styles.textbox}
                      sx={global.user_data.role_id != 104 ? styles.textInputPropsDisabled : styles.textInputProps}
                      label="Address Line 1"
                      InputLabelProps={{ style: { color: global.user_data.role_id != 104 ? disabled_text_color : colors.inputted_text} }}
                      disabled={global.user_data.role_id != 104}
                      onChange={(data) => setAddressLine1(data.target.value.replace(/[^A-Za-z0-9.'#@$%&]\s/g, ''))}
                      onBlur={() => setAddressLine1(addressLine1.trim())}
                      value={addressLine1}
                      error={addressLine1EmptyError}
                      helperText={addressLine1EmptyError ? 'Enter a street' : ''}
                    />

                    <TextField
                      style={styles.textbox}
                      sx={global.user_data.role_id != 104 ? styles.textInputPropsDisabled : styles.textInputProps}
                      label="Address Line 2"
                      InputLabelProps={{ style: { color: global.user_data.role_id != 104 ? disabled_text_color : colors.inputted_text} }}
                      disabled={global.user_data.role_id != 104}
                      onChange={(data) => setAddressLine2(data.target.value.replace(/[^A-Za-z0-9.'#@$%&]\s/g, ''))}
                      onBlur={() => setAddressLine2(addressLine2.trim())}
                      value={addressLine2}
                    />

                    <TextField
                      style={styles.textbox}
                      sx={global.user_data.role_id != 104 ? styles.textInputPropsDisabled : styles.textInputProps}
                      SelectProps={styles.selectProps}
                      select
                      label="State"
                      InputLabelProps={{ style: { color: global.user_data.role_id != 104 ? disabled_text_color : colors.inputted_text} }}
                      disabled={global.user_data.role_id != 104}
                      onChange={(data) => setState(data.target.value)}
                      value={state}
                      error={stateEmptyError}
                      helperText={stateEmptyError ? 'Enter a state' : ''}
                    >
                      {states.map((option) => (
                        <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      style={styles.textbox}
                      sx={global.user_data.role_id != 104 ? styles.textInputPropsDisabled : styles.textInputProps}
                      label="City"
                      InputLabelProps={{ style: { color: global.user_data.role_id != 104 ? disabled_text_color : colors.inputted_text} }}
                      disabled={global.user_data.role_id != 104}
                      onChange={(data) => setCity(data.target.value.replace(/[^A-Za-z0-9.'#@$%&]\s/g, ''))}
                      onBlur={() => setCity(city.trim())}
                      value={city}
                      error={cityEmptyError}
                      helperText={cityEmptyError ? 'Enter a city' : ''}
                    />

                    <TextField
                      style={styles.textbox}
                      sx={global.user_data.role_id != 104 ? styles.textInputPropsDisabled : styles.textInputProps}
                      label="Zip Code"
                      InputLabelProps={{ style: { color: global.user_data.role_id != 104 ? disabled_text_color : colors.inputted_text} }}
                      disabled={global.user_data.role_id != 104}
                      onChange={(data) => setZipCode(data.target.value.replace(/[^0-9-]/g, '').slice(0, 5))}
                      onBlur={() => setZipCode(zipCode.trim())}
                      value={zipCode}
                      error={zipCodeEmptyError || zipCodeLengthError}
                      helperText={zipCodeEmptyError ? 'Enter a zip code' :
                        zipCodeLengthError ? 'Zip Code must be 5 digits' : ''}
                    />

                    <TextField
                      style={{ visibility: 'hidden', ...styles.textbox }}
                      value={''}
                    />

                  </View>
                </View>

                <View style={styles.buttonDiv}>
                  <View style={{ left: '6%' }}>
                    <TouchableOpacity
                      style={styles.button}
                      onPress={() => setPageNum(1)}>
                      <Text style={styles.innerText}>Previous Page</Text>
                    </TouchableOpacity>
                  </View>

                  <View style={{ position: 'absolute', right: '6%' }}>
                    <TouchableOpacity
                      style={styles.button}
                      onPress={() => {
                        AddUser();
                      }}>
                      <Text style={styles.innerText}>Add User</Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </Scrollbars>
      </View>
  );
};

export default AddUser;
