export default {
    leader_dash_legend_container_light: "#ECECEC",
    leader_dash_legend_container_dark: "#494949",

    // Default colors
    defaultButton: "black",
    black: "#000000",
    blue: "#4287f5",
    light_blue: "#81aef7",
    navy_blue: "#0d1232",
    dark_gray: "#404040",
    white: "#FFFFFF",
    delete: "tomato",
    gold: '#ffc947',
    dark_gold: '#efa900',
    gray: '#f5f5f5',
    light_gray: '#D3D3D3',
    red: '#cc0000',
    purple: '#625364',
    loading_circle: '#625364',

    // Loading Circle
    loading_circle: '#625364',

    // **** Light Mode ****
    // Screen
    light_mode_screen_background: "#ffffff",

    // Header (back button, screen name, pfp)
    light_mode_screen_title: "#404040",

    // Textfields
    light_mode_textfield_background: "#ffffff",
    light_mode_textfield_background_locked: "#D3D3D3",
    light_mode_textfield_outline: "#404040",
    light_mode_user_inputted_text: "#000000",
    light_mode_placeholder_text: "#404040",

    // Buttons
    light_mode_button_text: "#000000",
    light_mode_button: "#D3D3D3",

    // Text
    light_mode_text: "#404040",
    light_mode_text_disabled: "#b0b0b0",

    // Other
    light_mode_modal_background: "#ffffff",
    light_mode_pdf_background: "#ffffff",
    light_mode_table_background_odd:"#E4E4E4",
    light_mode_table_background_even:"#EFEFEF",
    light_mode_table_background_button:"#D3D3D3",

    // Bottom Nav Bar
    light_mode_inactive_nav_bar_text: '#6F6F7A',
    light_mode_active_nav_bar_text: '#000000',
    light_mode_inactive_nav_bar: '#ffc947',
    light_mode_active_nav_bar: '#ffc947',

    // **** Dark Mode ****

    // Screen
    // dark_mode_screen_background: "#19171C",
    dark_mode_screen_background: "#1f1f1f",

    // Header (back button, screen name, pfp)
    dark_mode_screen_title: "#D7D8D9",

    // Textfields
    dark_mode_textfield_background: "#2a2a2a",
    dark_mode_textfield_background_locked: "#1f1f1f",
    dark_mode_textfield_outline: "#000000",
    dark_mode_user_inputted_text: "#b3bbc9",
    dark_mode_placeholder_text: "#b3bbc9",

    // Buttons
    dark_mode_button: "#000000",
    // dark_mode_button: "#1A1A1C",
    dark_mode_button_text: "#D7D8D9",
    // dark_mode_button_text: "#FFFFFF",

    // Text
    dark_mode_text: "#D7D8D9",
    dark_mode_text_disabled: "#909090",

    // Other
    dark_mode_modal_background: "#404040",
    pdf_mode_pdf_background: "#404040",
    dark_mode_table_background_odd:"#363636",
    dark_mode_table_background_even:"#262626",
    dark_mode_table_background_button:"#4c4c4c",

    // Bottom Nav Bar
    dark_mode_inactive_nav_bar_text: '#999',
    dark_mode_active_nav_bar_text: '#F7CD46',
    dark_mode_inactive_nav_bar: '#000',
    dark_mode_active_nav_bar: '#000',

    // Gold Button and text
    gold_button: '#ffc947',
    gold_button_text: '#000000',

    // Leader's dashboard
    leader_dash_light_gray: '#ECECEC',
    leader_dash_dark_gray: '#676A66',
    leader_dash_very_dark_gray: '#494949',
    leader_dash_darkmode_item: "#9F9F9F",

    // Table Colors
    light_mode_table_selected: '#d9e8fc !important',
    dark_mode_table_selected: '#4d6578 !important',

    light_mode_table_hover: '#e1edfc !important',
    dark_mode_table_hover: '#3a3f49 !important',
}