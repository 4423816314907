/* eslint-disable require-jsdoc */
import React, { useEffect, useReducer, useRef, useState, useMemo } from 'react';
import { View, Text, ScrollView, TouchableOpacity } from 'react-native';
import Modal from "react-native-modal";

import { useIsFocused } from '@react-navigation/native';
import { useScale } from '../../../components/scale';
import { delete_users, get_table_user, get_user_permissions } from '../../../api_handler/users'
import { get_all_unit_users, get_all_unit_names } from '../../../api_handler/units';
import { csv_upload, delete_s3, put_s3 } from '../../../api_handler/files';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Menu from "@mui/material/Menu";
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { styled, alpha, darken, createTheme, ThemeProvider, useTheme } from "@mui/material/styles";

import ModeContext from '../../../components/ModeContext';
import { useColorContext } from '../../../components/ColorContext'
import colors from '../../../colors';

import { Scrollbars } from 'react-custom-scrollbars';
import { MaterialReactTable, MRT_FullScreenToggleButton, MRT_ToggleGlobalFilterButton, MRT_ShowHideColumnsButton, MRT_ToggleFiltersButton } from 'material-react-table';
import { ExportToCsv } from 'export-to-csv';

import ArchiveIcon from "@mui/icons-material/Archive";
import DownloadIcon from "@mui/icons-material/Download";
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import ImportExportIcon from "@mui/icons-material/ImportExport";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import Users_Upload_Template from '../../../CSV_Templates/Users_Upload_Template.csv';
import NavSideBar from '../../../components/NavSideBar';
import { useSnackbar } from '../../../components/SnackbarContext';
import { useNavBarContext } from '../../../components/NavBarContext';
import * as Progress from 'react-native-progress'

export default function Users({ navigation }) {
    const { colors } = useColorContext();
    const { navState } = useNavBarContext();

    const [columnVisibility, setColumnVisibility] = useState({});
    const [loaded, setLoaded] = useState(false);
    const [density, setDensity] = useState('compact');
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 15 });
    const [showColumnFilters, setShowColumnFilters] = useState(false);
    const [showGlobalFilter, setShowGlobalFilter] = useState(false);
    const [showAddRow, setShowAddRow] = useState(true);
    const isFocused = useIsFocused();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const tableInstanceRef = useRef(null);
    const rerender = useReducer(() => ({}), {})[1]; // Forces the MRT_ components to re-render
    const [expandedUserData, setExpandedUserData] = useState({});
    const { mode } = React.useContext(ModeContext);

    const [rows, setRows] = React.useState([]);
    const [rowSelection, setRowSelection] = useState({});
    const [unitSelection, setUnitSelection] = useState([]);
    const [unitType, setUnitType] = useState([]);
    const [unitNames, setUnitNames] = React.useState([]);
    const [currentRows, setCurrentRows] = React.useState([]);

    const [hasViewPermission, sethasViewPermission] = React.useState();
    const [hasDeletePermission, sethasDeletePermission] = React.useState(false);
    const [FLoading, setFLoading] = useState(false);
    const [fetchedUserIds, setFetchedUserIds] = useState([]);
    const [deleteModalVis, setDeleteModalVis] = React.useState(false);
    const [usersToDelete, setUsersToDelete] = React.useState([]);
    const [rowsToDelete, setRowsToDelete] = React.useState([]);

    const { showSnackbar } = useSnackbar();

    // Retrieve all unit names and users when page is entered
    useEffect(() => {
        const fetchUnitNamesAndUsers = async () => {
            let initialUnit = -1;
            if (unitNames !== undefined && unitNames.length === 0) {
                initialUnit = await fetchUnitNames();
            }

            await fetchUnitUsers(initialUnit);
            setShowAddRow(true);
            setLoaded(true);
        };

        // Check that the user is entering the page (not exiting)
        if (isFocused) {
            fetchUnitNamesAndUsers();

        }

    }, [isFocused]);

    useEffect(() => {
        if (navState.canSeeUsers) {
            sethasViewPermission(true);
            sethasDeletePermission(true);
        }
    }, []);

    useEffect(() => {
        if (hasViewPermission === false) {
            showSnackbar("error", "Access Denied: User does not have correct permissions.");
            navigation.navigate('Home');
        }
    }, [hasViewPermission]);

    const StyledMenu = styled((props) => (
        <Menu
            elevation={0}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right"
            }}
            {...props}
        />
    ))(({ theme }) => ({
        "& .MuiPaper-root": {
            borderRadius: 6,
            marginTop: theme.spacing(1),
            minWidth: 180,
            color: colors.text,
            boxShadow:
                "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
            "& .MuiMenu-list": {
                padding: "4px 0",
                background: colors.textfield_background,
            },
            "& .MuiMenuItem-root": {
                "& .MuiSvgIcon-root": {
                    fontSize: 18,
                    color: colors.text,
                    marginRight: theme.spacing(1.5)
                },
                "&:active": {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        theme.palette.action.selectedOpacity
                    )
                },
                background: colors.textfield_background,
            }
        }
    }));

    // Create theme styling for table
    const globalTheme = useTheme();
    const tableTheme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: mode,
                    primary: globalTheme.palette.primary,
                    background: {
                        default: colors.textfield_background,
                    },
                },
            }),
        [globalTheme, isFocused]
    );

    const scale = useScale();
    const styles = ({
        tableWrapper: {
            height: '75%',
            width: '100%',
            justifyContent: 'center',
            marginTop: '2%'
        },
        tableContainer: {
            width: '90%',
            marginLeft: '5%',
            borderColor: colors.border_color,
            borderWidth: global.user_data.theme === 'dark' ? 1 : 0,
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.25,
            shadowRadius: 4,
        },
        container: {
            flex: 1,
            backgroundColor: colors.screen_background,
            flexDirection: 'row',
            height: '100%'
        },
        scrollView: {
            backgroundColor: colors.screen_background,
            flex: 10,
        },
        title: {
            fontSize: scale(30),
            fontFamily: 'Trebuchet MS',
            flex: 1,
            color: colors.text,
            paddingLeft: '5%',
            paddingTop: 25
        },
        selectAllbutton: {
            fontFamily: 'Trebuchet MS',
            color: mode == 'dark' ? colors.white : colors.black,
            borderColor: 'colors.button_border',
            borderWidth: 1,
            borderRadius: 10,
            backgroundColor: colors.button,
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.25,
            shadowRadius: 4,
        },
        descriptionAndFilterContainer: {
            flexDirection: 'row',
            marginTop: '1%',
            alignItems: 'flex-start',
            description: {
                fontFamily: 'Trebuchet MS',
                fontSize: scale(18),
                color: colors.text,
                paddingLeft: '5%',
                paddingRight: '5%',
                flex: 1
            },
            filterNote: {
                fontFamily: 'Trebuchet MS',
                paddingTop: '1%',
                color: colors.text
            },
        },
        header: {
            height: '150px',
        },
        main: {
            height: '100%',
            width: '100%',
        },
        titleDiv: {
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center',
        },
        buttonDiv: {
            flexDirection: 'row',
            // backgroundColor: 'red',
            height: '10%',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-around',
        },
        tableView: {
            width: '100%',
            alignItems: 'center',
            height: '80%',
        },
        tableDiv: {
            marginTop: '0%',
            backgroundColor: 'white',
            width: '90%',
            alignItems: 'center',
        },
        buttons: {
            width: '15%',
            backgroundColor: '#0d1232',
            borderRadius: '40',
            alignItems: 'center',
            color: 'white',
            borderRadius: 15,
            shadowColor: '#171717',
            shadowOffset: { width: -2, height: 4 },
            shadowOpacity: 0.2,
            shadowRadius: 3,
            marginTop: 30,
            marginBottom: 30,
        },
        innerText: {
            color: colors.button_text,
            fontWeight: 'bold',
            padding: 10,
        },
        modal: {
            backgroundColor: "white",
            justifyContent: 'center',
            alignItems: 'center',
            padding: 30,
        },
        modalText: {
            width: '60%',
            fontSize: scale(25),
            fontWeight: "bold",
            textAlign: "center",
            marginTop: '5%',
            marginBottom: '5%',
        },
        image: {
            flex: 1,
            width: '15%',
            resizeMode: 'contain',
        },
        modalButtonContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            width: '60%',
        },
        modalButton: {
            flex: 1,
            padding: '7px 30px 7px 30px',
            width: '20%',
        },
        buttonStyle: {
            width: '30%',
            backgroundColor: '#0d1232',
            borderRadius: '40',
            alignItems: 'center',
            color: 'white',
            borderRadius: 15,
            shadowColor: '#171717',
            shadowOffset: { width: -2, height: 4 },
            shadowOpacity: 0.2,
            shadowRadius: 3,
            padding: 5,
            marginBottom: '5%',
        },
        buttonText: {
            color: 'white',
            fontSize: scale(25),
        },
        textbox: {
            width: '66%',
            backgroundColor: colors.textfield_background,
        },
        checkBox: {
            height: "24px",
            color: colors.inputted_text,
        },
        deleteText: {
            fontSize: 25,
            fontFamily: 'Trebuchet MS',
            textAlign: 'center',
            color: colors.text,
        },
        confirmButtons:
        {
            width: '15%',
            borderRadius: 40,
            alignItems: 'center',
            backgroundColor: colors.button,
            shadowColor: '#171717',
            shadowOffset: { width: -2, height: 4 },
            shadowOpacity: 0.2,
            shadowRadius: 3,
            margin: '2%'
        },
        deleteModal:
        {
            width: '40%',
            height: '100%',
            marginLeft: '37%',
            marginTop: '5%',
            zIndex: 1000,
        },
        listItemTextTypography: {
            fontSize: "1rem",
            fontFamily: 'Trebuchet MS'
        },
        textInputProps: {
            '& .MuiFormLabel-root, & label.Mui-focused, & .MuiInputBase-input': {
                color: colors.inputted_text,
                // WebkitTextFillColor:  colors.inputted_text ,
                fontFamily: 'Trebuchet MS',

            },
            '.MuiSvgIcon-root': {
                fill: colors.inputted_text,
                fontFamily: 'Trebuchet MS',
            },
            '& .MuiOutlinedInput-root': {
                '&:hover fieldset': {
                    borderColor: colors.border_color,
                    fontFamily: 'Trebuchet MS',
                },
                '&.Mui-focused fieldset': {
                    borderColor: colors.border_color,
                    fontFamily: 'Trebuchet MS',
                },
                '& fieldset': {
                    borderColor: colors.border_color,
                },
            },
            "&  .MuiFormHelperText-root": {
                backgroundColor: colors.screen_background,
                fontFamily: 'Trebuchet MS',
                margin: 0,
                paddingLeft: 1
            },
            // .MuiFormHelperText-root.Mui-error
        },
        selectProps: {
            MenuProps: {
                sx: {
                    '& .MuiMenu-paper': {
                        background: colors.textfield_background,
                        color: colors.inputted_text,
                    },
                }
            }
        }
    });

    const columns = [
        {
            accessorKey: 'edit',
            header: 'Edit',
            size: 1,
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
            enableSorting: false,
            enableColumnFilter: false,
            enableGrouping: false,
            Cell: ({ row }) => {
                const [isHovered, setIsHovered] = React.useState(false);

                const handleMouseEnter = () => {
                    setIsHovered(true);
                };

                const handleMouseLeave = () => {
                    setIsHovered(false);
                };

                return (
                    <div
                        onClick={() => {
                            if (navState.canSeeUsers) {
                                let rowId = row.original.user_id;
                                navigation.navigate('admin_dashboard', { screen: 'edit_user', params: { user: rowId } });
                            } else {
                                // TODO:
                            }
                        }}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        <EditIcon style={{ color: isHovered ? 'green' : mode == 'dark' ? colors.white : colors.gray }} />
                    </div>
                );
            },
        },
        {
            accessorKey: 'dod',
            header: 'DOD',
            size: 1,
        },
        {
            accessorKey: 'rank',
            header: 'Rank',
            size: 1,
        },
        {
            accessorKey: 'last_name',
            header: 'Last Name',
            size: 1,
        },
        {
            accessorKey: 'first_name',
            header: 'First Name',
            size: 1,
        },
        {
            accessorKey: 'email',
            header: 'Email',
            size: 1,
        },
        {
            accessorKey: 'unit_name',
            header: 'Unit',
            size: 100,
        },
        {
            accessorKey: 'role',
            header: 'Role',
            size: 1,
        },
        {
            accessorKey: 'branch',
            header: 'Branch',
            size: 1,
        },
        {
            accessorKey: 'rcc',
            header: 'RCC',
            size: 1,
        },
        {
            accessorKey: 'mos',
            header: 'MOS',
            size: 1,
        },
    ]

    // Retrieves all users data and set initial rows 
    const fetchUnitUsers = async (initialUnit) => {
        try {
            const get_all_unit_users_JSON = {
                unit_ids: global.user_data.admin_unit_ids,
            };
            const unit_users = await get_all_unit_users(get_all_unit_users_JSON, global.token);
            if (unit_users.status === 200) {
                // Set initial current rows if units were just fetched
                console.log(initialUnit)
                if (initialUnit != -1) {
                    setCurrentRows(unit_users?.data?.body[initialUnit] || [])
                }
                else {
                    if (unitType.includes(0)) {
                        const allUserArrays = Object.values(unit_users.data.body);
                        setCurrentRows(allUserArrays.flat());
                    } else {
                        setCurrentRows(unitType.reduce((result, unitId) => {
                            const userArray = unit_users.data.body[unitId] || [];
                            return [...result, ...userArray];
                        }, []));
                    }
                }

                setRows(unit_users.data.body);
            } else {
                showSnackbar("error", "Application Error: Failed to retrieve all unit users")
                setCurrentRows([])
                setRows([])
            }

        }
        catch (error) {
            console.error('Error fetching unit users:', error);
        }
    };

    const fetchUnitNames = async () => {
        try {
            const unit_names_JSON = {
                'unit_id': 0,
                'get_top_unit': true,
                'get_path': true
            };

            // Retrieve all units and eliminate the ones the user does not have access to
            const unit_names_res = await get_all_unit_names(unit_names_JSON, global.token);
            if (unit_names_res.status !== 200) {
                showSnackbar("error", "Application Error: Failed to retrieve all units")
                return '';
            } else {
                const unit_ids_set = new Set(global.user_data.admin_unit_ids);
                const allUnitsOption = { unit_id: 0, unit_name: 'All Units' };
                const filteredUnits = unit_names_res.data.body.filter((unit) => unit_ids_set.has(unit.unit_id));

                if (filteredUnits.length > 1) {
                    setUnitNames([allUnitsOption, ...filteredUnits]);
                    setUnitSelection([allUnitsOption, ...filteredUnits]);
                } else {
                    setUnitNames([...filteredUnits]);
                    setUnitSelection([...filteredUnits]);
                }

                const unit = filteredUnits.find(unit => unit.unit_id === global.user_data.unit_id);
                if (unit) {
                    setUnitType([global.user_data.unit_id])
                    return global.user_data.unit_id;
                } else {
                    setUnitType([filteredUnits[0].unit_id]);
                    return filteredUnits[0].unit_id;
                }
            }
        }
        catch (error) {
            console.error('Error: ', error);
        }
    }

    const fileOpenMenu = (event) => {
        setAnchorEl(event.target);
    };

    const fileCloseMenu = () => {
        setAnchorEl(null);
    };

    function getCurrentFormattedDate() {
        const now = new Date();

        const day = String(now.getDate()).padStart(2, '0');
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Month is zero-based
        const year = now.getFullYear();

        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');

        return `${year}-${month}-${day}T${hours}_${minutes}`;
    }

    function formatTimeZoneDate(inputDateString) {
        if (!inputDateString) return ''

        const inputDate = new Date(inputDateString);

        const options = {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "2-digit", // Use "2-digit" for 24-hour format
            minute: "2-digit",
            timeZoneName: "short",
            hour12: false // Ensure 24-hour format
        };

        return inputDate.toLocaleString(undefined, options);
    }

    const csvOptions = {
        filename: getCurrentFormattedDate(),
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: ['DOD', 'Rank', 'Last Name', 'First Name', 'Email', 'Unit', 'Role', 'Branch', 'RCC', 'MOS'],
    };

    const csvExporter = new ExportToCsv(csvOptions);

    // Retrieve excel columns in correct order
    const handleExportData = () => {

        const desiredOrder = ['dod', 'rank', 'last_name', 'first_name', 'email', 'unit_name', 'role', 'branch', 'rcc', 'mos'];
        let rowsInDesiredOrder;
        for (let x = 0; x < unitType.length; x++) {
            rowsInDesiredOrder = rows[unitType[x]].map((item) => {
                const singleRow = {};
                desiredOrder.forEach((header) => {
                    singleRow[header] = item[header];
                });
                return singleRow;
            });
        }

        csvExporter.generateCsv(rowsInDesiredOrder);
        fileCloseMenu();
    };

    function uploadUserCSVFile() {
        let input = document.createElement('input');
        input.type = 'file';

        input.onchange = e => {
            let file = e.target.files[0];
            getUserCSV(file);
        }

        input.click();
    }

    function getUserCSV(file) {
        var reader = new FileReader();
        reader.readAsArrayBuffer(file);

        reader.onload = async function () {
            let binary = reader.result;
            setFLoading(true);
            uploadUsersCSV(binary);
        };

        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    async function uploadUsersCSV(binary) {

        if (navState.canSeeUsers) {
            var csv_filepath = 'add_user_csvs/' + global.user_data.user_id + '.csv';

            let data = {
                user_id: global.user_data.user_id,
                permissions: global.user_data.permissions,
            }

            let rev = await put_s3(csv_filepath, binary, 'text/csv', global.token);
            let response = await csv_upload(data, global.token);

            if (response !== null && response !== undefined) {
                setFLoading(false);

                showSnackbar("error", "Application Error: Failed to upload the users csv");
                window.location.reload();
            }
            else {
                showSnackbar("error", "Application Error: File Upload Error");
            }
        } else {
            showSnackbar("error", "error");
        }
    };

    const handleAdminUnitsChange = (selectedValues) => {
        const allUnitsIndex = selectedValues.indexOf(0);
        const previousAllUnitsIndex = unitType.indexOf(0);
        let updatedValues = [...selectedValues];

        if (allUnitsIndex !== -1 && previousAllUnitsIndex === -1 && selectedValues.length > 1) {
            updatedValues = [0];
        } else if (previousAllUnitsIndex !== -1 && selectedValues.length > 1) {
            updatedValues = selectedValues.filter(value => value !== 0);
        }

        if (updatedValues.length == 0) updatedValues = [0]; // If nothing is selected, this is like selecting All Units
        setUnitType(updatedValues);

        const filteredRows = updatedValues.reduce((result, unitId) => {
            if (unitId === 0) {
                return Object.values(rows).flat();
            } else {
                return result.concat(rows[unitId]);
            }
        }, []);

        setCurrentRows(filteredRows);
    };

    // const handleDeleteRows = async (rowsToDelete) => {

    //     // Dialog to ask user if they want to delete users
    //     const rowCount = rowsToDelete.length;

    //     // Get all names to delete
    //     let namestoDelete = [];
    //     rowsToDelete.forEach((row) => {
    //         namestoDelete.push(row.first_name + " " + row.last_name);
    //     });

    //     if (
    //         !confirm(rowCount === 1 ?
    //             `Are you sure you want to delete ${namestoDelete}?` :
    //             `Are you sure you want to delete ${rowCount} users: ${namestoDelete.join(', ')}?`
    //         )
    //     ) {
    //         return;
    //     }

    //     const delete_users_JSON = {
    //         admin_id: global.user_data.user_id,
    //         user_ids: userIDs,
    //         permissions: global.user_data.permissions
    //     }

    //     let res = await delete_users(delete_users_JSON, global.token);
    //     if (res.status !== 200) {
    //         showSnackbar("error", "Application Error: Failed to delete the user");
    //         return;
    //     } 

    //     showSnackbar("success", "Successfully deleted the user")

    //     // Update table data with deleted rows
    //     setCurrentRows(currentRows.filter((row) => !rowsToDelete.includes(row)));
    //     const updatedRows = { ...rows };
    //     selectedUnits.forEach(unit => {
    //         updatedRows[unit] = updatedRows[unit].filter(row => !rowsToDelete.includes(row));
    //     });
    //     setRows(updatedRows);

    //     // Get all Ids to delete
    //     let userIDs = [];
    //     rowsToDelete.forEach((row) => {
    //         userIDs.push(row.user_id);
    //     });
    // };

    function cancelDelete() {
        setRowsToDelete([]);
        // setBADatesToDelete([]);
        setDeleteModalVis(false);
    }

    const confirmDelete = async () => {
        setLoaded(false);
        setDeleteModalVis(false);

        // Get all Ids to delete
        let userIDs = [];
        rowsToDelete.forEach((row) => {
            userIDs.push(row.user_id);
        });

        const delete_users_JSON = {
            admin_id: global.user_data.user_id,
            user_ids: userIDs,
            permissions: global.user_data.permissions
        }

        let res = await delete_users(delete_users_JSON, global.token);
        setLoaded(true);
        if (res.status !== 200) {
            showSnackbar("error", "Application Error: Failed to delete the user");
            return;
        } else {
            showSnackbar("success", "Successfully deleted the user")
        }

        // Update table data with deleted rows
        setCurrentRows(currentRows.filter((row) => !rowsToDelete.includes(row)));
        const updatedRows = { ...rows };
        unitType.forEach(unit => {
            updatedRows[unit] = updatedRows[unit].filter(row => !rowsToDelete.includes(row));
        });
        setRows(updatedRows);
    };

    const fetchUserData = async (user_id) => {
        try {
            let res = await get_table_user({ user_id }, global.token);
            if (res.status !== 200) {
                showSnackbar("error", "Application Error: Failed to retrieve all users")
                return null;
            }

            return res.data.body;

        } catch (error) {
            console.error("Error fetching user data:", error);
            return null;
        }
    };

    const SectionHeader = ({ children }) => (
        <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            {children}
        </Typography>
    );

    const BottomField = ({ children }) => (
        <Typography style={{ marginBottom: '10px' }}>
            {children}
        </Typography>
    );

    const loadingStyles = {
        animation: {
            flexDirection: 'row',
            alignItems: 'center',
            flex: 1,
            justifyContent: 'center',
            paddingVertical: 20,
            background: colors.screen_background,
        }
    }

    if (!loaded) {
        return (
            <View style={loadingStyles.animation}>
                <Progress.CircleSnail
                    color={colors.loading_circle}
                    indeterminate={true}
                    size={275}
                    thickness={8}
                />
            </View>
        );
    }

    return (
        <View style={{ width: '100%', height: '100%' }}>
            <Scrollbars showsverticalscrollindicator="true" style={styles.scrollView}>
                <View style={styles.container}>
                    {/* <NavSideBar /> */}
                    {!hasViewPermission ? (
                        <View style={styles.main}>
                            <View style={styles.titleDiv}>
                                <Text style={styles.title}>Access Denied: User does not have permission to view this page.</Text>
                            </View>
                        </View>
                    ) : (
                        <View style={styles.main}>
                            <View style={styles.titleDiv}>
                                <Text style={styles.title}>
                                    <Text style={{ fontWeight: 'bold' }}>Admin Console {'>'} </Text>
                                    <Text>User Management</Text>
                                </Text>
                            </View>
                            <View style={styles.descriptionAndFilterContainer}>
                                <Text style={styles.descriptionAndFilterContainer.description}>
                                    This page allows admins to manage users under their Administration.
                                    You can add, view, update, and delete users that are visible in the table below. Users can
                                    also be filtered based on unit affiliation through the unit filter or based on various personal attributes
                                    through filters integrated within the table.
                                </Text>
                                <View style={{ flex: 1, flexDirection: 'column' }}>
                                    {(unitSelection.length > 0) ? (
                                        <View>
                                            <FormControl style={styles.textbox} sx={styles.textInputProps}>
                                                <InputLabel id="tables-units-label">Units Filter</InputLabel>
                                                <Select
                                                    inputProps={styles.selectProps}
                                                    labelId="tables-units-label"
                                                    multiple
                                                    value={unitType}
                                                    onChange={(value) => {
                                                        handleAdminUnitsChange(value.target.value);
                                                    }}
                                                    input={<OutlinedInput label="Units Filter" />}
                                                    renderValue={(selected) => {
                                                        const getSelectedLastComponent = (selectedName) => {
                                                            const nameComponents = selectedName.split(" -> ");
                                                            return nameComponents[nameComponents.length - 1];
                                                        };

                                                        return selected.map((value) => (
                                                            getSelectedLastComponent(unitSelection.find((item) => item.unit_id === value).unit_name)
                                                        )).join(", ");
                                                    }}
                                                >
                                                    {unitSelection.map((option) => (
                                                        <MenuItem
                                                            style={{ whiteSpace: "pre" }}
                                                            key={option.unit_id}
                                                            value={option.unit_id}
                                                            disabled={unitSelection.length === 1} // Disable the MenuItem if there's only one option
                                                        >
                                                            <Checkbox
                                                                sx={styles.checkBox}
                                                                checked={unitType.indexOf(option.unit_id) > -1}
                                                            />
                                                            <ListItemText primaryTypographyProps={{ style: styles.listItemTextTypography }} primary={option.unit_name} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </View>
                                    ) : (
                                        <View>
                                            <FormControl style={styles.textbox} sx={styles.textInputProps}>
                                                <InputLabel id="table-unit-temp-label">Units Filter</InputLabel>
                                                <Select
                                                    inputProps={styles.selectProps}
                                                    labelId="table-unit-temp-label"
                                                    value={""}
                                                    input={<OutlinedInput label="Units Filter" />}
                                                >
                                                </Select>
                                            </FormControl>
                                        </View>
                                    )}
                                    <Text style={styles.descriptionAndFilterContainer.filterNote}>
                                        Only users in the above selected units are displayed in the table
                                    </Text>
                                </View>
                            </View>
                            {/* Delete document modal */}
                            <Modal
                                isVisible={deleteModalVis}
                                style={styles.deleteModal}
                            >
                                <View style={{
                                    backgroundColor: colors.screen_background,
                                    alignItems: 'center',
                                    padding: '5%',
                                    borderRadius: 10,
                                    flexDirection: 'column',
                                }}>
                                    <View style={{ height: '50%', alignItems: 'center', marginTop: 25 }}>
                                        <Text style={styles.deleteText}>{usersToDelete.length === 1 ?
                                            `Are you sure you want to delete ${usersToDelete}?` :
                                            `Are you sure you want to delete ${usersToDelete.length} dates: ${usersToDelete.join(', ')}?`}</Text>
                                    </View>

                                    <View style={{
                                        flexDirection: 'row',
                                        width: '100%',
                                        justifyContent: 'space-around',
                                    }}>
                                        <TouchableOpacity
                                            style={styles.confirmButtons}
                                            onPress={() => cancelDelete()}>
                                            <Text style={styles.innerText}>Cancel</Text>
                                        </TouchableOpacity>

                                        <TouchableOpacity
                                            style={styles.confirmButtons}
                                            onPress={() => confirmDelete()}>
                                            <Text style={styles.innerText}>Submit</Text>
                                        </TouchableOpacity>
                                    </View>
                                </View>
                            </Modal>
                            {/* End of Delete Modal */}

                            <Scrollbars style={styles.tableWrapper} >
                                <View style={styles.tableContainer}>
                                    <ThemeProvider theme={tableTheme}>
                                        <MaterialReactTable
                                            columns={columns}
                                            data={currentRows}
                                            enableRowSelection
                                            positionToolbarAlertBanner={'bottom'}
                                            tableInstanceRef={tableInstanceRef}
                                            enableGrouping
                                            enableColumnDragging={false}
                                            enableColumnFilterModes
                                            enableRowActions
                                            enableStickyHeader
                                            enableExpandAll={false}
                                            selectAllMode={'all'}
                                            isMultiSortEvent={() => true}

                                            renderDetailPanel={({ row }) => {
                                                if (row.getIsExpanded()) {
                                                    const userId = row.original.user_id;
                                                    // This needs a refactor. If I remove !fetchedUserIds.includes(userId) this will 
                                                    // cause an infinite loop because when we expand the row it never closes. On the off
                                                    // chance we can't fetch the data from a userId we need to add more error handling which
                                                    // isn't in the scope of what I am tasked to do currently. What I did was add that extra
                                                    // line checking if we have fetched the userId already to stop unnecessary calls
                                                    if (!expandedUserData[userId] && !fetchedUserIds.includes(userId)) {
                                                        setFetchedUserIds(prevIds => [...prevIds, userId]);

                                                        fetchUserData(userId)
                                                            .then(userData => {
                                                                if (userData !== null) {
                                                                    setExpandedUserData(prevData => ({
                                                                        ...prevData,
                                                                        [userId]: userData
                                                                    }));
                                                                }
                                                            })
                                                            .catch(error => { });
                                                    }

                                                    // Render user data when available, or a loading message
                                                    const userData = expandedUserData[row.original.user_id];
                                                    return (
                                                        <View>
                                                            {userData ? (
                                                                <>
                                                                    <Box
                                                                        sx={{
                                                                            display: 'grid',
                                                                            margin: 'auto',
                                                                            gridTemplateColumns: '1fr 1fr',
                                                                            width: '100%',
                                                                        }}
                                                                    >
                                                                        <SectionHeader>Personal Information</SectionHeader>
                                                                        <br />
                                                                        <Typography>Middle Name: {userData.middle_name}</Typography>
                                                                        <BottomField>Phone Number: {userData.phone_number}</BottomField>

                                                                        <SectionHeader>Soldier Information</SectionHeader>
                                                                        <br />
                                                                        <Typography>Start Date (PEBD): {formatTimeZoneDate(userData.start_date)}</Typography>
                                                                        <Typography>Dental Date: {formatTimeZoneDate(userData.dent_date)}</Typography>
                                                                        <BottomField>Physical Health Assessment Date (PHA): {formatTimeZoneDate(userData.pha_date)}</BottomField>
                                                                        <Typography></Typography>

                                                                        <SectionHeader>Soldier Hierarchy</SectionHeader>
                                                                        <br />
                                                                        <Typography>Grade: {userData.grade}</Typography>
                                                                        <Typography>Superior's Unit: {userData.superior_unit_name}</Typography>
                                                                        <BottomField>Superior's Name: {userData.superior_info}</BottomField>
                                                                        <Typography></Typography>

                                                                        <SectionHeader>Command Organization</SectionHeader>
                                                                        <br />
                                                                        <Typography>Military Personnel Classification (MPC): {userData.mpc}</Typography>
                                                                        <Typography>Unit Identification Code (UIC): {userData.upc}</Typography>
                                                                        <Typography></Typography>
                                                                        <br />

                                                                        <SectionHeader>Readiness Information</SectionHeader>
                                                                        <br />
                                                                        <Typography>Incentive Pay: {userData.incentive_pay}</Typography>
                                                                        <Typography>ACIP Date: {formatTimeZoneDate(userData.acip_date)}</Typography>
                                                                        <Typography>Dental Readiness (DRC): {userData.drc}</Typography>
                                                                        <Typography>Medical Readiness (MRC): {userData.mrc}</Typography>
                                                                        <Typography>Flight Rated: {userData.flight_rated == "true" ? "Yes" : "No"}</Typography>
                                                                        <BottomField>Flight Status: {userData.flight_status == "true" ? "Yes" : "No"}</BottomField>

                                                                        <SectionHeader>Address</SectionHeader>
                                                                        <br />
                                                                        <Typography>Address Line 1: {userData.address_line_1}</Typography>
                                                                        <Typography>Address Line 2: {userData.address_line_2}</Typography>
                                                                        <Typography>State: {userData.state}</Typography>
                                                                        <Typography>City: {userData.city}</Typography>
                                                                        <Typography>Zip Code: {userData.zipcode}</Typography>
                                                                        <BottomField></BottomField>
                                                                    </Box>

                                                                    <Typography variant="h6" style={{ fontWeight: 'bold', marginTop: '10px' }}>Admin</Typography>
                                                                    <BottomField>Admin Privileges: {userData.admin_unit_names.join(', ')}</BottomField>
                                                                    <Typography>Permissions: {userData.permission_names.join(', ')}</Typography>
                                                                </>
                                                            ) : (
                                                                <Typography>Loading...</Typography>
                                                            )}
                                                        </View>
                                                    );
                                                }
                                            }}
                                            initialState={{
                                                sorting: [{ id: 'last_name', desc: false }],
                                                showGlobalFilter: false,
                                                columnOrder: ['mrt-row-select', 'mrt-row-expand', 'edit', 'dod', 'rank', 'last_name', 'first_name', 'email', 'unit_name', 'role', 'branch', 'rcc', 'mos']
                                            }}
                                            muiTableBodyProps={{
                                                sx: (theme) => ({
                                                    '& tr:nth-of-type(4n)': {
                                                        backgroundColor: colors.table_background_color,
                                                    },
                                                    '& tr:nth-of-type(4n - 2), & tr:nth-of-type(4n - 3)': {
                                                        backgroundColor: darken(theme.palette.background.default, 0.075),
                                                    },
                                                    '& tr.Mui-selected': {
                                                        backgroundColor: colors.table_selected,
                                                    },
                                                    '& tr:hover:not(.Mui-selected) > td': {
                                                        backgroundColor: colors.table_hover,
                                                    },
                                                }),
                                            }}

                                            // Table State Management for updater function
                                            onColumnVisibilityChange={(updater) => {
                                                setColumnVisibility((prev) =>
                                                    updater instanceof Function ? updater(prev) : updater
                                                );
                                                queueMicrotask(rerender); // Rerender after state update
                                            }}

                                            onDensityChange={(updater) => {
                                                setDensity((prev) =>
                                                    updater instanceof Function ? updater(prev) : updater
                                                );
                                                queueMicrotask(rerender); // Rerender after state update
                                            }}

                                            onRowSelectionChange={(updater) => {
                                                setRowSelection((prev) =>
                                                    updater instanceof Function ? updater(prev) : updater
                                                );
                                                queueMicrotask(rerender); // Rerender after state update
                                            }}

                                            onPaginationChange={(updater) => {
                                                setPagination((prev) =>
                                                    updater instanceof Function ? updater(prev) : updater
                                                );
                                                queueMicrotask(rerender); // Rerender after state update
                                            }}

                                            onShowColumnFiltersChange={(updater) => {
                                                setShowColumnFilters((prev) =>
                                                    updater instanceof Function ? updater(prev) : updater
                                                );
                                                queueMicrotask(rerender); // Rerender after state update
                                            }}

                                            onShowGlobalFilterChange={(updater) => {
                                                setShowGlobalFilter((prev) =>
                                                    updater instanceof Function ? updater(prev) : updater
                                                );
                                                queueMicrotask(rerender); // Rerender after state update
                                            }}

                                            state={{
                                                columnVisibility,
                                                density,
                                                rowSelection,
                                                pagination,
                                                showColumnFilters,
                                                showGlobalFilter,
                                            }}
                                            renderToolbarInternalActions={({ table }) => (
                                                <>
                                                    <Box>
                                                        <MRT_ToggleGlobalFilterButton table={tableInstanceRef.current} />

                                                        <Tooltip arrow title="Import/Export Data">
                                                            <IconButton onClick={fileOpenMenu}>
                                                                <ImportExportIcon />
                                                            </IconButton>
                                                        </Tooltip>

                                                        <StyledMenu anchorEl={anchorEl} open={open} onClose={fileCloseMenu}>
                                                            <a
                                                                id="myAnchor"
                                                                style={{
                                                                    textDecoration: "inherit",
                                                                    color: "inherit",
                                                                }}
                                                                href={Users_Upload_Template}
                                                                download="Users_Upload_Template"
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                <MenuItem disableRipple>
                                                                    <ArchiveIcon />
                                                                    Bulk Users Template
                                                                </MenuItem>
                                                            </a>

                                                            <MenuItem onClick={handleExportData} disableRipple>
                                                                <DownloadIcon />
                                                                Export Users Table
                                                            </MenuItem>

                                                            <MenuItem
                                                                onClick={() => {
                                                                    if (navState.canSeeUsers) {
                                                                        uploadUserCSVFile();
                                                                    } else {
                                                                        // TODO
                                                                    }
                                                                }}
                                                                disableRipple
                                                            >
                                                                <GroupAddIcon />
                                                                Upload Bulk Users List
                                                            </MenuItem>

                                                        </StyledMenu>

                                                        <MRT_ToggleFiltersButton table={tableInstanceRef.current} />
                                                        <MRT_ShowHideColumnsButton table={tableInstanceRef.current} />
                                                        <MRT_FullScreenToggleButton table={tableInstanceRef.current} />

                                                        {showAddRow ? (
                                                            <Tooltip arrow title="Add User">
                                                                <IconButton onClick={() => {
                                                                    if (navState.canSeeUsers) {
                                                                        setShowAddRow(false);

                                                                        // If one unit is selected... pass it to add user and set soldier's unit
                                                                        if (unitType.length == 1) {
                                                                            global.addUserSelectedUnit = unitType[0];
                                                                        }
                                                                        else {
                                                                            global.addUserSelectedUnit = null;
                                                                        }
                                                                        navigation.navigate('admin_dashboard', { screen: 'add_user' });
                                                                    } else {
                                                                        // TODO
                                                                    }
                                                                }
                                                                }
                                                                >
                                                                    <PersonAddAltIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        ) : null}

                                                    </Box>
                                                </>
                                            )}
                                            renderTopToolbarCustomActions={({ table }) => (
                                                <Box
                                                    sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                                                >
                                                    <Button
                                                        disabled={
                                                            table.getSelectedRowModel().rows.length === 0 ||
                                                            table.getSelectedRowModel().rows.length > 3
                                                        }
                                                        onClick={() => {
                                                            if (!hasDeletePermission) {
                                                                handleOpenAlert();
                                                            } else {
                                                                // Dialog to ask user if they want to delete users
                                                                const RowsToDelete = table.getSelectedRowModel().rows.map(row => row.original)
                                                                // Get all names to delete
                                                                let namestoDelete = [];
                                                                RowsToDelete.forEach((row) => {
                                                                    namestoDelete.push(row.first_name + " " + row.last_name);
                                                                });

                                                                setUsersToDelete(namestoDelete);
                                                                setRowsToDelete(RowsToDelete)
                                                                setDeleteModalVis(true)
                                                                table.setRowSelection([]);
                                                            }
                                                        }}
                                                        startIcon={<DeleteIcon />}
                                                        variant="contained"
                                                        style={{
                                                            color:
                                                                table.getSelectedRowModel().rows.length === 0 ?
                                                                    colors.new_table_color :
                                                                    colors.new_table_color_selected,
                                                            backgroundColor:
                                                                table.getSelectedRowModel().rows.length === 0 ?
                                                                    colors.new_table_bg_color : colors.new_table_bg_color_selected,
                                                        }}
                                                    >
                                                        Delete
                                                    </Button>

                                                </Box>
                                            )}
                                        />
                                    </ThemeProvider>
                                </View>
                            </Scrollbars>
                        </View>
                    )}
                </View>
            </Scrollbars>
        </View>
    );
};