/* eslint-disable indent */
/* eslint-disable max-len */
/* eslint-disable object-curly-spacing */
/* eslint-disable require-jsdoc */
import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import HomeScreen from '../pages/Home.js';
import RequestScreen from '../pages/Request.js';

import YourFilesScreen from '../pages/YourFiles.js';
import AwaitingAction from '../pages/AwaitingAction.js';

// RST screens
import CreateNewRST from '../pages/RST/CreateNewRST.js';
import RecommendRST from '../pages/RST/RecommendRST.js';
import ReviewRST from '../pages/RST/ReviewRST.js';
import RecommendRSTHome from '../pages/RST/RecommendRSTHome.js';
import ReviewRSTHome from '../pages/RST/ReviewRSTHome.js';
import RSTHome from '../pages/RST/RSTHome.js';

// EBDL/ATA/MDR screens
import CreateNewEBDL from '../pages/EBDL/CreateNewEBDL.js';
import ReviewEBDLHome from '../pages/EBDL/ReviewEBDLHome.js';
import ReviewEBDL from '../pages/EBDL/ReviewEBDL.js';

// 1380 screens
import Home1380 from '../pages/1380/Home1380.js';
import New1380 from '../pages/1380/New1380.js';
import RstTo1380 from '../pages/1380/RstTo1380.js';
import Review1380Home from '../pages/1380/Review1380Home.js';
import Review1380 from '../pages/1380/Review1380.js';
import Combined1380 from '../pages/1380/Combined1380.js';

import AddEvent from '../pages/AddEvent.js';
import EditEvent from '../pages/editEvent.js';
import ChangeEventStatus from '../pages/ChangeEventStatus.js';
import ViewComments from '../pages/ViewComments.js';

import ReviewScreen from '../pages/ReviewPage.js';
import ReportScreen from '../pages/ReportPage.js';
// import ChatScreen from '../pages/ChatPage.js';
import PayManagementScreen from '../pages/PayManagementPage.js';

import HelpScreen from '../pages/HelpPage.js';
import SHARPScreen from '../pages/SHARPpage.js';
import POCScreen from '../pages/POCsPage.js';
import MoreHelp from '../pages/MoreHelp.js';
import UserManual from '../pages/UserManual.js';
import BugReport from '../pages/BugReport.js';

// Settings pages
import SettingsScreen from '../pages/SettingsPage.js';

import CalendarPage from '../pages/CalendarPage.js';
// CCIR Pages 
import CCIR from '../pages/CommandCriticalInformationPage.js'
import CovidScreen from '../pages/CovidScreen.js';


// Leaders Dashboard Screens
// import LeaderScreen from '../pages/LeaderDash/LeadersPage.js';
import LeaderAdminOv from '../pages/LeaderDash/LeaderAdminOv.js';
import LeaderBudgetOv from '../pages/LeaderDash/LeaderBudgetOv.js';
import LeaderMedOv from '../pages/LeaderDash/LeaderMedOv.js';
import LeaderTrainingOv from '../pages/LeaderDash/LeaderTrainingOv.js';

const LeadersDashboardStack = createStackNavigator();

function LeadersDashboard() {
    return (
        <LeadersDashboardStack.Navigator>
            <LeadersDashboardStack.Screen name="document_overview" component={LeaderAdminOv} options={{ headerShown: false }} />
            <LeadersDashboardStack.Screen name="medical_overview" component={LeaderMedOv} options={{ headerShown: false }} />
            <LeadersDashboardStack.Screen name="training_overview" component={LeaderTrainingOv} options={{ headerShown: false }} />
            <LeadersDashboardStack.Screen name="budget_overview" component={LeaderBudgetOv} options={{ headerShown: false }} />
        </LeadersDashboardStack.Navigator>
    );
}

// Units Dashboard Screens
import EditFormsScreen from '../pages/admin_console/unit_information/edit_forms.js';
import UnitDocumentScreen from '../pages/admin_console/unit_information/unit_documents.js';
import UnitPOCScreen from '../pages/admin_console/unit_information/unit_poc.js';
import BADatesScreen from '../pages/admin_console/ba_dates/view_ba_dates.js';
import AddBADatesScreen from '../pages/admin_console/ba_dates/add_ba_dates.js';
import EditBADatesScreen from '../pages/admin_console/ba_dates/edit_ba_dates.js';

const UnitsDashboardStack = createStackNavigator();

function UnitsDashboard({route}) {
    return (
        <UnitsDashboardStack.Navigator>
            <UnitsDashboardStack.Screen name="edit_unit_forms_info" component={EditFormsScreen} options={{ headerShown: false }} />
            <UnitsDashboardStack.Screen name="unit_documents" component={UnitDocumentScreen} initialParams={route.params} options={{ headerShown: false }} />
            <UnitsDashboardStack.Screen name="usarc_documents" component={UnitDocumentScreen} initialParams={route.params} options={{ headerShown: false }} />
            <UnitsDashboardStack.Screen name="unit_POC" component={UnitPOCScreen} options={{ headerShown: false }} />
            <UnitsDashboardStack.Screen name="view_BA_dates" component={BADatesScreen} options={{ headerShown: false }} />
            <UnitsDashboardStack.Screen name="add_BA_dates" component={AddBADatesScreen} options={{ headerShown: false }} />
            <UnitsDashboardStack.Screen name="edit_BA_dates" component={EditBADatesScreen} options={{ headerShown: false }} />
        </UnitsDashboardStack.Navigator>
    );
}

// Admin Dashboard Screens
import ViewUsersScreen from '../pages/admin_console/users/view_users.js';
import AddUserScreen from '../pages/admin_console/users/add_user.js';
import EditUserScreen from '../pages/admin_console/users/edit_user.js';
import RolesScreen from '../pages/admin_console/roles/view_roles.js';
import ViewUnitsScreen from '../pages/admin_console/units/view_units.js';
import AddUnitScreen from '../pages/admin_console/units/add_unit.js';
import ViewLogsScreen from '../pages/admin_console/logs/view_logs.js';

const AdminDashboardStack = createStackNavigator();

function AdminDashboard() {
    return (
        <AdminDashboardStack.Navigator>
            <AdminDashboardStack.Screen name="view_users" component={ViewUsersScreen} options={{ headerShown: false }} />
            <AdminDashboardStack.Screen name="add_user" component={AddUserScreen} options={{ headerShown: false }} />
            <AdminDashboardStack.Screen name="edit_user" component={EditUserScreen} options={{ headerShown: false }} />
            <AdminDashboardStack.Screen name="roles" component={RolesScreen} options={{ headerShown: false }} />
            <AdminDashboardStack.Screen name="view_units" component={ViewUnitsScreen} options={{ headerShown: false }} />
            <AdminDashboardStack.Screen name="add_unit" component={AddUnitScreen} options={{ headerShown: false }} />
            <AdminDashboardStack.Screen name="audit_logs" component={ViewLogsScreen} options={{ headerShown: false }} />
        </AdminDashboardStack.Navigator>
    );
}

const Stack = createStackNavigator();

function AppStack() {
    return (
        <Stack.Navigator initialRouteName="Home">
            {/* UNUSED PAGES */}
            {/* <Stack.Screen name="Home1380" component={Home1380} options={{ headerShown: false }} />  */}
            {/* <Stack.Screen name="Review1380Home" component={Review1380Home} options={{ headerShown: false }} /> */}
            {/* <Stack.Screen name="ReviewEBDLHome" component={ReviewEBDLHome} options={{ headerShown: false}} /> */}
            {/* <Stack.Screen name="RecommendRSTHome" component={RecommendRSTHome} options={{ headerShown: false }} /> */}
            {/* <Stack.Screen name="ReviewRSTHome" component={ReviewRSTHome} options={{ headerShown: false }} /> */}
            {/* <Stack.Screen name="Review" component={ReviewScreen} options={{ headerShown: false }} /> */}
            {/* <Stack.Screen name="RSTHome" component={RSTHome} options={{ headerShown: false }} /> */}
            {/* <Stack.Screen name="Request" component={RequestScreen} options={{ headerShown: false }} /> */}
            {/* <Stack.Screen name="viewComments" component={ViewComments} options={{ headerShown: false }} /> */}
            {/* <Stack.Screen name="changeEventStatus" component={ChangeEventStatus} options={{ headerShown: false }} /> */}
            {/* <Stack.Screen name="addEvent" component={AddEvent} options={{ headerShown: false }} /> */}
            {/* <Stack.Screen name="editEvent" component={EditEvent} options={{ headerShown: false }} /> */}
            {/* <Stack.Screen name="MoreHelp" component={MoreHelp} options={{ headerShown: false }} /> */}
            {/* <Stack.Screen name="SoldierHelp" component={HelpScreen} options={{ headerShown: false }} /> */}
            {/* <Stack.Screen name="SHARP" component={SHARPScreen} options={{ headerShown: false }} /> */}
            {/* <Stack.Screen name="Report" component={ReportScreen} options={{ headerShown: false }} /> */}
            {/* <Stack.Screen name="CCIR" component={CCIR} options={{headerShown: false}} /> */}
            {/* <Stack.Screen name="CovidScreen" component={CovidScreen} options={{headerShown: false}} /> */}


            {/* Different Dashboards */}
            <Stack.Screen name="leaders_dashboard" component={LeadersDashboard} options={{ headerShown: false }} />
            <Stack.Screen name="units_dashboard" component={UnitsDashboard} options={{ headerShown: false }} />
            <Stack.Screen name="admin_dashboard" component={AdminDashboard} options={{ headerShown: false }} />

            <Stack.Screen name="Home" component={HomeScreen} options={{ headerShown: false }} />
            <Stack.Screen name="Settings" component={SettingsScreen} options={{ headerShown: false }} />
            <Stack.Screen name="Calendar" component={CalendarPage} options={{ headerShown: false }} />

            {/* Files related screens */}
            <Stack.Screen name="YourFiles" component={YourFilesScreen} options={{ headerShown: false }} />
            <Stack.Screen name="AwaitingAction" component={AwaitingAction} options={{ headerShown: false }} />

            {/* RST screens - could be in its own stack tbh */}
            <Stack.Screen name="CreateNewRST" component={CreateNewRST} options={{ headerShown: false }} />
            <Stack.Screen name="ReviewRST" component={ReviewRST} options={{ headerShown: false }} />
            <Stack.Screen name="RecommendRST" component={RecommendRST} options={{ headerShown: false }} />

            {/* 1380 screens - could be in its own stack tbh */}
            <Stack.Screen name="New1380" component={New1380} options={{ headerShown: false }} />
            <Stack.Screen name="RstTo1380" component={RstTo1380} options={{ headerShown: false }} />
            <Stack.Screen name="Combined1380" component={Combined1380} options={{ headerShown: false }} />
            <Stack.Screen name="Review1380" component={Review1380} options={{ headerShown: false }} />

            {/* EBDL screens - could be in its own stack tbh */}
            <Stack.Screen name="CreateNewEBDL" component={CreateNewEBDL} options={{ headerShown: false }} />
            <Stack.Screen name="ReviewEBDL" component={ReviewEBDL} options={{ headerShown: false}} />

            {/* Help screens - could be in its own stack tbh */}
            <Stack.Screen name="unit_POC" component={UnitPOCScreen} options={{ headerShown: false }} />
            <Stack.Screen name="UserManual" component={UserManual} options={{ headerShown: false }} />
            <Stack.Screen name="BugReport" component={BugReport} options={{ headerShown: false }} />

            
            <Stack.Screen name="PayManagement" component={PayManagementScreen} options={{ headerShown: false }} />

        </Stack.Navigator>
    );
};

export default AppStack;
