import { eventsAPI } from './api_settings';
import axios from 'axios';

/*
 * UNUSED API ENDPOINT
 * Reason: Took over the project and wasn't notified for reason the API isn't being used
 * Note: This endpoint was take over by the get_events API. When a user first logs in the
 * get_events API is called to get all events and then the app will create objects for each 
 * event and use that to display the edit event modal with the event details
 * Date: 3/12/2024
 * Author: Reynaldo Martinez
 */
export const get_event = async (data, token) => {
	const url_endpoint = eventsAPI + 'events/get_event';

	const header = {
		'Content-Type': 'application/json',
		'Authorization': token,
	};

	try {
		const response = await axios.post(url_endpoint, data, { headers: header });
		return response;
	} catch (error) {
		console.log(error.response)
		if (error.response) {
			return error.response;
		} else {
			return {
				statusCode: 500,
				body: 'An unexpected error occurred',
			};
		}
	}
};

/**
 * Fetches details for a all events for the logged in user
 *
 * @param {Object} data - data contains following information:
 * - user_id (int): Identifier for a user
 * - limit (string): Value that determines how many events we return
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 *
 * @example
 * get_event({ { "user_id": global.user_data.user_id, "limit": 200 }, global.idToken);
 */

export const get_events = async (data, token) => {
  const url_endpoint = eventsAPI + 'events/get_events';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
		const response = await axios.post(url_endpoint, data, { headers: header });
		return response;
	} catch (error) {
		console.log(error.response)
		if (error.response) {
			return error.response;
		} else {
			return {
				statusCode: 500,
				body: 'An unexpected error occurred',
			};
		}
	}
};

/*
 * UNUSED API ENDPOINT
 * Reason: Took over the project and wasn't notified for reason the API isn't being used
 * Note: This API was created for the mobile functionality but wasn't transfered over to web.
 * Specifically on mobile when you login the bottom of the screen you can see a list of
 * the events going on for today. That functionality is based on this endpoint. On web we show
 * upcoming events which is handled by the get_events API. If you wish to incorporate the
 * mobile logic look how mobile handles the return data from this endpoint and see if you can 
 * incorporate some of that logic here.
 * Date: 3/12/2024
 * Author: Reynaldo Martinez
 */
export const get_todays_events = async (data, token) => {
  const url_endpoint = eventsAPI + 'events/get_todays_events';

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  const res = await axios
    .post(url_endpoint, data, { headers: headers })
    .then((response) => response.data)
    .catch((error) => console.error(error.message));

  return res;
};

/**
 * Creates an event for any user, the user must have Calendar Permission to create events
 *
 * @param {Object} data - The event data containing the following properties:
 *  - scheduled_events_id (int): Identifier for the eventID
 *  - permission (int): The users permissions either 2052 or 0
 *  - organizer_id (int): Identifier for the event organizer
 *  - organizer_name (string): Name of the organizer
 *  - invitees_id (Array<int>): Array of invitees for the event
 *  - confirmed_id (Array<int>): Array of identifiers for confirmed attendees
 *  - title (string): Title of the event
 *  - location (string): Location of the event
 *  - description (string): Description of the event
 *  - period (boolean): Duration or period of the event
 *  - type (string): Type of the event [BA, ECT, Optional]
 *  - muta (int): Multiple Unit Training Assembly
 *  - remarks (string): Information related to the event
 *  - remarks_2 (string): Additional remarks
 *  - training_events (string): type of training event [Range, ACFT, CBRN Chamber, Driver's Training, Other]
 *  - starttime (isoFormat Date string): Start time of the event
 *  - endtime (isoFormat Date string): End time of the event
 *  - timestamp (isoFormat Date string): When the event was created
 *  - weekly (boolean): Indicates if the event occurs weekly
 *  - yearly (boolean): Indicates if the event occurs yearly
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 *
 */
export const create_event = async (data, token) => {
  const url_endpoint = eventsAPI + 'events/create_event';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
		const response = await axios.post(url_endpoint, data, { headers: header });
		return response;
	} catch (error) {
		console.log(error.response)
		if (error.response) {
			return error.response;
		} else {
			return {
				statusCode: 500,
				body: 'An unexpected error occurred',
			};
		}
	}
};

/**
 * Updates an event for any user, user must be calendar level permission to update
 * their own event
 *
 * @param {Object} data - The event data containing the following properties:
 *  - scheduled_events_id (int): Identifier for the eventID
 *  - permission (int): The users permissions either 2052 or 0
 *  - organizer_id (int): Identifier for the event organizer
 *  - organizer_name (string): Name of the organizer
 *  - invitees_id (Array<int>): Array of invitees for the event
 *  - confirmed_id (Array<int>): Array of identifiers for confirmed attendees
 *  - title (string): Title of the event
 *  - location (string): Location of the event
 *  - description (string): Description of the event
 *  - period (boolean): Duration or period of the event
 *  - type (string): Type of the event [BA, ECT, Optional]
 *  - muta (int): Multiple Unit Training Assembly
 *  - remarks (string): Information related to the event
 *  - remarks_2 (string): Additional remarks
 *  - training_events (string): type of training event [Range, ACFT, CBRN Chamber, Driver's Training, Other]
 *  - starttime (isoFormat Date string): Start time of the event
 *  - endtime (isoFormat Date string): End time of the event
 *  - timestamp (isoFormat Date string): When the event was created
 *  - weekly (boolean): Indicates if the event occurs weekly
 *  - yearly (boolean): Indicates if the event occurs yearly
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 *
 */
export const update_event = async (data, token) => {
  const url_endpoint = eventsAPI + 'events/update_event';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
		const response = await axios.put(url_endpoint, data, { headers: header });
		return response;
	} catch (error) {
		console.log(error.response)
		if (error.response) {
			return error.response;
		} else {
			return {
				statusCode: 500,
				body: 'An unexpected error occurred',
			};
		}
	}
};

/**
 * Deletes a single event
 *
 * @param {Object} data - Data contains following information:
 * - scheduled_events_id (int): Indentifier for the event we are deleting
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 *
 * @example
 * await delete_event({ "scheduled_events_id": global.eventID}, global.idToken);
 */
export const delete_event = async (data, token) => {
  const url_endpoint = eventsAPI + 'events/delete_event';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
		const response = await axios.delete(url_endpoint, {
			headers: header,
			data: data
		});
		return response;
	} catch (error) {
		console.log(error.response)
		if (error.response) {
			return error.response;
		} else {
			return {
				statusCode: 500,
				body: 'An unexpected error occurred',
			};
		}
	}
};

/*
 * UNUSED API ENDPOINT
 * Reason: Took over the project and wasn't notified for reason the API isn't being used
 * Note: This API is used on mobile but not on web. The ability to update the event invitees
 * is handled by the update_event endpoint and not this one. Theres no specific documentation on
 * why mobile has a specific endpoint for updating event invitees and web doesn't.
 * Date: 3/12/2024
 * Author: Reynaldo Martinez
 */
export const update_event_invitees = async (data, token) => {
  const url_endpoint = eventsAPI + 'events/update_event_invitees';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  await axios
    .put(url_endpoint, { headers: header, data: data })
    .then((response) => response.data)
    .catch((error) => console.error(error.message));
};

/*
 * UNUSED API ENDPOINT
 * Reason: Took over the project and wasn't notified for reason the API isn't being used
 * Note: This API was previously used by the ChangeEventStatus.js file but was ultimately 
 * scrapped by the previous team before giving it to our team. We weren't notified why the 
 * functionality was scrapped from web but it would make sense for web to have the same 
 * capabilites as mobile. Since we found it too late we don't have time to implement the 
 * functionality but for future teams look into incorporating the ability to accept or decline
 * event invites.
 * Date: 3/12/2024
 * Author: Reynaldo Martinez
 */
export const update_invite_status = async (data, token) => {
  const url_endpoint = eventsAPI + 'events/update_invite_status';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  await axios
    .put(url_endpoint, data, { headers: header })
    .then((response) => response.data)
    .catch((error) => console.error(error.message));
};

/*
 * UNUSED API ENDPOINT
 * Reason: Took over the project and wasn't notified for reason the API isn't being used
 * Note: This API was previously used by the ViewComments.js file but was ultimately 
 * scrapped by the previous team before giving it to our team. We weren't notified why the 
 * functionality was scrapped from web but it would make sense for web to have the same 
 * capabilites as mobile. Since we found it too late we don't have time to implement the 
 * functionality but for future teams look into incorporating the ability to see the comments
 * left by invitees.
 * Date: 3/12/2024
 * Author: Reynaldo Martinez
 */
export const get_invitees_status = async (data, token) => {
  const url_endpoint = eventsAPI + 'events/get_invitees_status';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  const res = await axios
    .post(url_endpoint, data, { headers: header })
    .then((response) => response.data)
    .catch((error) => console.error(error.message));
  return res;
};

/**
 * Sends a bug report eamil to the project mercury gmail account
 *
 * @param {Object} data - Data contains following information:
 * - subject (string): Information that will appear in the email subject line
 * - bugReport (string): This is the body of the email, will contain the Name and Email of the sender along with a description
 * - attachments (base64 image): If the user wishes to attach any images its stored here
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 */
export const ses_email = async (data, token) => {
  const url_endpoint = eventsAPI + 'events/ses_email';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
		const response = await axios.post(url_endpoint, data, { headers: header });
		return response;
	} catch (error) {
		console.log(error.response)
		if (error.response) {
			return error.response;
		} else {
			return {
				statusCode: 500,
				body: 'An unexpected error occurred',
			};
		}
	}
};


/**
 * Allows a user to create a Battle Assembly date for the use of RST forms.
 * If the event isn't a BA date it will go into the calendar but still handled 
 * by this endpoint
 *
 * @param {Object} data - Data contains following information:
 *  - event (string): The type of event [BA, ECT, Other]
 *  - mandatory (boolean): Determines if this event is mandatory for all soldiers
 *  - start_date (ISO Date): When the event starts
 *  - end_date (ISO Date): When the event ends
 *  - location (string): Where the event will take place
 *  - MUTA (string): The amount of MUTAs this event will complete
 *  - training_events (string): The description of the event
 *  - RST_authorized (boolean): Variable to determine if this is allowed to be used for RST
 *  - remarks1 (string): Any comments made by the creator
 *  - remarks2 (string): Any additional comments made by the creator
 *  - user_id (int): The identifier for the creator of the event
 *  - user_full_name (string): The name of the creator of the event
 *  - unit_id (int): What unit this creator is apart of, will show up for the unit specified and no other
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 */
export const add_ba_date = async (data, token) => {
  const url_endpoint = eventsAPI + 'events/add_ba_date';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
		const response = await axios.post(url_endpoint, data, { headers: header });
		return response;
	} catch (error) {
		console.log(error.response)
		if (error.response) {
			return error.response;
		} else {
			return {
				statusCode: 500,
				body: 'An unexpected error occurred',
			};
		}
	}
};

/**
 * Fetches all BA Dates created for a particular unit
 *
 * @param {Object} data - Data contains following information:
 *  - unit_id (int): What unit the user is apart of
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 */
export const get_all_ba_dates = async (data, token) => {
  const url_endpoint = eventsAPI + 'events/get_all_ba_dates';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
		const response = await axios.post(url_endpoint, data, { headers: header });
		return response;
	} catch (error) {
		console.log(error.response)
		if (error.response) {
			return error.response;
		} else {
			return {
				statusCode: 500,
				body: 'An unexpected error occurred',
			};
		}
	}
};

/**
 * Deletes a BA Date from the database, its passed as an array of BA Dates
 * at least one BA date is necessary for this API
 *
 * @param {Object} data - Data contains following information:
 *  - start_date (ISO Date): When the event starts
 *  - end_date (ISO Date): When the event ends
 *  - training_events (string): The description of the event
 *  - unit_id (int): What unit this creator is apart of, will show up for the unit specified and no other
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 */
export const delete_ba_dates = async (data, token) => {
  const url_endpoint = eventsAPI + 'events/delete_ba_dates';
  data = JSON.stringify(data);

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
		const response = await axios.delete(url_endpoint, {
			headers: header,
			data: data
		});
		return response;
	} catch (error) {
		console.log(error.response)
		if (error.response) {
			return error.response;
		} else {
			return {
				statusCode: 500,
				body: 'An unexpected error occurred',
			};
		}
	}
};

/**
 * Fetches a single BA date for the user to edit the existing information
 *
 * @param {Object} data - Data contains following information:
 *  - start_date (ISO Date): When the event starts
 *  - end_date (ISO Date): When the event ends
 *  - training_events (string): The description of the event
 *  - unit_id (int): What unit this creator is apart of, will show up for the unit specified and no other
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 */
export const get_single_ba_date = async (data, token) => {
  const url_endpoint = eventsAPI + 'events/get_single_ba_date';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
		const response = await axios.post(url_endpoint, data, { headers: header });
		return response;
	} catch (error) {
		console.log(error.response)
		if (error.response) {
			return error.response;
		} else {
			return {
				statusCode: 500,
				body: 'An unexpected error occurred',
			};
		}
	}
};

/**
 * Allows a user to update a previously created Battle Assembly date for the use of RST forms.
 * If the event isn't a BA date it will go into the calendar but still handled 
 * by this endpoint
 *
 * @param {Object} data - Data contains following information:
 *  - event (string): The type of event [BA, ECT, Other]
 *  - mandatory (boolean): Determines if this event is mandatory for all soldiers
 *  - start_date (ISO Date): When the event starts
 *  - end_date (ISO Date): When the event ends
 *  - location (string): Where the event will take place
 *  - MUTA (string): The amount of MUTAs this event will complete
 *  - training_events (string): The description of the event
 *  - RST_authorized (boolean): Variable to determine if this is allowed to be used for RST
 *  - remarks1 (string): Any comments made by the creator
 *  - remarks2 (string): Any additional comments made by the creator
 *  - user_id (int): The identifier for the creator of the event
 *  - user_full_name (string): The name of the creator of the event
 *  - unit_id (int): What unit this creator is apart of, will show up for the unit specified and no other
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 */
export const update_ba_date = async (data, token) => {
  const url_endpoint = eventsAPI + 'events/update_ba_date';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
		const response = await axios.put(url_endpoint, data, { headers: header });
		return response;
	} catch (error) {
		console.log(error.response)
		if (error.response) {
			return error.response;
		} else {
			return {
				statusCode: 500,
				body: 'An unexpected error occurred',
			};
		}
	}
};
