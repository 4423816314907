import React from 'react';
import {View, Text, StyleSheet} from 'react-native';

const NumberBadge = ({count}) => {
  const styles = StyleSheet.create({
    badge: {
      backgroundColor: 'red',
      borderRadius: 50,
      justifyContent: 'center',
      alignItems: 'center',
      paddingRight: 5,
      paddingLeft: 5,
      padding: 10,
      width: 45,
      fontSize: 12,
    },
  });

  return (
    <View style={styles.badge}>
      {count > 0 && <Text style={{color: 'white'}}>{count}</Text>}
    </View>
  );
};

export default NumberBadge;
