/* eslint-disable comma-dangle */
/* eslint-disable no-trailing-spaces */
/* eslint-disable linebreak-style */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-key */
/* eslint-disable new-cap */
/* eslint-disable valid-jsdoc */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable require-jsdoc */
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useRoute } from "@react-navigation/native";
import { View, Text, Image, TouchableOpacity } from "react-native";
import { TextInput, HelperText } from "react-native-paper";
import { ClickAwayListener } from "@mui/base";
import PropTypes from "prop-types";
import { useFocusEffect } from "@react-navigation/native";
import { en, registerTranslation } from "react-native-paper-dates";
import {
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    createTheme,
    ThemeProvider,
    useTheme,
    Paper,
    List,
    Autocomplete,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { useIsFocused } from "@react-navigation/native";
import { useScale } from "../../components/scale";
import ModeContext from "../../components/ModeContext";
import CheckboxList from "./CheckboxList";
import { useColorContext } from "../../components/ColorContext";
import {
    upload_file,
    update_file,
    get_single_file,
} from "../../api_handler/files";
import { create_notification } from "../../api_handler/notifications";
import {
    get_all_users_in_single_unit,
    get_forms_related_info_of_a_unit,
} from "../../api_handler/units";
import { get_all_ba_dates } from "../../api_handler/events";
import { pdfIn, generate_rst } from "../generatePDF.js";
import * as form_rst from "../../PDFs/RST_request_form_raw";
import Scrollbars from "react-custom-scrollbars";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useSnackbar } from "../../components/SnackbarContext";
import FormHelperText from "@mui/material/FormHelperText";

import TextField from "@mui/material/TextField";
import ViewShot from "react-native-view-shot";
import { captureRef } from "react-native-view-shot";
import { create_new_user_signature } from "../../api_handler/users.js";
import { put_image_s3 } from "../../api_handler/files";
import { storeJsonData } from "../../api_handler/asyncstorage.js";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavBarContext } from "../../components/NavBarContext.js";
import * as Progress from "react-native-progress";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import LieuDateSelect from "./TableComponents/LieuSelectComponent.js";
import { Dimensions } from "react-native";
import TableCellComponent from "./TableComponents/TableCellComponent.js";

registerTranslation("en", en);

function CreateNewRST({ navigation }) {
    const route = useRoute();
    const { width, height } = Dimensions.get("window");
    const { colors } = useColorContext();
    const { navstate } = useNavBarContext();
    const isFocused = useIsFocused();
    const { showSnackbar } = useSnackbar();
    const { draftFormID = "NONE", updatingSubmission } = route.params || {};

    const MONTHTONUM = new Map([
        ["JAN", "01"],
        ["FEB", "02"],
        ["MAR", "03"],
        ["APR", "04"],
        ["MAY", "05"],
        ["JUN", "06"],
        ["JUL", "07"],
        ["AUG", "08"],
        ["SEP", "09"],
        ["OCT", "10"],
        ["NOV", "11"],
        ["DEC", "12"],
    ]);

    const scale = useScale();
    const styles = {
        container: {
            height: "100%",
            width: "100%",
            flexDirection: "row",
            backgroundColor: colors.screen_background,
        },
        wrapper: {
            flexDirection: "row",
            height: "100%",
            width: "100%",
        },
        scrollView: {
            height: "100%",
            width: "100%",
        },
        mainWrapper: {
            flexDirection: "column",
            alignSelf: "center",
        },
        header: {
            marginTop: "0%",
            height: 110,
            backgroundColor: colors.screen_background,
        },
        pageTitle: {
            fontSize: scale(30),
            fontFamily: "Trebuchet MS",
            flex: 1,
            color: colors.text,
            paddingLeft: "5%",
            paddingTop: 25,
        },
        description: {
            fontFamily: "Trebuchet MS",
            fontSize: scale(18),
            color: colors.text,
            paddingLeft: "5%",
            paddingRight: "5%",
        },
        signText: {
            fontWeight: "bold",
            fontSize: scale(14),
            textAlign: "center",
            paddingLeft: ".5%",
            paddingRight: "2%",
            color: colors.bow,
            alignSelf: "center",
        },
        entryBox: {
            borderColor: colors.border_color,
            borderRadius: 5,
            borderWidth: 1.5,
            justifyContent: "center",
            alignItems: "center",
            padding: 5,
            paddingLeft: 5,
            paddingRight: 5,
            margin: 10,
            marginTop: 0,
            width: "95%",
        },
        denialComponent: {
            borderColor: colors.border_color,
            borderRadius: 5,
            borderWidth: 1.5,
            justifyContent: "center",
            alignSelf: "center",
            padding: 5,
            paddingLeft: 5,
            paddingRight: 5,
            margin: 10,
            marginTop: "2%",
            marginBottom: "-1%",
            width: "50%",
        },
        denialHeader: {
            fontWeight: "bold",
            fontSize: scale(16),
            textAlign: "center",
            margin: "1%",
            paddingLeft: "5%",
            paddingRight: "5%",
            color: colors.bow,
        },
        denialText: {
            fontSize: scale(16),
            textAlign: "center",
            margin: "1%",
            color: colors.file_Denied,
        },
        button: {
            color: colors.text,
            width: width > 1608 ? "10vw" : "12vw",
            borderRadius: 10,
            padding: 10,
            paddingLeft: 20,
            paddingRight: 20,
            backgroundColor: submitted ? colors.grey : colors.content_box,
            margin: 8,
            marginBottom: 15,
            borderColor: colors.border_color,
            borderWidth: 1,
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.25,
            shadowRadius: 4,
            alignSelf: "center",
        },
        signDocButton: {
            color: colors.text,
            width: width > 1608 ? "10vw" : "12vw",
            borderRadius: 10,
            padding: 10,
            paddingLeft: 20,
            paddingRight: 20,
            backgroundColor: submitted ? colors.grey : colors.action_button,
            borderColor: colors.border_color,
            borderWidth: 1,
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.25,
            shadowRadius: 4,
            textAlign: "center",
            alignSelf: "center",
            marginTop: "1%",
            marginRight: "1%",
        },
        mutaButton: {
            color: colors.text,
            borderRadius: 3,
            padding: 15.22,
            paddingLeft: 14.8,
            paddingRight: 14.8,
            backgroundColor: submitted ? colors.grey : colors.action_button,
            borderColor: colors.border_color,
            borderWidth: 1,
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.25,
            shadowRadius: 4,
            justifyContent: "center",
        },
        cancelButton: {
            width: width > 1608 ? "10vw" : "12vw",
            borderRadius: 10,
            padding: 10,
            paddingLeft: 20,
            paddingRight: 20,
            backgroundColor: colors.modal_background,
            margin: 8,
            marginBottom: 15,
            borderColor: colors.border_color,
            borderWidth: 1,
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.25,
            shadowRadius: 4,
            alignSelf: "center",
        },
        textStyle: {
            justifyContent: "center",
            fontSize: 15,
            fontWeight: "bold",
            color: colors.text,
            textAlign: "center",
        },
        textStyleNormal: {
            justifyContent: "center",
            fontSize: 18,
            color: colors.text,
        },
        mutaTextStyle: {
            justifyContent: "center",
            fontSize: 16.5,
            color: colors.text,
        },
        dropDownStyleOther: {
            borderColor: colors.border_color,
            borderWidth: 1,
            borderRadius: 5,
            width: "97.5%",
            marginTop: "1%",
            backgroundColor: colors.modal_background,
        },
        dropDownStyle: {
            borderColor: colors.border_color,
            borderWidth: 1,
            borderRadius: 5,
            width: "95%",
            marginTop: "1%",
            backgroundColor: colors.modal_background,
        },
        dropDownMenu: {
            backgroundColor: colors.bow,
        },
        textInputProps: {
            "& .MuiFormLabel-root, & label.Mui-focused, & .MuiInputBase-input":
            {
                color: colors.inputted_text,
                // WebkitTextFillColor:  colors.inputted_text ,
            },
            ".MuiSvgIcon-root": {
                fill: colors.inputted_text,
            },
            "& .MuiOutlinedInput-root": {
                "& fieldset": {
                    borderColor: colors.border_color,
                },
                "&:hover fieldset": {
                    borderColor: colors.border_color,
                },
                "&.Mui-focused fieldset": {
                    borderColor: colors.border_color,
                },
            },
            "&  .MuiFormHelperText-root": {
                backgroundColor: colors.screen_background,
                margin: 0,
                paddingLeft: 1,
            },
            // .MuiFormHelperText-root.Mui-error
        },
        commentInputOther: {
            borderColor: colors.border_color,
            minHeight: "fit-content",
            borderWidth: 1,
            borderRadius: 5,
            marginTop: "1%",
            backgroundColor: colors.modal_background,
            width: "97.5%",
        },
        commentInput: {
            borderColor: colors.border_color,
            minHeight: "fit-content",
            borderWidth: 1,
            borderRadius: 5,
            marginTop: "1%",
            backgroundColor: colors.modal_background,
            width: "95%",
            height: 54,
        },
        rstContainer: {
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            marginLeft: "1.25%",
            marginTop: "1%",
        },
        contentContainer: {
            flexDirection: "column",
            flex: 1,
        },
        contentBox: {
            alignSelf: "center",
            backgroundColor: colors.screen_background,
            height: "100%",
            width: "90%",
            marginTop: "2%",
            borderRadius: 10,
            justifyContent: "center",
            // padding: "2.5%",
            paddingVertical: "2.5%",
            paddingRight: "2.5%",
            paddingLeft: "3%",
            borderColor: colors.border_color,
            borderRadius: 5,
            borderWidth: 1,
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.25,
            shadowRadius: 4,
        },
        checkboxContainer: {
            flexDirection: "row",
            fontSize: 18,
            alignItems: "center",
            flex: 1,
            padding: 10,
        },
        selectProps: {
            MenuProps: {
                sx: {
                    "& .MuiMenu-paper": {
                        background: colors.textfield_background,
                        color: colors.inputted_text,
                    },
                },
            },
        },
        tableContainer: {
            flexDirection: "column",
            alignSelf: "center",
            width: "65%",
            marginLeft: "2.6%",
        },
        tableDiv: {
            marginTop: ".9%",
            // marginLeft: '5%',
            width: "90.3%",
            borderColor: colors.border_color,
            borderWidth: 1,
            borderRadius: 5,
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.25,
            shadowRadius: 4,
            backgroundColor: colors.modal_background,
        },
        rowContainer: {
            flexDirection: "row",
            marginTop: 15,
            marginBottom: 10,
            justifyContent: "center",
            // marginRight: '1%',
        },
        sectionDiv: {
            marginTop: "1.5%",
            // marginBottom: "1%",
            alignItems: "flex-start",
            left: "6%",
            width: "88%",
            height: "40px",
            overflow: "hidden",
        },
        sectionContainer: {
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
            width: "100%",
            paddingTop: 10,
        },
        horizontalLine: {
            // top: "30px",
            alignSelf: "center",
            marginBottom: "2%",
            width: "87%",
            height: "2px",
            backgroundColor: colors.border_color,
            position: "absolute",
            marginRight: "2.25%",
        },
        rmaDateBox: {
            "& .MuiFormLabel-root, & label.Mui-focused, & .MuiInputBase-input":
            {
                color: colors.inputted_text,
            },
            ".MuiSvgIcon-root": {
                fill: colors.inputted_text,
            },
            "& .MuiOutlinedInput-root": {
                "& fieldset": {
                    borderColor: colors.border_color,
                },
                "&:hover fieldset": {
                    borderColor: colors.border_color,
                },
                "&.Mui-focused fieldset": {
                    borderColor: colors.border_color,
                },
            },
            "&  .MuiFormHelperText-root": {
                backgroundColor: colors.modal_background,
            },
        },
    };

    const theme = useTheme();
    const reviewTheme = createTheme({
        palette: {
            mode: colors.mode,
            primary: theme.palette.primary,
        },
    });

    // const [showDatePicker, setShowDatePicker] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const { mode, toggleMode } = React.useContext(ModeContext);
    const [officers, setOfficers] = useState([]);
    const [commanders, setCommanders] = useState([]);
    const [recommender, setRecommender] = useState("");
    const [recommenderTypeEmptyError, setrecommenderTypeEmptyError] =
        useState(false);
    const [reviewer, setReviewer] = useState("");
    const [reviewerTypeEmptyError, setreviewerTypeEmptyError] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const [muta, setMUTA] = useState("");
    const [MUTAEmptyError, setMUTAEmptyError] = useState(false);

    const [ATAs, setATAs] = useState("");
    const [ATAEmptyError, setATAEmptyError] = useState(false);
    const [selectedType, setSelectedType] = useState("");
    const [selectedTypeEmptyError, setselectedTypeEmptyError] = useState(false);
    const [mutaMatch, setMutaMatch] = useState(true);
    const [justification, setJustification] = useState("");
    const [justificationTypeEmptyError, setjustificationTypeEmptyError] =
        useState(false);
    const [soldierNotes, setSoldierNotes] = useState("");
    const [soldierNotesEmptyError, setSoldierNotesEmptyError] = useState(false);
    const [tasks, setTasks] = useState("");
    const [taskTypeEmptyError, settaskTypeEmptyError] = useState(false);

    const [expandedDates, setExpandedDates] = useState([]);
    const [selectedRanges, setSelectedRanges] = useState([]);

    const [lieuBADates, setLieuBADates] = React.useState([]); // Needs to start as empty array
    const [lieuBADatesEmptyError, setlieuBADatesEmptyError] =
        React.useState(false);
    const [rstMUTA, setRSTMUTA] = useState([]); // Needs to start as empty array
    const [rstMUTAEmptyError, setrstMUTAEmptyError] = useState(false);

    const [toBePerformedOnDates, setToBePerformedOnDates] = useState([]);
    const [toBePerformedOnDatesEmptyError, settoBePerformedOnDatesEmptyError] =
        useState(false);
    const [toBePerformedOnMUTAEmptyError, settoBePerformedOnMUTAEmptyError] =
        useState(false);
    const dateToday = moment().format("MM/DD/YY");

    const [viewSig, setViewSig] = useState(false);
    const [pulledFromDraft, setPulledFromDraft] = useState(false);
    const [baDates, setBaDates] = useState([]);
    const [BADateEmptyError, setBADateEmptyError] = React.useState(false);
    const [unitInfo, setUnitInfo] = useState(null);

    const [doesUserHaveSignature, setDoesUserHaveSignature] = useState(false);
    const [didUserSignDocument, setDidUserSignDocument] = useState(false);
    const [signatureName, setSignatureName] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [failedToRetrieve, setFailedToRetrieve] = useState(false);
    const [selectedBADate, setSelectedBADate] = useState(null);

    const [ataFormattedDate, setATAFormattedDate] = useState("");
    const [ataSelectedDate, setATASelectedDate] = useState(null);
    const [ataDatePickerOpen, setATADatePickerOpen] = useState(false);
    const [ataDateError, setATADateError] = useState(false);
    const [rmaFormattedDate, setRMAFormattedDate] = useState("");
    const [rmaSelectedDate, setRMASelectedDate] = useState(null);
    const [rmaDatePickerOpen, setRMADatePickerOpen] = useState(false);
    const [rmaDateError, setRMADateError] = useState(false);
    const [hideDraftButton, setHideDraftButton] = useState(false);

    const [reasonForDenial, setReasonForDenial] = useState(""); // Only set if you're resubmitting a denied form

    /** *
     * This object is used for determine the values of the row
     * lieuBA: is for the dropdown select for ba dates
     * lieuBAMUTA: is for the muta checkboxes
     * toBePerformedOn: becomes an object that has the performedOnDate in DDMMMYYYY and its mutas,
     *                so this object is used to highlight the
     * id: To track the row index
     *
     */
    const [rows, setRows] = useState([
        {
            lieuBA: "",
            lieuBAMUTA: 0,
            toBePerformedOn: "",
            id: Date.now(),
        },
    ]);

    /** *
     * This object is used for determine what components in the table row to highlight.
     * lieuBA: is for the dropdown select for ba dates
     * lieuBAMUTA: is for the muta checkboxes
     * toBePerformedOnEmpty: used to deduce whether a performedOnDate has been chosen and highlight datePicker
     * toBePerformedOnMUTA: used to highlight muta check box for performedOnDate if empty
     *
     */
    const [highlightedRows, setHighlightedRows] = useState([
        {
            lieuBA: false,
            lieuBAMUTA: false,
            toBePerformedOnEmpty: false,
            toBePerformedOnMUTA: false,
        },
    ]);

    const viewRef = useRef();
    const rmaDatePickerRef = useRef(null);
    const ataDatePickerRef = useRef(null);

    useFocusEffect(
        React.useCallback(() => {
            // This effect is used to prevent navigation instantly if date picker is open
            const beforeRemoveListener = (e) => {
                if (!rmaDatePickerOpen && !ataDatePickerOpen) {
                    return;
                }

                e.preventDefault();

                setRMADatePickerOpen(false);
                setATADatePickerOpen(false);
                setTimeout(() => {
                    navigation.dispatch(e.data.action);
                }, 200);
            };

            navigation.addListener("beforeRemove", beforeRemoveListener);

            return () => {
                navigation.removeListener("beforeRemove", beforeRemoveListener);
            };
        }, [rmaDatePickerOpen, ataDatePickerOpen])
    );

    useEffect(() => {
        async function loadOfficers() {
            let errorFlag = false;
            let filterOfficers = [];
            let filterCommanders = [];
            const getAllOfficers = await get_all_users_in_single_unit(
                { unit_id: global.user_data.unit_id, permission: 28 },
                global.token
            );

            if (getAllOfficers.status === 200) {
                // Removes logged in user from officers list
                filterOfficers = getAllOfficers.data.body.filter(
                    (user) => user.user_id != global.user_data.user_id
                );
                setOfficers(filterOfficers);
            } else {
                errorFlag = true;
            }

            const getAllCommanders = await get_all_users_in_single_unit(
                { unit_id: global.user_data.unit_id, permission: 40 },
                global.token
            );

            if (getAllCommanders.status === 200) {
                // Removes logged in user from commanders list
                filterCommanders = getAllCommanders.data.body.filter(
                    (user) => user.user_id != global.user_data.user_id
                );
                setCommanders(filterCommanders);
            } else {
                errorFlag = true;
            }

            const unit_info = await get_forms_related_info_of_a_unit(
                { unit_id: global.user_data.unit_id },
                global.token
            );

            if (unit_info.status === 200) {
                setUnitInfo(unit_info.data.body);
            } else {
                errorFlag = true;
            }

            if (errorFlag) {
                showSnackbar(
                    "error",
                    "Application Error: Failed to retrieve required RST information"
                );
                setFailedToRetrieve(true);
            } else {
                if (
                    filterCommanders.length === 0 &&
                    filterOfficers.length === 0
                ) {
                    showSnackbar(
                        "error",
                        "Application Error: You do not have any Recommenders or Reviewers to choose from"
                    );
                    setFailedToRetrieve(true);
                } else if (filterCommanders.length === 0) {
                    showSnackbar(
                        "error",
                        "Application Error: You do not have any Recommenders to choose from"
                    );
                    setFailedToRetrieve(true);
                } else if (filterOfficers.length === 0) {
                    showSnackbar(
                        "error",
                        "Application Error: You do not have any Reviewers to choose from"
                    );
                    setFailedToRetrieve(true);
                }
            }

            setLoaded(true);
        }

        function checkSignature() {
            if (global.signature_uri == null || global.signature_uri === "") {
                setDoesUserHaveSignature(false);
            } else {
                setDoesUserHaveSignature(true);
            }
        }

        setselectedTypeEmptyError(false);
        setrecommenderTypeEmptyError(false);
        setreviewerTypeEmptyError(false);
        settaskTypeEmptyError(false);
        setjustificationTypeEmptyError(false);
        setBADateEmptyError(false);
        setMUTAEmptyError(false);
        setlieuBADatesEmptyError(false);
        setrstMUTAEmptyError(false);
        settoBePerformedOnDatesEmptyError(false);
        settoBePerformedOnMUTAEmptyError(false);
        setATAEmptyError(false);

        setDidUserSignDocument(false);

        if (isFocused) {
            loadOfficers();
            checkSignature();
        }
    }, [isFocused]);

    useEffect(() => {
        if (checkProcessedVariables()) {
            loadDraftForm();
        }
    }, [draftFormID, commanders, officers]);

    useEffect(() => {
        fetchUnitBAdates();
    }, []);

    function addSpacesToShortDate(dateStr) {
        if (!dateStr) {
            throw new Error(
                "Invalid date format. Date string cannot be empty."
            );
        }

        if (dateStr.length === 7) {
            // DDMMMYY
            const day = dateStr.substring(0, 2);
            const month = dateStr.substring(2, 5);
            const year = dateStr.substring(5, 7);

            if (!/^\d{2}[A-Z]{3}\d{2}$/.test(dateStr)) {
                throw new Error(
                    "Invalid date format for DDMMMYY. Expected format is DDMMMYY."
                );
            }

            return `${day} ${month} ${year}`;
        }

        if (dateStr.length === 9) {
            // DDMMMYYYY
            const day = dateStr.substring(0, 2);
            const month = dateStr.substring(2, 5);
            const year = dateStr.substring(5, 9);

            if (!/^\d{2}[A-Z]{3}\d{4}$/.test(dateStr)) {
                throw new Error(
                    "Invalid date format for DDMMMYYYY. Expected format is DDMMMYYYY."
                );
            }

            return `${day} ${month} ${year}`;
        }
    }

    function convertToMomentDate(dateString) {
        const day = dateString.substring(0, 2);
        const monthAbbr = dateString.substring(2, 5);
        const year = dateString.substring(5, 9);

        const monthNum = MONTHTONUM.get(monthAbbr);
        return moment({
            year: parseInt(year),
            month: monthNum - 1,
            day: parseInt(day),
        });
    }

    function handleImportFormRows(rstDates, performedDates) {
        const rows = [];

        let baDateIndex = 0;
        let performedDateIndex = 0;

        while (
            baDateIndex < rstDates.length &&
            performedDateIndex < performedDates.length
        ) {
            let rstDate = rstDates[baDateIndex];
            let performedDate = performedDates[performedDateIndex];
            // Handle MUTA-2 rstDate with MUTA-1 performedDates or vice versa
            if (rstDate.MUTA === 2 && performedDate.MUTA === 1) {
                for (let i = 0; i < 2; i++) {
                    if (performedDateIndex < performedDates.length) {
                        const id = Date.now() + rows.length;
                        const newRow = {
                            lieuBA: rstDate.value,
                            lieuBAMUTA: 2,
                            toBePerformedOn: {
                                ...performedDate,
                                MUTA: 1,
                                rowId: id,
                                label: addSpacesToShortDate(
                                    performedDate.label
                                ),
                            },
                            id: id,
                        };
                        rows.push(newRow);
                        performedDateIndex++;
                        performedDate =
                            performedDates[performedDateIndex] || {};
                    }
                }
                baDateIndex++;
            } else if (rstDate.MUTA === 1 && performedDate.MUTA === 2) {
                for (let i = 0; i < 2; i++) {
                    if (baDateIndex < rstDates.length) {
                        const id = Date.now() + rows.length;
                        const newRow = {
                            lieuBA: rstDate.value,
                            lieuBAMUTA: 1,
                            toBePerformedOn: {
                                ...performedDate,
                                MUTA: 2,
                                rowId: id,
                                label: addSpacesToShortDate(
                                    performedDate.label
                                ),
                            },
                            id: id,
                        };
                        rows.push(newRow);
                        baDateIndex++;
                        rstDate = rstDates[baDateIndex] || {};
                    }
                }
                performedDateIndex++;
            } else {
                // Handle cases where both MUTA values are the same
                const minMUTA = Math.min(rstDate.MUTA, performedDate.MUTA);

                for (let i = 0; i < 1; i++) {
                    const id = Date.now() + rows.length;
                    const newRow = {
                        lieuBA: rstDate.value,
                        lieuBAMUTA: rstDate.MUTA, // Set MUTA to rstDate's MUTA
                        toBePerformedOn: {
                            ...performedDate,
                            MUTA: performedDate.MUTA, // Keep performedDate's MUTA
                            rowId: id,
                            label: addSpacesToShortDate(performedDate.label),
                        },
                        id: id,
                    };
                    rows.push(newRow);
                }

                // Update MUTA values
                rstDate.MUTA -= minMUTA;
                performedDate.MUTA -= minMUTA;

                // Check if either MUTA has been exhausted
                if (rstDate.MUTA === 0) {
                    baDateIndex++;
                }
                if (performedDate.MUTA === 0) {
                    performedDateIndex++;
                }
            }
        }

        // Handle remaining rstDates if performedDates are exhausted
        while (baDateIndex < rstDates.length) {
            const rstDate = rstDates[baDateIndex];
            const id = Date.now() + rows.length;
            const newRow = {
                lieuBA: rstDate.value,
                lieuBAMUTA: rstDate.MUTA, // Remaining MUTA of rstDate
                toBePerformedOn: { rowId: id }, // No performedDate available
                id: id,
            };
            rows.push(newRow);
            baDateIndex++;
        }

        // Handle remaining performedDates if rstDates are exhausted
        while (performedDateIndex < performedDates.length) {
            const performedDate = performedDates[performedDateIndex];
            const id = Date.now() + rows.length;
            const newRow = {
                lieuBA: "", // No rstDate available
                lieuBAMUTA: performedDate.MUTA, // Remaining MUTA of performedDate
                toBePerformedOn: {
                    ...performedDate,
                    rowId: id,
                    label: addSpacesToShortDate(performedDate.label),
                },
                id: id,
            };
            rows.push(newRow);
            performedDateIndex++;
        }

        setRows(rows);
        return rows;
    }

    // useEffect(() => {
    //     const fetchData = async () => {
    //         if (draftFormID !== undefined && draftFormID != "NONE") {
    //             const fileData = await get_single_file(
    //                 { form_id: draftFormID, form_type: "RST" },
    //                 global.token
    //             );

    //             if (fileData !== undefined) {
    //                 if (fileData.body.recommender_id !== undefined) {
    //                     const recommenderOfficer = officers.find(
    //                         (officer) =>
    //                             officer.user_id == fileData.body.recommender_id
    //                     );
    //                     if (recommenderOfficer)
    //                         setRecommender(recommenderOfficer);
    //                 }

    //                 if (fileData.body.reviewer_id !== undefined) {
    //                     const reviewerOfficer = commanders.find(
    //                         (commander) =>
    //                             commander.user_id == fileData.body.reviewer_id
    //                     );
    //                     if (reviewerOfficer) setReviewer(reviewerOfficer);
    //                 }

    //                 setSoldierNotes(fileData.body.soldier_notes);
    //             }
    //         }
    //     };
    //     fetchData();
    // }, [draftFormID, officers, commanders]);

    const checkProcessedVariables = () => {
        return (
            draftFormID != "NONE" &&
            draftFormID !== undefined &&
            commanders.length > 0 &&
            officers.length > 0
        );
    };

    async function loadDraftForm() {
        setLoaded(false);
        const formIDandType = { form_id: draftFormID, form_type: "RST" };
        try {
            const fileData = await get_single_file(formIDandType, global.token);

            if (updatingSubmission == true) {
                // We are working with either an amendment to a submission or a recalled denial
                if (
                    fileData.body.recommender_comment &&
                    fileData.body.recommender_comment !== undefined &&
                    fileData.body.recommender_comment !== ""
                )
                    setReasonForDenial(fileData.body.recommender_comment);
                if (
                    fileData.body.reviewer_comment &&
                    fileData.body.reviewer_comment !== undefined &&
                    fileData.body.reviewer_comment !== ""
                )
                    setReasonForDenial(fileData.body.reviewer_comment);
            }

            if (fileData.body.recommender_id !== undefined) {
                setRecommender(
                    officers.find(
                        (officer) =>
                            officer.user_id == fileData.body.recommender_id
                    )
                );
                setrecommenderTypeEmptyError(false);
            }

            if (fileData.body.reviewer_id !== undefined) {
                setReviewer(
                    commanders.find(
                        (commander) =>
                            commander.user_id == fileData.body.reviewer_id
                    )
                );
                setreviewerTypeEmptyError(false);
            }

            setTasks(fileData.body.tasks_to_perform);
            setJustification(fileData.body.justification);
            setSoldierNotes(fileData.body.soldier_notes);
            switch (fileData.body.request_type) {
                case "RST":
                    setSelectedType("rst");
                    handleImportFormRows(
                        processRSTLieuDateString(fileData.body.rst_ba_date),
                        processRSTPerformedDateString(
                            fileData.body.rst_performed_on
                        )
                    );
                    break;
                case "RST-EXCUSEDABSENCE":
                    setSelectedType("excusedAbsence");
                    const updatedBaDates = baDates.map((date) => {
                        if (
                            date.label.includes(
                                fileData.body.excused_absence_ba_date
                            )
                        ) {
                            const dateSelectedTrue = {
                                ...date,
                                selected: true,
                                expanded: date.type === "range",
                            };
                            return dateSelectedTrue;
                        } else {
                            return {
                                ...date,
                                selected: false,
                                expanded: false,
                            };
                        }
                    });
                    setBaDates(updatedBaDates);

                    const expandedDates = updatedBaDates.reduce((acc, date) => {
                        if (date.type === "range" && date.expanded) {
                            return [...acc, ...expandDateRange([date])];
                        }
                        return [...acc, date];
                    }, []);

                    setExpandedDates(expandedDates);

                    const selectedDate =
                        expandedDates.find((date) => {
                            return (
                                date.label ===
                                fileData.body.excused_absence_ba_date
                            );
                        }) || null;

                    setSelectedBADate(selectedDate);
                    setMUTA(fileData.body.excused_absence_muta_num);

                    break;
                case "RST-ATA":
                    setSelectedType("ata");
                    setATASelectedDate(
                        convertToMomentDate(fileData.body.ata_date)
                    );
                    setATAFormattedDate(fileData.body.ata_date);
                    setATAs(fileData.body.ata_num_of_ata);
                    break;
                case "RST-RMA":
                    setSelectedType("rma");
                    setRMASelectedDate(
                        convertToMomentDate(fileData.body.rma_date)
                    );
                    setRMAFormattedDate(fileData.body.rma_date);
                    // RMA Performed On* rma_date to rmaSelectedDate, rmaFormattedDate is what's actually sent
                    break;
                default:
                    break;
            }
            setPulledFromDraft(true);
            setHideDraftButton(true);
        } catch (error) {
            console.error("Error loading draft form:", error);
        }
        setLoaded(true);
    }

    const captureTextAsImage = async () => {
        try {
            const fullname =
                global.user_data.first_name.toLowerCase() +
                " " +
                global.user_data.last_name.toLowerCase();
            if (signatureName.toLowerCase() === fullname) {
                const uri = await captureRef(viewRef, {
                    format: "png",
                    quality: 0.8,
                });

                let ret = await create_new_user_signature(
                    { user_id: global.user_data.user_id },
                    global.token
                );
                if (ret.status === 200) {
                    ret = ret.data;
                    await put_image_s3(
                        "signatures/" + ret.body.signature_id,
                        uri,
                        "image/png",
                        global.token
                    );

                    global.signature_uri = uri;

                    global.user_data.signature_id = ret.body.signature_id;
                    await AsyncStorage.setItem(
                        "signature",
                        global.signature_uri
                    );
                    storeJsonData("user_data", global.user_data);

                    setDoesUserHaveSignature(true);
                } else {
                    showSnackbar(
                        "error",
                        "Application Error: Failed to create your signature"
                    );
                }
            } else {
                showSnackbar(
                    "error",
                    "The name inputted does not match your name we have on record!"
                );
            }
        } catch (error) {
            console.error("Error capturing image:", error);
        }
    };

    const viewBlank = async () => {
        await pdfIn(form_rst.pdf());
    };

    const viewFilled = async () => {
        // Common PDF fields for all types
        const data = {
            header: unitInfo.unit_rst_header,
            field_memo: unitInfo.unit_rst_field_memo,
            memorandum: unitInfo.unit_rst_memorandum,
            commanding_officer: unitInfo.unit_rst_commanding_officer,
            date: dateToday,
            name_and_rank: await getRankName(),
            form_ID: draftFormID && draftFormID !== "NONE" ? draftFormID : "",
            tasks_to_perform: tasks,
            justification: justification,
            // One of these four will be set to true based on which type of RST it is
            excused_absence: false,
            rst: false,
            rma: false,
            ata: false,
        };

        switch (selectedType) {
            case "excusedAbsence":
                data.excused_absence_ba_date = selectedBADate.label;
                data.excused_absence_muta_num = muta;
                data.excused_absence = true;
                await generate_rst(
                    data.form_ID,
                    data,
                    route.navigation,
                    false,
                    false
                );
                break;

            case "rst":
                data.soldier_notes = soldierNotes;
                const lieuArray = [];
                const perfArray = [];

                rows.forEach((row) => {
                    const lieuEntry = `${row.lieuBA} (${row.lieuBAMUTA})`;
                    if (!lieuArray.includes(lieuEntry)) {
                        lieuArray.push(lieuEntry);
                    }
                    if (row.toBePerformedOn) {
                        const perfEntry = `${row.toBePerformedOn.value} (${row.toBePerformedOn.MUTA})`;
                        if (!perfArray.includes(perfEntry)) {
                            perfArray.push(perfEntry);
                        }
                    }
                });

                data.rst_ba_date = lieuArray.join(", ");
                data.rst_performed_on = perfArray.join(", ");
                data.rst = true;
                await generate_rst(
                    data.form_ID,
                    data,
                    route.navigation,
                    false,
                    false
                );
                break;

            case "rma":
                data.rma_date = rmaFormattedDate;
                data.rma = true;
                await generate_rst(
                    data.form_ID,
                    data,
                    route.navigation,
                    false,
                    false
                );
                break;

            case "ata":
                data.ata_date = ataFormattedDate;
                data.ata_num_of_ata = ATAs;
                data.ata = true;
                await generate_rst(
                    data.form_ID,
                    data,
                    route.navigation,
                    false,
                    false
                );
                break;

            default:
                console.error("Unknown selectedType:", selectedType);
                break;
        }
    };

    const parseDate = (dateString) => {
        const [day, month, year] = dateString.split(/(\d+)/).filter(Boolean);
        return new Date(`${month} ${day}, ${year}`);
    };

    const getRankName = async () => {
        if (
            global.user_data.middle_name == null ||
            global.user_data.middle_name == ""
        ) {
            return (
                global.user_data.rank +
                " " +
                global.user_data.first_name +
                " " +
                global.user_data.last_name
            );
        } else {
            return (
                global.user_data.rank +
                " " +
                global.user_data.first_name +
                " " +
                global.user_data.middle_name +
                " " +
                global.user_data.last_name
            );
        }
    };

    function formatDateRange(startDate, endDate) {
        const startDateStr = formatDateByPadding(
            new Date(startDate).toLocaleDateString()
        );
        const endDateStr = formatDateByPadding(
            new Date(endDate).toLocaleDateString()
        );
        return `${startDateStr} - ${endDateStr}`;
    }

    function getMonthAcryn(month) {
        const months = {
            1: "JAN",
            2: "FEB",
            3: "MAR",
            4: "APR",
            5: "MAY",
            6: "JUN",
            7: "JUL",
            8: "AUG",
            9: "SEP",
            10: "OCT",
            11: "NOV",
            12: "DEC",
        };
        return months[month];
    }

    function formatDateByPadding(date) {
        const arr = date.split("/");
        let day = arr[1];

        if (day.length === 1) {
            day = "0" + day;
        }

        date = day + getMonthAcryn(arr[0]) + arr[2];
        return date;
    }

    async function fetchUnitBAdates() {
        try {
            const get_all_ba_dates_JSON = {
                unit_id: global.user_data.unit_id,
                is_rst: true,
            };
            const ba_dates = await get_all_ba_dates(
                get_all_ba_dates_JSON,
                global.token
            );
            if (ba_dates.status === 200) {
                if (ba_dates.data.body.length === 0) {
                    showSnackbar(
                        "warning",
                        "There are no BA Dates to choose from"
                    );
                    return;
                }

                const dates = ba_dates.data.body;
                const letterPattern = /[a-zA-Z]/;
                const uniqueDateRanges = new Set();

                for (let i = 0; i < dates.length; i++) {
                    const startDate = new Date(dates[i].start_timestamp);
                    const endDate = new Date(dates[i].end_timestamp);
                    let label = "";
                    let type = "";

                    if (startDate.toDateString() === endDate.toDateString()) {
                        // If the start and end dates are the same, set the label to the start date only
                        label = formatDateByPadding(
                            startDate.toLocaleDateString()
                        );
                        type = "individual";
                    } else {
                        // If the start and end dates are different, set the label to the date range
                        label = `${formatDateByPadding(
                            startDate.toLocaleDateString()
                        )} - ${formatDateByPadding(
                            endDate.toLocaleDateString()
                        )}`;
                        type = "range";
                    }

                    if (letterPattern.test(label.substring(0, 2))) {
                        label = "0" + label;
                    }

                    // Check if the date range already exists in the uniqueDateRanges set
                    if (!uniqueDateRanges.has(label)) {
                        // If the date range doesn't exist, add it to the uniqueDateRanges set
                        uniqueDateRanges.add(label);

                        // Update the dates array with the unique date range
                        dates[i] = {
                            label: label,
                            date_range: formatDateRange(
                                dates[i].start_timestamp,
                                dates[i].end_timestamp
                            ),
                            type: type,
                            expanded: false,
                            selected: false,
                        };
                    } else {
                        // If the date range already exists, remove it from the dates array
                        dates.splice(i, 1);
                        i--;
                    }
                }

                setBaDates(sortByClosestDate(dates));
                setExpandedDates(sortByClosestDate(dates));
            } else {
                showSnackbar(
                    "error",
                    "Application Error: Failed to retrieve unit BA Dates"
                );
            }
        } catch (error) {
            console.error("Error fetching unit BA dates:", error);
        }
    }

    function GetSortOrder(prop) {
        return function (a, b) {
            const dateA = a[prop];
            const dateB = b[prop];

            const yearA = dateA.slice(-4);
            const monthA = getMonthNumber(dateA.slice(2, 5));
            const dayA = dateA.slice(0, 2);

            const yearB = dateB.slice(-4);
            const monthB = getMonthNumber(dateB.slice(2, 5));
            const dayB = dateB.slice(0, 2);

            const dateAFormatted = `${monthA
                .toString()
                .padStart(2, "0")}/${dayA}/${yearA}`;
            const dateBFormatted = `${monthB
                .toString()
                .padStart(2, "0")}/${dayB}/${yearB}`;

            return new Date(dateAFormatted) - new Date(dateBFormatted);
        };
    }

    function PerformedOnSortOrder(prop) {
        return function (a, b) {
            const dateA = a[prop];
            const dateB = b[prop];

            const [dayA, monthAbbrA, yearA] = dateA.split(" ");
            const [dayB, monthAbbrB, yearB] = dateB.split(" ");

            const monthA = getMonthNumber(monthAbbrA);
            const monthB = getMonthNumber(monthAbbrB);

            const dateAFormatted = `${monthA
                .toString()
                .padStart(2, "0")}/${dayA}/${yearA}`;
            const dateBFormatted = `${monthB
                .toString()
                .padStart(2, "0")}/${dayB}/${yearB}`;
            return new Date(dateAFormatted) - new Date(dateBFormatted);
        };
    }

    function getMonthNumber(monthAbbr) {
        const monthAbbreviations = [
            "JAN",
            "FEB",
            "MAR",
            "APR",
            "MAY",
            "JUN",
            "JUL",
            "AUG",
            "SEP",
            "OCT",
            "NOV",
            "DEC",
        ];
        return monthAbbreviations.indexOf(monthAbbr) + 1;
    }

    function sortByClosestDate(baDates) {
        const sortedObjects = baDates.sort((a, b) => {
            const startDiff =
                new Date(a.date_range.split(" - ")[0]) -
                new Date(b.date_range.split(" - ")[0]);
            if (startDiff !== 0) {
                return startDiff;
            }
            const endDiff =
                new Date(a.date_range.split(" - ")[1]) -
                new Date(b.date_range.split(" - ")[1]);
            if (endDiff !== 0) {
                return endDiff;
            }

            return a.label.localeCompare(b.label);
        });
        return sortedObjects;
    }

    const handleTextChange = (text) => {
        if (selectedType === "excusedAbsence") {
            // Validate input to allow only numbers
            setMUTA(text.replace(/[^0-9]/g, ""));
        } else if (selectedType === "ata") {
            setATAs(text.replace(/[^0-9]/g, ""));
        }
    };

    const handleLoadDraft = async () => {
        navigation.navigate("YourFiles", {
            from1380: false,
            formType: "RST",
            loadingDraft: true,
        });
    };

    const checkTableInputs = () => {
        const newHighlightedRows = [];

        rows.forEach((row, index) => {
            const rowErrors = {};

            if (row.lieuBA === "") {
                rowErrors.lieuBA = true;
            }

            if (row.lieuBAMUTA === 0) {
                rowErrors.lieuBAMUTA = true;
            }

            if (!row.toBePerformedOn) {
                rowErrors.toBePerformedOnEmpty = true;
            }

            if (row.toBePerformedOn.MUTA === 0) {
                rowErrors.toBePerformedOnMUTA = true;
            }

            if (Object.keys(rowErrors).length > 0) {
                newHighlightedRows[index] = rowErrors;
            }
        });

        setHighlightedRows(newHighlightedRows);

        if (newHighlightedRows.length > 0) {
            showSnackbar(
                "error",
                "Please correct one or more invalid inputs on the table"
            );
            return false;
        }

        return true;
    };

    const checkDatesAndMUTAs = () => {
        const baDates = [];
        let baMUTAs = 0;
        const uniqueLieuBADates = new Set();

        const performedOnDates = [];
        let performedMUTAs = 0;

        rows.forEach((row, index) => {
            if (!uniqueLieuBADates.has(row.lieuBA)) {
                uniqueLieuBADates.add(row.lieuBA);

                if (row.lieuBAMUTA === 0) {
                    baDates.push(row.lieuBA);
                } else {
                    baMUTAs += row.lieuBAMUTA;
                }
            }

            if (row.toBePerformedOn.MUTA === 0) {
                performedOnDates.push(row.toBePerformedOn.value || "");
            } else {
                performedMUTAs += row.toBePerformedOn?.MUTA || 0;
            }
        });

        let errorMessage = "";

        if (
            rows.some(
                (row) =>
                    row.lieuBAMUTA === 0 ||
                    (row.toBePerformedOn && row.toBePerformedOn.MUTA === 0)
            )
        ) {
            const rstDatesWithoutMUTA = rows
                .filter((row) => {
                    // Check if lieuBAMUTA is 0 or toBePerformedOn.MUTA is 0
                    return (
                        row.lieuBAMUTA === 0 ||
                        (row.toBePerformedOn && row.toBePerformedOn.MUTA === 0)
                    );
                })
                .map((row) => {
                    if (row.lieuBAMUTA === 0) {
                        return row.lieuBA;
                    } else {
                        return row.toBePerformedOn.label;
                    }
                });

            if (rstDatesWithoutMUTA.length > 0) {
                errorMessage +=
                    "Please select MUTAs for the following RST dates:\n";
                errorMessage += rstDatesWithoutMUTA.join("\n");
            }
        }

        if (performedOnDates.length > 0) {
            errorMessage +=
                "\nPlease select MUTAs for the following Performed on dates:\n";
            errorMessage += performedOnDates.join("\n");
        }

        // We only want to show mismatch MUTA message if MUTAs have been selected for lieu and performed on
        if (errorMessage === "") {
            if (baMUTAs !== performedMUTAs) {
                errorMessage +=
                    "MUTAs do not match.\nTotal BA MUTAs = " +
                    baMUTAs +
                    "\nTotal Performed on MUTAs = " +
                    performedMUTAs +
                    "\n";
            }
        }

        if (errorMessage !== "") {
            showSnackbar("error", errorMessage);
            return false;
        }

        return true;
    };

    const checkFields = () => {
        let check = true;

        if (selectedType === "rst") {
            if (lieuBADates.length <= 0) {
                check = false;
                setlieuBADatesEmptyError(true);
                setrstMUTAEmptyError(true);
            }

            // we return here to avoid showing multiple error messages
            if (!checkTableInputs()) {
                return;
            }

            // we return here to avoid showing multiple error messages
            if (!checkDatesAndMUTAs()) {
                return;
            }
        }

        if (selectedType === "excusedAbsence") {
            if (selectedBADate?.label === "" || selectedBADate === null) {
                check = false;
                setBADateEmptyError(true);
            }

            if (muta === "") {
                check = false;
                setMUTAEmptyError(true);
            }
        }

        if (selectedType === "rma") {
            if (
                rmaDateError ||
                rmaSelectedDate === null ||
                isNaN(new Date(rmaSelectedDate).getTime())
            ) {
                check = false;
                setRMADateError(true);
            }
        }

        if (selectedType === "ata") {
            if (
                ataDateError ||
                ataSelectedDate === null ||
                isNaN(new Date(ataSelectedDate).getTime())
            ) {
                check = false;
                setATADateError(true);
            }

            if (ATAEmptyError || ATAs === "") {
                check = false;
                setATAEmptyError(true);
            }
        }

        // All form types have these same fields
        if (selectedType === "") {
            check = false;
            setselectedTypeEmptyError(true);
        }

        if (justification === "") {
            check = false;
            setjustificationTypeEmptyError(true);
        }

        if (recommender === "") {
            check = false;
            setrecommenderTypeEmptyError(true);
        }

        if (reviewer === "") {
            check = false;
            setreviewerTypeEmptyError(true);
        }

        if (tasks === "") {
            check = false;
            settaskTypeEmptyError(true);
        }

        if (check || !viewSig) {
            // If viewSig is false, they can save whatever they have as a draft.
            setIsLoading(true);
            setSubmitted(true);
            onSubmit();
        } else {
            showSnackbar("error", "Please correct one or more invalid inputs");
        }
    };

    const onCancel = () => {
        navigation.navigate("Home");
    };

    const onSubmit = async () => {
        // make sure user has signature
        if (
            global.user_data.signature_id == null ||
            global.signature_uri == null
        ) {
            showSnackbar("error", "You do not have a signature...");
            setSubmitted(false);
            setIsLoading(false);
        }
        // Make a new data object w/ default values
        const form_data = {
            creator_id: null,
            comment: "",
            form_name: "",
            form_type: "",
            request_type: "",
            recommender_id: null,
            reviewer_ids: null,
            reviewer_id: null,
            status: viewSig ? 1 : 0, // If they haven't signed, save as a draft
            timestamp: null,
            timestamp_string: "",
            name_and_rank: "",
            date: null,
            excused_absence: false,
            excused_absence_ba_date: "",
            excused_absence_muta_num: "",
            rst: false,
            rst_ba_date: "",
            rst_performed_on: "",
            rma: false,
            rma_date: "",
            ata: false,
            ata_date: "",
            ata_num_of_ata: "",
            tasks_to_perform: "",
            justification: "",
            soldier_signature_id: null,
            recommend_approval_date: null,
            request_is_approved: null,
            request_is_approved_date: null,
            recommender_signature_id: null,
            reviewer_signature_id: null,
            recommender_comment: "",
            reviewer_comment: "",
            soldier_notes: "",
            unit_id: global.user_data.unit_id,
            unit_rst_header: unitInfo.unit_rst_header,
            unit_rst_field_memo: unitInfo.unit_rst_field_memo,
            unit_rst_memorandum: unitInfo.unit_rst_memorandum,
            unit_rst_commanding_officer: unitInfo.unit_rst_commanding_officer,
        };

        form_data.creator_id = global.user_data.user_id;
        form_data.name_and_rank = await getRankName();
        form_data.date = dateToday;
        form_data.comment = "";

        if (selectedType === "excusedAbsence") {
            form_data.excused_absence = true;
            form_data.excused_absence_ba_date = selectedBADate?.label ?? "";
            form_data.excused_absence_muta_num = muta ?? "";
        } else if (selectedType === "rst") {
            form_data.rst = true;

            const lieuArray = [];
            const perfArray = [];

            // Iterate over rows to populate lieuArray and perfArray
            rows.forEach((row) => {
                if (row.lieuBAMUTA === 1 || row.lieuBAMUTA === 2) {
                    const lieuEntry = row.lieuBA + " (" + row.lieuBAMUTA + ")";
                    if (!lieuArray.includes(lieuEntry)) {
                        lieuArray.push(lieuEntry);
                    }
                }
                if (
                    row.toBePerformedOn &&
                    (row.toBePerformedOn.MUTA === 1 ||
                        row.toBePerformedOn.MUTA === 2)
                ) {
                    const perfEntry =
                        row.toBePerformedOn.value +
                        " (" +
                        row.toBePerformedOn.MUTA +
                        ")";
                    if (!perfArray.includes(perfEntry)) {
                        perfArray.push(perfEntry);
                    }
                }
            });

            // Initialize form_data properties as empty strings
            form_data.rst_ba_date = "";
            form_data.rst_performed_on = "";

            // Concatenate lieuArray elements into form_data.rst_ba_date
            for (let i = 0; i < lieuArray.length - 1; i++) {
                form_data.rst_ba_date += lieuArray[i] + ", ";
            }
            if (lieuArray.length > 0) {
                form_data.rst_ba_date += lieuArray[lieuArray.length - 1];
            }

            // Concatenate perfArray elements into form_data.rst_performed_on
            for (let i = 0; i < perfArray.length - 1; i++) {
                form_data.rst_performed_on += perfArray[i] + ", ";
            }
            if (perfArray.length > 0) {
                form_data.rst_performed_on += perfArray[perfArray.length - 1];
            }
        } else if (selectedType === "rma") {
            form_data.rma = true;
            form_data.rma_date = rmaFormattedDate ?? "";
        } else if (selectedType === "ata") {
            form_data.ata = true;
            form_data.ata_date = ataFormattedDate ?? "";
            form_data.ata_num_of_ata = ATAs ?? "";
        }

        form_data.soldier_notes = soldierNotes ?? "";
        form_data.soldier_signature_id = global.user_data.signature_id;

        form_data.tasks_to_perform = tasks ?? "";
        form_data.justification = justification ?? "";

        const requestType =
            "RST" +
            (selectedType === "rst" ? "" : "-" + selectedType.toUpperCase());
        form_data.form_type = "RST";
        form_data.request_type = requestType;

        if (reviewer && reviewer.user_id !== undefined) {
            form_data.reviewer_ids = [reviewer.user_id];
            form_data.reviewer_id = reviewer.user_id;
        }
        if (recommender && recommender.user_id !== undefined)
            form_data.recommender_id = recommender.user_id;
        form_data.form_name = form_data.name_and_rank;

        setrstMUTAEmptyError(false);
        settoBePerformedOnMUTAEmptyError(false);

        let res;
        if (pulledFromDraft) {
            const updatedFormData = {
                ...form_data,
                attachments: null,
                form_type: "RST",
                form_id: draftFormID,
            };
            res = await update_file(updatedFormData, global.token); // They are now submitting a loaded draft
        } else res = await upload_file(form_data, global.token); // They created and submitted a new form in one go

        if (
            (!pulledFromDraft && res.status !== 200) ||
            (pulledFromDraft && !res.body.includes("successfully updated"))
        ) {
            showSnackbar(
                "error",
                "Application Error: Failed to upload the file"
            );
            setSubmitted(false);
            setIsLoading(false);
            return;
        } else {
            if (viewSig == true) {
                if (pulledFromDraft) {
                    await sendNotif(
                        recommender.user_id,
                        draftFormID,
                        "recommend"
                    );
                    await sendNotif(reviewer.user_id, draftFormID, "review");
                } else {
                    await sendNotif(
                        recommender.user_id,
                        res.data.body.form_id,
                        "recommend"
                    );
                    await sendNotif(
                        reviewer.user_id,
                        res.data.body.form_id,
                        "review"
                    );
                }
            }
            setSubmitted(true);
            setIsLoading(false);
            showSnackbar(
                "success",
                `Form ${viewSig ? "submitted" : "drafted"} successfully.`
            );
            navigation.navigate("Home");
        }
        /* ------------------------------------------*/
        // email to supervising chief
        // send email to res.sectionChiefEmail
    };

    const handleSignature = () => {
        setViewSig(!viewSig);
        setDidUserSignDocument(!didUserSignDocument);
    };

    const sendNotif = async (receiver_id, form_id, action) => {
        const data = {
            title: "RST Form",
            body: `You have one new RST form to ${action}`,
            receiver_id: receiver_id,
            sender_id: global.user_data.user_id,
            notification_type: "file",
            object_type: "review RST form",
            form_events_id: form_id,
        };

        const res = await create_notification(data, global.token);
        if (res.status !== 200) {
            const receiver = officers.filter((user) => receiver_id === user.id);
            showSnackbar(
                "success",
                "RST Form submitted successfully",
                "top",
                "right",
                6000,
                400,
                () => {
                    showSnackbar(
                        "error",
                        `Application Error: Failed to send notification to ${receiver[0].name}`
                    );
                }
            );
        }
    };

    const handleDutyChange = (event) => {
        setSelectedType(event.target.value);
        setselectedTypeEmptyError(false);
    };

    const handleReviewerChange = (event) => {
        setReviewer(event.target.value);
        setreviewerTypeEmptyError(false);
    };

    const handleRecommenderChange = (event) => {
        setRecommender(event.target.value);
        setrecommenderTypeEmptyError(false);
    };

    const isOneDayRange = (dateRange) => {
        const [startDate, endDate] = dateRange.split(" - ");
        return (
            new Date(startDate).toDateString() ===
            new Date(endDate).toDateString()
        );
    };

    // Function to expand date ranges into individual dates
    const expandDateRange = (selectedDates) => {
        return selectedDates.flatMap((date) => {
            if (date?.expanded && date?.type === "range") {
                const [startDate, endDate] = date.date_range.split(" - ");
                const start = new Date(startDate);
                const end = new Date(endDate);

                const expandedDates = [];
                for (
                    let current = new Date(start);
                    current <= end;
                    current.setDate(current.getDate() + 1)
                ) {
                    const formattedDate = `${current
                        .getDate()
                        .toString()
                        .padStart(2, "0")}${current
                            .toLocaleString("default", { month: "short" })
                            .toUpperCase()}${current.getFullYear()}`;
                    expandedDates.push({
                        label: formattedDate,
                        date_range: date.date_range,
                        type: "individual",
                        selected: true,
                        parentRange: date.label,
                    });
                }
                return [date, ...expandedDates];
            }
            return [date];
        });
    };

    const handleDraftRSTDateSelect = (selectedItems, setRstMuta) => {
        const selectedRanges = selectedItems.filter(
            (item) => item.type === "range"
        );
        const selectedDates = selectedItems.filter(
            (item) => item.type === "individual"
        );

        let updatedDates;

        if (baDates.length === 0) {
            updatedDates = baDates.map((date) => {
                if (date.type === "range") {
                    const isSelected = selectedRanges.some(
                        (range) => range.label === date.label
                    );
                    return {
                        ...date,
                        expanded: isSelected ? !date.expanded : date.expanded,
                        selected: isSelected,
                    };
                }
                return {
                    ...date,
                    selected: selectedDates.some(
                        (individual) => individual.label === date.label
                    ),
                };
            });
        } else {
            updatedDates = baDates.map((date) => {
                if (date.type === "range") {
                    const isSelected = selectedRanges.some(
                        (range) => range.label === date.label
                    );
                    return {
                        ...date,
                        expanded: isSelected ? !date.expanded : date.expanded,
                        selected: isSelected,
                    };
                }
                return {
                    ...date,
                    selected: selectedDates.some(
                        (individual) => individual.label === date.label
                    ),
                };
            });
        }

        setBaDates(updatedDates);

        const expandedDates = updatedDates.flatMap((date) => {
            if (date.expanded && date.type === "range") {
                const [startDate, endDate] = date.date_range.split(" - ");
                const start = parseDate(startDate);
                const end = parseDate(endDate);
                const dates = [];

                for (
                    let current = new Date(start);
                    current <= end;
                    current.setDate(current.getDate() + 1)
                ) {
                    const formattedDate = `${current
                        .getDate()
                        .toString()
                        .padStart(2, "0")}${current
                            .toLocaleString("default", { month: "short" })
                            .toUpperCase()}${current.getFullYear()}`;
                    dates.push({
                        label: formattedDate,
                        label: formattedDate,
                        type: "individual",
                        selected: selectedDates.some(
                            (individual) => individual.label === formattedDate
                        ),
                    });
                }
                return [date, ...dates];
            }
            return [date];
        });

        setExpandedDates(expandedDates);

        setLieuBADates((prevDates) => {
            const updatedDates = [...prevDates];
            selectedDates.forEach((date) => {
                const existingDateIndex = updatedDates.findIndex(
                    (d) => d.label === date.label
                );
                if (existingDateIndex !== -1) {
                    updatedDates.splice(existingDateIndex, 1);
                } else {
                    updatedDates.push(date);
                }
            });
            return updatedDates;
        });

        if (setRstMuta) {
            setRSTMUTA((prevMutas) => {
                const updatedMutas = [...prevMutas];

                selectedDates.forEach((date) => {
                    updatedMutas.push({
                        key: date.label,
                        label: date.label,
                        value: date.value,
                        type: date.type,
                        date_range: date.date_range,
                        date: date.label,
                        MUTA: 0,
                    });
                });

                updatedMutas.sort(GetSortOrder("label"));
                return updatedMutas;
            });
        }

        setlieuBADatesEmptyError(false);
    };

    /**
     * This function serves the same purpose as the RST date change but main difference is that
     * the excused absence is a single select menu so we needed to account for that
     */
    const handleEADateChange = (event, value) => {
        const selectedValue = value || null;

        // Update baDates based on the selected EA date
        const updatedDates = baDates.map((date) => {
            if (date.type === "range") {
                const isSelected = selectedValue?.label === date.label;
                return {
                    ...date,
                    expanded: isSelected ? !date.expanded : date.expanded,
                    selected: isSelected,
                };
            }
            return {
                ...date,
                selected: selectedValue?.label === date.label,
            };
        });

        setBaDates(updatedDates);

        const expandedDates = updatedDates.reduce((acc, date) => {
            if (date.type === "range" && date.expanded) {
                return [...acc, ...expandDateRange([date])];
            }
            return [...acc, date];
        }, []);

        setExpandedDates(expandedDates);

        // Update the selected BA date and handle errors
        if (selectedValue?.type === "individual") {
            if (selectedValue?.label === selectedBADate?.label) {
                setSelectedBADate(null);
                setBADateEmptyError(true);
            } else {
                setSelectedBADate(selectedValue);
                setBADateEmptyError(false);
            }
        } else if (selectedValue?.type === "range" && selectedBADate) {
            setBADateEmptyError(false);
        } else {
            setSelectedBADate(null);
            setBADateEmptyError(true);
        }
    };

    // Adds a row to the table and its corresponding error object
    const handleAddRow = () => {
        setRows((prevRows) => {
            const newRow = {
                lieuBA: "",
                lieuBAMUTA: 0,
                toBePerformedOn: "",
                id: Date.now(),
            };

            const updatedRows = [...prevRows, newRow];

            setHighlightedRows((prevHighlightedRows) => {
                if (prevHighlightedRows.length === prevRows.length) {
                    return [
                        ...prevHighlightedRows,
                        {
                            lieuBA: false,
                            lieuBAMUTA: false,
                            toBePerformedOnEmpty: false,
                            toBePerformedOnMUTA: false,
                        },
                    ];
                }
                return prevHighlightedRows;
            });

            return updatedRows;
        });
    };

    const handleRSTDateSelect = (event, value) => {
        setHideDraftButton(true);
        // Filter the selected items into ranges and individual dates
        const selectedRanges = value.filter((item) => item.type === "range");
        const selectedDates = value.filter(
            (item) => item.type === "individual"
        );

        // Filter out duplicate individual dates
        const uniqueSelectedDates = selectedDates.filter(
            (date, index, self) =>
                index === self.findIndex((d) => d.label === date.label)
        );

        // Update baDates based on the selected ranges and individual dates
        const updatedDates = baDates.map((date) => {
            if (date.type === "range") {
                const isSelected = selectedRanges.some(
                    (range) => range.label === date.label
                );
                return {
                    ...date,
                    expanded: isSelected ? !date.expanded : date.expanded, // determines whether we expand or collapse the date range
                    selected: isSelected,
                };
            }
            return {
                ...date,
                selected: uniqueSelectedDates.some(
                    (individual) => individual.label === date.label
                )
                    ? !date.selected
                    : date.selected, // determines whether we highlight the option or unhighlight
            };
        });

        // Set the updated dates to the state
        setBaDates(updatedDates);
        const expandedDates = updatedDates.reduce((acc, date) => {
            if (date.type === "range" && date.expanded) {
                return [...acc, ...expandDateRange([date])];
            }
            return [...acc, date];
        }, []);

        setExpandedDates(expandedDates);

        // To track what days the soldier is missing
        setLieuBADates(uniqueSelectedDates);

        const mutas = uniqueSelectedDates.map((item) => {
            const existingMuta = rstMUTA.find(
                (muta) => muta.label === item.label
            );
            if (existingMuta) {
                return existingMuta;
            }
            return {
                key: rstMUTA.length,
                label: item.label,
                value: item.label,
                MUTA: 0,
            };
        });

        mutas.sort(GetSortOrder("label"));
        setRSTMUTA(mutas);

        // Update the rows based on the unique selected dates
        setRows((prevRows) => {
            // if the first date has be
            if (uniqueSelectedDates.length === 0) {
                return prevRows.map((row) => ({
                    ...row,
                    lieuBA: "",
                    lieuBAMUTA: 0,
                }));
            } else {
                return prevRows.map((row) => {
                    const isInUniqueSelectedDates = uniqueSelectedDates.some(
                        (date) => date.label === row.lieuBA
                    );
                    if (!isInUniqueSelectedDates) {
                        return {
                            ...row,
                            lieuBA: "",
                            lieuBAMUTA: 0,
                        };
                    }
                    return row;
                });
            }
        });
    };

    function isDateInRange(date, rangeStr) {
        const [startStr, endStr] = rangeStr.split(" - ");
        const startDate = parseDate(startStr);
        const endDate = parseDate(endStr);
        return date >= startDate && date <= endDate;
    }

    const handleDeleteRow = (id, lieuBA, toBePerformedOn) => {
        // Find the row that will be deleted
        const rowToDelete = rows.find((row) => row.id === id);

        // Update the highlighted rows state
        setHighlightedRows((prevHighlightedRows) => {
            const updatedHighlightedRows = [...prevHighlightedRows];
            const rowIndex = prevHighlightedRows.findIndex(
                (row) => row?.id === id
            );

            // Remove the highlight for the deleted row
            updatedHighlightedRows.splice(rowIndex, 1);

            // Check if the deleted row had a toBePerformedOn date
            if (rowToDelete?.toBePerformedOn) {
                // Find the remaining rows with the same toBePerformedOn date
                const remainingConflicts = rows.filter(
                    (row) =>
                        row?.id !== id &&
                        row?.toBePerformedOn?.label ===
                        rowToDelete?.toBePerformedOn?.label
                );

                // If there are remaining conflicts, find the corresponding index in highlightedRows and remove the highlight
                if (remainingConflicts.length > 0) {
                    const conflictIndex = updatedHighlightedRows.findIndex(
                        (row) => row?.id === remainingConflicts[0]?.id
                    );
                    if (conflictIndex !== -1) {
                        updatedHighlightedRows[conflictIndex] = {
                            ...updatedHighlightedRows[conflictIndex],
                            toBePerformedOnEmpty: false,
                        };
                    }
                }
            }

            return updatedHighlightedRows;
        });

        // Update the rows state by filtering out the row with the specified id
        setRows((prevRows) => {
            const updatedRows = prevRows.filter((row) => row.id !== id);

            // Check if the deleted row's lieuBA is unique
            const isUniqueLieuBA =
                prevRows.filter((row) => row.lieuBA === lieuBA).length === 1;

            if (isUniqueLieuBA) {
                // If the deleted row's lieuBA is unique, set the MUTA value to 0 for that lieuBA in rstMUTA
                setRSTMUTA((prevDates) => {
                    return prevDates.map((date) => {
                        if (date.label === lieuBA) {
                            return { ...date, MUTA: 0 };
                        }
                        return date;
                    });
                });
            }

            return updatedRows.map((row) => {
                if (row.toBePerformedOn !== "") {
                    // Check if the current row has a date conflict with the deleted row
                    if (
                        rowToDelete.toBePerformedOn &&
                        rowToDelete.toBePerformedOn.label ===
                        row.toBePerformedOn.label
                    ) {
                        // If there's a date conflict, use the toBePerformedOn object from the deleted row
                        const updatedRow = {
                            ...row,
                            toBePerformedOn: {
                                ...JSON.parse(
                                    JSON.stringify(rowToDelete.toBePerformedOn)
                                ),
                            },
                        };
                        handleToBePerformedOnCheckboxChange(
                            updatedRow.toBePerformedOn.MUTA,
                            updatedRow,
                            updatedRow.id
                        );
                        return updatedRow;
                    } else {
                        // If there's no date conflict, update the MUTA value of the current row's toBePerformedOn object
                        const updatedRow = {
                            ...row,
                            toBePerformedOn: {
                                ...row.toBePerformedOn,
                                MUTA: row.toBePerformedOn.MUTA,
                            },
                        };
                        handleToBePerformedOnCheckboxChange(
                            updatedRow.toBePerformedOn.MUTA,
                            updatedRow,
                            updatedRow.id
                        );
                        return updatedRow;
                    }
                }
                return { ...row, toBePerformedOn: "" };
            });
        });

        // Check if the deleted row's toBePerformedOn date is unique
        const isUniqueDate =
            rows.filter(
                (row) => row.toBePerformedOn?.label === toBePerformedOn?.label
            ).length === 1;

        if (isUniqueDate) {
            setToBePerformedOnDates((prevDates) =>
                prevDates.filter(
                    (date) => date.label !== toBePerformedOn?.label
                )
            );
        }
    };

    function processRSTLieuDateString(inputString) {
        const lieuDates = [];
        const rstDates = [];
        const datePairs = inputString.split(",").map((pair) => pair.trim());

        for (const pair of datePairs) {
            const match = pair.match(/^(\d{2}[A-Z]{3}\d{4})\s*\((\d+)\)$/);
            if (match) {
                const [, dateStr, mutaStr] = match;
                const parsedMuta = parseInt(mutaStr);
                const parsedDate = parseDate(dateStr);

                // Find matching date range in baDates
                const baDate = baDates.find((item) =>
                    isDateInRange(parsedDate, item.date_range)
                );

                const rstMUTAItem = {
                    MUTA: parsedMuta,
                    date: dateStr,
                    date_range: "",
                    key: dateStr,
                    label: dateStr,
                    type: "individual",
                    value: dateStr,
                };

                const lieuBADateItem = {
                    date: dateStr,
                    date_range: "",
                    expanded: true,
                    key: dateStr,
                    label: dateStr,
                    selected: true,
                    type: "individual",
                    value: dateStr,
                };

                if (baDate) {
                    rstMUTAItem.date_range = baDate.date_range;
                    lieuBADateItem.date_range = baDate.date_range;
                }

                rstDates.push(rstMUTAItem);
                lieuDates.push(lieuBADateItem);
            }
        }

        setRSTMUTA(rstDates);
        handleDraftRSTDateSelect(lieuDates, true);
        return rstDates;
    }

    function processRSTPerformedDateString(inputString) {
        const performedDates = [];
        const datePairs = inputString.split(",").map((pair) => pair.trim());

        for (const pair of datePairs) {
            const match = pair.match(/^(\d{2}[A-Z]{3}\d{2,4})\s*\((\d+)\)$/);

            if (match) {
                let [, dateStr, mutaStr] = match;
                const parsedMuta = parseInt(mutaStr);

                // Update dateStr if it's in DDMMMYY format
                if (dateStr.length === 7) {
                    dateStr = dateStr.slice(0, 5) + 20 + dateStr.slice(5);
                }

                const performedOnItem = {
                    label: dateStr,
                    value: dateStr,
                    MUTA: parsedMuta,
                };

                performedDates.push(performedOnItem);
            }
        }
        // Assuming setToBePerformedOnDates is a valid function in your context
        setToBePerformedOnDates(performedDates);
        return performedDates;
    }

    /**
     * Function is used for the Date Pickers within the table. Main components of this function
     * is we check for duplicated dates (show error if true, alert and highlighted components) else
     * we add the date toBePerformedOn
     *
     * One thing that had to be done is when a user updates a date lets say from 06/14/2024 to 06/14/2024
     * there would be a date conflict error that would appear so the approach I took was to remove the date
     * from the toBePerformedOn and then added it back again to ensure no date conflicts appeared.
     *
     */
    const onDateTimeChange = async (value, rowId, index) => {
        // Check if the date provided is valid
        if (value === null || isNaN(new Date(value).getTime())) {
            setRows((prevRows) => {
                return prevRows.map((row) => {
                    if (row.id === rowId) {
                        return { ...row, toBePerformedOn: "" };
                    }
                    return row;
                });
            });
            return;
        }

        // Format the new date label and value
        const newLabel = moment(value.$d).format("DDMMMYYYY").toUpperCase();
        const dateVal = new Date(value.$d);

        // Check for duplicate dates in toBePerformedOnDates
        const isDuplicate = toBePerformedOnDates.some(
            (date) => date.label === newLabel && date.rowId !== rowId
        );
        if (isDuplicate) {
            // Update the highlighted rows to indicate a date conflict
            setHighlightedRows((prevState) => {
                const updatedHighlightedRows = [...prevState];
                updatedHighlightedRows[index] = {
                    ...updatedHighlightedRows[index],
                    toBePerformedOnEmpty: true,
                };
                return updatedHighlightedRows;
            });

            setRows((prevRows) => {
                return prevRows.map((row) => {
                    if (row.id === rowId) {
                        return {
                            ...row,
                            toBePerformedOn: {
                                label: moment(dateVal)
                                    .format("DDMMMYYYY")
                                    .toUpperCase(),
                            },
                        };
                    }
                    return row;
                });
            });

            showSnackbar("error", "Date already chosen");
            return;
        }

        // Create a new performedOn object for the selected date
        const perfObject = {
            label: moment(dateVal).format("DDMMMYYYY").toUpperCase(),
            value: moment(dateVal).format("DDMMMYYYY").toUpperCase(),
            MUTA: 0,
            rowId: rowId,
        };

        // Remove the previous date associated with the row from toBePerformedOnDates to avoid date already chosen errors within the same date picker
        setToBePerformedOnDates((prevDates) => {
            const updatedDates = prevDates.filter(
                (date) => date.rowId !== rowId
            );
            return [...updatedDates, perfObject];
        });

        // Update the rows with the new performedOn object
        setRows((prevRows) => {
            return prevRows.map((row) => {
                if (row.id === rowId) {
                    return { ...row, toBePerformedOn: perfObject };
                }
                return row;
            });
        });

        // Update the highlighted rows to indicate no date conflict
        setHighlightedRows((prevState) => {
            const updatedHighlightedRows = [...prevState];
            updatedHighlightedRows[index] = {
                ...updatedHighlightedRows[index],
                toBePerformedOnEmpty: false,
            };
            return updatedHighlightedRows;
        });

        // Sort the performedOn state for pretty format on the pdf
        setToBePerformedOnDates((prevDates) => {
            const updatedDates = [
                ...prevDates.filter((date) => date.rowId !== rowId),
                perfObject,
            ];
            const sortedDates = updatedDates.sort(
                PerformedOnSortOrder("label")
            );
            return sortedDates;
        });
    };

    // Function is called to handle the lieuBADate selection within the table
    const handleSelectChange = (event, id, index) => {
        const selectedDate = event.target.value;

        // Update the highlightedRows state to indicate no lieuBAMUTA error for the current row
        setHighlightedRows((prevState) => {
            const updatedHighlightedRows = [...prevState];

            // Update lieuBAMUTA error for the current row
            updatedHighlightedRows[index] = {
                ...updatedHighlightedRows[index],
                lieuBAMUTA: false,
            };

            // Update lieuBAMUTA error for all rows with the same lieuBA value
            const newHighlightedRows = updatedHighlightedRows.map((row, i) => {
                if (rows[i]?.lieuBA === selectedDate) {
                    return {
                        ...row,
                        lieuBAMUTA: false,
                    };
                }
                return row;
            });

            return newHighlightedRows;
        });

        // Update the rows state with the selected lieu date
        setRows((prevRows) => {
            const updatedRows = prevRows.map((row) => {
                if (row.id === id) {
                    // Check if there is another row with the same lieuBA value
                    const existingRow = prevRows.find(
                        (r) => r.lieuBA === selectedDate && r.id !== id
                    );
                    return {
                        ...row,
                        lieuBA: selectedDate,
                        lieuBAMUTA: existingRow ? existingRow.lieuBAMUTA : 0,
                    };
                }
                return row;
            });

            // Check if the selected date is being removed and update rstMUTA accordingly
            const removedDate = prevRows.find((row) => row.id === id).lieuBA;
            const isRemovedDateUnique =
                prevRows.filter((row) => row.lieuBA === removedDate).length ===
                1;
            if (isRemovedDateUnique) {
                setRSTMUTA((prevRSTMUTA) =>
                    prevRSTMUTA.map((item) =>
                        item.label === removedDate ? { ...item, MUTA: 0 } : item
                    )
                );
            }

            return updatedRows;
        });
    };

    const handleCheckboxChange = (value, label, index = null) => {
        if ([1, 2].includes(value)) {
            // Update the rstMUTA state with the new MUTA value for the corresponding label
            setRSTMUTA((prevState) =>
                prevState.map((item) =>
                    item.label === label ? { ...item, MUTA: value } : item
                )
            );

            // Update the rows state with the new lieuBAMUTA value for the corresponding label
            setRows((prevState) =>
                prevState.map((item) =>
                    item.lieuBA === label
                        ? { ...item, lieuBAMUTA: value }
                        : item
                )
            );

            // If an index is provided, update the highlightedRows state to indicate no lieuBAMUTA error for the current row
            if (index !== null) {
                setHighlightedRows((prevState) => {
                    const updatedHighlightedRows = [...prevState];
                    updatedHighlightedRows[index] = {
                        ...updatedHighlightedRows[index],
                        lieuBAMUTA: false,
                    };
                    return updatedHighlightedRows;
                });
            }
        }
    };

    const handleToBePerformedOnCheckboxChange = (value, row, index = null) => {
        if ([1, 2].includes(value)) {
            setToBePerformedOnDates((prevState) =>
                prevState.map((item) =>
                    item.label === row.toBePerformedOn.label
                        ? { ...item, MUTA: value }
                        : item
                )
            );

            // Update the rows state with the new toBePerformedOnMUTA value for the corresponding label
            setRows((prevState) =>
                prevState.map((item) =>
                    item.id === row.id
                        ? {
                            ...item,
                            toBePerformedOn: {
                                ...item.toBePerformedOn,
                                MUTA: value,
                            },
                        }
                        : item
                )
            );

            // Update the highlightedRows state to indicate no toBePerfomredOnMUTA error for the current row
            setHighlightedRows((prevState) => {
                const rowIndex = rows.findIndex((item) => item.id === row.id);
                if (rowIndex !== -1) {
                    const updatedHighlightedRows = [...prevState];
                    updatedHighlightedRows[rowIndex] = {
                        ...updatedHighlightedRows[rowIndex],
                        toBePerformedOnMUTA: false,
                    };
                    return updatedHighlightedRows;
                }
                return prevState;
            });
        }
    };

    const handleFocus = (rowId) => {
        // Reset the error state for the specific row
        setHighlightedRows((prevState) => {
            const updatedHighlightedRows = [...prevState];
            updatedHighlightedRows[rowId] = {
                ...updatedHighlightedRows[rowId],
                lieuBA: false,
            };
            return updatedHighlightedRows;
        });
    };

    const handleBlur = (rowId, value) => {
        // Set the error state for the specific row if value doesn't pass our conflict conditions
        setHighlightedRows((prevState) => {
            const updatedHighlightedRows = [...prevState];
            updatedHighlightedRows[rowId] = {
                ...updatedHighlightedRows[rowId],
                lieuBA: value === "" || value === "No Options",
            };
            return updatedHighlightedRows;
        });
    };

    // This component is used for the DropDown to prevent resetting scroll position to the top
    const PaperComponent = useCallback((paperProps) => {
        return (
            <Paper
                style={{
                    background: colors.modal_background,
                    color: colors.inputted_text,
                }}
            >
                {paperProps.children}
            </Paper>
        );
    }, []);

    const validateRMAStartDate = (date) => {
        if (date) {
            // Check if the provided date is a valid date
            const isValidDate = !isNaN(new Date(date).getTime());
            setRMADateError(!isValidDate);

            if (isValidDate) {
                // If the date is valid, set the RMA selected date and formatted date
                setRMASelectedDate(date);
                setRMAFormattedDate(
                    moment(date).format("DDMMMYYYY").toUpperCase()
                );
            } else {
                // If the date is invalid, reset the RMA selected date and formatted date
                setRMASelectedDate(null);
                setRMAFormattedDate("");
            }
        } else {
            // If no date is provided, set the RMA date error and reset the selected and formatted dates
            setRMADateError(true);
            setRMASelectedDate(null);
            setRMAFormattedDate("");
        }
    };

    const validateATAStartDate = (date) => {
        if (date) {
            // Check if the provided date is valid using moment.js
            const isValidDate = moment(date).isValid();
            setATADateError(!isValidDate);

            if (isValidDate) {
                // If the date is valid, set the ATA selected date and formatted date
                setATASelectedDate(date);
                setATAFormattedDate(
                    moment(date).format("DDMMMYYYY").toUpperCase()
                );
            } else {
                // If the date is invalid, reset the ATA selected date and formatted date
                setATASelectedDate(null);
                setATAFormattedDate("");
            }
        } else {
            // If no date is provided, set the ATA date error and reset the selected and formatted dates
            setATADateError(true);
            setATASelectedDate(null);
            setATAFormattedDate("");
        }
    };

    const loadingStyles = {
        animation: {
            flexDirection: "row",
            alignItems: "center",
            flex: 1,
            justifyContent: "center",
            paddingVertical: 20,
            background: colors.screen_background,
        },
    };

    if (isLoading) {
        return (
            <View style={loadingStyles.animation}>
                <Progress.CircleSnail
                    color={colors.loading_circle}
                    indeterminate={true}
                    size={275}
                    thickness={8}
                />
            </View>
        );
    }

    return (
        <ThemeProvider theme={reviewTheme}>
            <View style={styles.container}>
                <Scrollbars style={styles.scrollView}>
                    <View style={styles.mainWrapper}>
                        <Text style={styles.pageTitle}>New RST</Text>
                        <Text style={styles.description}>
                            Fill out an RST form for approval
                        </Text>

                        {reasonForDenial !== "" &&
                            reasonForDenial !== undefined && (
                                <View>
                                    <View style={styles.denialComponent}>
                                        <Text style={[styles.denialHeader]}>
                                            Reason for Denial:
                                        </Text>
                                        <Text style={[styles.denialText]}>
                                            {reasonForDenial}
                                        </Text>
                                    </View>
                                </View>
                            )}

                        <View style={styles.contentBox}>
                            <View style={{ flexDirection: "row" }}>
                                <View style={styles.contentContainer}>
                                    <FormControl
                                        fullWidth
                                        style={styles.dropDownStyle}
                                        sx={styles.textInputProps}
                                    >
                                        <InputLabel
                                            id="duty_checkbox"
                                            style={{
                                                color: colors.inputted_text,
                                            }}
                                        >
                                            Select type of duty*
                                        </InputLabel>
                                        <Select
                                            inputprops={styles.selectProps}
                                            label="Select type of duty*"
                                            labelId="duty_checkbox"
                                            id="duty-dropdown"
                                            value={selectedType}
                                            onChange={handleDutyChange}
                                            onBlur={() => {
                                                setselectedTypeEmptyError(
                                                    selectedType === ""
                                                        ? true
                                                        : false
                                                );
                                            }}
                                            error={selectedTypeEmptyError}
                                            display={"true"}
                                        >
                                            <MenuItem
                                                key={"excusedAbsence"}
                                                style={{
                                                    color: colors.inputted_text,
                                                }}
                                                value={"excusedAbsence"}
                                            >
                                                Excused Absence
                                            </MenuItem>
                                            <MenuItem
                                                key={"rst"}
                                                style={{
                                                    color: colors.inputted_text,
                                                }}
                                                value={"rst"}
                                            >
                                                RST
                                            </MenuItem>
                                            {/* <MenuItem
                                                    style={{color: colors.inputted_text}}
                                                    value={'rma'}
                                                >
                                                    RMA (Readiness Management Assembly)
                                                </MenuItem>
                                                <MenuItem
                                                    style={{color: colors.inputted_text}}
                                                    value={'ata'}
                                                >
                                                    ATA (Additional Training Assembly)
                                                </MenuItem> */}
                                        </Select>
                                        <FormHelperText
                                            style={{
                                                color: "red",
                                                paddingTop: "1.42px",
                                            }}
                                        >
                                            {selectedTypeEmptyError
                                                ? "Select type of duty"
                                                : " "}
                                        </FormHelperText>
                                    </FormControl>

                                    <View>
                                        <TextInput
                                            style={[styles.commentInput]}
                                            theme={{
                                                colors: {
                                                    text: colors.inputted_text,
                                                    accent: colors.textfield_background,
                                                    primary:
                                                        colors.inputted_text,
                                                    placeholder:
                                                        colors.placeholder_text,
                                                },
                                            }}
                                            selectionColor={"black"}
                                            activeOutlineColor={"black"}
                                            label="Tasks to be performed as makeup*"
                                            placeholder=""
                                            multiline={false}
                                            value={tasks}
                                            onChangeText={setTasks}
                                            onBlur={() => {
                                                settaskTypeEmptyError(
                                                    tasks === "" ? true : false
                                                );
                                            }}
                                            error={taskTypeEmptyError}
                                        />
                                        <HelperText
                                            style={{ color: "red" }}
                                            visible={taskTypeEmptyError}
                                        >
                                            {"Enter a task(s)"}
                                        </HelperText>
                                    </View>

                                    <View>
                                        <TextInput
                                            style={styles.commentInput}
                                            theme={{
                                                colors: {
                                                    text: colors.inputted_text,
                                                    accent: colors.textfield_background,
                                                    primary:
                                                        colors.inputted_text,
                                                    placeholder:
                                                        colors.placeholder_text,
                                                },
                                            }}
                                            selectionColor={"black"}
                                            activeOutlineColor={"black"}
                                            label="Justification*"
                                            placeholder=""
                                            multiline={false}
                                            value={justification}
                                            onChangeText={setJustification}
                                            onBlur={() => {
                                                setjustificationTypeEmptyError(
                                                    justification === ""
                                                        ? true
                                                        : false
                                                );
                                            }}
                                            error={justificationTypeEmptyError}
                                        />
                                        <HelperText
                                            style={{ color: "red" }}
                                            visible={
                                                justificationTypeEmptyError
                                            }
                                        >
                                            {"Enter a justification"}
                                        </HelperText>
                                    </View>
                                </View>
                                <View style={styles.contentContainer}>
                                    <FormControl
                                        fullWidth
                                        style={styles.dropDownStyle}
                                        sx={styles.textInputProps}
                                    >
                                        <InputLabel
                                            id="recommender_checkbox"
                                            style={{
                                                color: colors.inputted_text,
                                            }}
                                        >
                                            Select Recommender*
                                        </InputLabel>
                                        <Select
                                            inputprops={styles.selectProps}
                                            label="Select Recommender*"
                                            labelId="recommender_checkbox"
                                            id="recommender-dropdown"
                                            value={recommender}
                                            onChange={handleRecommenderChange}
                                            onBlur={() => {
                                                setrecommenderTypeEmptyError(
                                                    recommender === ""
                                                        ? true
                                                        : false
                                                );
                                            }}
                                            error={recommenderTypeEmptyError}
                                            display={"true"}
                                        >
                                            {officers.map((value) => {
                                                return (
                                                    <MenuItem
                                                        key={value}
                                                        value={value}
                                                    >
                                                        {value.name}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                        <FormHelperText
                                            style={{
                                                color: "red",
                                                paddingTop: "1.42px",
                                            }}
                                        >
                                            {recommenderTypeEmptyError
                                                ? "Select a recommender"
                                                : " "}
                                        </FormHelperText>
                                    </FormControl>

                                    <FormControl
                                        fullWidth
                                        style={styles.dropDownStyle}
                                        sx={styles.textInputProps}
                                    >
                                        <InputLabel
                                            id="reviewer_checkbox"
                                            style={{
                                                color: colors.inputted_text,
                                            }}
                                        >
                                            Select an Approver*
                                        </InputLabel>
                                        <Select
                                            inputprops={styles.selectProps}
                                            label="Select an Approver*"
                                            labelId="reviewer_checkbox"
                                            id="reviewer-dropdown"
                                            value={reviewer}
                                            onChange={handleReviewerChange}
                                            onBlur={() => {
                                                setreviewerTypeEmptyError(
                                                    reviewer === ""
                                                        ? true
                                                        : false
                                                );
                                            }}
                                            error={reviewerTypeEmptyError}
                                            display={"true"}
                                        >
                                            {commanders.map((value) => {
                                                return (
                                                    <MenuItem
                                                        key={value}
                                                        value={value}
                                                    >
                                                        {value.name}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                        <FormHelperText
                                            style={{
                                                color: "red",
                                                paddingTop: "1.42px",
                                            }}
                                        >
                                            {reviewerTypeEmptyError
                                                ? "Select an Approver"
                                                : " "}
                                        </FormHelperText>
                                    </FormControl>

                                    <View>
                                        <TextInput
                                            style={[styles.commentInput]}
                                            theme={{
                                                colors: {
                                                    text: colors.inputted_text,
                                                    accent: colors.textfield_background,
                                                    primary:
                                                        colors.inputted_text,
                                                    placeholder:
                                                        colors.placeholder_text,
                                                },
                                            }}
                                            selectionColor={"black"}
                                            activeOutlineColor={"black"}
                                            label="Soldier Notes"
                                            placeholder=""
                                            multiline={false}
                                            value={soldierNotes}
                                            onChangeText={setSoldierNotes}
                                        />
                                        <HelperText
                                            style={{ color: "red" }}
                                            visible={soldierNotesEmptyError}
                                        >
                                            {soldierNotesEmptyError ? " " : " "}
                                        </HelperText>
                                    </View>
                                </View>
                            </View>
                            {selectedType === "excusedAbsence" && (
                                <View style={{ flexDirection: "row" }}>
                                    <View style={styles.contentContainer}>
                                        <FormControl
                                            fullWidth
                                            style={styles.dropDownStyle}
                                            sx={styles.textInputProps}
                                        >
                                            <InputLabel
                                                id="excused_dates_checkbox"
                                                style={{
                                                    color: colors.inputted_text,
                                                }}
                                            ></InputLabel>
                                            <Autocomplete
                                                freeSolo
                                                id="excused-dates-dropdown"
                                                options={
                                                    expandedDates.length
                                                        ? expandedDates
                                                        : baDates
                                                }
                                                getOptionLabel={(option) =>
                                                    option.label
                                                }
                                                inputValue={
                                                    selectedBADate?.type ===
                                                        "individual"
                                                        ? selectedBADate.label
                                                        : ""
                                                }
                                                value={selectedBADate || null}
                                                onChange={(event, value) => {
                                                    handleEADateChange(
                                                        event,
                                                        value
                                                    );
                                                }}
                                                onBlur={() => {
                                                    setBADateEmptyError(
                                                        selectedBADate?.label ===
                                                        "" ||
                                                        selectedBADate ===
                                                        null
                                                    );
                                                }}
                                                isOptionEqualToValue={(
                                                    option,
                                                    value
                                                ) =>
                                                    option.label ===
                                                    value?.label &&
                                                    option.date_range ===
                                                    value?.date_range
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="B.A. Date*"
                                                        error={BADateEmptyError}
                                                    />
                                                )}
                                                renderOption={(
                                                    props,
                                                    option,
                                                    { selected }
                                                ) => (
                                                    <MenuItem
                                                        {...props}
                                                        style={{
                                                            backgroundColor:
                                                                styles.dropDownMenu,
                                                            paddingLeft:
                                                                option.type ===
                                                                    "individual" &&
                                                                    !isOneDayRange(
                                                                        option.date_range
                                                                    )
                                                                    ? "8%"
                                                                    : null,
                                                        }}
                                                        checked={selected}
                                                    >
                                                        {option.label}
                                                    </MenuItem>
                                                )}
                                                disableCloseOnSelect
                                                ListboxProps={{
                                                    style: {
                                                        maxHeight: "300px",
                                                        overflowY: "auto",
                                                    },
                                                }}
                                                ListboxComponent={List}
                                                PaperComponent={PaperComponent}
                                                noOptionsText={
                                                    <Typography
                                                        style={{
                                                            color: colors.inputted_text,
                                                        }}
                                                    >
                                                        No options
                                                    </Typography>
                                                }
                                                inputprops={{
                                                    style: {
                                                        backgroundColor:
                                                            colors.modal_background,
                                                    },
                                                }}
                                            />
                                            <FormHelperText
                                                style={{ color: "red" }}
                                            >
                                                {BADateEmptyError
                                                    ? "Select B.A. date(s)"
                                                    : " "}
                                            </FormHelperText>
                                        </FormControl>
                                    </View>

                                    <View style={styles.contentContainer}>
                                        <TextInput
                                            style={styles.commentInput}
                                            theme={{
                                                colors: {
                                                    text: colors.inputted_text,
                                                    accent: colors.textfield_background,
                                                    primary:
                                                        colors.inputted_text,
                                                    placeholder:
                                                        colors.placeholder_text,
                                                },
                                            }}
                                            selectionColor={"black"}
                                            activeOutlineColor={"black"}
                                            label="MUTA Number*"
                                            placeholder=""
                                            maxLength={3}
                                            value={muta}
                                            onChangeText={handleTextChange}
                                            onBlur={() => {
                                                setMUTAEmptyError(
                                                    muta === "" ? true : false
                                                );
                                            }}
                                            error={MUTAEmptyError}
                                        />
                                        <HelperText
                                            style={{ color: "red" }}
                                            visible={MUTAEmptyError}
                                        >
                                            {"Enter number of MUTAs"}
                                        </HelperText>
                                    </View>
                                </View>
                            )}
                            {selectedType === "rma" && (
                                <View style={{ flexDirection: "row" }}>
                                    <View style={styles.contentContainer}>
                                        <FormControl
                                            fullWidth
                                            style={{
                                                width: "50%",
                                                alignSelf: "center",
                                            }}
                                        >
                                            <LocalizationProvider
                                                dateAdapter={AdapterDayjs}
                                            >
                                                <ThemeProvider
                                                    theme={reviewTheme}
                                                >
                                                    <ClickAwayListener
                                                        onClickAway={(
                                                            event
                                                        ) => {
                                                            if (
                                                                rmaDatePickerRef.current &&
                                                                !rmaDatePickerRef.current.contains(
                                                                    event.target
                                                                )
                                                            ) {
                                                                setRMADatePickerOpen(
                                                                    false
                                                                );
                                                            }
                                                        }}
                                                        mouseEvent="onMouseDown"
                                                        disableRestoreFocus={
                                                            true
                                                        }
                                                    >
                                                        <DatePicker
                                                            label="RMA Performed On*"
                                                            sx={
                                                                styles.rmaDateBox
                                                            }
                                                            value={
                                                                rmaSelectedDate
                                                            }
                                                            onChange={(
                                                                date
                                                            ) => {
                                                                validateRMAStartDate(
                                                                    date
                                                                );
                                                            }}
                                                            dateFormat="MM/dd/yyyy"
                                                            onClose={() =>
                                                                setRMADatePickerOpen(
                                                                    false
                                                                )
                                                            }
                                                            onOpen={() =>
                                                                setRMADatePickerOpen(
                                                                    true
                                                                )
                                                            }
                                                            open={
                                                                rmaDatePickerOpen
                                                            }
                                                            slotProps={{
                                                                textField: {
                                                                    variant:
                                                                        "outlined",
                                                                    error: rmaDateError,
                                                                    InputProps:
                                                                    {
                                                                        style: {
                                                                            borderColor:
                                                                                rmaDateError
                                                                                    ? "red"
                                                                                    : colors.border_color,
                                                                            color: colors.inputted_text,
                                                                            backgroundColor:
                                                                                colors.modal_background,
                                                                        },
                                                                    },
                                                                    onBlur: () => {
                                                                        if (
                                                                            rmaSelectedDate ===
                                                                            null ||
                                                                            isNaN(
                                                                                new Date(
                                                                                    rmaSelectedDate
                                                                                ).getTime()
                                                                            )
                                                                        ) {
                                                                            setRMADateError(
                                                                                true
                                                                            );
                                                                        }
                                                                    },
                                                                    onFocus:
                                                                        () => {
                                                                            setRMADateError(
                                                                                false
                                                                            );
                                                                        },
                                                                },
                                                            }}
                                                            PopperProps={{
                                                                modifiers: [
                                                                    {
                                                                        name: "eventListeners",
                                                                        options:
                                                                        {
                                                                            scroll: false,
                                                                            resize: true,
                                                                        },
                                                                    },
                                                                ],
                                                                ref: (
                                                                    popperRef
                                                                ) => {
                                                                    if (
                                                                        popperRef &&
                                                                        popperRef.node
                                                                    ) {
                                                                        popperRef.node.id =
                                                                            "calendar-element";
                                                                        rmaDatePickerRef.current =
                                                                            popperRef.node.querySelector(
                                                                                ".MuiCalendarPicker-root"
                                                                            );
                                                                    }
                                                                },
                                                            }}
                                                        />
                                                    </ClickAwayListener>
                                                </ThemeProvider>
                                            </LocalizationProvider>
                                            <FormHelperText
                                                style={{ color: "red" }}
                                            >
                                                {rmaDateError
                                                    ? "Select a valid RMA date"
                                                    : " "}
                                            </FormHelperText>
                                        </FormControl>
                                    </View>
                                </View>
                            )}
                            {selectedType === "ata" && (
                                <View style={{ flexDirection: "row" }}>
                                    <View style={styles.contentContainer}>
                                        <FormControl
                                            fullWidth
                                            style={{ width: "95%" }}
                                        >
                                            <LocalizationProvider
                                                dateAdapter={AdapterDayjs}
                                            >
                                                <ThemeProvider
                                                    theme={reviewTheme}
                                                >
                                                    <ClickAwayListener
                                                        onClickAway={(
                                                            event
                                                        ) => {
                                                            if (
                                                                ataDatePickerOpen.current &&
                                                                !ataDatePickerOpen.current.contains(
                                                                    event.target
                                                                )
                                                            ) {
                                                                setATADatePickerOpen(
                                                                    false
                                                                );
                                                            }
                                                        }}
                                                        mouseEvent="onMouseDown"
                                                        disableRestoreFocus={
                                                            true
                                                        }
                                                    >
                                                        <DatePicker
                                                            label="ATA Performed On*"
                                                            sx={
                                                                styles.rmaDateBox
                                                            }
                                                            value={
                                                                ataSelectedDate
                                                            }
                                                            onChange={(
                                                                date
                                                            ) => {
                                                                validateATAStartDate(
                                                                    date
                                                                );
                                                            }}
                                                            dateFormat="MM/dd/yyyy"
                                                            onClose={() =>
                                                                setATADatePickerOpen(
                                                                    false
                                                                )
                                                            }
                                                            onOpen={() =>
                                                                setATADatePickerOpen(
                                                                    true
                                                                )
                                                            }
                                                            open={
                                                                ataDatePickerOpen
                                                            }
                                                            slotProps={{
                                                                textField: {
                                                                    variant:
                                                                        "outlined",
                                                                    error: ataDateError,
                                                                    InputProps:
                                                                    {
                                                                        style: {
                                                                            borderColor:
                                                                                ataDateError
                                                                                    ? "red"
                                                                                    : colors.border_color,
                                                                            color: colors.inputted_text,
                                                                            backgroundColor:
                                                                                colors.modal_background,
                                                                        },
                                                                    },
                                                                    onBlur: () => {
                                                                        if (
                                                                            ataSelectedDate ===
                                                                            null ||
                                                                            isNaN(
                                                                                new Date(
                                                                                    ataSelectedDate
                                                                                ).getTime()
                                                                            )
                                                                        ) {
                                                                            setATADateError(
                                                                                true
                                                                            );
                                                                        }
                                                                    },
                                                                    onFocus:
                                                                        () => {
                                                                            setATADateError(
                                                                                false
                                                                            );
                                                                        },
                                                                },
                                                            }}
                                                            PopperProps={{
                                                                modifiers: [
                                                                    {
                                                                        name: "eventListeners",
                                                                        options:
                                                                        {
                                                                            scroll: false,
                                                                            resize: true,
                                                                        },
                                                                    },
                                                                ],
                                                                ref: (
                                                                    popperRef
                                                                ) => {
                                                                    if (
                                                                        popperRef &&
                                                                        popperRef.node
                                                                    ) {
                                                                        popperRef.node.id =
                                                                            "calendar-element";
                                                                        ataDatePickerRef.current =
                                                                            popperRef.node.querySelector(
                                                                                ".MuiCalendarPicker-root"
                                                                            );
                                                                    }
                                                                },
                                                            }}
                                                        />
                                                    </ClickAwayListener>
                                                </ThemeProvider>
                                            </LocalizationProvider>
                                            <FormHelperText
                                                style={{ color: "red" }}
                                            >
                                                {ataDateError
                                                    ? "Select a valid ATA date"
                                                    : " "}
                                            </FormHelperText>
                                        </FormControl>
                                    </View>

                                    <View style={styles.contentContainer}>
                                        <TextInput
                                            style={{
                                                ...styles.commentInput,
                                                marginTop: "0%",
                                            }}
                                            theme={{
                                                colors: {
                                                    text: colors.inputted_text,
                                                    accent: colors.textfield_background,
                                                    primary:
                                                        colors.inputted_text,
                                                    placeholder:
                                                        colors.placeholder_text,
                                                },
                                            }}
                                            selectionColor={"black"}
                                            activeOutlineColor={"black"}
                                            label="ATA Number*"
                                            placeholder=""
                                            maxLength={3}
                                            value={ATAs}
                                            onChangeText={handleTextChange}
                                            onBlur={() => {
                                                setATAEmptyError(
                                                    ATAs === "" ? true : false
                                                );
                                            }}
                                            error={ATAEmptyError}
                                        />
                                        <HelperText
                                            style={{ color: "red" }}
                                            visible={ATAEmptyError}
                                        >
                                            {"Enter number of ATAs"}
                                        </HelperText>
                                    </View>
                                </View>
                            )}
                        </View>
                        {selectedType == "rst" && (
                            <>
                                <View style={styles.rstContainer}>
                                    <View
                                        style={{
                                            width: "45%",
                                            marginRight: "4%",
                                        }}
                                    >
                                        <FormControl
                                            fullWidth
                                            style={{
                                                ...styles.dropDownStyle,
                                                marginBottom: "1%",
                                                width: "100%",
                                            }}
                                            sx={styles.textInputProps}
                                        >
                                            <InputLabel
                                                id="rst_dates_checkbox"
                                                style={{
                                                    color: colors.inputted_text,
                                                }}
                                            ></InputLabel>
                                            <Autocomplete
                                                multiple
                                                id="rst-dates-dropdown"
                                                options={
                                                    expandedDates.length
                                                        ? expandedDates
                                                        : baDates
                                                }
                                                getOptionLabel={(option) =>
                                                    option.label
                                                }
                                                value={selectedRanges.concat(
                                                    lieuBADates
                                                )}
                                                onChange={handleRSTDateSelect}
                                                onBlur={() =>
                                                    setlieuBADatesEmptyError(
                                                        lieuBADates.length <= 0
                                                    )
                                                }
                                                isOptionEqualToValue={(
                                                    option,
                                                    value
                                                ) =>
                                                    option.label ===
                                                    value?.label &&
                                                    option.date_range ===
                                                    value?.date_range
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="In Lieu of B.A. Date(s)*"
                                                        error={
                                                            lieuBADatesEmptyError
                                                        }
                                                        style={{
                                                            alignSelf: "center",
                                                        }}
                                                    />
                                                )}
                                                renderOption={(
                                                    props,
                                                    option,
                                                    { selected }
                                                ) => (
                                                    <MenuItem
                                                        {...props}
                                                        style={{
                                                            backgroundColor:
                                                                styles.modal_background,
                                                            paddingLeft:
                                                                option.type ===
                                                                    "individual" &&
                                                                    !isOneDayRange(
                                                                        option.date_range
                                                                    )
                                                                    ? "8%"
                                                                    : null,
                                                        }}
                                                        checked={selected}
                                                    >
                                                        {option.label}
                                                    </MenuItem>
                                                )}
                                                disableCloseOnSelect
                                                ListboxProps={{
                                                    style: {
                                                        maxHeight: "250px",
                                                        overflowY: "auto",
                                                    },
                                                    role: "listbox",
                                                }}
                                                ListboxComponent={List}
                                                PaperComponent={PaperComponent}
                                                noOptionsText={
                                                    <Typography
                                                        style={{
                                                            color: colors.inputted_text,
                                                        }}
                                                    >
                                                        No options
                                                    </Typography>
                                                }
                                                inputprops={{
                                                    style: {
                                                        backgroundColor:
                                                            colors.modal_background,
                                                    },
                                                    classes: {
                                                        root: "MuiInputBase-root",
                                                        input: "MuiInputBase-input",
                                                    },
                                                }}
                                            // sx={{
                                            //   '& .MuiInputBase-root': {
                                            //     display: 'flex',
                                            //     justifyContent: 'center',
                                            //   },
                                            //   '& .MuiInputBase-input': {
                                            //     maxWidth: '100px', // Adjust the value as needed
                                            //   },
                                            // }}
                                            />
                                            <FormHelperText
                                                style={{ color: "red" }}
                                            >
                                                {lieuBADatesEmptyError
                                                    ? "Select B.A. date(s)"
                                                    : " "}
                                            </FormHelperText>
                                        </FormControl>
                                    </View>
                                    <View style={styles.tableContainer}>
                                        <View style={styles.tableDiv}>
                                            <TableContainer
                                                component={Paper}
                                                style={{
                                                    height: "fixed",
                                                    backgroundColor:
                                                        colors.table_background_color,
                                                    color: colors.bow,
                                                }}
                                            >
                                                <Table>
                                                    <TableHead>
                                                        <TableRow
                                                            style={{
                                                                borderColor:
                                                                    colors.border_color,
                                                            }}
                                                        >
                                                            <TableCell
                                                                style={{
                                                                    width: "45%",
                                                                }}
                                                            >
                                                                <Text
                                                                    style={{
                                                                        color: colors.inputted_text,
                                                                        fontSize:
                                                                            "1rem",
                                                                    }}
                                                                >
                                                                    In Lieu Of
                                                                </Text>
                                                            </TableCell>
                                                            <TableCell
                                                                style={{
                                                                    width: "45%",
                                                                }}
                                                            >
                                                                <Text
                                                                    style={{
                                                                        color: colors.inputted_text,
                                                                        fontSize:
                                                                            "1rem",
                                                                    }}
                                                                >
                                                                    To Be
                                                                    Performed On
                                                                </Text>
                                                            </TableCell>
                                                            <TableCell
                                                                style={{
                                                                    width: "10%",
                                                                    padding:
                                                                        "12px",
                                                                }}
                                                            >
                                                                <TouchableOpacity
                                                                    onPress={
                                                                        handleAddRow
                                                                    }
                                                                >
                                                                    <AddRoundedIcon
                                                                        style={{
                                                                            color: colors.file_Approved,
                                                                            fontSize:
                                                                                "3.5vh",
                                                                        }}
                                                                    />
                                                                </TouchableOpacity>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {loaded &&
                                                            rows.map(
                                                                (
                                                                    row,
                                                                    index
                                                                ) => (
                                                                    <TableRow
                                                                        key={
                                                                            row.id
                                                                        }
                                                                        style={{
                                                                            borderColor:
                                                                                colors.border_color,
                                                                            height:
                                                                                width >
                                                                                    1300
                                                                                    ? 113
                                                                                    : 137,
                                                                            verticalAlign:
                                                                                width >
                                                                                    1300
                                                                                    ? null
                                                                                    : "unset",
                                                                        }}
                                                                    >
                                                                        <TableCell
                                                                            style={{
                                                                                width: "45%",
                                                                            }}
                                                                        >
                                                                            <View
                                                                                style={{
                                                                                    flexDirection:
                                                                                        width >
                                                                                            1300
                                                                                            ? "row"
                                                                                            : "column",
                                                                                    alignItems:
                                                                                        "center",
                                                                                }}
                                                                            >
                                                                                <LieuDateSelect
                                                                                    index={
                                                                                        index
                                                                                    }
                                                                                    styles={
                                                                                        styles
                                                                                    }
                                                                                    value={
                                                                                        row.lieuBA
                                                                                    }
                                                                                    onChange={(
                                                                                        event
                                                                                    ) =>
                                                                                        handleSelectChange(
                                                                                            event,
                                                                                            row.id,
                                                                                            index,
                                                                                            row
                                                                                        )
                                                                                    }
                                                                                    error={
                                                                                        highlightedRows[
                                                                                            index
                                                                                        ]
                                                                                            ?.lieuBA
                                                                                    }
                                                                                    options={
                                                                                        lieuBADates
                                                                                    }
                                                                                    onFocus={() =>
                                                                                        handleFocus(
                                                                                            index
                                                                                        )
                                                                                    }
                                                                                    onBlur={() =>
                                                                                        handleBlur(
                                                                                            index,
                                                                                            row.lieuBA
                                                                                        )
                                                                                    }
                                                                                    width={
                                                                                        width
                                                                                    }
                                                                                    GetSortOrder={
                                                                                        GetSortOrder
                                                                                    }
                                                                                />
                                                                                {/* This is here to determine whether we show the MUTA checkboxes if the date has been selected */}
                                                                                {row.lieuBA &&
                                                                                    rows.findIndex(
                                                                                        (
                                                                                            r
                                                                                        ) =>
                                                                                            r.lieuBA ===
                                                                                            row.lieuBA
                                                                                    ) ===
                                                                                    rows.indexOf(
                                                                                        row
                                                                                    ) ? (
                                                                                    <CheckboxList
                                                                                        items={[
                                                                                            1,
                                                                                            2,
                                                                                        ]}
                                                                                        row={
                                                                                            row
                                                                                        }
                                                                                        onItemSelect={(
                                                                                            value
                                                                                        ) =>
                                                                                            handleCheckboxChange(
                                                                                                value,
                                                                                                row.lieuBA,
                                                                                                index
                                                                                            )
                                                                                        }
                                                                                        index={
                                                                                            row.lieuBA
                                                                                        }
                                                                                        selectedValue={
                                                                                            row.lieuBAMUTA
                                                                                        }
                                                                                        error={
                                                                                            highlightedRows[
                                                                                                index
                                                                                            ]
                                                                                                ?.lieuBAMUTA
                                                                                        }
                                                                                        width={
                                                                                            width
                                                                                        }
                                                                                    />
                                                                                ) : null}
                                                                            </View>
                                                                        </TableCell>
                                                                        <TableCell
                                                                            style={{
                                                                                width: "45%",
                                                                            }}
                                                                        >
                                                                            <TableCellComponent
                                                                                navigation={
                                                                                    navigation
                                                                                }
                                                                                rows={
                                                                                    rows
                                                                                }
                                                                                dateObj={
                                                                                    row
                                                                                }
                                                                                setRows={
                                                                                    setRows
                                                                                }
                                                                                toBePerformedOnDates={
                                                                                    toBePerformedOnDates
                                                                                }
                                                                                setToBePerformedOnDates={
                                                                                    setToBePerformedOnDates
                                                                                }
                                                                                onDateTimeChange={
                                                                                    onDateTimeChange
                                                                                }
                                                                                deleteEntry={() =>
                                                                                    handleDeleteRow(
                                                                                        row.id
                                                                                    )
                                                                                }
                                                                                reviewTheme={
                                                                                    reviewTheme
                                                                                }
                                                                                colors={
                                                                                    colors
                                                                                }
                                                                                styles={
                                                                                    styles
                                                                                }
                                                                                highlightedRows={
                                                                                    highlightedRows
                                                                                }
                                                                                index={
                                                                                    index
                                                                                }
                                                                                setHighlightedRows={
                                                                                    setHighlightedRows
                                                                                }
                                                                                width={
                                                                                    width
                                                                                }
                                                                                handleToBePerformedOnCheckboxChange={
                                                                                    handleToBePerformedOnCheckboxChange
                                                                                }
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell
                                                                            style={{
                                                                                width: "10%",
                                                                            }}
                                                                        >
                                                                            <TouchableOpacity
                                                                                onPress={() =>
                                                                                    handleDeleteRow(
                                                                                        row.id,
                                                                                        row.lieuBA,
                                                                                        row.toBePerformedOn
                                                                                    )
                                                                                }
                                                                            >
                                                                                <ClearRoundedIcon
                                                                                    style={{
                                                                                        color: colors.delete,
                                                                                        fontSize:
                                                                                            "2.8vh",
                                                                                    }}
                                                                                />
                                                                            </TouchableOpacity>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )
                                                            )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </View>
                                    </View>
                                </View>
                            </>
                        )}

                        <Text
                            style={[
                                styles.signText,
                                { width: "60%", marginTop: "2%" },
                            ]}
                        >
                            By clicking on the "Submit" button below, you
                            consent to your digital signature being affixed to
                            the document. Your signature can be viewed below
                            after clicking the "Sign Document" button. If you
                            don't have a signature, please add one below.
                        </Text>

                        <View
                            style={{
                                width: "100%",
                                alignItems: "center",
                                maringTop: "1%",
                            }}
                        >
                            {doesUserHaveSignature == true ? (
                                <>
                                    <TouchableOpacity
                                        style={[
                                            styles.signDocButton,
                                            {
                                                backgroundColor:
                                                    colors.action_button,
                                            },
                                        ]}
                                        onPress={handleSignature}
                                    >
                                        <Text
                                            style={{
                                                justifyContent: "center",
                                                fontSize: 15,
                                                color: colors.text,
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {viewSig
                                                ? "Document Signed"
                                                : "Sign Document"}
                                        </Text>
                                    </TouchableOpacity>
                                    {viewSig && (
                                        <>
                                            <Image
                                                resizeMode={"contain"}
                                                style={{
                                                    width: 200,
                                                    height: 100,
                                                    backgroundColor: "white",
                                                    marginRight: ".9%",
                                                    marginTop: "1%",
                                                }}
                                                source={{
                                                    uri: global.signature_uri,
                                                }}
                                            />
                                        </>
                                    )}
                                </>
                            ) : (
                                <View
                                    style={{
                                        width: "25%",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginRight: "1%",
                                    }}
                                >
                                    <Text
                                        style={[
                                            styles.textStyle,
                                            { padding: 15 },
                                        ]}
                                    >
                                        No signature found
                                    </Text>

                                    <TextInput
                                        style={styles.commentInput}
                                        theme={{
                                            colors: {
                                                text: colors.inputted_text,
                                                accent: colors.textfield_background,
                                                primary: colors.inputted_text,
                                                placeholder:
                                                    colors.placeholder_text,
                                            },
                                        }}
                                        label="Enter your first and last name"
                                        placeholder=""
                                        value={signatureName}
                                        onChange={(data) =>
                                            setSignatureName(data.target.value)
                                        }
                                    />

                                    <ViewShot
                                        ref={viewRef}
                                        style={{
                                            padding: 10,
                                            marginVertical: 10,
                                            backgroundColor: "white",
                                        }}
                                        options={{ format: "png", quality: 1 }}
                                    >
                                        <Text style={{ color: "black" }}>
                                            {signatureName}
                                        </Text>
                                    </ViewShot>

                                    <TouchableOpacity
                                        style={[
                                            styles.cancelButton,
                                            {
                                                backgroundColor:
                                                    colors.action_button,
                                            },
                                        ]}
                                        onPress={captureTextAsImage}
                                    >
                                        <Text style={styles.textStyle}>
                                            Create Signature
                                        </Text>
                                    </TouchableOpacity>
                                </View>
                            )}
                        </View>

                        <View style={[styles.rowContainer]}>
                            <TouchableOpacity
                                style={[
                                    styles.cancelButton,
                                    { marginLeft: "2%", marginRight: "2%" },
                                    { backgroundColor: colors.button_cancel },
                                ]}
                                onPress={onCancel}
                            >
                                <Text style={styles.textStyle}>Cancel</Text>
                            </TouchableOpacity>

                            <TouchableOpacity
                                style={[
                                    styles.cancelButton,
                                    { marginLeft: "2%", marginRight: "2%" },
                                ]}
                                onPress={viewBlank}
                            >
                                <Text style={styles.textStyle}>
                                    View Blank Form
                                </Text>
                            </TouchableOpacity>
                            <TouchableOpacity
                                style={[
                                    styles.cancelButton,
                                    { marginLeft: "2%", marginRight: "2%" },
                                ]}
                                onPress={viewFilled}
                            >
                                <Text style={styles.textStyle}>
                                    View Filled
                                </Text>
                            </TouchableOpacity>
                            {!pulledFromDraft && !hideDraftButton && (
                                <TouchableOpacity
                                    style={[
                                        styles.cancelButton,
                                        { marginLeft: "2%", marginRight: "2%" },
                                    ]}
                                    onPress={handleLoadDraft}
                                >
                                    <Text style={styles.textStyle}>
                                        Load Draft
                                    </Text>
                                </TouchableOpacity>
                            )}
                            {mutaMatch &&
                                selectedType !== "" &&
                                !failedToRetrieve && (
                                    <TouchableOpacity
                                        style={[
                                            styles.button,
                                            {
                                                backgroundColor: viewSig
                                                    ? colors.button_submit
                                                    : colors.button_draft,
                                                marginLeft: "2%",
                                                marginRight: "2%",
                                            },
                                        ]}
                                        onPress={() => {
                                            if (!submitted) {
                                                checkFields();
                                            }
                                        }}
                                    >
                                        <Text style={styles.textStyle}>
                                            {viewSig ? "Submit" : "Save Draft"}
                                        </Text>
                                    </TouchableOpacity>
                                )}
                            {!submitted && !mutaMatch && (
                                <Text style={styles.error}>
                                    There is an issue with the MUTAs you are
                                    doing and how many are selected. Make sure
                                    both are equal{" "}
                                </Text>
                            )}
                        </View>
                    </View>
                </Scrollbars>
            </View>
        </ThemeProvider>
    );
}

CreateNewRST.propTypes = {
    navigation: PropTypes.object.isRequired,
};

export default CreateNewRST;
