import React, {useContext, createContext, useState} from 'react';
import dayjs from 'dayjs';
import {getMonth} from './util';

// Create a context for calendar state
const CalendarContext = createContext();

// Create a custom hook to access the context
export const useCalendarContext = () => {
  const context = useContext(CalendarContext);
  if (!context) {
    throw new Error('useCalendarContext must be used within a CalendarProvider');
  }
  return context;
};

// CalendarProvider component to wrap your application
export function CalendarProvider({children}) {
  const initialState = {
    currentMonthIndex: dayjs().month(),
    currentMonth: getMonth(dayjs().month()),
    selectedMonth: undefined,
    selectedDay: undefined,
    showEventModal: false,
    showEditEventModal: false,
    isHovered: false,
    hoveredEvent: null,
    todayClicked: false,
    events: [],
    dotObjects: [],
    monthObjects: [],
    eventData: {selectedDate: null},
    labels: {
      Mandatory: true,
      Optional: true,
      Personal: true,
    },

    //If we are using the value to do something with a useEffect, the value in the context needs to be a useState!
    isSubmitted:useState(true),

    // This is the day which would be edited. On click of a specific day,
    // this will be selected with all of the event info it knows and turn on the showEventModal
    day: useState(null),
  };
  const [state, setState] = useState(initialState);

  return (
    <CalendarContext.Provider value={{state, setState}}>
      {children}
    </CalendarContext.Provider>
  );
}
