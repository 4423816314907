import React, { useContext, useEffect, useRef } from 'react';
import { View, Text, TouchableOpacity, Image, Dimensions } from 'react-native';

import { useScale } from '../components/scale';
import ModeContext from '../components/ModeContext';
import { useColorContext } from '../components/ColorContext';
import Scrollbars from "react-custom-scrollbars";
import { login, update_theme, create_new_user_signature, update_user_password, update_user_location } from '../api_handler/users.js';
import { put_image_s3 } from "../api_handler/files";
import AsyncStorage from '@react-native-async-storage/async-storage';
import ViewShot from 'react-native-view-shot';
import { storeJsonData } from '../api_handler/asyncstorage.js';
import { useSnackbar } from '../components/SnackbarContext.js';
import { captureRef } from 'react-native-view-shot';
import { TextField, IconButton, Autocomplete, FormControl, InputLabel, MenuItem } from '@mui/material';
import { useIsFocused } from '@react-navigation/native';
import * as default_pfp from '../api_handler/default_pfp';
import { get_image_s3 } from '../api_handler/files.js';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import * as Progress from 'react-native-progress'

function Review({ navigation }) {
  const { colors } = useColorContext();
  const { showSnackbar } = useSnackbar();
  const { mode, toggleMode } = useContext(ModeContext);
  const [rank_name, setRankName] = React.useState(global.user_data.rank + ' ' + global.user_data.first_name + ' ' + global.user_data.last_name);
  const [mail, setMail] = React.useState(global.user_data.email);
  const [addr1, setAddr1] = React.useState(global.user_data.city == null || global.user_data.state == null || global.user_data.city == '' || global.user_data.state == '' ? 'No Address' : global.user_data.address_line_1);
  const [addr2, setAddr2] = React.useState(global.user_data.address_line_2);
  const [cityStateZip, setCityStateZip] = React.useState(global.user_data.city == null || global.user_data.state == null || global.user_data.city == '' || global.user_data.state == '' ? '' : global.user_data.city + ' ' + global.user_data.state + ', ' + global.user_data.zipcode);
  const [passwordCur, setPasswordCur] = React.useState('');
  const [passwordCurEmptyError, setPasswordCurEmptyError] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [passwordEmptyError, setPasswordEmptyError] = React.useState(false);
  const [passRepeat, setPassRepeat] = React.useState('');
  const [passRepeatEmptyError, setPassRepeatEmptyError] = React.useState(false);
  const [state, setState] = React.useState(global.user_data.state);
  const [stateEmptyError, setStateEmptyError] = React.useState(false);
  const [city, setCity] = React.useState(global.user_data.city);
  const [cityEmptyError, setCityEmptyError] = React.useState(false);
  const [address_line_1, setAddress1] = React.useState(global.user_data.address_line_1);
  const [addressLine1EmptyError, setAddressLine1EmptyError] = React.useState(false);
  const [address_line_2, setAddress2] = React.useState(global.user_data.address_line_2);
  const [zipcode, setZipcode] = React.useState(global.user_data.zipcode);
  const [zipcodeEmptyError, setZipcodeEmptyError] = React.useState(false);
  const [signatureName, setSignatureName] = React.useState("");
  const [textWidth, setTextWidth] = React.useState(0);
  const states = require('./admin_console/users/json_files/States.json');
  const [pfp, setNewPfp ] = React.useState(global.profile_pic);
  const viewRef = useRef();
  const fileInputRef = useRef(null);
  const { width, height } = Dimensions.get('window');
  const screenWidth = Dimensions.get('window').width;
  const isFocused = useIsFocused();
  const [loaded, setLoaded] = React.useState(false);
  const scale = useScale();
  const styles = ({
    container: {
      // flex: 1,
      // backgroundColor: colors.screen_background,
      height: "100%",
      width: "100%",
      // flexDirection: "row",
      backgroundColor: colors.screen_background,
    },
    wrapper: {
      flexDirection: 'row',
      height: '100%'
    },
    list: {
      width: '75%',
    },
    title: {
      fontSize: scale(30),
      fontFamily: "Trebuchet MS",
      flex: 1,
      color: colors.text,
      paddingLeft: "5%",
      paddingTop: 25,
    },
    headerText: {
      fontSize: width < 1300 ?  20 : 25,
      fontWeight: 'bold',
      color: colors.text,
    },
    infoText: {
      fontSize: width < 1300 ? (width < 700 ? 9 : 13) : 16,
      fontWeight: 'bold',
      // paddingLeft: '6%',
      paddingVertical: '1%',
      color: colors.bow,
      
      overflow: 'hidden',
    },
    passwordRequirements:{
      fontWeight: 'bold',
      color: colors.bow,
    },
    button: {
      color: colors.text,
      width: '10vw',
      height: 40,
      borderRadius: 10,
      paddingTop: width < 1300 ? 5 : 10,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: colors.content_box,
      marginTop: '1%',
      borderColor: colors.border_color,
      borderWidth: 1,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      textAlign: "center",
      alignItems: "center",
    },
    textStyle: {
      justifyContent: "center",
      textAlign: 'center', 
      fontSize: width < 1650 ? 11 : 15,
      fontWeight: "bold",
      color: colors.text,
    },
    header: {
      height: '15%',
    },
    main: {
      flex: 10,
      height: "100%",
    },
    item: {
      backgroundColor: 'orange',
      padding: 20,
      marginVertical: 8,
      marginHorizontal: 16,
      borderRadius: 8,
      shadowColor: '#000',
      shadowOffset: { width: 3, height: 3 },
      shadowOpacity: 0.3,
      shadowRadius: 8,
    },
    helpBtn: {
      height: width < 1300 ? 30 : 40,
      width: "45%",
      borderRadius: 10,
      paddingTop: width < 1300 ? 5 : -5,
      paddingLeft: 20,
      paddingRight: 20,
      alignItems: 'center',
      color: 'white',
      borderRadius: 10,
      borderColor: colors.border_color,
      shadowColor: '#171717',
      borderWidth: 1,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: width < 1300 ? '1%' : '5%',
      marginBottom: "10%",
      backgroundColor: colors.button,
    },
    innerText: {
      color: colors.text,
      fontWeight: 'bold',
      fontSize: width < 1300 ? 9 : 15,
      marginTop: width < 1300 ? -10 : -5
    },
    helperText: {
      color: 'red',
      fontSize: 12,
      textAlign: 'left',
      marginTop: 4,
      backgroundColor: colors.screen_background
    },
    textbox: {
      width: '45%',
      marginTop: '1%',
      backgroundColor: colors.textfield_background,
      borderColor: colors.bow,
      InputLabelProps: {
        sx: {
          borderColor: colors.bow,
          color: colors.text,
        },
      },
    },
    smallTextBox:{
      width: '27%',
      marginTop: '1%',
      backgroundColor: colors.textfield_background,
      borderColor: colors.bow,
      InputLabelProps: {
        sx: {
          borderColor: colors.bow,
          color: colors.text,
        },
      },
    },
    passwordTextbox: {
      width: '50%',
      marginTop: '1%',
      backgroundColor: colors.textfield_background,
      borderColor: colors.bow,
      InputLabelProps: {
        sx: {
          borderColor: colors.bow,
          color: colors.text,
        },
      },
    },
    horizontalLine: {
      top: '20px',
      width: '100%',
      height: '2px',
      backgroundColor: '#d6dde4',
      position: 'absolute',
    },    
    horizontalLineSmall: {
      top: '20px',
      width: '20%',
      height: '2px',
      backgroundColor: '#d6dde4',
      position: 'absolute',
    },
    locationDiv:{
      marginTop: width < 1300 ? '2.5%' : '-3%',
      // alignItems: 'flex-start',
      right: width < 1300 ? '' : '31%',
      left: width < 1300 ? '5%' : '',
      width: '88%',
      overflow: 'hidden',
    },
    signatureDiv: {
      marginTop: width < 1300 ? '40px' : '57px',
      // alignItems: 'flex-start',
      right: width < 1300 ? '' : '22%',
      left: width < 1300 ? '5%' : '',
      width: width < 1300 ? '88%' : '66%',
      alignItems: width < 1300 ? null : 'center',
      overflow: 'hidden',
    },
    passwordDiv: {
      marginTop: '2.5%',
      // alignItems: 'flex-start',
      left: '5%',
      width: '88%',
      overflow: 'hidden',
    },
    sectionDiv: {
      marginTop: '1.5%',
      // alignItems: 'flex-start',
      left: '6%',
      width: '88%',
      overflow: 'hidden',
    },
    profileImage: {
      marginTop: -40,
      marginBottom: '2%',
      width: width < 1300 ? 100 : 150,
      height: width < 1300 ? 100 : 150,
      borderRadius: 100,
      overflow: 'hidden',
      borderColor: colors.bow,
      borderWidth: 2,
      alignSelf: 'center',
      marginLeft: width < 1300 ? (width < 350 ? '10px' : '20px') : null
      // left: '6%' 
    },
    chooseProfileImage: {
      marginTop: "8%",
      marginBottom: width >= 1300 ? "10%" : "15%" ,
      marginRight: width >= 1300 ? '-35%' : '-50',
      left: width < 1300 ? '88%' : '80%'
    },
    image: {
      flex: 1,
      width: undefined,
      height: undefined,
    },
    profileBox: {
      flexDirection: "column",
      marginLeft: '3%',
      marginTop: '2%',
      backgroundColor: colors.navSideBar_Background,
      width: '25%',
      height: width < 1300 ? (width < 1300 ? 175 : 325 ): 400,
      borderRadius: 20,
      alignItems: 'center',
      borderColor: colors.border_color,
      borderWidth: global.user_data.theme === 'dark' ? 1 : 0,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
    },
    profileBoxShrunk: {
      marginLeft: '10%',
      marginTop: '2%',
      backgroundColor: colors.navSideBar_Background,
      width: '80%',
      height: 175,
      borderRadius: 20,
      justifyContent: 'center',
      alignItems: 'center',
      borderColor: colors.border_color,
      borderWidth: global.user_data.theme === 'dark' ? 1 : 0,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
    },
    editArea: {
      flex: 2,
    },
    dropDownStyle: {
      borderColor: colors.bow,
      borderWidth: 1,
      borderRadius: 5,
      width: "45%",
      marginTop: '1%',
      backgroundColor: colors.textfield_background,
    },
    smallDropDownStyle: {
      borderColor: colors.bow,
      borderWidth: 1,
      borderRadius: 5,
      width: "27%",
      marginTop: '1%',
      backgroundColor: colors.textfield_background,
    },
    textInputProps: {
      '& .MuiFormLabel-root, & label.Mui-focused, & .MuiInputBase-input': {
        color: colors.inputted_text,
        borderColor: colors.bow
        // WebkitTextFillColor:  colors.inputted_text ,
      },
      '.MuiSvgIcon-root': {
        fill: colors.screen_background,
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor:  colors.bow,
        },
        '&:hover fieldset': {
          borderColor: colors.bow,
        },
        '&.Mui-focused fieldset': {
          borderColor: colors.bow,
        },
      },
      '&  .MuiFormHelperText-root': {
        backgroundColor: colors.screen_background,
        borderColor: colors.bow,
        margin: 0,
        paddingLeft: 1,
      },
      // .MuiFormHelperText-root.Mui-error
    },
  });

  useEffect(() => {
    async function getProfile() {
      var pfp = await get_image_s3('pfp/' + global.user_data.user_id, 'image/jpg', global.token);
      if (pfp === null || pfp === undefined) {
          global.profile_pic = 'data:image/png;base64,' + default_pfp.pfp();
      } else {
          global.profile_pic = await decodeBase64(pfp);
      }
      // console.log(gl)
      setNewPfp(global.profile_pic)
      setLoaded(true);
    }

    if (isFocused) {
      getProfile();
    }
  }, [isFocused])

  async function decodeBase64(s) {
    var e = {}, i, b = 0, c, x, l = 0, a, r = '', w = String.fromCharCode, L = s.length;
    var A = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
    for (i = 0; i < 64; i++) { e[A.charAt(i)] = i; }
    for (x = 0; x < L; x++) {
        c = e[s.charAt(x)]; b = (b << 6) + c; l += 6;
        while (l >= 8) { ((a = (b >>> (l -= 8)) & 0xff) || (x < (L - 2))) && (r += w(a)); }
    }
    return r;
  };

  function isValidPassword(password) {
    // Regular expressions to check each requirement
    const hasNumber = /\d/;
    const hasSpecialChar = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
    const hasUpperCase = /[A-Z]/;
    const hasLowerCase = /[a-z]/;
    const isLengthValid = /.{8,}/;

    // Check all requirements
    const meetsRequirements =
      hasNumber.test(password) &&
      hasSpecialChar.test(password) &&
      hasUpperCase.test(password) &&
      hasLowerCase.test(password) &&
      isLengthValid.test(password);

    return meetsRequirements;
  }

  const submit_new_password = async () => {
    const login_info = {
      email: mail,
      password: passwordCur,
    };

    const res = await login(login_info);

    if (res.status !== 200) {
      showSnackbar('error', 'Application Error: Current password is incorrect');
      setPasswordCurEmptyError(true);
      return;
    }

    if (passwordCur !== '' && password !== '' && passRepeat !== '') {
      if (password == passRepeat) {
        if (isValidPassword(password)) {
          const new_password = {
            user_id: global.user_data.user_id,
            email: global.user_data.email,
            password: password,
          };
          const msg = await update_user_password(new_password, global.token);
          if (msg.status !== 200) {
            showSnackbar('error', 'Application Error: Invalid password');
          } else {
            showSnackbar('success', 'Password successfully updated');
            setPasswordCur('');
            setPassword('');
            setPassRepeat('');
          }
        } else {
          showSnackbar('error', 'Password does not meet requirements');
          setPasswordEmptyError(true);
          setPassRepeatEmptyError(true);
        }
      } else {
        showSnackbar('error', 'Passwords do not match');
        setPasswordEmptyError(true);
        setPassRepeatEmptyError(true);
      }
    } else {
      showSnackbar('error', 'Password cannot be empty')
      setPasswordEmptyError(true);
      setPassRepeatEmptyError(true);
    }
  };

  const submit_new_location = async () => {
    if (address_line_1 === '' || city === '' || state === '' || zipcode === '') {
      showSnackbar("error", "Please correct one or more invalid inputs");
    } else {
      const new_location = {
        city: city == '' ? global.user_data.city : city,
        state: state == '' ? global.user_data.state : state,
        address_line_1: address_line_1 == '' ? global.user_data.address_line_1 : address_line_1,
        address_line_2: address_line_2,
        zipcode: zipcode == '' ? global.user_data.zipcode : zipcode,
        user_id: global.user_data.user_id,
      };
      let res = await update_user_location(new_location, global.idToken);
      if (res.status === 200) {
        global.user_data.city = new_location.city;
        global.user_data.state = new_location.state;
        global.user_data.address_line_1 = new_location.address_line_1;
        global.user_data.address_line_2 = new_location.address_line_2;
        global.user_data.zipcode = new_location.zipcode;
        // setLocation(global.user_data.city + ' ' + global.user_data.state);
      } else {
        showSnackbar("error", "Failed to update your location");
      }
      showSnackbar("success", "Location successfully updated");
    }
  };

  const captureTextAsImage = async () => {
    try {
      const fullname = global.user_data.first_name.toLowerCase() + " " + global.user_data.last_name.toLowerCase();
      if (signatureName.toLowerCase() === fullname) {
        const uri = await captureRef(viewRef, {
          format: 'png',
          quality: 0.8
        });

        let ret = await create_new_user_signature({ user_id: global.user_data.user_id }, global.token);
        if (ret.status === 200) {
          ret = ret.data;
          await put_image_s3('signatures/' + ret.body.signature_id, uri, 'image/png', global.token);

          global.signature_uri = uri;

          global.user_data.signature_id = ret.body.signature_id;
          await AsyncStorage.setItem('signature', global.signature_uri);
          storeJsonData('user_data', global.user_data);
          showSnackbar('success', "Signature updated");
          setSignatureName('');
        } else {
          showSnackbar("error", "Application Error: Failed to create your signature");
        }

      } else {
        showSnackbar('error', 'Input Error: The name inputted does not match your name we have on record');
      }
    } catch (error) {
      console.error('Error capturing image:', error);
    }
  };

  const handleStateChange = (event, value) => {
    console.log(value)
    setState(value.value);
    setStateEmptyError(false);

    if (value = null) {
      setStateEmptyError(true);
    }
  };

  const handleZipCodeChange = (text) => {
    const numericText = text.replace(/[^0-9]/g, '');
    const limitedText = numericText.slice(0, 5);
    setZipcode(limitedText);
    setZipcodeEmptyError(limitedText === '')
  };

  const handleAttachmentSelect = async (value) => {
    const file = value.target.files[0];
    if (!file) {
      showSnackbar("error", 'No image selected');
      setLoaded(true);
      return;
    }

    const fileType = file.type;
    if (!fileType.includes('image')) {
      showSnackbar('error', 'You must add an image that is in the .jpg or .png format');
      return;
    }

    const reader = new FileReader();
    reader.onload = async () => {
      const base64String = reader.result.split(',')[1];
      const imageData = `data:${fileType};base64,${base64String}`;

      // Use imageData as needed, for example, uploading to S3 or displaying on the UI
      // Replace the following lines with your S3 upload logic and newPfp function
      await put_image_s3('pfp/' + global.user_data.user_id, imageData, fileType, global.token);
      setNewPfp(imageData);

      showSnackbar('success','Successfully added image');
    };
    reader.readAsDataURL(file);
  };

  const loadingStyles = {
    animation: {
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
        justifyContent: 'center',
        paddingVertical: 20,
        background: colors.screen_background,
    }
  }

  if (!loaded) {
    return (
      <View style={loadingStyles.animation}>
          <Progress.CircleSnail
            color={colors.loading_circle}
            indeterminate={true}
            size={275}
            thickness={8}
          />
      </View>
    );
  }

  return (

    <View style={styles.container}>
      <Scrollbars style={styles.main}>
        <View style={{ flexDirection: "column" }}>
          <Text style={styles.title}>Settings - Your Profile</Text>
        </View>

        <View style={{ flexDirection: width < 1300 ? 'column' : 'row' }}>
          {/* Left column Aqui*/}
          <View style={width < 1300 ? styles.profileBoxShrunk : styles.profileBox}>
            <View style={{flexDirection: width < 1300 ? 'row' : '', marginTop: width < 1300 ? '7%' : 20}}>
              <View >
                <View style={styles.chooseProfileImage}>
                  <input
                    type="file"
                    accept=".jpg, .jpeg, .png, .pdf"
                    onChange={handleAttachmentSelect}
                    style={{ display: "none" }}
                    ref={fileInputRef}
                  />
                  <ControlPointIcon  style={{color: colors.bow}} onClick={() => fileInputRef.current.click()}/>
                </View>
                <View style={styles.profileImage}>
                  <Image
                    source={{ uri: pfp }}
                    style={styles.image}
                    resizeMode="cover"
                  />
                </View>
              </View>
              <View style={{marginVertical: '4%', alignItems: 'center', marginLeft: width < 1300 ? '5%' : null}}>
                <Text style={[styles.infoText]}>{rank_name}</Text>
                <Text style={[styles.infoText]}>{mail}</Text>
                <Text style={[styles.infoText]}>{addr1}</Text>
                <Text style={[styles.infoText]}>{addr2}</Text>
                <Text style={[styles.infoText]}>{cityStateZip}</Text>
              </View>
              
            </View>
              {/* toggle dark/light mode */}
              <TouchableOpacity style={styles.helpBtn} onPress={async () => {
                // Toggle between dark and light mode. Update mode color in web and in database.
                const update_theme_JSON = { 'user_id': global.user_data.user_id, 'theme': mode === 'light' ? 'dark' : 'light' };
                let res = await update_theme(update_theme_JSON, global.token);
                if (res.status !== 200) {
                  showSnackbar("error", "Application Error: Failed to update your theme")
                } else {
                  toggleMode();
                  global.user_data.theme = mode === 'light' ? 'dark' : 'light';
                  storeJsonData('user_data', global.user_data);
                }
              }}>
                <Text style={styles.innerText}>Toggle Dark Mode</Text>
              </TouchableOpacity>
          </View>
          {/* Right column */}
          <View style={styles.editArea}>
            {/* password edit */}
            <View style={styles.passwordDiv}>
              <Text style={styles.headerText}>Change Password</Text>
              <View style={[styles.horizontalLine, { left: width < 1300 ? '180px' : '220px' }]} />
              <View style={{flexDirection: 'row'}}>
                <View id='password-inputs' style={{width:'100%'}}>
                  <TextField
                    style={styles.passwordTextbox}
                    sx={styles.textInputProps}
                    size={width < 1300 ? 'small' : ''}
                    label="Current Password"
                    value={passwordCur}
                    error={passwordCurEmptyError}
                    onBlur={() => {
                      setPasswordCurEmptyError(passwordCur === "");
                    }}
                    onChange={(data) => setPasswordCur(data.target.value)}
                    InputLabelProps={styles.textbox.InputLabelProps}
                    inputProps={styles.textbox.InputLabelProps}
                  />
                  
                  <Text style={styles.helperText}>{passwordCurEmptyError ? "Enter current password" : ' '}</Text>
                  <TextField
                    style={styles.passwordTextbox}
                    sx={styles.textInputProps}
                    size={width < 1300 ? 'small' : ''}
                    label="New Password"
                    value={password}
                    error={passwordEmptyError}
                    onBlur={() => {
                      setPasswordEmptyError(password === "");
                    }}
                    onChange={(data) => setPassword(data.target.value)}
                    InputLabelProps={styles.textbox.InputLabelProps}
                    inputProps={styles.textbox.InputLabelProps}
                  />
                  <Text style={styles.helperText}>{passwordEmptyError ? "Enter new password" : ' '}</Text>
                  <TextField
                    style={styles.passwordTextbox}
                    sx={styles.textInputProps}
                    size={width < 1300 ? 'small' : ''}
                    label="Re-Enter Password"
                    value={passRepeat}
                    error={passRepeatEmptyError}
                    onBlur={() => {
                      setPassRepeatEmptyError(password === "");
                    }}
                    onChange={(data) => setPassRepeat(data.target.value)}
                    InputLabelProps={styles.textbox.InputLabelProps}
                    inputProps={styles.textbox.InputLabelProps}
                  />
                  <Text style={styles.helperText}>{passRepeatEmptyError ? "Re-enter new password" : ' '}</Text>
                </View>
                <View id='password-requirements' style={{right:'40%', top:'15%'}}>
                  <Text style={[styles.passwordRequirements, { fontSize: 15 }]}>Password must contain:</Text>
                  <Text style={[styles.passwordRequirements, { fontSize: 15 }]}>• 1 number</Text>
                  <Text style={[styles.passwordRequirements, { fontSize: 15 }]}>• 1 special character</Text>
                  <Text style={[styles.passwordRequirements, { fontSize: 15 }]}>• 1 uppercase letter</Text>
                  <Text style={[styles.passwordRequirements, { fontSize: 15 }]}>• 1 lowercase letter</Text>
                  <Text style={[styles.passwordRequirements, { fontSize: 15 }]}>• Be 8 characters in length</Text>
                </View>
              </View>
              <TouchableOpacity
                style={styles.button}
                onPress={submit_new_password}
              >
                <Text style={styles.textStyle}>Update Password</Text>
              </TouchableOpacity>
            </View>
            <View style={width < 1300 ? {} : {flexDirection: 'row', marginTop:'10%', right: '30%'}}> 
              {/* signature edit */}
              <View style={styles.signatureDiv}>
                <Text style={[styles.headerText, {marginLeft: '2%'}]}>Add/Change Signature</Text>
                {width < 1300 && <View style={[styles.horizontalLine, { left: width < 1300 ? '240px' : '280px' }]} /> }
                <TextField
                  style={styles.textbox}
                  sx={styles.textInputProps}
                  size={width < 1300 ? 'small' : ''}
                  label="Enter your first and last name"
                  value={signatureName}
                  onChange={(data) => setSignatureName(data.target.value)}
                  InputLabelProps={styles.textbox.InputLabelProps}
                  inputProps={styles.textbox.InputLabelProps}
                />
                <ViewShot ref={viewRef} style={{ marginVertical: 10, width: "fit-content", backgroundColor: 'white' }} options={{ format: 'png', quality: 1 }}>
                  <Text style={{ color: 'black', fontSize: 20 }}>{signatureName}</Text>
                </ViewShot>
                <TouchableOpacity
                  style={[styles.button, {marginTop: '0%'}]}
                  onPress={captureTextAsImage}
                >
                  <Text style={styles.textStyle}>Create Signature</Text>
                </TouchableOpacity>
              </View>
              {/* location edit */}
              <View style={styles.locationDiv}>
                <Text style={styles.headerText}>Change Location</Text>
                <View style={[styles.horizontalLine, { left: width < 1300 ? '180px' : '215px' }]} />
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingTop: '2%' }}>
                  <TextField
                    style={styles.textbox}
                    sx={styles.textInputProps}
                    size={width < 1300 ? 'small' : ''}
                    label="Address Line 1"
                    value={address_line_1}
                    error={addressLine1EmptyError}
                    onBlur={() => {
                      setAddressLine1EmptyError(address_line_1 === "");
                    }}
                    onChange={(data) => setAddress1(data.target.value)}
                    InputLabelProps={styles.textbox.InputLabelProps}
                    inputProps={styles.textbox.InputLabelProps}
                  />
                  <TextField
                    style={styles.textbox}
                    sx={styles.textInputProps}
                    size={width < 1300 ? 'small' : ''}
                    label="Address Line 2"
                    value={address_line_2}
                    onChange={(data) => setAddress2(data.target.value)}
                    InputLabelProps={styles.textbox.InputLabelProps}
                    inputProps={styles.textbox.InputLabelProps}
                  />
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between'}}>
                  <Text style={styles.helperText}>{addressLine1EmptyError ? "Enter address line 1" : ' '}</Text>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between'}}>
                  <TextField
                    style={styles.smallTextBox}
                    sx={styles.textInputProps}
                    size={width < 1300 ? 'small' : ''}
                    label="City"
                    value={city}
                    error={cityEmptyError}
                    onBlur={() => {
                      setCityEmptyError(city === "");
                    }}
                    onChange={(data) => setCity(data.target.value)}
                    InputLabelProps={styles.textbox.InputLabelProps}
                    inputProps={styles.textbox.InputLabelProps}
                  />
                  
                  <TextField
                    style={styles.smallTextBox}
                    sx={styles.textInputProps}
                    size={width < 1300 ? 'small' : ''}
                    label="Zipcode"
                    value={zipcode}
                    error={zipcodeEmptyError}
                    onBlur={() => {
                      setZipcodeEmptyError(zipcode === "");
                    }}
                    onChange={(data) => handleZipCodeChange(data.target.value)}
                    InputLabelProps={styles.textbox.InputLabelProps}
                    inputProps={styles.textbox.InputLabelProps}
                  />
                  <FormControl style={styles.smallDropDownStyle} sx={styles.textInputProps}>
                    <InputLabel id="state_checkbox" style={{ color: colors.inputted_text }}></InputLabel>
                    <Autocomplete
                      id="state-dropdown"
                      options={Object.values(states)}
                      size={width < 1300 ? 'small' : ''}
                      getOptionLabel={(option) => option.label || state}
                      value={state}
                      onChange={handleStateChange}
                      onBlur={() => {
                        setStateEmptyError(!state || state == 'N/A');
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="State"
                          error={stateEmptyError}
                        />
                      )}
                      renderOption={(props, option) => (
                        <li {...props} style={{ backgroundColor: colors.screen_background, color: colors.inputted_text }}>
                          <MenuItem {...props}>{option.label}</MenuItem>
                        </li>
                      )}
                      isOptionEqualToValue={(option, value) => option.label === value.label}
                    />
                  </FormControl>
                  {/* <View style={{ flexDirection: 'row' }}>
                    <Text style={styles.helperText}>{cityEmptyError ? "Enter a city" : ' '}</Text>
                    <Text style={[styles.helperText, { marginLeft: '55%' }]}>{zipcodeEmptyError ? "Enter a zipcode" : ' '}</Text>
                    <Text style={styles.helperText}>{stateEmptyError ? 'Select a state' : ' '}</Text>
                  </View> */}
                </View>
                <TouchableOpacity
                  style={[styles.button, {marginBottom: '5%', marginTop: '2.3%'}]}
                  onPress={submit_new_location}
                >
                  <Text style={styles.textStyle}>Update Location</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      </Scrollbars>
    </View>
  );
};

export default Review;