import React, {useEffect, useState} from 'react';
import {View} from 'react-native';


import Calendar from '../components/calendar/Calendar';
import {getMonth} from '../components/calendar/util';

import {useWindowDimensions} from 'react-native';
import {useIsFocused} from '@react-navigation/native';
import dayjs from 'dayjs';
import {useScale} from '../components/scale';
import CustomHeader from '../components/header';
import { useColorContext} from '../components/ColorContext';
import NavSideBar from '../components/NavSideBar';

function CalendarPage({navigation}) {
  const {colors} = useColorContext();
  const [currentMonth, setCurrentMonth] = useState(getMonth());
  const monthIndex = dayjs().month();
  const showEventModal = false;
  const [events, setEvents] = useState([]);
  const [selected, setSelected] = useState('');

  const calendarColors = {
    defaultButton: 'black',
    blue: '#4287f5',
    black: '#000000',
    dark_gray: '#404040',
    white: '#FFFFFF',
    delete: 'tomato',
    gold: '#ffc947',
    dark_gold: '#efa900',
    gray: '#f5f5f5',
    purple: '#625364',
    light_gray: '#D3D3D3',
  };

  const {width} = useWindowDimensions();
  const isFocused = useIsFocused();
  const [modalVisible, setModalVisible] = useState(false);
  const [isCurrentEvent, setisCurrentEvent] = useState('');

  const [markedDatesObj, setMarkedDatesObj] = useState({});
  const [agendaObj, setAgendaObj] = useState({});
  const [showAgenda, setShowAgenda] = useState(false);
  const [agendaDay, setAgendaDay] = useState();
  const [agenda, setAgenda] = useState([]);
  const [isCoCommander, setCoCommander] = useState(false);
  const [isBnCommander, setBnCommander] = useState(false);


  useEffect(() => {
    if (isFocused);
    setModalVisible(false);
  }, [isFocused]);

	useEffect(() => {
		const userRole = global.user_data.permissions;

		if (userRole.includes(2052))
			setBnCommander(true);
		else if (userRole.includes(2051))
			setCoCommander(true);

	}, []);

  useEffect(() => {
    if (showAgenda) dayAgenda();
  }, [agendaDay, agendaObj, markedDatesObj]);

  useEffect(() => {
    setCurrentMonth(getMonth(monthIndex));
  }, [monthIndex]);

  const scale = useScale();
  const styles = ({
    wrapper: {
      flexDirection: 'row',
      height: '100%'
    },
    container: {
      flex: 1,
      backgroundColor:  colors.screen_background ,
      overflow:'hidden'    
    },
    calendarContainer: {

    },
    heading: {
      fontSize: scale(50),
      fontFamily: 'Trebuchet MS',
      marginTop: '0.5%',
      color:  colors.text ,
    },
    sideText: {
      fontSize: scale(30),
      // fontFamily : 'Arial-BoldMT',
      marginTop: '0.5%',
      marginLeft: '2%',
      color:  colors.text ,
    },
    centeredView: {
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
    },
    list: {
      width: '75%',
    },
    title: {
      fontSize: scale(50),
    },
    button: {
      borderRadius: 10,
      padding: 10,
      paddingLeft: 20,
      paddingRight: 20,
      elevation: 2,
      backgroundColor: '#ffc947',
      margin: 8,
      marginBottom: 15,
      borderColor: 'black',
      borderWidth: 1,
    },
    addEventBtn: {
      borderRadius: 10,
      padding: 10,
      paddingLeft: 20,
      paddingRight: 20,
      elevation: 2,
      backgroundColor: 'black',
      margin: 8,
      marginBottom: 15,
      borderColor: 'black',
      borderWidth: 1,
    },
    header: {
      height: '15%',
    },
    calendarHeader: {
      backgroundColor: '#282c34',
      flex: 1,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '12px',
      color: 'white',
    },
    main: {
      marginTop: '0%',
      height: '85%',
      alignItems: 'center',
      textAlign: 'center',
    },
    calendarView: {
      marginTop: '0%',
      height: 'fit-content',
      width: '60%',
      textAlign: 'center',
      paddingLeft: '5%',
      paddingTop: '1%',
    },
    item: {
      backgroundColor: 'orange',
      padding: 20,
      marginVertical: 8,
      marginHorizontal: 16,
      borderRadius: 8,
      shadowColor: '#000',
      shadowOffset: {width: 3, height: 3},
      shadowOpacity: 0.3,
      shadowRadius: 8,
    },
    calendar: {
      //width:'100%',
      height:'100%',
      flex: 10,
      backgroundColor:  colors.screen_background ,    
      overflowY:'auto',
    },
    modalText: {
      textAlign: 'center',
      margin: 0,
      textAlignVertical: 'center',
      justifyContent: 'center',
      alignItems: 'center',
    },
    confirmedText: {
      textAlign: 'center',
      margin: 0,
      color: 'green',
      textAlignVertical: 'center',
      justifyContent: 'center',
      alignItems: 'center',
    },
    helpBtn: {
      width: '40%',
      backgroundColor: '#0d1232',
      borderRadius: '40',
      alignItems: 'center',
      color: 'white',
      borderRadius: 15,
      shadowColor: '#171717',
      shadowOffset: {width: -2, height: 4},
      shadowOpacity: 0.2,
      shadowRadius: 3,
      marginTop: 30,
      marginBottom: 30,
    },
    innerText: {
      color:  colors.button_text ,
      fontWeight: 'bold',
      padding: 10,
    },
  });

  const dayAgenda = () => {
    // shows all events for a day when clicked
    setAgenda([]);
    const today = agendaDay.dateString;
    const arr = [];

    if (agendaObj[today] != undefined) {
      for (let i = 0; i < agendaObj[today].size; i++) {
        const data = {
          id: agendaObj[today].agenda[i].id,
          title: agendaObj[today].agenda[i].title,
          time: agendaObj[today].agenda[i].time,
          type: agendaObj[today].agenda[i].type,
          description: agendaObj[today].agenda[i].description,
          author: agendaObj[today].agenda[i].author,
          status: agendaObj[today].agenda[i].status,
          inviteeNames: agendaObj[today].agenda[i].inviteeNames,
          confirmedNames: agendaObj[today].agenda[i].confirmedNames,
          remarks: agendaObj[today].agenda[i].remarks,
          remarks2: agendaObj[today].agenda[i].remarks2,
          training_events: agendaObj[today].agenda[i].training_events,
          canAccept: agendaObj[today].agenda[i].canAccept,
        };
        arr.push(data);
      }
    }
    setAgenda(arr);
  };


  return (
    <View style={styles.container}>
      <View style={styles.wrapper}>
		  {/* <NavSideBar /> */}
      <View style={styles.calendar}>
        <Calendar navigation={navigation} />
      </View>
      </View>
    </View>
	  );
};

export default CalendarPage;
