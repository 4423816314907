import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import dayjs from 'dayjs';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useColorContext } from '../ColorContext';
import CreateEventButton from './CreateEventButton';

export default function CalendarHeader({ state, setState }) {
  const { colors } = useColorContext();

  function handlePrevMonth() {
    setState({ ...state, currentMonthIndex: state.currentMonthIndex - 1 });
  }

  function handleNextMonth() {
    setState({ ...state, currentMonthIndex: state.currentMonthIndex + 1 });
  }

  function handleReset() {
    const monthChosen =
      state.currentMonthIndex === dayjs().month()
        ? state.currentMonthIndex + Math.random()
        : dayjs().month();
    setState({
      ...state,
      todayClicked: true,
      selectedDay: undefined,
      currentMonthIndex: monthChosen,
    });
  }

  const styles = {
    container: {
      flexDirection: 'row',
      alignItems: 'center',
      padding: 10,
      justifyContent: "space-between"
    },
    titleContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      flex: 0.5
    },
    titleText: {
      fontSize: 33,
      color: colors.screen_title,
      fontWeight: 'bold',
      flex: 1, // Equal flex to center-align the month
      textAlign: 'center', // Center text horizontally
    },
    text: {
      fontSize: 31,
      color: colors.text,
      fontWeight: 'bold',
      justifyContent: 'center',
      alignItems: 'center',
    },
    buttons: {
      fontSize: 60,
      color: colors.text,
      fontWeight: 'bold',
    },
    todayButton: {
      fontSize: 24,
      fontFamily: 'Calibri',
      fontWeight: 'bold',
      backgroundColor: colors.navSideBar_Background,
      borderRadius: 5,
      padding: 10,
      // flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      width: '10%',
      color: colors.text,
    },
    todayText: {
      fontSize: 27,
      color: colors.text,
      fontWeight: 'bold',
      padding: '2%',
    },
    prevChevron: {
      fontSize: 60,
      color: colors.text,
      fontWeight: 'bold',
      marginLeft: "5%",
    },
  };

  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={handleReset} style={styles.todayButton}>
        <Text style={styles.todayText}>Today</Text>
      </TouchableOpacity>
      <View style={styles.titleContainer} >
        <TouchableOpacity onPress={handlePrevMonth} style={styles.prevChevron}>
          <ChevronLeftIcon style={styles.prevChevron} />
        </TouchableOpacity>
        <Text style={styles.titleText}>
          {dayjs(new Date(dayjs().year(), state.currentMonthIndex)).format('MMMM YYYY')}
        </Text>
        <TouchableOpacity onPress={handleNextMonth} >
          <ChevronRightIcon style={styles.buttons} />
        </TouchableOpacity>
      </View>
      <CreateEventButton state={state} setState={setState} />
    </View>
  );
}
