/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import { PieChart, Pie, Cell, Legend, Tooltip, ResponsiveContainer } from 'recharts';
import colors from '../../src/colors';
import { Dimensions } from 'react-native';

class LegendItem extends Component {
  shouldComponentUpdate(nextProps) {
    return !Object.is(nextProps, this.props);
  }

  renderTooltip = ({ payload }) => {
    if (payload && payload.length) {
      const { name, value } = payload[0].payload;
      return (
        <div style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc' }}>
          {name}
        </div>
      );
    }
    return null;
  };

  render() {
    const { pieSelection, pieColorSelection } = this.props;
    const { width, height } = Dimensions.get('window');

    const defaultData = [{ name: 'No Data', value: 1 }];
    let dataToRender = pieSelection;
    let isEmpty = false;
    if(pieSelection[0].value === 0 & pieSelection[1].value === 0){
      dataToRender = defaultData;
      isEmpty = true;
    }

    const renderNonColoredLegendText = (value, entry) => {
      if (entry.payload.name.trim() == 'No Data') {
        return (
          <span
            style={{
              color: global.user_data.theme === 'light' ? "#000000" : "#B3BBC9",
              fontSize: '24px',
            }}
          >
            {entry.payload.name}
          </span>
        );
      } else {
        return (
          <span style={{ color: colors.text, fontSize: '24px'}}>
            {entry.payload.name}: {entry.payload.value}
          </span>
        );
      }
    };

    const styles = {
      containerStyle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
      },
      legendWrapperStyle: {
        display: 'flex',
        justifyContent: 'center',
      },
    };

    return (
      <ResponsiveContainer width="100%" height={250} >
        <PieChart style={width > 1550 ? {marginLeft: -100, marginTop: -10} : {marginTop: -10}}>
          <Tooltip content={this.renderTooltip} />
          <Pie
            data={dataToRender}
            outerRadius={'80%'}
            dataKey="value"
            nameKey="name"
            innerRadius={'35%'}
            startAngle={0}
            endAngle={360}
          >
            {dataToRender.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={isEmpty ? (
                  global.user_data.theme === 'light'
                    ? colors.leader_dash_legend_container_light
                    : colors.leader_dash_legend_container_dark
                ) : pieColorSelection[index]}
                stroke="#000"
                strokeWidth={1}
              />
            ))}
          </Pie>
          <Legend
            layout={width > 1550 ? 'vertical' : 'horizontal'}
            verticalAlign={width > 1550 ? 'middle' : 'bottom'}
            align="center"
            wrapperStyle={width > 1550 ? {marginLeft: "35%"} : styles.legendWrapperStyle}
            style={{ fontFamily: 'Trebuchet MS', fontSize: "40px"}}
            formatter={(value, entry) => renderNonColoredLegendText(value, entry)}
          />
        </PieChart>
      </ResponsiveContainer>
    );
  }
}

export default LegendItem;