import {jiraAPI} from './api_settings';
import axios from 'axios';

export const create_bug_report = async(data) => {
    const url_endpoint = jiraAPI + 'create_jira_bug_report'; 

    const header = {
        'Content-Type': 'application/json'
    }
    
    const res = await axios
    .post(url_endpoint, data, { headers: header })
    .then((response) => response.data)
    .catch((error) => console.error(error.message));

  return res;
};