import React, { useEffect, useState } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';

import { useColorContext } from '../../../components/ColorContext';

import { edit_unit_info_in_forms, get_forms_related_info_of_a_unit } from '../../../api_handler/units';

import { PDFDocument } from 'pdf-lib';
import * as form_rst from '../../../PDFs/RST_request_form_raw';
import * as form_1380 from '../../../PDFs/1380_form_raw';

import TextField from '@mui/material/TextField';
import { useScale } from '../../../components/scale';
import NavSideBar from '../../../components/NavSideBar';
import { useSnackbar } from '../../../components/SnackbarContext';

function EditForms({ navigation }) {
  const [formType, setFormType] = useState('RST');
  const scale = useScale();

  // RST inputs
  const [header1, setHeader1] = useState('');
  const [header2, setHeader2] = useState('');
  const [header3, setHeader3] = useState('');
  const [header4, setHeader4] = useState('');
  const [memoradum, setMemoradum] = useState('');
  const [fieldMemo, setFieldMemo] = useState('');
  const [commanderName1, setCommanderName1] = useState('');
  const [commanderName2, setCommanderName2] = useState('');
  const [commanderName3, setCommanderName3] = useState('');


  // 1380 inputs
  const [to, setTo] = useState('');
  const [from, setFrom] = useState('');
  const { colors } = useColorContext();
  const { showSnackbar } = useSnackbar();

  // Snackbar alerts
  const [openAlert, setOpenAlert] = useState(false);

  // When user opens page, get header, memoradum and Commander Name from database for selected unit
  useEffect(() => {
    async function fetchUnitFormData() {
      let ret = await get_forms_related_info_of_a_unit({ unit_id: global.user_data.unit_id }, global.token);
      if (ret.status === 200) {
        ret = ret.data
        // Set rst data
        const headerArray = ret.body.unit_rst_header.split('\n');
        const commandingOfficerNameArray = ret.body.unit_rst_commanding_officer.split('\n');

        if (headerArray.length == 4) {
          setHeader1(headerArray[0]);
          setHeader2(headerArray[1]);
          setHeader3(headerArray[2]);
          setHeader4(headerArray[3]);
        }

        if (commandingOfficerNameArray.length == 3) {
          setCommanderName1(commandingOfficerNameArray[0]);
          setCommanderName2(commandingOfficerNameArray[1]);
          setCommanderName3(commandingOfficerNameArray[2]);
        }

        setMemoradum(ret.body.unit_rst_memorandum);
        setFieldMemo(ret.body.unit_rst_field_memo);

        // Set 1380 data
        setTo(ret.body.unit_1380_to);
        setFrom(ret.body.unit_1380_from);
      } else {
        showSnackbar("error", `Application Error: Failed to fetch Form Information for ${global.user_data.unit_name}`)
      }
      
    }
    fetchUnitFormData();
  }, []);

  const styles = {
    container: {
      backgroundColor: colors.screen_background,
      flexDirection: 'row',
      height: '100%',
    },
    header: {
      height: 125,
    },
    innerContainer: {
      flexDirection: 'column',
      height: '100%',
      flex: 10
    },
    main: {
      height: '100%',
      flexDirection: 'column',
      flex: 10
    },
    titleAndDescription: {
      title: {
        fontSize: scale(30),
        fontFamily: 'Trebuchet MS',
        flex: 1,
        color: colors.text,
        paddingLeft: '5%',
        paddingTop: 25
      },
      description: {
        fontFamily: 'Trebuchet MS',
        fontSize: scale(18),
        color: colors.text,
        paddingLeft: '5%',
        paddingRight: '5%',
        width: '100%',
      }
    },
    titleText: {
      fontSize: 40,
      fontFamily: 'Trebuchet MS',
      height: '100%',
      color: colors.text,
    },
    tabView: {
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: colors.screen_background,
      marginTop: '5%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    inactiveTab: {
      width: '45%',
      alignItems: 'center',
      height: 45,
      borderBottomWidth: 2,
      borderColor: colors.bow,
      borderStyle: 'solid',
    },
    activeTab: {
      width: '45%',
      alignItems: 'center',
      height: 45,
      borderTopWidth: 2,
      borderLeftWidth: 2,
      borderRightWidth: 2,
      borderColor: colors.bow,
      borderStyle: 'solid',
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
    tabText: {
      color: colors.bow,
      fontSize: 28,
    },
    textboxContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      width: '100%',
      flexWrap: 'wrap',
      // justifyContent: 'center',
      alignItems: 'center',
      marginTop: 25,
    },
    textbox: {
      margin: 12,
      width: '25%',
      // paddingBottom: '1%',
      backgroundColor: colors.textfield_background,
    },
    textInputProps: {
      '& .MuiFormLabel-root, & label.Mui-focused, & .MuiInputBase-input': {
        color: colors.inputted_text,
        // WebkitTextFillColor:  colors.inputted_text : colors.dark_mode_inputted_text,
      },
      '.MuiSvgIcon-root': {
        fill: colors.inputted_text,
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: colors.border_color,
        },
        '&:hover fieldset': {
          borderColor: colors.border_color,
        },
        '&.Mui-focused fieldset': {
          borderColor: colors.border_color,
        },
      },
      '&  .MuiFormHelperText-root': {
        backgroundColor: colors.screen_background,
        margin: 0,
        paddingLeft: 1,
      },
      // .MuiFormHelperText-root.Mui-error
    },
    innerText: {
      color: colors.button_text,
      fontWeight: 'bold',
      fontSize: 18,
    },
    button: {
      backgroundColor: colors.textfield_background,
      width: 250,
      height: 40,
      alignItems: 'center',
      borderRadius: 20,
      borderColor: colors.border_color,
      borderWidth: 1,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      justifyContent: 'center',
    },
    buttonView: {
      justifyContent: 'space-evenly',
      marginTop: 50,
      flexDirection: 'row',
      height: 70,
      width: '100%',
      alignItems: 'center',
    },
  };

  const generatePDF = () => {
    if (formType === 'RST') {
      const header = header1 + '\n' + header2 + '\n' + header3 + '\n' + header4;
      const commander = commanderName1 + '\n' + commanderName2 + '\n' + commanderName3;
      const data = {
        header: header,
        memorandum: memoradum,
        commander: commander,
        fieldMemo: fieldMemo,
      };
      generate_rst(data);
    } else if (formType === '1380') {
      const data = {
        to: to,
        from: from,
      };
      generate_1380(data);
    }
  };

  const saveData = async () => {
    let data = {};

    if (formType === 'RST') {
      data = {
        form_type: 'RST',
        unit_id: global.user_data.unit_id,
        header: header1 + '\n' + header2 + '\n' + header3 + '\n' + header4,
        memorandum: memoradum,
        commanding_officer: commanderName1 + '\n' + commanderName2 + '\n' + commanderName3,
        field_memo: fieldMemo,
      };
    } else if (formType === '1380') {
      data = {
        form_type: '1380',
        unit_id: global.user_data.unit_id,
        unit_1380_to: to,
        unit_1380_from: from,
      };
    }

    const ret = await edit_unit_info_in_forms(data, global.token);

    if (ret.status == 200) {
      showSnackbar("success", `Successfully Updated '${formType}'`)
    } else {
      showSnackbar("error", `Application Error: Failed to update '${formType}'`)
    }
  };

  return (
    <View style={styles.container}>
      {/* <NavSideBar /> */}
      <View style={styles.innerContainer}>
        <View style={styles.main}>
          <View style={{ width: '100%' }}>
            <Text style={styles.titleAndDescription.title}>
              <Text style={{ fontWeight: 'bold' }}>Admin Console {'>'} </Text>
              <Text>Manage Forms for {global.user_data ? global.user_data.unit_name : ''}</Text>
            </Text>
            <View style={{ flexDirection: 'row', marginTop: '1%', alignItems: 'flex-start' }}>
              <Text style={styles.titleAndDescription.description}>
                This page allows admins to manage the various configurable fields on
                form available for soldiers to fill out. Once the managed fields are
                saved, they will be used any time a PDF is generated for viewing. Sample
                PDFs are available for each form demonstrating what the form may look like.
              </Text>
            </View>
          </View>

          <View style={styles.tabView}>
            <TouchableOpacity style={formType === 'RST' ? styles.activeTab : styles.inactiveTab} onPress={() => {
              setFormType('RST');
            }}>
              <Text style={styles.tabText}>RST</Text>
            </TouchableOpacity>
            <TouchableOpacity style={formType === '1380' ? styles.activeTab : styles.inactiveTab} onPress={() => {
              setFormType('1380');
            }}>
              <Text style={styles.tabText}>1380</Text>
            </TouchableOpacity>
          </View>
          {formType === 'RST' ? (
            <>
              <View style={styles.textboxContainer}>
                <TextField
                  style={styles.textbox}
                  sx={styles.textInputProps}
                  label="Header Line 1"
                  onChange={(data) => setHeader1(data.target.value)}
                  onBlur={() => setHeader1(header1.trim())}
                  value={header1}
                />
                <TextField
                  style={styles.textbox}
                  sx={styles.textInputProps}
                  label="Header Line 2"
                  onChange={(data) => setHeader2(data.target.value)}
                  onBlur={() => setHeader2(header2.trim())}
                  value={header2}
                />
                <TextField
                  style={styles.textbox}
                  sx={styles.textInputProps}
                  label="Header Line 3"
                  onChange={(data) => setHeader3(data.target.value)}
                  onBlur={() => setHeader3(header3.trim())}
                  value={header3}
                />
                <TextField
                  style={styles.textbox}
                  sx={styles.textInputProps}
                  label="Header Line 4"
                  onChange={(data) => setHeader4(data.target.value)}
                  onBlur={() => setHeader4(header4.trim())}
                  value={header4}
                />
                <TextField
                  style={styles.textbox}
                  sx={styles.textInputProps}
                  label="Field Memo Office Symbol"
                  onChange={(data) => setFieldMemo(data.target.value)}
                  onBlur={() => setFieldMemo(fieldMemo.trim())}
                  value={fieldMemo}
                />
                <TextField
                  style={styles.textbox}
                  sx={styles.textInputProps}
                  label="Memorandum For"
                  onChange={(data) => setMemoradum(data.target.value)}
                  onBlur={() => setMemoradum(memoradum.trim())}
                  value={memoradum}
                />
                <TextField
                  style={styles.textbox}
                  sx={styles.textInputProps}
                  label="Commanding Officer Line 1"
                  onChange={(data) => setCommanderName1(data.target.value)}
                  onBlur={() => setCommanderName1(commanderName1.trim())}
                  value={commanderName1}
                />
                <TextField
                  style={styles.textbox}
                  sx={styles.textInputProps}
                  label="Commanding Officer Line 2"
                  onChange={(data) => setCommanderName2(data.target.value)}
                  onBlur={() => setCommanderName2(commanderName2.trim())}
                  value={commanderName2}
                />
                <TextField
                  style={styles.textbox}
                  sx={styles.textInputProps}
                  label="Commanding Officer Line 3"
                  onChange={(data) => setCommanderName3(data.target.value)}
                  onBlur={() => setCommanderName3(commanderName3.trim())}
                  value={commanderName3}
                />
              </View>
              <View style={styles.buttonView}>
                <TouchableOpacity
                  style={styles.button}
                  onPress={() => generatePDF()}>
                  <Text style={styles.innerText}>View PDF</Text>
                </TouchableOpacity>

                <TouchableOpacity
                  style={styles.button}
                  onPress={() => {
                    saveData();
                  }}>
                  <Text style={styles.innerText}>Save</Text>
                </TouchableOpacity>
              </View>
            </>
          ) : (
            <>
              <View style={styles.textboxContainer}>
                <TextField
                  style={styles.textbox}
                  sx={styles.textInputProps}
                  label="To"
                  onChange={(data) => setTo(data.target.value)}
                  onBlur={() => setTo(to.trim())}
                  value={to}
                />
                <TextField
                  style={styles.textbox}
                  sx={styles.textInputProps}
                  label="From"
                  onChange={(data) => setFrom(data.target.value)}
                  onBlur={() => setFrom(from.trim())}
                  value={from}
                />
              </View>
              <View style={styles.buttonView}>
                <TouchableOpacity
                  style={styles.button}
                  onPress={() => generatePDF()}>
                  <Text style={styles.innerText}>View PDF</Text>
                </TouchableOpacity>

                <TouchableOpacity
                  style={styles.button}
                  onPress={() => {
                    saveData();
                  }}>
                  <Text style={styles.innerText}>Save</Text>
                </TouchableOpacity>
              </View>
            </>
          )}
        </View>
      </View>
    </View >
  );
};

export async function pdfIn(base64) {
  const pdfWindow = window.open('');
  pdfWindow.document.write('<iframe width=\'100%\' height=\'100%\' src=\'data:application/pdf;base64,' + base64 + '\'></iframe>');
};

const generate_rst = async (data) => {
  const pdfDoc = await PDFDocument.load(form_rst.pdf());
  const form = pdfDoc.getForm();

  form.getTextField('HEADER').setText(data.header);
  form.getTextField('FIELD MEMO OFFICE SYMBOL').setText(data.fieldMemo);
  form.getTextField('MEMORANDUM FOR').setText(data.memorandum);
  form.getTextField('COMMANDER').setText(data.commander);

  // form.flatten();
  const pdfBytes = await pdfDoc.saveAsBase64();
  pdfIn(pdfBytes);
};

const generate_1380 = async (data) => {
  const pdfDoc = await PDFDocument.load(form_1380.pdf());

  const form = pdfDoc.getForm();
  form.getField('2 FROM').setText(data.from);
  form.getField('4 TO').setText(data.to);

  // form.flatten();
  const pdfBytes = await pdfDoc.saveAsBase64();
  pdfIn(pdfBytes);
};

export default EditForms;
