/* eslint-disable linebreak-style */
/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, {useRef} from 'react';
import {View, Text, StyleSheet} from 'react-native';
import {HelperText, TextInput} from 'react-native-paper';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {createTheme, ThemeProvider, useTheme} from '@mui/material/styles';
import {ClickAwayListener, FormControlLabel, Radio} from '@mui/material';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {useColorContext} from '../../components/ColorContext';
import CustomRadioWithBackground from '../../components/CustomRadioWithBackground';
import {useFocusEffect} from '@react-navigation/native';
import moment from 'moment';

const AFTPComponent = ({
  navigation,
  eventDate,
  setEventDate,
  checkbox4H,
  handleCheckbox4H,
  checkbox8H,
  handleCheckbox8H,
  // pointsOnly,
  // togglePointsOnly,
  currentAFTPNumber,
  currentSupportNumber,
  bankTime,
  AFTPMUTAsError,
  eventDateEmptyError,
  datePicker2Open,
  datePicker3Open,
  eventDateEmptyErrorText,
  setEventDateEmptyError,
  eventDateEmptyError3,
  setEventDateEmptyError3,
  setDatePicker2Open,
  setDatePicker3Open,
  eventDate3,
  setEventDate3,
  hours,
  setDatePerformedAFTP,
  setDatePerformedAFTP2,
  locked,
  onSetHours,
  setHoursEmptyError,
  hoursEmptyError,
  setPoints,
  pointsEmptyError,
  setPointsEmptyError,
  points,
}) => {
  const {colors} = useColorContext();
  const styles = StyleSheet.create({
    container: {
      padding: 20,
    },
    label: {
      fontSize: 16,
      marginBottom: 5,
    },
    input: {
      height: 40,
      borderColor: 'gray',
      borderWidth: 1,
      marginBottom: 15,
      padding: 10,
    },
    cancelButton: {
      color: colors.text,
      width: '10vw',
      borderRadius: 10,
      padding: 10,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: colors.modal_background,
      margin: 8,
      marginBottom: 15,
      borderColor: colors.border_color,
      borderWidth: 1,
      shadowOffset: {width: 0, height: 2},
      shadowOpacity: 0.25,
      shadowRadius: 4,
      alignItems: 'center',
    },
    checkboxContainer: {
      flexDirection: 'row',
      fontSize: 18,
      alignItems: 'center',
      flex: 1,
    },
    textStyle: {
      justifyContent: 'center',
      fontSize: 15,
      fontWeight: 'bold',
      color: colors.text,
      marginTop: '2%',
    },
    commentInputContainer: {
      marginTop: '1.5%',
      width: '100%',
    },
    inputSplit: {
      backgroundColor: colors.textfield_background,
      borderColor: colors.border_color,
    },
    locationCommentInput: {
      borderColor: colors.border_color,
      borderWidth: 1,
      padding: 3,
      borderRadius: 1,
      fontSize: 16,
      backgroundColor: colors.textfield_background,
      height: 56,
    },
    contentContainer: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flex: 1,
    },
    hoursPointsInput: {
      width: '100%',
      marginTop: '1%',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-around',
      flex: 1,
      minHeight: 78,
    },
    inputSplitLocked: {
      backgroundColor: colors.screen_background,
      flex: 1,
    },
  });

  const theme = useTheme();
  const datePicker2Ref = useRef(null);
  const datePicker3Ref = useRef(null);
  const payTheme = createTheme({
    palette: {
      mode: colors.mode,
      primary: theme.palette.primary,
    },
  });

  useFocusEffect(
      React.useCallback(() => {
      // This effect is used to prevent navigation instantly if date picker is open
        const beforeRemoveListener = (e) => {
          if (!datePicker2Open && !datePicker3Open) {
            return;
          }

          e.preventDefault();

          setDatePicker2Open(false);
          setDatePicker3Open(false);
          setTimeout(() => {
            navigation.dispatch(e.data.action);
          }, 200);
        };

        navigation.addListener('beforeRemove', beforeRemoveListener);

        return () => {
          navigation.removeListener('beforeRemove', beforeRemoveListener);
        };
      }, [datePicker2Open, datePicker3Open]),
  );

  return (
    <View style={{alignItems: 'center', width: '100%'}}>
      <View style={{flexDirection: 'row', justifyContent: 'center'}}>
        {/* Date Picker for Period #1 */}
        <View style={{alignItems: 'center', marginRight: '3%'}}>
          <View style={{marginBottom: '2%'}}>
            <Text style={styles.textStyle}>Period #1</Text>
          </View>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ThemeProvider theme={payTheme}>
              <ClickAwayListener
                onClickAway={(event) => {
                  if (datePicker2Ref.current && !datePicker2Ref.current.contains(event.target)) {
                    setDatePicker2Open(false);
                  }
                }}
                mouseEvent="onMouseDown"

              >
                <DatePicker
                  label="To be Performed On*"
                  onChange={(date) => {
                    setEventDate(date);
                    setDatePerformedAFTP(moment(new Date(date)).format('MM/DD/YYYY'));
                    setEventDateEmptyError(!date.isValid());
                  }}
                  value={eventDate}
                  onClose={() => setDatePicker2Open(false)}
                  onOpen={() => setDatePicker2Open(true)}
                  open={datePicker2Open}
                  error={eventDateEmptyError}
                  slotProps={{
                    textField: {
                      sx: {
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: colors.border_color,
                          },
                          '&:hover fieldset': {
                            borderColor: colors.border_color,
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: colors.border_color,
                          },
                        },
                      },
                      onFocus: () => {
                        setEventDateEmptyError(false);
                      },
                      onBlur: () => {
                        setEventDateEmptyError(!eventDate || !eventDate.isValid());
                      },
                      error: eventDateEmptyError,
                    },
                  }}
                  PopperProps={{
                    modifiers: [
                      {
                        name: 'eventListeners',
                        options: {
                          scroll: false,
                          resize: true,
                        },
                      },
                    ],
                    ref: (popperRef) => {
                      if (popperRef && popperRef.node) {
                        popperRef.node.id = 'calendar-element';
                        datePicker2Ref.current = popperRef.node.querySelector('.MuiCalendarPicker-root');
                      }
                    },
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </ClickAwayListener>
            </ThemeProvider>
          </LocalizationProvider>
          <HelperText style={{color: 'red'}}>{eventDateEmptyError ? 'Enter performed on date(s)' : ' '}</HelperText>
        </View>
        {/* Date Picker for Period #2, if MUTA 2 */}
        {hours === '8' ? (
          <View style={{alignItems: 'center'}}>
            <View style={{marginBottom: '2%'}}>
              <Text style={styles.textStyle}>Period #2</Text>
            </View>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <ThemeProvider theme={payTheme}>
                <ClickAwayListener
                  onClickAway={(event) => {
                    if (datePicker3Ref.current && !datePicker3Ref.current.contains(event.target)) {
                      setDatePicker3Open(false);
                    }
                  }}
                  mouseEvent="onMouseDown"

                >
                  <DatePicker
                    label="To be Performed On*"
                    ampm={false}
                    onChange={(date) => {
                      setEventDate3(date);
                      setDatePerformedAFTP2(moment(new Date(date)).format('MM/DD/YYYY'));
                      setEventDateEmptyError3(!date.isValid());
                    }}
                    onClose={() => setDatePicker3Open(false)}
                    onOpen={() => setDatePicker3Open(true)}
                    open={datePicker3Open}
                    value={eventDate3}
                    error={eventDateEmptyError3}
                    slotProps={{
                      textField: {
                        sx: {
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: colors.border_color,
                            },
                            '&:hover fieldset': {
                              borderColor: colors.border_color,
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: colors.border_color,
                            },
                          },
                        },
                        onFocus: () => {
                          setEventDateEmptyError3(false);
                        },
                        onBlur: () => {
                          setEventDateEmptyError3(!eventDate3 || !eventDate3.isValid());
                        },
                        error: eventDateEmptyError3,
                      },
                    }}
                    PopperProps={{
                      modifiers: [
                        {
                          name: 'eventListeners',
                          options: {
                            scroll: false,
                            resize: true,
                          },
                        },
                      ],
                      ref: (popperRef) => {
                        if (popperRef && popperRef.node) {
                          popperRef.node.id = 'calendar-element';
                          datePicker3Ref.current = popperRef.node.querySelector('.MuiCalendarPicker-root');
                        }
                      },
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </ClickAwayListener>
              </ThemeProvider>
            </LocalizationProvider>
            <HelperText style={{color: 'red'}}>{eventDateEmptyError3 ? 'Enter performed on date(s)' : ' '}</HelperText>
          </View>
      ) : null}
      </View>
      <HelperText style={{color: 'red'}}>{eventDateEmptyErrorText}</HelperText>

      {/* Selecting MUTAs */}
      <View style={[styles.checkboxContainer, {flexDirection: 'column', justifyContent: 'center'}]}>
        <View style={{flexDirection: 'row', alignItems: 'center', height: '65%'}}>
          <Text style={[styles.textStyle, {marginRight: '10%'}]}>MUTAs*:</Text>
          <CustomRadioWithBackground
            id="MUTA 1"
            checked={checkbox4H == true}
            onChange={handleCheckbox4H}
            label="Single"
          />
          <CustomRadioWithBackground
            id="MUTA 2"
            checked={checkbox8H == true}
            onChange={handleCheckbox8H}
            label="Dual"
          />
        </View>
        <HelperText style={{color: 'red', paddingHorizontal: 12, paddingVertical: 0, marginRight: '3%'}}>{AFTPMUTAsError ? 'Select number of MUTAs' : ' '}</HelperText>
      </View>

      {/* <View style={[styles.checkboxContainer, {flexDirection: 'column'}]}>
        <View style={{flexDirection: 'row', alignItems: 'center', height: '100%'}}>
          <Text style={styles.textStyle}>Points Only</Text>
          <Checkbox
            status={pointsOnly ? 'checked' : 'unchecked'}
            color={colors.gold}
            uncheckedColor={colors.inputted_text}
            theme={{colors: {text: colors.text}}}
            onPress={togglePointsOnly}
          />
        </View>
      </View> */}
      <View
        style={[styles.hoursPointsInput]}
      >
        <TextInput
          id="Hours Input"
          keyboardType={'numeric'}
          theme={{
            colors: {
              text: locked ? colors.disabled_text : colors.inputted_text,
              accent: colors.textfield_background,
              primary: locked ? colors.disabled_text : colors.inputted_text,
              placeholder: locked ? colors.disabled_text : colors.placeholder_text,
              disabled: locked ? colors.disabled_text : colors.border_color,
            },
          }}
          style={[styles.inputSplit]}
          onChangeText={onSetHours}
          value={hours}
          label="Number of hour(s)*"
          mode="outlined"
          disabled={locked}
          onBlur={() => {
            setHoursEmptyError(hours === '');
          }}
          error={hoursEmptyError}
        />
        <TextInput
          id="Points Input"
          theme={{
            colors: {
              text: locked ? colors.disabled_text : colors.inputted_text,
              accent: colors.textfield_background,
              primary: locked ? colors.disabled_text : colors.inputted_text,
              placeholder: locked ? colors.disabled_text : colors.placeholder_text,
              disabled: locked ? colors.disabled_text : colors.border_color,
            },
          }}
          style={[styles.inputSplit]}
          onChangeText={setPoints}
          value={points}
          label="Retirement points*"
          mode="outlined"
          disabled={locked}
          onBlur={() => {
            setPointsEmptyError(points === '');
          }}
          error={pointsEmptyError}
        />
      </View>
      <View>
        <View style={{...styles.contentContainer, margin: 0, padding: 10}}>
          <Text style={[styles.textStyle, {fontSize: 17}]}>FYTD:</Text>
          <Text style={styles.textStyle}>
                              Current AFTP Number: {currentAFTPNumber}/72
          </Text>
          <Text style={styles.textStyle}>
                              Current Ground AFTP Number: {currentSupportNumber}
                              /12
          </Text>
          <Text style={styles.textStyle}>
                              Bank Time: {bankTime != null ? (bankTime.split('.')[0] + '.' + (bankTime.split('.')[1] || '').substr(0, 2).padEnd(2, '0')) : bankTime}
          </Text>
          <Text style={styles.textStyle}>
                              Note: Disregard Bank Time until Oct 1st
          </Text>
        </View>
      </View>
    </View>
  );
};

export default AFTPComponent;
