import React from 'react';
import ReactDOM from 'react-dom';
import SnackbarAlert from './SnackbarAlert';

const GlobalSnackbar = (props) => {
  return ReactDOM.createPortal(
    <SnackbarAlert {...props} />,
    document.body
  );
};

export default GlobalSnackbar;