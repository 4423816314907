/* eslint-disable require-jsdoc */
import React, { useEffect, useState, useRef } from "react";
import { View, Text, TouchableOpacity } from "react-native";
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import { ClickAwayListener } from '@mui/base';
import { useScale } from "../../../components/scale";

import { useIsFocused } from "@react-navigation/native";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import ModeContext from "../../../components/ModeContext";
import { useColorContext } from "../../../components/ColorContext";
import SnackbarAlert from "../../../components/SnackbarAlert";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";

import { add_ba_date } from "../../../api_handler/events";
import { useSnackbar } from "../../../components/SnackbarContext";

import { en, registerTranslation } from "react-native-paper-dates";
registerTranslation("en", en);

import NavSideBar from "../../../components/NavSideBar";

import dayjs from "dayjs";
var timezone = require('dayjs/plugin/timezone');
dayjs.extend(timezone);
import * as Progress from 'react-native-progress'

function Add_BA_Dates({ navigation }) {
  const scale = useScale();
  const { colors } = useColorContext();
  const { showSnackbar } = useSnackbar();
  const [loaded, setLoaded] = useState(true);

  // Value states
  const [eventType, setEventType] = React.useState("Battle Assembly");
  const [eventName, setEventName] = React.useState("Battle Assembly");
  const [isMandatory, setIsMandatory] = React.useState(true);
  const [isRSTAuthorized, setIsRSTAuthorized] = React.useState(true);
  const [MUTA, setMUTA] = React.useState("2");
  const [tempSavedMUTA, setTempSavedMUTA] = React.useState("2");
  const [MUTADisabled, setMUTADisabled] = React.useState(false);
  const [trainingEvents, setTrainingEvents] = React.useState("");
  const [location, setLocation] = React.useState("");
  const [Remarks1, setRemarks1] = React.useState("");
  const [Remarks2, setRemarks2] = React.useState("");
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const { mode } = React.useContext(ModeContext);
  const [userTimezone, setUserTimezone] = React.useState(dayjs.tz.guess());

  // Open alert set
  const [openAlert, setOpenAlert] = useState(false);

  // Error states
  const [eventNameEmptyError, setEventNameEmptyError] = useState(false);
  const [trainingEventsEmptyError, setTrainingEventsEmptyError] =
    useState(false);
  const [locationEmptyError, setLocationEmptyError] = useState(false);
  const [startDateEmptyError, setStartDateEmptyError] = useState(false);
  const [endDateEmptyError, setEndDateEmptyError] = useState(false);
  const [badDateError, setBadDateError] = useState(false);

  const [dateTimePicker1Open, setDateTimePicker1Open] = React.useState(false);
  const [dateTimePicker2Open, setDateTimePicker2Open] = React.useState(false);
  const dateTimePicker1Ref = useRef(null);
  const dateTimePicker2Ref = useRef(null);

  const [startDateError, setStartDateError] = useState('');
  const [endDateError, setEndDateError] = useState('');
  const [weekendError, setWeekendError] = useState("");

  // Define drop down states
  const eventTypeOptions = require("./json_files/eventTypeOptions.json");
  const isMandatoryOptions = require("./json_files/isMandatoryOptions.json");
  const isRSTAuthorizedOptions = require("./json_files/isRSTAuthorizedOptions.json");
  const MUTAOptions = require("./json_files/MUTAOptions.json");

  // Create focus
  const isFocused = useIsFocused();

  const styles = {
    container: {
      backgroundColor: colors.screen_background,
      flexDirection: "row",
      height: "100%",
    },
    title: {
      fontSize: scale(25),
      fontFamily: "Arial-BoldMT",
      marginTop: "0.5%",
      color: colors.text,
    },
    titleDiv: {
      marginTop: "0%",
      alignItems: "center",
    },
    main: {
      marginTop: '2%',
      flexDirection: "column",
      height: "85%",
      justifyContent: "center",
      flex: 10,
    },
    titleAndDescription: {
      title: {
        fontSize: scale(30),
        fontFamily: 'Trebuchet MS',
        flex: 1,
        color: colors.text,
        paddingLeft: '5%',
        paddingTop: 25
      },
      description: {
        flex: 1,
        fontFamily: 'Trebuchet MS',
        fontSize: scale(18),
        color: colors.text,
        paddingLeft: '5%',
        paddingRight: '5%',
      }
    },
    buttonDiv: {
      flexDirection: "row",
      height: "10%",
      paddingTop: "5%",
      paddingBottom: "10%",
      alignItems: "center",
      justifyContent: "space-between",
      width: "88%",
      alignSelf: "center",
    },
    button: {
      backgroundColor: colors.button,
      width: 200,
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 7,
      shadowColor: "#171717",
      shadowOffset: { width: -2, height: 4 },
      shadowOpacity: 0.2,
      shadowRadius: 3,
    },
    innerText: {
      color: colors.button_text,
      padding: 12,
      fontWeight: "bold",
    },
    textbox: {
      margin: 10,
      width: '25%',
      backgroundColor: colors.textfield_background,
      color: colors.inputted_text
    },
    dateBox: {
      margin: 1.5,
      width: "25%",
      backgroundColor: colors.textfield_background,
      "& .MuiFormLabel-root, & label.Mui-focused, & .MuiInputBase-input": {
        color: colors.inputted_text,
      },
      ".MuiSvgIcon-root ": {
        fill: colors.inputted_text,
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: colors.field_set,
        },
        "&:hover fieldset": {
          borderColor: colors.form_border,
        },
        "&.Mui-focused fieldset": {
          borderColor: "#1876d2",
        },
      },
      "& .MuiFormLabel-root.Mui-error": {
        color: colors.placeholder_text,
      },
      "& .MuiFormHelperText-root.Mui-error": {
        backgroundColor: colors.screen_background,
        color: "#d32f2f",
        margin: 0,
        paddingLeft: 1,
      },
    },
    dateBoxEnd: {
      width: "12vw",
      "& .MuiOutlinedInput-root": {
        "& fieldset, &:hover fieldset, &.Mui-focused fieldset": {
          borderColor: "transparent",
        },
        "& input": {
          textAlign: "right",
        },
      },
      "& .MuiInputLabel-shrink, & .MuiInputLabel-root.Mui-focused": {
        color: "transparent",
      },
      "& .MuiFormLabel-root": {
        textAlign: "right",
        right: "60px",
      },
    },
    textInputProps: {
      "& .MuiFormLabel-root, & label.Mui-focused, & .MuiInputBase-input": {
        color: colors.inputted_text,
      },
      ".MuiSvgIcon-root": {
        fill: colors.inputted_text,
      },
      "& .MuiOutlinedInput-root": {
        '& fieldset': {
          borderColor: colors.border_color, 
        },
        "&:hover fieldset": {
          borderColor: colors.form_border,
        },
        "&.Mui-focused fieldset": {
          borderColor: "#1876d2",
        },
      },
      "&  .MuiFormHelperText-root": {
        backgroundColor: colors.screen_background,
        margin: 0,
        paddingLeft: 1,
      },
      // .MuiFormHelperText-root.Mui-error
    },
    textInputPropsDisabled: {
      "& .MuiFormLabel-root.Mui-disabled, & label.Mui-focused.Mui-disabled, & .MuiInputBase-input.Mui-disabled":
        {
          color: colors.inputted_text,
          WebkitTextFillColor: colors.inputted_text,
        },
      "& .MuiFormHelperText-root.Mui-disabled": {
        backgroundColor: colors.screen_background,
        color: colors.placeholder_text,
        margin: 0,
        paddingLeft: 1,
      },
    },
    dateInputProps: {
      '& .MuiFormLabel-root, & label.Mui-focused, & .MuiInputBase-input': {
        color: colors.inputted_text,
      },
      '.MuiSvgIcon-root': {
        fill: colors.inputted_text,
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: colors.border_color, 
        },
        '&:hover fieldset': {
          borderColor: global.user_data.theme === 'dark' ? colors.form_border : '#1876d2',
        },
      },
      '&  .MuiFormHelperText-root': {
        backgroundColor: colors.screen_background,
        margin: 0,
        paddingLeft: 1,
      },
    },
    selectProps: {
      MenuProps: {
        sx: {
          "& .MuiMenu-paper": {
            background: colors.textfield_background,
            color: colors.inputted_text,
          },
        },
      },
    },
    sectionDiv: {
      marginTop: "1.5%",
      // marginBottom: "1%",
      alignItems: "flex-start",
      left: "6%",
      width: "88%",
      height: "40px",
      overflow: "hidden",
    },
    sectionContainer: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-evenly",
      width: "100%",
      paddingTop: 10
    },
    horizontalLine: {
      top: "30px",
      width: "100%",
      height: "2px",
      backgroundColor: "#d6dde4",
      position: "absolute",
    },
    sectionTitle: {
      fontSize: 20,
      fontWeight: "bold",
      color: colors.inputted_text,
    },
  };

  // Create theme styling for calendar
  const globalTheme = useTheme();
  const calendarTheme = createTheme({
    palette: {
      mode: mode,
      primary: globalTheme.palette.primary,
    },
  });

  useFocusEffect(
    React.useCallback(() => {
      // This effect is used to prevent navigation instantly if date picker is open
      const beforeRemoveListener = (e) => {
        if (!dateTimePicker1Open && !dateTimePicker2Open) {
          return;
        }

        e.preventDefault();

        setDateTimePicker1Open(false);
        setDateTimePicker2Open(false);
        // navigation.dispatch(e.data.action);
        setTimeout(() => {
          navigation.dispatch(e.data.action);
        }, 200);
      };

      navigation.addListener('beforeRemove', beforeRemoveListener);

      return () => {
        navigation.removeListener('beforeRemove', beforeRemoveListener);
      };
    }, [dateTimePicker1Open, dateTimePicker2Open])
);

  // Reset all values when user navigates to another page
  useEffect(() => {
    setEventType("Battle Assembly");
    setEventName("Battle Assembly");
    setIsMandatory(true);
    setIsRSTAuthorized(true);
    setMUTA("2");
    setTrainingEvents("");
    setLocation("");
    setRemarks1("");
    setRemarks2("");
    setStartDate(null);
    setEndDate(null);

    setEventNameEmptyError(false);
    setTrainingEventsEmptyError(false);
    setLocationEmptyError(false);
    setStartDateEmptyError(false);
    setBadDateError(false);
  }, [isFocused]);


  // Checks invalid or empty user input
  const checkInput = () => {
    let error = false;

    // Check empty training events
    if (eventType === "Other") {
      if (eventName === "") {
        error = true;
        setEventNameEmptyError(true);
      } else {
        setEventNameEmptyError(false);
      }
    } else {
      setEventNameEmptyError(false);
    }

    if (trainingEvents === "") {
      error = true;
      setTrainingEventsEmptyError(true);
    } else {
      setTrainingEventsEmptyError(false);
    }

    if (location === "") {
      error = true;
      setLocationEmptyError(true);
    } else {
      setLocationEmptyError(false);
    }

    if (startDate === null) {
      error = true;
      setStartDateEmptyError(true);
    } else {
      setStartDateEmptyError(false);
    }

    if (endDate === null) {
      error = true;
      setEndDateEmptyError(true);
    } else {
      setEndDateEmptyError(false);
    }

    // If endDate happens before startDate
    if (endDate < startDate) {
      error = true;
      setBadDateError(true);
    } else {
      setBadDateError(false);
    }

    return error;
  };

  // Handles event type to set eventName appropriately
  const handleEventType = (data) => {
    setEventType(data);

    if (data !== "Other") {
      setEventName(data);
    } else {
      setEventName("");
    }
  };

  // Handles RST dropdown data to set MUTA to 0 and disable interaction
  const handleRSTChange = (data) => {
    setIsRSTAuthorized(data);

    if (data === "false") {
      setTempSavedMUTA(MUTA);
      setMUTA("0");
      setMUTADisabled(true);
    } else {
      setMUTADisabled(false);
      setMUTA(tempSavedMUTA);
    }
  };

  // Function when Add Date button is clicked
  const AddBADate = async () => {
    // Check for input errors
    if (checkInput()) {
      showSnackbar("error", "Please correct one or more invalid inputs");
    } else {
      setLoaded(false);
      const newDate = {
        event: eventName,
        mandatory: isMandatory,
        start_date: startDate.tz(userTimezone).toISOString(),
        end_date: endDate.tz(userTimezone).toISOString(),
        location: location,
        MUTA: MUTA,
        training_events: trainingEvents,
        RST_authorized: isRSTAuthorized,
        remarks1: Remarks1,
        remarks2: Remarks2,
        user_id: global.user_data.user_id,
        user_full_name:
          global.user_data.first_name + " " + global.user_data.last_name,
        unit_id: global.user_data.unit_id,
      };


      const addBADateResponse = await add_ba_date(newDate, global.token);
      if (addBADateResponse.status !== 200 || addBADateResponse.data.body === undefined){
        if (addBADateResponse.data.body.includes("DUPLICATE EVENT")) {
          showSnackbar("error", "There is already an event on that weekend");
          setLoaded(true);
        } 
        else
        {
          showSnackbar("error", "Application Error: Failed to create the BA Date");
          navigation.navigate("units_dashboard", {
            screen: "view_BA_dates",
          });
        }
      }
      else if (addBADateResponse.status === 200) {
        showSnackbar("success", "Successfully added Battle Assembly date!");
        setEventName("Battle Assembly");
        setIsMandatory(true);
        setIsRSTAuthorized(true);
        setMUTA("2");
        setTrainingEvents("");
        setLocation("");
        setRemarks1("");
        setRemarks2("");
        setStartDate(null);
        setEndDate(null);
        navigation.navigate("units_dashboard", {
          screen: "view_BA_dates",
        });
        setLoaded(true);
      }
    }
  };

  const loadingStyles = {
    animation: {
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
        justifyContent: 'center',
        paddingVertical: 20,
        background: colors.screen_background,
    }
  }

  if (!loaded) {
    return (
      <View style={loadingStyles.animation}>
          <Progress.CircleSnail
            color={colors.loading_circle}
            indeterminate={true}
            size={275}
            thickness={8}
          />
      </View>
    );
  }

  return (
    <ThemeProvider theme={calendarTheme}>
      <View style={styles.container}>
        {/* <NavSideBar /> */}

        <View style={styles.main}>
          <View style={{ flexDirection: "row" }}>
              <View style={{ flexDirection: "column", flex: 1 }}>
                <Text style={styles.titleAndDescription.title}>
                  <Text style={{ fontWeight: 'bold' }}>Manage BA Dates {'>'} </Text>
                  <Text>Add BA Date</Text>
                </Text>
                <View style={{ marginTop: '1%', alignItems: 'flex-start', width: '100%', flex: 1 }}>
                  <Text style={styles.titleAndDescription.description}>
                    This page allows admins to add Battle Assembly dates.
										Keep in mind if the Event Type is not a Battle Assembly, it will only go into the calendar.
                  </Text>
                </View>
              </View>
          </View>

          <View style={styles.sectionDiv}>
            <Text style={styles.sectionTitle}>Battle Assembly Information</Text>
            <View style={[styles.horizontalLine]} />
          </View>

          <View style={styles.sectionContainer}>
            {/* <TextField
              style={styles.textbox}
              sx={styles.textInputProps}
              select
              required
              label="Event Type"
              onChange={(data) => handleEventType(data.target.value)}
              value={eventType}
            >
              {eventTypeOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>

            {eventType === "Other" ? (
              <TextField
                style={styles.textbox}
                sx={styles.textInputProps}
                required
                label="Event Name"
                onChange={(data) => setEventName(data.target.value)}
                onBlur={() => setEventName(eventName.trim())}
                value={eventName}
                error={eventNameEmptyError}
                helperText={eventNameEmptyError ? "Enter event name" : ""}
              />
            ) : null} */}

            <TextField
              style={styles.textbox}
              sx={styles.textInputProps}
              select
              required
              label="Event Type"
              onChange={(data) => handleEventType(data.target.value)}
              value={eventTypeOptions[0].value}
              disabled
              InputProps={{
                disableUnderline: true,
                style: { appearance: 'none' },
              }}
              SelectProps={{
                IconComponent: () => null,
              }}
            >
              <MenuItem key={eventTypeOptions[0].value} value={eventTypeOptions[0].value}>
                {eventTypeOptions[0].label}
              </MenuItem>
            </TextField>
            

            <TextField
              style={styles.textbox}
              sx={styles.textInputProps}
              required
              label="Location"
              onChange={(data) => setLocation(data.target.value)}
              onBlur={() => setLocation(location.trim())}
              value={location}
              error={locationEmptyError}
              helperText={locationEmptyError ? "Enter event location" : ""}
            />

            <TextField
              style={styles.textbox}
              sx={styles.textInputProps}
              required
              label="Training Event Description"
              onChange={(data) => setTrainingEvents(data.target.value)}
              onBlur={() => setTrainingEvents(trainingEvents.trim())}
              value={trainingEvents}
              error={trainingEventsEmptyError}
              helperText={
                trainingEventsEmptyError
                  ? "Enter training event description"
                  : ""
              }
            />

            <TextField
              style={styles.textbox}
              sx={styles.textInputProps}
              select
              required
              label="Mandatory Event"
              onChange={(data) => setIsMandatory(data.target.value)}
              value={isMandatory}
            >
              {isMandatoryOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              style={styles.textbox}
              sx={styles.textInputProps}
              select
              required
              label="RST Authorized (Includes special circumstances)"
              onChange={(data) => handleRSTChange(data.target.value)}
              value={isRSTAuthorized}
            >
              {isRSTAuthorizedOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              style={styles.textbox}
              // was gonna add the textinputpropsdisabled here but it makes more sense tbh to have it like "grey out" on disable instead.
              sx={styles.textInputProps}
              select
              required
              label="MUTA"
              onChange={(data) => setMUTA(data.target.value)}
              value={MUTA}
              disabled={MUTADisabled}
            >
              {MUTAOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              style={styles.textbox}
              sx={styles.textInputProps}
              label="Remarks 1"
              onChange={(data) => setRemarks1(data.target.value)}
              onBlur={() => setRemarks1(Remarks1.trim())}
              value={Remarks1}
            />

            <TextField
              style={styles.textbox}
              sx={styles.textInputProps}
              label="Remarks 2"
              onChange={(data) => setRemarks2(data.target.value)}
              onBlur={() => setRemarks2(Remarks2.trim())}
              value={Remarks2}
            />

            {eventType !== "Other" ? (
              <TextField
                style={{ visibility: "hidden", ...styles.textbox }}
                sx={styles.textInputProps}
                value={""}
              />
            ) : null}
          </View>

          <View style={styles.sectionDiv}>
            <Text style={styles.sectionTitle}>Date & Time</Text>
            <View style={[styles.horizontalLine]} />
          </View>

          <View style={styles.sectionContainer}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ClickAwayListener
            onClickAway={(event) => {
              if (dateTimePicker1Ref.current && !dateTimePicker1Ref.current.contains(event.target)) {
                setDateTimePicker1Open(false);
              }
            }}
            mouseEvent="onMouseDown"
          >
              <DatePicker
                sx={styles.dateBox}
                label="Start Date*"
                ampm={false}
                onChange={(date) => {
                  setStartDate(date);
                  if (endDate && date > endDate) {
                    setBadDateError(true);
                  } else {
                    setBadDateError(false);
                  }
                }}
                value={startDate}
                onClose={() => setDateTimePicker1Open(false)}
                onOpen={() => setDateTimePicker1Open(true)}
                open={dateTimePicker1Open}
                slotProps={{
                  textField: {
                    style: styles.textbox,
                    sx: styles.dateInputProps,
                    error: startDateEmptyError || badDateError,
                    helperText: startDateEmptyError
                      ? "Enter a start date"
                      : badDateError
                      ? "End date happens before the start date"
                      : " ",
                  },
                }}
                PopperProps={{
                  modifiers: [
                    {
                      name: 'eventListeners',
                      options: {
                        scroll: false,
                        resize: true,
                      },
                    },
                  ],
                  ref: (popperRef) => {
                    if (popperRef && popperRef.node) {
                      popperRef.node.id = 'calendar-element';
                      dateTimePicker1Ref.current = popperRef.node.querySelector('.MuiCalendarPicker-root');
                    }
                  },
                }}
              />
              </ClickAwayListener>
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ClickAwayListener
              onClickAway={(event) => {
                if (dateTimePicker2Ref.current && !dateTimePicker2Ref.current.contains(event.target)) {
                  setDateTimePicker2Open(false);
                }
              }}
              mouseEvent="onMouseDown"
            >
              <DatePicker
                sx={styles.dateBox}
                label="End Date*"
                ampm={false}
                onChange={(date) => {
                  setEndDate(date);
                  if (startDate && date < startDate) {
                    setBadDateError(true);
                  } else {
                    setBadDateError(false);
                  }
                }}
                value={endDate}
                onClose={() => setDateTimePicker2Open(false)}
                onOpen={() => setDateTimePicker2Open(true)}
                open={dateTimePicker2Open}
                slotProps={{
                  textField: {
                    style: styles.textbox,
                    sx: styles.dateInputProps,
                    error: startDateEmptyError || badDateError,
                    helperText: startDateEmptyError
                      ? "Enter a start date"
                      : badDateError
                      ? "End date happens before the start date"
                      : " ",
                  },
                }}
                PopperProps={{
                  modifiers: [
                    {
                      name: 'eventListeners',
                      options: {
                        scroll: false,
                        resize: true,
                      },
                    },
                  ],
                  ref: (popperRef) => {
                    if (popperRef && popperRef.node) {
                      popperRef.node.id = 'calendar-element';
                      dateTimePicker2Ref.current = popperRef.node.querySelector('.MuiCalendarPicker-root');
                    }
                  },
                }}
              />
            </ClickAwayListener>
            </LocalizationProvider>

            <TextField
              style={{ visibility: "hidden", ...styles.textbox }}
              value={""}
            />
          </View>

          <View style={styles.buttonDiv}>
            <TouchableOpacity
              style={styles.button}
              onPress={() => {
                navigation.navigate("units_dashboard", {
                  screen: "view_BA_dates",
                });
              }}
            >
              <Text style={styles.innerText}>Return to BA View</Text>
            </TouchableOpacity>

            <TouchableOpacity
              style={styles.button}
              onPress={() => {
                AddBADate();
              }}
            >
              <Text style={styles.innerText}>Add BA Date</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </ThemeProvider>
  );
}

export default Add_BA_Dates;
