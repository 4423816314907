// React
import React, { useState } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';

// UI Imports
import { useColorContext } from './ColorContext';
// File Imports
import { process_training_info_from_csv, put_s3, upload_BA_dates_CSV } from '../../src/api_handler/files';
import BA_Upload_Template from '../CSV_Templates/BA_Upload_Template.csv';

function UnitInfoSideNavbar() {
  const navigation = useNavigation();
  const [Floading, setFLoading] = useState(false);
  const { colors } = useColorContext();

  function uploadBACSVFile() {
    const input = document.createElement('input');
    input.type = 'file';

    input.onchange = (e) => {
      const file = e.target.files[0];
      getBACSVBase64(file);
    };
    input.onchange = (e) => {
      const file = e.target.files[0];
      getBACSV(file);
    };

    input.click();
  }

  function getBACSV(file) {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    reader.onload = async function () {
      const binary = reader.result;
      setFLoading(true);
      uploadBACSV(binary);
    };

    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  async function uploadBACSV(binary) {
    const data = {
      user_id: global.user_data.user_id,
      unit_id: global.user_data.unit_id,
      user_full_name: full_name,
    };

    const rev = await put_s3(csv_filepath, 'text/csv', binary, global.token);

    const response = await upload_BA_dates_CSV(data, global.token);

    if (response !== null && response !== undefined) {
      console.log(response);
      setFLoading(false);

      if (response.body === '') {
        alert('File Successfully Uploaded');
      } else {
        alert('File Uploaded But Failed to Add Following Dates:\n' + response.body);
      }
    } else {
      console.log(response);
      alert('File Upload Error');
    }
  };

  const download_template = () => {
    Linking.openURL(BA_Upload_Template);
  };

  /**
     * Handles Upload Subordinate Training Info button click.
     * Sends the file to be read on file input.
     */
  function handleTrainingInfoUploadClick() {
    const input = document.createElement('input');
    input.type = 'file';

    input.onchange = (e) => {
      const file = e.target.files[0];
      readTrainingInfoCSV(file);
    };

    input.click();
  }

  /**
     * Reads the Training Info CSV and on success, forwards it to be uploaded and
     * processed.
     *
     * @param file The Training Info file to be read
     */
  function readTrainingInfoCSV(file) {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    reader.onload = async function () {
      const binary = reader.result;
      setFLoading(true);
      uploadAndProcessTrainingInfoCSV(binary);
    };

    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  /**
     * Uploads the CSV containing Training Info into S3, then calls a lambda function that processes
     * that CSV.
     *
     * @param csv CSV to be uploaded
     */
  async function uploadAndProcessTrainingInfoCSV(binary) {
    // CSV Path in S3
    const csv_filepath = 'add_training_info_csvs/' + global.user_data.user_id + '.csv';

    // Put the file into S3
    await put_s3(csv_filepath, 'text/csv', binary, global.token);

    // Prepare data to call Lambda function with
    const data = {
      user_id: global.user_data.user_id,
      unit_id: global.user_data.unit_id,
      user_full_name: global.user_data.first_name + " " + global.user_data.middle_name + " " + global.user_data.last_name,
    };

    // Process the Training Info using a Lambda function
    const response = await process_training_info_from_csv(data, global.token);

    // Update the user
    if (response !== null && response !== undefined) {
      setFLoading(false);

      if (response.totalErrors === 0) {
        alert('File Successfully Uploaded and Process');
      } else {
        alert('File Uploaded and processed with ' + response.totalErrors + ' errors.');
      }
    } else {
      alert('Error uploading file. Please try again later.');
    }
  }

  // STYLES
  const styles = {
    container: {
      width: '15%',
      height: '100%',
      backgroundColor: colors.gold,
    },
    button_view: {
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
    },
    button: {
      width: '90%',
      borderRadius: '40',
      alignItems: 'center',
      backgroundColor: colors.button,
      borderRadius: 15,
      shadowColor: '#171717',
      shadowOffset: { width: -2, height: 4 },
      shadowOpacity: 0.2,
      shadowRadius: 3,
      marginTop: 20,
      marginBottom: 20,
      textDecoration: 'inherit',
      color: 'inherit',
    },
    innerText: {
      color: colors.button_text,
      fontWeight: 'bold',
      padding: 10,
    },
  };

  // Component Return
  return (
    <View style={styles.container}>
      <View style={styles.button_view}>
        <TouchableOpacity
          style={styles.button}
          onPress={() => {
            navigation.navigate('units_dashboard', { screen: 'edit_unit_forms_info' });
          }}>
          <Text style={styles.innerText}>View Unit Form Info</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.button}
          onPress={() => {
            navigation.navigate('units_dashboard', { screen: 'unit_documents' });
          }}>
          <Text style={styles.innerText}>View Unit Documents</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.button}
          onPress={() => {

            navigation.navigate('units_dashboard', { screen: 'unit_POC' });
          }}>
          <Text style={styles.innerText}>View Unit POCs</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.button}
          onPress={() => {
            navigation.navigate('units_dashboard', { screen: 'view_BA_dates' });
          }}>
          <Text style={styles.innerText}>View BA Dates</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.button}
          onPress={() => {
            uploadBACSVFile();
          }}>
          <Text style={styles.innerText}>Upload BA Schedule</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.button} onPress={() => {
          handleTrainingInfoUploadClick();
        }}>
          <Text style={styles.innerText}>Upload Subordinate Training Information</Text>
        </TouchableOpacity>
        <a
          style={styles.button}
          href={BA_Upload_Template}
          download="BA_Upload_Template"
          target="_blank"
          rel="noreferrer"
        >
          <TouchableOpacity
            style={{ alignItems: 'center' }}
            onPress={() => {
              download_template;
            }}>
            <Text style={styles.innerText}>Download BA Schedule Template</Text>
          </TouchableOpacity>
        </a>
      </View>
    </View>
  );
};

export default UnitInfoSideNavbar;
