/* eslint-disable linebreak-style */
/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, {useRef} from 'react';
import {View, Text, StyleSheet, TouchableOpacity} from 'react-native';
import {TextInput, HelperText} from 'react-native-paper';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {createTheme, ThemeProvider, useTheme} from '@mui/material/styles';
import {ClickAwayListener, FormControlLabel, Radio} from '@mui/material';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import CustomRadioWithBackground from '../../components/CustomRadioWithBackground';
import TextField from '@mui/material/TextField';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {useColorContext} from '../../components/ColorContext';
import {useFocusEffect} from '@react-navigation/native';
import moment from 'moment';

const RSTComponent = ({
  navigation,
  eventDate,
  setEventDate,
  checkbox4H,
  handleCheckbox4H,
  checkbox8H,
  handleCheckbox8H,
  // pointsOnly,
  // togglePointsOnly,
  duties,
  setDuties,
  setModifying,
  modifying,
  addEvent,
  modifyFirstEntryEvent,
  AFTPMUTAsError,
  selected,
  modifyingFirstEntry,
  eventDateEmptyError,
  datePicker1Open,
  eventDateEmptyErrorText,
  setEventDateEmptyError,
  setEventDateEmptyErrorText,
  setDatePicker1Open,
  dutiesEmptyError,
  setDutiesEmptyError,
  setFormattedEventDate,
  locked,
  hours,
  setHours,
  setHoursEmptyError,
  hoursEmptyError,
  setPoints,
  pointsEmptyError,
  setPointsEmptyError,
  points,
  events,
}) => {
  const {colors} = useColorContext();
  const styles = StyleSheet.create({
    container: {
      padding: 20,
    },
    label: {
      fontSize: 16,
      marginBottom: 5,
    },
    input: {
      height: 40,
      borderColor: 'gray',
      borderWidth: 1,
      marginBottom: 15,
      padding: 10,
    },
    cancelButton: {
      color: colors.text,
      width: '10vw',
      borderRadius: 10,
      padding: 10,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: colors.modal_background,
      margin: 8,
      marginBottom: 15,
      borderColor: colors.border_color,
      borderWidth: 1,
      shadowOffset: {width: 0, height: 2},
      shadowOpacity: 0.25,
      shadowRadius: 4,
      alignItems: 'center',
    },
    checkboxContainer: {
      flexDirection: 'row',
      fontSize: 18,
      alignItems: 'center',
      flex: 1,
    },
    textStyle: {
      justifyContent: 'center',
      fontSize: 15,
      fontWeight: 'bold',
      color: colors.text,
      marginTop: '2%',
    },
    commentInputContainer: {
      marginTop: '1.5%',
      width: '100%',
    },
    locationCommentInput: {
      borderColor: colors.border_color,
      borderWidth: 1,
      padding: 3,
      borderRadius: 1,
      fontSize: 16,
      backgroundColor: colors.textfield_background,
      height: 56,
    },
    hoursPointsInput: {
      width: '100%',
      marginTop: '1%',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-around',
      flex: 1,
      minHeight: 78,
    },
    inputSplit: {
      backgroundColor: colors.textfield_background,
      borderColor: colors.border_color,
    },
    inputSplitLocked: {
			backgroundColor: colors.screen_background,
			flex: 1,
		},
  });

  const theme = useTheme();
  const datePicker1Ref = useRef(null);
  const payTheme = createTheme({
    palette: {
      mode: colors.mode,
      primary: theme.palette.primary,
    },
  });

  const onSetHours = (hours) => {
    if (+hours || hours === '') {
      setHours(hours);
    }
  };

  useFocusEffect(
      React.useCallback(() => {
      // This effect is used to prevent navigation instantly if date picker is open
        const beforeRemoveListener = (e) => {
          if (!datePicker1Open) {
            return;
          }

          e.preventDefault();

          setDatePicker1Open(false);
          setTimeout(() => {
            navigation.dispatch(e.data.action);
          }, 200);
        };

        navigation.addListener('beforeRemove', beforeRemoveListener);

        return () => {
          navigation.removeListener('beforeRemove', beforeRemoveListener);
        };
      }, [datePicker1Open]),
  );

  return (
    <View style={{alignItems: 'center', width: '100%'}}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={payTheme}>
          <ClickAwayListener
            onClickAway={(event) => {
              if (datePicker1Ref.current && !datePicker1Ref.current.contains(event.target)) {
                setDatePicker1Open(false);
              }
            }}
            mouseEvent="onMouseDown"
          >
            <DatePicker
              id="Date Picker"
              label="To be Performed On*"
              onChange={(date) => {
                setEventDate(date);
                setFormattedEventDate(moment(new Date(date)).format('MM/DD/YYYY'));
                setEventDateEmptyError(!date.isValid());
              }}
              value={eventDate}
              error={eventDateEmptyError}
              onClose={() => setDatePicker1Open(false)}
              onOpen={() => setDatePicker1Open(true)}
              open={datePicker1Open}
              slotProps={{
                textField: {
                  sx: {
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: colors.border_color,
                      },
                      '&:hover fieldset': {
                        borderColor: colors.border_color,
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: colors.border_color,
                      },
                    },
                  },
                  onFocus: () => {
                    setEventDateEmptyError(false);
                    setEventDateEmptyErrorText(' ');
                  },
                  onBlur: () => {
                    setEventDateEmptyError(eventDate === null);
                    setEventDateEmptyErrorText(eventDate === null ? 'Enter performed on date(s)' : ' ');
                  },
                  error: eventDateEmptyError,
                },
              }}
              PopperProps={{
                modifiers: [
                  {
                    name: 'eventListeners',
                    options: {
                      scroll: false,
                      resize: true,
                    },
                  },
                ],
                ref: (popperRef) => {
                  if (popperRef && popperRef.node) {
                    popperRef.node.id = 'calendar-element';
                    datePicker1Ref.current = popperRef.node.querySelector('.MuiCalendarPicker-root');
                  }
                },
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </ClickAwayListener>
        </ThemeProvider>
      </LocalizationProvider>
      <HelperText style={{color: 'red'}}>{eventDateEmptyError ? eventDateEmptyErrorText : ''}</HelperText>

      <View style={[styles.checkboxContainer, {flexDirection: 'column', justifyContent: 'center'}]}>
        <View style={{flexDirection: 'row', alignItems: 'center', height: '65%'}}>
          <Text style={[styles.textStyle, {marginRight: '10%'}]}>MUTAs*:</Text>
          <CustomRadioWithBackground
            id="MUTA 1"
            checked={checkbox4H == true}
            onChange={handleCheckbox4H}
            label="MUTA 1"
          />
          <CustomRadioWithBackground
            id="MUTA 2"
            checked={checkbox8H == true}
            onChange={handleCheckbox8H}
            label="MUTA 2"
          />
        </View>
        <HelperText style={{color: 'red', paddingHorizontal: 12, paddingVertical: 0, marginRight: '3%'}}>{AFTPMUTAsError ? 'Select number of MUTAs' : ' '}</HelperText>
      </View>

      {/* <View style={[styles.checkboxContainer, {flexDirection: 'column'}]}>
        <View style={{flexDirection: 'row', alignItems: 'center', height: '100%'}}>
          <Text style={styles.textStyle}>Points Only</Text>
          <Checkbox
            status={pointsOnly ? 'checked' : 'unchecked'}
            color={colors.gold}
            uncheckedColor={colors.inputted_text}
            theme={{colors: {text: colors.text}}}
            onPress={togglePointsOnly}
          />
        </View>
      </View> */}
      <View
        style={[styles.hoursPointsInput]}
      >
        <TextInput
          keyboardType={'numeric'}
          id="Hours Input"
          theme={{
            colors: {
              text: locked ? colors.disabled_text : colors.inputted_text,
              accent: colors.textfield_background,
              primary: locked ? colors.disabled_text : colors.inputted_text,
              placeholder: locked ? colors.disabled_text : colors.placeholder_text,
              disabled: locked ? colors.disabled_text : colors.border_color,
            },
          }}
          style={[styles.inputSplit]}
          onChangeText={onSetHours}
          value={hours}
          label="Number of hour(s)*"
          mode="outlined"
          disabled={locked}
          onBlur={() => {
            setHoursEmptyError(hours === '');
          }}
          error={hoursEmptyError}
        />
        <TextInput
          id="Points Input"
          theme={{
            colors: {
              text: locked ? colors.disabled_text : colors.inputted_text,
              accent: colors.textfield_background,
              primary: locked ? colors.disabled_text : colors.inputted_text,
              placeholder: locked ? colors.disabled_text : colors.placeholder_text,
              disabled: locked ? colors.disabled_text : colors.border_color,
            },
          }}
          style={[styles.inputSplit]}
          onChangeText={setPoints}
          value={points}
          label="Retirement points*"
          mode="outlined"
          disabled={locked}
          onBlur={() => {
            setPointsEmptyError(points === '');
          }}
          error={pointsEmptyError}
        />
      </View>
      <View style={{width: '95%', alignItems: 'center'}}>
        <View style={[styles.commentInputContainer]}>
          <TextInput
            id="Location/Nature Input"
            theme={{
              colors: {
                text: colors.inputted_text,
                accent: colors.textfield_background,
                primary: colors.inputted_text,
                placeholder: colors.placeholder_text,
              },
            }}
            style={[styles.locationCommentInput, {padding: 0}]}
            label="Location/Nature of duties, training, or instruction*"
            placeholder=""
            multiline={false}
            value={duties}
            onChangeText={setDuties}
            onBlur={() => {
              setDutiesEmptyError(duties === '');
            }}
            error={dutiesEmptyError}
          />
          <HelperText style={{color: 'red', alignSelf: 'center', marginBottom: dutiesEmptyError ? '1.5%' : '0%'}}>{dutiesEmptyError ? 'Enter location/nature of duties performed' : ' '}</HelperText>
        </View>

        <TouchableOpacity
          id="Add/Modify Button"
          style={[
            styles.cancelButton,
            {
              backgroundColor: colors.action_button,
              opacity: (events.length >= 11 && !modifying) ? 0.5 : 1,
            },
          ]}
          onPress={() => {
            if (selected !== -1) {
              setModifying(true);
            }

            if (modifyingFirstEntry) {
              modifyFirstEntryEvent();
            } else {
              addEvent();
            }
          }}
          disabled={(events.length >= 11 && !modifying)}
        >
          <Text style={styles.textStyle}>
            {selected !== -1 ? 'Modify Entry' : 'Add Entry'}
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default RSTComponent;
