import React, { useEffect, useState, useRef } from "react";
import { View, Text, Image, TouchableOpacity } from "react-native";
import { CheckBox } from "react-native-web";
import { useScale } from "../../components/scale";
import NavSideBar from "../../components/NavSideBar";
import { useColorContext } from "../../components/ColorContext";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { TextInput } from "react-native-paper";
import { get_single_file, give_recommendation } from "../../api_handler/files";
import { generatePDF } from "../generatePDF.js";
import Scrollbars from "react-custom-scrollbars";
import { create_notification } from "../../api_handler/notifications.js";
import * as Progress from 'react-native-progress'
import TextField from '@mui/material/TextField';
import ViewShot from 'react-native-view-shot';
import { captureRef } from 'react-native-view-shot';
import { create_new_user_signature } from "../../api_handler/users.js";
import { put_image_s3 } from "../../api_handler/files";
import { storeJsonData } from '../../api_handler/asyncstorage.js';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useSnackbar } from '../../components/SnackbarContext';

function RecommendRST({ route, navigation }) {
  const { colors } = useColorContext();
  const scale = useScale();
  const { showSnackbar } = useSnackbar();
  const styles = {
    container: {
      height: '100%',
      width: '100%',
      flexDirection: 'row',
      backgroundColor: colors.screen_background,
    },
    main: {
      flex: 10,
      height: '100%'
    },
    header: {
      marginTop: "0%",
      height: 110,
      backgroundColor: colors.screen_background,
    },
    rowContainer: {
      flexDirection: 'row',
      marginTop: 15,
      marginBottom: 10,
      justifyContent: 'center',
    },
    title: {
      fontSize: scale(50),
    },
    pageTitle: {
      fontSize: scale(30),
      fontFamily: 'Trebuchet MS',
      flex: 1,
      color: colors.text,
      paddingLeft: '5%',
      paddingTop: 25
    },
    description: {
      fontSize: scale(18),
      marginLeft: '5%',
      textAlign: 'Left',
      paddingTop: 25,
      color: colors.bow,
      width: '50%',
      fontFamily: 'Trebuchet MS',
      marginBottom: '2%'
    },
    button: {
      width: '10vw',
      borderRadius: 10,
      padding: 10,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: colors.action_button,
      margin: 8,
      marginBottom: 15,
      borderColor: colors.border_color,
      borderWidth: 1,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      alignItems: 'center',
    },
    cancelButton: {
      width: '10vw',
      borderRadius: 10,
      padding: 10,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: colors.modal_background,
      margin: 8,
      marginBottom: 15,
      borderColor: colors.border_color,
      borderWidth: 1,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      alignItems: 'center',
    },
    signText: {
      fontWeight: 'bold',
      fontSize: scale(14),
      textAlign: 'center',
      margin: '1%',
      paddingLeft: '5%',
      paddingRight: '5%',
      color: colors.bow
    },
    contentContainer: {
      height: '50vh',
      width: '100%',
      marginTop: 10,
      margin: 20,
      flexDirection: 'row',
      justifyContent: 'center',
      flex: 1,
    },
    tableContainer: {
      backgroundColor: colors.screen_background,
      flex: 1,
      width: "35%",
    },
    tableDiv: {
      borderColor: colors.border_color,
      borderWidth: 1,
      borderRadius: 5,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      backgroundColor: colors.modal_background,
    },
    textStyle: {
      fontSize: 15,
      fontWeight: "bold",
      color: colors.text,
    },
    commentInput: {
      borderColor: colors.border_color,
      minHeight: 64,
      height: commentInputHeight,
      borderWidth: 1,
      borderRadius: 5,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      backgroundColor: colors.modal_background,
      width: "85%",
      marginTop: '5%'
    },
    entryBox: {
      backgroundColor: colors.screen_background,
      justifyContent: "center",
      alignItems: "center",
      padding: '3%',
      marginBottom: '2%',
      width: '85%',
      borderColor: colors.border_color,
      borderWidth: 1,
      borderRadius: 5,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
    },
    approvalModalTitle: {
      fontFamily: 'Trebuchet MS',
      fontSize: '1.25rem',
      fontWeight: 'bold',
      color: colors.text,
      marginBottom: '3%'
    },
    checkboxContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: 10,
    },
    checkboxLabel: {
      marginLeft: 8,
      fontSize: 15,
      color: colors.text,
    },
  };

  const [loaded, setLoaded] = useState(false);
  const [viewSig, setViewSig] = useState(false);
  const [author, setAuthor] = useState("");
  const [isApproved, setisApproved] = useState(false);
  const [isDisapproved, setisDisapproved] = useState(false);

  // FILE FIELDS
  const [requestName, setRequestName] = useState("");
  const [muta, setMUTA] = useState("");
  const [lieuBADates, setLieuBADates] = useState("");
  const [ATAs, setATAs] = useState("");
  const [date, setDate] = useState(new Date());
  const [checkBox3, setCheckBox3] = useState(false);
  const [checkBox4, setCheckBox4] = useState(false);
  const [checkBox5, setCheckBox5] = useState(false);
  const [checkBox6, setCheckBox6] = useState(false);
  const [checkBox7, setCheckBox7] = useState(false);
  const [checkBox8, setCheckBox8] = useState(false);
  const [selectedDuty, setSelectedDuty] = useState("");
  const [excusedBADates, setExcusedBADates] = useState("");
  const [RMADates, setRMADates] = useState("");
  const [ATADates, setATADates] = useState("");
  const [rstDates, setRstDates] = useState("");
  const [duties, setDuties] = useState("");
  const [justification, setJustification] = useState("");
  const [comment, setComment] = useState("");
  // const [submitted, setSubmitted] = useState(false);
  const [reqDate, setReqDate] = useState("");
  const [rankName, setRankName] = useState("");
  const [rerender, setRerender] = useState(false);
  const [form, setForm] = useState({});
  const [didUserSignDocument, setDidUserSignDocument] = useState(false);
  const [reviewerName, setReviewerName] = useState('');
  const [reviewerID, setReviewerID] = useState('');
  const [commentInputHeight, setCommentInputHeight] = useState(64);

  const [RSTData, setRSTData] = useState();

  const [doesUserHaveSignature, setDoesUserHaveSignature] = useState(false);
  const [signatureName, setSignatureName] = useState("");

  const viewRef = useRef();

  useEffect(() => {
    if (comment === '') {
      setCommentInputHeight(64);
    }
  }, [comment]);

  useEffect(() => {
    async function loadFile() {
      const res = await get_single_file(
        { form_id: route.params.fileID, form_type: "RST" },
        global.token
      );
      if (res !== undefined) {
        preLoadInfo(res.body);
        setReviewerName(res.body.reviewer_name);
        setReviewerID(res.body.reviewer_id);
        setRSTData(res.body);
        setLoaded(true);
        setRerender(!rerender);
      }
    }

    function checkSignature() {
      if (global.signature_uri == null || global.signature_uri === '') {
        setDoesUserHaveSignature(false);
      } else {
        setDoesUserHaveSignature(true);
      }
    }


    setDidUserSignDocument(false);

    checkSignature();
    loadFile();
  }, []);

  const captureTextAsImage = async () => {
    try {
      const fullname = global.user_data.first_name.toLowerCase() + " " + global.user_data.last_name.toLowerCase();
      if (signatureName.toLowerCase() === fullname) {
        const uri = await captureRef(viewRef, {
          format: 'png',
          quality: 0.8
        });

        let ret = await create_new_user_signature({ user_id: global.user_data.user_id }, global.token);
        if (ret.status === 200) {
          ret = ret.data
          await put_image_s3('signatures/' + ret.body.signature_id, uri, 'image/png', global.token);

          global.signature_uri = uri;

          global.user_data.signature_id = ret.body.signature_id;
          await AsyncStorage.setItem('signature', global.signature_uri);
          storeJsonData('user_data', global.user_data);

          setDoesUserHaveSignature(true);
        } else {
          showSnackbar("error", "Application Error: Failed to create your signature");
        }
        
      } else {
        showSnackbar('error', 'The name inputted does not match your name we have on record!');
      }
    } catch (error) {
      console.error('Error capturing image:', error);
    }
  };

  const viewFilled = async () => {
    setLoaded(false);
    const data = route.params;
    generatePDF(data.fileID, data.form_type, navigation, false, false, data.attachments, data.creator_id);
    setLoaded(true);
  };

  const preLoadInfo = async (form) => {
    setRequestName(form.name_and_rank);
    setAuthor(form.name_and_rank);
    setRankName(form.name_and_rank);
    setReqDate(form.date);
    setMUTA(form.excused_absence_muta_num);
    setLieuBADates(form.rst_ba_date);
    setATAs(form.ata_num_of_ata);
    if (form.date === undefined || form.date === "") {
      setDate(new Date());
    } else {
      setDate(new Date(form.date));
    }
    setCheckBox3(form.excused_absence);
    setCheckBox4(form.rst);
    setCheckBox5(form.rma);
    setCheckBox6(form.ata);
    if (form.request_is_approved === true) {
      setCheckBox7(true);
    } else {
      setCheckBox8(true);
    }
    setExcusedBADates(form.excused_absence_ba_date);
    setRstDates(form.rst_performed_on);
    setRMADates(form.rma_date);
    setATADates(form.ata_date);

    setDuties(form.tasks_to_perform);
    setJustification(form.justification);
    setForm(form);
  };

  useEffect(() => {
    if (checkBox3) setSelectedDuty("Excused Absence");
    else if (checkBox4) setSelectedDuty("RST");
    else if (checkBox5) setSelectedDuty("RMA");
    else if (checkBox6) setSelectedDuty("ATA");
  }, [checkBox3, checkBox4, checkBox5, checkBox6]);

  // Building RST data table:
  function createTable() {
    const data = [
      { label: "Requestor Name", data: author },
      { label: "Date Filed", data: reqDate },
      { label: "Duty to be Performed", data: selectedDuty },
      ...(checkBox3
        ? [
          { label: "B.A. Date(s)", data: excusedBADates },
          { label: "MUTA Number", data: muta },
        ]
        : []),
      ...(checkBox4
        ? [
          { label: "In Lieu of B.A. Date(s)", data: lieuBADates },
          { label: "To be Performed On", data: rstDates },
        ]
        : []),
      ...(checkBox5 ? [{ label: "Date(s)", data: RMADates }] : []),
      ...(checkBox6
        ? [
          { label: "Date(s)", data: ATADates },
          { label: "ATA Number", data: ATAs },
        ]
        : []),
      { label: "Tasks", data: duties },
      { label: "Justification", data: justification },
    ];

    return data;
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${TableCell.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:hover': {
      backgroundColor: colors.textfield_background_hover
    },

    '& td, & th': {
      borderColor: colors.border_color,
    },
  }));

  const changeAccept = (value) => {
    setisApproved(value);

    if (value) {
      setisDisapproved(false);
    }
  };

  const changeDecline = (value) => {
    setisDisapproved(value);

    if (value) {
      setisApproved(false);
    }
  };

  const onSubmit = async () => {
    setLoaded(false);
    // make sure user has signature and approved/disapproved
    const sigFile = await global.signature_uri;
    if (sigFile == null || sigFile === '') {
      showSnackbar('error', 'You do not have a signature.');
      setLoaded(true);
    } else if (isApproved === false && isDisapproved === false) {
      showSnackbar('error', 'Please either approve or disapprove this request.');
      setLoaded(true);
    } else {

      if(!didUserSignDocument) {
        showSnackbar("error", "Please Sign Document");
        setLoaded(true);
      } else {
        if (isApproved === true) {

          const data = {
            form_id: route.params.fileID,
            comment: comment,
            form_type: "RST",
            recommender_signature_id: global.user_data.signature_id,
            request_is_approved: true,
            permissions: global.user_data.permissions
          };
  
          let res = await give_recommendation(data, global.token);
          if (res.status === 200) {
            console.log(route.params);
            await sendNotifToSoldier(route.params.creator_id, 'approved');
            await sendNotifToReviewer(reviewerID);
            // showSnackbar("success", "RST form approved successfully.");
          } else {
            showSnackbar("error", "Application Error: Failed to add your recomendation to RST");
            navigation.navigate("AwaitingAction");
            return;
          }
          //will not let the reviewer continue if the file is dissaproved with an empty comment
        }else if (comment === '') {
          showSnackbar('error', 'Please enter a comment');
          setLoaded(true);
          return;
          
        } else if (isDisapproved === true) {
  
          const data = {
            form_id: route.params.fileID,
            comment: comment,
            form_type: "RST",
            recommender_signature_id: global.user_data.signature_id,
            request_is_approved: false,
            permissions: global.user_data.permissions
          };
  
          let res = await give_recommendation(data, global.token);
          if (res.status === 200) {
            await sendNotifToSoldier(route.params.creator_id, 'disapproved');
            // showSnackbar("success", "RST form disapproved successfully.");
          } else {
            showSnackbar("error", "Application Error: Failed to add your recomendation to RST");
            navigation.navigate("AwaitingAction");
            return;
          }
        }

        setLoaded(true);
        // showSnackbar('success', 'Recommendation submitted.')
        navigation.navigate("AwaitingAction");
      }
    }

    /* ------------------------------------------*/
    // email to supervising chief
    // send email to res.sectionChiefEmail
  };
  /* -----------------------------------------------*/
  /* -----------------------------------------------*/

  const sendNotifToSoldier = async (soldier_id, status) => {
    const data = {
      title: 'RST Form ' + status,
      body: comment,
      receiver_id: soldier_id,
      sender_id: global.user_data.user_id,
      notification_type: 'file',
      object_type: 'RST form ' + status,
      form_events_id: route.params.fileID,
    };
    let res = await create_notification(data, global.token);

    if (res.status !== 200) {
      showSnackbar(
        "success", 
        isApproved ? "RST form recommendation approved successfully." : "RST form recommendation disapproved successfully.",
        "top",
        "right",
        6000,
        400,
        () => {
          showSnackbar("error", `Application Error: Failed to send notification to ${requestName}`)
        }
      )
    } else {
      showSnackbar("success", isApproved ? "RST form recommendation approved successfully" : "RST form recommendation disapproved successfully");
    }
  };

  const sendNotifToReviewer = async (soldier_id) => {
    const data = {
      title: 'RST Form',
      body: 'RST Form recommended, ready for review',
      receiver_id: soldier_id,
      sender_id: global.user_data.user_id,
      notification_type: 'file',
      object_type: 'RST form recommend, ready for review',
      form_events_id: route.params.fileID,
    };
    let res = await create_notification(data, global.token);

    if (res.status !== 200) {
      showSnackbar(
        "success", 
        isApproved ? "RST form recommendation approved successfully" : "RST form recommendation disapproved successfully",
        "top",
        "right",
        6000,
        400,
        () => {
          showSnackbar("error", `Application Error: Failed to send notification to ${reviewerName}`)
        }
      )
    } else {
      showSnackbar("success", isApproved ? "RST form recommendation approved successfully" : "RST form recommendation disapproved successfully");
    }
  };

  const handleSignature = () => {
    setViewSig(true);
    setDidUserSignDocument(true);
  }

  const onCancel = () => {
    navigation.navigate("AwaitingAction");
  };

  const loadingStyles = {
    animation: {
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
        justifyContent: 'center',
        paddingVertical: 20,
        background: colors.screen_background,
    }
  }

  if (!loaded) {
    return (
      <View style={loadingStyles.animation}>
          <Progress.CircleSnail
            color={colors.loading_circle}
            indeterminate={true}
            size={275}
            thickness={8}
          />
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <Scrollbars style={styles.main}>
        <View style={{ flexDirection: 'column' }}>
          <Text style={styles.pageTitle}>Recommend</Text>
          <Text style={styles.description}>
            Review user's RST information and approve/deny the request
          </Text>
          <View style={{ flexDirection: "row", marginTop: '1%', width: '100%', paddingLeft: '5%', paddingRight: '5%' }}>
            <View style={[styles.tableContainer, {flex: 1, backgroundColor: colors.screen_background}]}>
              <View style={styles.tableDiv}>
              <TableContainer component={Paper} style={{ height: 'fit-content', width: '100%', paddingTop: '1%', backgroundColor: colors.textfield_background }}>
                  <Table>
                    <TableHead style={{borderColor: colors.border_color}}>
                      <TableRow>
                        <StyledTableCell style={{ color: colors.text, fontSize: '1.25rem', borderColor: colors.border_color, width: '35%' }}>Label</StyledTableCell>
                        <StyledTableCell style={{ color: colors.text, fontSize: '1.25rem', borderColor: colors.border_color }}>Data</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {createTable().map((row) => (
                        <StyledTableRow key={row.label}>
                          <StyledTableCell style={{ color: colors.text, fontSize: '1rem', width: '35%', borderWidth: row.label === 'Justification' ? 0 : 1 }}>{row.label}</StyledTableCell>
                          <StyledTableCell style={{ color: colors.text, fontSize: '1rem', borderWidth: row.label === 'Justification' ? 0 : 1 }}>{row.data}</StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </View>
            </View>
            
            <View style={{ flexDirection: "column", alignItems: "center", flex: 1 }}>
              <View style={styles.entryBox}>
                <Text style={styles.approvalModalTitle}>Recommender Actions</Text>

                <View style={styles.checkboxContainer}>
                  <CheckBox
                    disabled={false}
                    value={isApproved}
                    onValueChange={(newValue) => {
                      changeAccept(newValue);
                    }}
                  />
                  <Text style={styles.checkboxLabel}>Approve</Text>
                  <CheckBox
                    disabled={false}
                    value={isDisapproved}
                    onValueChange={(newValue) => {
                      changeDecline(newValue);
                    }}
                    style={{ marginLeft: 20, }}
                  />
                  <Text style={styles.checkboxLabel}>Disapprove</Text>
                </View>

                <TextInput
                  style={styles.commentInput}
                  theme={{
                    colors: {
                      text: colors.inputted_text,
                      accent: colors.textfield_background,
                      primary: colors.inputted_text,
                      placeholder: colors.placeholder_text,
                    },
                  }}
                  activeUnderlineColor={colors.bow}
                  placeholderTextColor={colors.text}
                  selectionColor={colors.text}
                  activeOutlineColor={colors.text}
                  InputProps={{ style: { color: colors.text } }}
                  label="Comment"
                  placeholder='Explain why you approved/denied the request (Optional)'
                  inputStyle={{ color: colors.bow }}
                  multiline={true}
                  autoSize={true}
                  value={comment}
                  onChangeText={(text) => {
                    setComment(text);
                    if (text.length === 0) {
                      setCommentInputHeight(64)
                    } 
                  }}
                  onContentSizeChange={({nativeEvent: {contentSize: {width, height}}}) => {
                    if (height > 64 && height < 96) {
                      setCommentInputHeight(96);
                    } else if (height > 96 && height < 128){
                      setCommentInputHeight(128);
                    } else if (height > 128 && height < 160) {
                      setCommentInputHeight(160);
                    } else if (height > 160){
                      setCommentInputHeight(192);
                    }
                  }}
                />
              </View>
          
              <Text style={[styles.signText, {width: '85%', marginTop: ''}]}>
                By clicking on the "Submit" button below, you consent to your
                digital signature being affixed to the document. Your signature can
                be viewed below after clicking the "Sign Document" button. If you
                don't have a signature, please add one below.
              </Text>
              <View style={{ width: '100%', alignItems: 'center', maringTop: '1%' }}>
                {doesUserHaveSignature ? (
                  <>
                    <TouchableOpacity
                      style={[styles.button, {backgroundColor: colors.action_button}]}
                      onPress={handleSignature}
                    >
                      <Text style={{ justifyContent: "center", fontSize: 15, color: colors.text, fontWeight: 'bold' }}>
                        {viewSig ? "Document Signed" : "Sign Document"}
                      </Text>
                    </TouchableOpacity>
                    {viewSig && (
                      <>
                        <Image
                          resizeMode={"contain"}
                          style={{ width: 200, height: 100, backgroundColor: 'white' }}
                          source={{ uri: global.signature_uri }}
                        />
                      </>
                    )}
                  </>
                ) : (
                  <View style={{ width: '50%', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={[styles.textStyle, { padding: 15 }]}>No signature found</Text>

                    <TextInput
                      style={styles.commentInput}
                      theme={{
                        colors: {
                          text: colors.inputted_text,
                          accent: colors.textfield_background,
                          primary: colors.inputted_text,
                          placeholder: colors.placeholder_text,
                        },
                      }}
                      label="Enter your first and last name"
                      placeholder=""
                      value={signatureName}
                      onChange={(data) => setSignatureName(data.target.value)}
                    />

                    <ViewShot ref={viewRef} style={{ padding: 10, marginVertical: 10, backgroundColor: 'white' }} options={{ format: 'png', quality: 1 }}>
                      <Text style={{ color: 'black' }}>{signatureName}</Text>
                    </ViewShot>
                    <TouchableOpacity style={styles.button} onPress={captureTextAsImage}>
                      <Text style={styles.textStyle}>Create Signature</Text>
                    </TouchableOpacity>
                  </View>
                )}
              </View>
            </View>
          </View>
          
          <View style={[styles.rowContainer, { marginTop: 50 }]}>
            <TouchableOpacity style={[styles.cancelButton, {backgroundColor: colors.button_cancel}]} onPress={onCancel}>
              <Text style={styles.textStyle}>Cancel</Text>
            </TouchableOpacity>

            <TouchableOpacity style={[styles.cancelButton, {marginLeft: '5%', marginRight: '5%'}]} onPress={viewFilled}>
              <Text style={styles.textStyle}>View Form</Text>
            </TouchableOpacity>

            <TouchableOpacity style={[styles.button, {backgroundColor: colors.button_submit}]} onPress={onSubmit}>
              <Text style={styles.textStyle}>Submit</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Scrollbars>
    </View>
  );
}

export default RecommendRST;
