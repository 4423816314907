import React from 'react';
import { View, Text, Image, TouchableOpacity, ScrollView } from 'react-native';

import { first_login_password_change } from '../api_handler/users.js';
import { IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { Visibility, VisibilityOff } from '@mui/icons-material';


function ResetTempPassword({ navigation, route }) {
    const [password, onChangePassword] = React.useState("");
    const [confirmPassword, onChangeConfirmPassword] = React.useState("");
    const [showPassword, setShowPassword] = React.useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
    const [error, setError] = React.useState('');

    const styles = ({
        container: {
            width: '100%',
            height: '100%',
            backgroundColor: 'white',
            alignItems: 'center',
        },
        main: {
            marginTop: '1%',
            width: '50%',
            height: '80%',
            alignItems: 'center',
            borderColor: 'white',
            borderWidth: 1,
        },
        buttonContainer: {
            width: 200,
            marginBottom: 15,
        },
        more_containers: {
            width: "100%",
            alignItems: 'center',
            flexDirection: 'column',
            marginTop: 10,
            marginBottom: 5,
        },
        logo: {
            width: 800,
            height: 300,
            resizeMode: 'contain',
        },
        textbox: {
            m: 1,
            borderWidth: 1,
            height: 60,
            width: '40%',
            marginTop: '3vh',
        },
        buttonStyle: {
            height: '40px',
            width: '100%',
            backgroundColor: "#0d1232",
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
        },
        buttonText: {
            color: 'white',
            fontSize: 25,
            marginBottom: 7,
            textAlign: 'center',
        },
        login_failed_text: {
            color: 'red',
            fontSize: 20,
            marginBottom: 7,
            textAlign: 'center',
        },
        validPasswordMsg: {
            fontWeight: 'bold',
            textAlign: 'center',
        },
        validPasswordMsgBox: {
            marginTop: 10,
            borderWidth: 0.3,
            paddingLeft: 100,
            paddingRight: 100,
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            lineHeight: '100%',  // Vertically center the text using line-height
        },
    });

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    async function handleOnSubmit() {
        if (password == confirmPassword) {
            const res = await first_login_password_change({ email: route.params.email, new_password: password });
            if (res.status === 400) {
                setError(res.data.body);
            } else {
                navigation.navigate('Login');
            }
        } else {
            setError("Passwords must match");
        }
    }

    return (
        <ScrollView showsverticalscrollindicator="true"style={{ backgroundColor: "#ffffff" }}>
            <View style={styles.container}>
                <View style={styles.main}>
                    <Image style={styles.logo} source={require('../assets/US_Army_Reserve logo light mode large final.png')} />
                    <View style={styles.more_containers}>
                        <Text style={{ marginBottom: 30, fontSize: 30 }}>Reset Temporary Password</Text>

                        <View style={styles.validPasswordMsgBox}>
                            <Text style={styles.validPasswordMsg}>
                                Password must have:
                                {'\n'}• At least 8 characters
                                {'\n'}• 1 special character
                                {'\n'}• 1 uppercase letter
                                {'\n'}• 1 lowercase letter
                                {'\n'}• 1 number
                            </Text>
                        </View>

                        <FormControl sx={styles.textbox} variant="outlined">
                            <InputLabel htmlFor="password">Password</InputLabel>
                            <OutlinedInput
                                id="password"
                                label={"New Password"}
                                type={showPassword ? 'text' : 'password'}
                                onChange={data => { onChangePassword(data.target.value); setError(''); }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>

                        <FormControl sx={styles.textbox} variant="outlined">
                            <InputLabel htmlFor="confirmPassword">Confirm Password</InputLabel>
                            <OutlinedInput
                                id="confirmPassword"
                                label={"Confirm Password"}
                                type={showConfirmPassword ? 'text' : 'password'}
                                onChange={data => { onChangeConfirmPassword(data.target.value); setError(''); }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleClickShowConfirmPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        <Text style={{ margin: 10, color: 'red' }}>{error}</Text>
                    </View>

                    <View style={styles.buttonContainer}>
                        <TouchableOpacity style={styles.buttonStyle} color="#0d1232" onPress={() => { handleOnSubmit() }}>
                            <Text style={styles.buttonText}>Submit</Text>
                        </TouchableOpacity>
                    </View>

                    <View style={styles.buttonContainer}>
                        <TouchableOpacity style={styles.buttonStyle} color="#0d1232" onPress={() => { navigation.navigate('Login') }}>
                            <Text style={styles.buttonText}>Back</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        </ScrollView>
    );
};

export default ResetTempPassword;
