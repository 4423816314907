import { usersAPI } from './api_settings';
import axios from 'axios';

/**
 * Allows a user to login 
 *
 * @param {Object} data - Data contains following information:
 * - email (string): The users email
 * - password (string): The users password
 *
 */
export const login = async (data) => {
  const info = JSON.stringify({ email: data.email, password: data.password });
  const url_endpoint = usersAPI + 'users/login';

  const header = {
    'Content-Type': 'application/json',
  };

  try {
    const response = await axios.post(url_endpoint, info, { headers: header });
    return response;
  } catch (error) {
    console.log(error.response)
    if (error.response) {
      return error.response;
    } else {
      return {
        statusCode: 500,
        body: 'An unexpected error occurred',
      };
    }
  }
};

/**
 * UNUSED API ENDPOINT
 * 
 * Reason: Took over the project and wasn't notified for the reason API isn't being used.
 * Note: This api is no longer being used but its in AWS Lambda
 * Date: 3/14/2024
 * Author: Reynaldo Martinez
 */
export const confirm_cognito_signup = async (data, token) => {
  const url_endpoint = usersAPI + 'users/confirm_cognito_signup';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  const res = await axios
    .post(url_endpoint, data, { headers: header })
    .then((response) => response.data)
    .catch((error) => console.error(error.message));

  return res;
};


/**
 * Fetches all the specified users details for an Admin Console Permission user.
 * This is used for the update user functionality of the application
 *
 * @param {Object} data - Data contains following information:
 * - user_id (int): The identifier for the specific user we wish to update
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 * 
 * @example 
 * await get_single_user({ user_id: user }, global.token);
 */
export const get_single_user = async (data, token) => {
  const url_endpoint = usersAPI + 'users/get_single_user';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.post(url_endpoint, data, { headers: header });
    return response;
  } catch (error) {
    console.log(error.response)
    if (error.response) {
      return error.response;
    } else {
      return {
        statusCode: 500,
        body: 'An unexpected error occurred',
      };
    }
  }
};


/**
 * Fetches all users in the project mercury
 *
 * @param {Object} data - Data contains following information:
 * - user_id (int): The identifier for the specific user we wish to update
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 * 
 * @example 
 * await get_table_user({ user_id }, global.token)
 */
export const get_table_user = async (data, token) => {
  const url_endpoint = usersAPI + 'users/get_table_user';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.post(url_endpoint, data, { headers: header });
    return response;
  } catch (error) {
    console.log(error.response)
    if (error.response) {
      return error.response;
    } else {
      return {
        statusCode: 500,
        body: 'An unexpected error occurred',
      };
    }
  }
};

/**
 * 
 * UNUSED API ENDPOINT: CALENDAR RELATED FUNCTIONALITY WAS REMOVED
 * 
 * Fetches all the usernames for the people who have been selected as invitees on the calendar,
 * used for displaying purposes when a user selects an event from the calendar
 *
 * @param {Object} data - Data contains following information:
 * - user_ids (Array<int>): The identifier for all users that are inviteed to an any event
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 * 
 * @example 
 * await get_users_names({ "user_ids": [...user_ids] }, global.idToken);
 */
export const get_users_names = async (data, token) => {
  const url_endpoint = usersAPI + 'users/get_users_names';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.post(url_endpoint, data, { headers: header });
    return response;
  } catch (error) {
    console.log(error.response)
    if (error.response) {
      return error.response;
    } else {
      return {
        statusCode: 500,
        body: 'An unexpected error occurred',
      };
    }
  }
};

/**
 * UNUSED API ENDPOINT
 * 
 * Reason: Took over the project and wasn't notified for the reason API isn't being used.
 * Date: 3/14/2024
 * Author: Reynaldo Martinez
 */
export const get_all_users = async (token) => {
  const url_endpoint = usersAPI + 'users/get_all_users';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  const res = await axios
    .get(url_endpoint, { headers: header })
    .then((response) => response.data)
    .catch((error) => console.error(error.message));

  return res;
};

/**
 * UNUSED API ENDPOINT: CALENDAR RELATED FUNCTIONALITY WAS REMOVED
 * 
 * Gets the subordinates of a user. Subordinate meaning the people the user currently manages
 *
 * @param {Object} data - Data contains following information:
 * - user_id (int): Indentifier for the user
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 *
 * @example
 * await get_user_subordinates({'user_id': global.user_data.user_id}, global.token);
 */
export const get_user_subordinates = async (data, token) => {
  const url_endpoint = usersAPI + 'users/get_user_subordinates';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.post(url_endpoint, data, { headers: header });
    return response;
  } catch (error) {
    console.log(error.response)
    if (error.response) {
      return error.response;
    } else {
      return {
        statusCode: 500,
        body: 'An unexpected error occurred',
      };
    }
  }
};

/**
 * Allows a user with Admin Console Permission to add a user to project mercury
 *
 * @param {Object} data - Data contains following information:
 * Required Input:
 * - admin_id (int): This is automatically sent over from the user creating the new account
 * - admin_permissions (Array<int>): This is automatically set by the application
 * - first_name (string): The new users first name
 * - last_name (string): The new users last name
 * - email (string): The new users email
 * - DOD (int): The military identifier for the new user
 * - role_id (int): The role that the new user will have
 * - start_date (string): The date in which they started with the military
 * - rank (string): The rank they currently hold in the military (not reqruied by backend but required by frontend)
 * - grade (string): Automatically set by the rank select (can't be modified)
 * - superior_id (int): Parsed from the 'Superior's Name' select
 * - unit_id (int): Automatically set to 'Unassigned Soldiers' and can't be deselected
 * - incentive_pay (string): Set to None, can't be deselected
 * 
 * Optional Input:
 * - permissions (int): Gray area, its not required but also no permissions '[]' is also a valid entry
 * - branch (string): The branch the new account is associated with
 * - acip_date (string): Only required if the user selects ACIP under incentive_pay
 * - dent_date (string): The date in which they will or had a dental appointment
 * - pha_date (string): The date in which they will or had a physical appointment
 * - mos (string): {not entirely sure what this is}
 * - mpc (string): {not entirely sure what this is}
 * - rcc (string): {not entirely sure what this is}
 * - upc (string): {not entirely sure what this is}
 * - pdlc (string): {not entirely sure what this is}
 * - drc (int): {not entirely sure what this is}
 * - mrc (int): {not entirely sure what this is}
 * - flight_rated (boolean string): Determines if the user has approved flight status
 * - flight_status (string): The status of their flight rating
 * - address_line_1 (string): The address of the soldiers unit
 * - address_line_2 (string): The address of the soldiers unit
 * - city (string): The city of the soldiers unit
 * - state (string): The state of the soldiers unit
 * - country (string): The country of the soldiers unit
 * - zipcode (int): The zipcode of the soldiers unit
 * - phone_number (string): The users phone number
 * - admin_unit_ids (Array<int>): The units that the new account will be an admin for
 * @param {string} token - Auth token to be allowed to invoke API
 *
 * @example
 * await add_user(data, global.token);
 */
export const add_user = async (data, token) => {
  const url_endpoint = usersAPI + 'users/add_user';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.put(url_endpoint, data, { headers: header });
    return response;
  } catch (error) {
    console.log(error.response)
    if (error.response) {
      return error.response;
    } else {
      return {
        statusCode: 500,
        body: 'An unexpected error occurred',
      };
    }
  }
};

/**
 * Allows a user with Admin Console Permission to add a user to project mercury
 *
 * @param {Object} data - Data contains following information:
 * Required Input:
 * - admin_id (int): This is automatically sent over from the user creating the new account
 * - admin_permissions (Array<int>): This is automatically set by the application
 * - first_name (string): The new users first name
 * - last_name (string): The new users last name
 * - email (string): The new users email
 * - DOD (int): The military identifier for the new user
 * - role_id (int): The role that the new user will have
 * - start_date (string): The date in which they started with the military
 * - rank (string): The rank they currently hold in the military (not reqruied by backend but required by frontend)
 * - grade (string): Automatically set by the rank select (can't be modified)
 * - superior_id (int): Parsed from the 'Superior's Name' select
 * - unit_id (int): Automatically set to 'Unassigned Soldiers' and can't be deselected
 * - incentive_pay (string): Set to None, can't be deselected
 * 
 * Optional Input:
 * - permissions (int): Gray area, its not required but also no permissions '[]' is also a valid entry
 * - branch (string): The branch the new account is associated with
 * - acip_date (string): Only required if the user selects ACIP under incentive_pay
 * - dent_date (string): The date in which they will or had a dental appointment
 * - pha_date (string): The date in which they will or had a physical appointment
 * - mos (string): {not entirely sure what this is}
 * - mpc (string): {not entirely sure what this is}
 * - rcc (string): {not entirely sure what this is}
 * - upc (string): {not entirely sure what this is}
 * - pdlc (string): {not entirely sure what this is}
 * - drc (int): {not entirely sure what this is}
 * - mrc (int): {not entirely sure what this is}
 * - flight_rated (boolean string): Determines if the user has approved flight status
 * - flight_status (string): The status of their flight rating
 * - address_line_1 (string): The address of the soldiers unit
 * - address_line_2 (string): The address of the soldiers unit
 * - city (string): The city of the soldiers unit
 * - state (string): The state of the soldiers unit
 * - country (string): The country of the soldiers unit
 * - zipcode (int): The zipcode of the soldiers unit
 * - phone_number (string): The users phone number
 * - admin_unit_ids (Array<int>): The units that the new account will be an admin for
 * @param {string} token - Auth token to be allowed to invoke API
 *
 * @example
 * await update_user(data, global.token);
 */
export const update_user = async (data, token) => {
  const url_endpoint = usersAPI + 'users/update_user';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.put(url_endpoint, data, { headers: header });
    return response;
  } catch (error) {
    console.log(error.response)
    if (error.response) {
      return error.response;
    } else {
      return {
        statusCode: 500,
        body: 'An unexpected error occurred',
      };
    }
  }
};

/**
 * Allows a user with Admin Console Permissions to delete an
 * account from project mercury
 *
 * @param {Object} data - Data contains following information:
 * - user_id (int): The identifier for the user
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 *
 * @example
 * delete_single_file({ user_id: global.user_data.user_id }, global.idToken);
 */
export const delete_users = async (data, token) => {
  const url_endpoint = usersAPI + 'users/delete_users';
  data = JSON.stringify(data);

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.delete(url_endpoint, {
      headers: header,
      data: data
    });
    return response;
  } catch (error) {
    console.log(error.response)
    if (error.response) {
      return error.response;
    } else {
      return {
        statusCode: 500,
        body: 'An unexpected error occurred',
      };
    }
  }
};


/**
 * Gets all possible potential superior users for the creation or modification of
 * a project mercury account
 *
 * @param {Object} data - Data contains following information:
 * - unit_id (int): Indentifier for the unit
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 *
 * @example
 * await get_all_superior_users({'unit_id': global.user_data.unit_id}, global.token);
 */
export const get_all_superior_users = async (data, token) => {
  const url_endpoint = usersAPI + 'users/get_all_superior_users';
  const info = JSON.stringify({ unit_id: data });

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.post(url_endpoint, info, { headers: header });
    return response;
  } catch (error) {
    console.log(error.response)
    if (error.response) {
      return error.response;
    } else {
      return {
        statusCode: 500,
        body: 'An unexpected error occurred',
      };
    }
  }
};

/**
 * Allows a user to update their theme from Light to Dark or vice versa
 *
 * @param {Object} data - Data contains following information:
 * - user_id (int): Indentifier for the user
 * - theme (string): The new theme for the user
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 *
 * @example
 * await update_theme({'user_id': global.user_data.user_id, 'theme': mode === 'light' ? 'dark' : 'light'}, global.token);
 */
export const update_theme = async (data, token) => {
  const url_endpoint = usersAPI + 'users/update_theme';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.put(url_endpoint, data, { headers: header });
    return response;
  } catch (error) {
    console.log(error.response)
    if (error.response) {
      return error.response;
    } else {
      return {
        statusCode: 500,
        body: 'An unexpected error occurred',
      };
    }
  }
};

/**
 * Allows the user to request a cognito confirmation code to reset their password 
 *
 * @param {Object} data - Data contains following information:
 * - email (string): The email for the user
 * 
 * @example 
 * forgot_password_reset({ email: values.email });
 */
export const forgot_password_reset = async (data) => {
  const url_endpoint = usersAPI + 'users/forgot_password_reset';

  const header = {
    'Content-Type': 'application/json',
  };

  try {
    const response = await axios.post(url_endpoint, data, { headers: header });
    return response;
  } catch (error) {
    console.log(error.response)
    if (error.response) {
      return error.response;
    } else {
      return {
        statusCode: 500,
        body: 'An unexpected error occurred',
      };
    }
  }
};

/**
 * Allows the user to reset their password on the application with the confirmation code
 * sent from cognito
 *
 * @param {Object} data - Data contains following information:
 * - email (string): The email for the user
 * 
 * @example 
 * confirm_forgot_password({ email: props.route.params.email, confirmation_code: values.code, new_password: values.password });
 */
export const confirm_forgot_password = async (data) => {
  const url_endpoint = usersAPI + 'users/confirm_forgot_password';

  const header = {
    'Content-Type': 'application/json',
  };

  try {
    const response = await axios.post(url_endpoint, data, { headers: header });
    return response;
  } catch (error) {
    console.log(error.response)
    if (error.response) {
      return error.response;
    } else {
      return {
        statusCode: 500,
        body: 'An unexpected error occurred',
      };
    }
  }
};

/**
 * When a user is first created this endpoint is responsible for verifying the user
 * with the verfication code sent from cognito and saving the new password for the user
 *
 * @param {Object} data - Data contains following information:
 * - email (string): The email for the user
 * - new_password (string): A passowrd the conforms to our policy 
 * 
 * @example 
 * first_login_password_change({ email: props.route.params.email, new_password: values.password });
 */
export const first_login_password_change = async (data) => {
  const url_endpoint = usersAPI + 'users/first_login_password_change';

  const header = {
    'Content-Type': 'application/json'
  }

  try {
    const response = await axios.post(url_endpoint, data, { headers: header });
    return response;
  } catch (error) {
    console.log(error.response)
    if (error.response) {
      return error.response;
    } else {
      return {
        statusCode: 500,
        body: 'An unexpected error occurred',
      };
    }
  }
};

/**
 * UNUSED API ENDPOINT
 * 
 * Reason: Took over the project and wasn't notified for the reason API isn't being used.
 * Note: This api is no longer being used but its in AWS Lambda
 * Date: 3/14/2024
 * Author: Reynaldo Martinez
 */
export const get_user_permissions = async (data, token) => {
  const url_endpoint = usersAPI + 'users/get_user_permissions';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token
  };

  let res = await axios
    .post(url_endpoint, data, { headers: header })
    .then((response) => response.data)
    .catch((error) => console.error(error.message));

  console.log(res);

  return res;
};

/**
 * Updates the users password, 
 *
 * @param {Object} data - Data contains following information:
 * - user_id (int): Identifier for the user
 * - email (string): The users email
 * - passowrd (string): The users new password
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 * 
 * @example 
 * update_user_password(data, global.idToken);
 */
export const update_user_password = async (data, token) => {
  const url_endpoint = usersAPI + 'users/update_user_password';

  const header = {
      'Content-Type': 'application/json',
      'Authorization': token
  }

  try {
      const response = await axios.put(url_endpoint, data, { headers: header });
      return response;
  } catch (error) {
      console.error(error.response);
      if (error.response) {
          return error.response;
      } else {
          return {
              statusCode: 500,
              body: 'An unexpected error occurred',
          };
      }
  }
};
/**
 * Updates the users signature, 
 *
 * @param {Object} data - Data contains following information:
 * - user_id (int): Identifier for the user
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 * 
 * @example 
 * create_new_user_signature(data, global.idToken);
 */
export const create_new_user_signature = async (data, token) => {
  const url_endpoint = usersAPI + 'users/create_new_user_signature';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.post(url_endpoint, data, { headers: header });
    return response;
  } catch (error) {
    console.log(error.response)
    if (error.response) {
      return error.response;
    } else {
      return {
        statusCode: 500,
        body: 'An unexpected error occurred',
      };
    }
  }
};

/**
 * Updates the users location, should be noted that this location isn't actually
 * the users location but rather the location of the unit they are apart of
 *
 * @param {Object} data - Data contains following information:
 * - city (string): The city where the unit resides
 * - state (string): The state where the unit resides
 * - address_line_1 (string): The address where the unit resides
 * - address_line_2 (string):
 * - zipcode (string): The zipcode where the unit resides
 * - user_id (int): Identifier for the user
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 * 
 * @example 
 * update_user_location(data, global.idToken);
 */
export const update_user_location = async (data, token) => {
  const url_endpoint = usersAPI + 'users/update_user_location';

  const header = {
      'Content-Type': 'application/json',
      'Authorization': token
  }

  try {
      const response = await axios.put(url_endpoint, data, { headers: header });
      return response;
  } catch (error) {
      console.error(error.response);
      if (error.response) {
          return error.response;
      } else {
          return {
              statusCode: 500,
              body: 'An unexpected error occurred',
          };
      }
  }
};