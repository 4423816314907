import React, {useEffect, useReducer, useRef, useState, useMemo} from 'react';
import {View, Text, ScrollView} from 'react-native';
// import Modal from "react-native-modal";

import {useIsFocused} from '@react-navigation/native';
import {useScale} from '../../../components/scale';
import CustomHeader from '../../../components/adminHeader';
import {get_all_unit_logs, get_all_unit_names} from '../../../api_handler/units';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import {darken, createTheme, ThemeProvider, useTheme} from '@mui/material/styles';

import ModeContext from '../../../components/ModeContext';
import {useColorContext} from '../../../components/ColorContext';

import { DateRangePicker, Stack } from 'rsuite';
import { Platform } from 'react-native';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';

import * as Progress from 'react-native-progress'

import dayjs from 'dayjs';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';

import {MaterialReactTable, MRT_FullScreenToggleButton, MRT_ToggleGlobalFilterButton, MRT_ShowHideColumnsButton, MRT_ToggleFiltersButton} from 'material-react-table';
import {ExportToCsv} from 'export-to-csv';

import DownloadIcon from '@mui/icons-material/Download';
import NavSideBar from '../../../components/NavSideBar';
import Scrollbars from 'react-custom-scrollbars';
import { useSnackbar } from '../../../components/SnackbarContext';

export default function Users({navigation}) {
  const { showSnackbar } = useSnackbar();
  const [columnVisibility, setColumnVisibility] = useState({});
  const [density, setDensity] = useState('compact');
  const [pagination, setPagination] = useState({pageIndex: 0, pageSize: 15});
  const [showColumnFilters, setShowColumnFilters] = useState(false);
  const [showGlobalFilter, setShowGlobalFilter] = useState(false);
  const isFocused = useIsFocused();
  const tableInstanceRef = useRef(null);
  const rerender = useReducer(() => ({}), {})[1]; // Forces the MRT_ components to re-render
  const {mode} = React.useContext(ModeContext);

  const [rows, setRows] = React.useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [unitSelection, setUnitSelection] = useState([]);
  const [unitType, setUnitType] = useState([]);
  const [unitNames, setUnitNames] = React.useState([]);
  const [currentRows, setCurrentRows] = React.useState([]);

  const [dates, setDates] = useState([
    new Date(new Date().setHours(0, 0, 0, 0)),
    new Date(new Date().setHours(23, 59, 59, 999)),
  ]);
  
  const {colors} = useColorContext();
  const [isLight, setIsLight] = useState(mode === 'light')
  const [isLoading, setIsLoading] = useState(true);

  // Retrieve all unit names and users when page is entered
  useEffect(() => {
    const fetchUnitNamesAndLogs = async () => {
      let initialUnit = -1;
      if (unitNames.length === 0) {
        initialUnit = await fetchUnitNames();
      }
      await fetchUnitLogs(initialUnit);
    };

    const loadDataAndStyles = async () => {
      setIsLoading(true);
      await Promise.all([fetchUnitNamesAndLogs(), importCSS()]);
      setIsLoading(false);
    }

    // Check that the user is entering the page (not exiting)
    if (isFocused) {
      loadDataAndStyles();
    }
  }, [isFocused]);

  useEffect(() => {
    if (global.user_data.theme === 'light') {
      setIsLight(true);
    } else {
      setIsLight(false);
    }
  }, [global.user_data.theme, isLight]);


  const predefinedRanges = [
    {
      label: 'Today',
      closeOverlay: false,
      value: [new Date(), new Date()],
      placement: 'left'
    },
    {
      label: 'Yesterday',
      closeOverlay: false,
      value: [addDays(new Date(), -1), addDays(new Date(), -1)],
      placement: 'left'
    },
    {
      label: 'Last 30 days',
      closeOverlay: false,
      value: [subDays(new Date(), 29), new Date()],
      placement: 'left'
    },
    {
      label: 'This month',
      closeOverlay: false,
      value: [startOfMonth(new Date()), new Date()],
      placement: 'left'
    },
    {
      label: 'Last month',
      closeOverlay: false,
      value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
      placement: 'left'
    },
    {
      label: 'This year',
      closeOverlay: false,
      value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
      placement: 'left'
    },
    {
      label: 'Last year',
      closeOverlay: false,
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
      placement: 'left'
    },
    {
      label: 'Last week',
      closeOverlay: false,
      value: value => {
        const start = new Date();
        return [
          startOfWeek(addDays(start, -7), { weekStartsOn: 0 }),
          endOfWeek(addDays(start, -7), { weekStartsOn: 0 })
        ];
      },
      appearance: 'default'
    },
    {
      label: 'This week',
      closeOverlay: false,
      value: value => {
        const start = new Date();
        return [
          startOfWeek(start, { weekStartsOn: 0 }),
          endOfWeek(start, { weekStartsOn: 0 })
        ];
      },
      appearance: 'default'
    },
    {
      label: 'Next week',
      closeOverlay: false,
      value: () => {
        const today = new Date();
        const nextWeek = addDays(today, 7);
        return [
          startOfWeek(nextWeek, { weekStartsOn: 0 }),
          endOfWeek(nextWeek, { weekStartsOn: 0 })
        ];
      },
      appearance: 'default'
    }
  ];

  if(Platform.OS === 'web') {
    require('rsuite/dist/rsuite.min.css');
  }

  // Create theme styling for table
  const globalTheme = useTheme();
  const tableTheme = useMemo(
      () =>
        createTheme({
          palette: {
            mode: mode,
            primary: globalTheme.palette.primary,
            background: {
              default:  colors.textfield_background,
            },
          },
        }),
      [globalTheme, isFocused],
  );

  const scale = useScale();
  const styles = ({
    tableContainer: {
      width: '90%',
      marginLeft: '5%',
      borderColor: colors.border_color,
      borderWidth: global.user_data.theme === 'dark' ? 1 : 0,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
    },
    tableWrapper: {
      height: '75%', 
      width: '100%', 
      justifyContent: 'center', 
      marginTop: '2%'
    },
    container: {
      flex: 1,
      backgroundColor: colors.screen_background,
      flexDirection: 'row',
      height: '100%'
    },
    scrollView: {
      backgroundColor: colors.screen_background,
      flex: 10,
    },
    title: {
      fontSize: scale(30),
      fontFamily: 'Trebuchet MS',
      flex: 1,
      color: colors.text,
      paddingLeft: '5%',
      paddingTop: 25
    },
    selectAllbutton: {
      fontFamily: 'Trebuchet MS',
      color: mode == 'dark' ? colors.white : colors.black,
      borderColor: colors.button_border,
      borderWidth: 1,
      borderRadius: 10,
      backgroundColor: colors.button,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
  },
    descriptionAndFilterContainer: {
      flexDirection: 'row', 
      marginTop: '1%', 
      alignItems: 'flex-start',
      marginRight: '5%',
      description: {
          fontFamily: 'Trebuchet MS', 
          fontSize: '18px', 
          color: colors.text,
          paddingLeft: '5%', 
          paddingRight: '2%', 
          flex: 1
      },
      filterNote: {
          paddingLeft: '1%', 
          fontFamily: 'Trebuchet MS', 
          paddingTop: '1%',
          color: colors.text
      },
      dateWrapper: {
        width: '35%', 
        textAlign: 'center', 
        marginLeft: '3%'
      },
      filterWrapper: {
        width: '50%', 
        textAlign: 'center',
        height: '10%'
      }
    },
    unitsFilter: {
      flex: 1,
    },
    filtersTextBox: {
      width: '100%',
      backgroundColor: colors.textfield_background,
      height: '80%'
    },
    header: {
      height: '150px',
    },
    main: {
      height: '100%',
      width: '100%',
    },
    titleDiv: {
      flexDirection: 'row',
      width: '100%',
      alignItems: 'center',
    },
    buttonDiv: {
      flexDirection: 'row',
      // backgroundColor: 'red',
      height: '10%',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-around',
    },
    topContainer: {
      flexDirection: 'row',
    },
    tableView: {
      width: '100%',
      alignItems: 'center',
      height: '80%',
    },
    tableDiv: {
      marginTop: '0%',
      backgroundColor: 'white',
      width: '90%',
      alignItems: 'center',
    },
    buttons: {
      width: '15%',
      backgroundColor: '#0d1232',
      borderRadius: '40',
      alignItems: 'center',
      color: 'white',
      borderRadius: 15,
      shadowColor: '#171717',
      shadowOffset: {width: -2, height: 4},
      shadowOpacity: 0.2,
      shadowRadius: 3,
      marginTop: 30,
      marginBottom: 30,
    },
    innerText: {
      color:  colors.button_text,
      fontWeight: 'bold',
      padding: 10,
    },
    modal: {
      backgroundColor: 'white',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 30,
    },
    modalText: {
      width: '60%',
      fontSize: scale(25),
      fontWeight: 'bold',
      textAlign: 'center',
      marginTop: '5%',
      marginBottom: '5%',
    },
    image: {
      flex: 1,
      width: '15%',
      resizeMode: 'contain',
    },
    modalButtonContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      width: '60%',
    },
    modalButton: {
      flex: 1,
      padding: '7px 30px 7px 30px',
      width: '20%',
    },
    buttonStyle: {
      width: '30%',
      backgroundColor: '#0d1232',
      borderRadius: '40',
      alignItems: 'center',
      color: 'white',
      borderRadius: 15,
      shadowColor: '#171717',
      shadowOffset: {width: -2, height: 4},
      shadowOpacity: 0.2,
      shadowRadius: 3,
      padding: 5,
      marginBottom: '5%',
    },
    buttonText: {
      color: 'white',
      fontSize: scale(25),
    },
    textbox: {
      width: '100%'
    },
    checkBox: {
      height: '24px',
      color:  colors.inputted_text,
    },
    listItemTextTypography: {
      fontSize: '1rem',
    },
    textInputProps: {
      '& .MuiFormLabel-root, & label.Mui-focused, & .MuiInputBase-input': {
          color: colors.inputted_text,
          // WebkitTextFillColor:  colors.inputted_text ,
          fontFamily: 'Trebuchet MS',

      },
      '.MuiSvgIcon-root': {
          fill: colors.inputted_text,
          fontFamily: 'Trebuchet MS',
      },
      '& .MuiOutlinedInput-root': {
          '&:hover fieldset': {
              borderColor: colors.border_color,
              fontFamily: 'Trebuchet MS',
          },
          '&.Mui-focused fieldset': {
              borderColor: colors.border_color,
              fontFamily: 'Trebuchet MS',
          },
          '& fieldset': {
              borderColor: colors.border_color,
          },
      },
      "&  .MuiFormHelperText-root": {
          backgroundColor: colors.screen_background,
          fontFamily: 'Trebuchet MS',
          margin: 0,
          paddingLeft: 1
      },
      // .MuiFormHelperText-root.Mui-error
    },
    dateBox: {
      'width': '100%',
      'backgroundColor':  colors.textfield_background,
      '& .MuiFormLabel-root, & label.Mui-focused, & .MuiInputBase-input': {
        color:  colors.inputted_text,
      },
      '.MuiSvgIcon-root ': {
        fill:  colors.inputted_text,
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor:  colors.field_set,
        },
        '&:hover fieldset': {
          borderColor: colors.form_border,
        },
        '&.Mui-focused fieldset': {
          borderColor: '#1876d2',
        },
      },
      '& .MuiFormLabel-root.Mui-error': {
        color:  colors.placeholder_text,
      },
      '& .MuiFormHelperText-root.Mui-error': {
        backgroundColor:  colors.screen_background,
        color: '#d32f2f',
        margin: 0,
        paddingLeft: 1,
      },
    },
    selectProps: {
      MenuProps: {
        sx: {
          '& .MuiMenu-paper': {
            background:  colors.textfield_background,
            color:  colors.inputted_text,
            
          },
        },
      },
    },
    dateRangePickerContainer: {
      flex: 1,
      marginBottom: '3.3%',
      paddingHorizontal: '2%',
    },
  });

  const columns = [
    {
      accessorFn: (row) => formatTableDate(row.timestamp),
      accessorKey: 'timestamp',
      header: 'Timestamp',
      size: 1,
      sortingFn: (a, b) => {
        const dateA = new Date(a.original.timestamp);
        const dateB = new Date(b.original.timestamp);
        return dateA.getTime() - dateB.getTime();
      },
    },
    {
      accessorKey: 'action',
      header: 'Action',
      size: 1,
    },
    {
      accessorKey: 'dod',
      header: 'DOD',
      size: 1,
    },
    {
      accessorKey: 'admin',
      header: 'Admin',
      size: 1,
    },
    {
      accessorKey: 'unit_name',
      header: 'Unit',
      size: 1,
    },
  ];

  function formatTableDate(inputDateString) {
    const inputDate = new Date(inputDateString);

    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit', // Use "2-digit" for 24-hour format
      minute: '2-digit',
      timeZoneName: 'short',
      hour12: false, // Ensure 24-hour format
    };

    return inputDate.toLocaleString(undefined, options);
  }

  const importCSS = async () => {
    if (isLight) {
      await import('../../../styles/dateRangePickerLight.css');
    } else {
      await import('../../../styles/dateRangePickerDark.css');
    }
  };

  // Retrieves all users data and set initial rows
  const fetchUnitLogs = async (initialUnit) => {
    try {
      const unit_logs = await get_all_unit_logs({ unit_ids: global.user_data.admin_unit_ids }, global.token);
      if (unit_logs.status === 200) {
        setRows(unit_logs.data.body);
  
        if (initialUnit != -1) {
          setUnitType([initialUnit]);
        } else {
          setUnitType([global.user_data.unit_id]);
        }
      } else {
        showSnackbar("error", "Application Error: Failed to retrieve unit logs for all units")
        setRows([]);
        setUnitType([]);
      }

      
    } catch (error) {
      console.error('Error fetching unit users:', error);
    }
  };

  // Retrieves all unit names and filter admin access
  const fetchUnitNames = async () => {
    try {
      const unit_names_JSON = {
        'unit_id': 0,
        'get_top_unit': true,
        'get_path': true,
      };

      // Retrieve all units and eliminate the ones the user does not have access to
      const unit_names_res = await get_all_unit_names(unit_names_JSON, global.token);
      if (unit_names_res.status !== 200) {
        showSnackbar("error", "Application Error: Failed to retrieve all units")
        return;
      }

      const unit_ids_set = new Set(global.user_data.admin_unit_ids);
      const allUnitsOption = { unit_id: 'all', unit_name: 'All Units' };
      const filteredUnits = unit_names_res.data.body.filter((unit) => unit_ids_set.has(unit.unit_id));
  
      if (filteredUnits.length > 1) {
        setUnitNames([allUnitsOption, ...filteredUnits]);
        setUnitSelection([allUnitsOption, ...filteredUnits]);
      } else {
        setUnitNames([...filteredUnits]);
        setUnitSelection([...filteredUnits]);
      }
      setUnitType([global.user_data.unit_id]);

      return global.user_data.unit_id;
    } catch (error) {
      console.error('Error: ', error);
    }
  };

  function csvFileName() {
    const now = new Date();

    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Month is zero-based
    const year = now.getFullYear();

    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}-${minutes}`;
  }

  const csvOptions = {
    filename: csvFileName(),
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: ['Timestamp', 'Action', 'Admin', 'DOD', 'Unit Name', 'Target'],
  };

  const csvExporter = new ExportToCsv(csvOptions);

  // Retrieve excel columns in correct order
  const handleExportData = () => {
    const desiredOrder = ['timestamp', 'action', 'admin', 'dod', 'unit_name', 'target'];

    const columnsInDesiredOrder = currentRows.map((item) => {
      const obj = {};
      desiredOrder.forEach((header) => {
        if (header == 'timestamp') {
          obj[header] = formatTableDate(item[header]);
        } else {
          obj[header] = item[header];
        }
      });
      return obj;
    });

    csvExporter.generateCsv(columnsInDesiredOrder);
  };

  const filterRows = (unitType, rows, dates) => {
    return unitType.reduce((result, unitId) => {
      if (unitId === 'all') {
        for (const unitRows of Object.values(rows)) {
          for (const [dateString, row] of Object.entries(unitRows)) {
            const [year, month, day] = dateString.split('-').map(Number);
            const submittedDate = new Date(Date.UTC(year, month - 1, day + 1));
            submittedDate.setHours(0, 0, 0, 0);
            if (submittedDate >= dates[0] && submittedDate <= dates[1]) {
              result = result.concat(row);
            }
          }
        }
      } else if (rows[unitId] !== undefined) {
        const unitRows = rows[unitId];
        for (const [dateString, row] of Object.entries(unitRows)) {
          const [year, month, day] = dateString.split('-').map(Number);
          const submittedDate = new Date(Date.UTC(year, month - 1, day + 1));
          submittedDate.setHours(0, 0, 0, 0);
          if (submittedDate >= dates[0] && submittedDate <= dates[1]) {
            result = result.concat(row);
          }
        }
      }
      return result;
    }, []);
  };
  
  const handleAdminUnitsChange = (selectedValues) => {
    const allUnitsIndex = selectedValues.indexOf('all');
    const previousAllUnitsIndex = unitType.indexOf('all');
    let updatedValues = [...selectedValues];
  
    if (allUnitsIndex !== -1 && previousAllUnitsIndex === -1 && selectedValues.length > 1) {
      updatedValues = ['all'];
    } else if (previousAllUnitsIndex !== -1 && selectedValues.length > 1) {
      updatedValues = selectedValues.filter(value => value !== 'all');
    }
  
    setUnitType(updatedValues);
  };
  
  const handleDateRangeChange = (dateRanges) => {
    if (dateRanges) {
      const startDate = new Date(dateRanges[0]);
      startDate.setHours(0, 0, 0, 0);
      const endDate = new Date(dateRanges[1]);
      endDate.setHours(23, 59, 59, 999);
      setDates([startDate, endDate]);
    } else {
      const today = new Date();
      setDates([
        new Date(today.setHours(0, 0, 0, 0)),
        new Date(today.setHours(23, 59, 59, 999)),
      ]);
    }
  };
  
  useEffect(() => {
    const filteredRows = filterRows(unitType, rows, dates);
    setCurrentRows(filteredRows);
  }, [unitType, rows, dates]);

  const loadingStyles = {
    animation: {
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
        justifyContent: 'center',
        paddingVertical: 20,
        background: colors.screen_background,
    }
  }

  return (
    <Scrollbars showsverticalscrollindicator="true"style={styles.scrollView}>
      <View style={styles.container}>
        {/* <NavSideBar/> */}
        {isLoading ? (
          <View style={loadingStyles.animation}>
            <Progress.CircleSnail
              color={colors.loading_circle}
              indeterminate={true}
              size={275}
              thickness={8}
            />
          </View>
        ) : (
          <View style={styles.main}>
            <View style={styles.titleDiv}>
              <Text style={styles.title}>
                <Text style={{fontWeight: 'bold'}}>Admin Console {'>'} </Text> 
                <Text>Logs</Text>
              </Text>
            </View>
            <View style={styles.descriptionAndFilterContainer}>
              <Text style={styles.descriptionAndFilterContainer.description}> 
                This page displays all Admin Console logs. Any actions taken by Admins 
                on Admin Console such as adding a Unit, deleting a User, adding bulk users 
                via a CSV Upload, or making permission changes are logged which can be viewed 
                here using Unit and Date Filters.
              </Text>
              
              <View style={{flex: 1}}>
                {(unitSelection.length > 0) ? (
                    <View style={styles.topContainer}>
                      <View style={styles.dateRangePickerContainer}>
                        <DateRangePicker
                          size="lg"
                          format="MM/dd/yyyy"
                          character=" - "
                          placeholder="Select Date Range"
                          ranges={predefinedRanges}
                          className={`custom-daterange-picker ${isLight ? 'light-mode' : 'dark-mode'}`}
                          onChange={(dates) => handleDateRangeChange(dates)}
                          placement='autoVerticalStart'
                        />
                        <Text style={styles.descriptionAndFilterContainer.filterNote}>
                          Only logs from above selected date are displayed in the table
                        </Text>
                      </View>
                      {/* <View style={styles.descriptionAndFilterContainer.filterWrapper}> */}
                      <View style={styles.unitsFilter}>
                        <FormControl style={styles.textbox} sx={styles.textInputProps}>
                          <InputLabel id="tables-units-label">Units Filter</InputLabel>
                          <Select
                            inputProps={styles.selectProps}
                            multiple
                            value={unitType}
                            onChange={(value) => {
                              handleAdminUnitsChange(value.target.value);
                            }}
                            input={
                              <OutlinedInput 
                                style={{height: '100%', fontSize: '16px'}} 
                                label="Units Filter"
                                inputProps={{
                                  style: {
                                    display: 'flex',
                                    alignItems: 'center',
                                  },
                                }} 
                              />
                            }
                            style={{backgroundColor: colors.textfield_background, height: '43px' }}
                            renderValue={(selected) => {
                              const getSelectedLastComponent = (selectedName) => {
                                const nameComponents = selectedName.split(' -> ');
                                return nameComponents[nameComponents.length - 1];
                              };
                              return (
                                selected.map((value) => (getSelectedLastComponent(unitSelection.find((item) => item.unit_id === value).unit_name))).join(', ')
                              );
                            }}
                          >
                            {unitSelection.map((option) => (
                              <MenuItem style={{whiteSpace: 'pre'}} key={option.unit_id} value={option.unit_id}>
                                <Checkbox
                                  sx= { styles.checkBox }
                                  checked={unitType.indexOf(option.unit_id) > -1}
                                />
                                <ListItemText primaryTypographyProps={{style: styles.listItemTextTypography}} primary={option.unit_name} />
                              </MenuItem>
                            ))}
                          </Select>

                        </FormControl>
                        <Text style={styles.descriptionAndFilterContainer.filterNote}>
                          Only logs generated by admins from the above selected units are displayed in the table
                        </Text>
                      </View>
                    </View>
                  ) : (
                    null
                )}
              </View>
            </View>
            
            
            <Scrollbars style={styles.tableWrapper} >
              <View style={styles.tableContainer}>
                <ThemeProvider theme={tableTheme}>
                  <MaterialReactTable
                    columns={columns}
                    data={currentRows}
                    positionToolbarAlertBanner={'bottom'}
                    tableInstanceRef={tableInstanceRef}
                    enableGrouping
                    enableColumnDragging={false}
                    enableColumnFilterModes
                    enableStickyHeader
                    isMultiSortEvent={() => true}
                    renderDetailPanel={({row}) => {
                      return (
                        <View>
                          <Typography>{row.original.target}</Typography>

                        </View>
                      );
                    }}

                    initialState={{
                      sorting: [{id: 'timestamp', desc: true}],
                      showGlobalFilter: false,
                      columnOrder: ['mrt-row-expand', 'timestamp', 'action', 'admin', 'dod', 'unit_name'],
                    }}
                    muiTableBodyProps={{
                      sx: (theme) => ({
                        '& tr:nth-of-type(4n)': {
                          backgroundColor:  colors.table_background_color,
                        },
                        '& tr:nth-of-type(4n - 2), & tr:nth-of-type(4n - 3)': {
                          backgroundColor: darken(theme.palette.background.default, 0.075),
                        },
                        '& tr.Mui-selected': {
                          backgroundColor:  colors.table_selected,
                        },
                        '& tr:hover:not(.Mui-selected) > td': {
                          backgroundColor:  colors.table_hover,
                        },
                      }),
                    }}

                    // Table State Management for updater function
                    onColumnVisibilityChange={(updater) => {
                      setColumnVisibility((prev) =>
                                            updater instanceof Function ? updater(prev) : updater,
                      );
                      queueMicrotask(rerender); // Rerender after state update
                    }}

                    onRowSelectionChange={(updater) => {
                      setRowSelection((prev) =>
                                            updater instanceof Function ? updater(prev) : updater,
                      );
                      queueMicrotask(rerender); // Rerender after state update
                    }}

                    onPaginationChange={(updater) => {
                      setPagination((prev) =>
                                            updater instanceof Function ? updater(prev) : updater,
                      );
                      queueMicrotask(rerender); // Rerender after state update
                    }}

                    onShowColumnFiltersChange={(updater) => {
                      setShowColumnFilters((prev) =>
                                            updater instanceof Function ? updater(prev) : updater,
                      );
                      queueMicrotask(rerender); // Rerender after state update
                    }}

                    onShowGlobalFilterChange={(updater) => {
                      setShowGlobalFilter((prev) =>
                                            updater instanceof Function ? updater(prev) : updater,
                      );
                      queueMicrotask(rerender); // Rerender after state update
                    }}

                    state={{
                      columnVisibility,
                      density,
                      rowSelection,
                      pagination,
                      showColumnFilters,
                      showGlobalFilter,
                    }}
                    renderToolbarInternalActions={({table}) => (
                      <>
                        <Box>
                          <MRT_ToggleGlobalFilterButton table={tableInstanceRef.current} />

                          <Tooltip arrow title="Export Table">
                            <IconButton onClick={handleExportData}
                            >
                              <DownloadIcon/>
                            </IconButton>
                          </Tooltip>

                          <MRT_ToggleFiltersButton table={tableInstanceRef.current} />
                          <MRT_ShowHideColumnsButton table={tableInstanceRef.current} />
                          <MRT_FullScreenToggleButton table={tableInstanceRef.current} />

                        </Box>
                      </>
                    )}
                  />
                </ThemeProvider>
              </View>
            </Scrollbars>
          </View>
        )}
      </View>
    </Scrollbars>
  );
};
