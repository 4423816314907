import React, {useEffect, useState, useCallback} from 'react';
import {Searchbar} from 'react-native-paper';
import {View, Text, Image, TouchableOpacity, ScrollView} from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import {useScale} from '../../components/scale';

import {AntDesign} from '@expo/vector-icons';
import CustomHeader from '../../components/header';
import moment from 'moment';
import { useColorContext } from '../../components/ColorContext';

import {get_recommend_files} from '../../api_handler/files';
import {createTheme, styled, useTheme,ThemeProvider} from '@mui/material/styles';
import {Pagination, Box} from '@mui/material';
import NavSideBar from '../../components/NavSideBar';
import { getFileDate, formatRstDisplayDate, formatDateToMilDate} from '../../components/FileDataFunctions';
import Scrollbars from "react-custom-scrollbars";
import { useSnackbar } from '../../components/SnackbarContext';

function RecommendRSTHome({navigation}) {
  const {colors} = useColorContext();
  const { showSnackbar } = useSnackbar();
  const scale = useScale();
  const styles = ({
    container: {
      height: '100%',
      width: '100%',
      flexDirection: 'row',
      backgroundColor: colors.screen_background,
    },
    main: {
      flex:10,
      height: '100%'
    },
    titleDiv: {
      flexDirection: 'row',
      width: '100%',
      alignItems: 'center',
    },
    title: {
      fontSize: scale(30),
      fontFamily: 'Trebuchet MS',
      flex: 1,
      color: colors.text,
      paddingLeft: '5%',
      paddingTop: 25
    },
    titleAndDescription: {
      title: {
        fontSize: scale(30),
        fontFamily: 'Trebuchet MS',
        flex: 1,
        color: colors.text,
        paddingLeft: '5%',
        paddingTop: 25
      },
      description: {
        fontSize: 18,
        marginHorizontal: 5,
        textAlign: 'Left',
        paddingTop: 25,
        color:  colors.bow,
        width: '50%',
        fontFamily: 'Trebuchet MS',
        marginBottom: '2%'
      },
    },
    contentBox: {
      backgroundColor: colors.content_box,
      height: '100%',
      width: "90%",
      marginLeft: '5%',
      marginTop: "2%",
      borderRadius: 10,
      justifyContent: "center",
      padding: '2.5%',
    },
    button: {
      height: 80,
      borderRadius: 10,
      padding: 10,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: colors.wob,
      margin: 8,
      marginBottom: 15,
      borderColor: colors.button_border,
      borderWidth: 1,
      shadowOffset: {width: 0, height: 2},
      shadowOpacity: 0.25,
      shadowRadius: 4,
      flexDirection: 'row',
      alignItems: 'center',
      flex: 1,
    },
    textStyle: {
      textAlign: 'center',
      fontSize: 15,
      fontWeight: 'bold',
      color: colors.text,
    },
    backButton: {
      height: 50,
      width: '30%',
      borderRadius: 10,
      alignItems: 'center',
      justifyContent: 'center',
      borderColor: 'black',
      borderWidth: 1,
      shadowOffset: {width: 0, height: 2},
      shadowOpacity: 0.25,
      shadowRadius: 4,
      backgroundColor: colors.button,
    },
    searchBar: {
      width: "50%",
      fontSize: 15,
      alignItems: "space-evenly",
      borderColor: "black",
      borderRadius: 5,
      borderWidth: 1,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      alignSelf: 'flex-start',
      marginLeft: '50%',
      backgroundColor: colors.modal_background,
      color: colors.text,
    },
    selected: {
      backgroundColor: colors.gold,
    },
    doc: {
      width: 40,
      height: 40,
    },
  });

  const [files, setFiles] = useState([]);
  const [selected, setSelected] = useState(-1);
  const [loaded, setLoaded] = useState(false);
  const [allFiles, setAllFiles] = useState([]);
  const [searchedFiles, setSearchedFiles] = useState([]);
  const [search, setSearch] = useState('');

  const theme = useTheme();
  const reviewTheme = createTheme({
    palette: {
      mode: colors.mode,
      primary: theme.palette.primary,
    },
  });

  // For pagination
  const ITEM_LIMIT = 5;
  const [currentPage, setCurrentPage] = useState(1);

  async function getFiles() {
    setFiles([]);

    const recommendFiles = await get_recommend_files({
      'user_id': global.user_data.user_id,
      'permissions': global.user_data.permissions
    },
    global.token,
    );
    
    if (recommendFiles.status === 200) {
      
      const filtered = recommendFiles.data.body.filter(
          (file) => file.status < 2 &&
                file.recommender_id === global.user_data.user_id &&
                file.form_type.toLowerCase() === 'rst',
      );

      if (filtered.length === 0) {
        showSnackbar('warning', 'You have no files to recommend');
        goBack();
        return;
      }

      //after we know there is more than 1, we will get the file date and sort based on it!
      const parsedFiles = filtered.map(file => ({
      ...file,
      parsedDate: getFileDate(file)
      }))

      parsedFiles.sort((a, b) => b.parsedDate - a.parsedDate);

      setFiles(parsedFiles)
      setAllFiles(parsedFiles);
      setSearch('');
      setSelected(-1);
      setLoaded(true);
    } else {
      showSnackbar("error", "Application Error: Failed to retrieve potential recommend files");
      goBack();
    }
  }

  const getCommentForFormType = useCallback((form) => {
    switch (form.form_type) {
        case 'RST': return form.rst_approver_comment;
        case 'EBDL': return form.ebdl_approver_comment;
        case '1380': return form.approver_comment_1380;
        case '1380 aftp': return form.approver_comment_1380;
        case 'ATA': return form.ata_approver_comment;
        case 'MDR': return form.mdr_approver_comment;
        default: return '';
    }
  }, []);

  useFocusEffect(
      React.useCallback(() => {
        async function getPageInfo() {
          await getFiles();
        }

        getPageInfo();
        setLoaded(true);
      }, []),
  );

  function sendFileID(form_id, form_type, attachments, creator_id) {
    const data = {
      fileID: form_id,
      form_type: form_type,
      attachments: attachments,
      creator_id: creator_id
    };
    console.log("Data: " + JSON.stringify(data));

    // check if file exists
    if (form_id != -1) {
      navigation.navigate('RecommendRST', data);
    } else {
      //alert('Please select a file');
      showSnackbar('errorr', 'Please select a file');
    }
  }

  const updateSearch = (search) => {
    setSearch(search);
    if (search === '') {
      setAllFiles(files);
    } else {
      setAllFiles(files.filter((file) => file.form_name.toLowerCase().includes(search.toLowerCase())));
    }
  };

  const getCurrentFiles = () =>{
    const offset = (currentPage - 1) * ITEM_LIMIT;
    const currentFiles = allFiles.slice(offset, offset + ITEM_LIMIT);

    setSearchedFiles(currentFiles);
  };

  const goBack = () => {
    navigation.navigate('Home');
  };

  function select(id) {
    setSelected(id);
  };

  const handlePage = (event, page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    getCurrentFiles();
  }, [currentPage, allFiles]);

  return (
    <View style={styles.container}>
    {/* <NavSideBar/> */}
    {!global.user_data.permissions?.includes(3001) ? (
        <View style={styles.main}>
            <View style={styles.titleDiv}>
                <Text style={styles.title}>Access Denied: User does not have permission to view this page.</Text>
            </View>
        </View>
      ) : (
        <Scrollbars style={styles.main}>
          <View style={{flexDirection: 'column'}}>
            <Text style={styles.titleAndDescription.title}>
              Recommend RST as First Line Leader
            </Text>
            <View style={{flexDirection: 'row', paddingLeft: '5%', paddingRight: '5%'}}>
              <Text style={{...styles.titleAndDescription.description, flex: 1}}> 
                You can recommend RSTs to another member in the Unit and
                track whether they approve it as well.
              </Text>

              <View style = {{ flexDirection: 'row', flex: 2 }}>
                <Searchbar
                  style={ styles.searchBar }
                  placeholder="Search by Requestor Name"
                  onChangeText={updateSearch}
                  placeholderTextColor={colors.bow}
                  iconColor={colors.bow}
                  inputStyle={{ color: colors.bow }}
                  value={search}
                />
              </View>
            </View>

            <View style={ styles.contentBox }>
              {searchedFiles.map((form) => (
              <View>
                <FileSelect
                  key={form.form_id}
                  id={form.form_id}
                  comment={getCommentForFormType(file)}
                  form_name={form.form_name}
                  selected={selected}
                  select={select}
                  form_type={form.form_type}
                  requestType={form.request_type}
                  timestamp={form.timestamp}
                  creator_id={form.creator_id}
                  excusedAbsenceBADate={form.excused_absence_ba_date}
                  rstPerformedOn={form.rst_performed_on}
                  rmaDate={form.rma_date}
                  ataDate={form.ata_date}
                />
              </View>
            ))}
            </View>
            <Box display="flex" justifyContent="center" marginTop={2}>
              <Pagination
                count={Math.ceil(allFiles.length / ITEM_LIMIT)}
                shape="rounded"
                variant='outlined'
                page={currentPage}
                onChange={handlePage}
                color="primary"
              />
            </Box>
          </View>
        </Scrollbars>
      )}
  </View>
  );

  function FileSelect({
    id,
    selected,
    form_name,
    requestType,
    timestamp,
    form_type,
    attachments,
    creator_id,
    excusedAbsenceBADate,
    rstPerformedOn,
    rmaDate, 
    ataDate,
  }) {
    const status_names = {
      1: "\nFile Corrupted as of ",
      2: "\nStatus: Awaiting Review "
    };

    let displayDate = formatRstDisplayDate(excusedAbsenceBADate || rstPerformedOn || rmaDate || ataDate);
    let statusDate = formatDateToMilDate(new Date(timestamp[0]).toLocaleDateString());
    const doc = displayDate === "Invalid Date" ? require('../../assets/corrupted_doc.png') : require('../../assets/doc_yellow.png');
    return (
      <TouchableOpacity
        style={[
					 styles.button,
					{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'},
        ]}
        onPress={() => sendFileID(id, form_type, attachments, creator_id)}
      >
        <Image style={styles.doc} source={doc} />
        <Text style={ styles.textStyle }>
          {form_name + ' (' + id + ')' +
						'\n' + requestType +
						'\nDate(s): ' + 
            displayDate +
            status_names[displayDate === "Invalid Date" ? 1 : 2] + 
            statusDate}
        </Text>
        <AntDesign name="right" size={14} color={colors.bow} />
      </TouchableOpacity>
    );
  }
};

export default RecommendRSTHome;
