import React, {useEffect, useReducer, useState, useMemo, useRef} from 'react';
import {View, Text, TouchableOpacity, ScrollView} from 'react-native';

import {Box, TextField, IconButton, Link} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import {Upload, DeleteForeverRounded} from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import {useTheme, darken, ThemeProvider, createTheme} from '@mui/material/styles';
import {useIsFocused} from '@react-navigation/native';
import {useScale} from '../../../components/scale';
import CustomHeader from '../../../components/adminHeader';

import {MaterialReactTable, MRT_FullScreenToggleButton, MRT_ToggleGlobalFilterButton, MRT_ShowHideColumnsButton, MRT_ToggleFiltersButton} from 'material-react-table';
import ModeContext from '../../../components/ModeContext';
import {useColorContext} from '../../../components/ColorContext';

import Modal from '@mui/material/Modal';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';

import { create_role, delete_role, get_permissions, get_roles, update_role } from '../../../api_handler/roles';

import {LogBox} from 'react-native';
import NavSideBar from '../../../components/NavSideBar';
import Scrollbars from 'react-custom-scrollbars';
import { useSnackbar } from '../../../components/SnackbarContext';
import { color } from '@mui/system';
import { useNavBarContext } from '../../../components/NavBarContext';
import * as Progress from 'react-native-progress'

function View_roles({navigation}) {
  const {colors} = useColorContext();
  const { showSnackbar } = useSnackbar();
  const { navState } = useNavBarContext();
  const [loaded, setLoaded] = useState(false);
  // Ignore the specific warning message related to the Modal
  LogBox.ignoreLogs(['The modal content node does not accept focus.']);

  // Table
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(7);

  const isFocused = useIsFocused();
  const tableInstanceRef = useRef(null);
  const scale = useScale();

  const { mode } = React.useContext(ModeContext);
  const isLight = (mode === 'light');
  const [density, setDensity] = useState('compact');
  const [columnVisibility, setColumnVisibility] = useState({});
  const [rowSelection, setRowSelection] = useState({});
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 15 });
  const rerender = useReducer(() => ({}), {})[1]; // Forces the MRT_ components to re-render
  const [showColumnFilters, setShowColumnFilters] = useState(false);
  const [showGlobalFilter, setShowGlobalFilter] = useState(false);

  const [hasPermission, setHasPermission] = React.useState(false);

  const [permissionsList, setPermissionsList] = React.useState([]);

  const styles = {
    container: {
      backgroundColor: colors.screen_background,
      flexDirection: 'row',
      height: '100%'
    },
    header: {
      height: 125,
    },
    innerContainer: {
      flexDirection: 'column',
      height:'100%',
      flex: 10
    },
    scrollView: {
      backgroundColor:  colors.screen_background,
      height: '100%',
      width: '100%',
    },
    titleAndDescription: {
      title: {
          fontSize: scale(30),
          fontFamily: 'Trebuchet MS',
          flex: 1,
          color: colors.text,
          paddingLeft: '5%',
          paddingTop: 25
      },
      description: {
        fontFamily: 'Trebuchet MS', 
        fontSize: scale(18), 
        color: colors.text,
        paddingLeft: '5%', 
        paddingRight: '5%',
        width: '100%',
      }
    },
    main: {
      height: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      flex: 10
    },
    textInputProps: {
      '& .MuiFormLabel-root, & label.Mui-focused, & .MuiInputBase-input': {
        color: colors.inputted_text,
      },
      '.MuiSvgIcon-root': {
        fill: colors.inputted_text,
      },
      '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
          borderColor: colors.form_border,
        },
        '&.Mui-focused fieldset': {
          borderColor: '#1876d2',
        },
      },
    },
    searchBar: {
      width: '50%',
      fontSize: '40',
      borderRadius: '40',
      marginTop: 30,
      marginBottom: 30,
      alignItems: 'space-evenly',
      buttonDiv: {
    },
      flexDirection: 'row',
      height: '10%',
      alignItems: 'center',
      justifyContent: 'space-evenly',
    },
    checkBox: {
      height: "24px",
      color: colors.inputted_text,
    },
    listItemTextTypography: {
        fontSize: "1rem"
    },
    tableView: {
      width: '100%',
      alignItems: 'center',
      height: '80%',
    },
    tableDiv: {
      marginTop: '0%',
      backgroundColor: 'white',
      width: '90%',
      alignItems: 'center',
    },
    buttons: {
      width: '15%',
      // backgroundColor: '#0d1232',
      borderRadius: '40',
      alignItems: 'center',
      borderRadius: 15,
      shadowColor: '#171717',
      shadowOffset: {width: -2, height: 4},
      shadowOpacity: 0.2,
      shadowRadius: 3,
      marginTop: 30,
      marginBottom: 30,
      backgroundColor:  colors.button,
    },
    innerText: {
      color:  colors.button_text,
      fontWeight: 'bold',
      padding: 10,
    },
    textbox: {
      marginTop: '2%',
      width: '80%',
      backgroundColor:  colors.textfield_background,
      InputLabelProps: {
        sx: {
          color:  colors.text,
        },
      },
    },
    table: {
      tableContainer: {
        width: '90%',
        alignSelf: 'center',
        marginTop: '2%'
      }
    },
    modal: {
      wrapper: {
        width: '40%',
        height: '100%',
        marginLeft: '35%',
        marginTop: '5%',
        zIndex: 1000,
      },
      viewPermissions: {
        backgroundColor:  colors.screen_background,
        alignItems: 'center',
        height: '60%',
        maxHeight: '80%',
        padding: '5%',
        borderRadius: 10,
        title: {
          fontSize: 50,
          fontFamily: 'Trebuchet MS',
          textAlign: 'center',
          color:  colors.text,
        },
        permissionsScroll: {
          width: '95%',
          borderWidth: 1,
          borderColor: colors.text,
          borderRadius: 10,
          padding: '2%',
          marginTop: '2%',
          marginBottom: '2%',
          permissions: {
            fontSize: 25,
            fontFamily: 'Trebuchet MS',
            textAlign: 'center',
            color:  colors.text,
          },
        },
        buttonWrapper: {
          width: '100%',
          alignItems: 'center',
          button: {
            width: '15%',
            borderRadius: 40,
            alignItems: 'center',
            backgroundColor:  colors.button,
            shadowColor: '#171717',
            shadowOffset: {width: -2, height: 4},
            shadowOpacity: 0.2,
            shadowRadius: 3,
            marginTop: '5%',
          },
        },
      },
      createRole: {
        backgroundColor:  colors.screen_background,
        alignItems: 'center',
        height: '60%',
        maxHeight: '80%',
        padding: '5%',
        borderRadius: 10,
        title: {
          fontSize: 50,
          fontFamily: 'Trebuchet MS',
          textAlign: 'center',
          color:  colors.text,
        },
        buttonWrapper: {
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '5%'
        },
        submitButtonWrapper: {
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'space-around',
          marginTop: '5%',
          button: {
            width: '15%',
            borderRadius: 40,
            alignItems: 'center',
            backgroundColor:  colors.button,
            shadowColor: '#171717',
            shadowOffset: {width: -2, height: 4},
            shadowOpacity: 0.2,
            shadowRadius: 3,
            marginTop: '5%',
          },
        },
      },
      updateRole: {
        backgroundColor:  colors.screen_background,
        alignItems: 'center',
        height: '60%',
        maxHeight: '80%',
        padding: '5%',
        borderRadius: 10,
        title: {
          fontSize: 50,
          fontFamily: 'Trebuchet MS',
          textAlign: 'center',
          color:  colors.text,
        },
        submitButtonWrapper: {
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'space-around',
          marginTop: '5%',
          button: {
            width: '15%',
            borderRadius: 40,
            alignItems: 'center',
            backgroundColor:  colors.button,
            shadowColor: '#171717',
            shadowOffset: {width: -2, height: 4},
            shadowOpacity: 0.2,
            shadowRadius: 3,
            marginTop: '5%',
          },
        },
      },
      deleteRole: {
        backgroundColor:  colors.screen_background,
        alignItems: 'center',
        padding: '5%',
        borderRadius: 10,
        flexDirection: 'column',
        question: {
          fontSize: 25,
          fontFamily: 'Trebuchet MS',
          textAlign: 'center',
          color: colors.text,
        },
        submitButtonWrapper: {
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'space-around',
          button: {
            width: '15%',
            borderRadius: 40,
            alignItems: 'center',
            backgroundColor:  colors.button,
            shadowColor: '#171717',
            shadowOffset: {width: -2, height: 4},
            shadowOpacity: 0.2,
            shadowRadius: 3,
            marginTop: '5%',
          },
        }
      }
    }
  };

  async function get_all_roles() {
    let ret = await get_roles({permissions: global.user_data.permissions}, global.token);
    let perms = await get_permissions({}, global.token);
    if (ret.status !== 200 || perms.status !== 200) {
      showSnackbar("error", "Application Error: Failed to retrieve roles and permissions")
      navigation.navigate('Home')
    } else {
      setRows(ret.data.body.filter(role => role.role !== 'SUPER_USER' && role.role !== 'SUPERUSER' && role.role_id >= 100));
      setPermissionsList(perms.data.body.filter(item => item.id >= 26 && item.id !== 38));
    }
    setLoaded(true);
  }

  useEffect(() => {
      get_all_roles();
      // Check that the user had permission for this page
      if(navState.canSeeAdminConsole){
        setHasPermission(true);
      } else{
        setHasPermission(false);
      }
  }, []);

  // Delete Unit Document code
  const [deleteModalVis, setDeleteModalVis] = React.useState(false);
  const [binary, setBinary] = React.useState();
  const [unitDocumentID, setUnitDocumentID] = React.useState();
  const [unitDocumentName, setUnitDocumentName] = React.useState();
  const [roleID, setRoleID] = React.useState();
  const [roleName, setRoleName] = React.useState('');

  async function startDeleteProcess(role_id, role_name) {
    setRoleID(role_id);
    setRoleName(role_name);
    setDeleteModalVis(true);
  }

  function cancelDelete() {
    setRoleID('');
    setDeleteModalVis(false);
  }

  const confirmDelete = async () => {
    let res = await delete_role({role_id: roleID, role_name: roleName}, global.token);

    setDeleteModalVis(false);

    if (res.status === 200) {
      showSnackbar("success", `Successfully delete the role named '${roleName}'`)
      get_all_roles();
    } else {
      showSnackbar("error", `Application Error: Failed to delete the role named '${roleName}'`)
    }
  };

  // Upload / Update New Role code
  const [createRoleModalVis, setCreateRoleModalVis] = React.useState(false);
  const [updateRoleModalVis, setUpdateRoleModalVis] = React.useState(false);
  const [permissionsModal, setPermissionsModal] = React.useState(false);
  const [rolePermissions, setRolePermissions] = React.useState([]);
  const [permissions, setPermissions] = React.useState([]);
  const [newRoleName, setNewRoleName] = React.useState('');

  const handleChange_request = (event) => {
    const {
      target: {value},
    } = event;
    setPermissions(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
    );
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

    const permissionOptions = async () => {
        await get_permissions();
    };


  function viewPermissions(role_name, role_permissions) {
    setPermissionsModal(true);
    setRoleName(role_name);
    setRolePermissions(role_permissions);
  }

  function closeViewPermissions() {
    setRoleName('');
    setRolePermissions([]);
    setPermissionsModal(false);
  }

  async function cancelRoleCreation() {
    setCreateRoleModalVis(false);
    setPermissions([]);
    setNewRoleName('');
  }

  async function startRoleCreation() {
    setCreateRoleModalVis(true);
  }

    // Create Role
    async function add_role() {
      if (newRoleName.trim() === "") {
        showSnackbar("error", "Please enter a value for 'Role Name'");
        return;
      }

      let res = await create_role({ role: newRoleName, permissions: permissions }, global.token);
      if (res.status === 200) {
        showSnackbar("success", "Successfully create new role");
        setCreateRoleModalVis(false);
        setPermissions([]);
        setNewRoleName('');
        get_all_roles();
      } else {
        showSnackbar("error", "Application Error: Failed to create new role", )
      }
    }

    async function startUpdateProcess(role_id, role_name, permissions) {
        setRoleName(role_name);
        setPermissions(permissions ? permissions : []);
        setRoleID(role_id);
        setUpdateRoleModalVis(true);
    }

  async function cancelRoleUpdate() {
    setUpdateRoleModalVis(false);
    setPermissions([]);
    setRoleName('');
  }

    // Update Role
    async function updateRole() {
      if (roleName.trim() === "") {
        showSnackbar("error", "Please enter a value for 'Role Name'");
        return;
      }

      let res = await update_role({ role: roleName, permissions: permissions, role_id: roleID }, global.token);
      if (res.status === 200) {
        showSnackbar("success", `Successfully updated the role: '${roleName}'`);
        setUpdateRoleModalVis(false);
        setPermissions([]);
        setRoleName('');
        get_all_roles();
      } else {
        showSnackbar("error", `Application Error: Failed to update role '${roleName}'`)
      }
    }

  const globalTheme = useTheme();
  const tableTheme = useMemo(
      () =>
        createTheme({
          palette: {
            mode: mode,
            primary: globalTheme.palette.primary,
            background: {
              default:  colors.textfield_background,
            },
          },
        }),
      [globalTheme, isFocused],
  );

  const columns = [
    {
      accessorKey: 'unit_document_name',
      header: 'Role',
      size: 1,
      align: 'center',
      Cell: ({row}) => {
        return (
        <IconButton style={{fontSize: scale(18), fontWeight: 'bold', fontFamily: 'Trebuchet MS'}} onClick={() => {
          viewPermissions(row.original.role, row.original.array_agg);
        }}>
          <Link style={{color: '#81aef7', textDecoration: 'underline', }} align='center'>
            {row.original.role}
          </Link>
        </IconButton>);
      },
    },
    {
      accessorKey: 'del',
      header: 'Delete Role',
      size: 1,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      Cell: ({row}) => {
        return (row.original.is_deletable === false ?
                    <DeleteForeverRounded style={{color:  colors.text}} /> :
                    <IconButton style={{padding: 0}} onClick={() => {
                      startDeleteProcess(row.original.role_id, row.original.role_name);
                    }}>
                      <DeleteForeverRounded style={{color: 'red'}} />
                    </IconButton>
        );
      },
      Cell: ({row}) => {
        const [isHovered, setIsHovered] = React.useState(false);
    
        const handleMouseEnter = () => {
          setIsHovered(true);
        };
    
        const handleMouseLeave = () => {
          setIsHovered(false);
        };

        return (
          <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
          
            {row.original.is_deletable === false ? (
                      <DeleteForeverRounded style={{color:  colors.text}} /> 
            ) : (
              <IconButton style={{padding: 0}} onClick={() => {
                startDeleteProcess(row.original.role_id, row.original.role);
              }}>
                <DeleteForeverRounded style={{color: 'red'}} />
              </IconButton>
            )}
          </div>
        );
      },
      enableSorting: false,
      enableColumnFilter: false,
      enableGrouping: false,
    },
    {
      accessorKey: 'edit',
      header: 'Update Permissions',
      size: 1,
      align: 'center',
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      Cell: ({row}) => {
        const [isHovered, setIsHovered] = React.useState(false);
    
        const handleMouseEnter = () => {
          setIsHovered(true);
        };
    
        const handleMouseLeave = () => {
          setIsHovered(false);
        };

        return (
          <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
          
            {row.original.is_deletable === false ? (
                      <Upload style={{color:  colors.text}} /> 
            ) : (
              <IconButton style={{padding: 0}} onClick={() => {
                startUpdateProcess(row.original.role_id, row.original.role, row.original.permissions_id);
              }}>
                <EditIcon style={{ color: isHovered ? 'green' : mode == 'dark' ? colors.white : colors.gray}}/>
              </IconButton>
            )}
          </div>
        );
      },
      enableSorting: false,
      enableColumnFilter: false,
      enableGrouping: false,
    },
  ];

  const loadingStyles = {
    animation: {
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
        justifyContent: 'center',
        paddingVertical: 20,
        background: colors.screen_background,
    }
  }

  if (!loaded) {
    return (
      <View style={loadingStyles.animation}>
          <Progress.CircleSnail
            color={colors.loading_circle}
            indeterminate={true}
            size={275}
            thickness={8}
          />
      </View>
    );
  }

    return (
      <Scrollbars showsverticalscrollindicator="true" style={styles.scrollView}>
        <View style={styles.container}>
          {/* <NavSideBar/> */}
            
          {!hasPermission ? (
            <View style={styles.main}>
              <View>
                <Text style={styles.titleAndDescription.title}>Access Denied: User does not have permission to view this page.</Text>
              </View>
            </View>
          ) : (                
            <View style={styles.innerContainer}>
              <View style={styles.main}>
                {/** Create Role Modal */}
                <Modal open={createRoleModalVis} style={styles.modal.wrapper}>
                  <View style={styles.modal.createRole}>
                    <Text style={styles.modal.createRole.title}>Create Role</Text>
                    <TextField
                      style={{...styles.textbox, marginTop: '5%'}}
                      label="Role Name"
                      value={newRoleName}
                      onChange={(data) => setNewRoleName(data.target.value)}
                      InputLabelProps={styles.textbox.InputLabelProps}
                      inputProps={styles.textbox.InputLabelProps}
                    />
                    <FormControl sx={{width: '80%', marginTop: '3%'}}>
                      <InputLabel style={{color:  colors.text, backgroundColor: colors.textfield_background}} id="request_filter_checkbox">Permissions</InputLabel>
                      <Select
                        labelId="request_filter_checkbox"
                        id="request-multiple-checkbox"
                        multiple
                        value={permissions}
                        onChange={handleChange_request}
                        input={<OutlinedInput style={{color:  colors.text, backgroundColor: colors.textfield_background}} label="Permissions" />}

                        renderValue={(selected) => {
                          const mapSelectedPermissionsToNames = (selectedPermissions) => {
                            return selectedPermissions.map((permissionId) => {
                              const selectedPermission = permissionsList.find((permission) => permission.id === permissionId);
                              return selectedPermission ? selectedPermission.permission_name : '';
                            });
                          };
                          return mapSelectedPermissionsToNames(selected).join(', ');
                        }}

                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                              width: 250,
                              zIndex: 1600,
                              backgroundColor:  colors.textfield_background,
                              color:  colors.text,
                            },
                          },
                          disableScrollLock: true,
                          // Add anchorOrigin and transformOrigin to adjust the positioning of the dropdown
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                        }}
                        styles={{zIndex: 9991, color:  colors.text, backgroundColor: colors.textfield_background}}
                      >
                        {permissionsList && permissionsList.map((option) => (
                          <MenuItem key={option.id} value={option.id} label={option.permission_name}> 
                              <Checkbox 
                                  checked={permissions && permissions.indexOf(option.id) > -1} 
                                  sx= { styles.checkBox }
                              />
                              <ListItemText primaryTypographyProps={{ style: styles.listItemTextTypography }} primary={option.permission_name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <View style={{...styles.modal.createRole.submitButtonWrapper, bottom: 0}}>
                      <TouchableOpacity
                        style={styles.modal.createRole.submitButtonWrapper.button}
                        onPress={() => cancelRoleCreation()}>
                        <Text style={styles.innerText}>Cancel</Text>
                      </TouchableOpacity>

                      <TouchableOpacity
                        style={styles.modal.createRole.submitButtonWrapper.button}
                        onPress={() => add_role()}>
                        <Text style={styles.innerText}>Submit</Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                </Modal>
                {/* End of Create role Modal */}

                {/* Delete role modal */}
                <Modal open={deleteModalVis} 
                style={{
                    width: '40%',
                    height: '100%',
                    marginLeft: '30%',
                    marginTop: '5%',
                    zIndex: 1000,
                }}>
                  <View style={styles.modal.deleteRole}>
                    <Text style={styles.modal.deleteRole.question}>{'Are you sure you would like to delete the role "' + roleName + '"?'}</Text>
                    <View style={styles.modal.deleteRole.submitButtonWrapper}>
                      <TouchableOpacity
                        style={styles.modal.deleteRole.submitButtonWrapper.button}
                        onPress={() => cancelDelete()}>
                        <Text style={styles.innerText}>Cancel</Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={styles.modal.deleteRole.submitButtonWrapper.button}
                        onPress={() => confirmDelete()}>
                        <Text style={styles.innerText}>Submit</Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                </Modal>
                {/* End of Delete Modal */}

                {/* Update Role Modal */}
                <Modal open={updateRoleModalVis} style={styles.modal.wrapper}>
                  <View style={styles.modal.updateRole}>
                      <Text style={styles.modal.updateRole.title}>Update Role</Text>
                    <TextField
                      style={{...styles.textbox, marginTop: '5%'}}
                      sx={styles.textInputProps}
                      label="Role Name"
                      value={roleName}
                      onChange={(data) => setRoleName(data.target.value)}
                      InputLabelProps={styles.textbox.InputLabelProps}
                      inputProps={styles.textbox.InputLabelProps}
                    />
                    <FormControl sx={{width: '80%', marginTop: '3%'}}>
                      <InputLabel style={{color:  colors.text, backgroundColor: colors.textfield_background}} id="request_filter_checkbox">Permissions</InputLabel>
                      <Select
                        labelId="request_filter_checkbox"
                        id="request-multiple-checkbox"
                        multiple
                        value={permissions}
                        onChange={handleChange_request}
                        input={<OutlinedInput style={{color:  colors.text, backgroundColor: colors.textfield_background}} label="Permissions" />}

                        renderValue={(selected) => {
                          const mapSelectedPermissionsToNames = (selectedPermissions) => {
                            return selectedPermissions.map((permissionId) => {
                              const selectedPermission = permissionsList.find((permission) => permission.id === permissionId);
                              return selectedPermission ? selectedPermission.permission_name : '';
                            });
                          };
                          
                          return mapSelectedPermissionsToNames(selected).join(', ');
                        }}
                        
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                              width: 250,
                              zIndex: 1600,
                              backgroundColor:  colors.textfield_background,
                              color:  colors.text,
                            },
                          },
                          disableScrollLock: true,
                          // Add anchorOrigin and transformOrigin to adjust the positioning of the dropdown
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                        }}
                        styles={{zIndex: 9991, color:  colors.text, backgroundColor: colors.textfield_background}}
                      >
                        {permissionsList && permissionsList.map((option) => (
                          <MenuItem key={option.id} value={option.id}> 
                            <Checkbox 
                              checked={permissions.includes(option.id)} 
                              sx= { styles.checkBox }
                            />
                            <ListItemText primaryTypographyProps={{ style: styles.listItemTextTypography }} primary={option.permission_name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <View style={styles.modal.updateRole.submitButtonWrapper}>
                      <TouchableOpacity
                        style={styles.modal.updateRole.submitButtonWrapper.button}
                        onPress={() => cancelRoleUpdate()}>
                        <Text style={styles.innerText}>Cancel</Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={styles.modal.updateRole.submitButtonWrapper.button}
                        onPress={() => updateRole()}>
                          <Text style={styles.innerText}>Submit</Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                </Modal>
                {/* End of Update Role Modal */}
                {/** Start of View permissions Modal */}
                <Modal open={permissionsModal} style={styles.modal.wrapper}>
                  <View style={styles.modal.viewPermissions}>
                    <View style={{alignItems: 'center'}}>
                      <Text style={styles.modal.viewPermissions.title}>Permissions In:</Text>
                      <Text style={styles.modal.viewPermissions.title}>{roleName}</Text>
                    </View>
                    <ScrollView style={styles.modal.viewPermissions.permissionsScroll}>
                      <Text style={styles.modal.viewPermissions.permissionsScroll.permissions}>
                        {rolePermissions.join(',\n')}
                      </Text>
                    </ScrollView>
                    <View style={styles.modal.viewPermissions.buttonWrapper}>
                      <TouchableOpacity
                        style={styles.modal.viewPermissions.buttonWrapper.button}
                        onPress={() => closeViewPermissions()}>
                          <Text style={styles.innerText}>Close</Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                </Modal>
                
                <View style={{width: '100%'}}>
                  <Text style={styles.titleAndDescription.title}>
                    <Text style={{fontWeight:'bold'}}>Admin Console {'>'} </Text>
                    <Text>Manage Roles for {global.user_data ? global.user_data.unit_name : ''}</Text>
                  </Text>
                  <View style={{flexDirection: 'row', marginTop: '1%', alignItems: 'flex-start'}}>
                    <Text style={styles.titleAndDescription.description}> 
                      This page allows admins to manage roles and permissions. Permissions allow or forbid users from 
                      accessing different parts of the application. Roles can be thought of as package of permissions. 
                      Users with a role will have all the role's permissions. This page allows your to create, view, edit, 
                      and delete Roles.
                    </Text>

                  </View>
                </View>

                <Scrollbars style={styles.table.tableContainer}>
                  <ThemeProvider theme={tableTheme}>
                    <MaterialReactTable
                      columns={columns}
                      data={rows}
                      enableRowSelection
                      positionToolbarAlertBanner={'bottom'}
                      tableInstanceRef={tableInstanceRef}
                      enableGrouping
                      enableColumnDragging={false}
                      enableStickyHeader
                      enableExpandAll={false}
                      selectAllMode={'all'}
                      isMultiSortEvent={() => true}
                      initialState={{
                        showGlobalFilter: false,
                        columnOrder: ['unit_document_name', 'edit', 'del'],
                      }}
                      muiTableBodyProps={{
                        sx: (theme) => ({
                          '& tr:nth-of-type(n)': {
                            backgroundColor:  colors.table_background_color,
                          },
                          '& tr:nth-of-type(2n-1)': {
                            backgroundColor: darken(theme.palette.background.default, 0.075),
                          },
                          '& tr.Mui-selected': {
                            backgroundColor:  colors.table_selected,
                          },
                          '& tr:hover:not(.Mui-selected) > td': {
                            backgroundColor:  colors.table_hover,
                          },
                        }),
                      }}

                      // Table State Management for updater function
                      onColumnVisibilityChange={(updater) => {
                        setColumnVisibility((prev) => updater instanceof Function ? updater(prev) : updater,);
                        queueMicrotask(rerender); // Rerender after state update
                      }}


                      onRowSelectionChange={(updater) => {
                        setRowSelection((prev) => updater instanceof Function ? updater(prev) : updater,);
                        queueMicrotask(rerender); // Rerender after state update
                      }}

                      onPaginationChange={(updater) => {
                        setPagination((prev) => updater instanceof Function ? updater(prev) : updater,);
                        queueMicrotask(rerender); // Rerender after state update
                      }}

                      onShowColumnFiltersChange={(updater) => {
                        setShowColumnFilters((prev) => updater instanceof Function ? updater(prev) : updater,);
                        queueMicrotask(rerender); // Rerender after state update
                      }}

                      onShowGlobalFilterChange={(updater) => {
                        setShowGlobalFilter((prev) => updater instanceof Function ? updater(prev) : updater,);
                        queueMicrotask(rerender); // Rerender after state update
                      }}

                      state={{
                        columnVisibility,
                        density,
                        rowSelection,
                        pagination,
                        showColumnFilters,
                        showGlobalFilter,
                      }}

                      renderToolbarInternalActions={({table}) => (
                        <>
                          <Box>
                          <MRT_FullScreenToggleButton table={tableInstanceRef.current} />

                            <Tooltip arrow title="Add Role">
                              <IconButton onClick={() => {
                                startRoleCreation()
                              }}
                              >
                                <AddIcon />
                              </IconButton>
                          </Tooltip>
                          
                          </Box>
                        </>
                      )}
                    />
                  </ThemeProvider>
                </Scrollbars>
              </View>
            </View>
          )}
        </View >
      </Scrollbars>
    );
};

export default View_roles;
