import React from 'react';
import {View, Text} from 'react-native';
import {TouchableOpacity, StyleSheet } from 'react-native';

import {useScale} from '../components/scale';
import CustomHeader from '../components/header';
import NavSideBar from '../components/NavSideBar';
import { useColorContext} from '../components/ColorContext';
import { Linking } from 'react-native';

function CCIR({navigation}) {
  const {colors} = useColorContext();
  const scale = useScale();
  const styles = ({
    container: {
      flex: 1,
      backgroundColor: colors.screen_background,
    },
    navBarWrapper: {
      flexDirection: 'row',
      height: '100%',
      flex: 1,

    },
    list: {
      width: '75%',
    },
    title: {
      fontSize: scale(50),
      fontFamily: 'Trebuchet MS',
      marginTop: '0.5%',
      color: colors.text,
    },
    button: {
      marginTop: 2,
    },
    header: {
      backgroundColor: 'pink',
      height: '15%',
    },
    main: {
      marginBottom: 20,
      marginLeft: 380,
    },
    item: {
      backgroundColor: colors.gold_button,
      alignItems: 'center',
      justifyContent: 'center',
      padding: 20,
      height: 100, // Increase the height as needed
      width: 700, // Increase the width as needed
      margin: 10,
      borderRadius: 20,
      marginVertical: 8,
      marginHorizontal: 16,
      borderRadius: 8,
      shadowColor: '#000',
      shadowOffset: { width: 3, height: 3 },
      shadowOpacity: 0.3,
      shadowRadius: 8,
    },
  
    innerText: {
      color: colors.button_text,
      fontWeight: 'bold',
      padding: 10,
      fontSize: 20,
    },
    buttonContainer: {
      flexDirection: 'column',
      flex: 2,
      justifyContent: 'center',
      alignItems: 'center',
      maxHeight: 250,
      marginVertical: 50,
    },
    hidden: {
      backgroundColor: 'transparent',
      borderWidth: 0,
    },
    wrapper: {
      alignItems: 'center', // Align items vertically within the row
      marginVertical: 10,
      marginLeft: 250,
    },

  });

  function confirmAppExit(url) {
    Alert.alert(
      'Confirmation',
      'You are about to open a link. Do you want to proceed?',
      [
        {
          text: 'Cancel',
          style: 'cancel',
        },
        {
          text: 'Open Link',
          onPress: () => {
            Linking.openURL(url);
          },
        },
      ],
      { cancelable: true }
    );
  }
  return (

    <View style = {styles.container}>
      <View style={styles.navBarWrapper}>
      {/* <NavSideBar /> */}
      <View style={styles.main}>
      <Text style={styles.title}>COVID-19 Form</Text>
      </View>
      </View>
    </View>
  );
};

export default CCIR;
