/** *****************************/
/** ********* IMPORTS ***********/
/** *****************************/
import React, { useEffect, useState, useMemo, memo, useContext, useRef, useCallback, forwardRef } from 'react';
import { FlatList, Image, Text, TouchableOpacity, View, ScrollView } from 'react-native';
import { useColorContext } from './ColorContext';
import { AuthUserContext } from '../nav/AuthUserProvider.js';
import { useNavBarContext } from './NavBarContext';
import { CounterContext } from './CounterContext.js'
import { useScale } from '../components/scale';
import { useNavigation } from '@react-navigation/native';
import { useSnackbar } from './SnackbarContext.js';
import { Scrollbars } from 'react-custom-scrollbars';
import { removeData } from '../api_handler/asyncstorage.js';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Entypo } from '@expo/vector-icons';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountTreeRoundedIcon from '@mui/icons-material/AccountTreeRounded';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ApprovalRoundedIcon from '@mui/icons-material/ApprovalRounded';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowLeftRoundedIcon from '@mui/icons-material/ArrowLeftRounded';
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import BugReportRoundedIcon from '@mui/icons-material/BugReportRounded';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import ChecklistRoundedIcon from '@mui/icons-material/ChecklistRounded';
import ConnectWithoutContactRoundedIcon from '@mui/icons-material/ConnectWithoutContactRounded';
import Diversity1RoundedIcon from '@mui/icons-material/Diversity1Rounded';
import Diversity2RoundedIcon from '@mui/icons-material/Diversity2Rounded';
import Diversity3RoundedIcon from '@mui/icons-material/Diversity3Rounded';
import EngineeringRoundedIcon from '@mui/icons-material/EngineeringRounded';
import ExploreRoundedIcon from '@mui/icons-material/ExploreRounded';
import FolderCopyRoundedIcon from '@mui/icons-material/FolderCopyRounded';
import HelpIcon from '@mui/icons-material/Help';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import MedicalInformationRoundedIcon from '@mui/icons-material/MedicalInformationRounded';
import PanToolRoundedIcon from '@mui/icons-material/PanToolRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import PlagiarismRoundedIcon from '@mui/icons-material/PlagiarismRounded';
import PointOfSaleRoundedIcon from '@mui/icons-material/PointOfSaleRounded';
import QuestionMarkRoundedIcon from '@mui/icons-material/QuestionMarkRounded';
import RecommendIcon from '@mui/icons-material/Recommend';
import RequestQuoteRoundedIcon from '@mui/icons-material/RequestQuoteRounded';
import SourceRoundedIcon from '@mui/icons-material/SourceRounded';
import TaskRoundedIcon from '@mui/icons-material/TaskRounded';
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded';
import VerifiedUserRoundedIcon from '@mui/icons-material/VerifiedUserRounded';
import { DescriptionRounded, Update } from '@mui/icons-material';
import { UploadFileRounded } from '@mui/icons-material';
import { SchoolRounded } from '@mui/icons-material';
import { TaskRounded } from '@mui/icons-material';
import { VolunteerActivismRounded } from '@mui/icons-material';
import { PeopleAltRounded } from '@mui/icons-material';
import { ArticleRounded } from '@mui/icons-material';
import { DateRangeRounded } from '@mui/icons-material';
import { Diversity3Rounded } from '@mui/icons-material';
import { ContactEmergencyRounded } from '@mui/icons-material';
import { BlockRounded } from '@mui/icons-material';
import { InfoRounded } from '@mui/icons-material';
import { FolderCopyRounded } from '@mui/icons-material';
import { UpdateRounded } from '@mui/icons-material';
import SchemaRoundedIcon from '@mui/icons-material/SchemaRounded';
import VolunteerActivismOutlinedIcon from '@mui/icons-material/VolunteerActivismOutlined'
import SavingsOutlinedIcon from '@mui/icons-material/SavingsOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import MasksOutlinedIcon from '@mui/icons-material/MasksOutlined';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';

import { FlagCircle } from '@mui/icons-material';
import { get_review_files, get_approved_1380s, get_recommend_files } from '../api_handler/files.js';
import { PixelRatio, Dimensions } from 'react-native';
/**
* Navigation Side Bar is a component that contains links to the different
*  functionalities of the web application. Should be maintained in an effort
*  to increase accessibility to the most frequented features. Other features
*  inaccessible from any other places should also be included here.
*/
const NavSideBar = React.memo(() => {
	const { width, height } = Dimensions.get('window');
	const { user, setUser } = useContext(AuthUserContext);
	const { navState, setNavState } = useNavBarContext();
	const { showSnackbar } = useSnackbar();
	const { actionFiles, updateActionFiles, unpaidFiles, updateUnpaidFiles } = useContext(CounterContext);
	const [fileCount, setCountFile] = useState(0);

	const [name, setName] = useState('');
	const navigation = useNavigation();

	const currentMainMenuId = useMemo(() => navState.mainMenuTab || null, [navState.mainMenuTab]);
	const currentSubMenuId = useMemo(() => navState.subMenuTab || null, [navState.subMenuTab]);
	const currentSubMenuOptionId = useMemo(() => navState.subMenuOptionTab || null, [navState.subMenuOptionTab]);
	const isSubMenuOpen = useMemo(() => [3, 4, 5, 6, 7, 8, 9].includes(navState.mainMenuTab), [navState.mainMenuTab]);
	const isSubMenuOptionsOpen = useMemo(() => [31, 62].includes(navState.subMenuTab), [navState.subMenuTab]);

	const formTypes = {
		66: 'EBDL',
		67: 'ATA',
		68: 'MDR',
	};

	useEffect(() => {
		const fetchPendingFilesCount = async () => {
			const revFiles = await get_review_files({
				'role': global.user_data.role,
				'user_id': global.user_data.user_id,
				'permissions': global.user_data.permissions
			},
				global.token,
			);
			const recFiles = await get_recommend_files({
				user_id: global.user_data.user_id,
				permissions: global.user_data.permissions,
			}, global.token);

			if (revFiles.status === 200 && recFiles.status === 200) {
				let accountId = global.user_data.user_id;
				const filtered = revFiles.data.body.filter(file => {
					if (file.status >= 4) return false;

					if (file.form_type === '1380' && file.form_id > 3593) {
						const isReviewer = file.reviewer_ids?.includes(accountId)
						const isValidator = file.validator_ids?.includes(accountId)

						const needsOfficerReview = file.reviewer_id === null
						const needsValidatorReview = file.validator_id === null

						if (isReviewer && needsOfficerReview) {
							return true;
						}

						if (isValidator && needsValidatorReview) {
							return true;
						}

						return false;
					} else if (file.form_type === '1380') {
						return true;
					}

					return true;
				});

				const filteredRecFiles = recFiles.data.body.filter((file) => file.status < 2 && file.recommender_id !== file.reviewer_id);
				setCountFile(filtered.length + filteredRecFiles.length);
				updateActionFiles(filtered.length + filteredRecFiles.length);
			} else if (!(revFiles.data.body === "Invalid Permissions" || recFiles.data.body === "Invalid Permissions")) {
				showSnackbar("error", "Application Error: Failed to retrieve navbar counter information");
			}
		};

		const fetch1380s = async () => {
			const requests = await get_approved_1380s({
				user_id: global.user_data.user_id,
				unit_id: global.user_data.unit_id,
				permissions: global.user_data.permissions,
				admin_unit_ids: global.user_data.admin_unit_ids,
			}, global.token);

			if (requests.status === 200) {
				const unpaid = requests.data.body.filter((file) => file.status !== 6);
				updateUnpaidFiles(unpaid.length);
			} else if (!requests.data.body === "Invalid Permissions") {
				showSnackbar("error", "Application Error: Failed to retrieve navbar counter information");
			}
		};


		fetch1380s();
		fetchPendingFilesCount();
	}, []);

	const [payManagementTitle, setPayManagementTitle] = useState(`Pay Management (${global.unpaidFiles})`);

	useEffect(() => {
		// Function to update the title based on screen width
		const updateTitle = () => {
			const screenWidth = Dimensions.get('window').width;

			const newTitle = screenWidth < 1444
				? `Pay Mgmt. (${unpaidFiles})`
				: `Pay Management (${unpaidFiles})`;

			setPayManagementTitle(newTitle);
		};

		updateTitle();
		const subscription = Dimensions.addEventListener('change', updateTitle);

		return () => subscription?.remove();
	}, [unpaidFiles]);


	/************************/
	/** Menus and Submenus **/
	/***********************/
	const options = [
		{
			id: 'user',
			title: name,
			choice: 1,
			hasSubMenus: false,
			link: 'Settings',
			isVisible: true,
		},
		{
			id: 'home',
			title: 'Home',
			choice: 2,
			hasSubMenus: false,
			link: 'Home',
			isVisible: true,
		},
		{
			id: 'admin',
			title: 'Admin',
			choice: 3,
			hasSubMenus: true,
			link: '',
			isVisible: navState.canSeeAdminConsole || navState.canSeeUsers || navState.canUploadBaDates,
		},
		{
			id: 'pay',
			title: payManagementTitle,
			choice: 11,
			hasSubMenus: false,
			link: 'PayManagement',
			isVisible: navState.canSeePayManagement || navState.canEditPayManagement,
		},
		{
			id: 'leader',
			title: 'Leader',
			choice: 4,
			hasSubMenus: true,
			link: '',
			isVisible: navState.canSeeLeadersDash || navState.canEditLeadersDash,
		},
		{
			id: 'review',
			title: 'Review',
			choice: 5,
			hasSubMenus: true,
			link: '',
			isVisible: true
		},
		{
			id: 'request',
			title: `Requests (${actionFiles})`,
			choice: 6,
			hasSubMenus: true,
			link: '',
			isVisible: true,
		},
		{
			id: 'report',
			title: 'Report',
			choice: 7,
			hasSubMenus: true,
			link: 'Report',
			isVisible: true,
		},
		{
			id: 'resources',
			title: 'Resources',
			choice: 8,
			hasSubMenus: true,
			link: '',
			isVisible: true,
		},
		{
			id: 'help',
			title: 'Help',
			choice: 9,
			hasSubMenus: true,
			link: '',
			isVisible: true,
		},
		// {
		// 	id: 'calendar',
		// 	title: 'Calendar',
		// 	choice: 10,
		// 	hasSubMenus: false,
		// 	link: 'Calendar',
		// 	isVisible: true,
		// },
		{
			id: 'signOut',
			title: 'Sign Out',
			choice: 0,
			hasSubMenus: false,
			link: 'SignOut',
			isVisible: true,
		},
	]

	const adminSubMenus = [
		{
			id: 'unit-page',
			title: `Manage ${(global.user_data && global.user_data.unit_name) ? global.user_data.unit_name : 'Unit'}`,
			choice: 31,
			link: '',
			isVisible: navState.canSeeAdminConsole,
			hasSubmenuOptions: true,
		},
		{
			id: 'unit-ba-dates',
			title: 'Manage BA Dates',
			choice: 36,
			link: 'view_BA_dates',
			isVisible: !navState.canSeeAdminConsole && navState.canUploadBaDates,
			hasSubmenuOptions: false,
		},
		{
			id: 'users',
			title: 'User Management',
			choice: 32,
			link: 'view_users',
			isVisible: navState.canSeeUsers,
			hasSubmenuOptions: false,
		},
		{
			id: 'roles',
			title: 'Roles',
			choice: 33,
			link: 'roles',
			isVisible: navState.canSeeAdminConsole,
			hasSubmenuOptions: false,
		},
		{
			id: 'units',
			title: 'Units Management',
			choice: 34,
			link: 'view_units',
			isVisible: navState.isSuperUser,
			hasSubmenuOptions: false,
		},
		{
			id: 'logs',
			title: 'Audit Logs',
			choice: 35,
			link: 'audit_logs',
			isVisible: navState.canSeeAdminConsole,
			hasSubmenuOptions: false,
		},
		// Add more sub-menu items as needed
	];

	const adminSubMenuOptions = [
		{
			id: 'unit-pocs',
			title: 'Manage Unit POCs',
			choice: 311,
			link: 'unit_POC',
			isVisible: true
		},
		{
			id: 'unit-form-fields',
			title: 'Manage Form Fields',
			choice: 312,
			link: 'edit_unit_forms_info',
			isVisible: true
		},
		{
			id: 'unit-ba-dates',
			title: 'Manage BA Dates',
			choice: 313,
			link: 'view_BA_dates',
			isVisible: navState.canUploadBaDates
		},
	]

	const leaderSubMenus = [
		{
			id: 'admin-overview',
			title: 'Admin',
			choice: 41,
			link: 'document_overview',
			isVisible: true,
			hasSubmenuOptions: false,
		},
		{
			id: 'budget-overview',
			title: 'Budget',
			choice: 42,
			link: 'budget_overview',
			isVisible: true,
			hasSubmenuOptions: false,
		},
		{
			id: 'medical-overview',
			title: 'Medical',
			choice: 43,
			link: 'medical_overview',
			isVisible: true,
			hasSubmenuOptions: false,
		},
		{
			id: 'training-overview',
			title: 'Training',
			choice: 44,
			link: 'training_overview',
			isVisible: true,
			hasSubmenuOptions: false,
		},
		// Add more sub-menu items as needed
	];

	const reviewSubMenus = [
		{
			id: 'unit-documents',
			title: 'Unit Documents',
			choice: 83,
			link: 'unit_documents',
			isVisible: true,
			hasSubmenuOptions: false,
			pageTitle: `Documents for ${global.user_data ? global.user_data.unit_name : ''}`,
			unit_id: global.user_data.unit_id,
			permissionNeeded: 37,
		},
		{
			id: 'usarc-documents',
			title: 'USARC Documents',
			choice: 84,
			link: 'usarc_documents',
			isVisible: true,
			hasSubmenuOptions: false,
			pageTitle: 'Documents for USARC',
			unit_id: 0,
			permissionNeeded: 37,
		},
		{
			id: 'unit-ba-dates',
			title: 'View BA Dates',
			choice: 85,
			link: 'view_BA_dates',
			isVisible: true,
			hasSubmenuOptions: false,
		},
	];

	const reportSubMenus = [
		{
			id: 'ccir',
			title: 'CCIR',
			choice: 90,
			link: 'https://safety.army.mil/ON-DUTY/Workplace/OSHA-Corner/Report-OSHA-Events',
			isVisible: true,
			hasSubmenuOptions: false,
		},
	]

	const requestSubMenus = [
		{ id: 'request-rst', title: 'Request New RST', choice: 61, link: 'CreateNewRST', isVisible: true, hasSubmenuOptions: false },
		{ id: 'request-1380', title: '1380', choice: 62, link: 'New1380', isVisible: true, hasSubmenuOptions: true },
		{ id: 'request-ebdl', title: 'Request New EBDL', choice: 66, link: 'CreateNewEBDL', isVisible: true, hasSubmenuOptions: false },
		{ id: 'request-rma-ata', title: 'Request New RMA/ATA', choice: 67, link: 'CreateNewEBDL', isVisible: true, hasSubmenuOptions: false },
		{ id: 'request-mdr', title: 'Request New MDR', choice: 68, link: 'CreateNewEBDL', isVisible: true, hasSubmenuOptions: false },
		{ id: 'your_files', title: 'Your Files', choice: 63, link: 'YourFiles', isVisible: true, hasSubmenuOptions: false },
		{ id: 'awaiting_action', title: `Your Actions (${actionFiles})`, choice: 65, link: 'AwaitingAction', isVisible: navState.canSeeReview || fileCount > 0, hasSubmenuOptions: false }, //aqui
	]

	const request1380SubMenuOptions = [
		{
			id: 'new1380',
			title: 'New Blank 1380',
			choice: 621,
			link: 'New1380',
			isVisible: true,
		},
		{
			id: 'pullRST',
			title: 'Pull From Forms',
			choice: 622,
			link: 'RstTo1380',
			isVisible: true,
		},
	];

	const resourcesSubMenus = [
		{
			id: 'sharp',
			title: 'USARC SHARP',
			choice: 71,
			link: 'https://www.armyresilience.army.mil/sharp',
			isVisible: true,
			hasSubmenuOptions: false,
		},
		{
			id: 'poc',
			title: 'Points of Contact',
			choice: 714,
			link: 'unit_POC',
			isVisible: true,
			hasSubmenuOptions: false,
		},
		{
			id: 'equal_opportunity',
			title: 'Equal Opportunity',
			choice: 72,
			link: 'https://www.usar.army.mil/Resources/Equal-Opportunity/EOFlowProcess/',
			isVisible: true,
			hasSubmenuOptions: false,
		},
		{
			id: 'suicide_prevention',
			title: 'Suidide Prevention',
			choice: 73,
			link: 'https://www.armyresilience.army.mil/suicide-prevention/pages/IAmHavingThoughtsOfSuicide.html',
			isVisible: true,
			hasSubmenuOptions: false,
		},
		{
			id: 'helpline',
			title: 'Helpline',
			choice: 74,
			link: 'https://safehelpline.org/how-to-get-help',
			isVisible: true,
			hasSubmenuOptions: false,
		},
		// Add more sub-menu items as needed
	];

	const helpSubMenus = [
		{
			id: 'user-guide',
			title: 'User Guide',
			choice: 81,
			link: 'UserManual',
			isVisible: true,
			hasSubmenuOptions: false,
		},
		{
			id: 'bug-report',
			title: 'Bug Report',
			choice: 82,
			link: 'BugReport',
			isVisible: true,
			hasSubmenuOptions: false,
		},
	]

	/***************/
	/** Functions **/
	/***************/

	/**
	 * UseEffect to grab the user's name and icon.
	 */
	useEffect(() => {
		function capitalizeFirstLetter(string) {
			return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
		}

		// Wait for profile pictures to be updated in other files
		const getData = async () => {

			let name =
				(global.user_data && global.user_data.rank ? global.user_data.rank : "") +
				(global.user_data && global.user_data.first_name ? ' ' + capitalizeFirstLetter(global.user_data.first_name) : "") +
				(global.user_data && global.user_data.last_name ? ' ' + capitalizeFirstLetter(global.user_data.last_name) : "");

			if (name == null || name === '') {
				setName('Profile');
			} else {
				setName(name);
			}
		};

		getData();
	}, []);

	/**
	 * Utility function to select icons based on a unique choice number. Add more cases as new icons are needed. There should
	 * be a one to one mapping between icons and features even if that causes some redundancies. 
	 * 
	 * @param {*} choice - Unique code that has a one to one mapping to each available feature on the nav bar
	 * @returns The icon corresponding with the choice.
	 */
	function getMainMenuIcon(choice) {
		switch (choice) {
			// User
			case 1:
				return (
					<View style={styles.options.optionsItemContainer}>
						<AccountCircleIcon style={styles.options.optionsIcon} />
					</View>
				)
			// Home
			case 2:
				return (
					<View style={styles.options.optionsItemContainer}>
						<HomeRoundedIcon style={styles.options.optionsIcon} />
					</View>
				)
			// Admin
			case 3:
				return (
					<View style={styles.options.optionsItemContainer}>
						<EngineeringRoundedIcon style={styles.options.optionsIcon} />
					</View>
				)
			// Leader
			case 4:
				return (
					<View style={styles.options.optionsItemContainer}>
						<VerifiedUserRoundedIcon style={styles.options.optionsIcon} />
					</View>
				)
			// Review
			case 5:
				return (
					<View style={styles.options.optionsItemContainer}>
						<Entypo name="download" style={{ ...styles.options.optionsIcon }} />
					</View>
				)
			// Request
			case 6: //aqui
				return (
					<View style={styles.options.optionsItemContainer}>
						<SchemaRoundedIcon style={{ ...styles.options.optionsIcon, fontSize: width < 1300 ? '2.3vh' : '3.3vh' }} />
					</View>
				)
			// Report
			case 7:
				return (
					<View style={styles.options.optionsItemContainer}>
						<Entypo name="upload" style={{ ...styles.options.optionsIcon }} />
					</View>
				)

			// Soldier Resources
			case 8:
				return (
					<View style={styles.options.optionsItemContainer}>
						<Diversity2RoundedIcon style={styles.options.optionsIcon} />
					</View>
				)
			// Help
			case 9:
				return (
					<View style={styles.options.optionsItemContainer}>
						<HelpIcon style={styles.options.optionsIcon} />
					</View>
				)
			// Calendar
			case 10:
				return (
					<View style={styles.options.optionsItemContainer}>
						<CalendarMonthRoundedIcon style={styles.options.optionsIcon} />
					</View>
				)
			case 0:
				return (
					<View style={styles.options.optionsItemContainer}>
						<LogoutRoundedIcon style={styles.options.optionsIcon} />
					</View>
				)
			// Pay Management	
			case 11:
				return (
					<View style={styles.options.optionsItemContainer}>
						<PointOfSaleRoundedIcon style={styles.options.optionsIcon} />
					</View>
				)
			// Unknown
			default:
				return (
					<View style={styles.options.optionsItemContainer}>
						<QuestionMarkRoundedIcon style={styles.options.optionsIcon} />
					</View>
				)
		}
	}

	/**
	 * Utility function to select submenu icons based on a unique choice number. Add more cases as new icons are needed. There should
	 * be a one to one mapping between icons and features even if that causes some redundancies.
	 * 
	 * @param {*} choiceCode - Unique code that has a one to one mapping to each available feature on the nav bar
	 * @returns The icon corresponding with the choice.
	 */
	function getSubMenuIcon(choiceCode) {
		switch (choiceCode) {
			// Admin
			case 31:
				return (
					<View style={styles.subMenuItems.subMenuItemContainer}>
						<ManageAccountsRoundedIcon style={styles.subMenuItems.subMenuItemsIcons} />
					</View>
				)
			case 32:
				return (
					<View style={styles.subMenuItems.subMenuItemContainer}>
						<PeopleRoundedIcon style={styles.subMenuItems.subMenuItemsIcons} />
					</View>
				)
			case 33:
				return (
					<View style={styles.subMenuItems.subMenuItemContainer}>
						<AdminPanelSettingsIcon style={styles.subMenuItems.subMenuItemsIcons} />
					</View>
				)
			case 34:
				return (
					<View style={styles.subMenuItems.subMenuItemContainer}>
						<AccountTreeRoundedIcon style={styles.subMenuItems.subMenuItemsIcons} />
					</View>
				)
			case 35:
				return (
					<View style={styles.subMenuItems.subMenuItemContainer}>
						<PlagiarismRoundedIcon style={styles.subMenuItems.subMenuItemsIcons} />
					</View>
				)
			case 36:
				return (
					<View style={styles.subMenuOptionsItems.subMenuItemOptionsContainer}>
						<DateRangeRounded style={styles.subMenuOptionsItems.subMenuOptionsItemsIcons} />
					</View>
				)
			// Leader
			case 41:
				return (
					<View style={styles.subMenuItems.subMenuItemContainer}>
						<SourceRoundedIcon style={styles.subMenuItems.subMenuItemsIcons} />
					</View>
				)
			case 42:
				return (
					<View style={styles.subMenuItems.subMenuItemContainer}>
						<TrendingUpRoundedIcon style={styles.subMenuItems.subMenuItemsIcons} />
					</View>
				)
			case 43:
				return (
					<View style={styles.subMenuItems.subMenuItemContainer}>
						<MedicalInformationRoundedIcon style={styles.subMenuItems.subMenuItemsIcons} />
					</View>
				)
			case 44:
				return (
					<View style={styles.subMenuItems.subMenuItemContainer}>
						<ChecklistRoundedIcon style={styles.subMenuItems.subMenuItemsIcons} />
					</View>
				)
			// Review
			case 51:
				return (
					<View style={styles.subMenuItems.subMenuItemContainer}>
						<ApprovalRoundedIcon style={styles.subMenuItems.subMenuItemsIcons} />
					</View>
				)
			case 52:
				return (
					<View style={styles.subMenuItems.subMenuItemContainer}>
						<ApprovalRoundedIcon style={styles.subMenuItems.subMenuItemsIcons} />
					</View>
				)
			case 53:
				return (
					<View style={styles.subMenuItems.subMenuItemContainer}>
						<RecommendIcon style={styles.subMenuItems.subMenuItemsIcons} />
					</View>
				)
			case 54:
				return (
					<View style={styles.subMenuItems.subMenuItemContainer}>
						<ApprovalRoundedIcon style={styles.subMenuItems.subMenuItemsIcons} />
					</View>
				)
			// Request
			case 61:
				return (
					<View style={styles.subMenuItems.subMenuItemContainer}>
						<PersonOffOutlinedIcon style={styles.subMenuItems.subMenuItemsIcons} />
					</View>
				)
			case 62:
				return (
					<View style={styles.subMenuItems.subMenuItemContainer}>
						<RequestQuoteOutlinedIcon style={{ ...styles.subMenuItems.subMenuItemsIcons }} />
					</View>
				)
			case 63:
				return (
					<View style={styles.subMenuItems.subMenuItemContainer}>
						<FolderCopyRounded style={styles.subMenuItems.subMenuItemsIcons} />
					</View>
				)
			case 65:
				return (
					<View style={styles.subMenuItems.subMenuItemContainer}>
						<FolderCopyRounded style={styles.subMenuItems.subMenuItemsIcons} />
					</View>
				)

			case 66:
				return (
					<View style={styles.subMenuItems.subMenuItemContainer}>
						<SchoolOutlinedIcon style={styles.subMenuItems.subMenuItemsIcons} />
					</View>
				)
			case 67:
				return (
					<View style={styles.subMenuItems.subMenuItemContainer}>
						<SavingsOutlinedIcon style={styles.subMenuItems.subMenuItemsIcons} />
					</View>
				)
			case 68:
				return (
					<View style={styles.subMenuItems.subMenuItemContainer}>
						<VolunteerActivismOutlinedIcon style={styles.subMenuItems.subMenuItemsIcons} />
					</View>
				)
			// Resources
			case 71:
				return (
					<View style={styles.subMenuItems.subMenuItemContainer}>
						<Entypo name="awareness-ribbon" style={{ ...styles.subMenuItems.subMenuItemsIcons, fontSize: width < 1300 ? '1.6vh' : '2.6vh' }} />
					</View>
				)
			case 714:
				return (
					<View style={styles.subMenuOptionsItems.subMenuItemOptionsContainer}>
						<ContactEmergencyRounded style={styles.subMenuOptionsItems.subMenuOptionsItemsIcons} />
					</View>
				)
			case 72:
				return (
					<View style={styles.subMenuItems.subMenuItemContainer}>
						<Diversity3RoundedIcon style={styles.subMenuItems.subMenuItemsIcons} />
					</View>
				)
			case 73:
				return (
					<View style={styles.subMenuItems.subMenuItemContainer}>
						<Diversity1RoundedIcon style={styles.subMenuItems.subMenuItemsIcons} />
					</View>
				)
			case 74:
				return (
					<View style={styles.subMenuItems.subMenuItemContainer}>
						<ConnectWithoutContactRoundedIcon style={styles.subMenuItems.subMenuItemsIcons} />
					</View>
				)
			// Help
			case 81:
				return (
					<View style={styles.subMenuItems.subMenuItemContainer}>
						<ExploreRoundedIcon style={{ ...styles.subMenuItems.subMenuItemsIcons, fontSize: width < 1300 ? '1.6vh' : '2.6vh' }} />
					</View>
				)
			case 82:
				return (
					<View style={styles.subMenuItems.subMenuItemContainer}>
						<BugReportRoundedIcon style={styles.subMenuItems.subMenuItemsIcons} />
					</View>
				)
			case 83:
				return (
					<View style={styles.subMenuItems.subMenuItemContainer}>
						<ManageAccountsRoundedIcon style={styles.subMenuItems.subMenuItemsIcons} />
					</View>
				)
			case 84:
				return (
					<View style={styles.subMenuItems.subMenuItemContainer}>
						<MilitaryTechIcon style={{ ...styles.subMenuItems.subMenuItemsIcons, fontSize: width < 1300 ? '2.5vh' : '3.5vh' }} />
					</View>
				)
			case 90:
				return (
					<View style={styles.subMenuItems.subMenuItemContainer}>
						<InfoRounded style={styles.subMenuItems.subMenuItemsIcons} />
					</View>
				)
			case 85:
				return (
					<View style={styles.subMenuOptionsItems.subMenuItemOptionsContainer}>
						<DateRangeRounded style={{ ...styles.subMenuItems.subMenuItemsIcons, fontSize: width < 1300 ? '1.7vh' : '2.7vh' }} />
					</View>
				)
			default:
				return (
					<View style={styles.subMenuItems.subMenuItemContainer}>
						<QuestionMarkRoundedIcon style={styles.subMenuItems.subMenuItemsIcons} />
					</View>
				)
		}
	}

	/**
	 * Utility function to select submenu options icons based on a unique choice number. Add more cases as new icons are needed. There should
	 * be a one to one mapping between icons and features even if that causes some redundancies.
	 * 
	 * @param {*} choiceCode - Unique code that has a one to one mapping to each available feature on the nav bar
	 * @returns The icon corresponding with the choice.
	 */
	// aqui
	function getsubMenuItemsIcons(choiceCode) {
		switch (choiceCode) {
			case 311:
				return (
					<View style={styles.subMenuOptionsItems.subMenuItemOptionsContainer}>
						<PeopleAltRounded style={styles.subMenuOptionsItems.subMenuOptionsItemsIcons} />
					</View>
				)
			case 312:
				return (
					<View style={styles.subMenuOptionsItems.subMenuItemOptionsContainer}>
						<ArticleRounded style={styles.subMenuOptionsItems.subMenuOptionsItemsIcons} />
					</View>
				)
			case 313:
				return (
					<View style={styles.subMenuOptionsItems.subMenuItemOptionsContainer}>
						<DateRangeRounded style={styles.subMenuOptionsItems.subMenuOptionsItemsIcons} />
					</View>
				)
			case 621:
				return (
					<View style={styles.subMenuOptionsItems.subMenuItemOptionsContainer}>
						<DescriptionRounded style={styles.subMenuOptionsItems.subMenuOptionsItemsIcons} />
					</View>
				)
			case 622:
				return (
					<View style={styles.subMenuOptionsItems.subMenuItemOptionsContainer}>
						<UploadFileRounded style={styles.subMenuOptionsItems.subMenuOptionsItemsIcons} />
					</View>
				)
			default:
				return (
					<View style={styles.subMenuOptionsItems.subMenuItemOptionsContainer}>
						<QuestionMarkRoundedIcon style={styles.subMenuOptionsItems.subMenuOptionsItemsIcons} />
					</View>
				)
		}
	}

	/**
	 * Utility function to render the menu items.
	 * 
	 * @param {*} item - Item to be rendered
	 * @returns A renderable main option for side bar.
	 */
	const RenderMainMenuItem = React.memo(({ item }) => {
		return item.isVisible && <MenuItem title={item.title} choice={item.choice} hasSubMenus={item.hasSubMenus} link={item.link} />;
	});


	/**
	 * Synonymous with Menu Item. These are the main options on the nav bar. If an item has submenus, it will also display its submenus.
	 * 
	 * @param {*} title -  Title text for the menu
	 * @param {*} choice - Unique code that identifies that option
	 * @param {*} hasSubMenus - A boolean for whether that menu has children submenus which will need rendering
	 * @param {*} link - The link for the main menu
	 * 
	 * @returns Menu Item and its submenu items with proper text, icon and navigation set. 
	 */
	const MenuItem = React.memo(({ title, choice, hasSubMenus, link }) => {
		const isSelected = isMainMenuChoice(choice);

		const handlePress = useCallback(() => {
			handleMainOptionPress(hasSubMenus, choice, link);
		}, [choice, hasSubMenus, link, handleMainOptionPress]);

		const renderSubMenu = useMemo(() => {
			if (!isSelected) return null;

			switch (currentMainMenuId) {
				case 3:
					return <SubMenuItem subMenuItems={adminSubMenus} mainMenuItemId={choice} />;
				case 4:
					return <SubMenuItem subMenuItems={leaderSubMenus} mainMenuItemId={choice} />;
				case 5:
					return <SubMenuItem subMenuItems={reviewSubMenus} mainMenuItemId={choice} />;
				case 6:
					return <SubMenuItem subMenuItems={requestSubMenus} mainMenuItemId={choice} />;
				case 7:
					return <SubMenuItem subMenuItems={reportSubMenus} mainMenuItemId={choice} />;
				case 8:
					return <SubMenuItem subMenuItems={resourcesSubMenus} mainMenuItemId={choice} />;
				case 9:
					return <SubMenuItem subMenuItems={helpSubMenus} mainMenuItemId={choice} />;
				default:
					return null;
			}
		}, [isSelected, currentMainMenuId, choice]);

		return (
			<View key={choice}>
				<TouchableOpacity
					style={isSelected ? styles.options.selectedOption : styles.options}
					onPress={handlePress}
				>
					{getMainMenuIcon(choice)}
					<Text style={styles.options.optionsText}>{title}</Text>
					{hasSubMenus && <ArrowDropDownRoundedIcon style={styles.options.dropDownIcon} />}
				</TouchableOpacity>
				{renderSubMenu}
			</View>
		);
	});


	/**
	 * Renders Sub Menu Items on the NavBar, which are the items that display when a certain menu is expanded.
	 * 
	 * @param {*} subMenuItems - The Sub Menu options to be rendered
	 * @param {*} subMenuItems - The Main Menu choice code that will be attached to this Sub Menu
	 * @returns A list of submenu options rendered with their text, icon and navigation set.
	 */
	const SubMenuItem = React.memo(({ subMenuItems, mainMenuItemId }) => {
		const renderSubMenuOption = useCallback((item) => {
			const isSelected = isSubMenuChoice(item.choice);

			const handlePress = () => {
				handleSubMenuOptionPress(item.link, item.choice, mainMenuItemId, item.hasSubmenuOptions, item);
			};

			const renderSubMenuOptions = useMemo(() => {
				if (!isSelected) return null;

				switch (currentSubMenuId) {
					case 31:
						if (navState.canSeeAdminConsole) {
							return <SubMenuOptionsItem options={adminSubMenuOptions} subMenuId={currentSubMenuId} mainMenuItemId={mainMenuItemId} />;
						}
						break;
					case 62:
						return <SubMenuOptionsItem options={request1380SubMenuOptions} subMenuId={currentSubMenuId} mainMenuItemId={mainMenuItemId} />;
					case 71:
						return <SubMenuOptionsItem options={sharpSubMenuOptions} subMenuId={currentSubMenuId} mainMenuItemId={mainMenuItemId} />;
					default:
						return null;
				}
			}, [isSelected, currentSubMenuId, navState.canSeeAdminConsole, mainMenuItemId]);

			return (
				item.isVisible && (
					<View key={item.choice}>
						<TouchableOpacity
							style={isSelected ? styles.subMenuItems.selectedSubMenu : styles.subMenuItems}
							onPress={handlePress}
						>
							{getSubMenuIcon(item.choice)}
							<Text style={styles.subMenuItems.subMenuItemsText}>{item.title}</Text>
							{item.hasSubmenuOptions && <ArrowDropDownRoundedIcon style={styles.options.dropDownIcon} />}
						</TouchableOpacity>
						{renderSubMenuOptions}
					</View>
				)
			);
		}, [currentSubMenuId, handleSubMenuOptionPress, isSubMenuChoice, mainMenuItemId, navState.canSeeAdminConsole]);

		return <View>{subMenuItems.map(renderSubMenuOption)}</View>;
	});

	const SubMenuOptionsItem = React.memo(({ options, subMenuId, mainMenuItemId }) => {
		const renderOption = useCallback((item) => {
			const handlePress = () => {
				handlePopOutOptionPress(item.link, item.choice, subMenuId, mainMenuItemId);
			};

			return (
				item.isVisible && (
					<View key={item.choice}>
						<TouchableOpacity style={styles.subMenuOptionsItems} onPress={handlePress}>
							{getsubMenuItemsIcons(item.choice)}
							<Text style={styles.subMenuOptionsItems.subMenuOptionsItemsText}>{item.title}</Text>
						</TouchableOpacity>
					</View>
				)
			);
		}, [handlePopOutOptionPress, mainMenuItemId, subMenuId]);

		return <View>{options.map(renderOption)}</View>;
	});

	/**
	 * Handles the press of a Main Menu option. Function also handles the toggling of Main Menu tabs
	 * that have any Sub Menus, also resets the navigation state when switching between Main Menu items, 
	 * handles direct navigation for static Main Menu items (no Sub Menus). Hanldes the Sign Out Main Menu
	 * press.
	 *
	 * @param {boolean} hasSubMenus - Indicates if the clicked Main Menu item has any submenus.
	 * @param {number} choiceCode - A unique identifier for the main menu item that was clicked.
	 * @param {string} link - The navigation link or route associated with the main menu item. This is used
	 *                         for direct navigation for items without submenus.
	 */
	const handleMainOptionPress = useCallback((hasSubMenus, choiceCode, link) => {
		if (!hasSubMenus) {
			if (link === 'SignOut') {
				signOut();
			} else {
				// Navigate directly to the page from static main menu
				navigation.navigate(link);
				setNavState({
					...navState,
					openTab: choiceCode,
					origTab: navState.openTab,
					mainMenuTab: choiceCode,
				});
			}
		} else if (hasSubMenus && choiceCode !== currentMainMenuId) {
			// Toggle the main menu item
			setNavState({
				...navState,
				openTab: choiceCode,
				origTab: navState.openTab,
				mainMenuTab: choiceCode,
			});
		} else {
			// Untoggle the main menu item
			setNavState({
				...navState,
				openTab: null,
				origTab: navState.openTab,
				mainMenuTab: null,
			});
		}
	}, [currentMainMenuId, navState, signOut]);

	/**
	 * Manages the execution when a Sub Menu option is clicked. This function decides whether to navigate
	 * based on the link provided by the Sub Menu component or toggle the visibility of the Sub Menu Options.
	 * It will update the navState to reflect a more precise state of our current navigation.
	 *
	 * @param {string} link - The navigation link associated with the Sub Menu option. This is used for direct navigation
	 *                        when the Sub Menu option does not have additional Sub Menu options.
	 * @param {number} subMenuChoiceCode - An optional identifier for the Sub Menu option. Used to determine navigation
	 *                                     logic and to manage the navState for the Sub Menu's.
	 * @param {number} mainMenuChoiceCode - The identifier for the Main Menu item where the Sub Menu option is contained,
	 *                                      this helps us know which Main Menu is currently expanded (toggled).
	 * @param {boolean} hasSubmenuOptions - Indicates whether the Sub Menu has further Sub Menu options to display,
	 *                                      this determines whether navigation occurs or Sub Menu options are toggled.
	 * @param {Object} item - An object containing additional information that might be needed for
	 * 						  Sub Menu navigation.
	 */
	const handleSubMenuOptionPress = useCallback((link, subMenuChoiceCode, mainMenuChoiceCode, hasSubmenuOptions, item) => {
		if (!hasSubmenuOptions) {
			navigateBasedOnLink(link, subMenuChoiceCode, mainMenuChoiceCode, item);
			setNavState({
				...navState,
				openTab: subMenuChoiceCode,
				origTab: navState.openTab,
				mainMenuTab: mainMenuChoiceCode,
				subMenuTab: subMenuChoiceCode,
				subMenuOptionTab: null
			});
		} else if (hasSubmenuOptions && subMenuChoiceCode !== currentSubMenuId) {
			// Toggle the sub menu item
			setNavState({
				...navState,
				openTab: subMenuChoiceCode,
				origTab: navState.openTab,
				mainMenuTab: mainMenuChoiceCode,
				subMenuTab: subMenuChoiceCode,
				subMenuOptionTab: null
			});
		} else {
			// Untoggle the sub menu item
			setNavState({
				...navState,
				openTab: null,
				origTab: navState.openTab,
				mainMenuTab: mainMenuChoiceCode,
				subMenuTab: null,
				subMenuOptionTab: null
			});
		}
	}, [currentSubMenuId, navState]);

	/**
	 * Manages the execution when a Sub Menu Option is clicked. This function also updates the navState
	 * to reflect which Sub Menu Option was just clicked to maintain consistency across the application
	 * from Main Menu's all the way down to the Sub Menu Options
	 *
	 * @param {string} link - The navigation link associated with the Sub Menu option. This is used for direct navigation
	 *                        when the Sub Menu option does not have additional Sub Menu options.
	 * @param {number} subMenuChoiceCode - An optional identifier for the Sub Menu option. Used to determine navigation
	 *                                     logic and to manage the navState for the Sub Menu's.
	 * @param {number} mainMenuChoiceCode - The identifier for the Main Menu item where the Sub Menu option is contained,
	 *                                      this helps us know which Main Menu is currently expanded (toggled).
	 */
	const handlePopOutOptionPress = useCallback((link, choice, subMenuChoiceCode, mainMenuChoiceCode) => {
		navigateBasedOnLink(link, subMenuChoiceCode, mainMenuChoiceCode);
		setNavState({
			...navState,
			openTab: choice,
			origTab: navState.openTab,
			mainMenuTab: mainMenuChoiceCode,
			subMenuTab: subMenuChoiceCode,
			subMenuOptionTab: choice
		});
	}, [navState]);

	/**
	 * Hanldes the app's navigation based on different conditions. We use the Main Menu choice code to 
	 * determine which page to navigate to, we also use the Sub Menu choice code to determine what specific
	 * page within the Main Menu we should navigate to. Handles both external links and internal navigation
	 * within the application. 
	 *
	 * @param {string} link - The URL or route to navigate to. External URLs are opened in a new tab,
	 *                        while internal routes trigger app navigation.
	 * @param {number|null} subMenuChoiceCode - An optional Sub Menu choice code that may affect navigation logic,
	 *                                          particularly for internal app navigation.
	 * @param {number|null} mainMenuChoiceCode - An optional main menu choice code that may affect navigation logic,
	 *                                           particularly for internal app navigation.
	 * @param {Object|null} item - An optional object containing additional information that might be needed for
	 * 							   Sub Menu navigation, such as the requests tab ('New1380' or 'YourFiles')
	 */
	function navigateBasedOnLink(link, subMenuChoiceCode = null, mainMenuChoiceCode = null, item = null) {
		if (link.includes('https')) {
			window.open(link, '_blank');
			return;
		}

		if (link === 'New1380' || link === 'YourFiles') {
			navigation.navigate(link, { yfData: {}, from1380: link == 'New1380' })
		}
		// Navigation based on mainMenuChoiceCode and subMenuChoiceCode
		else if (mainMenuChoiceCode === 3) {
			if (subMenuChoiceCode === 31 || subMenuChoiceCode === 36) {
				navigation.navigate('units_dashboard', { screen: link });
				return;
			} else {
				navigation.navigate('admin_dashboard', { screen: link });
				return;
			}
		} else if (mainMenuChoiceCode === 4) {
			navigation.navigate('leaders_dashboard', { screen: link });
			return;
		} else if (mainMenuChoiceCode === 5) {
			if (subMenuChoiceCode === 85) {
				navigation.navigate('units_dashboard', { screen: link });
			} else {
				navigation.navigate('units_dashboard', { screen: link, title: item?.title ?? "title", unit_id: item?.unit_id ?? 0 });
			}
		} else if (subMenuChoiceCode && subMenuChoiceCode >= 66 && subMenuChoiceCode <= 68) {
			const selectedType = formTypes[subMenuChoiceCode] || null;
			navigation.navigate(link, selectedType ? { selectedType } : {});
		} else {
			navigation.navigate(link);
		}
	}

	/**
	 * Utility function to help differentiate whether the current item is the selected item
	 * from the Main Menu.
	 * 
	 * @param {*} choice choice code of the selected Main Menu Item
	 * @returns true if the menu item is the one selected, false otherwise
	 */
	const isMainMenuChoice = useMemo(
		() => (choice) => isSubMenuOpen && choice === currentMainMenuId,
		[isSubMenuOpen, currentMainMenuId]
	);


	/**
	 * Utility function to help differentiate whether the current item is the selected item
	 * from the Sub Menu.
	 * 
	 * @param {*} choice choice code of the selected Sub Menu Item
	 * @returns true if the menu item is the one selected, false otherwise
	 */
	const isSubMenuChoice = useMemo(
		() => (choice) => isSubMenuOptionsOpen && choice === currentSubMenuId,
		[isSubMenuOptionsOpen, currentSubMenuId]
	);



	// Sign out function
	const signOut = async () => {
		await removeData('user_data');
		await removeData('idToken');

		await setUser(null);

		global.token = null;
		global.user_data = null;
		global.profile_pic = null;
		global.revFiles = null;
		global.approvFiles = null;
		global.signature_uri = null;
		global.editUserID = null;
		global.reviewRec = null;
		global.reviewRecRst = null;
		global.reviewRst = null;
		global.recRst = null;

		navigation.navigate('Login');
	};


	/******************************/
	/*********** STYLES ***********/
	/******************************/
	const { colors } = useColorContext();
	const scale = useScale();

	const styles = {
		container: {
			flex: 1.5,
			height: '100%',
			backgroundColor: colors.navSideBar_Background,
			scrollbarWidth: 'none', // For Firefox
			msOverflowStyle: 'none', // For Internet Explorer/Edge
			borderRightWidth: 5,
			borderColor: 'black'
		},
		logoWrapper: {
			height: '15%',
			width: '100%',
			paddingTop: '10%',
			paddingRight: '10%',
			paddingLeft: '5%',
			logo: {
				width: '100%',
				height: '100%',
				resizeMode: 'contain',
			},
		},
		options: {
			flexDirection: 'row',
			height: '100%',
			width: '100%',
			paddingTop: width < 1400 ? (width < 1000 ? (width < 800 ? '10%' : '7%') : '5%') : '3%',
			paddingBottom: width < 1400 ? (width < 1000 ? (width < 800 ? '10%' : '7%') : '5%') : '3%',
			paddingLeft: width < 1483 ? '2.5%' : '5%',
			paddingRight: '5%',
			alignItems: 'center',

			selectedOption: {
				flexDirection: 'row',
				height: '100%',
				width: '100%',
				paddingTop: width < 1400 ? (width < 1000 ? (width < 800 ? '10%' : '7%') : '5%') : '3%',
				paddingBottom: width < 1400 ? (width < 1000 ? (width < 800 ? '10%' : '7%') : '5%') : '3%',
				paddingLeft: width < 1483 ? '2.5%' : '5%',
				paddingRight: '5%',
				alignItems: 'center',
				backgroundColor: colors.selectedOption
			},
			optionsItemContainer: {
				width: '15%',
				aspectRatio: 1,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			},
			optionsIcon: {
				fontSize: width < 1300 ? '2vh' : '3vh',
				color: colors.bow,
			},
			optionsImageIcon: {
				height: '100%',
				width: '100%',
				resizeMode: 'contain',
				tintColor: colors.bow,
			},
			optionsText: {
				width: '80%',
				paddingLeft: '4%',
				fontFamily: 'Trebuchet MS',
				fontSize: width < 1483 ? (width < 1183 ? (width < 883 ? '16px' : '17px') : '18px') : '19px',
				color: colors.bow,
			},
			dropDownIcon: {
				width: '25%',
				fontSize: 25,
				color: colors.bow,
			},
		},
		subMenuItems: {
			flexDirection: 'row',
			height: '100%',
			width: '100%',
			paddingTop: width < 1400 ? (width < 1000 ? (width < 800 ? '10%' : '7%') : '5%') : '3%',
			paddingBottom: width < 1400 ? (width < 1000 ? (width < 800 ? '10%' : '7%') : '5%') : '3%',
			paddingLeft: width < 1483 ? '10%' : '20%',
			paddingRight: '3%',
			alignItems: 'center',
			backgroundColor: colors.expandedSubmenuOptions,

			selectedSubMenu: {
				flexDirection: 'row',
				height: '100%',
				width: '100%',
				paddingTop: width < 1400 ? (width < 1000 ? (width < 800 ? '10%' : '7%') : '5%') : '3%',
				paddingBottom: width < 1400 ? (width < 1000 ? (width < 800 ? '10%' : '7%') : '5%') : '3%',
				paddingLeft: width < 1483 ? '10%' : '20%',
				paddingRight: '3%',
				alignItems: 'center',
				backgroundColor: colors.selectedSubMenuOption
			},
			subMenuItemContainer: {
				width: '13%',
				aspectRatio: 1,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			},
			subMenuItemsIcons: {
				fontSize: width < 1300 ? '2vh' : '3vh',
				color: colors.bow,
			},
			subMenuImageIcons: {
				height: '100%',
				width: '100%',
				resizeMode: 'contain',
				tintColor: colors.bow,
			},
			subMenuItemsText: {
				width: '80%',
				paddingLeft: '4%',
				fontFamily: 'Trebuchet MS',
				fontSize: width < 1483 ? (width < 1183 ? (width < 883 ? '13px' : '14px') : '15px') : '16px',
				color: colors.bow,
			},
		},
		subMenuOptionsItems: {
			flexDirection: 'row',
			height: '100%',
			width: '100%',
			paddingTop: width < 1400 ? (width < 1000 ? (width < 800 ? '10%' : '7%') : '5%') : '3%',
			paddingBottom: width < 1400 ? (width < 1000 ? (width < 800 ? '10%' : '7%') : '5%') : '3%',
			paddingLeft: width < 1483 ? '16.5%' : '31%',
			paddingRight: '0%',
			alignItems: 'center',
			backgroundColor: colors.expandedSubmenuOptions,


			subMenuItemOptionsContainer: {
				width: '11%',
				aspectRatio: 1,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			},
			subMenuOptionsItemsIcons: {
				fontSize: width < 1300 ? '2vh' : '3vh',
				color: colors.bow,
			},
			subMenuOptionsItemsImages: {
				height: '100%',
				width: '100%',
				resizeMode: 'contain',
				tintColor: colors.bow,
			},
			subMenuOptionsItemsText: {
				width: '80%',
				paddingLeft: '4%',
				fontFamily: 'Trebuchet MS',
				fontSize: width < 1483 ? (width < 1183 ? (width < 883 ? '11px' : '12px') : '13px') : '14px',
				color: colors.bow,
			},
		}
	};

	/**********************/
	/** RETURN COMPONENT **/
	/**********************/
	return (
		<>
			<View style={{ width: '17%', borderRightWidth: 1, borderRightColor: colors.border_color }}>
				<Scrollbars autoHide style={styles.container}>
					<View style={styles.logoWrapper}>
						<Image style={styles.logoWrapper.logo} source={colors.logo} />
					</View>
					<FlatList
						data={options}
						renderItem={({ item }) => <RenderMainMenuItem item={item} />}
						keyExtractor={item => item.choice}
					/>
				</Scrollbars>
			</View>
		</>
	);
});

export default NavSideBar;