import React, { useContext, createContext, useState, useEffect } from 'react';

const NavBarContext = createContext();

export const useNavBarContext = () => {
  const context = useContext(NavBarContext);
  if (!context) {
    throw new Error('useNavBarContext must be used within a NavBarProvider');
  }
  return context;
};

//checks if the given user has any admin functionality, if they have at least one, they can view the admin console.
export function checkIfAdmin() {
  return global.user_data.permissions?.includes(26);
}

export function checkIfApprover() {
  return global.user_data.permissions?.includes(29)
    || global.user_data.permissions?.includes(30)
    || global.user_data.permissions?.includes(31)
    || global.user_data.permissions?.includes(32)
    || global.user_data.permissions?.includes(40);
}

export default function NavBarProvider({ children }) {
  const storedState = JSON.parse(localStorage.getItem('navState')) || {};

  const initialState = {
    origTab:2,
    openTab:null,
    mainMenuTab: 2,
    subMenuTab:null,
    subMenuOptionTab: null,
    //These are set to useStates so when they are changed a given page rerenders them yay
    canSeePayManagement: useState(false),
    canEditPayManagement: useState(false),
    canSeeLeadersDash: useState(false),
    canEditLeadersDash: useState(false),
    canSeeAdminConsole: useState(false),
    canSeeReview: useState(false),
    canSeeRecommend: useState(false),
    isSuperUser: useState(false),
    canSeeUnitInfo: useState(false),
    canSeeRoles: useState(false),
    canSeeUsers: useState(false),
    canUploadUnitDocuments: useState(false),
    canUploadBaDates: useState(false)

  };

  const [navState, setNavState] = useState({ ...initialState, ...storedState });

  useEffect(() => {
    // Update permissions being held in the file every time the permissions of the user are changed.
    // Might only happen one time in the lifespan of a refresh but I still want to put it here to be safe.
    if (global.user_data) {
      const updatedState = {
        ...navState,
        canSeePayManagement: global.user_data.permissions?.includes(34),
        canEditPayManagement: global.user_data.permissions?.includes(33),
        canSeeLeadersDash: global.user_data.permissions?.includes(36),
        canEditLeadersDash: global.user_data.permissions?.includes(35),
        canSeeAdminConsole: checkIfAdmin(),
        canSeeReview: checkIfApprover(),
        canSeeRecommend: global.user_data.permissions?.includes(28),
        isSuperUser:global.user_data.role?.includes('SUPERUSER'),
        canSeeUnitInfo: checkIfAdmin(),
        canSeeRoles: checkIfAdmin(),
        canSeeUsers: global.user_data.permissions?.includes(27),
        canUploadUnitDocuments: global.user_data.permissions?.includes(37),
        canUploadBaDates: global.user_data.permissions?.includes(39)
      };
      setNavState(updatedState);
      localStorage.setItem('navState', JSON.stringify(updatedState));
    }
  }, [navState.origTab, navState.openTab, navState.mainMenuTab, navState.subMenuTab, navState.subMenuOptionTab]); // use effect runs every time a new tab is entered to make sure the permissions are what they seem!

  return <NavBarContext.Provider value={{ navState, setNavState }}>{children}</NavBarContext.Provider>;
}
