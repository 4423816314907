/* eslint-disable no-trailing-spaces */
/* eslint-disable camelcase */
/* eslint-disable linebreak-style */
/* eslint-disable max-len */
/* eslint-disable require-jsdoc */
import React, {useState} from 'react';

import {useScale} from '../components/scale';
import CustomHeader from '../components/header';
import {ses_email} from '../api_handler/events';
import {useColorContext} from '../components/ColorContext';
import NavSideBar from '../components/NavSideBar';
import {create_bug_report} from '../api_handler/bug_report';
import {useSnackbar} from '../components/SnackbarContext';
import {Select, MenuItem, InputLabel, FormControl} from '@mui/material';

import {
  Text,
  View,
  TouchableOpacity,
  Image,
  TextInput,
  Button,
  ScrollView,
} from 'react-native';
import * as Progress from 'react-native-progress'


function BugReport({navigation}) {
  const {colors} = useColorContext();
  const {showSnackbar} = useSnackbar();
  const [refresh, setRefresh] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState(null);
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [base64Images, setBase64Images] = useState([]);
  const [displayImages, setDisplayImages] = useState([]);
  const [fileAttached, setFileAttached] = useState(false);
  const [emailSendingStatus, setEmailSendingStatus] = useState('');
  const [imageAdded, setImageAdded] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [platform, setPlatform] = useState('Web');
  const [mobileOS, setMobileOS] = useState('Android');
  const [ticketType, setTicketType] = useState('Bug Report');

  const scale = useScale();
  const styles = ({
    container: {
      flex: 1,
      backgroundColor:  colors.screen_background,
    },
    wrapper: {
      flexDirection: 'row',
      height: '100%',
    },
    list: {
      width: '75%',
    },
    title: {
      fontSize: scale(50),
      fontFamily: 'Trebuchet MS',
      marginTop: '3%',
      color:  colors.text,
      marginBottom: '2.5%',
    },
    fileAttachedText: {
      fontSize: scale(13),
      marginBottom: 10,
      color:  colors.text,
    },
    button: {
      marginTop: 5,
    },
    header: {
      backgroundColor: 'pink',
      height: '15%',
    },
    main: {
      marginTop: '0%',
      backgroundColor:  colors.screen_background,
      height: '85%',
      alignItems: 'center',
      flex: 2,
    },
    welcome: {

    },
    item: {
      backgroundColor: 'orange',
      padding: 20,
      marginVertical: 8,
      marginHorizontal: 16,
      borderRadius: 8,
      shadowColor: '#000',
      shadowOffset: {width: 3, height: 3},
      shadowOpacity: 0.3,
      shadowRadius: 8,
    },
    image: {
      width: 200, // adjust width as needed
      height: 200, // adjust height as needed
      resizeMode: 'contain',
      margin: 5,
      alignSelf: 'center',
    },
    imageAddedText: {
      textAlign: 'center',
      fontSize: 14,
      marginVertical: 5,
      color:  colors.wob,
    },
    helpBtn: {
      width: '40%',
      backgroundColor: '#0d1232',
      borderRadius: '40',
      alignItems: 'center',
      color: 'white',
      borderRadius: 15,
      shadowColor: '#171717',
      shadowOffset: {width: -2, height: 4},
      shadowOpacity: 0.2,
      shadowRadius: 3,
      marginTop: 30,
      marginBottom: 30,
    },
    innerText: {
      color: colors.button_text,
      fontWeight: 'bold',
      padding: 10,
    },
    textInputProps: {
      '& .MuiFormLabel-root, & label.Mui-focused, & .MuiInputBase-input': {
        color: colors.inputted_text,
        // WebkitTextFillColor:  colors.inputted_text ,
      },
      '.MuiSvgIcon-root': {
        fill: colors.inputted_text,
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: colors.border_color,
        },
        '&:hover fieldset': {
          borderColor: colors.border_color,
        },
        '&.Mui-focused fieldset': {
          borderColor: colors.border_color,
        },
      },
      '&  .MuiFormHelperText-root': {
        backgroundColor: colors.screen_background,
        margin: 0,
        paddingLeft: 1,
      },
      // .MuiFormHelperText-root.Mui-error
    },
    inputSubject: {
      width: '75%',
      margin: 15,
      borderWidth: 1,
      borderRadius: 5,
      padding: 10,
      backgroundColor: colors.modal_background,
      color: colors.text,
      borderColor: colors.border_color
    },
    inputDescription: {
      minHeight: 150,
      width: '75%',
      margin: 15,
      borderWidth: 1,
      borderRadius: 5,
      padding: 10,
      textAlignVertical: 'top',
      backgroundColor: colors.modal_background,
      color: colors.text,
      borderColor: colors.border_color
    },
    selectProps: {
      MenuProps: {
        sx: {
          '& .MuiMenu-paper': {
            background: colors.textfield_background,
            color: colors.inputted_text,
          },
        },
      }
    },
    select: {
      width: '75%',
      margin: 15,
    },
    dropDownStyle: {
      borderColor: colors.border_color,
      borderWidth: 1,
      borderRadius: 5,
      width: '75%',
      // marginTop: '1%',
      margin: 15,
      backgroundColor: colors.modal_background,
    },
    dropDownMenu: {
      backgroundColor: colors.bow,
    },
  });

  function isValidEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  function uploadAttachment() {
    const input = document.createElement('input');
    input.type = 'file';
    input.multiple = true;

    input.onchange = (e) => {
      for (let i = 0; i < e.target.files.length; i++) {
        getBase64(e.target.files[i]);
      }
    };

    input.click();
  }

  function getBase64(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {
      let base64 = reader.result;

      let fileType = '';
      if (base64.includes('data:image/jpeg')) {
        fileType = 'jpg';
        base64 = base64.replace('data:image/jpeg;base64,', '');
      } else if (base64.includes('data:image/png')) {
        fileType = 'png';
        base64 = base64.replace('data:image/png;base64,', '');
      } else {
        showSnackbar('error', 'Unsupported file type. Please upload a PNG or JPEG file.');
        return; // Exit the function early
      }

      setDisplayImages((displayImages) => [...displayImages, {data: base64, type: fileType}]);
      setBase64Images((base64Images) => [...base64Images, base64]);
      setFileAttached(true);
    };
    reader.onerror = function(error) {
      console.log('Error: ', error);
    };
  }

  const removeImage = (index) => {
    const newDisplayImages = displayImages.filter((img, idx) => idx !== index);
    const newBase64Images = base64Images.filter((img, idx) => idx !== index);

    setDisplayImages(newDisplayImages);
    setBase64Images(newBase64Images);

    // Update the imageAdded state
    if (newDisplayImages.length === 0) {
      setImageAdded(false);
    }
  };

  const displayImagesArray = () => {
    return displayImages.map((image, index) => {
      return (
        <View key={index}>
          <Image
            source={{uri: `data:image/${image.type};base64,${image.data}`}}
            style={styles.image}
          />
          <TouchableOpacity onPress={() => removeImage(index)}>
            <View style={{marginBottom: 10}}> {/* add as much space as you need */}
              <Text style = {styles.imageAddedText}> Remove Image</Text>
            </View>
          </TouchableOpacity>
        </View>
      );
    });
  };

  const handleTicketTypeChange = (event) => {
    setTicketType(event.target.value);
  };

  const handlePlatformChange = (event) => {
    setPlatform(event.target.value);
  };

  const handleMobileOSChange = (event) => {
    setMobileOS(event.target.value);
  };

  const loadingStyles = {
    animation: {
      flexDirection: 'row',
      alignItems: 'center',
      flex: 1,
      justifyContent: 'center',
      paddingVertical: 20,
      background: colors.screen_background,
    },
  }

  if (isLoading) {
    return (
      <View style={loadingStyles.animation}>
        <Progress.CircleSnail
          color={colors.loading_circle}
          indeterminate={true}
          size={275}
          thickness={8}
        />
      </View>
    );
  }

  return (
    <ScrollView style = {styles.container}>
      <View style = {styles.wrapper}>
        <View style = {styles.main}>
          <Text style = {styles.title}>{ticketType}</Text>
          <FormControl fullWidth style={styles.dropDownStyle} sx={styles.textInputProps}>
            <InputLabel id="type_select" style={{color: colors.inputted_text}}>Select Ticket Type*</InputLabel>
            <Select
              inputProps={styles.selectProps}
              label="Select Report Type"
              labelId='type_select'
              value={ticketType}
              onChange={handleTicketTypeChange}
              styles={styles.select}
              display
            >
              <MenuItem style={{color: colors.inputted_text}} value={'Bug Report'}>Bug Report</MenuItem>
              <MenuItem style={{color: colors.inputted_text}} value={'Feature Request'}>Feature Request</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth style={styles.dropDownStyle} sx={styles.textInputProps}>
            <InputLabel id="platform_select" style={{color: colors.inputted_text}}>Select Platform*</InputLabel>
            <Select
              inputProps={styles.selectProps}
              label="Select platform"
              labelId='platform_select'
              value={platform}
              onChange={handlePlatformChange}
              styles={styles.select}
              display
            >
              <MenuItem style={{color: colors.inputted_text}} value={'Web'}>Web</MenuItem>
              <MenuItem style={{color: colors.inputted_text}} value={'Mobile'}>Mobile</MenuItem>
              <MenuItem style={{color: colors.inputted_text}} value={'Web/Mobile'}>Web & Mobile</MenuItem>
            </Select>
          </FormControl>
          {(platform === 'Mobile' || platform === 'Web/Mobile') && (
            <FormControl fullWidth style={styles.dropDownStyle} sx={styles.textInputProps}>
              <InputLabel id="mobileOS_select" style={{color: colors.inputted_text}}>Select Mobile OS*</InputLabel>
              <Select
                inputProps={styles.selectProps}
                label="Select Mobile OS"
                labelId='mobileOS_select'
                value={mobileOS}
                onChange={handleMobileOSChange}
                styles={styles.select}
                display
              >
                <MenuItem style={{color: colors.inputted_text}} value={'Android'}>Android</MenuItem>
                <MenuItem style={{color: colors.inputted_text}} value={'iOS'}>iOS</MenuItem>
                <MenuItem style={{color: colors.inputted_text}} value={'Android & iOS'}>Android & iOS</MenuItem>
              </Select>
            </FormControl>
          )}
          <TextInput
            style={styles.inputSubject}
            placeholder= "Name*"
            placeholderTextColor={colors.placeholder_text}
            onChangeText={setName}
            defaultValue={name}
          />
          <TextInput
            style={styles.inputSubject}
            placeholder= "Email*"
            placeholderTextColor={colors.placeholder_text}
            onChangeText={setEmail}
            defaultValue={email}
          />
          <TextInput
            style={styles.inputSubject}
            placeholder= "Subject*"
            placeholderTextColor={colors.placeholder_text}
            onChangeText={setSubject}
            defaultValue={subject}
          />
          <TextInput
            multiline
            style={styles.inputDescription}
            placeholder= "Describe the issue in detail here*"
            placeholderTextColor={colors.placeholder_text}
            onChangeText={setBody}
            defaultValue={body}
          />
          <View style={{marginBottom: 10}}> {/* add as much space as you need */}
            <Button
              color={colors.bg_color}
              title="Add screenshot"
              onPress={uploadAttachment}
            />
          </View>
          {displayImagesArray()}
          <View style={{marginBottom: 10}}> {/* add as much space as you need */}
            <Button
              color={ colors.bg_color}
              title="Create email"
              onPress={async () => {
                setIsLoading(true);
                if (name === '' || subject === '' || email === '' || body === '') {
                  showSnackbar('error', 'Please fill in all the required fields.');
                  setIsLoading(false);
                  return;
                }

                if (!isValidEmail(email)) {
                  showSnackbar('error', 'Please enter a valid email address.');
                  setIsLoading(false);
                  return;
                }
                setEmailSendingStatus('Sending...');

                const jira_data = {
                  name: name,
                  email: email,
                  subject: subject,
                  body: `${body}\n\nLogged in as: ${global.user_data.email}`,
                  attachments: base64Images,
                  ticket_type: ticketType,
                  platform: platform,
                  mobileOS: platform !== 'Web' ? mobileOS : 'N/A',
                };

                const email_data = {
                  subject: subject,
                  bugReport: `Name: ${name}\nEmail: ${email}\nDescription: ${body}`,
                  attachments: base64Images,
                };
  
                const jira_response = await create_bug_report(jira_data);
              
                // Assuming that the token is available in the current scope
                const email_response = await ses_email(email_data, token);
                if (email_response.status === 200) {
                  showSnackbar('success', 'Email sent!');
                  setIsLoading(false);
                  navigation.navigate('Home')
                } else {
                  setEmailSendingStatus('')
                  showSnackbar('error', 'Application Error: Failed to send email');
                  setIsLoading(false);
                }
              }}
            />

          </View>

          <Text style = {styles.imageAddedText}>{emailSendingStatus}</Text>

        </View>
      </View>
    </ScrollView>
  );
};


export default BugReport;
