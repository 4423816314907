import { filesAPI, s3API, API_ENV } from './api_settings';
import axios from 'axios';

const S3Bucket = API_ENV === 'PROD' ? 's3?key=electric-eagles/' : 's3?key=electric-eagles-dev/';

/**
 * Fetches all details for a single user file
 *
 * @param {Object} data - Data contains following information:
 * - form_id (int): Indentifier for the form in the database
 * - form_type (string): The type of file we have [RST, 1380-, EBDL, ATA, MDR]
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 *
 * @example
 * get_single_file({ 'form_id': props.route.params.form_id, 'form_type': props.route.params.form_type }, global.idToken);
 */
export const get_single_file = async (data, token) => {
  const url_endpoint = filesAPI + 'files/get_single_file';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  const res = await axios
    .post(url_endpoint, data, { headers: header })
    .then((response) => response.data)
    .catch((error) => console.error(error.message));
  
  return res;
};

/**
 * Retrieves all subordinate's budget data
 *
 * @param {Object} data - Data contains following information:
 * - user_id (int): Identifier for the user
 * - permissions (Array<int>): Array of ints that will hold the various permissions a user has
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 *
 * @example
 * get_budget_summary({ user_id: global.user_data.user_id, permissions: permissions: global.user_data.permissions}, global.idToken);
 */
export const get_budget_summary = async (data, token) => {
  const url_endpoint = filesAPI + 'files/get_budget_summary';
  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.post(url_endpoint, data, { headers: header });
    return response;
  } catch (error) {
    console.log(error.response)
    if (error.response) {
      return error.response;
    } else {
      return {
        statusCode: 500,
        body: 'An unexpected error occurred',
      };
    }
  }
};

/**
 * Fetches all files a user is a reviewer for
 *
 * @param {Object} data - Data contains following information:
 * - role (string): An identifier for which base permissions a user has (used specifically to check if user is ara)
 * - user_id (int): An identifier for the user
 * - permissions (Array<int>): All permissions attach to a user (endpoint checks for [3001, 3002, 3003, 3004])
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 *
 * @example
 * get_review_files({ 'role': global.user_data.role, 'user_id': global.user_data.user_id, 'permissions': global.user_data.permissions }, global.idToken);
 */
export const get_review_files = async (data, token) => {
  const url_endpoint = filesAPI + 'files/get_review_files';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.post(url_endpoint, data, { headers: header });
    return response;
  } catch (error) {
    console.log(error.response)
    if (error.response) {
      return error.response;
    } else {
      return {
        statusCode: 500,
        body: 'An unexpected error occurred',
      };
    }
  }
};

/**
 * Fetches all files a user is a recommender for its more for RST FLL
 *
 * @param {Object} data - Data contains following information:
 * - user_id (int): An identifier for the user
 * - permissions (Array<int>): All permissions attach to a user (endpoint checks for [3001])
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 *
 * @example
 * get_recommend_files({ 'user_id': global.user_data.user_id, 'permissions': global.user_data.permissions }, global.idToken);
 */
export const get_recommend_files = async (data, token) => {
  const url_endpoint = filesAPI + 'files/get_recommend_files';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.post(url_endpoint, data, { headers: header });
    return response;
  } catch (error) {
    console.log(error.response)
    if (error.response) {
      return error.response;
    } else {
      return {
        statusCode: 500,
        body: 'An unexpected error occurred',
      };
    }
  }
};

/**
 * UNUSED API ENDPOINT
 * 
 * Reason: Took over the project and wasn't notified for the reason API isn't being used.
 * Date: 4/4/2024
 * Author: Reynaldo Martinez
 */
export const get_approved_files = async (data, token) => {
  const info = JSON.stringify({ user_id: data.user_id, role: data.role });
  const url_endpoint = filesAPI + 'files/get_approved_files';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.post(url_endpoint, info, { headers: header });
    return response;
  } catch (error) {
    console.log(error.response)
    if (error.response) {
      return error.response;
    } else {
      return {
        statusCode: 500,
        body: 'An unexpected error occurred',
      };
    }
  }
};

/**
 * Fetches all approved 1380 type forms
 *
 * @param {Object} data - Data contains following information:
 * - user_id (int): An identifier for the user account
 * - unit_id (int): An identifier for the unit the account is apart of
 * - permissions (Array<int>): All permissions attach to a user (endpoint checks for [3001])
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 *
 * @example
 * await get_approved_1380s({ user_id: data.user_id, role: data.role, unit_id: data.unit_id, permissions: data.permissions, admin_unit_ids: data.admin_unit_ids }, global.token);
 */
export const get_approved_1380s = async (data, token) => {
  const info = JSON.stringify({ user_id: data.user_id, role: data.role, unit_id: data.unit_id, permissions: data.permissions, admin_unit_ids: data.admin_unit_ids });
  const url_endpoint = filesAPI + 'files/get_approved_1380s';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.post(url_endpoint, info, { headers: header });
    return response;
  } catch (error) {
    console.log(error.response)
    if (error.response) {
      return error.response;
    } else {
      return {
        statusCode: 500,
        body: 'An unexpected error occurred',
      };
    }
  }
};

/**
 * Fetches all files for a user
 *
 * @param {Object} data - Data contains following information:
 * - user_id (int): An identifier for the user
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 *
 * @example
 * get_all_user_files({ user_id: global.user_data.user_id }, global.idToken);
 */
export const get_all_user_files = async (data, token) => {
  const info = JSON.stringify({ user_id: data });
  const url_endpoint = filesAPI + 'files/get_all_user_files';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.post(url_endpoint, info, { headers: header });
    return response;
  } catch (error) {
    console.log(error.response)
    if (error.response) {
      return error.response;
    } else {
      return {
        statusCode: 500,
        body: 'An unexpected error occurred',
      };
    }
  }
};

/**
 * UNUSED API ENDPOINT
 * 
 * Reason: Took over the project and wasn't notified for the reason API isn't being used.
 * Date: 4/4/2024
 * Author: Reynaldo Martinez
 */
export const get_all_files = async (data, token) => {
  const info = JSON.stringify({ limit: data.limit, offset: data.offset });
  const url_endpoint = filesAPI + 'files/get_all_files';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.post(url_endpoint, info, { headers: header });
    return response;
  } catch (error) {
    console.log(error.response)
    if (error.response) {
      return error.response;
    } else {
      return {
        statusCode: 500,
        body: 'An unexpected error occurred',
      };
    }
  }
};

/**
 * Fetches all subordinates files for each person a user manages
 *
 * @param {Object} data - Data contains following information:
 * - user_id (int): Identifier for the user
 * - permissions (Array<int>): Array of ints that will hold the various permissions a user has
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 *
 * @example
 * get_all_subordinates_files({ user_id: global.user_data.user_id, admin_unit_ids: global.user_data.admin_unit_ids, permissions: permissions: global.user_data.permissions}, global.idToken);
 */
export const get_all_subordinates_files = async (data, token) => {
  const url_endpoint = filesAPI + 'files/get_all_subordinates_files';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.post(url_endpoint, data, { headers: header });
    return response;
  } catch (error) {
    console.log(error.response)
    if (error.response) {
      return error.response;
    } else {
      return {
        statusCode: 500,
        body: 'An unexpected error occurred',
      };
    }
  }
};

/**
 * UNUSED FUNCTION
 * 
 * Reason: This function was used for an old version of the home page
 * Date: 4/4/2024
 * Author: Reynaldo Martinez
 */
export const getFiles = async () => {
  const timezoneOffset = ((new Date()).getTimezoneOffset() / 60);

  const temp = {};
  
  if (global.user_data.role === 'ara' || global.user_data.role === 'ARA') {
    var rev = await get_all_files(temp, global.token);
  } else {
    var rev = await get_all_user_files(global.user_data.user_id, global.token);
  }

  if (rev.status === 200) {
    rev = rev.data;
    (rev.body).map((file) => {
      if (file.status == 1) // Pending (Yellow)
      {
        file.status = 'Ready for Review      Requestor: ';
        file.timestamp[0] = new Date(file.timestamp[0]);
        const time0Temp = file.timestamp[0].toString();
        var time0 = time0Temp.substring(0, time0Temp.length - 32) + time0Temp.substring(33, time0Temp.length);
        file.timestamp = '  Submitted on ' + time0;
        file.pic = require('../assets/doc_yellow.png');

        if (file.requestType == undefined) {
          file.requestType = '';
        }
      }

      if (file.status == 2) // Recommended But Not Reviewed (Yellow)
      {
        if (file.request_type == 'RST_Form') {
          file.status = 'Pending Recommender Signature      Requestor: ';
          file.timestamp[0] = new Date(file.timestamp[0]);
          const time0Temp = file.timestamp[0].toString();
          var time0 = time0Temp.substring(0, time0Temp.length - 32) + time0Temp.substring(33, time0Temp.length);
          file.timestamp = '  Submitted on ' + time0;
          file.pic = require('../assets/doc_orange.png');
        } else {
          file.status = 'Ready for Review      Requestor: ';
          file.timestamp[0] = new Date(file.timestamp[0]);
          const time0Temp = file.timestamp[0].toString();
          var time0 = time0Temp.substring(0, time0Temp.length - 32) + time0Temp.substring(33, time0Temp.length);
          file.timestamp = '  Submitted on ' + time0;
          file.pic = require('../assets/doc_orange.png');

          if (file.requestType == undefined) {
            file.requestType = '';
          }
        }
      }

      if (file.status == 3) // Recommended (Yellow)
      {
        file.status = 'Recommended  Requestor: ';

        file.timestamp[0] = new Date(file.timestamp[0]);
        const time0Temp = file.timestamp[0].toString();
        var time0 = time0Temp.substring(0, time0Temp.length - 32) + time0Temp.substring(33, time0Temp.length);

        file.timestamp[1] = new Date(file.timestamp[1]);
        const time1Temp = file.timestamp[1].toString();
        var time1 = time1Temp.substring(0, time1Temp.length - 32) + time1Temp.substring(33, time1Temp.length);

        file.timestamp = '  Submitted on ' + file.timestamp[0] + '   Recommended on ' + file.timestamp[1];
        file.pic = require('../assets/doc_yellow.png');
        if (file.requestType == undefined) {
          file.requestType = '';
        }
      }

      if (file.status == 4) // Approved (Green)
      {
        file.status = 'Approved      Requestor: ';

        file.timestamp[0] = new Date(file.timestamp[0]);
        const time0Temp = file.timestamp[0].toString();
        var time0 = time0Temp.substring(0, time0Temp.length - 32) + time0Temp.substring(33, time0Temp.length);

        file.timestamp[1] = new Date(file.timestamp[1]);
        const time1Temp = file.timestamp[1].toString();
        var time1 = time1Temp.substring(0, time1Temp.length - 32) + time1Temp.substring(33, time1Temp.length);
        if (file.timestamp_string[1] == 'File Reviewed') {
          file.timestamp = '  Submitted on ' + time0 + '   Approved on ' + time1;
        }

        file.pic = require('../assets/doc_green.png');
        if (file.requestType == undefined) {
          file.requestType = '';
        }
      }

      if (file.status == 5) // Denied (RED)
      {
        file.status = 'Disapproved   Requestor: ';

        file.timestamp[0] = new Date(file.timestamp[0]);
        const time0Temp = file.timestamp[0].toString();
        var time0 = time0Temp.substring(0, time0Temp.length - 32) + time0Temp.substring(33, time0Temp.length);

        file.timestamp[1] = new Date(file.timestamp[1]);
        const time1Temp = file.timestamp[1].toString();
        var time1 = time1Temp.substring(0, time1Temp.length - 32) + time1Temp.substring(33, time1Temp.length);
        if (file.timestamp_string[1] == 'File Reviewed') {
          file.timestamp = '  Submitted on ' + time0 + '   Disapproved on ' + time1;
        }

        file.pic = require('../assets/doc_red.png');

        if (file.requestType == undefined) {
          file.requestType = '';
        }
      }

      if (file.status == 6) // Paid (GREEN STAMP)
      {
        file.status = 'Paid   Requestor: ';

        file.timestamp[0] = new Date(file.timestamp[0]);
        const time0Temp = file.timestamp[0].toString();
        var time0 = time0Temp.substring(0, time0Temp.length - 32) + time0Temp.substring(33, time0Temp.length);

        file.timestamp[1] = new Date(file.timestamp[1]);
        const time1Temp = file.timestamp[1].toString();
        var time1 = time1Temp.substring(0, time1Temp.length - 32) + time1Temp.substring(33, time1Temp.length);
        if (file.timestamp_string[1] == 'File Reviewed') {
          file.timestamp = '  Submitted on ' + time0 + '   Paid on ' + time1;
        }

        file.pic = require('../assets/doc_paid.png');

        if (file.requestType == undefined) {
          file.requestType = '';
        }
      }
    });
  } else {
    return [];
  }

  return rev;
};

/**
 * UNUSED FUNCTION
 * 
 * Reason: This function was used for an old version of the home page
 * Date: 4/4/2024
 * Author: Reynaldo Martinez
 */
export const getApprovedFiles = async () => {
  const timezoneOffset = ((new Date()).getTimezoneOffset() / 60);

  let rev = await get_approved_files(global.user_data, global.token);

  global.approvFiles = rev.data.body;

  if (rev.status === 200) {
    (rev.data.body).map((file) => {
      if (file.status == 1) // Pending (Yellow)
      {
        file.status = 'Ready for Review      Requestor: ';
        file.timestamp[0] = new Date(file.timestamp[0]);
        const time0Temp = file.timestamp[0].toString();
        var time0 = time0Temp.substring(0, time0Temp.length - 32) + time0Temp.substring(33, time0Temp.length);
        file.timestamp = '  Submitted on ' + time0;
        file.pic = require('../assets/doc_yellow.png');

        if (file.requestType == undefined) {
          file.requestType = '';
        }
      }

      if (file.status == 2) // Recommended But Not Reviewed (Yellow)
      {
        if (file.requestType == 'RST') {
          file.status = 'Pending Recommender Signature      Requestor: ';
          file.timestamp[0] = new Date(file.timestamp[0]);
          const time0Temp = file.timestamp[0].toString();
          var time0 = time0Temp.substring(0, time0Temp.length - 32) + time0Temp.substring(33, time0Temp.length);
          file.timestamp = '  Submitted on ' + time0;
          file.pic = require('../assets/doc_orange.png');
        } else {
          file.status = 'Ready for Review      Requestor: ';
          file.timestamp[0] = new Date(file.timestamp[0]);
          const time0Temp = file.timestamp[0].toString();
          var time0 = time0Temp.substring(0, time0Temp.length - 32) + time0Temp.substring(33, time0Temp.length);
          file.timestamp = '  Submitted on ' + time0;
          file.pic = require('../assets/doc_orange.png');

          if (file.requestType == undefined) {
            file.requestType = '';
          }
        }
      }

      if (file.status == 3) // Recommended (Yellow)
      {
        file.status = 'Recommended  Requestor: ';

        file.timestamp[0] = new Date(file.timestamp[0]);
        const time0Temp = file.timestamp[0].toString();
        var time0 = time0Temp.substring(0, time0Temp.length - 32) + time0Temp.substring(33, time0Temp.length);

        file.timestamp[1] = new Date(file.timestamp[1]);
        const time1Temp = file.timestamp[1].toString();
        var time1 = time1Temp.substring(0, time1Temp.length - 32) + time1Temp.substring(33, time1Temp.length);

        file.timestamp = '  Submitted on ' + file.timestamp[0] + '   Recommended on ' + file.timestamp[1];
        file.pic = require('../assets/doc_yellow.png');
        if (file.requestType == undefined) {
          file.requestType = '';
        }
      }

      if (file.status == 4) // Approved (Green)
      {
        file.status = 'Approved      Requestor: ';

        file.timestamp[0] = new Date(file.timestamp[0]);
        const time0Temp = file.timestamp[0].toString();
        var time0 = time0Temp.substring(0, time0Temp.length - 32) + time0Temp.substring(33, time0Temp.length);

        file.timestamp[1] = new Date(file.timestamp[1]);
        const time1Temp = file.timestamp[1].toString();
        var time1 = time1Temp.substring(0, time1Temp.length - 32) + time1Temp.substring(33, time1Temp.length);
        if (file.timestamp_string[1] == 'File Reviewed') {
          file.timestamp = '  Submitted on ' + time0 + '   Approved on ' + time1;
        }

        file.pic = require('../assets/doc_green.png');
        if (file.requestType == undefined) {
          file.requestType = '';
        }
      }

      if (file.status == 5) // Denied (RED)
      {
        file.status = 'Disapproved   Requestor: ';

        file.timestamp[0] = new Date(file.timestamp[0]);
        const time0Temp = file.timestamp[0].toString();
        var time0 = time0Temp.substring(0, time0Temp.length - 32) + time0Temp.substring(33, time0Temp.length);

        file.timestamp[1] = new Date(file.timestamp[1]);
        const time1Temp = file.timestamp[1].toString();
        var time1 = time1Temp.substring(0, time1Temp.length - 32) + time1Temp.substring(33, time1Temp.length);
        if (file.timestamp_string[1] == 'File Reviewed') {
          file.timestamp = '  Submitted on ' + time0 + '   Disapproved on ' + time1;
        }

        file.pic = require('../assets/doc_red.png');

        if (file.requestType == undefined) {
          file.requestType = '';
        }
      }
    });
  } else {
    return [];
  }

  return rev.data.body;
};

/**
 * Allows a user to give a recommendation on a RST known as 'Recommend as First Line Leader (FLL)'
 *
 * @param {Object} data - Data contains following information:
 * - form_id (int): An identifier for the file we are working with
 * - comment (string): The comment the recommender will leave
 * - form_type (string): Identifier for the type of form we are working with
 * - recommender_signature_id (int): An identifier for a recommenders signature (its an int stored in the database that has a corresponding value in S3)
 * - request_is_approved (boolean): Determines if the recommender approved or disapproved
 * - permissions (Array<int>): Permission array that allows us to determine what the user can access (endpoint requires 3001)
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 *
 * @example
 * give_recommendation(data, global.idToken);
 */
export const give_recommendation = async (data, token) => {
  const url_endpoint = filesAPI + 'files/give_recommendation';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.post(url_endpoint, data, { headers: header });
    return response;
  } catch (error) {
    console.log(error.response)
    if (error.response) {
      return error.response;
    } else {
      return {
        statusCode: 500,
        body: 'An unexpected error occurred',
      };
    }
  }
};


/**
 * Allows a user to reviews a RST, EBDL, ATA, MDR
 *
 * @param {Object} data - Data contains following information:
 * - form_id (int): An identifier for the file we are working with
 * - form_type (string): Identifier for the type of form we are working with
 * - reviewer_comment (string): The comment the reviewer will leave
 * - status (int): An int that is either 4 (approved) or 5 (disapproved)
 * - reviewer_signature_id (int): An identifier for a user signature (its an int stored in the database that has a corresponding value in S3)
 * - request_is_approved (boolean): Determines if the recommender approved or disapproved
 * - permissions (Array<int>): Permission array that allows us to determine what the user can access (endpoint requires 3001)
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 *
 * @example
 * review_file(data, global.idToken);
 */
export const review_file = async (data, token) => {
  const url_endpoint = filesAPI + 'files/review_file';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.put(url_endpoint, data, { headers: header });
    return response;
  } catch (error) {
    console.log(error.response)
    if (error.response) {
      return error.response;
    } else {
      return {
        statusCode: 500,
        body: 'An unexpected error occurred',
      };
    }
  }
};

/**
 * Allows a user to review a DA 1380 pay form 
 *
 * @param {Object} data - Data contains following information:
 * - form_id (int): An identifier for the file we are working with
 * - name_10 (string): The submitters name retrieved from the global.user_data (in the form of: Rank First Middle Last, Grade)
 * - signature_id_11 (string): This is the reviewers signature (its an int stored in the database that has a corresponding value in S3)
 * - status (int): An int that is either 4 (approved) or 5 (disapproved)
 * - comments (string): The comments left on the form by the approver
 * - permissions (Array<int>): Permission array that allows us to determine what the user can access (endpoint requires 3001)
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 *
 * @example
 * approve_1380s(data, global.idToken);
 */
export const approve_1380s = async (data, token) => {
  const url_endpoint = filesAPI + 'files/approve_1380';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.post(url_endpoint, data, { headers: header });
    return response;
  } catch (error) {
    console.log(error.response)
    if (error.response) {
      return error.response;
    } else {
      return {
        statusCode: 500,
        body: 'An unexpected error occurred',
      };
    }
  }
};

/**
 * Retrieves the users information regarding AFTP information like current AFTP Number,
 * current Ground AFTP Number, and Bank Time
 *
 * @param {Object} data - Data contains following information:
 * - user_id (int): The identifier for the user
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 *
 * @example
 * get_aftp_information({ user_id: global.user_data.user_id }, global.idToken);
 */
export const get_aftp_information = async (data, token) => {
  const url_endpoint = filesAPI + 'files/get_aftp_information';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.post(url_endpoint, data, { headers: header });
    return response;
  } catch (error) {
    console.log(error.response)
    if (error.response) {
      return error.response;
    } else {
      return {
        statusCode: 500,
        body: 'An unexpected error occurred',
      };
    }
  }
};

/**
 * UNUSED API ENDPOINT
 * 
 * Reason: Took over the project and wasn't notified for the reason API isn't being used.
 * Date: 3/16/2024
 * Author: Reynaldo Martinez
 */
export const update_file = async (data, token) => {
  const url_endpoint = filesAPI + 'files/update_file';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  const res = await axios
    .put(url_endpoint, data, { headers: header })
    .then((response) => response.data)
    .catch((error) => console.error(error.message));

  return res;
};

/**
 * Allows a user to review a DA 1380 pay form 
 *
 * @param {Object} data - Data contains following information:
 * - form_id (int): An identifier for the file we are working with
 * - status (int): An int that is either 4 (Unpaid) or 6 (Paid)
 * - permissions (Array<int>): Permission array that allows us to determine what the user can access
 * - already_paid (string): A boolean string value
 * - timestamp (Date): A date object indiciting the exact time file was updated
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 *
 * @example
 * update_file_status(data, global.idToken);
 */
export const update_file_status = async (data, token) => {
  const url_endpoint = filesAPI + 'files/update_file_status';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.put(url_endpoint, data, { headers: header });
    return response;
  } catch (error) {
    console.log(error.response)
    if (error.response) {
      return error.response;
    } else {
      return {
        statusCode: 500,
        body: 'An unexpected error occurred',
      };
    }
  }
};

/**
 * Allows a user upload a file either RST, 1380, EBDL, ATA, MDR
 * Note: The data object will change depending on the different forms I will include some of the
 * different payloads and specify the type of form they pertain to
 *
 * @param {Object} data - Data contains following information:
 * RST-RST (possible values: [-RST, -Excused Absence, -RMA, -ATA])
 * - creator_id (int): An identifier for who upload the form
 * - comment (string): This is blank will be used for comments from recommenders or approvers
 * - form_name (string): String that will display your name and rank for front end purposes
 * - form_type (string): The type of form in this case RST
 * - request_type (string): The same value as form_type
 * - recommender_id (int): The identifier for the recommender you assigned to your form
 * - reviewer_id (int): The identifier for the reviewer/appover you assigned to your form
 * - status (int): This will be set to 1 to signify "submitted"
 * - timestamp (ISO Date): Left blank on the payload, will be updated by the backend for when the form was submitted
 * - timestamp_string (string): This will be updated with variables to determine what stage the form is along ["File Upload", "File Recommended", "File Approved", "File Paid", "File Disapproved", etc]
 * - name_and_rank (string): This is the name and rank of the user and is used to fill the `form_name` of the payload
 * - date (Date): Will be in the format of `MM/DD/YY` and its set to the day the form was submitted
 * - execused_absence (boolean): A variable to determine if the RST is a 'Excused Absence` type
 * - excused_absence_ba_date (Date): Date object providing what dates correspond to the excused absence
 * - excused_absence_muta_num (int): The number of MUTAs this Excused Absence is suppose to make up
 * - rst (boolean): Variable to determine if this is an RST type of duty 
 * - rst_ba_date (Date): The date in which the RST will be missed
 * - rst_performed_on (Date): The date in which the RST will be made up
 * - rma (boolean): Variable to determine if this is an RMA type of duty
 * - rma_data (Date): The date in which the RMA will be performed
 * - ata (boolean): Variable to determine if this is an ATA type of duty
 * - ata_date (Date): The date in which the ATA will be performed
 * - ata_num_of_ata (int): How many MUTAs will be performed for the ATA
 * - tasks_to_perform (string): A field that will describe what the solider intends to do
 * - justification (string): Reason why the solider is submitting a RST
 * - soldier_signature_id (int): This is the users signature (its an int stored in the database that has a corresponding value in S3)
 * - recommend_approval_date (Date): The date in which a recommendation was provided
 * - request_is_approved (boolean): A variable to determine if the RST has been approved or not
 * - request_is_approved_date (Date): The date when the approval was provided
 * - recommender_signature_id (int): This is the recommenders signature (its an int stored in the database that has a corresponding value in S3)
 * - reviewer_signature_id (int): This is the reviewers signature (its an int stored in the database that has a corresponding value in S3)
 * - recommender_comment (string): The comment left by the recommender
 * - reviewer_comment (string): The comment left by the reviewer
 * - solider_notes (string): Any notes the solider deems necessary for their form
 * - unit_id (string): The id of the unit the solider is apart of
 * - unit_rst_header (string): An identifier that will be used to popualte headers on the form
 * - unit_rst_field_memo (string): An identifier that will be used to popualte headers on the form
 * - unit_rst_memorandum (string): An identifier that will be used to popualte headers on the form
 * - unit_rst_commanding_officer (string): An identifier that will be used to popualte headers on the form
 * 
 * DA 1380-AFTP  (possible values: [-RST, -AFTP, -MDR, -RMA, -EBDL, -OTHER])
 * - creator_id (int): An identifier for who upload the form
 * - comment (string): This is blank will be used for comments from recommenders or approvers
 * - form_name (string): String that will display your name and rank for front end purposes
 * - form_type (string): The type of form which will be a 1380
 * - request_type (string): The same value as form_type
 * - recommender_id (int): The identifier for the recommender you assigned to your form
 * - reviewer_id (int): The identifier for the reviewer/appover you assigned to your form
 * - status (int): This will be set to 1 to signify "submitted"
 * - creation_date_1 (Date): The date in which the form was creation
 * - from_2 (string): What unit this form is coming from (address of unit)
 * - aniversary_year_3 (Date): Left null on all the forms {don't really know what this field is used for}
 * - to_4 (string): Where unit this form is going to (address of unit)
 * - name_5 (string): The name of the user (Last First M.)
 * - grade_6 (string): The grade of the user
 * - branch_7 (string): What branch the user is apart of
 * - individuals_assigned_organization_8 (string): Left blank on all the forms {don't really know what this field is used for}
 * - duty_performed_9 (string): What type of duty was performed [RST, AFTP, MDR, RMA, EBDL, OTHER]
 * - cite_Authorization_9 (string): Army lingo for which company allowed this {don't really know what this field is used for}
 * - date_9a (Array<Date>): This is when the pay form will be performed
 * - description_9d (string): Description pertaining to the pay form
 * - hours_9b (Array<string>): Corresponds to how many mutas are going to be made up 1 MUTA = 4 points, 2 MUTA = 8 points
 * - retirement_points_9c (Array<string>): How many retirment points they will get 1 MUTA = P1, 2 MUTA = P2
 * - name_10 (string): Left empty on all the forms {don't really know what this field is used for}
 * - iperms_12 (boolean): Set to false for the forms {don't really know what this field is used for}
 * - pay_data_12 (string): Left blank for the forms {don't really know what this field is used for, something for the amount of pay for the solider}
 * - attachments (Array<Any>): This will hold any attached documents the solider provides
 * - unit_id (string): The id of the unit the solider is apart of
 * - hdip_acip_authorized_9 (boolean): This is for AFTP forms specifically {don't really know what this field is used for}
 * - aftp_number_9d (Array<string>): Corresponds to the amount of aftps that a user has submitted
 * - support_number_9d (Array<string>): This is something that pertains to soliders that are flight rated
 * - time_in_9d (Array<string>): The hours they started flying (from time in to time out must be a minimum of 4 hours)
 * - time_out_9d (Array<string>): The hours they stopped flying (from time in to time out must be a minimum of 4 hours)
 * - acft_9d (Array<string>): Number that corresponds to which aircraft they flew on
 * - flt_hrs_9d (Array<string>): How many hours were logged during their flight (calculated different from the amount of hours flew)
 * - type_of_traing_completed_9d (string): The type of training performed
 * - bank_time (float): Something that corresponds with the amount of pay they will recieve or have
 * 
 * There are other forms like the Dental, EBDL, ATA, MDR that follows this same structure. I showed the DA 1380-AFTP and RST
 * because these should give you a good idea of the information required from the forms. All forms are very different they all require
 * specific information so to understand the information needed for each form do a CTRL+SHIFT+F for `upload_file` and you can see
 * the data that is built before sending over a payload for this api
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 *
 * @example
 * review_file(data, global.idToken);
 */
export const upload_file = async (data, token) => {
  const url_endpoint = filesAPI + 'files/upload_file';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.post(url_endpoint, data, { headers: header });
    return response;
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      return error.response;
    } else {
      console.error(error.response);
      return { 
        statusCode: 500,
        error: 'An error occurred while uploading the file.' 
      };
    }
  }
};

/**
 * Allows a user to delete a previously submitted file as long as the status isn't approved
 *
 * @param {Object} data - Data contains following information:
 * - user_id (int): The identifier for the user
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 *
 * @example
 * delete_single_file({ user_id: global.user_data.user_id }, global.idToken);
 */
export const delete_single_file = async (data, token) => {
  const url_endpoint = filesAPI + 'files/delete_single_file';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.delete(url_endpoint, {
      headers: header,
      data: data
    });
    return response;
  } catch (error) {
    console.log(error.response)
    if (error.response) {
      return error.response;
    } else {
      return {
        statusCode: 500,
        body: 'An unexpected error occurred',
      };
    }
  }
};

/**
 * Fetches a users information from our S3 bucket like profile photo or user signature
 *
 * @param {Object} data - Data contains following information:
 * - user_id (int): The identifier for the user
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 *
 * @example
 * get_image_s3('pfp/' + global.user_data.user_id, 'image/jpg', global.idToken);
 * get_image_s3('signatures/' + global.user_data.user_id, 'image/jpg', global.idToken);
 */
export const get_image_s3 = async (filepath, content_type, token) => {
  const config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: s3API + S3Bucket + 'users/' + filepath,
    headers: {
      'Authorization': token,
      'Content-Type': content_type,
    },
  };

  const ret = axios.request(config)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error);
    });

  return ret;
};

/**
 * Adds information to the S3 bucket like a new unit, update to a unit,
 * any account modifications, or any additional documents for the user
 *
 * @param {Object} data - Data contains following information:
 * - user_id (int): The identifier for the user
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 *
 */
export const put_s3 = async (filepath, data, content_type, token) => {

  const config = {
    method: 'put',
    maxBodyLength: Infinity,
    maxContentLength: Infinity,
    url: s3API + S3Bucket + filepath,
    headers: {
      'Authorization': token,
      'Content-Type': content_type,
    },
    data: data
  };

  const ret = axios.request(config)
    .then((response) => {
      console.log(response);
      return response.data
    })
    .catch((error) => {
      console.log(error);
  });

  return ret;
};

/**
 * Puts information to our S3 bucket for a user like profile photo or user signature
 *
 * @param {Object} data - Data contains following information:
 * - user_id (int): The identifier for the user
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 *
 * @example
 * put_image_s3('pfp/' + global.user_data.user_id, 'image/jpg', global.idToken);
 * put_image_s3('signatures/' + global.user_data.user_id, 'image/jpg', global.idToken);
 */
export const put_image_s3 = async (filepath, data, content_type, token) => {
  const url_endpoint = s3API + S3Bucket + 'users/' + filepath;

  const header = {
    'Content-Type': content_type,
    'Authorization': token,
  };

  let res = await axios
    .put(url_endpoint, data, { headers: header })
    .then((response) => response.data)
    .catch((error) => console.error(error.message));

  return res;
};

/**
 * Removes information from the S3 bucket. Currently only used 
 * for the removal of unit documents
 *
 * @param {Object} data - Data contains following information:
 * - user_id (int): The identifier for the user
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 *
 */
export const delete_s3 = async (filepath, content_type, token) => {
  const url_endpoint = s3API + S3Bucket + filepath;

  const header = {
    'Content-Type': content_type,
    'Authorization': token,
  };

  const res = await axios
    .delete(url_endpoint, { headers: header })
    .then((response) => response.data)
    .catch((error) => console.error(error.message));

  return res;
};

/**
 * Fetches the unit document information from our S3 bucket
 *
 * @param {Object} data - Data contains following information:
 * - key (string): The folder in the S3 bucket we are searching for
 * - unit_id (int): The identifier for the unit the account is apart of
 * - name (string): The name of the unit document
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 * 
 * @example 
 * await generate_presigned_url_s3({ key: 'units/' + unit_id + '/' + name }, global.token);
 */
export const generate_presigned_url_s3 = async (data, token) => {
  const url_endpoint = filesAPI + 'files/generate_presigned_url_s3';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  const res = await axios
    .post(url_endpoint, data, { headers: header })
    .then((response) => response.data)
    .catch((error) => console.error(error.message));

  return res;
};

/**
 * Fetches the unit document information from our S3 bucket
 *
 * @param {Object} data - Data contains following information:
 * - key (string): The folder in the S3 bucket we are searching for
 * - unit_id (int): The identifier for the unit the account is apart of
 * - name (string): The name of the unit document
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 * 
 * @example 
 * await csv_upload({ key: 'units/' + unit_id + '/' + name }, global.token);
 */
export const csv_upload = async (data, token) => {
  const url_endpoint = filesAPI + 'files/csv_upload';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  const res = await axios
    .post(url_endpoint, data, { headers: header })
    .then((response) => response.data)
    .catch((error) => console.error(error.message));

  return res;
};

/**
 * Allows users to bulk upload a batch of Battle Assembly Dates for their unit
 *
 * @param {Object} data - Data contains following information:
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 * 
 * @example 
 * await upload_BA_dates_CSV({ key: 'units/' + unit_id + '/' + name }, global.token);
 */
export const upload_BA_dates_CSV = async (data, token) => {
  const url_endpoint = filesAPI + 'files/upload_ba_dates_csv';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.post(url_endpoint, data, { headers: header });
    return response;
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      return error.response;
    } else {
      console.error(error.response);
      return { 
        statusCode: 500,
        error: 'An error occurred while uploading the file.' 
      };
    }
  }
};

/**
 * UNUSED API ENDPOINT
 * 
 * Reason: Took over the project and wasn't notified for the reason API isn't being used.
 * Date: 4/4/2024
 * Author: Reynaldo Martinez
 */
export const get_ba_dates_for_rst_forms = async (data, token) => {
  const url_endpoint = filesAPI + 'files/get_ba_dates_for_rst_forms';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.post(url_endpoint, data, { headers: header });
    return response;
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      return error.response;
    } else {
      console.error(error.response);
      return { 
        statusCode: 500,
        error: 'An error occurred while uploading the file.' 
      };
    }
  }
};

/**
 * Allows users to bulk upload a batch of Battle Assembly Dates for their unit
 *
 * @param {Object} data - Data contains following information:

 * @param {string} token - Auth token to be allowed to invoke API
 * 
 * @example 
 * await process_training_info_from_csv({ user_id: global.user_data.user_id,admin_unit_ids: global.user_data.admin_unit_ids, user_full_name: global.name,}, global.token);
 */
export async function process_training_info_from_csv(data, token) {
  const url_endpoint = filesAPI + 'files/add_training_from_csv';
  // const info = JSON.stringify({ user_id: data.user_id, admin_unit_ids: data. });
  console.log(data, token)
  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.post(url_endpoint, data, { headers: header });
    return response;
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      return error.response;
    } else {
      console.error(error.response);
      return { 
        statusCode: 500,
        error: 'An error occurred while uploading the file.' 
      };
    }
  }
}

/**
 * Gets the training information for the user
 *
 * @param {Object} data - Data contains following information:
 * - user_id (int): The identifier for the user
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 *
 * @example
 * get_training_info({ user_id: global.user_data.user_id }, global.idToken);
 */
export const get_training_info = async (data, token) => {
  const url_endpoint = filesAPI + 'files/get_training_info';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.post(url_endpoint, data, { headers: header });
    return response;
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      return error.response;
    } else {
      console.error(error.response);
      return { 
        statusCode: 500,
        error: 'An error occurred while uploading the file.' 
      };
    }
  }
};
