import React from 'react';
import {View, Text, StyleSheet} from 'react-native';
import Day from './Day';
import {useColorContext} from '../ColorContext';
import Scrollbars from 'react-custom-scrollbars';

export default function Month({state, setState}) {
  const {colors} = useColorContext();

  const styles = StyleSheet.create({
    grid: {
      flex: 1,
      width: '80%',
    },
    row: {
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
    dayOfWeek: {
      borderWidth: 1,
      borderColor: 'gray',
      flex: 1,
      fontSize: 28,
      textAlign: 'center',
      color: colors.text,
    },
    dotw: {
      flexDirection: 'row',
      borderBottomWidth: 1,
      borderColor: colors.text,
      border: 1,
    },
  });

  return (
    <Scrollbars style={styles.grid}>
      
      <View>
        <View style={styles.dotw}>
          <Text style={styles.dayOfWeek}>SUN</Text>
          <Text style={styles.dayOfWeek}>MON</Text>
          <Text style={styles.dayOfWeek}>TUE</Text>
          <Text style={styles.dayOfWeek}>WED</Text>
          <Text style={styles.dayOfWeek}>THU</Text>
          <Text style={styles.dayOfWeek}>FRI</Text>
          <Text style={styles.dayOfWeek}>SAT</Text>
        </View>
        {state.currentMonth.map((row, i) => (
          <View>
            <View style={styles.row} key={i}>
              {row.map((day, idx) => (
                <Day
                  day={day}
                  state={state}
                  setState={setState}
                  key={idx}
                />
              ))}
            </View>
          </View>
        ))}
      </View>
    </Scrollbars>
  );
}
