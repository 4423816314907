/* eslint-disable linebreak-style */
/* eslint-disable camelcase */
/* eslint-disable no-trailing-spaces */
/* eslint-disable react/prop-types */
/* eslint-disable comma-dangle */
/* eslint-disable object-curly-spacing */
/* eslint-disable max-len */
/* eslint-disable require-jsdoc */
import React, { useEffect, useState, useCallback } from 'react';
import { Button, Searchbar } from 'react-native-paper';
import {
  View,
  Text,
  Image,
  Dimensions,
  TouchableOpacity,
} from 'react-native';
import { useScale } from '../../components/scale';
import moment from 'moment';
import { AntDesign } from '@expo/vector-icons';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';

import { useIsFocused} from '@react-navigation/native';
// eslint-disable-next-line object-curly-spacing
import { Pagination, Box, createTheme, ThemeProvider, useTheme } from '@mui/material';
import { get_all_user_files } from '../../api_handler/files';
import { useColorContext } from '../../components/ColorContext';
import { getFileDate, formatRstDisplayDate, formatDateToMilDate } from '../../components/FileDataFunctions';
import Scrollbars from 'react-custom-scrollbars';
import { useSnackbar } from '../../components/SnackbarContext';
import * as Progress from 'react-native-progress'

function RstTo1380({ navigation }) {
  const {width} = Dimensions.get('window');
  const scale = useScale();
  const isFocused = useIsFocused();
  const { colors } = useColorContext();
  const { showSnackbar } = useSnackbar();
  const [files, setFiles] = useState([]);
  const [selected, setSelected] = useState(-1);
  const [loaded, setLoaded] = useState(false);
  const [allFiles, setAllFiles] = useState([]);
  const [searchedFiles, setSearchedFiles] = useState([]);
  const [search, setSearch] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [isOpen, setIsOpen] = useState({});
  const defaultFilters = [
    {id: 'Date Ascending', isChecked: false},
    {id: 'Date Descending', isChecked: false},
    {id: 'Form ID Ascending', isChecked: false},
    {id: 'Form ID Descending', isChecked: false},
    {id: 'Submitted', isChecked: false},
    {id: 'Approved', isChecked: false},
    {id: 'Denied', isChecked: false},
    {id: 'Paid', isChecked: false},
    {id: 'Awaiting Review', isChecked: false},
    {id: 'Must Resubmit', isChecked: false},
    {id: 'dental', isChecked: false, isForm: true},
    {id: 'RST', isChecked: false, isForm: true},
    {id: 'ATA', isChecked: false, isForm: true},
    {id: 'EBDL', isChecked: false, isForm: true},
    {id: 'MDR', isChecked: false, isForm: true},
  ];
  const [modalFilters, setModalFilters] = useState(defaultFilters);

  const theme = useTheme();
  const reviewTheme = createTheme({
    palette: {
      mode: colors.mode,
      primary: theme.palette.primary,
    },
  });

  const checkItem = (id) => {
    return modalFilters.some((filter) => filter.id === id && filter.isChecked);
  };

  const handleExclusiveCheck = (id) => {
    setModalFilters((prevFilters) => {
      const isChecked = prevFilters.find((filter) => filter.id === id)?.isChecked;

      return prevFilters.map((filter) => {
        if (
          filter.id === 'Date Ascending' ||
          filter.id === 'Date Descending' ||
          filter.id === 'Form ID Ascending' ||
          filter.id === 'Form ID Descending'
        ) {
          if (filter.id === id) {
            return {
              ...filter,
              isChecked: !isChecked,
            };
          } else {
            return {
              ...filter,
              isChecked: false,
            };
          }
        }
        return filter;
      });
    });
  };

  const toggleListItem = (index) => {
    setIsOpen((prevState) => ({
      ...prevState,
      [index]: !prevState[index], // Toggle the state of the list item
    }));
  };

  const toggleChecked = (id) => {
    setModalFilters((prevData) => {
      return prevData.map((entry) => {
        if (entry.id === id) {
          return {...entry, isChecked: !entry.isChecked};
        }
        return entry;
      });
    });
  };

  // For pagination
  const ITEM_LIMIT = 5;
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    async function getFiles() {
      setFiles([]);

      const res = await get_all_user_files(
          global.user_data.user_id,
          global.token,
      );
      if (res.status === 200) {
        const filtered = res.data.body.filter(
            (file) => file.form_type !== '1380' && file.form_type.toLowerCase() !== '1380 aftp' && file.request_type !== 'RST-EXCUSEDABSENCE' && file.request_type !== 'RST-RMA' && file.request_type !== 'RST-ATA' && (file.status == 1 || file.status == 4),
        );

        if (filtered.length === 0) {
          showSnackbar('warning', 'You have no files to choose from');
          goBack();
          return;
        }

        // after we know there is more than 1, we will get the file date and sort based on it!
        const parsedFiles = filtered.map((file) => ({
          ...file,
          parsedDate: getFileDate(file),
        }));

        parsedFiles.sort((a, b) => b.form_id - a.form_id);

        setFiles(parsedFiles);
        setAllFiles(parsedFiles);
        setSearch('');
        setSelected(-1);
        setLoaded(true);
      } else {
        showSnackbar('error', 'Application Error: Failed to retrieve your files');
        goBack();
      }
    }
    getFiles();
  }, []);

  const styles = {
    container: {
      height: '100%',
      width: '100%',
      flexDirection: 'row',
      backgroundColor: colors.screen_background,
    },
    header: {
      marginTop: '0%',
      height: 110,
    },
    main: {
      flex: 10,
      height: '100%',
    },
    title: {
      fontSize: scale(50),
    },
    pageTitle: {
      fontSize: scale(30),
      fontFamily: 'Trebuchet MS',
      flex: 1,
      color: colors.text,
      paddingLeft: '5%',
      paddingTop: 25,
    },
    description: {
      fontSize: 18,
      marginHorizontal: 5,
      textAlign: 'Left',
      paddingTop: 25,
      color: colors.bow,
      width: '50%',
      fontFamily: 'Trebuchet MS',
      marginBottom: '2%',
    },
    contentBox: {
      backgroundColor: colors.content_box,
      height: '100%',
      width: '75%',
      marginTop: '2%',
      borderRadius: 10,
      justifyContent: 'center',
      padding: '2.5%',
      borderColor: colors.border_color,
      borderRadius: 5,
      borderWidth: 1,
      shadowOffset: {width: 0, height: 2},
      shadowOpacity: 0.25,
      shadowRadius: 4,
    },
    button: {
      height: 120,
      borderRadius: 10,
      padding: 30,
      paddingLeft: 40,
      paddingRight: 40,
      backgroundColor: colors.wob,
      margin: 8,
      marginBottom: 15,
      borderColor: colors.border_color,
      borderWidth: 1,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      flexDirection: 'row',
      justifyContent: 'center',
      flex: 1,
    },
    textStyle: {
      textAlign: 'center',
      fontSize: 15,
      fontWeight: 'bold',
      color: colors.text,
    },
    backText: {
      justifyContent: 'center',
      fontSize: 15,
      fontWeight: 'bold',
    },
    backButton: {
      height: 40,
      width: 160,
      borderRadius: 10,
      alignItems: 'center',
      justifyContent: 'center',
      borderColor: 'black',
      borderWidth: 1,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      backgroundColor: colors.button,
      color: colors.button_text_text,
    },
    searchBar: {
      width: '50%',
      fontSize: 15,
      alignItems: 'space-evenly',
      borderColor: colors.border_color,
      borderRadius: 5,
      borderWidth: 1,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      alignSelf: 'flex-start',
      marginLeft: '50%',
      backgroundColor: colors.modal_background,
      color: colors.text,
    },
    selected: {
      backgroundColor: colors.gold,
    },
    doc: {
      width: 40,
      height: 40,
    },
    checkbox: {
      backgroundColor: colors.modal_background,
      color: colors.gold_button_text,
      fontFamily: 'Trebuchet MS',
      borderRadius: 10,
      justifyContent: 'center',
      padding: '2.5%',
      width: '95%',
      borderColor: colors.border_color,
      borderRadius: '5px',
      borderWidth: '1px',
      borderStyle: 'solid',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    },
    formControlLabel: {
      marginLeft: width < 1373 ? (width < 840 ? '0%' : '5%') : '10%',
      fontFamily: 'Trebuchet MS',
      color: colors.gold_button_text,
    },
    formControLabelText: {
      fontFamily: 'Trebuchet MS',
      fontSize: width < 1587 ? (width < 840 ? 'x-small' : 'small' ) : 'medium',
    },
    filterButton: {
      width: '60%',
      fontFamily: 'Trebuchet MS',
    },
    applyButton: {
      backgroundColor: colors.action_button,
      color: colors.gold_button_text,
      marginRight: width < 1440 ? '0%' : '5%',
      fontFamily: 'Trebuchet MS',
      borderColor: colors.border_color,
      borderWidth: 1,
      shadowOffset: {width: 0, height: 2},
      shadowOpacity: 0.25,
      shadowRadius: 4,
    },
    clearButton: {
      backgroundColor: colors.modal_background,
      color: colors.gold_button_text,
      marginLeft: width < 1440 ? '0%' : '5%',
      fontFamily: 'Trebuchet MS',
      borderColor: colors.border_color,
      borderWidth: 1,
      shadowOffset: {width: 0, height: 2},
      shadowOpacity: 0.25,
      shadowRadius: 4,
    },
  };

  function sendFileID(fileID, formType) {
    const data = {
      form_id: fileID,
      form_type: formType,
      from1380: false,
    };

    // check if file exists
    if (fileID != -1) {
      // change nav to normal 1380 screen just like mobile
      navigation.navigate('Combined1380', data);
    } else {
      showSnackbar('error', 'Please select a file');
    }
  }

  const updateSearch = (search) => {
    setSearch(search);
    if (search === '') {
      setAllFiles(files);
    } else {
      setAllFiles(
          files.filter((file) => {
            return file.request_type.toLowerCase().includes(search.toLowerCase());
          },
          )
      );
    }
  };
  

  const getCurrentFiles = () => {
    const offset = (currentPage - 1) * ITEM_LIMIT;
    const currentFiles = allFiles.slice(offset, offset + ITEM_LIMIT);

    setSearchedFiles(currentFiles);
  };

  const goBack = () => {
    navigation.navigate('Home');
  };

  function select(id) {
    setSelected(id);
  }

  const handlePage = (event, page) => {
    setCurrentPage(page);
  };

  const loadingStyles = {
    animation: {
      flexDirection: 'row',
      alignItems: 'center',
      flex: 1,
      justifyContent: 'center',
      paddingVertical: 20,
      background: colors.screen_background,
    },
  };

  const [isFiltered, setIsFiltered] = useState(false);
  const [filteredSearchResults, setFilteredSearchResults] = useState([]);

  const getCommentForFormType = useCallback((form) => {
    switch (form.form_type) {
        case 'RST': return form.rst_approver_comment;
        case 'EBDL': return form.ebdl_approver_comment;
        case '1380': return form.approver_comment_1380;
        case '1380 aftp': return form.approver_comment_1380;
        case 'ATA': return form.ata_approver_comment;
        case 'MDR': return form.mdr_approver_comment;
        default: return '';
    }
  }, []);

  useEffect(() => {
    getCurrentFiles();
  }, [currentPage, allFiles, filteredSearchResults]);

  if (!loaded) {
    return (
      <View style={loadingStyles.animation}>
        <Progress.CircleSnail
          color={colors.loading_circle}
          indeterminate={true}
          size={275}
          thickness={8}
        />
      </View>
    );
  }

  const updateFilter = () => {
    setSearch('');
    let filteredFiles = [...files];

    // AND search for date and form ID filters
    const dateFilter = modalFilters.find(
        (item) =>
          (item.id === 'Date Ascending' || item.id === 'Date Descending') &&
        item.isChecked,
    );
    const formIDFilter = modalFilters.find(
        (item) =>
          (item.id === 'Form ID Ascending' || item.id === 'Form ID Descending') &&
        item.isChecked,
    );

    if (dateFilter) {
      filteredFiles = filteredFiles
          .map((file) => ({
            ...file,
            parsedDate: getFileDate(file),
          }))
          .sort((a, b) =>
          dateFilter.id === 'Date Ascending' ?
            a.parsedDate - b.parsedDate :
            b.parsedDate - a.parsedDate,
          );
    }

    if (formIDFilter) {
      filteredFiles = filteredFiles.sort((a, b) =>
        formIDFilter.id === 'Form ID Ascending' ?
          a.form_id - b.form_id :
          b.form_id - a.form_id,
      );
    }

    // OR search for form type filters
    const formTypeFilters = modalFilters.filter(
        (item) => item.isForm && item.isChecked,
    );

    if (formTypeFilters.length > 0) {
      filteredFiles = filteredFiles.filter((file) =>
        formTypeFilters.some((filter) => file.form_type === filter.id),
      );
    }
    setCurrentPage(1);
    setAllFiles(filteredFiles);
    setIsFiltered(true);
  };

  const clearFilter = () => {
    setModalFilters(defaultFilters);
    setAllFiles(files);
    setFilteredSearchResults([]);
    setIsFiltered(false);
    setSearch('');
    setCurrentPage(1);
  };

  return (
    <ThemeProvider theme={reviewTheme}>
      <View style={styles.container}>
        {/* <NavSideBar /> */}
        <Scrollbars style={styles.main}>
          <View style={{ alignText: 'center', flex: 3 }}>
            <Text style={styles.pageTitle}>New 1380</Text>
            <View style={{flexDirection: 'row', paddingLeft: '5%', paddingRight: '5%'}}>
              <Text style={{...styles.description, flex: 1}}>
                Select an approved form to pull from. More options will follow when submitting the 1380.
              </Text>
              <View style={{ flexDirection: 'row', flex: 2 }}>
                <Searchbar
                  style={styles.searchBar}
                  placeholderTextColor={colors.bow}
                  iconColor={colors.bow}
                  inputStyle={{ color: colors.bow }}
                  placeholder="Search by Form Type"
                  onChangeText={updateSearch}
                  value={search}
                />
              </View>
            </View>
            <View
              style={{
                flexDirection: 'row',
                paddingLeft: '5%',
                paddingRight: '5%',
              }}
            >
              <View style={styles.contentBox}>
                {searchedFiles.map((file) => (
                  <FileSelect
                    key={file.form_id}
                    id={file.form_id}
                    comment={getCommentForFormType(file)}
                    name={file.form_name}
                    date={file.timestamp}
                    selected={selected}
                    select={select}
                    status={file.status}
                    style={styles.item}
                    filename={file.form_name}
                    filetype={file.form_type}
                    navigation={navigation}
                    requestType={file.request_type}
                    timestamp_msg={file.timestamp_string}
                    attachments={file.attachments}
                    excusedAbsenceBADate={file.excused_absence_ba_date}
                    rstPerformedOn={file.rst_performed_on}
                    rmaDate={file.rma_date}
                    ataDate={file.ata_date}
                    ataDates={file.ata_dates} // This one is the dates filled out in an ATA form
                    ebdlDates={file.ebdl_dates}
                    mdrDates={file.mdr_dates}
                  />
                ))}
              </View>
              <View
                style={{
                  flexDirection: 'column',
                  flex: 2,
                  marginLeft: '2.5%',
                  marginRight: '2.5%',
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '2%',
                }}
              >
                <List style={styles.checkbox}>
                  <ListItemButton onClick={() => toggleListItem('Date Submitted')}>
                    <ListItemText
                      primary="Date Submitted"
                      primaryTypographyProps={{
                        fontFamily: 'Trebuchet MS',
                        fontSize: width < 973 ? (width < 840 ? 'small' : 'medium') : 'large',
                      }}
                    />
                    {isOpen['Date Submitted'] ? (<ExpandLess />) : (<ExpandMore />)}
                  </ListItemButton>
                  <Collapse
                    in={isOpen['Date Submitted']}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkItem('Date Ascending')}
                              onChange={() =>
                                handleExclusiveCheck('Date Ascending')
                              }
                            />
                          }
                          label={
                            <Typography style={styles.formControLabelText}>
                                  Date Ascending
                            </Typography>
                          }
                          style={styles.formControlLabel}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkItem('Date Descending')}
                              onChange={() =>
                                handleExclusiveCheck('Date Descending')
                              }
                            />
                          }
                          label={
                            <Typography style={styles.formControLabelText}>
                                  Date Descending
                            </Typography>
                          }
                          style={styles.formControlLabel}
                        />
                      </FormGroup>
                    </List>
                  </Collapse>
                  <ListItemButton onClick={() => toggleListItem('form_id')}>
                    <ListItemText
                      primary="Form ID"
                      primaryTypographyProps={{
                        fontFamily: 'Trebuchet MS',
                        fontSize: width < 973 ? (width < 840 ? 'small' : 'medium') : 'large',
                      }}
                    />
                    {isOpen['form_id'] ? (<ExpandLess />) : (<ExpandMore />)}
                  </ListItemButton>
                  <Collapse
                    in={isOpen['form_id']}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkItem('Form ID Ascending')}
                              onChange={() =>
                                handleExclusiveCheck('Form ID Ascending')
                              }
                            />
                          }
                          label={
                            <Typography style={styles.formControLabelText}>
                              Form ID Ascending
                            </Typography>
                          }
                          style={styles.formControlLabel}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkItem('Form ID Descending')}
                              onChange={() =>
                                handleExclusiveCheck('Form ID Descending')
                              }
                            />
                          }
                          label={
                            <Typography style={styles.formControLabelText}>
                                Form ID Descending
                            </Typography>
                          }
                          style={styles.formControlLabel}
                        />
                      </FormGroup>
                    </List>
                  </Collapse>
                  <ListItemButton onClick={() => toggleListItem('form_type')}>
                    <ListItemText
                      primary="Form Type"
                      primaryTypographyProps={{
                        fontFamily: 'Trebuchet MS',
                        fontSize: width < 973 ? (width < 840 ? 'small' : 'medium') : 'large',
                      }}
                    />
                    {isOpen['form_type'] ? (<ExpandLess />) : (<ExpandMore />)}
                  </ListItemButton>
                  <Collapse
                    in={isOpen['form_type']}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkItem('dental')}
                              onChange={() =>
                                toggleChecked('dental')
                              }
                            />
                          }
                          label={
                            <Typography style={styles.formControLabelText}>
                              Dental
                            </Typography>
                          }
                          style={styles.formControlLabel}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkItem('RST')}
                              onChange={() =>
                                toggleChecked('RST')
                              }
                            />
                          }
                          label={
                            <Typography style={styles.formControLabelText}>
                              RST
                            </Typography>
                          }
                          style={styles.formControlLabel}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkItem('ATA')}
                              onChange={() =>
                                toggleChecked('ATA')
                              }
                            />
                          }
                          label={
                            <Typography style={styles.formControLabelText}>
                              ATA
                            </Typography>
                          }
                          style={styles.formControlLabel}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkItem('EBDL')}
                              onChange={() =>
                                toggleChecked('EBDL')
                              }
                            />
                          }
                          label={
                            <Typography style={styles.formControLabelText}>
                              EBDL
                            </Typography>
                          }
                          style={styles.formControlLabel}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkItem('MDR')}
                              onChange={() =>
                                toggleChecked('MDR')
                              }
                            />
                          }
                          label={
                            <Typography style={styles.formControLabelText}>
                              MDR
                            </Typography>
                          }
                          style={styles.formControlLabel}
                        />
                      </FormGroup>
                    </List>
                  </Collapse>
                </List>
                <View
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    marginTop: '5%',
                    marginBottom: '5%',
                    justifyContent: 'center',
                    flexWrap: width < 1440 ? 'wrap' : 'nowrap',
                  }}
                >
                  <Button
                    color={colors.gold_button_text}
                    style={[styles.filterButton, styles.applyButton]}
                    onPress={updateFilter}
                    titleStyle
                  >
                    <Text style={{fontSize: width < 972 ? 9 : 15}}>
                      Apply
                    </Text>
                  </Button>
                  <Button
                    color={colors.gold_button_text}
                    style={[styles.filterButton, styles.clearButton]}
                    onPress={clearFilter}
                  >
                    <Text style={{fontSize: width < 972 ? 9 : 15}}>
                      Clear
                    </Text>
                  </Button>
                </View>
              </View>
            </View>
            <Box display="flex" justifyContent="center" marginTop={2}>
              <Pagination
                count={Math.ceil(allFiles.length / ITEM_LIMIT)}
                shape="rounded"
                variant="outlined"
                page={currentPage}
                onChange={handlePage}
                color="primary"
              />
            </Box>
          </View>
        </Scrollbars>
      </View>
    </ThemeProvider>
  );

  function FileSelect({
    id,
    filetype,
    filename,
    requestType,
    date,
    comment,
    timestamp_msg,
    status,
    navigation,
    attachments,
    excusedAbsenceBADate,
    rstPerformedOn,
    rmaDate,
    ataDate,
    ataDates,
    ebdlDates,
    mdrDates,
  }) {
    const docs = {
      1: require('../../assets/doc_yellow.png'),
      2: require('../../assets/approved_rec_doc.png'),
      3: require('../../assets/disapproved_rec_doc.png'),
      4: require('../../assets/doc_green.png'),
      5: require('../../assets/doc_red.png'),
      6: require('../../assets/corrupted_doc.png'),
    };

    const status_names = {
      1: '\nStatus: Submitted ',
      2: '\nStatus: Awaiting Review ',
      3: '\nStatus: Must Resubmit ',
      4: '\nStatus: Approved ',
      5: '\nStatus: Denied ',
      6: '\nFile Corrupted as of ',
    };
    
    const statusDate = formatDateToMilDate(new Date(date[0]).toLocaleDateString());
    let displayDate = formatRstDisplayDate(excusedAbsenceBADate || rstPerformedOn || rmaDate || ataDate || ataDates || ebdlDates || mdrDates || moment(new Date(date[date.length - 1])).format('DDMMMYY').toUpperCase());
    if (filetype.includes('ATA')) {
      displayDate = ataDates;
    }

    if (filetype.includes('EBDL')) {
      displayDate = ebdlDates;
    }

    if (filetype.includes('MDR')) {
      displayDate = mdrDates;
    }
    return (
      <TouchableOpacity
        style={[
          styles.button,
          {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          },
        ]}
        onPress={() => sendFileID(id, filetype)}
      >
        <Image style={styles.doc} source={docs[displayDate === 'Invalid Date' ? 6 : status]} />
        <Text style={styles.textStyle}>
          {filename +
            ' (' +
            id +
            ')' +
            '\n' +
            requestType +
            '\nDate(s): ' +
            displayDate +
            status_names[displayDate === 'Invalid Date' ? 6 : status] +
            statusDate +
            '\n' +
            ((comment && comment !== undefined && comment !== '') ? 'Comment: ' + comment : '')}
        </Text>
        <AntDesign name="right" size={14} color={colors.text} />
      </TouchableOpacity>
    );
  }
}

export default RstTo1380;
