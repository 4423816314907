import React, { useEffect, useState } from 'react';
import { Searchbar } from 'react-native-paper';
import { View, Text, Image, TouchableOpacity, ScrollView, StyleSheet } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { useScale } from '../../components/scale';

import { AntDesign } from '@expo/vector-icons';
import CustomHeader from '../../components/header';
import NavSideBar from '../../components/NavSideBar';
import moment from 'moment';

import { get_review_files } from '../../api_handler/files';
import {createTheme, styled, useTheme,ThemeProvider} from '@mui/material/styles';
import { Pagination, Box } from '@mui/material';
import { useColorContext } from '../../components/ColorContext';
import { getFileDate, formatDateToMilDate } from '../../components/FileDataFunctions';
import Scrollbars from "react-custom-scrollbars";

function Review1380Home({ navigation }) {
  const scale = useScale();
  const { colors } = useColorContext();
  
  const styles = ({
    container: {
      height: '100%',
      width: '100%',
      flexDirection: 'row',
      backgroundColor: colors.screen_background,
    },
    main: {
      flex:10,
      height: '100%'
    },
    titleDiv: {
      flexDirection: 'row',
      width: '100%',
      alignItems: 'center',
    },
    title: {
      fontSize: scale(30),
      fontFamily: 'Trebuchet MS',
      flex: 1,
      color: colors.text,
      paddingLeft: '5%',
      paddingTop: 25
    },
    header: {
      marginTop: '0%',
      height: 110,
      backgroundColor: colors.screen_background,
    },
    pageTitle: {
      fontSize: scale(30),
      fontFamily: 'Trebuchet MS',
      flex: 1,
      color: colors.text,
      paddingLeft: '5%',
      paddingTop: 25
    },
    description: {
      fontSize: 18,
      marginHorizontal: 5,
      textAlign: 'Left',
      paddingTop: 25,
      color:  colors.bow,
      width: '50%',
      fontFamily: 'Trebuchet MS',
      marginBottom: '2%'
    },
    contentBox: {
      backgroundColor: colors.content_box,
      height: '100%',
      width: "90%",
      marginLeft: '5%',
      marginTop: "2%",
      borderRadius: 10,
      justifyContent: "center",
      padding: '2.5%',
    },
    button: {
      height: 80,
      borderRadius: 10,
      padding: 10,
      paddingLeft: 20,
      paddingRight: 20,
      margin: 8,
      marginBottom: 15,
      borderColor: colors.button_border,
      borderWidth: 1,
      shadowOffset: {width: 0, height: 2},
      shadowOpacity: 0.25,
      shadowRadius: 4,
      flexDirection: 'row',
      alignItems: 'center',
      flex: 1,
      backgroundColor: colors.wob,
    },
    textStyle: {
      textAlign: 'center',
      fontSize: 19,
      fontWeight: 'bold',
      color: colors.text,
    },
    backButton: {
      height: 40,
      width: 160,
      borderRadius: 10,
      alignItems: 'center',
      justifyContent: 'center',
      borderColor: 'black',
      borderWidth: 1,
      shadowOffset: {width: 0, height: 2},
      shadowOpacity: 0.25,
      shadowRadius: 4,
      marginTop: '5%',
      backgroundColor: colors.button,
    },
    searchBar: {
      width: "50%",
      fontSize: 15,
      alignItems: "space-evenly",
      borderColor: "black",
      borderRadius: 5,
      borderWidth: 1,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      alignSelf: 'flex-start',
      marginLeft: '50%',
      backgroundColor: colors.modal_background,
      color: colors.text,
    },
    selected: {
      backgroundColor: colors.gold,
    },
    doc: {
      width: 50,
      height: 50,
    }
  });

  const [files, setFiles] = useState([]);
  const [selected, setSelected] = useState(-1);
  const [loaded, setLoaded] = useState(false);
  const [allFiles, setAllFiles] = useState([]);



  const [searchedFiles, setSearchedFiles] = useState([]);
  const [search, setSearch] = useState('');

  const theme = useTheme();
  const reviewTheme = createTheme({
    palette: {
      mode: colors.mode,
      primary: theme.palette.primary,
    },
  });
   
  // For pagination
  const ITEM_LIMIT = 5;
  const [currentPage, setCurrentPage] = useState(1);

  async function getFiles() {
    setFiles([]);

    const reviewFiles = await get_review_files({
      'role': global.user_data.role,
      'user_id': global.user_data.user_id,
      'permissions': global.user_data.permissions
    },
      global.token
    );

    if (reviewFiles !== undefined) {
      const filtered = reviewFiles.body.filter(
        (file) => file.status < 4 && (file.form_type === '1380' || file.form_type === '1380 aftp')
      );

      if (filtered.length === 0) {
        alert("You have no files to review")
        // goBack();
        return;
      }
      //after we know there is more than 1, we will get the file date and sort based on it!
      const parsedFiles = filtered.map(file => ({
        ...file,
        parsedDate: getFileDate(file)
      }))

      parsedFiles.sort((a, b) => b.parsedDate - a.parsedDate);

      setFiles(parsedFiles)
      setAllFiles(parsedFiles);
      setSearch('');
      setSelected(-1);
      setLoaded(true);
    } else {
      alert("You have no files to review")
      goBack();
    }
  }

  useFocusEffect(
    React.useCallback(() => {
      async function getPageInfo() {
        await getFiles();
      }

      getPageInfo();
      setLoaded(true);

    }, [])
  );

  function sendFileID(form_id, form_type, attachments, creator_id) {
    const data = {
      fileID: form_id,
      form_type: form_type,
      attachments: attachments,
      creator_id: creator_id,
    };

    // check if file exists
    if (form_id != -1) {
      navigation.navigate('Review1380', data);
    } else {
      alert('Please select a file');
    }
  }

  const updateSearch = (search) => {
    setSearch(search);
    if (search === '') {
      setAllFiles(files);
    } else {
      setAllFiles(files.filter((file) => file.form_name.toLowerCase().includes(search.toLowerCase())));
    }
  };

  const getCurrentFiles = () => {
    const offset = (currentPage - 1) * ITEM_LIMIT;
    const currentFiles = allFiles.slice(offset, offset + ITEM_LIMIT);

    setSearchedFiles(currentFiles);
  }

  const goBack = () => {
    navigation.navigate('Home');
  };

  function select(id) {
    setSelected(id);
  };

  const handlePage = (event, page) => {
    setCurrentPage(page);
  }

  useEffect(() => {
    getCurrentFiles()
  }, [currentPage, allFiles])

  return (
  
    <ThemeProvider theme={reviewTheme}>
      <View style={styles.container}>
        {/* <NavSideBar /> */}
        {!global.user_data.permissions?.includes(3001) ? (
          <View style={styles.main}>
              <View style={styles.titleDiv}>
                  <Text style={styles.title}>Access Denied: User does not have permission to view this page.</Text>
              </View>
          </View>
        ) : (
        <Scrollbars style={styles.main}>
          <View style={{ flexDirection: 'column' }}>
            <Text style={styles.pageTitle}>Review</Text>
            <View style={{flexDirection: 'row', paddingLeft: '5%', paddingRight: '5%'}}>
              <Text style={{...styles.description, flex: 1}}>
                Select a user's 1380 for review
              </Text>

              <View style={{ flexDirection: 'row', flex: 2 }}>
                <Searchbar
                  style={styles.searchBar}
                  placeholder="Search by Requestor Name"
                  placeholderTextColor={colors.bow}
                  iconColor={colors.bow}
                  inputStyle={{ color: colors.bow }}
                  onChangeText={updateSearch}
                  value={search}
                />
              </View>
            </View>
            <View style={styles.contentBox}>
              {searchedFiles.map((form) => (
                <View>
                  <FileSelect
                    key={form.form_id}
                    id={form.form_id}
                    form_name={form.form_name}
                    selected={selected}
                    select={select}
                    form_type={form.form_type}
                    requestType={form.request_type}
                    timestamp={form.timestamp}
                    attachments={form.attachments}
                    creator_id={form.creator_id}
                    date_9a={form.date_9a}
                  />
                </View>
              ))}
            </View>
            <Box display="flex" justifyContent="center" marginTop={2}>
              <Pagination
                count={Math.ceil(allFiles.length / ITEM_LIMIT)}
                shape="rounded"
                variant='outlined'
                page={currentPage}
                onChange={handlePage}
                color="primary"
              />
            </Box>
          </View>
        </Scrollbars>
        )}
      </View>
    </ThemeProvider> 
  );

  function FileSelect({
    id,
    selected,
    form_name,
    requestType,
    timestamp,
    form_type,
    attachments,
    creator_id,
    date_9a,
  }) {
    const status_names = {
			1: "\nFile Corrupted as of ",
			2: "\nStatus: Awaiting Review "
		};

    let displayDate;
    if(date_9a)
    {
      displayDate = moment(new Date(date_9a[0])).format('DDMMMYY').toUpperCase() + (date_9a.length == 2 ? ", " + moment(new Date(date_9a[1])).format('DDMMMYY').toUpperCase() : '');
    } 
    else 
    {
      displayDate = 'Invalid Date';
    }
    const doc = displayDate === "Invalid Date" ? require('../../assets/corrupted_doc.png') : require('../../assets/doc_yellow.png');
    let statusDate = formatDateToMilDate(new Date(timestamp[0]).toLocaleDateString());

    return (
      <TouchableOpacity
        style={[
          styles.button,
          { flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }
        ]}
        onPress={() => sendFileID(id, form_type, attachments, creator_id)}
      >
        <Image style={styles.doc} source={doc} />
        <Text style={styles.textStyle}>
          {form_name + ' (' + id + ')' +
            '\n' + requestType +
            "\nDate(s): " +
            displayDate +
            status_names[displayDate === "Invalid Date" ? 1 : 2] +
            statusDate
          }
        </Text>
        <AntDesign name="right" size={14} color={colors.bow} />
      </TouchableOpacity>
    );
  }
};

export default Review1380Home;