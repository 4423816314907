import React, { createContext, useState } from 'react';

export const CounterContext = createContext();

export const CounterProvider = ({ children }) => {
    const [unpaidFiles, setUnpaidFiles] = useState(0);
    const [actionFiles, setActionFiles] = useState(0);
  
    const updateUnpaidFiles = (count) => {
      setUnpaidFiles(count);
    };
  
    const updateActionFiles = (count) => {
      setActionFiles(count);
    };
  
    const contextValue = {
      unpaidFiles,
      updateUnpaidFiles,
      actionFiles,
      updateActionFiles,
    };
  
    return (
      <CounterContext.Provider value={contextValue}>
        {children}
      </CounterContext.Provider>
    );
  };