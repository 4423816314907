export function getFileDate(file) {

    if (file.form_type.includes('1380')) {
        if (file.date_9a) {
            if (file.date_9a.length == 2) {
                return (Math.max(new Date(file.date_9a[0]), new Date(file.date_9a[1])));
            }
            return new Date(file.date_9a[0]);
        } else {
            return "01/01/9999";
        }
    }
    else if (file.form_type.includes('RST')) {
        //one of these will be filled out, as it needs to be for the sake of an RST form.
        if (file.excused_absence_ba_date) {
            let excusedDates = file.excused_absence_ba_date.split(",")

            if (excusedDates.length == 2) {
                return parseCustomDate(excusedDates[0]) > parseCustomDate(excusedDates[1]) ? parseCustomDate(excusedDates[0]) : parseCustomDate(excusedDates[1]);
            }
            return parseCustomDate(excusedDates[0])

        }
        //must be the rst_performed on at this point, as one of these need to be filled.
        else {
            if(file.rst_performed_on !== null){
            let excusedDates = file.rst_performed_on.split(",");
            if (excusedDates.length == 2) {
                return parseCustomDate(excusedDates[0]) > parseCustomDate(excusedDates[1]) ? parseCustomDate(excusedDates[0]) : parseCustomDate(excusedDates[1]);
            }
            return parseCustomDate(excusedDates[0]);
            }
        }
    }
    //At this point it could only be a dental form, return the timestamp.
    else {
        return new Date(file.timestamp[file.timestamp.length - 1])
    }

}
const parseCustomDate = (dateStr) => {
    if (!dateStr) {
        return new Date(0);
    }
    let splitString = dateStr.trim().split(" ");
    if (splitString.length == 2) {
        dateStr = splitString[0];
    }


    let day = parseInt(dateStr.substring(0, 2));
    let month = parseMonth(dateStr.substring(2, 5));
    let year = parseInt(dateStr.substring(5, 7)) + 2000;

    return new Date(year, month, day);
};

const parseMonth = (monthStr) => {
    const months = {
        'JAN': 0, 'FEB': 1, 'MAR': 2, 'APR': 3, 'MAY': 4, 'JUN': 5,
        'JUL': 6, 'AUG': 7, 'SEP': 8, 'OCT': 9, 'NOV': 10, 'DEC': 11
    };
    return months[monthStr] || 0;
};

export function formatRstDisplayDate(date) {
    if (date) {
        let arr = date.split(',');
        if (arr.length == 2) {
            return arr[0].trim().split(' ')[0] + ", " + arr[1].trim().split(' ')[0];
        }
        else {
            return arr[0].trim().split(' ')[0];
        }
    }
    else
    {
        return "Invalid Date";
    }
}

/**
 * Returns the month three letter acronym based on MM string passed in
 * @param month string of size 2 in MM format
 * @returns month string in three letter acronym format
 */
const getMonthAcryn = (month) => {
    const months = {
        '1': 'JAN', '2': 'FEB', '3': 'MAR', '4': 'APR', '5': 'MAY', '6': 'JUN',
        '7': 'JUL', '8': 'AUG', '9': 'SEP', '10': 'OCT', '11': 'NOV', '12': 'DEC'
    };
    return months[month];
};

/** 
 * formats timestamp to be in DDMMMYYYY format
 * @param date timestamp at specified position in timestamp array from database
 * @return date formated timestamp at specified position in DDMMMYYYY
 */
export function formatDateToMilDate(date) {
    let arr = date.split('/');
    date = arr[1] + getMonthAcryn(arr[0]) + arr[2];
    return date;
}

/** 
 * formats timestamp time to be in 24 hour format followed by time zone hh:mm timezone format
 * @param timestamp
 * @return formattedTime formated timestamp time in hh:mm timezone
 */
export function formatTime(timestamp) {
    const inputDate = new Date(timestamp);
  
    const options = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      timeZoneName: 'short'
    };
  
    let formattedTime = inputDate.toLocaleString(undefined, options);
  
    return formattedTime;
  }