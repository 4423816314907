import React, { useState, useEffect } from "react";
import { View, Text, TouchableOpacity, StyleSheet } from "react-native";
import { useColorContext } from "../../components/ColorContext";

const CheckboxList = ({ items, onItemSelect, index, selectedValue, error, width, row }) => {
  const [checkedItem, setCheckedItem] = useState(
    selectedValue !== null ? selectedValue : null
  );
  const { colors } = useColorContext();

  const styles = StyleSheet.create({
    container: {
      flexDirection: width > 1300 ? "column" : 'row',
      marginTop: width > 1300 ? "0%" : "3%",
      flexWrap: "wrap",
    },
    checkboxContainer: {
      flexDirection: "row",
      alignItems: "center",
      marginHorizontal: 10,
      marginVertical: 5,
    },
    checkbox: {
      borderWidth: 1,
      borderColor: colors.border_color,
      borderRadius: 4,
      width: 70,
      height: 30,
      alignItems: "center",
      marginRight: 10,
    },
    checkedCheckbox: {
      backgroundColor: colors.gold,
      color: "#FFF",
    },
    uncheckedCheckbox: {
      backgroundColor: colors.modal_background,
    },
    label: {
      fontSize: 16,
      color: colors.bow,
    },
    checkboxText: {
      marginTop: "1.5%",
      fontSize: 16,
      color: global.user_data.theme === "light" ? "#000" : "#FFF",
    },
  });

  const handleCheckboxChange = (item) => {
    setCheckedItem(item);
    if (row !== null) {
      onItemSelect(item, row, row.id);
    } else {
      onItemSelect(item, index);
    }
  };

  const getCheckboxStyles = (item) => {
    const isChecked = item === selectedValue || item === selectedValue;
    return [
      styles.checkbox,
      error ? { borderColor: "red", borderWidth: 1 } : {},
      isChecked ? styles.checkedCheckbox : styles.uncheckedCheckbox,
      isChecked
        ? global.user_data.theme === "light"
          ? { color: "#000" }
          : { color: "#000" }
        : { color: global.user_data.theme === "light" ? "#000" : "#FFF" },
    ];
  };

  return (
    <View style={styles.container}>
      {items.map((item) => (
        <TouchableOpacity
          key={item}
          onPress={() => {
            handleCheckboxChange(item)
          }}
          style={styles.checkboxContainer}
        >
          <View style={getCheckboxStyles(item)}>
            <Text
              style={[
                styles.checkboxText,
                item === checkedItem ? { color: "#000" } : {},
              ]}
            >
              MUTA {item}
            </Text>
          </View>
        </TouchableOpacity>
      ))}
    </View>
  );
};

export default CheckboxList;
