import React, { forwardRef, useCallback } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useColorContext } from './ColorContext';

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SnackbarAlert = ({
    open,
    onClose,
    severity,
    message,
    width,
    autoHideDuration,
    paddingBottom,
    paddingRight,
    anchorVertical,
    anchorHorizontal,
}) => {
    const { colors } = useColorContext();

    const getColorProps = useCallback(() => {
        if (severity === 'error') {
            return {
                rootColor: colors.errorRootColor,
                iconColor: colors.errorIconColor,
                backgroundColor: colors.errorBackgroundColor,
            };
        } else if (severity === 'success') {
            return {
                rootColor: colors.successRootColor,
                iconColor: colors.successIconColor,
                backgroundColor: colors.successBackgroundColor,
            };
        } else {
            return {
                rootColor: colors.warningRootColor,
                iconColor: colors.warningIconColor,
                backgroundColor: colors.warningBackgroundColor,
            }
        }
    }, [severity, colors]);
    
    const { rootColor, iconColor, backgroundColor } = getColorProps();

    return (
        <>
            <Snackbar
            open={open}
            onClose={onClose}
            autoHideDuration={autoHideDuration || 6000}
            anchorOrigin={{ vertical: anchorVertical, horizontal: anchorHorizontal }}
            sx={{
                'width': width || 400,
                'zIndex': 9999,
                'paddingBottom': paddingBottom || '2%',
                'paddingRight': paddingRight || '2%',
                '& .MuiPaper-root, & .MuiAlert-action': {
                    color: rootColor,
                },
                '& .MuiAlert-icon': {
                    color: iconColor,
                },
            }}
            >
            <Alert
                onClose={onClose}
                severity={severity}
                sx={{ 
                    backgroundColor: backgroundColor,
                    '& .MuiAlert-message': {
                        whiteSpace: 'pre-line',
                    },
                }}
            >
                {message}
            </Alert>
            </Snackbar>
        </>
    );
};

export default SnackbarAlert;
