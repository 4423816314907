import React, {useEffect, useReducer, useState, useMemo, useRef} from 'react';
import {View, Text, TouchableOpacity, ScrollView} from 'react-native';

import ModeContext from '../../../components/ModeContext';
import PropTypes from 'prop-types';
import {useIsFocused} from '@react-navigation/native';

import {useScale} from '../../../components/scale';
import {IMaskInput} from 'react-imask';

import {Box, TextField, IconButton} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import {useTheme, darken, createTheme, ThemeProvider} from '@mui/material/styles';
import {Edit, DeleteForeverRounded} from '@mui/icons-material';

import {MaterialReactTable, MRT_FullScreenToggleButton, MRT_ToggleGlobalFilterButton, MRT_ShowHideColumnsButton, MRT_ToggleFiltersButton} from 'material-react-table';
import CustomHeader from '../../../components/adminHeader';
import Modal from 'react-native-modal';

import {get_all_unit_poc, add_unit_poc, update_unit_poc, delete_unit_poc} from '../../../api_handler/units';

import UnitInfoSideNavbar from '../../../components/temp_unit_info_side_navbar';
import {useColorContext} from '../../../components/ColorContext';
import NavSideBar from '../../../components/NavSideBar';
import Scrollbars from 'react-custom-scrollbars';

import { HelperText } from 'react-native-paper';
import { useSnackbar } from '../../../components/SnackbarContext';
import * as Progress from 'react-native-progress'

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const {onChange, ...other} = props;
  return (
    <IMaskInput
      {...other}
      mask="(000) 000-0000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({target: {name: props.name, value}})}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function Unit_POC({navigation}) {
  const {colors} = useColorContext();
  const { showSnackbar } = useSnackbar();
  // Table
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(7);
  const isFocused = useIsFocused();
  const {mode} = React.useContext(ModeContext);
  const tableInstanceRef = useRef(null);
  const scale = useScale();
  const [loaded, setLoaded] = useState(false);

  // States
  const [addPOCModalVis, setAddPOCDocumentModalVis] = React.useState(false);
  const [updatePOCModalVis, setUpdatePOCModalVis] = React.useState(false);
  const [deleteModalVis, setDeleteModalVis] = React.useState(false);
  const [role, setRole] = React.useState('');
  const [roleError, setRoleError] = React.useState(false);
  const [roleErrorText, setRoleErrorText] = React.useState('');
  const [name, setName] = React.useState('');
  const [nameError, setNameError] = React.useState(false);
  const [nameErrorText, setNameErrorText] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [emailError, setEmailError] = React.useState(false);
  const [emailErrorText, setEmailErrorText] = React.useState('');
  const [POCID, setPOCID] = React.useState('');
  const isLight = (mode === 'light');
  const [density, setDensity] = useState('compact');
  const [columnVisibility, setColumnVisibility] = useState({});
  const [rowSelection, setRowSelection] = useState({});
  const [pagination, setPagination] = useState({pageIndex: 0, pageSize: 15});
  const rerender = useReducer(() => ({}), {})[1]; // Forces the MRT_ components to re-render
  const [showColumnFilters, setShowColumnFilters] = useState(false);
  const [showGlobalFilter, setShowGlobalFilter] = useState(false);
  const [hasPermissions, setHasPermissions] = React.useState(global.user_data.permissions?.includes(26));

  const [phoneNumber, setPhoneNumber] = React.useState({
    textmask: '',
    numberformat: '1320',
  });
  const [phoneNumberError, setPhoneNumberError] = React.useState(false);
  const [phoneNumberErrorText, setPhoneNumberErrorText] = React.useState('');

  const styles = ({
    container: {
      backgroundColor: colors.screen_background,
      flexDirection: 'row',
      height: '100%',
    },
    header: {
      height: 125,
    },
    innerContainer: {
      flexDirection: 'column',
      height:'100%',
      flex: 10
    },
    scrollView: {
      backgroundColor:  colors.screen_background,
      height: '100%',
      width: '100%',
    },
    titleAndDescription: {
      title: {
          fontSize: scale(30),
          fontFamily: 'Trebuchet MS',
          flex: 1,
          color: colors.text,
          paddingLeft: '5%',
          paddingTop: 25
      },
      description: {
        fontFamily: 'Trebuchet MS', 
        fontSize: scale(18), 
        color: colors.text,
        paddingLeft: '5%', 
        paddingRight: '5%',
        paddingTop: '1%',
        width: '100%',
      }
    },
    main: {
      height: '100%',
      flexDirection: 'column',
      flex: 10
    },
    buttonDiv: {
      flexDirection: 'row',
      // backgroundColor: 'red',
      height: 100,
      // width: '50%',
      alignItems: 'center',
      justifyContent: 'space-around',
    },
    dropdownInput: {
      'm': 1,
      'width': 300,
      'backgroundColor':  colors.textfield_background,
      '& .MuiFormLabel-root, & .MuiSelect-select': {
        color:  colors.text ,
      },
      // .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input
    },
    textInput: {
      width: '25%',
      margin: 20,
      marginLeft: 30,
      backgroundColor:  colors.textfield_background,
    },
    deleteText: {
      fontSize: 25,
      fontFamily: 'Trebuchet MS',
      textAlign: 'center',
      color:  colors.text,
    },
    textInputProps: {
      '& .MuiFormLabel-root, & label.Mui-focused, & .MuiInputBase-input': {
        color: colors.inputted_text,
      },
      '.MuiSvgIcon-root': {
        fill: colors.inputted_text,
      },
      '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
          borderColor: colors.form_border,
        },
        '&.Mui-focused fieldset': {
          borderColor: '#1876d2',
        },
      },
    },
    tableView: {
      width: '100%',
      alignItems: 'center',
      height: '80%',
      marginBottom: 50,
    },
    tableDiv: {
      marginTop: '0%',
      width: '90%',
      alignItems: 'center',
    },
    buttons: {
      width: '15%',
      borderRadius: '40',
      alignItems: 'center',
      backgroundColor:  colors.button,
      borderRadius: 15,
      shadowColor: '#171717',
      shadowOffset: {width: -2, height: 4},
      shadowOpacity: 0.2,
      shadowRadius: 3,
      marginTop: 30,
      marginBottom: 30,
    },
    innerText: {
      color:  colors.button_text,
      fontWeight: 'bold',
      padding: 10,
      whiteSpace: 'nowrap',
    },
    textbox: {
      width: '80%',
      marginTop: '5%',
      backgroundColor:  colors.textfield_background,
      InputLabelProps: {
        sx: {
          color:  colors.text,
        },
      },
    },
    tableWrapper: {
      width: '91%',
      marginTop: '2%',
      marginLeft: '5%',
      borderColor: colors.border_color,
      borderWidth: global.user_data.theme === 'dark' ? 1 : 0,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
    },
    modal: {
      wrapper: {
        width: '40%',
        height: '100%',
        marginLeft: '37%',
        marginTop: '5%',
        zIndex: 1000,
      },
      addPOC: {
        backgroundColor:  colors.screen_background,
        alignItems: 'center',
        padding: '5%',
        borderRadius: 10,
        flexDirection: 'column',
        title: {
          fontSize: 50,
          fontFamily: 'Trebuchet MS',
          textAlign: 'center',
          color:  colors.text,
        },
      },
      updatePOC: {
        backgroundColor:  colors.screen_background,
        alignItems: 'center',
        padding: '5%',
        borderRadius: 10,
        flexDirection: 'column',
        title: {
          fontSize: 50,
          fontFamily: 'Trebuchet MS',
          textAlign: 'center',
          color:  colors.text,
        },
      },
      deletePOC: {
        backgroundColor:  colors.screen_background,
        alignItems: 'center',
        padding: '5%',
        borderRadius: 10,
        flexDirection: 'column',
      },
      submitButtonWrapper: {
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-around',
        button: {
          width: '15%',
          borderRadius: 40,
          alignItems: 'center',
          backgroundColor:  colors.button,
          shadowColor: '#171717',
          shadowOffset: {width: -2, height: 4},
          shadowOpacity: 0.2,
          shadowRadius: 3,
          marginTop: '5%',
        },
      },
    }
  });

  async function get_unit_documents() {
    const ret = await get_all_unit_poc({'unit_id': global.user_data.unit_id}, global.token);
    if (ret.status === 200) {
      setRows(ret.data.body);
    } else {
      showSnackbar("error", "Application Error: Failed to retrieve your unit's POCs")
      setRows([])
    }
    setLoaded(true);
  }

  useEffect(() => {
    get_unit_documents();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChange = (event) => {
    setPhoneNumber({
      ...phoneNumber,
      [event.target.name]: event.target.value,
    });
    // console.log(phoneNumber);
  };

  function validateRole() {
    if (role == null || role.trim() == '') {
      setRoleError(true);
      setRoleErrorText('Enter a role');
    } else {
      setRoleError(false);
      setRoleErrorText('');
    }
  }

  function validateName() {
    if (name === null || name.trim() === '') {
      setNameError(true);
      setNameErrorText('Enter a name');
    } else {
      setNameError(false);
      setNameErrorText('');
    }
  }

  function validateEmail() {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const trimmed_email = email.trim();
    if (re.test(trimmed_email)) {
      // Email valid. Procees to test if it's from the right domain (Second argument is to check that the string ENDS with this domain, and that it doesn't just contain it)
      if (trimmed_email.indexOf('@army.mil', trimmed_email.length - '@army.mil'.length) !== -1) {
        setEmailError(false);
        setEmailErrorText('');
      } else {
        setEmailError(true);
        setEmailErrorText('Email must end with @army.mil');
      }
    } else {
      setEmailError(true);
      setEmailErrorText('Enter an email');
    }
  }

  function validatePhoneNumber(){
    const re = /^\(\d{3}\)\s\d{3}-\d{4}$/
    if(re.test(phoneNumber.textmask)){
      setPhoneNumberError(false);
      setPhoneNumberErrorText('');
    }
    else{
      setPhoneNumberError(true);
      setPhoneNumberErrorText('Enter a phone number');
    }
  }

  // Add new POC
  async function addPOC() {
    validateEmail();
    validateName();
    validateRole();
    validatePhoneNumber();

    if(emailError || nameError || roleError || phoneNumberError){
      showSnackbar("error", "Please correct one or more invalid inputs");
    } else {
      const data = {
        unit_id: global.user_data.unit_id,
        poc_role: role.trim(),
        poc_name: name.trim(),
        poc_phone_number: phoneNumber.textmask,
        poc_email: email.trim(),
      };
      const ret = await add_unit_poc(data, global.token);
      if (ret.status !== 200) {
        showSnackbar("error", "Application Error: Failed to create new POC")
      } else {
        showSnackbar("success", "Successfully Added New POC")
        get_unit_documents();
        setAddPOCDocumentModalVis(false);
  
        setRole('');
        setName('');
        setPhoneNumber({
          textmask: '',
          numberformat: '1320',
        });
        setEmail('');
        setPOCID('');
      }
    }
  }

  function cancelAdd(){
    setRole('');
    setRoleError(false);
    setRoleErrorText('');
    setName('');
    setNameError(false);
    setNameErrorText('');
    setPhoneNumber({
      textmask: '',
      numberformat: '1320',
    });
    setPhoneNumberError(false);
    setPhoneNumberErrorText('');
    setEmail('');
    setEmailError(false);
    setEmailErrorText('');
    setPOCID('');
    setAddPOCDocumentModalVis(false)
  }

  // Update POC info
  function startUpdateProcess(id, role, name, phoneNumber, email) {
    setRole(role);
    setName(name);
    setPhoneNumber({
      textmask: phoneNumber,
      numberformat: '1320',
    });
    setEmail(email);
    setPOCID(id);
    setUpdatePOCModalVis(true);
  }

  async function updatePOC() {
    validateEmail();
    validateName();
    validateRole();
    validatePhoneNumber();

    if(emailError || nameError || roleError || phoneNumberError){
      showSnackbar("error", "Please correct one or more invalid inputs");
    } else {
      const data = {
        unit_id: global.user_data.unit_id,
        poc_role: role.trim(),
        poc_name: name.trim(),
        poc_phone_number: phoneNumber.textmask,
        poc_email: email.trim(),
        poc_id: POCID,
      };
      const ret = await update_unit_poc(data, global.token);
      if (ret.status !== 200) {
        showSnackbar("error", "Application Error: Failed to create new POC")
      } else {
        showSnackbar("success", "Successfully Updated New POC")
        get_unit_documents();

        setRole('');
        setName('');
        setPhoneNumber({
          textmask: '',
          numberformat: '1320',
        });
        setEmail('');
        setPOCID('');
        setUpdatePOCModalVis(false);
      }
    }
  }

  function cancelUpdate() {
    setRole('');
    setRoleError(false);
    setRoleErrorText('');
    setName('');
    setNameError(false);
    setNameErrorText('');
    setPhoneNumber({
      textmask: '',
      numberformat: '1320',
    });
    setPhoneNumberError(false);
    setPhoneNumberErrorText('');
    setEmail('');
    setEmailError(false);
    setEmailErrorText('');
    setPOCID('');
    setUpdatePOCModalVis(false);
  }

  // Delete Unit Document code
  function startDeleteProcess(id, name) {
    setPOCID(id);
    setName(name);
    setDeleteModalVis(true);
  }

  function cancelDelete() {
    setRole('');
    setName('');
    setPhoneNumber({
      textmask: '',
      numberformat: '1320',
    });
    setEmail('');
    setPOCID('');
    setDeleteModalVis(false);
  }

  const confirmDelete = async () => {
    setDeleteModalVis(false);

    let res = await delete_unit_poc({poc_id: POCID}, global.token);
    if (res.status === 200) {
      showSnackbar("success", "Successfully deleted POC")
      const newRows = rows.filter((pocs) => pocs.poc_id != POCID);
      setRows(newRows);
    } else {
      showSnackbar("error", "Application Error: Failed to delete POC")
    }

    setRole('');
    setName('');
    setPhoneNumber({
      textmask: '',
      numberformat: '1320',
    });
    setEmail('');
    setPOCID('');
  };

  const globalTheme = useTheme();
  const tableTheme = useMemo(
      () =>
        createTheme({
          palette: {
            mode: mode,
            primary: globalTheme.palette.primary,
            background: {
              default:  colors.textfield_background,
            },
          },
        }),
      [globalTheme, isFocused],
  );

  const columns = [
    {
      accessorKey: 'poc_role',
      header: 'Role',
      minWidth: 100,
      align: 'center',
    },
    {
      accessorKey: 'poc_name',
      header: 'Name',
      minWidth: 100,
      align: 'center',
    },
    {
      accessorKey: 'poc_phone_number',
      header: 'Phone Number',
      minWidth: 100,
      align: 'center',
    },
    {
      accessorKey: 'poc_email',
      header: 'Email',
      minWidth: 100,
      align: 'center',
    },
    ...(hasPermissions ? [{ //checks if user has admin permission in which case add delete to columns otherwise don't 
      accessorKey: 'del',
      header: 'Delete POC',
      minWidth: 50,
      align: 'center',
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      enableSorting: false,
      enableColumnFilter: false,
      enableGrouping: false,
      Cell: ({row}) => {
        return (<IconButton onClick={() => {
          startDeleteProcess(row.original.poc_id, row.original.poc_name);
        }}>
          <DeleteForeverRounded style={{color: 'red'}} />
        </IconButton>);
      },
    }] : []),
    ...(hasPermissions ? [{
      accessorKey: 'edit',
      header: 'Update POC',
      minWidth: 50,
      align: 'center',
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      enableSorting: false,
      enableColumnFilter: false,
      enableGrouping: false,
      Cell: ({row}) => {
        const [isHovered, setIsHovered] = React.useState(false);
    
        const handleMouseEnter = () => {
          setIsHovered(true);
        };
    
        const handleMouseLeave = () => {
          setIsHovered(false);
        };

        return (
          <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <IconButton onClick={() => {
              startUpdateProcess(row.original.poc_id, row.original.poc_role, row.original.poc_name, row.original.poc_phone_number, row.original.poc_email);
            }}>
              <Edit style={{ color: isHovered ? 'green' : mode == 'dark' ? colors.white : colors.gray}}/>
            </IconButton>
          </div>
        );
      }
    }] : []),
  ];

  const loadingStyles = {
    animation: {
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
        justifyContent: 'center',
        paddingVertical: 20,
        background: colors.screen_background,
    }
  }

  if (!loaded) {
    return (
      <View style={loadingStyles.animation}>
          <Progress.CircleSnail
            color={colors.loading_circle}
            indeterminate={true}
            size={275}
            thickness={8}
          />
      </View>
    );
  }

  return (

    <Scrollbars showsverticalscrollindicator="true" style={styles.scrollView}>
      <View style={styles.container}>
        {/* <NavSideBar /> */}
        <View style={styles.innerContainer}>
          <View style={styles.main}>
            <View style={{width: '100%'}}>
              <Text style={styles.titleAndDescription.title}>
                <Text style={{fontWeight:'bold'}}>{hasPermissions ? 'Admin Console > ' : 'SHARP Points of Contact' } </Text>
                <Text>{hasPermissions ? 'Manage POCs for ' : 'for ' }{global.user_data.unit_name}</Text>
              </Text>
              <View style={{flexDirection: 'row', marginTop: '1%', alignItems: 'flex-start'}}>
                <Text style={styles.titleAndDescription.description}> {hasPermissions ?
                  'This page allows admins to manage POCs for the Managed Unit. Admins can ' 
                  + 'add, view, edit, and delete POCs. Name, phone number and email for each POC '
                  + 'in this Unit will be visible to all members of the Unit.' 
                  : 
                  'This page provides a list of all SHARP points of contact for ' + global.user_data.unit_name}
                </Text>
              </View>
            </View>

            {/* Add New POC Modal */}
            <Modal isVisible={addPOCModalVis} style={styles.modal.wrapper}>
              <View style={styles.modal.addPOC}>
                <Text style={styles.modal.addPOC.title}>Add New POC</Text>
                
                <TextField
                  style={styles.textbox}
                  sx={styles.textInputProps}
                  label="Role*"
                  value={role}
                  error={roleError}
                  onBlur={() => {
                    validateRole();
                  }}
                  onChange={(data) => setRole(data.target.value)}
                  InputLabelProps={styles.textbox.InputLabelProps}
                  inputProps={styles.textbox.InputLabelProps}
                />
                <HelperText style={{ color: 'red' }}>{roleError ? roleErrorText : ""}</HelperText>
                <TextField
                  style={styles.textbox}
                  sx={styles.textInputProps}
                  label="Name*"
                  value={name}
                  onChange={(data) => setName(data.target.value)}
                  error={nameError}
                  onBlur={() => {
                    validateName();
                  }}
                  InputLabelProps={styles.textbox.InputLabelProps}
                  inputProps={styles.textbox.InputLabelProps}
                />
                <HelperText style={{ color: 'red' }}>{nameError ? nameErrorText : ""}</HelperText>
                <TextField
                  value={phoneNumber.textmask}
                  sx={styles.textInputProps}
                  onChange={handleChange}
                  error={phoneNumberError}
                  onBlur={() => {
                    validatePhoneNumber();
                  }}
                  name="textmask"
                  id="formatted-text-mask-input"
                  label="Phone Number*"
                  InputProps={{
                    inputComponent: TextMaskCustom,
                  }}
                  style={styles.textbox}
                  InputLabelProps={styles.textbox.InputLabelProps}
                  inputProps={styles.textbox.InputLabelProps}
                />
                <HelperText style={{ color: 'red' }}>{phoneNumberError ? phoneNumberErrorText : ""}</HelperText>
                <TextField
                  style={styles.textbox}
                  sx={styles.textInputProps}
                  label="Email*"
                  value={email}
                  onChange={(data) => setEmail(data.target.value)}
                  error={emailError}
                  onBlur={() => {
                    validateEmail();
                  }}
                  InputLabelProps={styles.textbox.InputLabelProps}
                  inputProps={styles.textbox.InputLabelProps}
                />
                <HelperText style={{ color: 'red' }}>{emailError ? emailErrorText : ""}</HelperText>

                <View style={styles.modal.submitButtonWrapper}>
                  <TouchableOpacity
                    style={styles.modal.submitButtonWrapper.button}
                    onPress={() => cancelAdd()}>
                    <Text style={styles.innerText}>Cancel</Text>
                  </TouchableOpacity>

                  <TouchableOpacity
                    style={styles.modal.submitButtonWrapper.button}
                    onPress={() => addPOC()}>
                    <Text style={styles.innerText}>Submit</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </Modal>
            {/* End of Add New POC Modal */}

            {/* Update POC Modal */}
            <Modal isVisible={updatePOCModalVis} style={styles.modal.wrapper}>
              <View style={styles.modal.updatePOC}>
                <Text style={styles.modal.updatePOC.title}>Update POC</Text>
                <TextField
                  style={styles.textbox}
                  sx={styles.textInputProps}
                  label="Role"
                  value={role}
                  onChange={(data) => setRole(data.target.value)}
                  error={roleError}
                  onBlur={() => {
                    validateRole();
                  }}
                  InputLabelProps={styles.textbox.InputLabelProps}
                  inputProps={styles.textbox.InputLabelProps}
                />
                <HelperText style={{ color: 'red' }}>{roleError ? roleErrorText : ""}</HelperText>
                <TextField
                  style={styles.textbox}
                  sx={styles.textInputProps}
                  label="Name"
                  value={name}
                  onChange={(data) => setName(data.target.value)}
                  error={nameError}
                  onBlur={() => {
                    validateName();
                  }}
                  InputLabelProps={styles.textbox.InputLabelProps}
                  inputProps={styles.textbox.InputLabelProps}
                />
                <HelperText style={{ color: 'red' }}>{nameError ? nameErrorText : ""}</HelperText>
                <TextField
                  value={phoneNumber.textmask}
                  sx={styles.textInputProps}
                  onChange={handleChange}
                  error={phoneNumberError}
                  onBlur={() => {
                    validatePhoneNumber();
                  }}
                  name="textmask"
                  id="formatted-text-mask-input"
                  label="Phone Number"
                  InputProps={{
                    inputComponent: TextMaskCustom,
                  }}
                  style={styles.textbox}
                  InputLabelProps={styles.textbox.InputLabelProps}
                  inputProps={styles.textbox.InputLabelProps}
                />
                <HelperText style={{ color: 'red' }}>{phoneNumberError ? phoneNumberErrorText : ""}</HelperText>
                <TextField
                  style={styles.textbox}
                  sx={styles.textInputProps}
                  label="Email"
                  value={email}
                  onChange={(data) => setEmail(data.target.value)}
                  error={emailError}
                  onBlur={() => {
                    validateEmail();
                  }}
                  InputLabelProps={styles.textbox.InputLabelProps}
                  inputProps={styles.textbox.InputLabelProps}
                />
                <HelperText style={{ color: 'red' }}>{emailError ? emailErrorText : ""}</HelperText>

                <View style={styles.modal.submitButtonWrapper}>
                  <TouchableOpacity
                    style={styles.modal.submitButtonWrapper.button}
                    onPress={() => cancelUpdate()}>
                    <Text style={styles.innerText}>Cancel</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={styles.modal.submitButtonWrapper.button}
                    onPress={() => updatePOC()}>
                    <Text style={styles.innerText}>Submit</Text>
                  </TouchableOpacity>
                </View>

              </View>
            </Modal>
            {/* End of Update POC Modal */}

            {/* Delete POC modal */}
            <Modal isVisible={deleteModalVis} style={styles.modal.wrapper}>
              <View style={styles.modal.deletePOC}>
                <Text style={styles.deleteText}>{'Are you sure you would like to delete the POC "' + name + '"?'}</Text>

                <View style={styles.modal.submitButtonWrapper}>
                  <TouchableOpacity
                    style={styles.modal.submitButtonWrapper.button}
                    onPress={() => cancelDelete()}>
                    <Text style={styles.innerText}>Cancel</Text>
                  </TouchableOpacity>

                  <TouchableOpacity
                    style={styles.modal.submitButtonWrapper.button}
                    onPress={() => confirmDelete()}>
                    <Text style={styles.innerText}>Delete POC</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </Modal>
            {/* End of POC Modal */}

            <View style={styles.tableWrapper}>
              <ThemeProvider theme={tableTheme}>
                <MaterialReactTable
                  columns={columns}
                  data={rows}
                  enableRowSelection
                  positionToolbarAlertBanner={'bottom'}
                  tableInstanceRef={tableInstanceRef}
                  enableGrouping
                  enableColumnDragging={false}
                  enableStickyHeader
                  enableExpandAll={false}
                  selectAllMode={'all'}
                  isMultiSortEvent={() => true}

                  initialState={{
                    showGlobalFilter: false,
                    columnOrder: ['poc_role', 'poc_name', 'poc_phone_number', 'poc_email', 'edit', 'del'],
                  }}
                  muiTableBodyProps={{
                    sx: (theme) => ({
                      '& tr:nth-of-type(n)': {
                        backgroundColor:  colors.table_background_color,
                      },
                      '& tr:nth-of-type(2n-1)': {
                        backgroundColor: darken(theme.palette.background.default, 0.075),
                      },
                      '& tr.Mui-selected': {
                        backgroundColor:  colors.table_selected,
                      },
                      '& tr:hover:not(.Mui-selected) > td': {
                        backgroundColor:  colors.table_hover,
                      },
                    }),
                  }}

                  // Table State Management for updater function
                  onColumnVisibilityChange={(updater) => {
                    setColumnVisibility((prev) =>
                                            updater instanceof Function ? updater(prev) : updater,
                    );
                    queueMicrotask(rerender); // Rerender after state update
                  }}


                  onRowSelectionChange={(updater) => {
                    setRowSelection((prev) =>
                                            updater instanceof Function ? updater(prev) : updater,
                    );
                    queueMicrotask(rerender); // Rerender after state update
                  }}

                  onPaginationChange={(updater) => {
                    setPagination((prev) =>
                                            updater instanceof Function ? updater(prev) : updater,
                    );
                    queueMicrotask(rerender); // Rerender after state update
                  }}

                  onShowColumnFiltersChange={(updater) => {
                    setShowColumnFilters((prev) =>
                                            updater instanceof Function ? updater(prev) : updater,
                    );
                    queueMicrotask(rerender); // Rerender after state update
                  }}

                  onShowGlobalFilterChange={(updater) => {
                    setShowGlobalFilter((prev) =>
                                            updater instanceof Function ? updater(prev) : updater,
                    );
                    queueMicrotask(rerender); // Rerender after state update
                  }}

                  state={{
                    columnVisibility,
                    density,
                    rowSelection,
                    pagination,
                    showColumnFilters,
                    showGlobalFilter,
                  }}

                  renderToolbarInternalActions={({table}) => (
                    <>
                      <Box>
                        <MRT_ToggleGlobalFilterButton table={tableInstanceRef.current} />
                        <MRT_ToggleFiltersButton table={tableInstanceRef.current} />
                        <MRT_ShowHideColumnsButton table={tableInstanceRef.current} />
                        <MRT_FullScreenToggleButton table={tableInstanceRef.current} />

                        {hasPermissions ? <Tooltip arrow title="Add Role">
                          <IconButton onClick={() => {
                            setAddPOCDocumentModalVis(true)
                          }}
                          >
                            <AddIcon />
                          </IconButton>
                        </Tooltip> : <View></View>}
                      </Box>
                    </>
                  )}
                />
              </ThemeProvider>
            </View>
          </View>
        </View>
      </View>
    </Scrollbars >
  );
};

export default Unit_POC;
