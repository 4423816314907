import React, { useState, useMemo, useEffect } from 'react';
import { View, Switch, Text } from 'react-native';
import { useColorContext } from '../ColorContext';

function ChartToggler({handleToggle, dataKeys}) {
    const [budgetToggle, setBudgetToggle] = useState(true);
    const [submittedToggle, setSubmittedToggle] = useState(true);
    const [approvedToggle, setApprovedToggle] = useState(true);
    const [spentToggle, setSpentToggle] = useState(true);
    const [targetToggle, setTargetToggle] = useState(true);

    useEffect(() => {
        if (!dataKeys.includes("Budget")) setBudgetToggle(false)
        if (!dataKeys.includes("Requested")) setSubmittedToggle(false)
        if (!dataKeys.includes("Approved")) setApprovedToggle(false)
        if (!dataKeys.includes("Paid")) setSpentToggle(false)
        if (!dataKeys.includes("Target")) setTargetToggle(false)
    }, [])

    const { colors } = useColorContext();

    return (
            <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                <View>
                    <Text style={{ color: colors.bow }}>Budget</Text>
                    <Switch onValueChange={() => {
                        setBudgetToggle(!budgetToggle)
                        handleToggle("Budget")
                        }} value={budgetToggle} />
                </View>
                <View>
                    <Text style={{ color: colors.bow }}>Requested</Text>
                    <Switch onValueChange={() => {
                        setSubmittedToggle(!submittedToggle)
                        handleToggle("Requested")
                        }} value={submittedToggle} />
                </View>
                <View>
                    <Text style={{ color: colors.bow }}>Approved</Text>
                    <Switch onValueChange={() => {
                        setApprovedToggle(!approvedToggle)
                        handleToggle("Approved")
                        }} value={approvedToggle} />
                </View>
                <View>
                    <Text style={{ color: colors.bow }}>Paid</Text>
                    <Switch onValueChange={() => {
                        setSpentToggle(!spentToggle)
                        handleToggle("Paid")}} value={spentToggle} />
                </View>
                <View>
                    <Text style={{ color: colors.bow }}>Target</Text>
                    <Switch onValueChange={() => {
                        setTargetToggle(!targetToggle)
                        handleToggle("Target")
                        }} value={targetToggle} />
                </View>
            </View>
    );
}

export default ChartToggler;