import React, {useEffect, useState} from 'react';
import {View, Text} from 'react-native';
import {Dimensions, ScrollView, Pressable} from 'react-native';
import {en, registerTranslation} from 'react-native-paper-dates';
import {get_invitees_status} from '../api_handler/events';
import {useScale} from '../components/scale';
import CustomHeader from '../components/header';
import { useColorContext} from '../components/ColorContext';
registerTranslation('en', en);


const windowDimensions = Dimensions.get('window');
const windowHeight = windowDimensions.height;

const ViewCommentsScreen = ({route, navigation}) => {
  const {colors} = useColorContext();
  const [comments, setComments] = useState([]);

  useEffect(() => {
    async function loadComments() {
      console.log(global.eventID);
      let commentInfo = await get_invitees_status({'event_id': global.eventID}, global.token);
      console.log('commentInfo: ' + commentInfo);
      commentInfo = commentInfo.body;

      const temp = [];
      if (commentInfo.length !== 0) {
        console.log('True');
        for (let i = 0; i < commentInfo.length; i++) {
          const data = {
            author: commentInfo[i].name,
            comment: commentInfo[i].comment ? `"${commentInfo[i].comment}"` : 'No comment to display',
            accepted: changeAcceptedText(commentInfo[i].accepted),
          };
          temp.push(data);
        }
      }
      setComments(temp);
      console.log(comments);
    }
    loadComments();
  }, []);

  const scale = useScale();
  const styles = ({
    container: {
      flex: 1,
      backgroundColor:  colors.screen_background,
    },
    header: {
      height: 0.15 * windowHeight,
    },
    comments: {
      marginTop: '20px',
      fontsize: 20,
      color:  colors.text ,
    },
    commentBox: {
      padding: 10,
      borderBottomWidth: 1,
      width: '100%',
      borderBottomColor: colors.black,
    },
    title: {
      fontSize: scale(50),
      fontFamily: 'Trebuchet MS',
      marginTop: '0.5%',
      margin: '.05%',
      marginLeft: '38%',
      color:  colors.text ,
    },
    list: {
      width: '75%',
    },
    text: {
      fontSize: 22,
      textAlign: 'Left',
      color:  colors.text ,
    },
    acceptedText: {
      fontWeight: 'bold',
      color: 'green',
    },
    declinedText: {
      fontWeight: 'bold',
      color: 'red',
    },
    contentBox: {
      backgroundColor:  colors.comment_color,
      height: '100vh',
      width: '100%',
      marginTop: '5%',
    },
  });

  const changeAcceptedText = (accepted) => {
    switch (accepted) {
      case true:
        return 'Yes';
      case false:
        return 'No';
      default:
        return 'Not yet evaluated';
    };
  };

  return (
    <View style = {styles.container}>
      <CustomHeader />
      <View style={{flexDirection: 'row', width: '100%', height: 0.06 * windowHeight}}>
        <Pressable
          onPress={() => {
            navigation.navigate('Calendar');
          }}
        >
          <Text style={styles.text}>&larr; Calendar</Text>
        </Pressable>
        <Text style={styles.title}>Invitees' Status</Text>
      </View>
      <ScrollView>
        <View style={styles.contentBox}>
          {(comments.length !== 0) ? (
                            comments.map((item) => (
                              <View style={styles.comments}>
                                <Text style={styles.text}>{item.comment}{`\n`}</Text>
                                <Text style={styles.comments}>Author: {item.author}</Text>
                                <Text style={item.accepted === 'Yes' ? styles.acceptedText : item.accepted === 'No' ? styles.declinedText : styles.comments}>Accepted Invite: {item.accepted} </Text>
                              </View>
                            ))
                        ) : <Text style={styles.text}> No Comments to display </Text>}
        </View>
      </ScrollView>
    </View>
  );
};

export default ViewCommentsScreen;
