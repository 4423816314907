import React, { useEffect, useState, useMemo, useContext, useRef } from "react";
import {
  FlatList,
  View,
  Text,
  TextInput,
  TouchableOpacity,
  ScrollView,
  Modal,
} from "react-native";
import UploadIcon from '@mui/icons-material/Upload';
import { Box, IconButton } from '@mui/material';
import { Tooltip as MUITooltip } from '@mui/material';
import { MaterialReactTable, MRT_FullScreenToggleButton, MRT_ToggleGlobalFilterButton, MRT_ShowHideColumnsButton, MRT_ToggleFiltersButton } from 'material-react-table';
import { useScale } from "../../components/scale";
import CustomHeader from "../../components/header";
import SnackbarAlert from "../../components/SnackbarAlert";
import UploadRoundedIcon from "@mui/icons-material/UploadRounded";
import {
  Search as SearchIcon,
  Refresh as RefreshIcon,
} from "@mui/icons-material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  PieChart,
  Pie,
  Cell,
  Legend,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import DropDownPicker from "react-native-dropdown-picker";
import { useColorContext } from "../../components/ColorContext";
import {
  get_training_info,
  process_training_info_from_csv,
  put_s3,
} from "../../api_handler/files";
import NavSideBar from "../../components/NavSideBar";
import Scrollbars from "react-custom-scrollbars";
import { useTheme, createTheme, ThemeProvider, darken } from '@mui/material/styles';
import ModeContext from '../../components/ModeContext';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { useIsFocused } from '@react-navigation/native';
import { useNavBarContext } from "../../components/NavBarContext";
import { useSnackbar } from '../../components/SnackbarContext'
import * as Progress from 'react-native-progress'

/**
 * Leader's Dashboard Training Page. Displays all training information
 * for each subordinate of the logged in user.
 *
 * Functional Design: useEffect on page load, fetches data from the backend,
 * parses it and sets the relevant backend data useStates. Those changes are picked
 * up and table data is populated. Initial tab and graphic options are populated on
 * page load but each of graphic data is set either once when backedn data is loaded,
 * tab is pressed, or when a new graphic option is pressed.
 */
export default function LeaderTrainingOv({ navigation }) {
  const [refresh, setRefresh] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState(null);
  const [loaded, setLoaded] = useState(false);

  const { colors } = useColorContext();
  const { navState } = useNavBarContext();
  const { showSnackbar } = useSnackbar();

  const [searchText, setSearchText] = useState('All Units');
  const [unitType, setUnitType] = useState(['All Units'])
  const [openAlert, setOpenAlert] = useState(false);
  const [hasPermission, setHasPermission] = React.useState(false);


  // REACT TABLE
  const [rows, setRows] = React.useState([]);
  const { mode } = React.useContext(ModeContext);
  const isFocused = useIsFocused();
  const tableInstanceRef = useRef(null);

  // Toggle to fetch data as needed.
  const [toggle, setToggle] = useState(true);

  // BACKEND DATA
  const [ectLocationData, setEctLocationData] = useState([]);
  const [ectStatusData, setEctStatusData] = useState([]);
  const [rfoStatusData, setRfoStatusData] = useState([]);
  const [rfoTypeData, setRfoTypeData] = useState([]);
  const [schoolResData, setSchoolResData] = useState([]);
  const [schoolStatusData, setSchoolStatusData] = useState([]);
  const [readinessHWData, setReadinessHWData] = useState([]);
  const [acftStatusData, setAcftStatusData] = useState([]);
  const [acftDateData, setAcftDateData] = useState([]);
  const [iwqStatusData, setIwqStatusData] = useState([]);
  const [iwqWeaponData, setIwqWeaponData] = useState([]);
  const [iwqDateData, setIwqDateData] = useState([]);
  const [mosqStatusData, setMosqStatusData] = useState([]);
  const [pmeStatusData, setPmeStatusData] = useState([]);
  const [soldierData, setSoldierData] = useState([]);

  // PIE CHART USESTATES
  const [pieButtons, setPieButtons] = useState(ectPieButtons);
  const [pieData, setPieData] = useState();
  const [pieColors, setPieColors] = useState();
  const [activeChartButton, setActiveChartButton] = useState(0);
  const [toggleChartDataFetch, setToggleChartDataFetch] = useState(true);

  // TABS USESTATES
  const [activeTabButton, setActiveTabButton] = useState(0);
  const [headerLabels, setHeaderLabels] = useState(ectTableHeaders);

  // Pie Chart/Legend
  // colors for pie charts light mode
  //this hurts to look at... keeping it like this for the time being but man this should be look a lil different LOL
  const ectLocationPalette = [
    colors.leader_dash_blue,
    colors.leader_dash_green,
    colors.leader_dash_yellow,
    colors.leader_dash_orange,
    colors.leader_dash_red,
    colors.bow,
  ];
  const rfoTypePalette = [
    colors.leader_dash_blue,
    colors.leader_dash_orange,
    colors.leader_dash_yellow,
    colors.leader_dash_green,
    colors.leader_dash_red,
  ];
  const schoolResDatePalette = [
    colors.leader_dash_red,
    colors.leader_dash_orange,
    colors.leader_dash_yellow,
    colors.leader_dash_green,
  ];
  const acftDatePalette = [
    colors.leader_dash_green,
    colors.leader_dash_yellow,
    colors.leader_dash_orange,
    colors.leader_dash_red,
  ];
  const iwqDatePalette = [
    colors.leader_dash_green,
    colors.leader_dash_yellow,
    colors.leader_dash_orange,
    colors.leader_dash_red,
  ];
  const weaponPalette = [
    colors.leader_dash_blue,
    colors.leader_dash_green,
    colors.leader_dash_yellow,
    colors.leader_dash_orange,
    colors.leader_dash_red,
    colors.bow,
  ];
  const ectStatusPalette = [
    colors.leader_dash_blue,
    colors.leader_dash_orange,
    colors.leader_dash_yellow,
    colors.leader_dash_green,
    colors.leader_dash_red,
  ];
  const rfoStatusPalette = [
    colors.leader_dash_blue,
    colors.leader_dash_orange,
    colors.leader_dash_yellow,
    colors.leader_dash_green,
    colors.leader_dash_red,
  ];
  const schooleResStatusPalette = [
    colors.leader_dash_blue,
    colors.leader_dash_yellow,
    colors.leader_dash_green,
    colors.leader_dash_red,
  ];
  const passFailPalette = [
    colors.leader_dash_green,
    colors.leader_dash_red,
    colors.leader_dash_blue,
  ];
  const mosqStatusPalette = [
    colors.leader_dash_red,
    colors.leader_dash_orange,
    colors.leader_dash_yellow,
    colors.leader_dash_green,
  ];
  const pmeStatusPalette = [
    colors.leader_dash_red,
    colors.leader_dash_orange,
    colors.leader_dash_yellow,
    colors.leader_dash_green,
  ];

  // unit drop down menu
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("All Units");
  const [unitSelection, setUnitSelection] = useState([]);

  // Training Info CSV Upload functionality UseStates
  const [fileUploadLoading, setFileUploadLoading] = useState(false);
  const [uploadErrors, setUploadErrors] = useState([]);
  const [isErrorLogOpen, setIsErrorLogOpen] = useState(false);

  // Database to String Mappings
  const ectLocationEnumToStringMapping = {
    null: "N/A",
    0: "N/A",
    1: "No ECT",
    2: "Homestation",
    3: "Exercise Iron Falcon",
    4: "Exercise Viper Scimitar",
  };
  const ectStatusEnumToStringMapping = {
    null: "N/A",
    0: "N/A",
    1: "No ECT",
    2: "Pending Submission",
    3: "Submitted",
    4: "Approved",
    5: "Denied",
  };
  const rfoStatusEnumToStringMapping = {
    null: "N/A",
    0: "N/A",
    1: "No Request For Orders (No RFO)",
    2: "Pending Submission",
    3: "Submitted",
    4: "Processed",
    5: "Denied",
  };
  const rfoTypeEnumToStringMapping = {
    null: "N/A",
    0: "N/A",
    1: "None Pending",
    2: "Active Duty Operational Support (ADOS)",
    3: "Active Duty Training (ADT)",
    4: "Mobilization (Mob)",
    5: "Extended Combat Training (ECT)",
  };
  const schoolResStatusEnumToString = {
    null: "N/A",
    0: "N/A",
    1: "No Reservation Required",
    2: "Submitted",
    3: "Approved",
    4: "Denied",
  };
  const heightWeightEnumToString = {
    null: "N/A",
    true: "Pass",
    false: "Fail",
  };
  const acftStausEnumToString = {
    null: "Unable to Complete",
    true: "Pass",
    false: "Fail",
  };
  const iwqStatusEnumToString = {
    null: "Unable to Complete",
    true: "Pass",
    false: "Fail",
  };
  const flagStatusEnumToString = {
    null: "N/A",
    true: "Yes",
    false: "No",
  };
  const mosqStatusEnumToString = {
    null: "N/A",
    0: "N/A",
    1: "Unqualified",
    2: "School Reserved",
    3: "In School",
    4: "Qualified",
  };
  const pmeStatusEnumToString = {
    null: "N/A",
    0: "N/A",
    1: "Unqualified",
    2: "School Reserved",
    3: "In School",
    4: "Qualified",
  };

  useEffect(() => {
    if (navState.canEditLeadersDash) {
      setHasPermission(true);
    } else {
      setHasPermission(false);
    }
  }, []);

  /**
   * UseEffect that runs each time toggle variable is changed.
   * Fetches data from the endpoint.
   */
  useEffect(() => {
    fetchData();
  }, [toggle]);

  /**
   *  Fetches data by calling an AWS Lambda endpoint.
   */
  async function fetchData() {
    // Prepare a post query
    const data = {
      user_id: global.user_data.user_id,
      admin_unit_ids: global.user_data.admin_unit_ids
    };

    // Call the endpoint and call a parser on response
    const response = await get_training_info(data, global.token);
    if (response.status === 200) {
      parseTrainingInfoResponse(response.data.body);
      setUploadErrors(response.data.errors)
    } else {
      showSnackbar("error", "Application Error: Failed to retrieve soldiers training information");
    }
    setLoaded(true);
  }

  // Opens Error Message Alert
  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  // Closes Error Message Alert
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  /**
   * Parses the training information returned by the backend and sets the appropriate
   * states for the table and the charts.
   *
   * @param trainingInfo Training Information returned by the backend
   */
  async function parseTrainingInfoResponse(trainingInfo) {
    // EXTENDED COMBAT TRAININGS
    setEctLocationData([
      {
        name: "No ECT",
        value: trainingInfo.filter((training) => training.ect_location === 1)
          .length,
      },
      {
        name: "Homestation",
        value: trainingInfo.filter((training) => training.ect_location === 2)
          .length,
      },
      {
        name: "Exercise Iron Falcon",
        value: trainingInfo.filter((training) => training.ect_location === 3)
          .length,
      },
      {
        name: "Exercise Viper Scimitar",
        value: trainingInfo.filter((training) => training.ect_location === 4)
          .length,
      },
    ]);
    setEctStatusData([
      {
        name: "No ECT",
        value: trainingInfo.filter((training) => training.ect_status === 1)
          .length,
      },
      {
        name: "Pending Submission",
        value: trainingInfo.filter((training) => training.ect_status === 2)
          .length,
      },
      {
        name: "Submitted",
        value: trainingInfo.filter((training) => training.ect_status === 3)
          .length,
      },
      {
        name: "Approved",
        value: trainingInfo.filter((training) => training.ect_status === 4)
          .length,
      },
      {
        name: "Denied",
        value: trainingInfo.filter((training) => training.ect_status === 5)
          .length,
      },
    ]);

    // REQUEST FOR ORDERS
    setRfoStatusData([
      {
        name: "No RFO",
        value: trainingInfo.filter((training) => training.rfo_status === 1)
          .length,
      },
      {
        name: "Pending Submission",
        value: trainingInfo.filter((training) => training.rfo_status === 2)
          .length,
      },
      {
        name: "Submitted",
        value: trainingInfo.filter((training) => training.rfo_status === 3)
          .length,
      },
      {
        name: "Approved",
        value: trainingInfo.filter((training) => training.rfo_status === 4)
          .length,
      },
      {
        name: "Denied",
        value: trainingInfo.filter((training) => training.rfo_status === 5)
          .length,
      },
    ]);
    setRfoTypeData([
      {
        name: "None Pending",
        value: trainingInfo.filter((training) => training.rfo_type === 1)
          .length,
      },
      {
        name: "ADOS",
        value: trainingInfo.filter((training) => training.rfo_type === 2)
          .length,
      },
      {
        name: "ADT",
        value: trainingInfo.filter((training) => training.rfo_type === 3)
          .length,
      },
      {
        name: "Mobilization",
        value: trainingInfo.filter((training) => training.rfo_type === 4)
          .length,
      },
      {
        name: "ECT",
        value: trainingInfo.filter((training) => training.rfo_type === 5)
          .length,
      },
    ]);

    // SCHOOL SLOTS
    setSchoolResData([
      {
        name: "No Reservation Required",
        value: trainingInfo.filter(
          (training) => training.school_res_status === 1
        ).length,
      },
      {
        name: "Submitted",
        value: trainingInfo.filter(
          (training) => training.school_res_status === 2
        ).length,
      },
      {
        name: "Approved",
        value: trainingInfo.filter(
          (training) => training.school_res_status === 3
        ).length,
      },
      {
        name: "Denied",
        value: trainingInfo.filter(
          (training) => training.school_res_status === 4
        ).length,
      },
    ]);
    setSchoolStatusData([
      {
        name: "<14 Days",
        value: trainingInfo.filter(
          (training) => daysUntilSchoolResDate(training.school_res_date) === 1
        ).length,
      },
      {
        name: "<30 Days",
        value: trainingInfo.filter(
          (training) => daysUntilSchoolResDate(training.school_res_date) === 2
        ).length,
      },
      {
        name: ">30 < 60 Days",
        value: trainingInfo.filter(
          (training) => daysUntilSchoolResDate(training.school_res_date) === 3
        ).length,
      },
      {
        name: ">60 Days",
        value: trainingInfo.filter(
          (training) => daysUntilSchoolResDate(training.school_res_date) === 4
        ).length,
      },
    ]);

    // READINESS
    setReadinessHWData([
      {
        name: "Pass",
        value: trainingInfo.filter(
          (training) => training.height_weight === true
        ).length,
      },
      {
        name: "Fail",
        value: trainingInfo.filter(
          (training) => training.height_weight === false
        ).length,
      },
    ]);
    setAcftStatusData([
      {
        name: "Pass",
        value: trainingInfo.filter((training) => training.acft_status === true)
          .length,
      },
      {
        name: "Fail",
        value: trainingInfo.filter((training) => training.acft_status === false)
          .length,
      },
      {
        name: "Unable to Complete",
        value: trainingInfo.filter((training) => training.acft_status === null)
          .length,
      },
    ]);
    setAcftDateData([
      {
        name: ">90 Days",
        value: trainingInfo.filter(
          (training) => daysUntilAcftDate(training.acft_date) === 1
        ).length,
      },
      {
        name: "<90 Days",
        value: trainingInfo.filter(
          (training) => daysUntilAcftDate(training.acft_date) === 2
        ).length,
      },
      {
        name: "<30 Days",
        value: trainingInfo.filter(
          (training) => daysUntilAcftDate(training.acft_date) === 3
        ).length,
      },
      {
        name: "Past Due",
        value: trainingInfo.filter(
          (training) => daysUntilAcftDate(training.acft_date) === 4
        ).length,
      },
    ]);
    setIwqStatusData([
      {
        name: "Pass",
        value: trainingInfo.filter((training) => training.iwq_status === true)
          .length,
      },
      {
        name: "Fail",
        value: trainingInfo.filter((training) => training.iwq_status === false)
          .length,
      },
      {
        name: "Unable to Complete",
        value: trainingInfo.filter((training) => training.iwq_status === null)
          .length,
      },
    ]);
    setIwqDateData([
      {
        name: ">90 Days",
        value: trainingInfo.filter(
          (training) => daysUntilIwqDate(training.iwq_date) === 1
        ).length,
      },
      {
        name: "<90 Days",
        value: trainingInfo.filter(
          (training) => daysUntilIwqDate(training.iwq_date) === 2
        ).length,
      },
      {
        name: "<30 Days",
        value: trainingInfo.filter(
          (training) => daysUntilIwqDate(training.iwq_date) === 3
        ).length,
      },
      {
        name: "Past Due",
        value: trainingInfo.filter(
          (training) => daysUntilIwqDate(training.iwq_date) === 4
        ).length,
      },
    ]);
    setIwqWeaponData(
      parseIwqWeaponResponse(
        trainingInfo.map((training) => training.iwq_weapon)
      )
    );

    // MILITARY EDUCATION
    setMosqStatusData([
      {
        name: "Unqualified",
        value: trainingInfo.filter((training) => training.mosq_status === 1)
          .length,
      },
      {
        name: "School Reserved",
        value: trainingInfo.filter((training) => training.mosq_status === 2)
          .length,
      },
      {
        name: "In School",
        value: trainingInfo.filter((training) => training.mosq_status === 3)
          .length,
      },
      {
        name: "Qualified",
        value: trainingInfo.filter((training) => training.mosq_status === 4)
          .length,
      },
    ]);
    setPmeStatusData([
      {
        name: "Unqualified",
        value: trainingInfo.filter((training) => training.pme_status === 1)
          .length,
      },
      {
        name: "School Reserved",
        value: trainingInfo.filter((training) => training.pme_status === 2)
          .length,
      },
      {
        name: "In School",
        value: trainingInfo.filter((training) => training.pme_status === 3)
          .length,
      },
      {
        name: "Qualified",
        value: trainingInfo.filter((training) => training.pme_status === 4)
          .length,
      },
    ]);
    const data = trainingInfo.map((training) => ({
      id: training.id,
      name: training.name,
      unit: training.unit ? training.unit : "N/A",
      grade: training.grade ? training.grade : "N/A",
      rank: training.rank ? training.rank : "N/A",
      ect_location: ectLocationEnumToStringMapping[training.ect_location],
      ect_status: ectStatusEnumToStringMapping[training.ect_status],
      rfo_status: rfoStatusEnumToStringMapping[training.rfo_status],
      rfo_type: rfoTypeEnumToStringMapping[training.rfo_type],
      rfo_date: training.rfo_date ? training.rfo_date : "N/A",
      school_res_status:
        schoolResStatusEnumToString[training.school_res_status],
      school_res_date: training.school_res_date
        ? training.school_res_date
        : "N/A",
      height_weight: heightWeightEnumToString[training.height_weight],
      acft_status: acftStausEnumToString[training.acft_status],
      acft_date: training.acft_status ? training.acft_status : "N/A",
      acft_date: training.acft_date ? training.acft_date : "N/A",
      iwq_status: iwqStatusEnumToString[training.iwq_status],
      iwq_weapon: training.iwq_weapon ? training.iwq_weapon : "N/A",
      iwq_date: training.iwq_date ? training.iwq_date : "N/A",
      flag_status: flagStatusEnumToString[training.flag_status],
      mosq_status: mosqStatusEnumToString[training.mosq_status],
      pme_status: pmeStatusEnumToString[training.pme_status],
    }));

    // GETTING UNITS FOR FILTER
    const uniqueUnits = [...new Set(data.map(item => item.unit))];

    const unitObjects = uniqueUnits.map(unit => ({
      label: unit,
      value: unit,
    }));

    unitObjects.sort((a, b) => {
      const aLabel = a.label;
      const bLabel = b.label;

      // Ensures All Units comes first
      if (aLabel === "All Units") {
        return -1;
      }
      if (bLabel === "All Units") {
        return 1;
      }

      // Ensures Unassigned Soldiers come second
      if (aLabel === "Unassigned Soldiers") {
        return -1;
      }
      if (bLabel === "Unassigned Soldiers") {
        return 1;
      }
    
      // Basic sorting for HHC related needs to be expanded
      const aHHC = aLabel.includes("HHC");
      const bHHC = bLabel.includes("HHC");

      if (aHHC && !bHHC) {
        return -1;
      }
      if (!aHHC && bHHC) {
        return 1;
      }

      if (aHHC && bHHC) {
        const aValue = parseInt(aLabel.split(" ")[1]);
        const bValue = parseInt(bLabel.split(" ")[1]);
        return aValue - bValue;
      }

      return aLabel.localeCompare(bLabel);
    });

    unitObjects.unshift({
      label: "All Units",
      value: "All Units",
    });

    // TABLE DATA
    setSoldierData(data);
    setRows(data);
    setUnitSelection(unitObjects);
  }

  /**
   * Calculates days left until school reservation date from today.
   *
   * Current return mappings: {
   *    <14 days -> 1
   *    <30 days -> 2
   *    <60 days -> 3
   *    >60 days -> 4
   * }
   *
   * @param date Unformatted date string returned from backend
   * @return Enumerations for different time lengths
   */
  function daysUntilSchoolResDate(date) {
    if (date === null || date === undefined) {
      return 0;
    }

    const formattedDate =
      date.slice(0, 4) + "-" + date.slice(4, 6) + "-" + date.slice(6, 8);
    const dateObj = new Date(formattedDate);

    const today = new Date();
    const diffMilliseconds = dateObj - today;
    const diffDays = diffMilliseconds / (1000 * 60 * 60 * 24);

    // Mappings
    if (diffDays < 14) {
      return 1;
    } else if (diffDays < 30) {
      return 2;
    } else if (diffDays < 60) {
      return 3;
    } else {
      return 4;
    }
  }

  /**
   * Calculates days left until acft due date from today.
   *
   * Current return mappings: {
   *    >90 days -> 1
   *    <90 days -> 2
   *    <30 days -> 3
   *    <0 days -> 4
   * }
   *
   * @param date Unformatted date string returned from backend
   * @return Enumeration for different time lengths
   */
  function daysUntilAcftDate(date) {
    if (date === null || date === undefined) {
      return 0;
    }

    const formattedDate =
      date.slice(0, 4) + "-" + date.slice(4, 6) + "-" + date.slice(6, 8);
    const dateObj = new Date(formattedDate);

    const today = new Date();
    const diffMilliseconds = dateObj - today;
    const diffDays = diffMilliseconds / (1000 * 60 * 60 * 24);

    // Mappings
    if (diffDays < 0) {
      return 4;
    } else if (diffDays < 30) {
      return 3;
    } else if (diffDays < 90) {
      return 2;
    } else {
      return 1;
    }
  }

  /**
   * Calculates days left until IWQ weapon date from today.
   *
   * Current return mappings: {
   *    <14 days -> 1
   *    <30 days -> 2
   *    <60 days -> 3
   *    >60 days -> 4
   * }
   *
   * @param date Unformatted date string returned from backend
   * @return Enumerations for different time lengths
   */
  function daysUntilIwqDate(date) {
    if (date === null || date === undefined) {
      return 0;
    }

    const formattedDate =
      date.slice(0, 4) + "-" + date.slice(4, 6) + "-" + date.slice(6, 8);
    const dateObj = new Date(formattedDate);

    const today = new Date();
    const diffMilliseconds = dateObj - today;
    const diffDays = diffMilliseconds / (1000 * 60 * 60 * 24);

    // Mappings
    if (diffDays < 0) {
      return 4;
    } else if (diffDays < 30) {
      return 3;
    } else if (diffDays < 90) {
      return 2;
    } else {
      return 1;
    }
  }

  /**
   * Counts the different weapons (excluding nulls) in the response.
   *
   * @param weaponResponse iwq_weapon data from the backend
   * @return Array of [{ name: weaponName, value: weaponCount }...]
   */
  function parseIwqWeaponResponse(weaponResponse) {
    const weaponCounts = {};
    weaponResponse.forEach((weapon) => {
      if (weaponCounts[weapon]) {
        weaponCounts[weapon] += 1;
      } else {
        weaponCounts[weapon] = 1;
      }
    });

    const weaponData = Object.entries(weaponCounts)
      .map(([k, v]) => ({
        name: k,
        value: v,
      }))
      .filter((weapon) => weapon.name !== "null");

    return weaponData;
  }

  /* =========================================================================*/
  /* =============================TABLE RELATED CODE==========================*/
  /* =========================================================================*/

  // table headers for each tab
  const ectTableHeaders = ["ECT LOCATION", "ECT STATUS"];
  const rfoTableHeaders = ["RFO TYPE", "RFO STATUS", "RFO DATE"];
  const schoolSlotTableHeaders = ["RESERVATION STATUS", "SUBMISSION DATE"];
  const readinessTableHeaders = [
    "HT./WT.",
    "ACFT STATUS",
    "ACFT DATE",
    "IWQ STATUS",
    "IWQ WEAPON",
    "IWQ DATE",
    "FLAG",
  ];
  const milEdTableHeaders = ["MOSQ STATUS", "PME STATUS"];

  // when tab is selected, it needs its own tab info: the index, the text, and the associated table headers.
  const tabOptions = [
    {
      value: 0,
      text: "ECT",
      headers: ectTableHeaders,
    },
    {
      value: 1,
      text: "RFO",
      headers: rfoTableHeaders,
    },
    {
      value: 2,
      text: "Schools",
      headers: schoolSlotTableHeaders,
    },
    {
      value: 3,
      text: "Readiness",
      headers: readinessTableHeaders,
    },
    {
      value: 4,
      text: "Mil. Education",
      headers: milEdTableHeaders,
    },
  ];

  /**
   * UseEffect Ran whenever a tab is pressed. Resets the pie button to be the first one
   * selected, sets up table headers for that tab, and configures the different pie charts
   * for that tab
   */
  useEffect(() => {
    setActiveChartButton(0);
    setupTableHeaders();
    setupPieCharts();
    setToggleChartDataFetch(!toggleChartDataFetch);
  }, [activeTabButton]);

  /**
   * Sets up table headers for the tab selected by grabbing the appropriate
   * tableHeader object from above.
   */
  function setupTableHeaders() {
    switch (activeTabButton) {
      case 0:
        setHeaderLabels(ectTableHeaders);
        break;
      case 1:
        setHeaderLabels(rfoTableHeaders);
        break;
      case 2:
        setHeaderLabels(schoolSlotTableHeaders);
        break;
      case 3:
        setHeaderLabels(readinessTableHeaders);
        break;
      case 4:
        setHeaderLabels(milEdTableHeaders);
        break;
    }
  }

  /**
   * Sets up pie chart options based on the active tab.
   */
  function setupPieCharts() {
    switch (activeTabButton) {
      case 0:
        setPieButtons(ectPieButtons);
        break;
      case 1:
        setPieButtons(rfoPieButtons);
        break;
      case 2:
        setPieButtons(schoolSlotPieButtons);
        break;
      case 3:
        setPieButtons(readinessPieButtons);
        break;
      case 4:
        setPieButtons(milEdPieButtons);
        break;
    }
  }

  /**
   * A list of tabs that highlight when selected.
   *
   * @return Interactable Tab Selector component
   */
  const TabSelector = () => {
    return (
      <View style={styles.dateSelector}>
        {tabOptions.map((button, index) => (
          <TouchableOpacity
            key={index}
            style={[
              styles.pieButton,
              activeTabButton === button.value && styles.dateButtonPressed,
            ]}
            onPress={() => {
              setActiveTabButton(button.value);
            }}
          >
            <Text
              style={[
                {
                  color: colors.text,
                },
                activeTabButton === button.value && styles.whiteText,
              ]}
            >
              {button.text}
            </Text>
          </TouchableOpacity>
        ))}
      </View>
    );
  };

  /**
   * Adds the tab specific headers after the basic identifying information for the user.
   *
   * @return Complete list of headers for the table
   */
  const Headers = () => {
    return (
      <View style={{ ...styles.searchBarContainer, padding: 10 }}>
        <Text style={styles.headerLabels}>NAME</Text>
        <Text style={styles.smallHeaderLabels}>GRADE</Text>
        <Text style={styles.smallHeaderLabels}>UNIT</Text>
        <Text style={styles.headerLabels}>RANK</Text>
        {headerLabels &&
          headerLabels.map((label, index) => (
            <Text key={index} style={styles.headerLabels}>
              {label}
            </Text>
          ))}
      </View>
    );
  };

  /**
   * Each entry on the table. Color codes the tab specific entries to the same ones as the graphic.
   *
   */
  const Item = ({
    name,
    unit,
    grade,
    rank,
    ect_location,
    ect_status,
    rfo_status,
    rfo_type,
    rfo_date,
    school_res_status,
    school_res_date,
    height_weight,
    acft_status,
    acft_date,
    iwq_status,
    iwq_weapon,
    iwq_date,
    flag_status,
    mosq_status,
    pme_status,
  }) => {
    function getEctStatusColor(ect_status) {
      switch (ect_status) {
        case "N/A":
          return colors.bow;
        case "No ECT":
          return colors.leader_dash_blue;
        case "Pending Submission":
          return colors.leader_dash_orange;
        case "Submitted":
          return colors.leader_dash_yellow;
        case "Approved":
          return colors.leader_dash_green;
        case "Denied":
          return colors.leader_dash_red;
        default:
          return colors.bow;
      }
    }

    function getRfoTypeColor(rfoType) {
      switch (rfoType) {
        case "N/A":
          return colors.bow;
        case "None Pending":
          return colors.leader_dash_green;
        case "Active Duty Operational Support (ADOS)":
          return colors.leader_dash_blue;
        case "Extended Combat Training (ECT)":
          return colors.leader_dash_yellow;
        case "Active Duty Training (ADT)":
          return colors.leader_dash_orange;
        case "Mobilization (Mob)":
          return colors.leader_dash_red;
        default:
          return colors.bow;
      }
    }

    function getRfoStatusColor(rfoStatus) {
      switch (rfoStatus) {
        case "N/A":
          return colors.bow;
        case "No Request For Orders (No RFO)":
          return colors.leader_dash_blue;
        case "Pending Submission":
          return colors.leader_dash_orange;
        case "Submitted":
          return colors.leader_dash_yellow;
        case "Processed":
          return colors.leader_dash_green;
        case "Denied":
          return colors.leader_dash_red;
        default:
          return colors.bow;
      }
    }

    function getSchoolResStatusColor(schoolResStatus) {
      switch (schoolResStatus) {
        case "N/A":
          return colors.bow;
        case "No Reservation Required":
          return colors.leader_dash_blue;
        case "Submitted":
          return colors.leader_dash_yellow;
        case "Approved":
          return colors.leader_dash_green;
        case "Denied":
          return colors.leader_dash_red;
        default:
          return colors.bow;
      }
    }

    function getSchoolResDateColor(schoolResDate) {
      if (schoolResDate === "N/A") {
        return colors.bow;
      }

      const daysUntil = daysUntilSchoolResDate(schoolResDate);
      switch (daysUntil) {
        case 0:
          return colors.bow;
        case 1:
          return colors.leader_dash_red;
        case 2:
          return colors.leader_dash_orange;
        case 3:
          return colors.leader_dash_yellow;
        case 4:
          return colors.leader_dash_green;
        default:
          return colors.bow;
      }
    }

    function getHwColor(hw) {
      switch (hw) {
        case "N/A":
          return colors.bow;
        case "Pass":
          return colors.leader_dash_green;
        case "Fail":
          return colors.leader_dash_red;
      }
    }

    function getAcftStatusColor(acftStatus) {
      switch (acftStatus) {
        case "Unable to Complete":
          return colors.bow;
        case "Pass":
          return colors.leader_dash_green;
        case "Fail":
          return colors.leader_dash_red;
      }
    }

    function getAcftDateColor(acftDate) {
      if (acftDate === "N/A") {
        return colors.bow;
      }

      const daysUntil = daysUntilAcftDate(acftDate);
      switch (daysUntil) {
        case 0:
          return colors.bow;
        case 1:
          return colors.leader_dash_blue;
        case 2:
          return colors.leader_dash_yellow;
        case 3:
          return colors.leader_dash_orange;
        case 4:
          return colors.leader_dash_red;
        default:
          return colors.bow;
      }
    }

    function getIwqStatusColor(iwqStatus) {
      switch (iwqStatus) {
        case "Unable to Complete":
          return colors.bow;
        case "Pass":
          return colors.leader_dash_green;
        case "Fail":
          return colors.leader_dash_red;
      }
    }

    function getIwqDateColor(iwqDate) {
      if (iwqDate === "N/A") {
        return colors.bow;
      }

      const daysUntil = daysUntilIwqDate(iwqDate);
      switch (daysUntil) {
        case 0:
          return colors.bow;
        case 1:
          return colors.leader_dash_red;
        case 2:
          return colors.leader_dash_orange;
        case 3:
          return colors.leader_dash_yellow;
        case 4:
          return colors.leader_dash_green;
        default:
          return colors.bow;
      }
    }

    function getFlagColor(flag) {
      switch (flag) {
        case "N/A":
          return colors.bow;
        case "Yes":
          return colors.leader_dash_red;
        case "No":
          return colors.leader_dash_green;
      }
    }

    function getMosqStatusColor(mosqStatus) {
      switch (mosqStatus) {
        case "N/A":
          return colors.bow;
        case "Unqualified":
          return colors.leader_dash_red;
        case "School Reserved":
          return colors.leader_dash_orange;
        case "In School":
          return colors.leader_dash_yellow;
        case "Qualified":
          return colors.leader_dash_green;
        default:
          return colors.bow;
      }
    }

    function getPmeStatusColor(pmeStatus) {
      switch (pmeStatus) {
        case "N/A":
          return colors.bow;
        case "Unqualified":
          return colors.leader_dash_red;
        case "School Reserved":
          return colors.leader_dash_orange;
        case "In School":
          return colors.leader_dash_yellow;
        case "Qualified":
          return colors.leader_dash_green;
        default:
          return colors.bow;
      }
    }

    const bolded = { ...styles.itemDetail, fontWeight: "bold" };

    // ECT
    if (activeTabButton === 0) {
      const ectStatusColor = getEctStatusColor(ect_status);

      return (
        <View style={styles.item}>
          <Text style={styles.itemDetail}>{name}</Text>
          <Text style={styles.itemDoc}>{grade}</Text>
          <Text style={styles.itemDoc}>{unit}</Text>
          <Text style={styles.itemDetail}>{rank}</Text>
          <Text style={{ ...bolded, color: colors.bow }}> {ect_location} </Text>
          <Text style={{ ...bolded, color: ectStatusColor }}>
            {" "}
            {ect_status}{" "}
          </Text>
        </View>
      );
    }

    // RFO
    else if (activeTabButton == 1) {
      const rfoStatusColor = getRfoStatusColor(rfo_status);
      const rfoTypeColor = getRfoTypeColor(rfo_type);

      return (
        <View style={styles.item}>
          <Text style={styles.itemDetail}>{name}</Text>
          <Text style={styles.itemDoc}>{grade}</Text>
          <Text style={styles.itemDoc}>{unit}</Text>
          <Text style={styles.itemDetail}>{rank}</Text>
          <Text style={{ ...bolded, color: rfoTypeColor }}> {rfo_type} </Text>
          <Text style={{ ...bolded, color: rfoStatusColor }}>
            {" "}
            {rfo_status}{" "}
          </Text>
          <Text style={{ ...bolded, color: colors.bow }}>
            {" "}
            {formatDateForTable(rfo_date)}{" "}
          </Text>
        </View>
      );
    }

    // Schools
    else if (activeTabButton == 2) {
      const schoolStatusColor = getSchoolResStatusColor(school_res_status);
      const schoolDateStatusColor = getSchoolResDateColor(school_res_date);

      return (
        <View style={styles.item}>
          <Text style={styles.itemDetail}>{name}</Text>
          <Text style={styles.itemDoc}>{grade}</Text>
          <Text style={styles.itemDoc}>{unit}</Text>
          <Text style={styles.itemDetail}>{rank}</Text>
          <Text style={{ ...bolded, color: schoolStatusColor }}>
            {" "}
            {school_res_status}{" "}
          </Text>
          <Text style={{ ...bolded, color: schoolDateStatusColor }}>
            {" "}
            {formatDateForTable(school_res_date)}{" "}
          </Text>
        </View>
      );
    }

    // Readiness
    else if (activeTabButton == 3) {
      const hwColor = getHwColor(height_weight);
      const acftStatusColor = getAcftStatusColor(acft_status);
      const acftDateColor = getAcftDateColor(acft_date);
      const iwqStatusColor = getIwqStatusColor(iwq_status);
      const iwqDateColor = getIwqDateColor(iwq_date);
      const flagColor = getFlagColor(flag_status);

      return (
        <View style={styles.item}>
          <Text style={styles.itemDetail}>{name}</Text>
          <Text style={styles.itemDoc}>{grade}</Text>
          <Text style={styles.itemDoc}>{unit}</Text>
          <Text style={styles.itemDetail}>{rank}</Text>
          <Text style={{ ...bolded, color: hwColor }}> {height_weight} </Text>
          <Text style={{ ...bolded, color: acftStatusColor }}>
            {" "}
            {acft_status}{" "}
          </Text>
          <Text style={{ ...bolded, color: acftDateColor }}>
            {" "}
            {formatDateForTable(acft_date)}{" "}
          </Text>
          <Text style={{ ...bolded, color: iwqStatusColor }}>
            {" "}
            {iwq_status}{" "}
          </Text>
          <Text style={{ ...bolded, color: colors.bow }}> {iwq_weapon} </Text>
          <Text style={{ ...bolded, color: iwqDateColor }}>
            {" "}
            {formatDateForTable(iwq_date)}{" "}
          </Text>
          <Text style={{ ...bolded, color: flagColor }}> {flag_status} </Text>
        </View>
      );
    }

    // Military Education
    else if (activeTabButton == 4) {
      const mosqStatusColor = getMosqStatusColor(mosq_status);
      const pmeStatusColor = getPmeStatusColor(pme_status);

      return (
        <View style={styles.item}>
          <Text style={styles.itemDetail}>{name}</Text>
          <Text style={styles.itemDoc}>{grade}</Text>
          <Text style={styles.itemDoc}>{unit}</Text>
          <Text style={styles.itemDetail}>{rank}</Text>
          <Text style={{ ...bolded, color: mosqStatusColor }}>
            {" "}
            {mosq_status}{" "}
          </Text>
          <Text style={{ ...bolded, color: pmeStatusColor }}>
            {" "}
            {pme_status}{" "}
          </Text>
        </View>
      );
    }
  };

  /**
   * The entry renderer on the table.
   */
  const renderItem = ({ item }) => (
    <Item
      onPress={() => setSelectedId(item.id)}
      name={item.name}
      unit={item.unit}
      grade={item.grade}
      rank={item.rank}
      ect_location={item.ect_location}
      ect_status={item.ect_status}
      rfo_status={item.rfo_status}
      rfo_type={item.rfo_type}
      rfo_date={item.rfo_date}
      school_res_status={item.school_res_status}
      school_res_date={item.school_res_date}
      height_weight={item.height_weight}
      acft_status={item.acft_status}
      acft_date={item.acft_date}
      iwq_status={item.iwq_status}
      iwq_weapon={item.iwq_weapon}
      iwq_date={item.iwq_date}
      flag_status={item.flag_status}
      mosq_status={item.mosq_status}
      pme_status={item.pme_status}
    />
  );

  /**
   * Formats the date string provided in 'yyyymmdd' format,
   * into 'mm-dd-yyyy'
   */
  function formatDateForTable(date) {
    if (date === "N/A") {
      return "N/A";
    }

    const year = date.slice(0, 4);
    const month = date.slice(4, 6);
    const day = date.slice(6, 8);

    return month + "-" + day + "-" + year;
  }

  /* =========================================================================*/
  /* ===========================GRAPHICS RELATED CODE=========================*/
  /* =========================================================================*/

  // button labels for charts, when new document catagory is selected the button change to corresponding stuff with initial data and color palette for pie
  const ectPieButtons = [
    {
      text: "ECT Location",
      color: ectLocationPalette,
      value: 0,
    },
    {
      text: "ECT Order Status",
      color: ectStatusPalette,
      value: 1,
    },
  ];
  const rfoPieButtons = [
    {
      text: "RFO Type",
      color: rfoTypePalette,
      value: 0,
    },
    {
      text: "RFO Status",
      color: rfoStatusPalette,
      value: 1,
    },
  ];
  const schoolSlotPieButtons = [
    {
      text: "Reservation Status",
      color: schooleResStatusPalette,
      value: 0,
    },
    {
      text: "Reservation Submission Date",
      color: schoolResDatePalette,
      value: 1,
    },
  ];
  const readinessPieButtons = [
    {
      text: "Ht./Wt.",
      color: passFailPalette,
      value: 0,
    },
    {
      text: "ACFT Status",
      color: passFailPalette,
      value: 1,
    },
    {
      text: "ACFT Date",
      color: acftDatePalette,
      value: 2,
    },
    {
      text: "IWQ Status",
      color: passFailPalette,
      value: 3,
    },
    {
      text: "IWQ Date",
      color: iwqDatePalette,
      value: 4,
    },
    {
      text: "IWQ Weapon",
      color: weaponPalette,
      value: 5,
    },
  ];
  const milEdPieButtons = [
    {
      text: "MOSQ Status",
      color: mosqStatusPalette,
      value: 0,
    },
    {
      text: "PME Weapon",
      color: pmeStatusPalette,
      value: 1,
    },
  ];

  /**
   * UseEffect that runs when a new tab is pressed or when a new pie button is pressed.
   * Sets the pie chart colors as well as the data specific to the chosen pie button.
   */
  useEffect(() => {
    if (pieButtons) {
      const activeButtonConfig = pieButtons.filter(
        (button) => button.value === activeChartButton
      );
      setPieColors(activeButtonConfig[0].color);
      setupPieData();
    }
  }, [toggleChartDataFetch, ectLocationData]);

  /**
   * Sets the appropriate data to pieData based on the active chart button
   */
  function setupPieData() {
    switch (activeTabButton) {
      // ECT
      case 0:
        switch (activeChartButton) {
          // ECT Location
          case 0:
            setPieData(ectLocationData);
            break;
          // ECT Order Status
          case 1:
            setPieData(ectStatusData);
            break;
        }
        break;

      // RFO
      case 1:
        switch (activeChartButton) {
          // RFO Status
          case 0:
            setPieData(rfoStatusData);
            break;
          // RFO Type
          case 1:
            setPieData(rfoTypeData);
            break;
        }
        break;

      // School Slots
      case 2:
        switch (activeChartButton) {
          // School Reservation Status
          case 0:
            setPieData(schoolResData);
            break;
          // School Reservation Date
          case 1:
            setPieData(schoolStatusData);
            break;
        }
        break;

      // Readiness
      case 3:
        switch (activeChartButton) {
          // Height Weight
          case 0:
            setPieData(readinessHWData);
            break;
          // ACFT Status
          case 1:
            setPieData(acftStatusData);
            break;
          // ACFT Date
          case 2:
            setPieData(acftDateData);
            break;
          // IWQ Status
          case 3:
            setPieData(iwqStatusData);
            break;
          // IWQ Date
          case 4:
            setPieData(iwqDateData);
            break;
          // IWQ Weapon
          case 5:
            setPieData(iwqWeaponData);
            break;
        }
        break;

      // Military Education
      case 4:
        switch (activeChartButton) {
          // MOSQ Status
          case 0:
            setPieData(mosqStatusData);
            break;
          // PME Status
          case 1:
            setPieData(pmeStatusData);
            break;
        }
    }
  }

  /**
   * Sets up the buttons for the different graphics but mapping over pieButtons.
   * If pressed, sets that button to be the active button.
   *
   * @return Interactable buttons for different graphics available under the tab
   */
  const PieSelector = () => {
    return (
      <View style={styles.chartSelector}>
        {pieButtons &&
          pieButtons.map((button, index) => (
            <TouchableOpacity
              key={index}
              style={[
                styles.pieButton,
                activeChartButton === button.value && styles.pieButtonPressed,
              ]}
              onPress={() => {
                setActiveChartButton(button.value);
                setToggleChartDataFetch(!toggleChartDataFetch);
              }}
            >
              <Text style={styles.legend}>{button.text}</Text>
            </TouchableOpacity>
          ))}
      </View>
    );
  };

  /**
   * Uses pieData and its corresponding pieColors to generate a pie chart
   *
   * @return Interactable graphics component containing the pie chart.
   */
  const Graphics = () => {
    return (
      <ResponsiveContainer idth="100%" height={325}>
        <PieChart>
          <Tooltip />
          <Pie
            data={pieData}
            outerRadius={"80%"}
            dataKey="value"
            nameKey="name"
            innerRadius={"35%"}
          >
            {pieData &&
              Object.values(pieData).map((entry, index) => (
                <Cell key={`cell-${index}`} fill={pieColors[index]} />
              ))}
          </Pie>
          <Legend style={{ fontFamily: 'Trebuchet MS' }} />
        </PieChart>
      </ResponsiveContainer>
    );
  };

  const scale = useScale();

  /* =========================================================================*/
  /* ===============================STYLE SHEETs==============================*/
  /* =========================================================================*/

  const styles = {
    container: {
      width: '100%',
      backgroundColor: colors.screen_background,
    },
    pdf: {
      width: 500,
      height: 1000,
    },
    tableContainer: {
      justifyContent: 'center',
      marginVertical: '2%',
      alignSelf: 'center',
      marginHorizontal: '5%',
      borderColor: colors.border_color,
      borderWidth: global.user_data.theme === 'dark' ? 1 : 0,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
    },
    pageTopSection: {
      flexDirection: 'row',
      alignSelf: 'center',
      marginHorizontal: '5%',
      marginTop: '2.5%'
    },
    titleDiv: {
      flexDirection: 'row',
      width: '100%',
      alignItems: 'center',
    },
    title: {
      fontSize: scale(30),
      fontFamily: 'Trebuchet MS',
      flex: 1,
      paddingTop: 25,
      fontWeight: 'bold',
      marginHorizontal: 5,
      textAlign: 'Left',
      color: colors.screen_title,
    },
    pageInfoText: {
      fontSize: 18,
      marginHorizontal: 5,
      textAlign: "Left",
      paddingTop: 25,
      color: colors.text,
    },
    textInputProps: {
      '& .MuiFormLabel-root, & label.Mui-focused, & .MuiInputBase-input': {
        color: colors.inputted_text,
        // WebkitTextFillColor:  colors.inputted_text ,
        fontFamily: 'Trebuchet MS',

      },
      '.MuiSvgIcon-root': {
        fill: colors.inputted_text,
        fontFamily: 'Trebuchet MS',
      },
      '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
          borderColor: colors.form_border,
          fontFamily: 'Trebuchet MS',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#1876d2',
          fontFamily: 'Trebuchet MS',
        },
        // '& fieldset': {
        //     borderColor:  '#c4c4c4' : 'transparent',
        // },
      },
      "&  .MuiFormHelperText-root": {
        backgroundColor: colors.screen_background,
        fontFamily: 'Trebuchet MS',
        margin: 0,
        paddingLeft: 1
      },
      // .MuiFormHelperText-root.Mui-error
    },
    textbox: {
      width: '66%',
      backgroundColor: colors.textfield_background,
    },
    unitsFilter: {
      marginTop: '5%',
    },
    dropDownMenu: {
      marginTop: 30,
      backgroundColor: colors.leader_dash_legend_container,
      color: colors.bow,
      border: false,
      borderRadius: 10,
      fontSize: 18,
      shadowColor: "#000",
      shadowOffset: { width: 3, height: 3 },
      shadowOpacity: 0.3,
      shadowRadius: 8,
      flex: 0.3,
    },
    list: {
      alignItems: "center",
      marginTop: "1%",
      width: "90%",
      borderRadius: 20,
      backgroundColor: colors.leader_dash_legend_container,
    },
    pageTitle: {
      fontSize: 30,
      fontWeight: "bold",
      marginHorizontal: 5,
      textAlign: "Left",
      paddingTop: 25,
      color: colors.text,
    },
    button: {
      marginTop: 2,
    },
    header: {
      height: "15%",
    },
    main: {
      marginTop: "1%",
      height: "85%",
      alignItems: "center",
    },
    bottomContainer: {
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '2%'
    },
    chartButtons: {
      width: "99%",
      alignContent: "center",
    },
    chartSelector: {
      flexDirection: "row",
      flex: 1,
      justifyContent: "space-evenly",
      textAlign: "center",
      borderBottomWidth: 2,
      borderColor: "#D9D9D9",
      margin: "1%",
    },
    pieChart: {
      flexDirection: "column",
      flex: 2,
      alignItems: "center",
      fontFamily: 'Trebuchet MS',
      fontWeight: "bold",
      fontSize: 14,
    },
    pieButton: {
      flex: 0.25,
    },
    pieButtonPressed: {
      borderBottomWidth: 4,
      borderColor: "#A98C42",
    },
    dateSelector: {
      width: "99%",
      alignSelf: "center",
      flexDirection: "row",
      justifyContent: "space-evenly",
      textAlign: "center",
      margin: 25,
      color: colors.text,
    },
    dateButtonPressed: {
      borderRadius: 20,
      backgroundColor: "#A98C42",
      color: colors.text,
    },
    tableHeader: {
      flexDirection: "column",
      justifyContent: "content",
      position: "sticky",
      top: 0,
      zIndex: 999,
      width: "99%",
      flex: 2,
      // color: mode === "light" ? colors.text ,
    },
    searchBarContainer: {
      flexDirection: "row",
      flex: 1,
      margin: 5,
      borderRadius: 20,
      borderBottomWidth: 1,
      borderColor: "#ECECEC",
      alignItems: "center",
      shadowColor: "#ECECEC",
      shadowOffset: { width: 3, height: 3 },
      shadowOpacity: 0.3,
      shadowRadius: 8,
      backgroundColor: colors.textfield_background,
      // color: mode === "light" ? colors.text ,
    },
    icons: {
      padding: 10,
      color: colors.text,
    },
    modal: {
      backgroundColor: "white",
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      color: colors.text,
    },
    searchBar: {
      borderRadius: 20,
      textAlign: "left",
      flex: 1,
      padding: 10,
      outlineStyle: "none",
      // color: mode === "light" ? colors.text ,
      backgroundColor: colors.textfield_background,
      color: colors.bow,
    },
    headerLabels: {
      flex: 1,
      justifyContent: "content",
      fontSize: 18,
      textAlign: "center",
      color: colors.text,
    },
    smallHeaderLabels: {
      flex: 0.75,
      justifyContent: "content",
      fontSize: 18,
      textAlign: "center",
      color: colors.text,
    },
    item: {
      flex: 1,
      flexDirection: "row",
      backgroundColor: "white",
      paddingVertical: 20,
      marginVertical: 8,
      marginHorizontal: 5,
      borderRadius: 20,
      shadowColor: "#000",
      shadowOffset: { width: 3, height: 3 },
      shadowOpacity: 0.3,
      shadowRadius: 8,
      alignItems: "center",
      textAlign: "center",
      backgroundColor: colors.textfield_background,
    },
    itemDoc: {
      flex: 0.75,
      color: colors.text,
    },
    itemDetail: {
      flex: 1,
      color: colors.text,
    },
    doc: {
      margin: 15,
      width: 40,
      height: 40,
      color: colors.text,
    },
    legendContainer: {
      backgroundColor: colors.leader_dash_legend_container,
      borderRadius: 20,
      flex: 3,
      width: '50%',
      height: 400,
      alignItems: "center",
      color: colors.text,
      fontFamily: "Trebuchet MS",
      borderColor: colors.border_color,
      borderWidth: global.user_data.theme === 'dark' ? 1 : 0,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
    },
    legend: {
      alignItems: "left",
      color: colors.text,
    },
    allButton: {
      flexDirection: "row",
      height: 30,
      width: "30%",
      marginLeft: "25%",
    },
    appButton: {
      flexDirection: "row",
      height: 30,
      width: "30%",
      marginLeft: "25%",
    },
    filterAllText: {
      fontSize: 15,
      marginTop: 10,
    },
    filterAppText: {
      fontSize: 15,
      marginTop: 10,
    },
    whiteText: {
      color: "white",
    },
    errorLog: {
      flexDirection: "column",
      height: "70%",
      width: "50%",
      marginTop: "10%",
      marginLeft: "25%",
      borderRadius: 20,
      shadowColor: "#000",
      shadowOffset: { width: 3, height: 3 },
      shadowOpacity: 0.3,
      shadowRadius: 8,
      padding: 10,
      backgroundColor: colors.textfield_background,
      borderWidth: 4,
      borderColor: '#FF6961',
    },
    headerWrapper: {
      flexDirection: "row",
      width: "100%",
      borderBottomWidth: 2,
      borderColor: colors.bow,
    },
    errorLogHeader: {

      color: colors.bow,
      flex: 9,
      fontSize: 30,
      fontWeight: "bold",
      height: "60px",
      textAlign: "center",
    },
    errors: {
      flexDirection: "column",
      width: "100%",
      // backgroundColor: "purple",
    },
    errorText: {
      color: colors.text,
      fontFamily: "Trebuchet MS",
    },
  };

  /* =========================================================================*/
  /* ================================CSV Upload===============================*/
  /* =========================================================================*/

  /**
   * Handles Upload Subordinate Training Info button click.
   * Sends the file to be read on file input.
   */
  function handleTrainingInfoUploadClick() {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = '.csv';

    input.onchange = (e) => {
      const file = e.target.files[0];
      if (file) {
        if (file.type === 'text/csv' || file.name.toLowerCase().endsWith('.csv')) {
          readTrainingInfoCSV(file);
        } else {
          showSnackbar('error', 'Please select a CSV file.');
        }
      }
    };

    input.click();
  }

  /**
   * Reads the Training Info CSV and on success, forwards it to be uploaded and
   * processed.
   *
   * @param file The Training Info file to be read
   */
  function readTrainingInfoCSV(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = function () {
      let csv64 = reader.result;
      csv64 = csv64.substring(21);
      setUploadErrors([]);
      setFileUploadLoading(true);
      uploadAndProcessTrainingInfoCSV(csv64);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
      setFileUploadLoading(false);
    };
  }

  /**
   * Uploads the CSV containing Training Info into S3, then calls a lambda function that processes
   * that CSV.
   *
   * @param csv CSV to be uploaded
   */
  async function uploadAndProcessTrainingInfoCSV(csv) {
    // CSV Path in S3
    const csv_filepath =
      "add_training_info_csvs/" + global.user_data.user_id + ".csv";

    // Put the file into S3
    await put_s3(csv_filepath, csv, "text/csv", global.token).catch((error) => {
      setFileUploadLoading(false);
      console.log(error);
      showSnackbar("error", "Application Error: Failed to upload the file");
    });

    // Prepare data to call Lambda function with
    const data = {
      user_id: global.user_data.user_id,
      admin_unit_ids: global.user_data.admin_unit_ids,
      user_full_name: global.user_data.first_name + " " + global.user_data.last_name
    };

    // Process the Training Info using a Lambda function
    const response = await process_training_info_from_csv(data, global.token)
    setFileUploadLoading(false);
    console.log(response)
    // Update the user
    if (response.status === 200) {
      if (response.data.totalErrors === 0) {
        showSnackbar("success", "File Successfully Uploaded and Process");
        window.location.reload();
      } else {
        setUploadErrors(response.data.errors);
        setTimeout(function () {
          setIsErrorLogOpen(true);
        }, 500);
      }
    } else {
      setIsErrorLogOpen(true);
      showSnackbar("error", "Application Error: Failed to upload the file");
    }
  }

  const CsvUploadErrorLog = () => {

    const error = uploadErrors;
    const ValidEntries = isErrorLogOpen ? uploadErrors[0].ValidEntries : ["NO ENTRIES"];

    function formatName(fullName) {
      if (error) {
        const parts = fullName.split(' ');
        if (parts.length > 1) {
          fullName = parts.slice(1).join(' ');
        }
        return fullName.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
      }
      return fullName;
    }

    return (
      <Modal
        visible={isErrorLogOpen}
        onRequestClose={handleErrorLogClose}
        animationType="slide"
        transparent={true}
      >
        <View style={styles.errorLog}>
          <View style={styles.headerWrapper}>
            <Text style={styles.errorLogHeader}>CSV Upload Errors</Text>
            <TouchableOpacity style={{ flex: 1, alignItems: "center" }}>
              <CloseRoundedIcon
                style={{ color: colors.bow, fontSize: 35, marginTop: "3%" }}
                onClick={() => handleErrorLogClose()}
              />
            </TouchableOpacity>
          </View>
          <Scrollbars style={styles.errors}>
            {error && ValidEntries ? (

              <View >

                <View style={{ margin: 5 }}>
                  <Text style={styles.errorText}>Errors:</Text>
                  {(Object.values(error).map((error, index) => (
                    <View key={index} style={{ margin: 10, flex: 1, justifyContent: 'center', color: 'white' }}>
                      <Text style={styles.errorText}> Entry {error.EntryNo},  {error.Error}</Text>
                    </View>
                  ))
                  )}
                </View>

                <View>
                  <Text style={styles.errorText}>Valid Entries:</Text>
                  <ul style={{ listStyle: 'none' }}>
                    {ValidEntries.map((entry, entryIndex) => (
                      <li key={entryIndex} style={styles.errorText}>{entryIndex + 1}. {formatName(entry)}</li>
                    ))}
                  </ul>
                </View>
              </View>


            ) : (
              <Text>No error message available </Text>

            )}


          </Scrollbars>
        </View>
      </Modal>
    );
  };

  function handleErrorLogClose() {
    setFileUploadLoading(false);
    setIsErrorLogOpen(false);
  }

  /* =========================================================================*/
  /* ===================================UTILS=================================*/
  /* =========================================================================*/

  // Search Bar
  const handleSearch = (selectedValues) => {
    const allUnitsIndex = selectedValues.indexOf('All Units');
    const previousAllUnitsIndex = unitType.indexOf('All Units');

    let updatedValues = [...selectedValues];

    if (allUnitsIndex !== -1 && previousAllUnitsIndex === -1 && selectedValues.length > 1) {
      updatedValues = ['All Units'];
    }
    else if (previousAllUnitsIndex !== -1 && selectedValues.length > 1) {
      updatedValues = selectedValues.filter(value => value !== 'All Units');
    }

    setUnitType(updatedValues);
  
  
    let filtered;
    if (!updatedValues.includes('All Units')) {
      filtered = soldierData.filter(item => 
        updatedValues.some(unit => item.unit.toLowerCase().includes(unit.toLowerCase()))
      );
    } else {
      filtered = soldierData;
    }
  
    setRows(filtered);
  };

  // refresh list
  async function refreshList() {
    setToggle(!toggle);
  }
  /* =========================================================================*/
  /* =============================REACT TABLE MUI STUFF=======================*/
  /* =========================================================================*/


  function getEctStatusColor(ect_status) {
    switch (ect_status) {
      case "N/A":
        return colors.bow;
      case "No ECT":
        return colors.leader_dash_blue;
      case "Pending Submission":
        return colors.leader_dash_orange;
      case "Submitted":
        return colors.leader_dash_yellow;
      case "Approved":
        return colors.leader_dash_green;
      case "Denied":
        return colors.leader_dash_red;
      default:
        return colors.bow;
    }
  }

  function getRfoTypeColor(rfoType) {
    switch (rfoType) {
      case "N/A":
        return colors.bow;
      case "None Pending":
        return colors.leader_dash_green;
      case "Active Duty Operational Support (ADOS)":
        return colors.leader_dash_blue;
      case "Extended Combat Training (ECT)":
        return colors.leader_dash_yellow;
      case "Active Duty Training (ADT)":
        return colors.leader_dash_orange;
      case "Mobilization (Mob)":
        return colors.leader_dash_red;
      default:
        return colors.bow;
    }
  }

  function getRfoStatusColor(rfoStatus) {
    switch (rfoStatus) {
      case "N/A":
        return colors.bow;
      case "No Request For Orders (No RFO)":
        return colors.leader_dash_blue;
      case "Pending Submission":
        return colors.leader_dash_orange;
      case "Submitted":
        return colors.leader_dash_yellow;
      case "Processed":
        return colors.leader_dash_green;
      case "Denied":
        return colors.leader_dash_red;
      default:
        return colors.bow;
    }
  }

  function getSchoolResStatusColor(schoolResStatus) {
    switch (schoolResStatus) {
      case "N/A":
        return colors.bow;
      case "No Reservation Required":
        return colors.leader_dash_blue;
      case "Submitted":
        return colors.leader_dash_yellow;
      case "Approved":
        return colors.leader_dash_green;
      case "Denied":
        return colors.leader_dash_red;
      default:
        return colors.bow;
    }
  }

  function getSchoolResDateColor(schoolResDate) {
    if (schoolResDate === "N/A") {
      return colors.bow;
    }

    const daysUntil = daysUntilSchoolResDate(schoolResDate);
    switch (daysUntil) {
      case 0:
        return colors.bow;
      case 1:
        return colors.leader_dash_red;
      case 2:
        return colors.leader_dash_orange;
      case 3:
        return colors.leader_dash_yellow;
      case 4:
        return colors.leader_dash_green;
      default:
        return colors.bow;
    }
  }

  function getHwColor(hw) {
    switch (hw) {
      case "N/A":
        return colors.bow;
      case "Pass":
        return colors.leader_dash_green;
      case "Fail":
        return colors.leader_dash_red;
    }
  }

  function getAcftStatusColor(acftStatus) {
    switch (acftStatus) {
      case "Unable to Complete":
        return colors.bow;
      case "Pass":
        return colors.leader_dash_green;
      case "Fail":
        return colors.leader_dash_red;
    }
  }

  function getAcftDateColor(acftDate) {
    if (acftDate === "N/A") {
      return colors.bow;
    }

    const daysUntil = daysUntilAcftDate(acftDate);
    switch (daysUntil) {
      case 0:
        return colors.bow;
      case 1:
        return colors.leader_dash_blue;
      case 2:
        return colors.leader_dash_yellow;
      case 3:
        return colors.leader_dash_orange;
      case 4:
        return colors.leader_dash_red;
      default:
        return colors.bow;
    }
  }

  function getIwqStatusColor(iwqStatus) {
    switch (iwqStatus) {
      case "Unable to Complete":
        return colors.bow;
      case "Pass":
        return colors.leader_dash_green;
      case "Fail":
        return colors.leader_dash_red;
    }
  }

  function getIwqDateColor(iwqDate) {
    if (iwqDate === "N/A") {
      return colors.bow;
    }

    const daysUntil = daysUntilIwqDate(iwqDate);
    switch (daysUntil) {
      case 0:
        return colors.bow;
      case 1:
        return colors.leader_dash_red;
      case 2:
        return colors.leader_dash_orange;
      case 3:
        return colors.leader_dash_yellow;
      case 4:
        return colors.leader_dash_green;
      default:
        return colors.bow;
    }
  }

  function getFlagColor(flag) {
    switch (flag) {
      case "N/A":
        return colors.bow;
      case "Yes":
        return colors.leader_dash_red;
      case "No":
        return colors.leader_dash_green;
    }
  }

  function getMosqStatusColor(mosqStatus) {
    switch (mosqStatus) {
      case "N/A":
        return colors.bow;
      case "Unqualified":
        return colors.leader_dash_red;
      case "School Reserved":
        return colors.leader_dash_orange;
      case "In School":
        return colors.leader_dash_yellow;
      case "Qualified":
        return colors.leader_dash_green;
      default:
        return colors.bow;
    }
  }

  function getPmeStatusColor(pmeStatus) {
    switch (pmeStatus) {
      case "N/A":
        return colors.bow;
      case "Unqualified":
        return colors.leader_dash_red;
      case "School Reserved":
        return colors.leader_dash_orange;
      case "In School":
        return colors.leader_dash_yellow;
      case "Qualified":
        return colors.leader_dash_green;
      default:
        return colors.bow;
    }
  }

  const globalTheme = useTheme();
  const generalTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: colors.mode,
          primary: globalTheme.palette.primary,
        },
      }),
    [globalTheme, isFocused],
  );
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: mode,
          primary: globalTheme.palette.primary,
          background: {
            default: colors.textfield_background,
          },
        },
        typography: { fontSize: 17 },
      }),
    [globalTheme, isFocused],
  );

  const columns = useMemo(
    () => {
      switch (activeTabButton) {
        case 0:
          return [
            {
              accessorKey: 'unit',
              header: 'Unit',
              size: 1,
            },
            {
              accessorKey: 'grade',
              header: 'Grade',
              size: 1,
            },
            {
              accessorKey: 'rank',
              header: 'Rank',
              size: 1,
            },
            {
              accessorKey: 'name',
              header: 'Name',
              size: 1,
            },
            {
              accessorKey: 'ect_location',
              header: 'ECT Location',
              size: 1,
              Cell: ({ row }) => {
                return (
                  <Text style={{ fontWeight: 'bold', color: colors.bow, fontSize: scale(16), fontFamily: 'Trebuchet MS' }}>
                    {row.original.ect_location}
                  </Text>);
              }
            },
            {
              accessorKey: 'ect_status',
              header: 'ECT Status',
              size: 1,
              Cell: ({ row }) => {
                return (
                  <Text style={{ fontWeight: 'bold', color: getEctStatusColor(row.original.ect_status), fontSize: scale(16), fontFamily: 'Trebuchet MS' }}>
                    {row.original.ect_status}
                  </Text>);
              }
            },
          ];
        case 1:
          return [
            {
              accessorKey: 'unit',
              header: 'Unit',
              size: 1,
            },
            {
              accessorKey: 'grade',
              header: 'Grade',
              size: 1,
            },
            {
              accessorKey: 'rank',
              header: 'Rank',
              size: 1,
            },
            {
              accessorKey: 'name',
              header: 'Name',
              size: 1,
            },
            {
              accessorKey: 'rfo_status',
              header: 'RFO Status',
              size: 1,
              Cell: ({ row }) => {
                return (
                  <Text style={{ fontWeight: 'bold', color: getRfoStatusColor(row.original.rfo_status), fontSize: scale(16), fontFamily: 'Trebuchet MS' }}>
                    {row.original.rfo_status}
                  </Text>);
              }
            },
            {
              accessorKey: 'rfo_type',
              header: 'RFO Type',
              size: 1,
              Cell: ({ row }) => {
                return (
                  <Text style={{ fontWeight: 'bold', color: getRfoTypeColor(row.original.rfo_type), fontSize: scale(16), fontFamily: 'Trebuchet MS' }}>
                    {row.original.rfo_type}
                  </Text>);
              }
            },
            {
              accessorKey: 'rfo_date',
              header: 'RFO Date',
              size: 1,
              Cell: ({ row }) => {
                return (
                  <Text style={{ fontWeight: 'bold', color: colors.bow, fontSize: scale(16), fontFamily: 'Trebuchet MS' }}>
                    {row.original.rfo_date}
                  </Text>);
              }
            },
          ];
        case 2:
          return [
            {
              accessorKey: 'unit',
              header: 'Unit',
              size: 1,
            },
            {
              accessorKey: 'grade',
              header: 'Grade',
              size: 1,
            },
            {
              accessorKey: 'rank',
              header: 'Rank',
              size: 1,
            },
            {
              accessorKey: 'name',
              header: 'Name',
              size: 1,
            },
            {
              accessorKey: 'school_res_status',
              header: 'Reservation Status',
              size: 1,
              Cell: ({ row }) => {
                return (
                  <Text style={{ fontWeight: 'bold', color: getSchoolResStatusColor(row.original.school_res_status), fontSize: scale(16), fontFamily: 'Trebuchet MS' }}>
                    {row.original.school_res_status}
                  </Text>);
              }
            },
            {
              accessorKey: 'school_res_date',
              header: 'Submission Date',
              size: 1,
              Cell: ({ row }) => {
                return (
                  <Text style={{ fontWeight: 'bold', color: getSchoolResDateColor(row.original.school_res_date), fontSize: scale(16), fontFamily: 'Trebuchet MS' }}>
                    {row.original.school_res_date}
                  </Text>);
              }
            },
          ];
        case 3:
          return [
            {
              accessorKey: 'unit',
              header: 'Unit',
              size: 1,
            },
            {
              accessorKey: 'grade',
              header: 'Grade',
              size: 1,
            },
            {
              accessorKey: 'rank',
              header: 'Rank',
              size: 1,
            },
            {
              accessorKey: 'name',
              header: 'Name',
              size: 1,
            },
            {
              accessorKey: 'height_weight',
              header: 'HT./WT.',
              size: 1,
              Cell: ({ row }) => {
                return (
                  <Text style={{ fontWeight: 'bold', color: getHwColor(row.original.height_weight), fontSize: scale(16), fontFamily: 'Trebuchet MS' }}>
                    {row.original.height_weight}
                  </Text>);
              }
            },
            {
              accessorKey: 'acft_status',
              header: 'ACFT STATUS',
              size: 1,
              Cell: ({ row }) => {
                return (
                  <Text style={{ fontWeight: 'bold', color: getAcftStatusColor(row.original.acft_status), fontSize: scale(16), fontFamily: 'Trebuchet MS' }}>
                    {row.original.acft_status}
                  </Text>);
              }
            },
            {
              accessorKey: 'acft_date',
              header: 'ACFT DATE',
              size: 1,
              Cell: ({ row }) => {
                return (
                  <Text style={{ fontWeight: 'bold', color: getAcftDateColor(row.original.acft_date), fontSize: scale(16), fontFamily: 'Trebuchet MS' }}>
                    {row.original.acft_date}
                  </Text>);
              }
            },
            {
              accessorKey: 'iwq_status',
              header: 'IWQ STATUS',
              size: 1,
              Cell: ({ row }) => {
                return (
                  <Text style={{ fontWeight: 'bold', color: getIwqStatusColor(row.original.iwq_status), fontSize: scale(16), fontFamily: 'Trebuchet MS' }}>
                    {row.original.iwq_status}
                  </Text>);
              }
            },
            {
              accessorKey: 'iwq_weapon',
              header: 'IWQ WEAPON',
              size: 1,
              Cell: ({ row }) => {
                return (
                  <Text style={{ fontWeight: 'bold', color: colors.bow, fontSize: scale(16), fontFamily: 'Trebuchet MS' }}>
                    {row.original.iwq_weapon}
                  </Text>);
              }
            },
            {
              accessorKey: 'iwq_date',
              header: 'IWQ DATE',
              size: 1,
              Cell: ({ row }) => {
                return (
                  <Text style={{ fontWeight: 'bold', color: getIwqDateColor(row.original.iwq_date), fontSize: scale(16), fontFamily: 'Trebuchet MS' }}>
                    {row.original.iwq_date}
                  </Text>);
              }
            },
            {
              accessorKey: 'flag_status',
              header: 'FLAG',
              size: 1,
              Cell: ({ row }) => {
                return (
                  <Text style={{ fontWeight: 'bold', color: getFlagColor(row.original.flag_status), fontSize: scale(16), fontFamily: 'Trebuchet MS' }}>
                    {row.original.flag_status}
                  </Text>);
              }
            },
          ];
        case 4:
          return [
            {
              accessorKey: 'unit',
              header: 'Unit',
              size: 1,
            },
            {
              accessorKey: 'grade',
              header: 'Grade',
              size: 1,
            },
            {
              accessorKey: 'rank',
              header: 'Rank',
              size: 1,
            },
            {
              accessorKey: 'name',
              header: 'Name',
              size: 1,
            },
            {
              accessorKey: 'mosq_status',
              header: 'MOSQ Status',
              size: 1,
              Cell: ({ row }) => {
                return (
                  <Text style={{ fontWeight: 'bold', color: getMosqStatusColor(row.original.mosq_status), fontSize: scale(16), fontFamily: 'Trebuchet MS' }}>
                    {row.original.mosq_status}
                  </Text>);
              }
            },
            {
              accessorKey: 'pme_status',
              header: 'PME Status',
              size: 1,
              Cell: ({ row }) => {
                return (
                  <Text style={{ fontWeight: 'bold', color: getPmeStatusColor(row.original.pme_status), fontSize: scale(16), fontFamily: 'Trebuchet MS' }}>
                    {row.original.pme_status}
                  </Text>);
              }
            },
          ];
        default:
          return [
            {
              accessorKey: 'unit',
              header: 'Unit',
              size: 1,
            },
            {
              accessorKey: 'grade',
              header: 'Grade',
              size: 1,
            },
            {
              accessorKey: 'rank',
              header: 'Rank',
              size: 1,
            },
            {
              accessorKey: 'name',
              header: 'Name',
              size: 1,
            },
          ];
      }
    }, [rows, activeTabButton],
  );

  const loadingStyles = {
    animation: {
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
        justifyContent: 'center',
        paddingVertical: 20,
        background: colors.screen_background,
    }
  }

  if (!loaded) {
    return (
      <View style={loadingStyles.animation}>
          <Progress.CircleSnail
            color={colors.loading_circle}
            indeterminate={true}
            size={275}
            thickness={8}
          />
      </View>
    );
  }



  /* =========================================================================*/
  /* =============================COMPONENT RETURN============================*/
  /* =========================================================================*/

  return (

    <ThemeProvider theme={generalTheme}>
      <View style={{ height: '100%', width: '100%', flexDirection: 'row' }}>
        {/* <NavSideBar /> */}
        <SnackbarAlert
          open={openAlert}
          onClose={handleCloseAlert}
          severity="error"
          message="Access Denied: User does not have correct permissions."
          anchorVertical={'bottom'}
          anchorHorizontal={'right'}
          autoHideDuration={5000}
        />
        <Scrollbars
          showsverticalscrollindicator="false"
          style={styles.container}
        >
          <View style={styles.pageTopSection}>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                alignSelf: "center",
                marginTop: "2.5%",
                flex: 6
              }}
            >
              <View style={{ alignText: "center", flex: 3 }}>

                <View style={styles.titleDiv}>
                  <Text style={styles.title}>
                    <Text style={{ fontWeight: 'bold' }}>Training Overview</Text>
                  </Text>
                </View>

                <Text style={styles.pageInfoText} >
                  Overview of soldiers' Request for Orders (RFO), ECT, Soldier
                  Readiness, and Military Education statuses. Visualize the
                  proportion of Army Combat Fitness Test (ACFT), Individual
                  Weapons Qualification (IWQ), Military Occupation Speacialty
                  Qualification (MOSQ), and Professional Military Education (PME)
                  statuses.
                </Text>

                <View style={styles.unitsFilter}>
                  <FormControl style={styles.textbox}>
                    <InputLabel id="tables-units-label">Units Filter</InputLabel>
                    <Select
                      multiple
                      value={unitType}
                      input={
                        <OutlinedInput 
                          label="Units Filter" 
                          sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor: colors.border_color,
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                              borderColor: colors.border_color,
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              borderColor: colors.border_color,
                            },
                            '& .MuiSelect-icon': {
                              color: colors.inputted_text,
                            },
                            '& .MuiInputBase-input': {
                              color: colors.inputted_text,
                            },
                            '& .MuiFormLabel-root': {
                              color: colors.inputted_text,
                            },
                            '& .MuiFormLabel-root.Mui-focused': {
                              color: colors.inputted_text,
                            },
                            '& .MuiFormHelperText-root': {
                              backgroundColor: colors.screen_background,
                              margin: 0,
                              paddingLeft: 1,
                            },
                          }}
                        />
                      }
                      onChange={(value) => {
                        handleSearch(value.target.value);
                      }}
                      renderValue={(selected) => selected.join(', ')}
                    >
                      {unitSelection.map((option) => (
                        <MenuItem style={{ whiteSpace: "pre" }} key={option.label} value={option.value}>
                          <ListItemText primaryTypographyProps={{ style: styles.listItemTextTypography }} primary={option.label} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </View>


                <Text
                  style={{
                    fontSize: 12,
                    marginHorizontal: 5,
                    textAlign: 'Left',
                    paddingTop: 5,
                    fontFamily: 'Trebuchet MS',
                    color: colors.text
                  }}
                >
                  Statuses are displayed for above selected Unit.
                </Text>
                <TabSelector />
              </View>

              <View style={{ flexDirection: 'row', flex: .5 }}>
                <View />
              </View>

              <View style={styles.legendContainer}>
                <Graphics style={{ marginTop: "2%" }} />
                <View style={styles.chartButtons}>
                  <PieSelector />
                </View>
              </View>
            </View>
          </View>


          <CsvUploadErrorLog />
          <View style={styles.tableContainer}>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                columns={columns}
                data={rows}
                tableInstanceRef={tableInstanceRef}

                renderToolbarInternalActions={({ table }) => (
                  <>
                    <Box>
                      <MRT_ToggleGlobalFilterButton table={tableInstanceRef.current} />
                      <MUITooltip arrow title="Refresh Data">
                        <IconButton onClick={() => refreshList()}>
                          <RefreshIcon />
                        </IconButton>
                      </MUITooltip>

                      { hasPermission && 
                        (
                          <MUITooltip arrow title="Upload Training CSV">
                            <IconButton onClick={() => {
                              handleTrainingInfoUploadClick();
                            }}>
                              <UploadRoundedIcon />
                            </IconButton>
                          </MUITooltip>
                        )
                      }
                      <MRT_ToggleFiltersButton table={tableInstanceRef.current} />
                      <MRT_ShowHideColumnsButton table={tableInstanceRef.current} />
                      <MRT_FullScreenToggleButton table={tableInstanceRef.current} />
                    </Box>
                  </>
                )}

              />
            </ThemeProvider>
          </View>


        </Scrollbars>
      </View>
    </ThemeProvider>
  );
}
