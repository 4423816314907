import { rolesAPI } from './api_settings';
import axios from 'axios';

/**
 * This endpoint fetches all the roles stored within the database. It's required that the user
 * has the Admin Dashboard permission to invoke this endpoint. We pass the entire permission
 * array but only look for permission code 26
 *
 * @param {Object} data - Data contains following information:
 * - permissions (Array<int>): The entire users permission array
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 * 
 * @example 
 * await get_roles({permissions: global.user_data.permissions}, global.token);
 */
export const get_roles = async (data, token) => {
  const url_endpoint = rolesAPI + 'roles/get_roles';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.post(url_endpoint, data, { headers: header });
    return response;
  } catch (error) {
    console.log(error.response)
    if (error.response) {
      return error.response;
    } else {
      return {
        statusCode: 500,
        body: 'An unexpected error occurred',
      };
    }
  }
};

/**
 * This endpoint fetches all the roles stored within the database. It's required that the user
 * has the Admin Dashboard permission to invoke this endpoint. We pass the entire permission
 * array but only look for permission code 26
 *
 * @param {Object} data - Data contains following information:
 *  - we pass over an empty object for the data, not entirely sure why the endpoint is a 
 *  post since we are returning all permissions and letting the front end handle the filtering.
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 * 
 * @example 
 * await await get_permissions({}, global.token);
 * 
 * There are two ways to fix this, either change it to a GET which makes more sense or leave it as
 * a post and pass in the role_id and we only return the permissions for that role. Could even leave
 * this as a simple GET endpoint and create a new one to return permissions for only one role
 */
export const get_permissions = async (data, token) => {
  const url_endpoint = rolesAPI + 'roles/get_permissions';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token
  }

  try {
    const response = await axios.post(url_endpoint, data, { headers: header });
    return response;
  } catch (error) {
    console.log(error.response)
    if (error.response) {
      return error.response;
    } else {
      return {
        statusCode: 500,
        body: 'An unexpected error occurred',
      };
    }
  }
};

/**
 * This endpoint allows a user to create a new role. We pass over an array of the users permissions
 * but the only permission required is the Admin Console permission which has a code of 26
 *
 * @param {Object} data - Data contains following information:
 *  - role (string): The new name for the new role
 *  - permissions (Array<int>): All permissions the user currently has
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 * 
 * @example 
 * await create_role({ role: newRoleName, permissions: permissions }, global.token);
 */
export const create_role = async (data, token) => {
  const url_endpoint = rolesAPI + 'roles/create_role';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.put(url_endpoint, data, { headers: header });
    return response;
  } catch (error) {
    console.log(error.response)
    if (error.response) {
      return error.response;
    } else {
      return {
        statusCode: 500,
        body: 'An unexpected error occurred',
      };
    }
  }
};

/*
 * UNUSED API ENDPOINT
 * Reason: Took over the project and wasn't notified for reason the api isn't being used
 * Note: This endpoint is strictly for use on mobile. This endpoint is used to find
 * all users with a specific calendar permission. This will return the users data with 
 * matching permissions and is used for Event invitees
 * Date: 3/12/2024
 * Author: Reynaldo Martinez
 */
export const get_all_role_users = async (data, token) => {
  const url_endpoint = rolesAPI + 'roles/get_all_role_users';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  const res = await axios
    .post(url_endpoint, data, { headers: header })
    .then((response) => response.data)
    .catch((error) => console.error(error.message));

  return res;
};


/**
 * This endpoint allows a user to delete a role. There aren't any permissions attached to the endpoint
 * but for a user to access this page they must have the Admin Console (code: 26)
 *
 * @param {Object} data - Data contains following information:
 *  - role_id (int): Identifier for the role to be deleted
 *  - role_name (string): The name of the role to be deleted
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 * 
 * @example 
 * await delete_role({role_id: roleID, role_name: roleName}, global.token);
 */
export const delete_role = async (data, token) => {
  const url_endpoint = rolesAPI + 'roles/delete_role';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
		const response = await axios.delete(url_endpoint, {
			headers: header,
			data: data
		});
		return response;
	} catch (error) {
		console.log(error.response)
		if (error.response) {
			return error.response;
		} else {
			return {
				statusCode: 500,
				body: 'An unexpected error occurred',
			};
		}
	}
};

/**
 * This endpoint allows a update a pre-existing role. We pass over an array of the users permissions
 * but the only permission required is the Admin Console permission which has a code of 26
 *
 * @param {Object} data - Data contains following information:
 *  - role (string): The new name for the role
 *  - permissions (Array<int>): All permissions the user currently has
 *  - role_id (int): Identifier for the pre-existing role
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 * 
 * @example 
 * await update_role({ role: roleName, permissions: permissions, role_id: roleID }, global.token);
 */
export const update_role = async (data, token) => {
  const url_endpoint = rolesAPI + 'roles/update_role';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.put(url_endpoint, data, { headers: header });
    return response;
  } catch (error) {
    console.log(error.response)
    if (error.response) {
      return error.response;
    } else {
      return {
        statusCode: 500,
        body: 'An unexpected error occurred',
      };
    }
  }
};