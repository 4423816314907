import React, { useEffect, useReducer, useState, useMemo, useRef } from 'react';
import { View, Text, TouchableOpacity, ScrollView } from 'react-native';

import ModeContext from '../../../components/ModeContext';
import { useIsFocused } from '@react-navigation/native';

import SnackbarAlert from '../../../components/SnackbarAlert';

import { useScale } from '../../../components/scale';
import Scrollbars from 'react-custom-scrollbars';
import { Box, TextField, IconButton, Link, Tooltip } from '@mui/material';
import { useTheme, darken, ThemeProvider, createTheme } from '@mui/material/styles';
import { DeleteForeverRounded } from '@mui/icons-material';

import { MaterialReactTable, MRT_FullScreenToggleButton, MRT_ToggleGlobalFilterButton, MRT_ShowHideColumnsButton, MRT_ToggleFiltersButton } from 'material-react-table';
import Modal from 'react-native-modal';

import { useColorContext } from '../../../components/ColorContext';

import { get_all_unit_documents, add_unit_document, delete_unit_document } from '../../../api_handler/units';
import { put_s3, delete_s3, generate_presigned_url_s3 } from '../../../api_handler/files';

import UploadIcon from '@mui/icons-material/Upload';
import EditIcon from '@mui/icons-material/Edit';
import NavSideBar from '../../../components/NavSideBar';
import { useNavBarContext } from '../../../components/NavBarContext';
import { useSnackbar } from '../../../components/SnackbarContext'
import * as Progress from 'react-native-progress'

function Unit_Documents({ navigation, route }) {
  // Table
  const { showSnackbar } = useSnackbar();
  const { title, unit_id, managePermission} = route.params;
  const { navState } = useNavBarContext();
  const [rows, setRows] = React.useState([]);
  const isFocused = useIsFocused();
  const tableInstanceRef = useRef(null);
  const [loaded, setLoaded] = useState(false);
  const scale = useScale();

  const { colors } = useColorContext();

  const { mode } = React.useContext(ModeContext);
  const [density, setDensity] = useState('compact');
  const [columnVisibility, setColumnVisibility] = useState({});
  const [rowSelection, setRowSelection] = useState({});
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 15 });
  const rerender = useReducer(() => ({}), {})[1]; // Forces the MRT_ components to re-render
  const [showColumnFilters, setShowColumnFilters] = useState(false);
  const [showGlobalFilter, setShowGlobalFilter] = useState(false);

  const [hasPermission, setHasPermission] = React.useState(true);
  const [hasManagePermissions, setHasManagePermissions] = React.useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('error');

  useEffect(() => {
    setHasManagePermissions(navState.canUploadUnitDocuments || global.user_data.role_id == 30)
  }, [global.user_data.permissions])

  const normalSubtitle = `This page allows you to view documents for ${global.user_data.unit_name}.`
  const adminSubtitle = `This page allows admins to manage documents for the managed unit.\nAdmins can add, view, reupload, and delete documents.\nAll documents are visible to all members of the managed unit.`

  const styles = ({
    tableContainer: {
      width: '95%',
      justifyContent: 'center',
      marginLeft: '2.5vw',
      marginTop: '7vh',
      borderColor: colors.border_color,
      borderWidth: global.user_data.theme === 'dark' ? 1 : 0,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
    },
    scrollView: {
      backgroundColor: colors.screen_background,
      height: '100%',
      width: '100%',
    },
    container: {
      backgroundColor: colors.screen_background,
      flexDirection: 'row',
      height: '100%'
    },
    title: {
      fontSize: scale(25),
      fontFamily: 'Trebuchet MS',
      marginTop: '0.5%',
      color: colors.text,
    },
    header: {
      backgroundColor: 'pink',
      height: 145,
    },
    innerContainer: {
      flexDirection: 'column',
      height: '100%',
      flex: 10
    },
    main: {
      height: '100%',
      flexDirection: 'column',
      flex: 10
    },
    titleAndDescription: {
      title: {
        fontSize: scale(30),
        fontFamily: 'Trebuchet MS',
        flex: 1,
        color: colors.text,
        paddingLeft: '5%',
        paddingTop: 25
      },
      description: {
        fontFamily: 'Trebuchet MS',
        fontSize: scale(18),
        color: colors.text,
        paddingLeft: '5.5%',
        paddingRight: '5%',
        paddingTop: '1%',
        width: '100%',
      }
    },
    buttonDiv: {
      flexDirection: 'row',
      // backgroundColor: 'red',
      height: 100,
      // width: '50%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    dropdownInput: {
      'm': 1,
      'width': 300,
      'backgroundColor': colors.textfield_background,
      '& .MuiFormLabel-root, & .MuiSelect-select': {
        color: colors.text,
      },
      // .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input
    },
    textInput: {
      width: '25%',
      margin: 20,
      marginLeft: 30,
      backgroundColor: colors.textfield_background,
    },
    deleteText: {
      fontSize: 25,
      fontFamily: 'Trebuchet MS',
      textAlign: 'center',
      color: colors.text,
    },
    textInputProps: {
      '& .MuiFormLabel-root, & label.Mui-focused, & .MuiInputBase-input': {
        color: colors.inputted_text,
      },
    },
    tableView: {
      width: '100%',
      alignItems: 'center',
      height: '80%',
      marginBottom: 50,
    },
    tableDiv: {
      marginTop: '0%',
      width: '90%',
      alignItems: 'center',
    },
    buttons: {
      margin: '2%',
      borderRadius: '40',
      alignItems: 'center',
      backgroundColor: colors.button,
      borderRadius: 15,
      shadowColor: '#171717',
      shadowOffset: { width: -2, height: 4 },
      shadowOpacity: 0.2,
      shadowRadius: 3,
      //marginTop: 30,
      //marginBottom: 30,
    },
    innerText: {
      color: colors.button_text,
      fontWeight: 'bold',
      padding: 10,
      whiteSpace: 'nowrap',
    },
    itext: {
      padding: '5%',
      color: colors.button_text,
    },
    textbox: {
      width: 400,
      marginBottom: 40,
      backgroundColor: colors.textfield_background,
      InputLabelProps: {
        sx: {
          color: colors.text,
        },
      },
    },
    tableWrapper: {
      marginTop: '5%',
      width: '90%',
      marginLeft: '5%',
      borderColor: colors.border_color,
      borderWidth: global.user_data.theme === 'dark' ? 1 : 0,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
    }
  });

  async function get_unit_documents() {
    const ret = await get_all_unit_documents({ 'unit_id': unit_id }, global.token);
    if (ret.status === 200) {
      setRows(ret.data.body);
    } else {
      showSnackbar("error", "Application Error: Failed to retrieve your units documents")
      setRows([])
    }
    setLoaded(true);
  }

  useEffect(() => {
    get_unit_documents();
  }, []);

  // Delete Unit Document code
  const [deleteModalVis, setDeleteModalVis] = React.useState(false);
  const [binary, setBinary] = React.useState();
  const [unitDocumentID, setUnitDocumentID] = React.useState();
  const [unitDocumentName, setUnitDocumentName] = React.useState();

  function startDeleteProcess(unit_document_id, unit_document_name) {
    setUnitDocumentID(unit_document_id);
    setUnitDocumentName(unit_document_name);
    setDeleteModalVis(true);
  }

  function cancelDelete() {
    setUnitDocumentID('');
    setDeleteModalVis(false);
  }

  const confirmDelete = async () => {
    setDeleteModalVis(false);
    const ret = await delete_unit_document({ unit_document_id: unitDocumentID }, global.token);
    if (ret.status !== 200) {
      showSnackbar("error", "Application Error: Failed to delete the unit document")
    } else {
      await delete_s3('units/' + unit_id + '/' + unitDocumentName, 'application/pdf', global.token);
      showSnackbar("success", "Document Successfully Deleted")

      const newRows = rows.filter((docs) => docs.unit_document_id != unitDocumentID);
      setRows(newRows);
    }
  };

  // Upload New Unit Document code
  const [uploadUnitDocumentModalVis, setUploadUnitDocumentModalVis] = React.useState(false);
  const [documentName, setDocumentName] = React.useState('');

  function uploadPDF() {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.pdf';

    input.onchange = (e) => {
      const file = e.target.files[0];
      getPDFBase64(file);
    };

    input.click();
  }

  async function getPDFBase64(file) {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    reader.onload = async function () {
      const binary = reader.result;
      setBinary(binary);
      setDocumentName(file.name.substring(0, file.name.lastIndexOf('.')));
      setUploadUnitDocumentModalVis(true);
    };

    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  async function savePDF() {
    if (documentName == null || documentName.trim() == '') {
      setDocumentName();
      showSnackbar("error", "Document Name Cannot Be Empty");
    } else {
      let finalName = documentName;
      if (finalName.includes('.pdf')) {
        finalName = finalName.replace('.pdf', '');
      }

      finalName = finalName.replace('.', '');
      finalName = finalName.replace('_', '');

      if (hasManagePermissions) {
        const ret = await add_unit_document({ unit_id: unit_id, unit_document_name: finalName.trim(), permissions: global.user_data.permissions }, token);
        if (ret.status == 400 || ret.status== 401) {
          showSnackbar("error", "Application Error: Failed to upload the document")
          setUploadUnitDocumentModalVis(false);
        } else {
          await put_s3('units/' + unit_id + '/' + finalName.trim(), binary, 'application/pdf', global.token);
          get_unit_documents();
          setUnitDocumentID;

          showSnackbar("success", "Document Successfully Uploaded")
          setUploadUnitDocumentModalVis(false);
        }
      }
    }
  };

  async function cancelPDFUpload() {
    setUploadUnitDocumentModalVis(false);
  }

  // View PDF
  async function viewPDF(name) {
    const presigned_document_url = await generate_presigned_url_s3({ key: 'units/' + unit_id + '/' + name }, global.token);
    const pdfWindow = window.open('');
    pdfWindow.document.write('<iframe width=\'100%\' height=\'100%\' src=' + presigned_document_url.body + '></iframe>');
  }

  // Update PDF
  function updatePDF(name) {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.pdf';

    input.onchange = (e) => {
      const file = e.target.files[0];
      updatePDFBinary(file, name);
    };

    input.click();
  }

  async function updatePDFBinary(file, name) {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    reader.onload = async function () {
      const binary = reader.result;
      await put_s3('units/' + unit_id + '/' + name, 'application/pdf', binary, global.token);

      setAlertMessage("Document Successfully Updated");
      setAlertSeverity("success");
      setOpenAlert(true);
    };

    reader.onerror = function (error) {
      console.log('Error: ', error);

      setAlertMessage("Something Went Wrong");
      setAlertSeverity("error");
      setOpenAlert(true);
    };
  }

  // Closes Error Message Alert
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const globalTheme = useTheme();
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: mode,
          primary: globalTheme.palette.primary,
          background: {
            default: colors.textfield_background,
          },
        },
      }),
    [globalTheme, isFocused],
  );

  const columns = useMemo(() => {
    return hasManagePermissions ? 
    [
        {
          accessorKey: 'unit_document_name',
          header: 'Document Name',
          minWidth: 100,
          align: 'center',
          Cell: ({ row }) => {
            return (<IconButton style={{ fontSize: scale(16), fontWeight: 'bold' }} onClick={() => {
              viewPDF(row.original.unit_document_name);
            }}>
              <Link style={{ color: '#81aef7', textDecoration: 'underline', fontSize: scale(16), fontWeight: 'bold' }} align='center' >
                {row.original.unit_document_name}
              </Link>
            </IconButton>);
          },
        },
        {
          accessorKey: 'del',
          header: 'Delete Document',
          minWidth: 50,
          align: 'center',
          muiTableHeadCellProps: {
            align: 'center',
          },
          muiTableBodyCellProps: {
            align: 'center',
          },
          enableSorting: false,
          enableColumnFilter: false,
          enableGrouping: false,
          Cell: ({ row }) => {
            return (<IconButton onClick={() => {
              startDeleteProcess(row.original.unit_document_id, row.original.unit_document_name);
            }}>
              <DeleteForeverRounded style={{ color: 'red' }} />
            </IconButton>);
          },
        },
        {
          accessorKey: 'edit',
          header: 'Update Document',
          minWidth: 50,
          align: 'center',
          muiTableHeadCellProps: {
            align: 'center',
          },
          muiTableBodyCellProps: {
            align: 'center',
          },
          enableSorting: false,
          enableColumnFilter: false,
          enableGrouping: false,
          Cell: ({ row }) => {
            const [isHovered, setIsHovered] = React.useState(false);
        
            const handleMouseEnter = () => {
              setIsHovered(true);
            };
        
            const handleMouseLeave = () => {
              setIsHovered(false);
            };
            return (
              <div
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <IconButton onClick={() => {
                  updatePDF(row.original.unit_document_name);
                }}>
                  <EditIcon style={{ color: isHovered ? 'green' : mode == 'dark' ? colors.white : colors.gray}}/>
                </IconButton>
              </div>
            );
          },
        },
      ]
      : [
        {
          accessorKey: 'unit_document_name',
          header: 'Document Name',
          minWidth: 100,
          align: 'center',
          Cell: ({ row }) => {
            return (<IconButton style={{ fontSize: scale(10) }} onClick={() => {
              viewPDF(row.original.unit_document_name);
            }}>
              <Link style={{ color: '#81aef7', textDecoration: 'underline' }} align='center' >
                {row.original.unit_document_name}
              </Link>
            </IconButton>);
          },
        },
      ]
  }, [hasManagePermissions])

  const loadingStyles = {
    animation: {
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
        justifyContent: 'center',
        paddingVertical: 20,
        background: colors.screen_background,
    }
  }

  if (!loaded) {
    return (
      <View style={loadingStyles.animation}>
          <Progress.CircleSnail
            color={colors.loading_circle}
            indeterminate={true}
            size={275}
            thickness={8}
          />
      </View>
    );
  }


  return (
    <Scrollbars showsverticalscrollindicator="true" style={styles.scrollView}>
      <View style={styles.container}>
        {/* <NavSideBar /> */}
          <View style={styles.innerContainer}>
            <View style={styles.main}>
              <View style={{ width: '100%' }}>
                <Text style={styles.titleAndDescription.title}>
                  {/* <Text style={{ fontWeight: 'bold' }}>Admin Console {'>'} </Text> */}
                  <Text>{title}</Text>
                </Text>
                <View style={{ marginTop: '1%', alignItems: 'flex-start', width: '90%' }}>
                  <Text style={styles.titleAndDescription.description}>{hasManagePermissions ? adminSubtitle : normalSubtitle}</Text>
                  <Text style={styles.titleAndDescription.description}>{hasManagePermissions ? `Note: All documents must be in .pdf format.` : `Note: All documents are in .pdf format.`}</Text>
                </View>
              </View>

              {/* Upload New Unit Document Modal */}
              <Modal
                isVisible={uploadUnitDocumentModalVis}
                style={{
                  alignSelf: 'center',
                  margin: 0,
                  width: '40%',
                }}
              >
                <View style={{
                  backgroundColor: colors.textfield_background,
                  alignItems: 'center',
                  height: '40%',
                }}>
                  <View style={{ height: '25%', alignItems: 'center', marginTop: 50 }}>
                    <Text style={{
                      fontSize: 50,
                      fontFamily: 'Trebuchet MS',
                      textAlign: 'center',
                      color: colors.text,
                    }}>Rename Document</Text>
                  </View>

                  <TextField
                    style={styles.textbox}
                    label="Document Name"
                    value={documentName}
                    onChange={(data) => setDocumentName(data.target.value)}
                    InputLabelProps={styles.textbox.InputLabelProps}
                    inputProps={styles.textbox.InputLabelProps}
                  />

                  <View style={{
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'space-around',
                  }}>
                    <TouchableOpacity
                      style={{
                        width: '15%',
                        borderRadius: '40',
                        alignItems: 'center',
                        backgroundColor: colors.button,
                        borderRadius: 15,
                        shadowColor: '#171717',
                        shadowOffset: { width: -2, height: 4 },
                        shadowOpacity: 0.2,
                        shadowRadius: 3,
                        marginTop: 30,
                        marginBottom: 30,
                      }}
                      onPress={() => cancelPDFUpload()}>
                      <Text style={styles.innerText}>Cancel</Text>
                    </TouchableOpacity>

                    <TouchableOpacity
                      style={{
                        width: '15%',
                        borderRadius: '40',
                        alignItems: 'center',
                        backgroundColor: colors.button,
                        borderRadius: 15,
                        shadowColor: '#171717',
                        shadowOffset: { width: -2, height: 4 },
                        shadowOpacity: 0.2,
                        shadowRadius: 3,
                        marginTop: 30,
                        marginBottom: 30,
                      }}
                      onPress={() => savePDF()}>
                      <Text style={styles.innerText}>Submit</Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </Modal>
              {/* End of Upload New Unit Document Modal */}

              {/* Delete document modal */}
              <Modal
                isVisible={deleteModalVis}
                style={{
                    width: '40%',
                    height: '100%',
                    marginLeft: '37%',
                    marginTop: '5%',
                    zIndex: 1000,
                }}
              >
                <View style={{
                    backgroundColor:  colors.screen_background,
                    alignItems: 'center',
                    padding: '5%',
                    borderRadius: 10,
                    flexDirection: 'column',
                }}>
                  <View style={{ height: '50%', alignItems: 'center', marginTop: 25 }}>
                    <Text style={styles.deleteText}>{'Are you sure you would like to delete the document "' + unitDocumentName + '"?'}</Text>
                  </View>

                  <View style={{
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'space-around',
                  }}>
                    <TouchableOpacity
                      style={{
                        width: '15%',
                        borderRadius: 40,
                        alignItems: 'center',
                        backgroundColor:  colors.button,
                        shadowColor: '#171717',
                        shadowOffset: {width: -2, height: 4},
                        shadowOpacity: 0.2,
                        shadowRadius: 3,
                        margin: '2%'
                      }}
                      onPress={() => cancelDelete()}>
                      <Text style={styles.innerText}>Cancel</Text>
                    </TouchableOpacity>

                    <TouchableOpacity
                      style={{
                        width: '15%',
                        borderRadius: 40,
                        alignItems: 'center',
                        backgroundColor:  colors.button,
                        shadowColor: '#171717',
                        shadowOffset: {width: -2, height: 4},
                        shadowOpacity: 0.2,
                        shadowRadius: 3,
                        margin: '2%'
                      }}
                      onPress={() => confirmDelete()}>
                      <Text style={styles.innerText}>Submit</Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </Modal>
              {/* End of Delete Modal */}

              <View style={styles.tableWrapper}>
                <ThemeProvider theme={tableTheme}>
                  <MaterialReactTable
                    columns={columns}
                    data={rows}
                    enableRowSelection
                    positionToolbarAlertBanner={'bottom'}
                    tableInstanceRef={tableInstanceRef}
                    enableGrouping
                    enableColumnDragging={false}
                    enableStickyHeader
                    enableExpandAll={false}
                    selectAllMode={'all'}
                    isMultiSortEvent={() => true}

                    initialState={{
                      showGlobalFilter: false,
                      columnOrder: ['unit_document_name', 'edit', 'del'],
                    }}
                    muiTableBodyProps={{
                      sx: (theme) => ({
                        '& tr:nth-of-type(n)': {
                          backgroundColor: colors.table_background_color,
                        },
                        '& tr:nth-of-type(2n-1)': {
                          backgroundColor: darken(theme.palette.background.default, 0.075),
                        },
                        '& tr.Mui-selected': {
                          backgroundColor: colors.table_selected,
                        },
                        '& tr:hover:not(.Mui-selected) > td': {
                          backgroundColor: colors.table_hover,
                        },
                      }),
                    }}

                    // Table State Management for updater function
                    onColumnVisibilityChange={(updater) => {
                      setColumnVisibility((prev) =>
                        updater instanceof Function ? updater(prev) : updater,
                      );
                      queueMicrotask(rerender); // Rerender after state update
                    }}


                    onRowSelectionChange={(updater) => {
                      setRowSelection((prev) =>
                        updater instanceof Function ? updater(prev) : updater,
                      );
                      queueMicrotask(rerender); // Rerender after state update
                    }}

                    onPaginationChange={(updater) => {
                      setPagination((prev) =>
                        updater instanceof Function ? updater(prev) : updater,
                      );
                      queueMicrotask(rerender); // Rerender after state update
                    }}

                    onShowColumnFiltersChange={(updater) => {
                      setShowColumnFilters((prev) =>
                        updater instanceof Function ? updater(prev) : updater,
                      );
                      queueMicrotask(rerender); // Rerender after state update
                    }}

                    onShowGlobalFilterChange={(updater) => {
                      setShowGlobalFilter((prev) =>
                        updater instanceof Function ? updater(prev) : updater,
                      );
                      queueMicrotask(rerender); // Rerender after state update
                    }}

                    state={{
                      columnVisibility,
                      density,
                      rowSelection,
                      pagination,
                      showColumnFilters,
                      showGlobalFilter,
                    }}

                    
                    renderToolbarInternalActions={({ table }) => {
                        return hasManagePermissions ? (
                            <>
                                <Box>
                                <MRT_ToggleGlobalFilterButton table={tableInstanceRef.current} />
                                <Tooltip arrow title="Upload Unit Document">
                                    <IconButton onClick={() => uploadPDF(true)}>
                                    <UploadIcon />
                                    </IconButton>
                                </Tooltip>
                                <MRT_ToggleFiltersButton table={tableInstanceRef.current} />
                                <MRT_ShowHideColumnsButton table={tableInstanceRef.current} />
                                <MRT_FullScreenToggleButton table={tableInstanceRef.current} />
                                </Box>
                            </>
                        ) : null
                    }} 
                  />
                </ThemeProvider>
                <SnackbarAlert
                  open={openAlert}
                  onClose={handleCloseAlert}
                  severity={alertSeverity}
                  message={alertMessage}
                  anchorVertical={'bottom'}
                  anchorHorizontal={'right'}
                  autoHideDuration={5000}
                  paddingBottom="2.5%"
                  paddingRight="2%"
                />
              </View>
            </View>
          </View>
      </View >
    </Scrollbars>
  );
};

export default Unit_Documents;
