import React, { useState, useEffect } from "react";
import { View, Text, StyleSheet } from "react-native";
import { useColorContext } from "../ColorContext";
import { formatDollarAmount } from "../../helpers/textFormatting";

const Stats = ({ getBudgetStats, activeChart, budget, refresh}) => {
    const { colors } = useColorContext();
    const [stats, setStats] = useState(null);

    
    useEffect(() => {
        let isMounted = true;
            const fetchData = async () => {
                try {
                    const response = await getBudgetStats();
                    const data = response.data.body;
                    if (isMounted) {
                        setStats(data);
                    }
                } catch (error) {
                    console.error('Error fetching data:', error.message);
                }
            };

            fetchData();

            return () => {
                isMounted = false; // Update mounted flag to false on unmount
            };
        }, [getBudgetStats, budget, refresh]);

    // Trigger getBudgetStats when refresh prop changes
    useEffect(() => {
        getBudgetStats(); // Trigger getBudgetStats function
    }, [refresh]);

    if (!stats) {
        return null;
    }


    return (
        <View style={styles.container}>
            <Text style={styles.header}>Stats</Text>
            <View style={styles.statsContainer}>
                <View style={styles.stat}>
                    <Text style={styles.label}>Budget</Text>
                    <Text style={[styles.value, { color: colors.bow }]}>
                        {budget ? formatDollarAmount(stats.subtype.budget) : '---'}
                    </Text>
                </View>
                <View style={styles.stat}>
                    <Text style={styles.label}>Paid</Text>
                    <Text style={[styles.value, { color: colors.bow }]}>
                        {formatDollarAmount(stats.subtype.spent)}
                    </Text>
                </View>
                {(activeChart === "ALL" &&
                <View style={styles.stat}>
                    <Text style={styles.label}>Last Edit</Text>
                    <Text style={[styles.value, { color: colors.bow }]}>
                        {/*stats will need to pull last edit date*/'---'}
                    </Text>
                </View>
                )}
            </View>
            <View style={styles.statsContainer}>
                <View style={styles.stat}>
                    <Text style={styles.label}>Approved</Text>
                    <Text style={[styles.value, { color: colors.bow }]}>
                        {formatDollarAmount(stats.subtype.approved)}
                    </Text>
                </View>
                <View style={styles.stat}>
                    <Text style={styles.label}>Requested</Text>
                    <Text style={[styles.value, { color: colors.bow }]}>
                        {formatDollarAmount(stats.subtype.submitted)}
                    </Text>
                </View>
                {(activeChart === "ALL" &&
                <View style={styles.stat}>
                    <Text style={styles.label}>Remaining</Text>
                    <Text style={[styles.value, { color: colors.bow }]}>
                        {formatDollarAmount(stats.budget.remaining)}
                    </Text>
                </View>
                )}
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        padding: 16,
        marginHorizontal: 25,
    },
    header: {
        fontSize: 20,
        fontWeight: "bold",
        marginBottom: 16,
        color: "gray",
    },
    statsContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
    },
    stat: {
        flexShrink: 1,
        padding: 8,
        margin: 4,
    },
    label: {
        fontSize: 16,
        fontWeight: "bold",
        marginBottom: 4,
        color: "gray",
    },
    value: {
        fontSize: 16,
    },
});

export default Stats;
