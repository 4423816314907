import { medicalAPI } from './api_settings';
import axios from 'axios';

/*
 * UNUSED API ENDPOINT
 * Reason: Took over the project and wasn't notified for reason the api isn't being used
 * Note: This endpoint is strictly for use on mobile. This pulls your medical data from the database
 * and displays it to you on the 'Review' tab on mobile. I guess this functionality was scraped when 
 * implementing functionality
 * Date: 3/12/2024
 * Author: Reynaldo Martinez
 */
export const get_medical_data = async (data, token) => {
  const url_endpoint = medicalAPI + 'medical/get_medical_data';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.post(url_endpoint, data, { headers: header });
    return response;
} catch (error) {
    console.log(error.response)
    if (error.response) {
        return error.response;
    } else {
        return {
            statusCode: 500,
            body: 'An unexpected error occurred',
        };
    }
}
};

/**
 * Retrieves all the medical data for the users subordinates
 *
 * @param {Object} data - Data contains following information:
 * - user_id (int): Identifier for the user
 * 
 * @param {string} token - Auth token to be allowed to invoke API
 *
 * @example
 * get_subordinates_med_data({ user_id: global.user_data.user_id, permissions: permissions: global.user_data.permissions}, global.idToken);
 */
export const get_subordinates_med_data = async (data, token) => {
  const url_endpoint = medicalAPI + 'medical/get_subordinates_med_data';

  const header = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  try {
    const response = await axios.post(url_endpoint, data, { headers: header });
    return response;
  } catch (error) {
    console.log(error.response)
    if (error.response) {
      return error.response;
    } else {
      return {
        statusCode: 500,
        body: 'An unexpected error occurred',
      };
    }
  }
};
