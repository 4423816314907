import React, { useState, useContext } from 'react';
import { View, Text, TextInput, TouchableOpacity, Modal } from 'react-native';
import { lightModeStyles, darkModeStyles, budgetOverviewStyles, modalStyles } from './styles';
import ModeContext from "../ModeContext";

const AlertThreshold = ({ isVisible, onCancel, onSave }) => {
  const [threshold, setThreshold] = useState('');
  const { mode } = useContext(ModeContext);
  const styles = mode === 'light' ? lightModeStyles : darkModeStyles;

  const handleSave = () => {
    // Validate the threshold input
    const parsedThreshold = parseFloat(threshold);
    if (isNaN(parsedThreshold) || parsedThreshold < 1 || parsedThreshold > 100) {
      // You can display an error message or handle it as needed
      return;
    }

    // Call the onSave function with the parsed and validated threshold
    onSave(parsedThreshold);

    // Clear the input field
    setThreshold('');
  };

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={isVisible}
    >
      <View style={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 22,
      }}>
        <View style={{
          backgroundColor: mode === 'light' ? '#f0ecec' : '#201c1c', // Light/Dark mode for modal
          borderRadius: 10,
          padding: 20,
          alignItems: 'center',
          width: 300,
          height: 250,
          shadowColor: mode === 'light' ? 'black' : 'white',
          shadowOffset: {
            width: 1,
            height: 1,
          },
          shadowOpacity: 0.55,
          shadowRadius: 6,
          elevation: 6,
        }}>
          <Text style={[styles.text, { fontWeight: 'bold', marginBottom: 5, textAlign: 'center', fontSize: '18px' }]}>
            Enter Alert Threshold
          </Text>
          <Text style={[styles.text, { fontWeight: 'bold', marginBottom: 10, textAlign: 'center' }]}>
            (sends an alert when the budget reaches the entered percentage):
          </Text>
          <TextInput
            style={{
              backgroundColor: 'transparent', // Make the background transparent
              borderColor: mode === 'light' ? 'black' : 'white',
              borderWidth: 1,
              padding: 10,
              borderRadius: 5,
              marginBottom: 20,
              width: '100%',
              color: mode === 'light' ? 'black' : 'white',
            }}
            placeholder="Percentage (1-100)%"
            keyboardType="numeric"
            value={threshold}
            onChangeText={setThreshold}
          />

          <View style={modalStyles.buttonContainer}>
            <TouchableOpacity
              style={[budgetOverviewStyles.editButton, {alignItems: 'center', padding: '10px' }]}
              onPress={handleSave}
            >
              <Text style={{ fontWeight: 'bold', color: 'white' }}>Save</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[budgetOverviewStyles.deleteButton, { alignItems: 'center', padding: '10px' }]}
              onPress={onCancel}
            >
              <Text style={{ fontWeight: 'bold', color: 'white' }}>Cancel</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default AlertThreshold;