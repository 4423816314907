/* eslint-disable no-trailing-spaces */
/* eslint-disable require-jsdoc */
/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useRef } from 'react';
import { View, StyleSheet } from 'react-native';
import { useColorContext } from '../ColorContext';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { ThemeProvider, HelperText, TextInput } from 'react-native-paper';
import { ClickAwayListener, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { createTheme, useTheme } from '@mui/material/styles';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const AFTPTrainingPeriod = ({
  periodNumber,
  typeOfTrainingCompletedValue,
  typeOfTrainingCompletedValueEmptyError,
  setTypeOfTrainingCompletedValueEmptyError,
  typeOfTrainingCompletedOther,
  setTypeOfTrainingCompletedOther,
  typeOfTrainingCompletedOtherEmptyError,
  setTypeOfTrainingCompletedOtherEmptyError,
  groundTrainingDescription,
  setGroundTrainingDescription,
  setTypeOfTrainingCompletedValue,
  setIsGround,
  formattedTimeIn,
  setTimeIn,
  setTimeIn2,
  timeIn,
  timeInEmptyError,
  setTimeInEmptyError,
  formattedTimeOut,
  timeOut,
  timeIn2,
  timeOut2,
  formattedTimeIn2,
  formattedTimeOut2,
  setTimeOut,
  setTimeOut2,
  timeOutEmptyError,
  setTimeOutEmptyError,
  timeOutErrorText,
  setUserEditedTimeIn,
  setUserEditedTimeOut,
  setUserEditedTimeIn2,
  setUserEditedTimeOut2,
  setFormattedTimeOut,
  setFormattedTimeOut2,
  AFTPNumber,
  setAFTPNumber,
  AFTPNumberEmptyError,
  setAFTPNumberEmptyError,
  supportNumber,
  setSupportNumber,
  supportNumberError,
  setSupportNumberError,
  isGround,
  tailNumber,
  setTailNumber,
  tailNumberError,
  setTailNumberError,
  tailNumberErrorText,
  FLTHRS,
  setFLTHRS,
  FLTHRSError,
  setFLTHRSError,
  FLTHRSErrorText,
  setFLTHRSErrorText,
  timePicker1Open,
  setTimePicker1Open,
  timePicker2Open,
  setTimePicker2Open,
  setFormattedTimeIn,
  setFormattedTimeIn2,
  setTailNumberErrorText,
  locked,
  pulled,
  hours,
  eventDate,
  eventDate3,
}) => {
  const { colors } = useColorContext();
  const theme = useTheme();
  const timePicker1Ref = useRef(null);
  const timePicker2Ref = useRef(null);
  const payTheme = createTheme({
    palette: {
      mode: colors.mode,
      primary: theme.palette.primary,
    },
  });
  const styles = StyleSheet.create({
    dropDownStyle: {
      borderColor: colors.border_color,
      borderWidth: 1,
      marginHorizontal: 12,
      marginVertical: 6,
      width: '95%',
      backgroundColor: colors.textfield_background,
      justifyContent: 'center',
    },
    contentContainer: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flex: 1,
    },
    textInputProps: {
      '& .MuiFormLabel-root, & label.Mui-focused, & .MuiInputBase-input': {
        color: colors.inputted_text,
      },
      '.MuiSvgIcon-root': {
        fill: colors.inputted_text,
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: colors.border_color,
        },
        '&:hover fieldset': {
          borderColor: colors.border_color,
        },
        '&.Mui-focused fieldset': {
          borderColor: colors.border_color,
        },
      },
      '& .MuiFormHelperText-root': {
        backgroundColor: colors.screen_background,
        margin: 0,
        paddingLeft: 1,
      },
    },
    selectProps: {
      MenuProps: {
        sx: {
          '& .MuiMenu-paper': {
            background: colors.textfield_background,
            color: colors.inputted_text,
          },
        },
      },
    },
    inputSplitLocked: {
      backgroundColor: colors.screen_background,
      borderColor: colors.border_color,
    },
    inputSplit: {
      backgroundColor: colors.textfield_background,
      borderColor: colors.border_color,
    },
  });

  const handleAftpDutyChange = (event) => {
    setTypeOfTrainingCompletedValue(event.target.value);
    if (event.target.value === 'Ground Training' || event.target.value === 'Flight Physical') {
      setIsGround(true);
      setTailNumber('');
      setTailNumberError(false);
      setTailNumberErrorText('');
      setFLTHRS('');
      setFLTHRSError(false);
      setFLTHRSErrorText('');
    } else if (isGround) {
      setIsGround(false);
      setSupportNumber('');
      setSupportNumberError(false);
    }
  };

  function padZero(num) {
    return num.toString().padStart(2, '0');
  }

  function addHours(time, hoursToAdd) {
    const [hours, minutes] = time.split(':').map(Number);
    const totalMinutes = hours * 60 + minutes + hoursToAdd * 60;
    const newHours = Math.floor(totalMinutes / 60) % 24;
    const newMinutes = totalMinutes % 60;
    return `${padZero(newHours)}:${padZero(newMinutes)}`;
  }

  const onSetAFTPNumber = (AFTPNumber) => {
    // if (periodNumber == '1') console.log(timeIn, timeOut, addHours(timeIn, 4), eventDate, eventDate.add(1, 'day'));
    // if (periodNumber == '2') console.log(timeIn2, timeOut2, eventDate3);
    if (+AFTPNumber || AFTPNumber === '') {
      setAFTPNumber(AFTPNumber);
    }
  };

  const onSetSupportNumber = (supportNumber) => {
    if (+supportNumber || supportNumber === '') {
      setSupportNumber(supportNumber);
    }
  };

  const onSetFLTHRS = (FLTHRS) => {
    setFLTHRSError(false);
    setFLTHRS(FLTHRS);
  };

  const onSetTailNumber = (tailNumber) => {
    setTailNumberError(false);
    if (+tailNumber || tailNumber === '') {
      setTailNumber(tailNumber);
    }
  };

  // This is the list that appears in Select Type of Training Completed for Period X
  const TYPEOFTRAININGAFTP = [
    {
      label: 'RL Progression',
      value: 'RL Progression',
    },
    {
      label: 'Continuation Training',
      value: 'Continuation Training',
    },
    {
      label: 'Mission',
      value: 'Mission',
    },
    {
      label: 'APART/PFE/Evaluation',
      value: 'APART/PFE/Evaluation',
    },
    {
      label: 'Simulator',
      value: 'Simulator',
    },
    {
      label: 'Maintenance Test Flight',
      value: 'Maintenance Test Flight',
    },
    {
      label: 'Ground Training',
      value: 'Ground Training',
    },
    {
      label: 'Flight Physical',
      value: 'Flight Physical',
    },
    {
      label: 'Other Flight',
      value: 'Other Flight',
    },
  ];

  const AFTPTrainingItems = TYPEOFTRAININGAFTP.map((item) => (
    <MenuItem key={item.value} value={item.value}>
      {item.label}
    </MenuItem>
  ));

  return (
    <View style={{ width: hours === '8' ? '48.5%' : '100%', justifyContent: 'center', alignItems: 'center' }}>
      <View style={{ width: '100%', alignItems: 'center', backgroundColor: colors.screen_background }}>
        <FormControl
          fullWidth
          style={styles.dropDownStyle}
          sx={styles.textInputProps}
        >
          <InputLabel id="duty_checkbox">
            Select Type of Training Completed for Period #{periodNumber}*
          </InputLabel>
          <Select
            id="Type of Training Select"
            inputProps={styles.selectProps}
            label={`Select Type of Training Completed for Period #${periodNumber}*`}
            labelId="duty_checkbox"
            value={typeOfTrainingCompletedValue}
            onChange={handleAftpDutyChange}
            onBlur={() => {
              setTypeOfTrainingCompletedValueEmptyError(typeOfTrainingCompletedValue === '');
            }}
            error={typeOfTrainingCompletedValueEmptyError}
            display={"true"}
          >
            {AFTPTrainingItems}
          </Select>
        </FormControl>
        <View style={{ backgroundColor: colors.screen_background }}>
          <HelperText style={{ color: 'red', marginBottom: typeOfTrainingCompletedValueEmptyError ? '5%' : '0%' }}>{typeOfTrainingCompletedValueEmptyError ? 'Enter type of training' : ' '}</HelperText>
        </View>
      </View>

      {typeOfTrainingCompletedValue === 'Other Flight' && (
        <View style={{ width: '100%', alignItems: 'center' }}>
          <TextInput
            id="Other Type of Training Completed"
            theme={{
              colors: {
                text: colors.inputted_text,
                accent: colors.textfield_background,
                primary: colors.inputted_text,
                placeholder: colors.placeholder_text,
              },
            }}
            selectionColor={'black'}
            activeOutlineColor={colors.inputted_text}
            outlineColor={colors.inputted_text}
            style={[
              locked ? styles.inputSplitLocked : styles.inputSplit,
              {
                width: '95%',
                borderColor: colors.border_color,
                borderWidth: 1,
                borderRadius: 5,
                padding: 0,
              },
            ]}
            onChangeText={setTypeOfTrainingCompletedOther}
            value={typeOfTrainingCompletedOther}
            label="Type of Training Completed"
            editable={pulled ? true : false}
            onBlur={() => {
              setTypeOfTrainingCompletedOtherEmptyError(typeOfTrainingCompletedOther === '');
            }}
            error={typeOfTrainingCompletedOtherEmptyError}
          />
          <HelperText style={{ color: 'red', marginBottom: typeOfTrainingCompletedOtherEmptyError ? '5%' : '0%' }}>{typeOfTrainingCompletedOtherEmptyError ? 'Enter type of training' : ' '}</HelperText>
        </View>
      )}

      {/* aqui */}
      {typeOfTrainingCompletedValue === 'Ground Training' && (
        <View style={{ width: '100%', alignItems: 'center' }}>
          <TextInput
            theme={{
              colors: {
                text: colors.inputted_text,
                accent: colors.textfield_background,
                primary: colors.inputted_text,
                placeholder: colors.placeholder_text,
              },
            }}
            id="ground-training-description"
            mode="outlined"
            label="Ground Training Description (optional)"
            selectionColor={'black'}
            activeOutlineColor={colors.inputted_text}
            outlineColor={colors.inputted_text}
            style={[styles.inputSplit,
              {
                width: '95%',
                borderColor: colors.border_color,
                borderRadius: 5,
                marginTop: '-1%',
                marginBottom: '3%',
              },
            ]}
            onChangeText={setGroundTrainingDescription}
            value={groundTrainingDescription}
          />
        </View>
      )}

      <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%', paddingTop: 15 }}>
        <View style={styles.contentContainer}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ThemeProvider theme={payTheme}>
              <ClickAwayListener
                onClickAway={(event) => {
                  if (timePicker1Ref.current && !timePicker1Ref.current.contains(event.target)) {
                    setTimePicker1Open(false);
                  }
                }}
                mouseEvent="onMouseDown"
              >
                <TimePicker
                  id="Start Time Picker"
                  label="Start Time*"
                  ampm={false}
                  onChange={(time) => {
                    if (periodNumber == '1') {
                      setTimeIn(time.format('HH:mm'));
                      setFormattedTimeIn(time);

                      setTimeOut(addHours(time.format('HH:mm'), 4));
                      setFormattedTimeOut(time.clone().add(4, 'hours'));

                      setTimeIn2(addHours(time.format('HH:mm'), 4));
                      setFormattedTimeIn2(time.clone().add(4, 'hours'));

                      setTimeOut2(addHours(time.format('HH:mm'), 8));
                      setFormattedTimeOut2(time.clone().add(8, 'hours'));

                      setUserEditedTimeIn(true);
                      setUserEditedTimeOut(true);
                      setUserEditedTimeIn2(true);
                      setUserEditedTimeOut2(true);
                      setTimeInEmptyError(!time || !time.isValid());
                    } else if (periodNumber == '2') {
                      setTimeIn2(time.format('HH:mm'));
                      setFormattedTimeIn2(time);

                      setTimeOut2(addHours(time.format('HH:mm'), 4));
                      setFormattedTimeOut2(time.clone().add(4, 'hours'));

                      setUserEditedTimeIn2(true);
                      setUserEditedTimeOut2(true);
                      setTimeInEmptyError(!time || !time.isValid());
                    }
                  }}
                  open={timePicker1Open}
                  onClose={() => setTimePicker1Open(false)}
                  onOpen={() => setTimePicker1Open(true)}
                  value={periodNumber == '1' ? formattedTimeIn : formattedTimeIn2}
                  error={timeInEmptyError}
                  slotProps={{
                    textField: {
                      sx: {
                        'width': '90%',
                        'marginLeft': '5%',
                        'marginRight': '4%',
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: colors.border_color,
                          },
                          '&:hover fieldset': {
                            borderColor: colors.border_color,
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: colors.border_color,
                          },
                          '& .MuiInputBase-input': {
                            color: colors.inputted_text,
                          },
                        },
                        '& .MuiInputLabel-root': {
                          color: colors.inputted_text,
                        },
                        '& .Mui-focused .MuiInputLabel-root': {
                          color: colors.inputted_text,
                        },
                      },
                      inputProps: {
                        onFocus: () => {
                          setTimeInEmptyError(false);
                        },
                        onBlur: () => {
                          setTimeInEmptyError(!formattedTimeIn || !formattedTimeIn.isValid());
                        },
                      },
                    },
                  }}
                  PopperProps={{
                    modifiers: [
                      {
                        name: 'eventListeners',
                        options: {
                          scroll: false,
                          resize: true,
                        },
                      },
                    ],
                    ref: (popperRef) => {
                      if (popperRef && popperRef.node) {
                        popperRef.node.id = 'calendar-element';
                        timePicker1Ref.current = popperRef.node.querySelector('.MuiCalendarPicker-root');
                      }
                    },
                  }}
                />
              </ClickAwayListener>
            </ThemeProvider>
          </LocalizationProvider>
          <HelperText style={{ color: 'red' }}>{timeInEmptyError ? 'Select start time for period #1' : ' '}</HelperText>
        </View>
        <View style={styles.contentContainer}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ThemeProvider theme={payTheme}>
              <ClickAwayListener
                onClickAway={(event) => {
                  if (timePicker2Ref.current && !timePicker2Ref.current.contains(event.target)) {
                    setTimePicker2Open(false);
                  }
                }}
                mouseEvent="onMouseDown"

              >
                <TimePicker
                  id="End Time Picker"
                  label="End Time*"
                  ampm={false}
                  onChange={(time) => {
                    if (periodNumber == '1') {
                      setTimeOut(time.format('HH:mm'));
                      setFormattedTimeOut(time);

                      setTimeIn2(time.format('HH:mm'));
                      setFormattedTimeIn2(time);

                      setTimeOut2(addHours(time.format('HH:mm'), 4));
                      setFormattedTimeOut2(time.clone().add(4, 'hours'));

                      setUserEditedTimeOut(true);
                      setUserEditedTimeIn2(true);
                      setUserEditedTimeOut2(true);
                      setTimeOutEmptyError(!time || !time.isValid());
                    } else if (periodNumber == '2') {
                      setTimeOut2(time.format('HH:mm'));
                      setFormattedTimeOut2(time);
                      setUserEditedTimeOut2(true);
                      setTimeOutEmptyError(!time || !time.isValid());
                    }
                  }}
                  open={timePicker2Open}
                  onClose={() => setTimePicker2Open(false)}
                  onOpen={() => setTimePicker2Open(true)}
                  value={periodNumber == '1' ? (formattedTimeOut ?? formattedTimeIn) : (formattedTimeOut2 ?? formattedTimeIn2)}
                  error={timeOutEmptyError}
                  slotProps={{
                    textField: {
                      sx: {
                        'width': '90%',
                        'marginLeft': '5%',
                        'marginRight': '4%',
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: colors.border_color,
                          },
                          '&:hover fieldset': {
                            borderColor: colors.border_color,
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: colors.border_color,
                          },
                          '& .MuiInputBase-input': {
                            color: colors.inputted_text,
                          },
                        },
                        '& .MuiInputLabel-root': {
                          color: colors.inputted_text,
                        },
                        '& .Mui-focused .MuiInputLabel-root': {
                          color: colors.inputted_text,
                        },
                      },
                      inputProps: {
                        onFocus: () => {
                          setTimeInEmptyError(false);
                        },
                        onBlur: () => {
                          setTimeInEmptyError(!formattedTimeIn || !formattedTimeIn.isValid());
                        },
                      },
                    },
                  }}
                  PopperProps={{
                    modifiers: [
                      {
                        name: 'eventListeners',
                        options: {
                          scroll: false,
                          resize: true,
                        },
                      },
                    ],
                    ref: (popperRef) => {
                      if (popperRef && popperRef.node) {
                        popperRef.node.id = 'calendar-element';
                        timePicker2Ref.current = popperRef.node.querySelector('.MuiCalendarPicker-root');
                      }
                    },
                  }}
                />
              </ClickAwayListener>
            </ThemeProvider>
          </LocalizationProvider>
          <HelperText style={{ color: 'red' }}>{timeOutErrorText}</HelperText>
        </View>
      </View>

      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          width: '95%',
        }}
      >
        <View style={styles.contentContainer}>
          <TextInput
            id="AFTP Number"
            keyboardType={'numeric'}
            theme={{
              colors: {
                text: colors.inputted_text,
                accent: colors.textfield_background,
                primary: colors.inputted_text,
                placeholder: colors.placeholder_text,
              },
            }}
            style={[
              locked ? styles.inputSplitLocked : styles.inputSplit,
              {
                width: '95%',
                marginRight: '4%',
                borderColor: colors.border_color,
                borderWidth: 1,
                borderRadius: 5,
              },
            ]}
            label="AFTP Number*"
            placeholder={AFTPNumber}
            multiline={false}
            value={AFTPNumber}
            onChangeText={onSetAFTPNumber}
            onBlur={() => {
              setAFTPNumberEmptyError(AFTPNumber === '');
            }}
            error={AFTPNumberEmptyError}
          />
          <HelperText style={{ color: 'red' }}>{AFTPNumberEmptyError ? 'Enter AFTP number' : ' '}</HelperText>
        </View>
        <View style={styles.contentContainer}>
          <TextInput
            id="Ground AFTP Number"
            keyboardType={'numeric'}
            theme={{
              colors: {
                text: !isGround ? colors.disabled_text : colors.inputted_text,
                accent: colors.textfield_background,
                primary: !isGround ? colors.disabled_text : colors.inputted_text,
                placeholder: !isGround ? colors.disabled_text : colors.placeholder_text,
                disabled: !isGround ? colors.disabled_text : colors.border_color,
              },
            }}
            style={[
              !isGround ? styles.inputSplitLocked : styles.inputSplit,
              {
                width: '95%',
                marginLeft: '4%',
                borderColor: !isGround ? colors.disabled_text : colors.border_color,
                borderWidth: 1,
                borderRadius: 5,
              },
            ]}
            label="Ground AFTP Number"
            placeholder=""
            disabled={!isGround}
            multiline={false}
            value={supportNumber}
            onChangeText={onSetSupportNumber}
            error={supportNumberError}
            onBlur={() => {
              if (isGround && typeOfTrainingCompletedValue != '') {
                setSupportNumberError(supportNumber === '');
              }
            }}
          />
          <HelperText style={{ color: 'red' }}>{supportNumberError ? 'Enter ground number' : ' '}</HelperText>
        </View>
      </View>

      <View
        style={{
          flexDirection: 'row',
          alignItems: 'start',
          marginTop: 10,
          marginBottom: 10,
          width: '95%',
        }}
      >
        <View style={styles.contentContainer}>
          <TextInput
            id="Tail Number"
            keyboardType={'numeric'}
            theme={{
              colors: {
                text: typeOfTrainingCompletedValue == ''  || typeOfTrainingCompletedValue == 'Ground Training' || typeOfTrainingCompletedValue == 'Flight Physical' 
                  ? colors.disabled_text : colors.inputted_text,
                accent: colors.textfield_background,
                primary: typeOfTrainingCompletedValue == ''  || typeOfTrainingCompletedValue == 'Ground Training' || typeOfTrainingCompletedValue == 'Flight Physical'
                  ? colors.disabled_text : colors.inputted_text,
                placeholder: typeOfTrainingCompletedValue == ''  || typeOfTrainingCompletedValue == 'Ground Training' || typeOfTrainingCompletedValue == 'Flight Physical' 
                  ? colors.disabled_text : colors.placeholder_text,
                disabled: typeOfTrainingCompletedValue == ''  || typeOfTrainingCompletedValue == 'Ground Training' || typeOfTrainingCompletedValue == 'Flight Physical' 
                  ? colors.disabled_text : colors.border_color,
              },
            }}
            style={[
              typeOfTrainingCompletedValue == ''  || typeOfTrainingCompletedValue == 'Ground Training' || typeOfTrainingCompletedValue == 'Flight Physical'
                ? styles.inputSplitLocked : styles.inputSplit,
              {
                width: '95%',
                marginRight: '4%',
                borderColor: typeOfTrainingCompletedValue == ''  || typeOfTrainingCompletedValue == 'Ground Training' || typeOfTrainingCompletedValue == 'Flight Physical'
                  ? colors.disabled_text : colors.border_color,
                borderWidth: 1,
                borderRadius: 5,
              },
            ]}
            label="Tail Number"
            placeholder=""
            disabled={isGround || typeOfTrainingCompletedValue == 'Ground Training'}
            multiline={false}
            value={tailNumber}
            onChangeText={onSetTailNumber}
            onBlur={() => {
              if (!isGround && typeOfTrainingCompletedValue != '') {
                if (tailNumber === '') {
                  setTailNumberError(true);
                  setTailNumberErrorText('Enter tail number for period #1');
                  return;
                } else if (tailNumber.length < 3) {
                  setTailNumberError(true);
                  setTailNumberErrorText('Tail number must have at least 3 digits');
                  return;
                }
              }
              setTailNumberError(false);
              setTailNumberErrorText(' ');
            }}
            error={tailNumberError}
          />
          <HelperText style={{ color: 'red' }}>{tailNumberError && (typeOfTrainingCompletedValue != '' && !isGround) ? tailNumberErrorText : ' '}</HelperText>
        </View>
        <View style={styles.contentContainer}>
          <TextInput
            id="Flight Hours"
            keyboardType={'numeric'}
            theme={{
              colors: {
                text: typeOfTrainingCompletedValue == ''  || typeOfTrainingCompletedValue == 'Ground Training' || typeOfTrainingCompletedValue == 'Flight Physical' 
                  ? colors.disabled_text : colors.inputted_text,
                accent: colors.textfield_background,
                primary: typeOfTrainingCompletedValue == ''  || typeOfTrainingCompletedValue == 'Ground Training' || typeOfTrainingCompletedValue == 'Flight Physical'
                  ? colors.disabled_text : colors.inputted_text,
                placeholder: typeOfTrainingCompletedValue == ''  || typeOfTrainingCompletedValue == 'Ground Training' || typeOfTrainingCompletedValue == 'Flight Physical' 
                  ? colors.disabled_text : colors.placeholder_text,
                disabled: typeOfTrainingCompletedValue == ''  || typeOfTrainingCompletedValue == 'Ground Training' || typeOfTrainingCompletedValue == 'Flight Physical' 
                  ? colors.disabled_text : colors.border_color,
              },
            }}
            style={[
              typeOfTrainingCompletedValue == ''  || typeOfTrainingCompletedValue == 'Ground Training' || typeOfTrainingCompletedValue == 'Flight Physical' 
                ? styles.inputSplitLocked : styles.inputSplit,
              {
                width: '95%',
                marginLeft: '4%',
                borderColor: typeOfTrainingCompletedValue == ''  || typeOfTrainingCompletedValue == 'Ground Training' || typeOfTrainingCompletedValue == 'Flight Physical'
                  ? colors.disabled_text : colors.border_color,
                borderWidth: 1,
                borderRadius: 5,
              },
            ]}
            label="Flight Hours"
            placeholder=""
            disabled={typeOfTrainingCompletedValue == ''  || typeOfTrainingCompletedValue == 'Ground Training' || typeOfTrainingCompletedValue == 'Flight Physical'}
            multiline={false}
            value={FLTHRS}
            onChangeText={onSetFLTHRS}
            onBlur={() => {
              if (!isGround && typeOfTrainingCompletedValue != '') {
                if (FLTHRS === '') {
                  setFLTHRSError(true);
                  setFLTHRSErrorText(`Enter flight hours for Period #${periodNumber}`);
                } else if (+FLTHRS < 0.1 || 9.9 < +FLTHRS) {
                  setFLTHRSError(true);
                  setFLTHRSErrorText('Flight hours must be between 0.1 and 9.9');
                } else {
                  setFLTHRSError(false);
                  setFLTHRSErrorText('');
                }
              }
            }}
            error={FLTHRSError}
          />
          <HelperText style={{ color: 'red' }}>{(FLTHRSError && !isGround) ? FLTHRSErrorText : ''}</HelperText>
        </View>
      </View>
    </View>
  );
};

export default AFTPTrainingPeriod;
