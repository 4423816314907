import React, {useEffect, useState} from 'react';
import {View, Text, TouchableOpacity} from 'react-native';

import CustomHeader from '../../components/header';
import NumberBadge from '../../components/NumberBadge';
import {useFocusEffect} from '@react-navigation/native';
import {get_recommend_files, get_review_files} from '../../api_handler/files';
import { useColorContext } from '../../components/ColorContext';

function RSTHome({navigation}) {
  
  const {colors} = useColorContext();
  const styles = ({
    container: {
      flex: 1,
      backgroundColor: colors.screen_background,
    },
    header: {
      marginTop: '0%',
      height: 110,
      backgroundColor: colors.screen_background,
    },
    pageTitle: {
      fontSize: 30,
      fontWeight: 'bold',
      marginHorizontal: 5,
      textAlign: 'Left',
      paddingTop: 25,
      color: colors.text,
    },
    contentBox: {
      backgroundColor: colors.content_box,
      height: '50vh',
      width: '100%',
      borderRadius: '20px',
      justifyContent: 'center',
      padding: 10,
      paddingLeft: 10,
      paddingRight: 10,
      marginTop: 10,
    },
    button: {
      height: 80,
      borderRadius: 10,
      padding: 10,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: colors.wob,
      margin: 8,
      marginBottom: 15,
      borderColor: colors.button_border,
      borderWidth: 1,
      shadowOffset: {width: 0, height: 2},
      shadowOpacity: 0.25,
      shadowRadius: 4,
      flexDirection: 'row',
      alignItems: 'center',
    },
    textStyle: {
      justifyContent: 'Left',
      fontSize: 15,
      fontWeight: 'bold',
      color: colors.text,
    },
    backButton: {
      height: 40,
      width: 160,
      borderRadius: 10,
      alignItems: 'center',
      justifyContent: 'center',
      borderColor: 'black',
      borderWidth: 1,
      shadowOffset: {width: 0, height: 2},
      shadowOpacity: 0.25,
      shadowRadius: 4,
      marginTop: '5%',
      backgroundColor: colors.wob,
    },
  });

  const [canReview, setCanReview] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [canRecommend, setCanRecommend] = useState(false);

  useEffect(() => {
    if (global.user_data.permissions.includes(3001)) {
      setCanRecommend(true);
    }
    if (global.user_data.permissions.includes(3002)) {
      setCanReview(true);
    }
  }, []);

  const getRecRevFiles = async () => {
    setLoaded(false);
    // only call this endpoint for users with the permission
    if (global.user_data.permissions.includes(3001)) {
      const rec = await get_recommend_files({'user_id': global.user_data.user_id,'permissions': global.user_data.permissions}, global.token);

		    const recRstFiltered = rec.body.filter(
			    (file) => file.status < 2 && file.form_type.toLowerCase() === 'rst',
		    );

      global.recRst = recRstFiltered.length;
    }

    if (global.user_data.permissions.includes(3002)) {
      const rev = await get_review_files({'role': global.user_data.role, 'user_id': global.user_data.user_id,'permissions': global.user_data.permissions}, global.token);

      const revRstFiltered = rev.body.filter(
          (file) => file.status < 4 && file.form_type.toLowerCase() === 'rst',
      );
      global.reviewRst = revRstFiltered.length;
    }

    global.reviewRecRst = global.reviewRst + global.recRst;
    setLoaded(true);
  };

  useFocusEffect(
      React.useCallback(() => {
        async function getInfo() {
          getRecRevFiles();
        }
        getInfo();
      }, []),
  );

  const createRST = () => {
    navigation.navigate('CreateNewRST');
  };

  const recommendRSTHome = () => {
    navigation.navigate('RecommendRSTHome');
  };

  const reviewRSTHome = () => {
    navigation.navigate('ReviewRSTHome');
  };

  const goBack = () => {
    navigation.navigate('Request');
  };

  if (!loaded) {
    return (
      <View style = { styles.container }>
        <CustomHeader />
        <View style = { styles.header }>
		  <Text style = {{color:  colors.text }}>Loading...</Text>
        </View>
      </View>
    );
  }

  return (
    <View style = { styles.container }>
      <CustomHeader />

      <View
        style={{
          flexDirection: 'row',
          zIndex: 5,
          width: '75%',
          alignSelf: 'center',
        }}
      >
        <View style={{alignText: 'center', flex: 3}}>
          <Text style={ styles.pageTitle}>RST (Rescheduled Training)</Text>

          <Text
            style={{
              fontSize: 18,
              marginHorizontal: 5,
              textAlign: 'Left',
              paddingTop: 25,
              color:  colors.text ,
            }}
          >
                        Create New RST, or Recommend/Review RSTs
          </Text>

          <View>
            <TouchableOpacity
              style = { styles.backButton }
              onPress= {() => goBack()}>
              <Text style={ styles.textStyle}>Back to Request</Text>

            </TouchableOpacity>
            <View style={ styles.contentBox }>
              <TouchableOpacity
                style={[ styles.button , {flex: (canRecommend && canReview) ? 1 : 0.5}]}
                onPress={() => createRST()}
              >
                <Text style={ styles.textStyle}>Submit New RST Request</Text>
              </TouchableOpacity>

              {canRecommend ? <TouchableOpacity
                style={[ styles.button , {flex: (canRecommend && canReview) ? 1 : 0.5, justifyContent: 'space-between'}]}
                onPress={() => recommendRSTHome()}
              >
                <Text style={ styles.textStyle}>Recommend RST as FLL (First Line Leader)</Text>
                {global.recRst > 0 ?
                        <View style={{flexDirection: 'flex-end'}}>
                          <NumberBadge count={global.recRst} />
                        </View> : null}
              </TouchableOpacity> : null}

              {canReview ?
                        <TouchableOpacity
                          style={[ styles.button , {flex: (canRecommend && canReview) ? 1 : 0.5, justifyContent: 'space-between'}]}
                          onPress={() => reviewRSTHome()}
                        >
                          <Text style={ styles.textStyle}>Review RST as Commander</Text>
                          {global.reviewRst > 0 ?
                        <View style={{flexDirection: 'flex-end'}}>
                          <NumberBadge count={global.reviewRst} />
                        </View> : null}
					    </TouchableOpacity> : null}
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default RSTHome;
