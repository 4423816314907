import React, { useContext, useEffect, useState } from 'react';
import { View, Text, Image } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { removeData } from '../api_handler/asyncstorage.js';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useScale } from '../components/scale';

import { AuthUserContext } from '../nav/AuthUserProvider.js';

function CustomHeader() {
  const navigation = useNavigation();
  const [currPage, setCurrPage] = useState('');
  const [profilePic, setProfilePic] = useState('');
  const [name, setName] = useState('');

  const { user, setUser } = useContext(AuthUserContext);

  useEffect(() => {
    // Redirect user to login if data is null
    // if (global.user_data === undefined) {
    //   navigation.navigate('Login');
    // }

    // Wait for profile pictures to be updated in other files
    const getData = async () => {
      const storedProfilePic = await AsyncStorage.getItem('profilePic');
      const storedName = await AsyncStorage.getItem('name');
      setProfilePic(storedProfilePic || '');
      setName(storedName || '');
    };

    getData();

    const routeName = navigation?.getState()?.routes?.[navigation?.getState()?.index]?.name || '';
    setCurrPage(routeName);
  }, [navigation]);

  const [unitName, setUnitName] = React.useState();

  const scale = useScale();

  const styles = ({
    container: {
      width: '100%',
      height: '100%',
      backgroundColor: 'white',
    },
    bigContainer: {
      height: 145,
    },
    header: {
      backgroundColor: '#0d1232',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      flex: 1,
    },
    content: {
      height: '80%',
      // backgroundColor : 'pink',
      width: '80%',
      flex: 1,
      // alignItems: 'center',
      justifyContent: 'center',
      // flexDirection: 'row'
    },
    title: {
      fontSize: scale(40),
      width: '100%',
      // marginLeft: '160%',
      color: 'white',
      fontFamily: 'Trebuchet MS',
      justifyContent: 'center',
    },
    logo: {
      width: '20%',
      height: '80%',
      resizeMode: 'contain',
      // marginLeft: 0,
      // backgroundColor: 'red',
      // position: 'absolute',
      justifyContent: 'center', // Center vertically
    },
    bar: {
      // flexDirection : 'row',
      backgroundColor: 'black',
      width: '100%',
      height: '30%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    barLinks: {
      flexDirection: 'row',
      backgroundColor: 'black',
      width: '100%',
      height: '100%',
      alignItems: 'center',
      flex: 1,
    },
    headerLink: {
      color: 'white',
      flex: 1,
      textAlign: 'center',
      fontSize: '12px',
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
    headerLinkAdmin: {
      color: 'white',
      flex: 1,
      textAlign: 'center',
      display: 'none',
    },
    home: {
      // flex : .10,
      color: 'white',
    },
    calendar: {
      // flex : .10,
      color: 'white',
    },
    review: {
      // flex : .10,
      color: 'white',
    },
    docs: {
      // flex : .10,
      color: 'white',
    },
    notis: {
      // flex : .10,
      color: 'white',
    },
    help: {
      // flex : .10,
      color: 'white',
    },
    sOut: {
      // flex : .10,
      color: 'white',
    },
    back: {
      // flex : .1,
      size: '10%',
      resizeMode: 'contain',
      backgroundColor: 'red',
      zIndex: 1,
    },
    contentBox: {
      width: '8%',
      height: '80%',
      alignSelf: 'center',
      alignItems: 'center', // Center horizontally
      justifyContent: 'center', // Center vertically
    },
    circularContainer: {
      height: '80%',
      width: '45%',
      borderRadius: 50,
      overflow: 'hidden',
      alignItems: 'center', // Center horizontally
      justifyContent: 'center', // Center vertically
    },
    User: {
      flex: 1,
      width: '100%', // Set width to 100% to fill circularContainer
      height: '100%', // Set height to 100% to fill circularContainer
    },
    welcome: {
      height: '80%',
      width: '90%',
      color: '#fecc67',
      fontSize: scale(10),
      fontFamily: 'Trebuchet MS',
      // alignItems: 'right',
    },
  });

  // User clicks log out
  const logOut = async () => {
    await removeData('user_data');
    await removeData('idToken');

    await setUser(null);

    global.token = null;
    global.user_data = null;
    global.profile_pic = null;
    global.revFiles = null;
    global.approvFiles = null;
    global.signature_uri = null;
    global.editUserID = null;
    global.reviewRec = null;
    global.reviewRecRst = null;
    global.reviewRst = null;
    global.recRst = null;

    navigation.navigate('Login');
  };

  useEffect(() => {
    setUnitName(global.user_data.unit_name);
  }, []);

  return (
    <View style={styles.bigContainer}>
      <View style={styles.container}>
        <View style={styles.header} >
          <View style={styles.content}>
            <Image style={styles.logo} source={require('../assets/US_Army_Reserve logo dark mode large final.png')} />
            {/* <Text style={styles.title}>U.S. Army Reserve</Text> */}
          </View>

          <View style={styles.contentBox}>
            <View style={styles.circularContainer}>
              <Image style={styles.User} source={{ uri: profilePic }} />
            </View>
          </View>
        </View>

        <View style={styles.bar}>
          <View style={styles.barLinks}>
            <Image style={styles.back} source={require('../assets/backbutton.png')} />

            <Text onPress={() => navigation.navigate('Home')} style={[styles.headerLink, { color: (currPage === 'Home') ? '#fecc67' : 'white' }]}>Home</Text>
            <Text onPress={() => navigation.navigate('Unit Documents')}
              style={[
                styles.headerLink,
                {
                  color: [
                    'Unit Documents',
                    'Unit POC',
                    'BA Dates',
                    'Edit BA Dates',
                    'Add BA Dates',
                  ].includes(currPage) ? '#fecc67' : 'white',
                },
              ]}
            >
              {unitName}
            </Text>
            <Text onPress={() => navigation.navigate('Users')}
              style={[
                styles.headerLink,
                {
                  color: [
                    'Users',
                    'AddUser',
                    'EditUser',
                  ].includes(currPage) ? '#fecc67' : 'white',
                },
              ]}
            >
              Users
            </Text>
            <Text onPress={() => navigation.navigate('Roles')} style={[styles.headerLink, { color: (currPage === 'Roles') ? '#fecc67' : 'white' }]}>Roles</Text>
            <Text onPress={() => navigation.navigate('Units')} style={[styles.headerLink, { color: (currPage === 'Units') ? '#fecc67' : 'white' }]}>Units</Text>
            <Text onPress={() => navigation.navigate('Logs')} style={[styles.headerLink, { color: (currPage === 'Logs') ? '#fecc67' : 'white' }]}>Logs</Text>
            {/* <Text onPress={() => navigation.navigate('Units')} style={[styles.headerLink, { color: (currPage === 'Units') ? '#fecc67' : 'white' }]}>Units</Text> */}
            <Text onPress={() => logOut()} style={styles.headerLink}>Sign Out</Text>
          </View>
        </View>
      </View>
    </View>

  );
};

export default CustomHeader;
