/* eslint-disable react/prop-types */
/* eslint-disable linebreak-style */
/* eslint-disable max-len */
/* eslint-disable require-jsdoc */
import React, { useState, useEffect, useRef } from "react";
import {
    View,
    Text,
    StyleSheet,
    FlatList,
    Pressable,
    TextInput,
    TouchableOpacity,
    TouchableWithoutFeedback,
} from "react-native";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CancelIcon from "@mui/icons-material/Cancel";
import { useColorContext } from "./ColorContext";
import {
    get_all_users_in_single_unit,
    get_reviewers_by_permission,
    get_all_unit_names,
} from "../api_handler/units";
import { backdropClasses } from "@mui/material";
import { useSnackbar } from "../components/SnackbarContext";

function CustomAutocomplete({
    placeholderText,
    permission,
    selectedOption,
    setSelectedOption,
    error,
    setError,
    reviewerIds,
    validatorIds,
    manualOption,
    setManualOption,
    setValidatorSig,
    setShowValidatorSig,
    setReviewerOption,
    isValidator,
    resetSearchQuery,
    from,
    zIndex,
}) {
    const { showSnackbar } = useSnackbar();
    const [chevron, setChevron] = useState(false);
    const [filterChevron, setFilterChevron] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [optionalFilterData, setOptionalFilterData] = useState([]);
    const [filterDropdownOpen, setFilterDropdownOpen] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState({
        name: "Your Unit",
        list: "",
    });
    const [typing, setTyping] = useState(false);
    const [hoveredItem, setHoveredItem] = useState(null);

    const [chevronSize, setChevronSize] = useState(18);
    const [cancelSize, setCancelSize] = useState(18);

    // Approver Group States
    const [allUnits, setAllUnits] = useState([]);
    const [allReviewers, setAllReviewers] = useState([]);
    const [numOptions, setNumOptions] = useState();

    const [userUnit, setUserUnit] = useState({});
    const [unitReviewers, setUnitReviewers] = useState([]);
    const [battalionReviewers, setBattalionReviewers] = useState([]);
    const [hqReviewers, setHqReviewers] = useState([]);
    const [armyReviewers, setArmyReviewers] = useState([]);
    const [isHQ, setIsHQ] = useState(false);
    const [savedSearchQuery, setSavedSearchQuery] = useState("");

    const [processedInitialData, setProcessedInitialData] = useState(false);
    const [processedManualOption, setProcessedManualOption] = useState(false);
    const [group, setGroup] = useState(isValidator ? 'Validators' : 'Approvers');
    const [disabled, setDisabled] = useState(false);

    const { colors } = useColorContext();


    useEffect(() => {
        if(permission === 41){
            console.log("-------- START IMPORTANT STATES : use for debuggging --------")
            console.log(permission !== 41 ? "Approver" : "Validator")
            console.log("manualOption: ", manualOption)
            console.log('optionfilterData: ', optionalFilterData);
            console.log('selectedFilter: ', selectedFilter)
            console.log('selectedOption: ', selectedOption)
            console.log('validatorIds: ', validatorIds)
            console.log('searchQuery: ', searchQuery)
            console.log("-------- END IMPORTANT STATES -------------------------------")
        }
    }, [validatorIds])

    // Dropdown scrollerbar functionality
    const wrapperRef = useRef(null);
    useEffect(() => {
        function handleClickOutside(event) {
            if (
                wrapperRef.current &&
                !wrapperRef.current.contains(event.target)
            ) {
                setShowSuggestions(false);
                setChevron(false);
                setTyping(false);
                if (selectedOption.length > 0 && selectedOption.name != 0) {
                    if (savedSearchQuery && (searchQuery == '' || selectedOption.length > 1)) setSearchQuery(savedSearchQuery);
                    else setSearchQuery(selectedOption[0].name);
                }
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [selectedOption]);

    // Grabs all possible reviewers and grabs all units
    useEffect(() => {
        processFormInformation();
    }, [permission]);

    const checkStateVariables = () => {
        if(numOptions == 3){
            return unitReviewers.length !== 0 
            && hqReviewers.length !== 0 && allReviewers.length !== 0
        } else {
            return unitReviewers.length !== 0 && battalionReviewers.length !== 0
            && hqReviewers.length !== 0 && allReviewers.length !== 0
        }
    }
    
    useEffect(() => {
        if (from === 'New1380') {
            if (checkStateVariables() && processedInitialData && reviewerIds.length > 0) {
                const { reviewerMatch, index } = processReviewersIds(reviewerIds, false);
                if (reviewerMatch) {
                    if (typeof reviewerMatch?.user_id !== 'string') {
                        setReviewerOption(reviewerMatch);
                    }
                    setSelectedFilter(optionalFilterData[index]);
                    setFilterData(optionalFilterData[index], reviewerMatch);
                }
            }
        }
    // }, [reviewerIds, allUnits, allReviewers, unitReviewers, battalionReviewers, hqReviewers]);
    }, [reviewerIds, processedInitialData]);

    useEffect(() => {
        if (from === 'New1380') {
            if (checkStateVariables() && processedInitialData && validatorIds?.length > 0) {
                const { reviewerMatch, index } = processReviewersIds(validatorIds, false);
                if (reviewerMatch) {
                    if (typeof reviewerMatch?.user_id !== 'string') {
                        setReviewerOption(reviewerMatch);
                    }
                    setSelectedFilter(optionalFilterData[index]);
                    setFilterData(optionalFilterData[index], reviewerMatch);
                }
            }
        }
    }, [validatorIds, processedInitialData]);

    const handleDisable = (data) => {
        const firstUserId = data[0].user_id;
        return data.every(option => option.user_id === firstUserId);
    };
    
    const handleDraftedValidators = (approvedReviewer) => {
        const manualOptLength = [...new Set(manualOption.map(user => user.user_id))].length
        // no drafted validators
        const noPulledValidator = validatorIds.length === 0 && 
            (selectedOption === null || selectedOption === '' || selectedOption.name === '' || selectedOption[0] === '');
        // Validator group Drafted, from pulled forms
        const pulledValidatorGroupSelected = manualOptLength > 1 && 
            validatorIds.every(id => manualOption.some(option => option.user_id === id)) && 
            manualOption.every(option => validatorIds.some(id => id === option.user_id)); 
        // Validator group Drafted, not from pulled forms
        const validatorGroupSelected = validatorIds.length > 1 && !pulledValidatorGroupSelected && !noPulledValidator;
        // Single Drafted Validator from pulled forms
        const singlePulledValidatorSelected = validatorIds === 1 && manualOption.some(option => option.user_id === validatorIds[0]);
        const singleValidatorSelected = validatorIds === 1 && !manualOption.some(option => option.user_id === validatorIds[0]);

        let list = manualOption?.length > 1 ? groupValidators(manualOption) : [manualOption];
        optionalFilterData.unshift({name: "Pulled Validator", list: [...new Set(list)] });

        if(pulledValidatorGroupSelected || validatorGroupSelected || singleValidatorSelected){
            const { reviewerMatch, index } = processReviewersIds(validatorIds, pulledValidatorGroupSelected ? true : false);
            if (reviewerMatch) {
                if (typeof reviewerMatch?.user_id !== 'string') {
                    setReviewerOption(reviewerMatch);
                }
                setSelectedFilter(optionalFilterData[index]);
                setFilterData(optionalFilterData[index], reviewerMatch);
            }
            if(pulledValidatorGroupSelected)
                processSelectedOption(list[0])
        }
        else if(noPulledValidator){
            setSelectedFilter({ name: "Pulled Validator", list: list });
            setFilterData(optionalFilterData[0], "");
            setSelectedOption("")
            setReviewerOption("")
            setSearchQuery("");
            setSavedSearchQuery('');
        }
        else {
            const pulledValidator = manualOption.findIndex(option => option.user_id === validatorIds[0])
            setSelectedFilter({ name: "Pulled Validator", list: list });
            setFilterData(optionalFilterData[0], manualOption[pulledValidator]);
            setDisabled(approvedReviewer !== -1 && handleDisable(manualOption));
            setValidatorSig(manualOption[approvedReviewer === -1 ? 0 : manualOption?.length - 1].sig_id);
        }
    };

    useEffect(() => {
        if(manualOption?.length > 0){
            const approvedReviewer = manualOption.findIndex(item => item.status === 4);
            const signValidator = manualOption.every(option => option.status === 4) && handleDisable(manualOption);
            setShowValidatorSig(signValidator ? true : false);

            if (optionalFilterData[0]?.name === "Pulled Validator"  && manualOption?.length > 0) {
                let updatedList = manualOption?.length > 1 ? groupValidators(manualOption) : [manualOption];
                optionalFilterData[0].list = updatedList;
                    setOptionalFilterData([...optionalFilterData]);
                    setSelectedFilter({ name: "Pulled Validator", list: updatedList });
                    setFilterData(optionalFilterData[0], manualOption[approvedReviewer === -1 ? 0 : manualOption?.length - 1]);
                    setValidatorSig(manualOption[approvedReviewer === -1 ? 0 : manualOption?.length - 1].sig_id);
                    setDisabled(approvedReviewer !== -1 && handleDisable(manualOption));
            } 
            
            else if(manualOption.some(option => option.selected !== undefined) && processedInitialData) {
                handleDraftedValidators(approvedReviewer);
            } 
            
            else if (processedInitialData && optionalFilterData?.length !== 0) {
                let list = manualOption?.length > 1 ? groupValidators(manualOption) : [manualOption];
                optionalFilterData.unshift({name: "Pulled Validator", list: list });
                setSelectedFilter({ name: "Pulled Validator", list: list });
                setFilterData(optionalFilterData[0], manualOption[approvedReviewer === -1 ? 0 : manualOption?.length - 1]);
                setValidatorSig(manualOption[approvedReviewer === -1 ? 0 : manualOption?.length - 1].sig_id);
                setDisabled(approvedReviewer !== -1 && handleDisable(manualOption));
            }
        }
    }, [manualOption, processedInitialData]);

    useEffect(() => {
        if (resetSearchQuery.reset === true) {
            setSearchQuery("");
            setSavedSearchQuery('');
            setSelectedOption("");
            setDisabled(false);

            if(manualOption?.length >= 1){
                const formIdsSet = new Set(resetSearchQuery.form_ids);
                const updatedOptions = manualOption.filter(option => formIdsSet.has(option.form_id));
                setManualOption(updatedOptions);
            } else {
                let data = optionalFilterData.filter(item => item.name !== 'Pulled Validator');
                setOptionalFilterData(data);
                setSelectedFilter({ name: "Your Unit", list: data[0] });
                setFilterData(data[0], "");
                setSelectedOption("")
                setReviewerOption("")
                setSearchQuery("");
                setSavedSearchQuery('');
            }
        }
    }, [resetSearchQuery]);

    const groupValidators = (reviewers) => {
        const extractFullUnitName = (name) => {
            const match = name.match(/- (.+)/);
            return match ? match[1].trim() : "";
        };
    
        const sortedReviewers = [...reviewers].sort((a, b) => {
            const unitA = extractFullUnitName(a.name);
            const unitB = extractFullUnitName(b.name);
            return unitA.localeCompare(unitB);
        });
    
        const unitName = 'PULLED VALIDATOR';
    
        let updatedReviewers = [];
    
        updatedReviewers.push({
            unit_id: 0,
            user_id: `${unitName}`,
            name: `All Approvers in Pulled Validators`,
        });
    
        const addedReviewerIds = new Set();
    
        sortedReviewers.forEach((reviewer) => {
            if (!addedReviewerIds.has(reviewer.user_id)) {
                updatedReviewers.push({
                    unit_id: 0,
                    user_id: reviewer.user_id,
                    name: reviewer.name,
                });
                addedReviewerIds.add(reviewer.user_id);
            }
        });
    
        return updatedReviewers;
    }
    
    let splitUnitNames = [];
    let unitOrder = [];

    const processFormInformation = async () => {
        await fetchAllUnits();
        await getReviewers(global.user_data.unit_id);
        setProcessedInitialData(true);
    };

    // Grabs All Units Names in hierarchical order
    const fetchAllUnits = async () => {
        try {
            const unit_names_JSON = {
                unit_id: 0,
                get_top_unit: true,
                get_path: true,
            };

            // Retrieve all units and eliminate the ones the user does not have access to
            const unit_names_res = await get_all_unit_names(
                unit_names_JSON,
                global.token
            );
            if (unit_names_res.status !== 200) {
                showSnackbar(
                    "error",
                    "Application Error: Failed to retrieve all units"
                );
                return "";
            } else {
                splitUnitNames = unit_names_res.data.body.map((unit) => {
                    unitOrder.push(unit.unit_id);

                    return {
                        unit_id: unit.unit_id,
                        unit_name: unit.unit_name.trim().split(" -> "),
                    };
                });

                setAllUnits(splitUnitNames);
            }
        } catch (error) {
            console.error("Error: ", error);
        }
    };

    async function getReviewers(id) {
        const reviewersList = await get_reviewers_by_permission(
            JSON.stringify({ permission: permission }),
            global.token
        );

        let allReviewers = [];
        if (reviewersList.status === 200) {
            allReviewers = reviewersList.data.body.filter(
                (user) => user.user_id != global.user_data.user_id
            );
        } else {
            showSnackbar(
                "error",
                "Application Error: Failed to retrieve all Reviewers"
            );
            setFailedToRetrieve(true);
        }

        allReviewers = allReviewers.sort((a, b) => {
            const unitA = extractUnitNumber(a.name);
            const unitB = extractUnitNumber(b.name);
            return unitA - unitB;
        });

        setAllReviewers(allReviewers);
        filterUnits(splitUnitNames, allReviewers);
    }

    function groupReviewersByUnit(allReviewers) {
        // Function to extract the full unit name from a reviewer's name
        const extractFullUnitName = (name) => {
            const match = name.match(/- (.+)/);
            return match ? match[1].trim() : "";
        };

        // Get all unique unit names
        const unitNames = [
            ...new Set(
                allReviewers.map((reviewer) =>
                    extractFullUnitName(reviewer.name)
                )
            ),
        ];

        // Create a map to keep track of whether a unit has been inserted
        const unitInsertedMap = unitNames.reduce((map, unitName) => {
            map[unitName] = false;
            return map;
        }, {});

        // Sort reviewers by unit names to ensure correct order
        const sortedReviewers = [...allReviewers].sort((a, b) => {
            const unitA = extractFullUnitName(a.name);
            const unitB = extractFullUnitName(b.name);
            return unitA.localeCompare(unitB);
        });

        // Create an array to store the new list
        let updatedReviewers = [];

        // Loop through sortedReviewers and insert the new entries above each unique unit name
        sortedReviewers.forEach((reviewer) => {
            const unitName = extractFullUnitName(reviewer.name);
            if (unitName && !unitInsertedMap[unitName]) {
                updatedReviewers.push({
                    unit_id: reviewer.unit_id,
                    user_id: `${unitName}`,
                    name: `All Reviewers in ${unitName}`,
                });
                unitInsertedMap[unitName] = true;
            }
            updatedReviewers.push(reviewer);
        });

        // Sort the updatedReviewers based on unitIdOrder
        updatedReviewers.sort((a, b) => {
            const indexA = unitOrder.indexOf(a.unit_id);
            const indexB = unitOrder.indexOf(b.unit_id);

            // If both unit_ids are in the unitIdOrder, sort based on their position
            if (indexA !== -1 && indexB !== -1) {
                return indexA - indexB;
            }

            // If only one unit_id is in the unitIdOrder, prioritize it
            if (indexA !== -1) return -1;
            if (indexB !== -1) return 1;

            // If neither unit_id is in the unitIdOrder, maintain their current order
            return 0;
        });

        return updatedReviewers;
    }

    function filterUnits(allUnits, allReviewers) {
        const unit = allUnits.find(unit => unit.unit_id === global.user_data.unit_id);

        const unitLength = unit.unit_name.length;
        const isHQ = unitLength === 1;
        const userHQName = unit.unit_name[0];
        const userBattalionName = unitLength > 1 ? unit.unit_name[1] : null;

        const battlionUnits = isHQ ? [] : allUnits.filter(unit => unit.unit_name.some(name => name.includes(userBattalionName)));
        const hqUnits = allUnits.filter(unit => unit.unit_name.some(name => name.includes(userHQName)));

        const filterReviewers = (units) => {
            const unitIds = units.map(unit => unit.unit_id);
            const filteredReviewers = allReviewers.filter(reviewer => unitIds.includes(reviewer.unit_id));
            return filteredReviewers;
        };

        const unitRev = filterReviewers([unit])
        const battalionRev = isHQ ? [] : filterReviewers(battlionUnits);
        const hqRev = filterReviewers(hqUnits)

        const unitOptions = [
            unitRev?.length > 0 ? { user_id: "ALL UNIT", name: `All ${group} Within Your Unit` } : { user_id: "ALL UNIT", name: `No ${group} Within Your Unit` },
            ...unitRev
        ];

        setNumOptions(isHQ ? 3 : 4);

        const groupedBattalion = groupReviewersByUnit(battalionRev)
        const groupedHq = groupReviewersByUnit(hqRev, allReviewers)
        const groupedArmy = groupReviewersByUnit(allReviewers, allReviewers)

        const commonFilterData = [
            { name: "Your Unit", list: unitOptions },
            { name: "Your Brigade", list: groupedHq },
            { name: "Whole Army", list: groupedArmy }
        ];

        if (isHQ) {
            setSelectedFilter({ name: "Your Unit", list: unitOptions });
            setOptionalFilterData(commonFilterData);
            setSearchResults(unitOptions);
        } else {
            setSelectedFilter({ name: "Your Unit", list: unitOptions });
            setOptionalFilterData([
                ...commonFilterData.slice(0, 1),
                { name: "Your Battalion", list: groupedBattalion },
                ...commonFilterData.slice(1)
            ]);
            setSearchResults(unitOptions);
        }

        setIsHQ(isHQ)
        setUserUnit(unit);
        setUnitReviewers(unitOptions)
        setBattalionReviewers(groupedBattalion)
        setHqReviewers(groupedHq)
        setArmyReviewers(groupedArmy);
        setIdsForUnits(allUnits, allReviewers);
    }

    const setIdsForUnits = (allUnits, allReviewers) => {
        const units = allUnits.map((unit) => {
            const userIds = allReviewers
                .filter((reviewer) => reviewer.unit_id === unit.unit_id)
                .map((reviewer) => reviewer.user_id);

            return {
                ...unit,
                user_ids: userIds,
            };
        });

        setUnitStructure(units);
    };

    const setUnitStructure = (allUnits) => {
        const unitIdMap = {};

        allUnits.forEach((unit) => {
            unitIdMap[unit.unit_name[unit.unit_name.length - 1]] = unit.unit_id;
        });

        let units = allUnits.map((unit) => {
            const structure = [];
            for (let i = 0; i < unit.unit_name.length; i++) {
                const unitName = unit.unit_name[i];
                if (unitIdMap[unitName]) {
                    structure.push(unitIdMap[unitName]);
                }
            }
            return {
                ...unit,
                unit_structure: structure,
            };
        });

        setAllUnits(units);
    };

    const processReviewersIds = (reviewerIds, pulledValidators) => {
        const pulledValidatorList = manualOption?.length > 0;
        if (reviewerIds.length > 1 && !pulledValidators) {
            const unitMatch = findGroupMatch(reviewerIds, unitReviewers);
            if (unitMatch) {
                return { reviewerMatch: unitMatch, index: pulledValidatorList ? 1 : 0 };
            }

            if (!isHQ) {
                const battalionMatch = findGroupMatch(reviewerIds, battalionReviewers);
                if (battalionMatch) {
                    return { reviewerMatch: battalionMatch, index: pulledValidatorList ? 2 : 1 };
                }
            }
            
            const hqMatch = findGroupMatch(reviewerIds, hqReviewers);
            if (hqMatch) {
                return { reviewerMatch: hqMatch, index: isHQ ? (pulledValidatorList ? 2 : 1) : (pulledValidatorList ? 3 : 2) };
            };

            const allMatch = findGroupMatch(reviewerIds, allReviewers);
            if (allMatch) {
                return { reviewerMatch: allMatch, index: isHQ ? (pulledValidatorList ? 3 : 2) : (pulledValidatorList ? 4 : 3) };
            };

            return { reviewerMatch: null, index: 0 };
        } else if(pulledValidators){
            const individualId = reviewerIds[0];
            const allPulledValidators = manualOption.every(option => option?.selected === true)
            if(allPulledValidators){
                return { 
                    reviewerMatch: { unit_id: 0, user_id: 0, name: `All Approvers in Pulled Validators`, }, 
                    index: 0
                }
            }

            const singlePulledValidator = manualOption.findIndex(option => option.user_id === individualId)
            if(singlePulledValidator !== -1){
                return { reviewerMatch: manualOption[singlePulledValidator], index: 0 };
            }

            return { reviewerMatch: null, index: 0 };
        } else {
            const individualId = reviewerIds[0];
            const unitReviewer = unitReviewers.find(reviewer => reviewer.user_id === individualId);
            
            if (unitReviewer) {
                return { reviewerMatch: unitReviewer, index: pulledValidatorList ? 1 : 0 };
            }


            if (!isHQ) {
                const battalionReviewer = battalionReviewers.find(reviewer => reviewer.user_id === individualId);
                if (battalionReviewer) {
                    return { reviewerMatch: battalionReviewer, index: pulledValidatorList ? 2 : 1 };
                }
            }

            const hqReviewer = hqReviewers.find(reviewer => reviewer.user_id === individualId);
            if (hqReviewer) {
                return { reviewerMatch: hqReviewer, index: isHQ ? (pulledValidatorList ? 2 : 1) : (pulledValidatorList ? 3 : 2) };
            }

            const armyReviewer = allReviewers.find(reviewer => reviewer.user_id === individualId);
            if (armyReviewer) {
                return { reviewerMatch: armyReviewer, index: isHQ ? (pulledValidatorList ? 3 : 2) : (pulledValidatorList ? 4 : 3) };
            }

            return { reviewerMatch: null, index: 0 };
        }
    };

    const findGroupMatch = (reviewerIds, groupReviewers) => {
        const reviewerIdStrings = reviewerIds.map(id => id.toString());
        const matchingGroups = groupReviewers.filter(group => {
            if (group.name.startsWith(`All ${group} in`)) {
                const unitName = group.name.split(`All ${group} in `)[1];
                return reviewerIdStrings.every(id =>
                    groupReviewers.some(reviewer =>
                        reviewer.user_id.toString() === id &&
                        reviewer.name.includes(unitName)
                    )
                );
            }

            return reviewerIdStrings.includes(group.user_id.toString());
        });

        return matchingGroups.length > 0 ? matchingGroups : null;
    };

    const findUsersInOptionalFilterData = (userIds) => {
        const ids = Array.isArray(userIds) ? userIds : [userIds];
        const filterOption = ids[0] === "PULLED VALIDATOR" 
            ? optionalFilterData[0]
            : optionalFilterData.find(option => option.name === selectedFilter.name);

        if (filterOption) {
            if (ids[0] === 'ALL UNIT' && selectedFilter.name === 'Your Unit') {
                const integerUserIds = filterOption.list
                    .slice(1)
                    .filter(user => Number.isInteger(Number(user.user_id)));

                return {
                    filterIndex: optionalFilterData.indexOf(filterOption),
                    user_ids: integerUserIds
                };
            }

            let foundUsers;
            if(ids[0] === 'PULLED VALIDATOR'){
                return {
                    filterIndex: optionalFilterData.indexOf(filterOption),
                    user_ids: filterOption.list.filter(item => item.user_id !== "PULLED VALIDATOR")
                };
            }
            else if(filterOption.name === "Pulled Validator") { 
                if(filterOption.list.length > 1)
                    foundUsers = filterOption.list.filter(item => item.user_id === ids[0]);
                else 
                    foundUsers = filterOption.list[0][0];
            } else {
                foundUsers = filterOption.list.filter(item => {
                    if (typeof ids[0] === 'string' && isNaN(ids[0])) {
                        return item.name.includes(ids[0]);
                    } else {
                        return ids.includes(item.user_id);
                    }
                });
            }

            if (foundUsers.length > 0 || foundUsers) {
                if(!Array.isArray(foundUsers)) foundUsers = [foundUsers];

                const integerUserIds = foundUsers
                    .filter(user => Number.isInteger(Number(user.user_id)));
                return {
                    filterIndex: optionalFilterData.indexOf(filterOption),
                    user_ids: integerUserIds
                };
            }
        }

        return null;
    };

    // Sets selected reviewer and passes it to the 1380 page
    function processSelectedOption(item) {
        if (typeof item?.user_id !== 'string') {
            setSavedSearchQuery(item.name);
            setReviewerOption(item);
        }

        const foundUserData = findUsersInOptionalFilterData(item.user_id);
        if (foundUserData) {
            const { filterIndex, user_ids } = foundUserData;
            setFilterData(optionalFilterData[filterIndex], user_ids);
        }
    }

    function extractUnitNumber(str) {
        const match = str.match(/(\b\w+\b) (\d+)(?:th|st|nd|rd)/);
        return match ? parseInt(match[2], 10) : null;
    }

    const onChangeText = (text) => {
        setSearchQuery(text);
        setShowSuggestions(true);
    };

    const handleFocus = () => {
        setChevron(true);
        setShowSuggestions(true);
        setTyping(true);
    };

    const handleFilterFocus = () => {
        if (showSuggestions) setShowSuggestions(false);
        setFilterChevron(true);
        setFilterDropdownOpen(true);
    };

    const handleFilterBlur = (e) => {
        // Only close if clicking outside the dropdown
        if (!e.currentTarget.contains(e.relatedTarget)) {
            setFilterChevron(false);
            setFilterDropdownOpen(false);
        }
    };

    const handleSearchBlur = (event) => {
        setChevron(false);
        setShowSuggestions(false);
        setTyping(false);
        if (selectedOption.length > 0 && selectedOption.name != 0) {
            if (savedSearchQuery && (searchQuery == '' || selectedOption.length > 1)) setSearchQuery(savedSearchQuery);
            else setSearchQuery(selectedOption[0].name);
        }
    };

    const handleChevronPress = () => {
        if(disabled) {
            return;
        }

        if (filterChevron) {
            setFilterChevron(false);
            setFilterDropdownOpen(false);
        }
        setShowSuggestions(!chevron);
        setChevron(!chevron);
    };

    const handleFilterChevronPress = () => {
        if(disabled) {
            return;
        }

        if (chevron) {
            setChevron(false);
            setShowSuggestions(false);
        }
        setFilterDropdownOpen(!filterChevron);
        setFilterChevron(!filterChevron);
    };

    const handleCancelPress = () => {
        if(disabled) {
            return;
        }

        setSearchQuery("");
        setSavedSearchQuery('');
        setSelectedOption("");
        setShowSuggestions(false);
        setTyping(false);
        setReviewerOption("")
    };

    const getSearchQuery = (selectedOption) => {
        let query = '';
        if (Array.isArray(selectedOption) && selectedOption.length > 1) {
            if (selectedOption.every(selected => manualOption?.some(option => option.user_id === selected.user_id))) {
                query = 'All Approvers in Pulled Validators'
            } else if(selectedOption.every(option => option.unit_id == global.user_data.unit_id) && selectedFilter.name == "Your Unit" ){
                query = `All ${group} Within Your Unit`;
            } else {
                let intIndex = isNaN(selectedOption[0].user_id) ? 1 : 0;
                let unitName = selectedOption[intIndex].name.split(' - ')[1];

                query = `All ${group} in ${unitName}`; 
            }
            setSavedSearchQuery(query);  
        }
        else {
            const individual = Array.isArray(selectedOption) ? selectedOption[0] : selectedOption;
            if(selectedOption && selectedOption.length > 0){
                if(numOccurrences(selectedFilter.list, selectedOption[0].unit_id, selectedOption[0].user_id )){
                    let unitName = selectedOption[0].name.split(' - ')[1];
                    setSavedSearchQuery(`All ${group} in ${unitName}`)
                } else {
                    setSavedSearchQuery(query)
                }
            } 
            query = individual.name;
        }
        return query;
    }

    const setFilterData = (item, selectedOption) => {
        if (optionalFilterData && item) {
            for (let i = 0; i < optionalFilterData.length; i++) {
                if (optionalFilterData[i].name === item.name) {
                    setSearchResults(item.list);
                    setSelectedOption(selectedOption);
                    setSearchQuery(getSearchQuery(selectedOption));
                    setReviewerOption(Array.isArray(selectedOption) && selectedOption.length === 1 
                        ? selectedOption[0]
                        : selectedOption
                    );
                    setError(false);
                    break;
                }
            }
        }
    }

    const numOccurrences = (list, users_unit_id, user_id) => {
        let temp = 0;
        let flag = false;
        for(let i = 0; i < list.length; i++){
            if(list[i].user_id != user_id){
                if(list[i].unit_id == users_unit_id)
                    temp = temp + 1;
                if(temp == 1)
                    flag = true;
                else
                    flag = false;
            } 
        }
        return flag;
    };

    const handleSelectOption = (item, event) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        if (item.name.includes(`No ${group}`)) {
            return;
        }

        if(typeof item.user_id == 'string') 
            setSavedSearchQuery(item.name);

        processSelectedOption(item);
        setChevron(false);
        setShowSuggestions(false);
        setTyping(false);
    };

    const handleSelectFilter = (item) => {
        setSelectedFilter(item);
        setSavedSearchQuery("");
        setFilterDropdownOpen(false);
        setFilterData(item, { user_id: 0, unit_id: 0, name: '' });
        setHoveredItem(null);
        setTimeout(() => {
            // Prevent immediate closing of the dropdown
            setFilterChevron(false);
        }, 0);
    };

    const OptionalFilterDropdown = () => {
        return (
            <View
                style={[styles.unitFilterDropdownContainer, { width: "100%" }]}
                onMouseDown={(e) => e.preventDefault()}
            >
                <FlatList
                    data={optionalFilterData}
                    scrollEnabled={true}
                    renderItem={({ item, index }) => (
                        <View
                            style={[
                                index === optionalFilterData.length - 1 && {
                                    borderBottomLeftRadius: 5,
                                    borderBottomRightRadius: 5,
                                },
                                { overflow: "hidden" },
                            ]}
                        >
                            <TouchableOpacity
                                style={[
                                    {
                                        backgroundColor:
                                            item.name === selectedFilter.name
                                                ? colors.button_selected
                                                : "",
                                    },
                                    { padding: 10 },
                                    item.name === hoveredItem
                                        ? {
                                            backgroundColor:
                                                colors.button_hover,
                                        }
                                        : {},
                                ]}
                                // onMouseEnter={() => setHoveredItem(item.name)}
                                // onMouseLeave={() => setHoveredItem(null)}
                                onPress={(e) => {
                                    e.stopPropagation(); // Prevent event from bubbling up
                                    handleSelectFilter(item);
                                }}
                            >
                                <Text style={styles.unitFilterDropdownText}>
                                    {item.name}
                                </Text>
                            </TouchableOpacity>
                        </View>
                    )}
                    keyExtractor={(index) => index.toString()}
                />
            </View>
        );
    };

    const SuggestionBox = () => {
        const filteredResults = searchQuery
            ? searchResults.filter(
                (result) =>
                    result.name &&
                    result.name
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase())
            )
            : searchResults; // If we aren't searching by anything then show everyone
        return (
            <View
                style={styles.searchSuggestionBox}
                onMouseDown={(e) => e.preventDefault()} // Prevent blur when clicking inside
            >

                {filteredResults.length < 1 && (selectedOption == '' || selectedOption.user_id == 0) ? (
                    <Text
                        style={[
                            styles.suggestionBoxOptionText,
                            { padding: 10 },
                        ]}
                    >
                        No {group} within {selectedFilter.name}
                    </Text>
                ) : (
                    <FlatList
                        data={filteredResults}
                        renderItem={({ item, index }) => {
                            if(Array.isArray(item))
                                item = item[index];
                            

                            let isSelected = false;
                            if (selectedOption.length > 0 && selectedOption.user_id != 0 && selectedOption[0] != '') {
                                isSelected = selectedOption ? selectedOption?.some(option => option?.name === item?.name) : false;
                                if (!isSelected) isSelected = savedSearchQuery === item.name ? true : false;
                            }
                            return (
                                <View
                                    style={[
                                        index === filteredResults.length - 1 && {
                                            borderBottomLeftRadius: 5,
                                            borderBottomRightRadius: 5,
                                        },
                                        { overflow: "hidden" },
                                    ]}
                                >
                                    <TouchableOpacity
                                        style={[
                                            { backgroundColor: isSelected ? colors.button_selected : "" },
                                            { padding: 10 },
                                        ]}
                                        onPress={(event) =>handleSelectOption(item, event)}
                                    >
                                        <Text
                                            style={[
                                                styles.suggestionBoxOptionText,
                                                typeof item.user_id === "string"
                                                    ? null
                                                    : { marginLeft: 20 },
                                            ]}
                                        >
                                            {item.name}
                                        </Text>
                                    </TouchableOpacity>
                                </View>
                            );
                        }}
                        keyExtractor={(index) => index.toString()}
                    />
                )}
            </View>
        );
    };

    const styles = StyleSheet.create({
        container: {
            flex: 1,
            flexDirection: "row",
            alignSelf: "center",
            width: "95%",
            borderColor: error ? "red" : colors.border_color,
            borderWidth: showSuggestions ? 2.5 : 1,
            borderRadius: 5,
        },
        unitFilterContainer: {
            flexDirection: "column",
            width: "25%",
            alignItems: "center",
            justifyContent: "center",
        },
        unitFilterButtonContainer: {
            flexDirection: "row",
            backgroundColor: colors.filter_dropdown,
            height: "100%",
            width: "100%",
            alignItems: "center",
            borderRadius: 5,
            position: "relative",
            boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
        },
        unitFilterButtonView: {
            width: "80%",
        },
        unitFilterButton: {
            flex: 1,
            color: colors.inputted_text,
        },
        unitFilterButtonText: {
            color: colors.inputted_text,
            textAlign: "center",
            fontWeight: "bold",
        },
        unitFilterChevronIcon: {
            alignItems: "flex-start",
            width: "20%",
        },
        unitFilterDropdownContainer: {
            width: "100%",
            backgroundColor: colors.textfield_background,
            borderRadius: 5,
            marginTop: 5,
            maxHeight: 200,
            position: "absolute",
            zIndex: zIndex,
            boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.75)",
        },
        unitFilterDropdownText: {
            color: colors.inputted_text,
            fontSize: 16,
        },
        searchBarContainer: {
            flexDirection: "column",
            width: "75%",
            justifyContent: "center",
        },
        searchBar: {
            paddingLeft: "2%",
            width: "80%",
        },
        cancelIcon: {
            alignItems: "center",
            width: "10%",
        },
        searchChevronIcon: {
            alignItems: "center",
            width: "10%",
        },
        searchSuggestionBox: {
            width: "100%",
            maxHeight: 350,
            position: "absolute",
            backgroundColor: colors.textfield_background,
            borderRadius: 5,
            marginTop: 17,
            boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.75)",
        },
        suggestionBoxOptionText: {
            color: colors.inputted_text,
            fontSize: 16,
        },
    });

    return (
        <TouchableWithoutFeedback>
            <View style={styles.container} ref={wrapperRef}>
                <View style={styles.unitFilterContainer}>
                    <View
                        style={styles.unitFilterButtonContainer}
                        onBlur={handleFilterBlur}
                        tabIndex={0}
                    >
                        <View style={styles.unitFilterButtonView}>
                            {!disabled
                                ?
                                <TouchableOpacity
                                    onPress={!disabled ? handleFilterFocus : null}
                                    onBlur={handleFilterBlur}
                                    style={styles.unitFilterButton}
                                >
                                    <Text style={styles.unitFilterButtonText}>
                                        {selectedFilter.name}
                                    </Text>
                                </TouchableOpacity>
                                :
                                <Text style={{            
                                    color: colors.inputted_text_disabled,
                                    textAlign: "center",
                                    fontWeight: "bold",
                                }}>
                                    {selectedFilter.name}
                                </Text>
                            }
                        </View>
                        <View style={styles.unitFilterChevronIcon}>
                            <Pressable
                                style={{ color: disabled ? colors.inputted_text_disabled : colors.inputted_text }}
                                onPress={!disabled ? handleFilterChevronPress : null}
                                onBlur={handleFilterBlur}
                            >
                                {filterChevron ? (
                                    <KeyboardArrowDownIcon
                                        style={{ color: disabled ? colors.inputted_text_disabled : colors.inputted_text, fontSize: chevronSize }}
                                        fontSize={"inherit"}
                                    />
                                ) : (
                                    <KeyboardArrowUpIcon
                                        style={{ color: disabled ? colors.inputted_text_disabled : colors.inputted_text, fontSize: chevronSize }}
                                        fontSize={"inherit"}
                                    />
                                )}
                            </Pressable>
                        </View>
                    </View>
                    {filterDropdownOpen && (
                        <View
                            style={{ alignItems: "flex-start", width: "100%" }}
                        >
                            <OptionalFilterDropdown />
                        </View>
                    )}
                </View>
                <View style={styles.searchBarContainer}>
                    <View style={{ flexDirection: "row" }}>
                        <View style={styles.searchBar}>
                            <TextInput
                                placeholder={typing ? "" : placeholderText}
                                placeholderTextColor={colors.inputted_text}
                                value={searchQuery}
                                style={{
                                    outline: "none",
                                    fontSize: 16,
                                    color: colors.inputted_text,
                                    opacity: !disabled ? 1 : 0.5
                                }}
                                onFocus={!disabled ? handleFocus : null}
                                onBlur={handleSearchBlur}
                                onChangeText={onChangeText}
                                editable={disabled ? false : true}
                            />
                        </View>
                        <View style={styles.cancelIcon}>
                            <Pressable
                                style={{ color: disabled ? colors.inputted_text_disabled : colors.inputted_text }}
                                onPress={!disabled ? handleCancelPress : null}
                            >
                                <CancelIcon
                                    style={{ fontSize: cancelSize }}
                                    fontSize={"inherit"}
                                />
                            </Pressable>
                        </View>
                        <View style={styles.searchChevronIcon}>
                            <Pressable
                                style={{ color: disabled ? colors.inputted_text_disabled : colors.inputted_text }}
                                onPress={!disabled ? handleChevronPress : null}
                                onBlur={handleSearchBlur}
                            >
                                {chevron ? (
                                    <KeyboardArrowDownIcon
                                        style={{ color: disabled ? colors.inputted_text_disabled : colors.inputted_text, fontSize: chevronSize }}
                                        fontSize={"inherit"}
                                    />
                                ) : (
                                    <KeyboardArrowUpIcon
                                        style={{ color: disabled ? colors.inputted_text_disabled : colors.inputted_text, fontSize: chevronSize }}
                                        fontSize={"inherit"}
                                    />
                                )}
                            </Pressable>
                        </View>
                    </View>
                    {showSuggestions && (
                        <View>
                            <SuggestionBox />
                        </View>
                    )}
                </View>
            </View>
        </TouchableWithoutFeedback>
    );
}

export default CustomAutocomplete;