import React, {useContext} from "react";
import { View, Text } from "react-native";
import { ResponsiveContainer, PieChart, Tooltip, Pie, LineChart, XAxis, YAxis, CartesianGrid, Legend, Cell, Line } from "recharts";
import { useColorContext } from "../ColorContext";
import ModeContext from "../ModeContext";

const Chart = React.memo(({pieSelection, pieColorSelection, budgetChartData, dataKeys, chartLineColors, range, subtype}) => {
    const { colors } = useColorContext();
    const { mode, toggleMode } = useContext(ModeContext);

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            const dataPoint = payload[0].payload;

            return (
                <View
                    style={{
                        backgroundColor: colors.white,
                        padding: 10,
                        borderRadius: 25,
                    }}
                >
                    {dataKeys.map((dataKey, index) => {
                        return (
                            <Text
                                key={dataKey}
                            >
                                {`${dataKey}: ${dataPoint[dataKey]}`}
                            </Text>
                        );
                    })}
                </View>
            );
        }

        return null;
    };

    return (
        <View>
            { subtype !== 'ALL' ? <ResponsiveContainer width="100%" height={275}>
                <PieChart>
                    <Tooltip />
                    <Pie
                        data={pieSelection}
                        outerRadius={"80%"}
                        innerRadius={"35%"}
                        dataKey="value"
                        nameKey="name"
                    >
                        {Object.values(pieSelection).map((entry, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={pieColorSelection[index]}
                            />
                        ))}
                    </Pie>
                    <Legend style={{ fontFamily: "Trebuchet MS" }} />
                </PieChart>
            </ResponsiveContainer> : null}
            <View style={{ flexDirection: "column", flex: 0.25 }} />
            <Text
                style={{
                    margin: 15,
                    marginTop: 25,
                    fontSize: 18,
                    textAlign: "center",
                    color: mode === "light" ? colors.black : colors.white,
                }}
            >
                Dollar Usage Per Month{"\n"}2023-2024
            </Text>
            { dataKeys.length > 0 ? (
                <ResponsiveContainer width={475} height={400}>
                <LineChart
                    width="98%"
                    height="98%"
                    data={budgetChartData?.[range]}
                    style={{ fontFamily: "Trebuchet MS" }}
                >
                    <XAxis
                        dataKey="month"
                        interval={0}
                        tickCount={4}
                        style={{
                            fontFamily: "Trebuchet MS",
                            fill:
                                mode === "light"
                                    ? colors.black
                                    : colors.white,
                        }}
                    />
                    <YAxis
                        tick={{
                            fill:
                                mode === "light"
                                    ? colors.black
                                    : colors.white,
                        }}
                    />
                    <Tooltip content={<CustomTooltip />} />
                    <CartesianGrid strokeDasharray="5 5" />     
                    <Legend />
                    
                    {dataKeys.map((dataKey, index) => (
                        <Line
                            key={dataKey}
                            type="monotone"
                            dataKey={dataKey}
                            stroke={chartLineColors?.[dataKey]}
                            strokeWidth={3}
                        />
                    ))}
                </LineChart>
            </ResponsiveContainer>
            ) : (<></>)}
            
        </View>
    );
})

export default Chart;