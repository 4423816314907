import React, { useEffect, useState } from 'react';
import { ActivityIndicator, FlatList, View, Text, StyleSheet, Image, Button, TouchableOpacity } from 'react-native';
import CustomHeader from '../../components/header';
import { useColorContext } from '../../components/ColorContext';
import {useFocusEffect} from '@react-navigation/native';
import { get_review_files } from '../../api_handler/files';
import NumberBadge from '../../components/NumberBadge';

function Home1380({ navigation }) {
    const { colors } = useColorContext();
    const [loaded, setLoaded] = useState(false);
    const styles = ({
        container: {
          flex: 1,
          backgroundColor: colors.screen_background,
        },
        header: {
          marginTop: '0%',
          height: 110,
          backgroundColor: colors.screen_background,
        },
        pageTitle: {
          fontSize: 30,
          fontWeight: 'bold',
          marginHorizontal: 5,
          textAlign: 'Left',
          paddingTop: 25,
          color: colors.text,
        },
        contentBox: {
          backgroundColor: colors.content_box,
          height: '50vh',
          width: '100%',
          borderRadius: '20px',
          justifyContent: 'center',
          padding: 10,
          paddingLeft: 10,
          paddingRight: 10,
          marginTop: 10,
        },
        button: {
          height: 80,
          borderRadius: 10,
          padding: 10,
          paddingLeft: 20,
          paddingRight: 20,
          backgroundColor: colors.wob,
          margin: 8,
          marginBottom: 15,
          borderColor: colors.button_border,
          borderWidth: 1,
          shadowOffset: {width: 0, height: 2},
          shadowOpacity: 0.25,
          shadowRadius: 4,
          flexDirection: 'row',
          alignItems: 'center',
        },
        textStyle: {
          justifyContent: 'Left',
          fontSize: 15,
          fontWeight: 'bold',
          color: colors.text,
        },
        backButton: {
          height: 40,
          width: 160,
          borderRadius: 10,
          alignItems: 'center',
          justifyContent: 'center',
          borderColor: 'black',
          borderWidth: 1,
          shadowOffset: {width: 0, height: 2},
          shadowOpacity: 0.25,
          shadowRadius: 4,
          marginTop: '5%',
          backgroundColor: colors.wob,
        },
      });
    const [canReview, setCanReview] = useState(false);

    useEffect(() => {
        if (global.user_data.permissions.includes(3002))
            setCanReview(true)
    }, [])
    
  const getRecRevFiles = async () => {
    setLoaded(false);
    // only call this endpoint for users with the permission
    if (global.user_data.permissions.includes(3001)) {
        const rec = await get_review_files({'role': global.user_data.role, 'user_id': global.user_data.user_id,'permissions': global.user_data.permissions}, global.token);

        const rev1380Filtered = rec.body.filter(
            (file) => file.status < 4 && (file.form_type === '1380' || file.form_type === '1380 aftp')
        );

        global.review1380 = rev1380Filtered.length;
    }

    setLoaded(true);
  };

  useFocusEffect(
      React.useCallback(() => {
        async function getInfo() {
          getRecRevFiles();
        }
        getInfo();
      }, []),
  )

    const new1380 = () => {
        navigation.navigate('New1380', {
            yfData: {},
            from1380: false
        });
    };

    const rstTo1380 = () => {
        navigation.navigate('RstTo1380');
    };

    const review1380 = () => {
        navigation.navigate('Review1380Home');
    };

    const goBack = () => {
        navigation.navigate('Request');
    };

    if (!loaded) {
        return (
          <View style = { styles.container}>
            <CustomHeader />
            <View style = { styles.header}>
              <Text style = {{color:  colors.text }}>Loading...</Text>
            </View>
          </View>
        );
      }
    
    return (
        <View style={styles.container}>
            <CustomHeader />

            <View
                style={{
                    flexDirection: "row",
                    zIndex: 5,
                    width: "75%",
                    alignSelf: "center",
                }}
            >
                <View style={{ alignText: "center", flex: 3 }}>
                    <Text style={styles.pageTitle}>1380</Text>

                    <Text
                        style={{
                            fontSize: 18,
                            marginHorizontal: 5,
                            textAlign: "Left",
                            paddingTop: 25,
                            color: colors.text
                        }}
                    >
                        Create New 1380, or Review 1380s
                    </Text>

                    <View>
                        <TouchableOpacity
                            style={styles.backButton}
                            onPress={() => goBack()}>
                            <Text style={styles.textStyle}>Back to Request</Text>

                        </TouchableOpacity>
                        <View style={styles.contentBox}>
                            <TouchableOpacity
                                style={[ styles.button , {flex: canReview ? 1 : 0.5}]}
                                onPress={() => new1380()}
                            >
                                <Text style={styles.textStyle}>Submit New 1380</Text>
                                <Text style={styles.textStyle}> (Blank Form)</Text>
                            </TouchableOpacity>

                            <TouchableOpacity
                                style={[ styles.button , {flex: canReview ? 1 : 0.5, justifyContent: 'space-between'}]}
                                onPress={() => rstTo1380()}
                            >
                                <Text style={styles.textStyle}>Submit New 1380 (Pull from RST)</Text>
                            </TouchableOpacity>

                            {canReview ? <TouchableOpacity
                                style={[ styles.button , {flex: canReview ? 1 : 0.5, justifyContent: 'space-between'}]}
                                onPress={() => review1380()}
                            >
                                <Text style={styles.textStyle}>Review 1380 as Approver</Text>
                                {global.review1380 > 0 ?
                                    <View style={{flexDirection: 'flex-end'}}>
                                        <NumberBadge count={global.review1380} />
                                    </View> : null}
                            </TouchableOpacity> : null}
                        </View>
                    </View>
                </View>
            </View>
        </View>
    );

};

export default Home1380;