import React from 'react';
import { get_events } from '../../api_handler/events';
import { get_users_names } from '../../api_handler/users';
import dayjs from 'dayjs';

export async function requestCalendarEvents(state, setState) {
  // console.log(typeof setState);
  return getCalendarInfo(state, setState);
}

const colorDots = {
  Mandatory: 'red',
  Optional: 'green',
  Personal: 'blue',
};

const colorEvents = {
  Mandatory: 'rgba(255, 0, 0, 0.5)', // Light red
  Optional: 'rgba(0, 255, 0, 0.5)', // Light green
  Personal: 'rgba(0, 0, 255, 0.5)', // Light blue
};

const isAcceptable = (organizer_role_id) => {
  let acceptable = false;
  if (global.user_data.role_id == 7 && organizer_role_id == 7) {
    acceptable = true;
  } else if (global.user_data.role_id == 8 && organizer_role_id == 8) {
    acceptable = true;
  } else {
    acceptable = false;
  }
  // return true;
  return acceptable;
};

const getCalendarInfo = async (state, setState) => {
  try {
    const get_events_JSON = { 'user_id': global.user_data.user_id, 'limit': 100 };
    let getEventsData = await get_events(get_events_JSON, global.token);
    if (getEventsData.status === 200) {
      getEventsData = getEventsData.data;
      for (let i = 0; i < getEventsData.body.length; i++) {
        const event = getEventsData.body[i];
  
        if (event.invitees_id.includes(global.user_data.user_id)) {
          getEventsData.body[i].status = 0;
        }
        if (event.confirmed_id.includes(global.user_data.user_id)) {
          getEventsData.body[i].status = 1;
        }
        if (event.organizer_id === global.user_data.user_id) {
          getEventsData.body[i].status = 3;
        }
      }
  
      if (getEventsData.body) {
        await setState((prevState) => ({
          ...prevState,
          events: getEventsData.body,
        }));
        await createdotsObjects(getEventsData.body, state, setState);
        // setEvents(getEventsData.body);
      }
    }
  } catch (error) {
    console.log('An error occurred:', error);
  }
};

const createdotsObjects = async (events, state, setState) => {
  // get all events for user and display them on calendar as dots
  // event details not shown until user presses them
  const markedEvents = {};
  const agendaEvents = {};
  let dots = [];
  let day = [];
  let dayData = {};
  let markedData = {};
  const user_ids = new Set();

  // Grab all invitees of all events
  for (const i in events) {
    events[i].invitees_id.forEach((id) => user_ids.add(id));
  }

  // Convert the Set back to an array
  let user_names = await get_users_names({ 'user_ids': [...user_ids] }, global.token);
  if (user_names.status !== 200) {
    return;
  }

  user_names = user_names.data;
  // Add dot objects for all events
  for (const i in events) {
    // Initialize variables
    let time; let dotsObjP; let dayObjP; let currDate; let endDate; let startDate;
    const c = colorDots[events[i].type];

    // Take start and end times in local time zones to display on calendar
    startDate = dayjs(events[i].starttime);
    endDate = dayjs(events[i].endtime);

    // add a year if events are dental or physical exams
    if (events[i].title === 'Dental Exam Due' || events[i].title === 'Physical Exam Due') {
      startDate = startDate.plus({ years: 1 });
      endDate = endDate.plus({ years: 1 });
    }

    // Convert to appropriate formats
    startDate = startDate.format('YYYY-MM-DD');
    endDate = endDate.format('YYYY-MM-DD');

    dotsObjP = {
      color: c,
    };

    events[i].inviteeNames = [];
    events[i].confirmedNames = [];

    // Get names of invitees using ids
    for (const invitee_id of events[i].invitees_id) {
      if (user_names.body[invitee_id] !== undefined) {
        events[i].inviteeNames.push(user_names.body[invitee_id]);
      }
    }

    for (const confirmed of events[i].confirmed_id) {
      if (user_names.body[confirmed] !== undefined) {
        events[i].confirmedNames.push(user_names.body[confirmed]);
      }
    }

    // List is empty, so we show message saying no invitees instead on names
    if (events[i].inviteeNames.length == 0) {
      events[i].inviteeNames.push('No Invitees');
    }

    // // Concatenate all the names with ', '
    else {
      events[i].inviteeNames = events[i].inviteeNames.join(', ');
    }

      dayObjP = {
        title: events[i].title,
        time: events[i].period && startDate != endDate ?'Ongoing event starting on '+dayjs(events[i].starttime).format('MM/DD/YYYY')+ " at "+dayjs(events[i].starttime).format('HH:mm') : dayjs(events[i].starttime).format('HH:mm'),
        type: events[i].type,
        id: events[i].scheduled_events_id,
        description: events[i].description,
        author: events[i].organizer_id,
        status: events[i].status,
        inviteeNames: events[i].inviteeNames,
        confirmedNames: events[i].confirmedNames,
        remarks: events[i].remarks,
        remarks2: events[i].remarks_2,
        training_events: events[i].training_events,
        canAccept: isAcceptable(events[i].organizer_role_id),
        location: events[i].location,
        startDate: dayjs(events[i].starttime),
        endDate:dayjs(events[i].endtime),
        muta: events[i].muta,
        weekly:events[i].weekly,
        yearly:events[i].yearly,
        period: events[i].period,
      };
    dots.push(dotsObjP);
    day.push(dayObjP);

    if (markedEvents[startDate] == undefined) {
      markedData['dots'] = dots; // set the array of dots
      markedEvents[startDate] = markedData; // add markers to marked dates
      dayData['agenda'] = day;
      agendaEvents[startDate] = dayData; // add events to the days agenda
      agendaEvents[startDate].size = 1;
    } else {
      const size = agendaEvents[startDate].size + 1;
      markedEvents[startDate].dots.splice(1, 0, dotsObjP); // add markers to marked dates
      agendaEvents[startDate].agenda.splice(1, 0, dayObjP); // add events to the days agenda
      agendaEvents[startDate].size = size;
    }

    // reset bins
    dots = [];
    day = [];
    markedData = {};
    dayData = {};

    // makes dots for all multiple-day events
    if (events[i].period && startDate != endDate) {
      currDate = dayjs(startDate).add(1, 'day').format('YYYY-MM-DD');
      while (dayjs(currDate).isBefore(endDate)) {
        dotsObjP = {
          color: c,
        };

          dayObjP = {
            title: events[i].title,
            time: events[i].period ? 'Ongoing event starting on '+dayjs(events[i].starttime).format('MM/DD/YYYY')+ " at "+dayjs(events[i].starttime).format('HH:mm') : dayjs(events[i].starttime).format('HH:mm'),
            type: events[i].type,
            id: events[i].scheduled_events_id,
            description: events[i].description,
            author: events[i].organizer_id,
            status: events[i].status,
            inviteeNames: events[i].inviteeNames,
            confirmedNames: events[i].confirmedNames,
            remarks: events[i].remarks,
            remarks2: events[i].remarks_2,
            training_events: events[i].training_events,
            canAccept: isAcceptable(events[i].organizer_role_id),
            location: events[i].location,
            startDate: dayjs(events[i].starttime),
            endDate: dayjs(events[i].endtime),
            muta: events[i].muta,
            weekly:events[i].weekly,
            yearly:events[i].yearly,
            period: events[i].period,
          };

        dots.push(dotsObjP);
        day.push(dayObjP);

        if (markedEvents[currDate] == undefined) {
          markedData['dots'] = dots; // set the array of dots
          markedEvents[currDate] = markedData; // add markers to marked dates
          dayData['agenda'] = day;
          agendaEvents[currDate] = dayData; // add events to the days agenda
          agendaEvents[currDate].size = 1;
        } else {
          const size = agendaEvents[currDate].size + 1;
          markedEvents[currDate].dots.splice(1, 0, dotsObjP); // add markers to marked dates
          agendaEvents[currDate].agenda.splice(1, 0, dayObjP); // add events to the days agenda
          agendaEvents[currDate].size = size;
        }

        // reset bins
        dots = [];
        day = [];
        markedData = {};
        dayData = {};

        currDate = dayjs(currDate).add(1, 'day').format('YYYY-MM-DD');
      }

      // create dot for the new curr date
      dotsObjP = {
        color: c,
      };

        dayObjP = {
          title: events[i].title,
          time: 'Ongoing event starting on '+dayjs(events[i].starttime).format('MM/DD/YYYY')+ " at "+dayjs(events[i].starttime).format('HH:mm'),
          type: events[i].type,
          id: events[i].scheduled_events_id,
          description: events[i].description,
          author: events[i].organizer_id,
          status: events[i].status,
          inviteeNames: events[i].inviteeNames,
          confirmedNames: events[i].confirmedNames,
          remarks: events[i].remarks,
          remarks2: events[i].remarks_2,
          training_events: events[i].training_events,
          canAccept: isAcceptable(events[i].organizer_role_id),
          location: events[i].location,
          startDate: dayjs(events[i].starttime),
          endDate: dayjs(events[i].endtime),
          muta: events[i].muta,
          weekly:events[i].weekly,
          yearly:events[i].yearly,
          period: events[i].period,
        };

      dots.push(dotsObjP);
      day.push(dayObjP);

      if (markedEvents[currDate] == undefined) {
        markedData['dots'] = dots; // set the array of dots
        markedEvents[currDate] = markedData; // add markers to marked dates
        dayData['agenda'] = day;
        agendaEvents[currDate] = dayData; // add events to the days agenda
        agendaEvents[currDate].size = 1;
      } else {
        const size = agendaEvents[currDate].size + 1;
        markedEvents[currDate].dots.splice(1, 0, dotsObjP); // add markers to marked dates
        agendaEvents[currDate].agenda.splice(1, 0, dayObjP); // add events to the days agenda
        agendaEvents[currDate].size = size;
      }

      // reset bins
      dots = [];
      day = [];
      markedData = {};
      dayData = {};
    }

    if (events[i].weekly) {
      currDate = dayjs(startDate).add(7, 'day').format('YYYY-MM-DD');
      let currEndDate = dayjs(events[i].endtime)!=null ? dayjs(events[i].endtime).add(7, 'day').format('YYYY-MM-DD'):undefined;

        for (let j = 1; j < 52; j++) {
          // makes dots for weekly event for the next year, 52 weeks
          dotsObjP = {
            color: c,
          };
          dayObjP = {
            title: events[i].title,
            time: dayjs(events[i].starttime).format('HH:mm'),
            type: events[i].type,
            id: events[i].scheduled_events_id,
            description: events[i].description,
            author: events[i].organizer_id,
            status: events[i].status,
            inviteeNames: events[i].inviteeNames,
            confirmedNames: events[i].confirmedNames,
            remarks: events[i].remarks,
            remarks2: events[i].remarks_2,
            training_events: events[i].training_events,
            canAccept: isAcceptable(events[i].organizer_role_id),
            location: events[i].location,
            startDate: dayjs(events[i].starttime),
            endDate: dayjs(events[i].endtime),
            muta: events[i].muta,
            weekly:events[i].weekly,
            yearly:events[i].yearly,
            period: events[i].period,
          };

        dots.push(dotsObjP);
        day.push(dayObjP);

        if (markedEvents[currDate] == undefined) {
          markedData['dots'] = dots; // set the array of dots
          markedEvents[currDate] = markedData; // add markers to marked dates
          dayData['agenda'] = day;
          agendaEvents[currDate] = dayData; // add events to the days agenda
          agendaEvents[currDate].size = 1;
        } else {
          const size = agendaEvents[currDate].size + 1;
          markedEvents[currDate].dots.splice(1, 0, dotsObjP); // add markers to marked dates
          agendaEvents[currDate].agenda.splice(1, 0, dayObjP); // add events to the days agenda
          agendaEvents[currDate].size = size;
        }

        // reset bins
        dots = [];
        day = [];
        markedData = {};
        dayData = {};

        currDate = dayjs(currDate).add(7, 'day').format('YYYY-MM-DD');
      }

        // create dot for the new curr date
        dotsObjP = {
          color: c,
        };
        dayObjP = {
          title: events[i].title,
          time: dayjs(events[i].starttime).format('HH:mm'),
          type: events[i].type,
          id: events[i].scheduled_events_id,
          description: events[i].description,
          author: events[i].organizer_id,
          status: events[i].status,
          inviteeNames: events[i].inviteeNames,
          confirmedNames: events[i].confirmedNames,
          remarks: events[i].remarks,
          remarks2: events[i].remarks_2,
          training_events: events[i].training_events,
          canAccept: isAcceptable(events[i].organizer_role_id),
          location: events[i].location,
          startDate: dayjs(events[i].starttime),
          endDate: events[i].period ? dayjs(events[i].endtime):null,
          muta: events[i].muta,
          weekly:events[i].weekly,
          yearly:events[i].yearly,
          period: events[i].period,
        };

      dots.push(dotsObjP);
      day.push(dayObjP);

      if (markedEvents[currDate] == undefined) {
        markedData['dots'] = dots; // set the array of dots
        markedEvents[currDate] = markedData; // add markers to marked dates
        dayData['agenda'] = day;
        agendaEvents[currDate] = dayData; // add events to the days agenda
        agendaEvents[currDate].size = 1;
      } else {
        const size = agendaEvents[currDate].size + 1;
        markedEvents[currDate].dots.splice(1, 0, dotsObjP); // add markers to marked dates
        agendaEvents[currDate].agenda.splice(1, 0, dayObjP); // add events to the days agenda
        agendaEvents[currDate].size = size;
      }

      // reset bins
      dots = [];
      day = [];
      markedData = {};
      dayData = {};
    }

    if (events[i].yearly) {
      currDate = dayjs(startDate).add(365, 'day').format('YYYY-MM-DD');

        for (let j = 1; j < 10; j++) {
          // makes dots for the yearly event for the next 10 years
          dotsObjP = {
            color: c,
          };
          dayObjP = {
            title: events[i].title,
            time: dayjs(events[i].starttime).format('HH:mm'),
            type: events[i].type,
            id: events[i].scheduled_events_id,
            description: events[i].description,
            author: events[i].organizer_id,
            status: events[i].status,
            inviteeNames: events[i].inviteeNames,
            confirmedNames: events[i].confirmedNames,
            remarks: events[i].remarks,
            remarks2: events[i].remarks_2,
            training_events: events[i].training_events,
            canAccept: isAcceptable(events[i].organizer_role_id),
            location: events[i].location,
            startDate: dayjs(events[i].starttime),
            endDate: events[i].period ? dayjs(events[i].endtime):null,
            muta: events[i].muta,
            weekly:events[i].weekly,
            yearly:events[i].yearly,
            period: events[i].period,
          };

        dots.push(dotsObjP);
        day.push(dayObjP);

        if (markedEvents[currDate] == undefined) {
          markedData['dots'] = dots; // set the array of dots
          markedEvents[currDate] = markedData; // add markers to marked dates
          dayData['agenda'] = day;
          agendaEvents[currDate] = dayData; // add events to the days agenda
          agendaEvents[currDate].size = 1;
        } else {
          const size = agendaEvents[currDate].size + 1;
          markedEvents[currDate].dots.splice(1, 0, dotsObjP); // add markers to marked dates
          agendaEvents[currDate].agenda.splice(1, 0, dayObjP); // add events to the days agenda
          agendaEvents[currDate].size = size;
        }

        // reset bins
        dots = [];
        day = [];
        markedData = {};
        dayData = {};

        currDate = dayjs(currDate).add(365, 'day').format('YYYY-MM-DD');
      }
        // create dot for the new curr date
        dotsObjP = {
          color: c,
        };
        dayObjP = {
          title: events[i].title,
          time: dayjs(events[i].starttime).format('HH:mm'),
          type: events[i].type,
          id: events[i].scheduled_events_id,
          description: events[i].description,
          author: events[i].organizer_id,
          status: events[i].status,
          inviteeNames: events[i].inviteeNames,
          confirmedNames: events[i].confirmedNames,
          remarks: events[i].remarks,
          remarks2: events[i].remarks_2,
          training_events: events[i].training_events,
          canAccept: isAcceptable(events[i].organizer_role_id),
          location: events[i].location,
          startDate: dayjs(events[i].starttime),
          endDate: events[i].period ? dayjs(events[i].endtime):null,
          muta: events[i].muta,
          weekly:events[i].weekly,
          yearly:events[i].yearly,
          period: events[i].period,
        };
      dots.push(dotsObjP);
      day.push(dayObjP);

      if (markedEvents[currDate] == undefined) {
        markedData['dots'] = dots; // set the array of dots
        markedEvents[currDate] = markedData; // add markers to marked dates
        dayData['agenda'] = day;
        agendaEvents[currDate] = dayData; // add events to the days agenda
        agendaEvents[currDate].size = 1;
      } else {
        const size = agendaEvents[currDate].size + 1;
        markedEvents[currDate].dots.splice(1, 0, dotsObjP); // add markers to marked dates
        agendaEvents[currDate].agenda.splice(1, 0, dayObjP); // add events to the days agenda
        agendaEvents[currDate].size = size;
      }

      // reset bins
      dots = [];
      day = [];
      markedData = {};
      dayData = {};
    }
  }
  const updatedState = {
    ...state, dotObjects: markedEvents, monthObjects: agendaEvents
  };
  await setState((prevState) => ({
    ...prevState,
    dotObjects: markedEvents,
    monthObjects: agendaEvents
  }));
  
};