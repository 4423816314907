import React, {useEffect, useState, Fragment} from 'react';
import {View, Text, TouchableOpacity} from 'react-native';
import dayjs from 'dayjs';
import {getMonth} from './util';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {useColorContext} from '../ColorContext';

export default function SmallCalendar({state, setState}) {
  const [currentMonthIdx, setCurrentMonthIdx] = useState(state.currentMonthIndex);
  const [currentMonth, setCurrentMonth] = useState(getMonth(currentMonthIdx));
  const {colors} = useColorContext();
  let isCurr = false;
  const colorDots = {
     red:'Mandatory',
     green:'Optional',
     blue:'Personal',
  };

  useEffect(() => {
    setCurrentMonth(getMonth(currentMonthIdx));
  }, [currentMonthIdx]);

  useEffect(() => {
    if (state.todayClicked) {
      setCurrentMonthIdx(state.currentMonthIndex);
      // setState({ ...state, todayClicked: false });
    }
  }, [state.todayClicked]);

  function handlePrevMonth() {
    setCurrentMonthIdx(currentMonthIdx - 1);
  }

  function handleNextMonth() {
    setCurrentMonthIdx(currentMonthIdx + 1);
  }

  function displayDots(day) {
    const formattedDay = day.format('YYYY-MM-DD');

    if (state.dotObjects[formattedDay] && state.dotObjects[formattedDay].dots.length > 0) {
      
      for (const event of state.dotObjects[formattedDay].dots) {
        let s = colorDots[event.color]
        if(state.labels[s] == true ){
          if (event.color === 'red' ) {
            return <View style={[styles.markedDot, {backgroundColor: 'red'}]} />;
          }
          else if (event.color === 'green') {
            return <View style={[styles.markedDot, {backgroundColor: 'green'}]} />;
          }
          else  if (event.color === 'blue') {
            return <View style={[styles.markedDot, {backgroundColor: 'blue'}]} />;
          }
        }
        return <View style={[styles.markedDot,]} />;
      
      }
    }
    return <View style={[styles.markedDot,]} />;
    return null;
  }

  function getDayStyle(day) {
    const format = 'DD-MM-YY';
    const nowDay = dayjs().format(format);
    const currDay = day.format(format);
    const slcDay = state.selectedDay && state.selectedDay.format(format);

    if (nowDay === currDay) {
      isCurr = true;
      return styles.today;
    } else if (currDay === slcDay) {
      isCurr = false;
      return styles.selectedDay;
    } else {
      isCurr = false;
      return styles.normalDay;
    }
  }

  const styles = ({
    container: {
      marginTop: 9,
    },
    header: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      //alignItems: 'center',
    },
    headerText: {
      fontSize: 22,
      color: colors.screen_title,
      fontWeight: 'bold',
      //alignSelf: 'left',
    },
    headerButtons: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    buttonText: {
      color: colors.text,
      fontSize: 40,
    },
    grid: {
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    dayHeader: {
      fontSize: 20,
      paddingVertical: 10,
      paddingHorizontal: 5,
      color: colors.text,
      textAlign: 'center',
      width: '14.2857%', // 7 columns in a row
      fontWeight: 'bold',
    },
    dayButton: {
      paddingHorizontal: '2%',
      width: '14.2857%', // 7 columns in a row
      alignItems: 'center',
    },
    dayText: {
      fontSize: 20,
      paddingVertical: 10,
      paddingHorizontal: 5,
      textAlign: 'center',
      // color:  colors.text:colors.dark_mode_text,
    },
    today: {
      backgroundColor: colors.calendar_selected,
      borderRadius: 999,
      color: 'white',
    },
    markedDot: {
      width: 10,
      height: 10,
      borderRadius: 5,
      margin: 2,
    },
    selectedDay: {
      backgroundColor: colors.light_blue,
      borderRadius: 999,
      fontWeight: 'bold',
      color: 'white',
    },
    normalDay: {},
  });

  const handleMonthChange = (day) => {
    setState({...state, selectedMonth: currentMonthIdx, selectedDay: day});
  };

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text style={styles.headerText}>
          {dayjs(new Date(dayjs().year(), currentMonthIdx)).format('MMMM YYYY')}
        </Text>
        <View style={styles.headerButtons}>
          <TouchableOpacity onPress={handlePrevMonth}>
            <ChevronLeftIcon style={styles.buttonText} />
          </TouchableOpacity>
          <TouchableOpacity onPress={handleNextMonth}>
            <ChevronRightIcon style={styles.buttonText} />
          </TouchableOpacity>
        </View>
      </View>
      <View style={styles.grid}>

        {currentMonth[0].map((day, i) => (
          <Text key={i} style={styles.dayHeader}>
            {day.format('dd').charAt(0)}
          </Text>
        ))}

        {currentMonth.map((row, i) => (
          <Fragment key={i}>
            {row.map((day, idx) => (
              <TouchableOpacity
                key={idx}
                onPress={() => {
                  handleMonthChange(day);
                }}
                style={[styles.dayButton, getDayStyle(day)]}
              >
                <Text style={[styles.dayText, {color: isCurr?colors.calendar_inverse_text:colors.text}]}>{day.format('D')}</Text>
                <View style={{flexDirection: 'row'}}>
                  {displayDots(day)}
                </View>
              </TouchableOpacity>
            ))}
          </Fragment>
        ))}
      </View>
    </View>
  );
}
