import React, {useEffect, useState} from 'react';
import {View, Text} from 'react-native';
import {useScale} from '../components/scale';
import CustomHeader from '../components/header';
import {TextInput} from 'react-native-paper';
import {CheckBox} from 'react-native-web';
import {Dimensions, useWindowDimensions, Pressable} from 'react-native';
import {en, registerTranslation, DatePickerModal} from 'react-native-paper-dates';
registerTranslation('en', en);
import {get_event, update_event, update_invite_status} from '../api_handler/events';
import {create_notification} from '../api_handler/notifications';
import {useIsFocused} from '@react-navigation/native';
import {StaticTimePicker} from '@mui/x-date-pickers/StaticTimePicker';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import { useColorContext} from '../components/ColorContext';

const windowDimensions = Dimensions.get('window');
const windowHeight = windowDimensions.height;

function ChangeEventStatusPage({route, navigation}) {
  const {colors} = useColorContext();
  const [refresh, setRefresh] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState(null);

  const scale = useScale();

  const styles = ({
    container: {
      flex: 1,
      backgroundColor: colors.screen_background,
  
    },
    textBox: {
      margin: 12,
      width: '50%',
      fontSize: 18,
    },
    input: {
      minHeight: 'fit-content',
      margin: 12,
      borderWidth: 1,
      padding: 3,
      borderRadius: 5,
      fontSize: 18,
      backgroundColor: colors.white,
      width: '50%',
    },
    list: {
      width: '75%',
      flex: 1,
    },
    button: {
      borderRadius: 10,
      padding: 10,
      elevation: 2,
      backgroundColor: 'black',
      margin: 8,
      borderColor: 'black',
      borderWidth: 1,
      width: 'fit-content',
    },
    buttonClose: {
      borderRadius: 10,
      padding: 10,
      elevation: 2,
      backgroundColor: 'black',
      margin: 8,
      borderColor: 'black',
      borderWidth: 1,
      width: 'fit-content',
    },
    view: {
      flexDirection: 'row',
      textAlign: 'center',
    },
    textStyle: {
      color: 'white',
      fontSize: '100%',
    },
    textStyleRed: {
      color: 'red',
      fontSize: '100%',
    },
    title: {
      fontSize: scale(50),
      fontFamily: 'Trebuchet MS',
      marginTop: '0.5%',
      margin: '.05%',
      marginLeft: '35%',
      color: colors.text,
    },
    main: {
      marginTop: '1.5%',
      height: '79%',
      alignItems: 'center',
    },
    item: {
      backgroundColor: 'orange',
      padding: 20,
      marginVertical: 8,
      marginHorizontal: 16,
      borderRadius: 8,
      shadowColor: '#000',
      shadowOffset: {width: 3, height: 3},
      shadowOpacity: 0.3,
      shadowRadius: 8,
    },
    innerText: {
      color: 'white',
      fontWeight: 'bold',
      padding: 10,
    },
    checkboxContainer: {
      flexDirection: 'row',
      fontSize: 18,
      alignItems: 'center',
      flex: 1,
    },
    heading: {
      fontSize: scale(50),
      fontFamily: 'Trebuchet MS',
      marginTop: '0.5%',
      marginLeft: '38%',
    },
    list2: {
      fontSize: 300,
      flex: 1,
    },
    modalStyling: {
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      // marginLeft: '250%',
    },
    label: {
      fontSize: 18,
      marginTop: '0.5%',
      marginRight: '2%',
      color: colors.text,
      textAlign: 'center',
    },
    text: {
      fontSize: 22,
      textAlign: 'center',
      color: colors.text,
    },
  });
  const isFocused = useIsFocused();

  const [showPicker, setShowPicker] = useState(false);
  const [showInvitees, setshowInvitees] = useState(false);

  const [showStart, setShowStart] = useState(false);
  const [showEnd, setShowEnd] = useState(false);
  const [showEndTime, setshowEndTime] = useState(false);

  const [showEButton, setshowEButton] = useState(false);
  const [showSButton, setshowSButton] = useState(false);
  const [showEndCal, setshowEndCal] = useState(false);
  const [showCal, setshowCal] = useState(false);

  const [showSetTimes, setshowSetTimes] = useState(false);
  const [showTime, setshowTime] = useState(false);
  const [startMode, setStartMode] = useState('date');
  const [endMode, setEndMode] = useState('date');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [timestamp, setTimestamp] = useState(new Date());

  const [isYearly, setisYearly] = useState(false);
  const [isWeekly, setisWeekly] = useState(false);
  const [isPeriod, setIsPeriod] = useState(false);

  const [newInvitees, setNewInvitees] = useState([]);
  const [inviteeIDs, setinviteeIDs] = useState([]);
  const [confirmedIDs, setconfirmedIDs] = useState([]);

  const [organizer, setOrganizer] = useState('');
  const [organizerID, setOrganizerId] = useState(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [location, setLocation] = useState('');
  const [remarks, setRemarks] = useState('');
  const [remarks_2, setRemarks2] = useState('');
  const [training_events, setTrainingEvents] = useState('');
  const [type, setType] = useState('Optional');
  const {width} = useWindowDimensions();
  const [comment, setComment] = useState('');
  const [isAccepted, setAccepted] = useState(false);
  const [isDeclined, setDeclined] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const [muta, setMuta] = useState(0);

  useEffect(() => {
    async function preLoad() {
      await getEventInfo();
      setLoaded(true);
    }
    preLoad();
  }, []);

  const getEventInfo = async () => {
    // api gets all info about this event from firebase

    const get_event_JSON = {'scheduled_events_id': global.eventID};
    const event = await get_event(get_event_JSON, global.token);

    if (event) {
      setStartDate(dayjs(event.body['starttime']).$d);
      if (event.body['period']) setEndDate(dayjs(event.body['endtime']).$d);
      else setEndDate(dayjs(event.body['starttime']).$d);
      setType(event.body['type']);
      setTitle(event.body['title']);
      setIsPeriod(event.body['period']);
      setDescription(event.body['description']);
      setOrganizer(event.body['organizer_name']);
      setOrganizerId(event.body['organizer_id']);
      setinviteeIDs(event.body['invitees_id']);
      setconfirmedIDs(event.body['confirmed_id']);
      setisWeekly(event.body['weekly']);
      setisYearly(event.body['yearly']);
      setLocation(event.body['location']);
      setMuta(event.body['muta']);
      setRemarks(event.body['remarks']);
      setRemarks2(event.body['remarks_2']);
      setTrainingEvents(event.body['training_events']);
      setTimestamp(event.body['timestamp']);
    }
  };


  // Closes any time popups
  const onDismissSingle = React.useCallback(() => {
    setshowCal(false);
    setshowTime(false);
    setshowEndCal(false);
    setshowEndTime(false);
  }, [showCal]);

  // Closes start date calendar
  const onConfirmSingle = React.useCallback((params) => {
    setshowCal(false);
    const hours = startDate.getHours();
    const minutes = startDate.getMinutes();

    params.date.setHours(hours, minutes, 0);

    setStartDate(params.date);
  }, [showCal, startDate]);

  // Closes end date calendar
  const onConfirmEndDate = React.useCallback((params) => {
    setshowEndCal(false);
    const hours = endDate.getHours();
    const minutes = endDate.getMinutes();

    params.date.setHours(hours, minutes, 0);

    setEndDate(params.date);
    setIsPeriod(true);
  }, [showEndCal, endDate]);

  // Closes start time
  const onConfirmTime = (time_JSON) => {
    if (time_JSON !== null) {
      const hours = time_JSON.$H;
      const minutes = time_JSON.$m;
      setshowTime(false);
      const d = startDate;
      d.setHours(hours, minutes, 0);
      setStartDate(d);
    }
  };

  const onConfirmEndTime = (time_JSON) => {
    if (time_JSON !== null) {
      const hours = time_JSON.$H;
      const minutes = time_JSON.$m;
      setshowEndTime(false);
      const d = endDate;
      d.setHours(hours, minutes, 0);
      setEndDate(d);
      setIsPeriod(true);
    }
  };

  const changeAccept = (value) => {
    setAccepted(value);

    if (value) setDeclined(false);
  };
  const changeDecline = (value) => {
    setDeclined(value);

    if (value) setAccepted(false);
  };

  // Update event if input is valid
  const submit = async () => {
    if (dayjs(endDate).isBefore(startDate) && isPeriod) {
      alert('Start Date Must Be Before End Date');
    } else if (dayjs(startDate).isBefore(dayjs(), 'day')) {
      alert('Event Cannot Be In The Past...');
    }

    // Update event based on user input
    else {
      const update_event_JSON = {
        'scheduled_events_id': global.eventID,
        'organizer_id': organizerID,
        'organizer_name': organizer,
        'invitees_id': inviteeIDs,
        'confirmed_id': confirmedIDs,
        'title': title,
        'location': location,
        'description': description,
        'period': isPeriod,
        'type': type,
        'muta': muta,
        'remarks': remarks,
        'remarks_2': remarks_2,
        'training_events': training_events,
        'starttime': startDate.toISOString(),
        'endtime': endDate.toISOString(),
        'timestamp': timestamp,
        'weekly': isWeekly,
        'yearly': isYearly,
      };

      const invite_status_JSON = {
        event_id: global.eventID,
        accepted: isAccepted,
        user_id: global.user_data.user_id,
        comment: comment,
      };

      await update_event(update_event_JSON, global.token);
      await update_invite_status(invite_status_JSON, global.token);

      // Send notifications to about updated event
      sendNotifToOrganizer(title);

      global.eventID = '';
      navigation.navigate('Calendar');
    }
  };

  const sendNotifToOrganizer = async (title) => {
    const userName = global.user_data.rank + ' ' + global.user_data.first_name + ' ' + global.user_data.last_name;
    const userStatus = isAccepted ? 'Accepted' : isDeclined ? 'Declined' : 'Not yet decided';

    const notificationContents = {
      title: userName + ' Has changed their invite status!',
      body: 'Changed status to: ' + userStatus + ' for your event: ' + title + '\nStart Time: ' + startDate.toISOString() + '\nEnd Time: ' + endDate.toISOString(),
      receiver_id: organizerID,
    };

    await create_notification(notificationContents, global.token);
  };

  if (!loaded) {
    return (
      <View style = { styles.container }>
		 <CustomHeader />
        <View style = {styles.header}>: styles.titleDark
		  <Text style={ styles.title }>Loading...</Text>
        </View>
      </View>
    );
  }

  return (
    <View style = { styles.container }>
		 <CustomHeader />
      <View style={{flexDirection: 'row', width: '100%', height: 0.06 * windowHeight}}>
        <Pressable
          onPress={() => {
            navigation.navigate('Calendar');
          }}
        >
          <Text style={ styles.text }>&larr; Calendar</Text>
        </Pressable>
        <Text style={ styles.title }>Accept/Decline</Text>
      </View>
      <View>
        <Text style={ styles.text }> Review the event information and accept or decline the invite </Text>
      </View>
      <View style={styles.main}>
        <Text style={ styles.label}>
					Author: {organizer}
        </Text>
        <TextInput
          label="Event Title"
          style={styles.textBox}
          editable={false}
          value={title}
        />

        <TextInput
          label="Event Type"
          style={styles.textBox}
          editable={false}
          value={type}
        />

        <View>
          <View style={{flexDirection: 'row', width: '100%', alignItems: 'center'}}>
            <Pressable
              style={styles.button}
              onPress={() => {
                setshowSetTimes(!showSetTimes);
                setshowSButton(true);
                setshowEButton(true);
                setShowStart(false);
                setShowEnd(false);
                setshowInvitees(false);
                if (showPicker) setShowPicker(false);
              }}
            >
              <Text style={styles.textStyle}>Event Date-Time</Text>
            </Pressable>
            <Text style={ styles.text }>
              {isPeriod ?
							(startDate.toString()).substring(4, 21) + ' - ' + (endDate.toString()).substring(4, 21) :
							(startDate.toString()).substring(4, 21)}
            </Text>
          </View>

          {showSetTimes ? (
						<View style={{marginLeft: '10%'}}>
						  {showSButton ? (
							<Pressable
							  style={styles.button}
							  onPress={() => {
							    setShowStart(true);
							    setShowEnd(false);
							    setshowSButton(false);
							    setshowEButton(true);
							  }}
							>
							  <Text style={styles.textStyle}>Set Start Time</Text>
							</Pressable>
							) : null}

						  {showStart ? (
						<View style={styles.view}>
						  <Pressable
						    style={styles.button}
						    onPress={() => {
						      setshowCal(true);
						    }}
						  >
						    <Text style={styles.textStyle}>Date</Text>
						  </Pressable>

						  <Pressable
						    style={styles.button}
						    onPress={() => {
						      {showTime ? setshowTime(false) : setshowTime(true);}
						    }}
						  >
						    <Text style={styles.textStyle}>Time</Text>
						  </Pressable>
						</View>
					) : null}

						  {showCal ? (
						<View style={{width: width * 0.75}}>
						  <DatePickerModal
						    locale="en"
						    mode="single"
						    visible={true}
						    onDismiss={onDismissSingle}
						    date={startDate}
						    onConfirm={onConfirmSingle}
						    validRange={{
						      startDate: new Date(), // optional
						      //   endDate: new Date(), // optional
						      //   disabledDates: [new Date()] // optional
						    }}
						  />
						</View>
					) : null}

						  {showTime ? (
						<View style={{width: width * 0.75}}>
						  <LocalizationProvider dateAdapter={AdapterDayjs}>

						    <StaticTimePicker
						      onAccept={onConfirmTime}
						      ampm={false}
						      ampmInClock={false}
						      sx={{
						        width: '30%',
						        bgcolor: '#F2F2F2',
						      }}
						    />

						  </LocalizationProvider>
						</View>
					) : null}

						  {showEButton ? (
						<Pressable
						  style={styles.button}
						  onPress={() => {
						    setShowEnd(true);
						    setShowStart(false);
						    setshowEButton(false);
						    setshowSButton(true);
						  }}
						>
						  <Text style={styles.textStyle}>Set End Time (Optional)</Text>
						</Pressable>
					) : null}

						  {showEnd ? (
						<View style={styles.view}>
						  <Pressable
						    style={styles.button}
						    onPress={() => {
						      setshowEndCal(true);
						    }}
						  >
						    <Text style={styles.textStyle}>Date</Text>
						  </Pressable>

						  <Pressable
						    style={styles.button}
						    onPress={() => {
						      {showEndTime ? setshowEndTime(false) : setshowEndTime(true);}
						    }}
						  >
						    <Text style={styles.textStyle}>Time</Text>
						  </Pressable>
						</View>
					) : null}

						  {showEndCal ? (
						<View style={{width: width * 0.75}}>
						  <DatePickerModal
						    locale="en"
						    mode="single"
						    visible={true}
						    onDismiss={onDismissSingle}
						    date={endDate}
						    onConfirm={onConfirmEndDate}
						    validRange={{
						      startDate: startDate, // optional
						      // endDate: new Date(), // optional
						      // disabledDates: [new Date()] // optional
						    }}
						  />
						</View>
					) : null}

						  {showEndTime ? (
						<View style={{width: width * 0.75}}>
						  <LocalizationProvider dateAdapter={AdapterDayjs}>

						    <StaticTimePicker
						      visible={true}
						      onAccept={onConfirmEndTime}
						      ampm={false}
						      ampmInClock={false}
						      sx={{
						        width: '30%',
						        bgcolor: '#F2F2F2',
						      }}
						    />

						  </LocalizationProvider>
						</View>
					) : null}

						</View>
					) : null}

        </View>

        <View>
          <Pressable
            style={styles.button}
            onPress={() => {
              setshowInvitees(!showInvitees);
              setshowSetTimes(false);
            }}
          >
            <Text style={styles.textStyle}>Event Invitees</Text>
          </Pressable>
          <Text style = { styles.text }>{showInvitees ? (route.params.names) : null}</Text>
        </View>
        <TextInput
          label="Event Location"
          style={styles.textBox}
          value={location}
          multiline={false}
          editable={false}
          onPressOut={() => {
            if (showPicker) setShowPicker(false);
            if (showSetTimes) setshowSetTimes(false);
          }}
        />

        <TextInput
          label="Event Notes"
          style={styles.textBox}
          value={description}
          multiline={true}
          editable={false}
          onPressOut={() => {
            if (showPicker) setShowPicker(false);
            if (showSetTimes) setshowSetTimes(false);
          }}
        />

        <TextInput
          label="MUTA"
          style={styles.textBox}
          value={muta}
          type="number"
          multiline={false}
          editable={false}
          pattern="[0-9]*"
          onPressOut={() => {
            if (showPicker) setShowPicker(false);
            if (showSetTimes) setshowSetTimes(false);
          }}
        />

        <TextInput
          label="Remarks"
          style={styles.textBox}
          value={remarks}
          multiline={true}
          editable={false}
          onPressOut={() => {
            if (showPicker) setShowPicker(false);
            if (showSetTimes) setshowSetTimes(false);
          }}
        />

        <TextInput
          label="Remarks 2"
          style={styles.textBox}
          onChangeText={setRemarks2}
          value={remarks_2}
          multiline={true}
          editable={false}
          onPressOut={() => {
            if (showPicker) setShowPicker(false);
            if (showSetTimes) setshowSetTimes(false);
          }}
        />

        {isWeekly ? (<Text style={ styles.text }>
					Event takes place every week
        </Text>) : null}
        {isYearly ? (<Text style={ styles.text }>
					Event takes place every year
        </Text>) : null}

        <View style={{flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center', paddingTop: 30, paddingBottom: 30}}>
          <CheckBox
            disabled={false}
            value={isAccepted}
            onValueChange={changeAccept}
          />

          <Text style={ styles.label}> Accept Invite </Text>
          <CheckBox
            disabled={false}
            value={isDeclined}
            onValueChange={changeDecline}
          />

          <Text style={ styles.label}> Decline Invite </Text>
        </View>

        <TextInput
          label="Comment (Optional)"
          style={styles.textBox}
          onChangeText={setComment}
          value={comment}
          multiline={true}
          editable={true}
          onPressOut={() => {
            if (showPicker) setShowPicker(false);
            if (showSetTimes) setshowSetTimes(false);
          }}
        />

        <View>
          <Pressable
            style={styles.button}
            onPress={submit}
          >
            <Text style={styles.textStyle}>Submit</Text>

          </Pressable>
        		</View>
      </View>
    </View>
  );
};

export default ChangeEventStatusPage;
